import { FC, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { CreditEducationInfo, CreditEducationProps } from '@/interfaces/CreditEducation';
import CreditEducationProvider, { useCreditEducationCtx } from './CreditEducationProvider';
import CustomerInformation from './components/CustomerInformation';
import IntegrationInformation from './components/IntegrationInformation';
import IntegrationControl from './components/IntegrationControl';
import { Loader } from '@progress/kendo-react-all';

const CreditEducation: FC<CreditEducationProps> = (props) => {
  const { isCobuyer, appRecId } = props;
  const { isDataLoading } = useCreditEducationCtx((s) => s);
  const { creditEducationInfo, setCreditEducationInfo } = useCreditEducationCtx((s) => s);
  const { creditEducationDBInfo } = useCreditEducationCtx((s) => s);
  const { setApplicationRecId } = useCreditEducationCtx((s) => s);
  const { fetchCreditEducationData } = useCreditEducationCtx((s) => s);
  const { setIsCobuyerTab } = useCreditEducationCtx((s) => s);
  
  useEffect(() => {
    if (appRecId == null) return;

    setApplicationRecId(appRecId);    
    setIsCobuyerTab(isCobuyer);

    const fetchData = async () => {
      await fetchCreditEducationData(appRecId);
    }

    fetchData().catch(console.error);
  }, [appRecId]);
  
  useEffect(() => {
    const buyerInfo =
      isCobuyer ? creditEducationDBInfo?.coBuyer : creditEducationDBInfo?.buyer;
    const buyerCreditInfo =
      isCobuyer
        ? creditEducationDBInfo?.coBuyerCreditEducation
        : creditEducationDBInfo?.buyerCreditEducation;
    const creditInfo = {
      firstName: buyerInfo?.b_FIRST,
      middleName: buyerInfo?.b_MID,
      lastName: buyerInfo?.b_LAST,
      dateOfBirth: buyerInfo?.b_DOB,
      SSNLast4: buyerInfo?.b_SSN!.slice(-4),
      phoneNumber: buyerInfo?.b_CPHONE,
      homePhoneNumber: buyerInfo?.b_HPHONE,
      workPhoneNumber: buyerInfo?.b_LLPHONE,
      faxNumber: buyerInfo?.b_LLFAX,
      email: buyerInfo?.b_EMAIL,
      currentAddress: buyerInfo?.b_ADDR,
      currentCity: buyerInfo?.b_CITY,
      currentState: buyerInfo?.b_STATE,
      currentZipCode: buyerInfo?.b_ZIP,
      appBuyersRecID: buyerInfo?.rec_Id!.toString(),
      creditRepairCloudID: buyerCreditInfo?.creditRepairCloudId,
      trackingToken: buyerCreditInfo?.smartCreditTrackingToken,
      customerToken: buyerCreditInfo?.smartCreditCustomerToken,
      creditEducationID: buyerCreditInfo?.rec_Id!.toString(),
    } as CreditEducationInfo;
    setCreditEducationInfo(creditInfo);
  }, [isCobuyer]);

  return (
    <Grid container direction="column" flex={1} gap={3}>
      { isDataLoading && 
        <Grid container direction="column" flex={1} alignContent={'center'}>
          <Loader size="large" />
        </Grid>
      }

      { !isDataLoading && 
        <>
          <CustomerInformation formData={creditEducationInfo} />
          <IntegrationInformation formData={creditEducationInfo} />
          <IntegrationControl formData={creditEducationInfo} />
        </>
      }
    </Grid>

  );
};

export default (props: CreditEducationProps) => (
  <CreditEducationProvider>
    <CreditEducation {...props} />
  </CreditEducationProvider>
);
