import { z } from "zod";

export const ZMpd = z.object({
  accountNumber: z
    .string()
    .regex(/^\d{5,17}$/)
    .nullish()
    .default(null),
  routingNumber: z
    .string()
    .regex(/^\d{9}$/)
    .nullish()
    .default(null),
  token: z.string().nullish().default(null),
  lastFour: z.string().nullish().default(null),
  /** Visa, Mastercard ... */
  brand: z.string().nullish().default(null),
  exp: z.string().nullish().default(null), // needs transform to date
});
export type ZMpd = z.infer<typeof ZMpd>;
