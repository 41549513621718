import { FC } from 'react';
// kendo
import { Button } from '@/components/button/Button';
// state
import { useInventoryViewCtx } from '../state';

const AddNewInventoryButton: FC = () => {
  const setIsModalOpen = useInventoryViewCtx((s) => s.setIsModalOpen);

  return (
    <Button label="Add New" buttonStyle={{ width: '190px' }} onClick={() => setIsModalOpen(true)} />
  );
};

export default AddNewInventoryButton;
