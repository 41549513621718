import { salesService } from '@/services/salesService';
import { AppDispatch } from '@/store/store';
import { toast } from 'react-toastify';
import { salesActions } from './salesSlice';
import { DealData } from '@/interfaces/Sales';

export const getQuoteData = (compId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setQuoteDataLoading(true));
    const response = await salesService.getQuoteData(compId);
    dispatch(salesActions.setQuoteData(response));
    return response;
  } catch (e) {
    toast.error('There was an error receiving company quote data');
    throw e;
  } finally {
    dispatch(salesActions.setQuoteDataLoading(false));
  }
};

export const getDealData = (appId?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setDealDataLoading(true));
    const response = await salesService.getDealsQuotesForm(appId);
    dispatch(salesActions.setDealData(response));
    return response;
  } catch (e) {
    toast.error('There was an error receiving deal data');
    throw e;
  } finally {
    dispatch(salesActions.setDealDataLoading(false));
  }
};

export const setDealData = (dealData: DealData) => async (dispatch: AppDispatch) => {
  dispatch(salesActions.setDealData(dealData));
};

export const getSalesDocList = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setSalesDocLoading(true));
    const response = await salesService.getSalesDocList(appRecId);
    dispatch(salesActions.setSalesDocInitial(response));
    return response;
  } catch (e) {
    toast.error('Unable to retrieve sales documents');
    throw e;
  } finally {
    dispatch(salesActions.setSalesDocLoading(false));
  }
};

export const getPresaleDocList = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setSalesDocLoading(true));
    const response = await salesService.getPresaleDocList(appRecId);
    dispatch(salesActions.setPresaleDocListInitial(response));
    return response;
  } catch (e) {
    toast.error('Unable to retrieve presales documents');
    throw e;
  } finally {
    dispatch(salesActions.setSalesDocLoading(false));
  }
};

/** @deprecated use value/setter in React-context in `SalesDetailProvider` (`saleData`, `setSaleData`, `setHasQuote`, `saleDataLoading`, `setSaleDataLoading`) */
export const getSaleData =
  (appRecId: number, showLoader = true) =>
  async (dispatch: AppDispatch) => {
    try {
      if (showLoader) {
        dispatch(salesActions.setSaleDataLoading(true));
      }
      const response = await salesService.getSale(appRecId);
      dispatch(salesActions.setSaleData(response));
      return response;
    } catch (e) {
      toast.error('There was an error receiving the sale data');
      throw e;
    } finally {
      dispatch(salesActions.setSaleDataLoading(false));
    }
  };

/** @deprecated Remove: redundant - use `getSaleData(appRecId, false)` */
export const getSaleDataNoLoading = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await salesService.getSale(appRecId);
    dispatch(salesActions.setSaleData(response));
    return response;
  } catch (e) {
    toast.error('There was an error receiving the sale data');
    throw e;
  }
};

export const getAvailableVehicles = (compId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setAvailableVehiclesLoading(true));
    const response = await salesService.getAvailableVehicles(compId);
    dispatch(salesActions.setAvailableVehicles(response));
    return response;
  } catch (e) {
    toast.error("There was an error receiving the company's vehicles");
    throw e;
  } finally {
    dispatch(salesActions.setAvailableVehiclesLoading(false));
  }
};

export const getSaleVehicle = (recId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setAvailableVehiclesLoading(true));
    const response = await salesService.getSaleVehicle(recId);
    dispatch(salesActions.setSaleVehicle(response));
    return response;
  } catch (e) {
    toast.error("There was an error receiving the company's vehicles");
    throw e;
  } finally {
    dispatch(salesActions.setAvailableVehiclesLoading(false));
  }
};

export const getApplication = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setApplicationDataLoading(true));
    const response = await salesService.getApplication(appRecId);
    dispatch(salesActions.setApplicationData(response));
    dispatch(salesActions.setSelectedVehicleId(response.vehRecID));
    return response;
  } catch {
    toast.error('There was an error receiving the application data');
    return;
  } finally {
    dispatch(salesActions.setApplicationDataLoading(false));
  }
};

export const getVerificationList = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await salesService.getVerificationList(appRecId);
    dispatch(salesActions.setVerificationList(response));
    return response;
  } catch {
    toast.error('There was an error retrieving the verification list');
    return;
  }
};

export const getLienholders = (compId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setSaleDataLoading(true));
    const response = await salesService.getLienHolders(compId);
    dispatch(salesActions.setLienholders(response));
    return response;
  } catch {
    toast.error('There wan an error retrieving the verification list');
    return;
  } finally {
    dispatch(salesActions.setSaleDataLoading(false));
  }
};

export const getFinanceTaxRate = (appRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(salesActions.setFinanceTaxRatesLoading(true));
    const response = await salesService.getFinanceTaxRates(appRecId);
    dispatch(salesActions.setFinanceTaxRates(response));
    return {
      ...response,
      stateRate: (response.stateRate || 0) / 100,
      cityRate: (response.cityRate || 0) / 100,
      countyRate: (response.countyRate || 0) / 100,
      locationRate: (response.locationRate || 0) / 100,
    };
  } finally {
    dispatch(salesActions.setFinanceTaxRatesLoading(false));
  }
};

export const getCompanyUnderwriting = (compId: number) => async (dispatch: AppDispatch) => {
  const response = await salesService.getCompanyUnderwriting(compId);
  dispatch(salesActions.setCompanyUnderwriting(response));
  return response;
};
