// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import ConditionalFmtCell, {
  ApplyCellStyle,
} from '@/mui/components/dataTable/cells/ConditionalFmtCell';
import ButtonLinkCell from '@/mui/components/dataTable/cells/ButtonLinkCell';
// utils
import { datetimeCellFmt, datetimeCellGetter } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { UserAuthRow } from '../../interfaces/user';

const applyActiveStyles: ApplyCellStyle<UserAuthRow, 'active'> = (activeValue) => ({
  color: activeValue === true ? 'green' : 'red',
});

export const tableColumns: MuiColumnDef<UserAuthRow>[] = [
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => p.value ?? '-',
  },
  {
    field: 'phoneCell',
    headerName: 'Phone',
    minWidth: 150,
    valueFormatter: (p) => p.value ?? '-',
  },
  {
    field: 'dateLastAccess',
    headerName: 'Date Last Access',
    minWidth: 170,
    type: 'datetime',
    valueFormatter: datetimeCellFmt,
    valueGetter: datetimeCellGetter,
    align: 'left',
  },
  {
    field: 'role',
    headerName: 'Roles',
    minWidth: 150,
    flex: 0.5,
    valueFormatter: (p) => p.value?.templateName ?? 'None set',
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    minWidth: 80,
    valueFormatter: (p: GridValueFormatterParams<UserAuthRow['active']>) =>
      p.value === true ? 'Active' : 'Inactive',
    renderCell: (p) => <ConditionalFmtCell {...p} applyCellStyle={applyActiveStyles} />,
  },
  {
    field: 'recId',
    headerName: 'Actions',
    renderCell: ButtonLinkCell,
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
  },
];
