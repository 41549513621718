import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import { Modal } from '@/components/modals/Modal';
import { Button } from '@/components/button/Button';
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { ZipInput } from '@/components/inputs/zipInput/ZipInput';
import { BasicTable } from '@/components/table/Table';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// state
import { useApplicationReferences } from './useApplicationReferences';
// utils
import { usaStateCodes } from '@/general/regions';
// style
import styles from './ApplicationReferences.module.scss';
import applicationTabStyle from '../ApplicationTabs.module.scss';

export const ApplicationReferences: FC = () => {
  const {
    control,
    columns,
    referenceList,
    postReferenceLoading,
    handleAddNewReference,
    handleSubmit,
    getReferencesLoading,
    editReferenceOpen,
    deleteReference,
    deleteReferenceLoading,
    updateReference,
    onEditModalClose,
    watch,
    fullReferenceAddress,
    handlePlaceSelected,
    isDisabled,
  } = useApplicationReferences();

  // @todo move component to file
  const ReferenceForm: FC<{ isEdit: boolean }> = (props) => {
    return (
      <form
        onSubmit={
          !props.isEdit ? handleSubmit(handleAddNewReference) : handleSubmit(updateReference)
        }
      >
        <section className={applicationTabStyle.section}>
          <SectionHeader title="Reference Information" />
          <div className={applicationTabStyle.bodyContainer}>
            <div className={applicationTabStyle.bodyChild}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextInput label="First Name" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextInput label="Last Name" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="relationship"
                control={control}
                render={({ field }) => (
                  <TextInput label="Relationship" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="referenceFor"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    label="Reference For"
                    data={['Buyer', 'Co-Buyer']}
                    disabled={isDisabled}
                    {...field}
                  />
                )}
              />
              <div className={applicationTabStyle.inputRow}>
                <label className={applicationTabStyle.inputLabel}>Years/Months Known</label>
                <div className={applicationTabStyle.inputRowChild}>
                  <Controller
                    name="yearsKnown"
                    control={control}
                    render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                  />
                  <Controller
                    name="monthsKnown"
                    control={control}
                    render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                  />
                </div>
              </div>
              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <TextArea readOnly={isDisabled} rows={3} label="Comments" {...field} />
                )}
              />
            </div>
            <div className={applicationTabStyle.bodyChild}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <AddressAutocomplete
                    label="Address"
                    {...field}
                    readOnly={isDisabled}
                    onPlaceSelected={handlePlaceSelected}
                    fullAddress={fullReferenceAddress}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => <TextInput label="City" readOnly={isDisabled} {...field} />}
              />
              <div className={applicationTabStyle.inputRow}>
                <label className={applicationTabStyle.inputLabel}>State/Zip</label>
                <div className={applicationTabStyle.inputRowChild}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        suggest
                        data={usaStateCodes}
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                  />
                </div>
              </div>
              <Controller
                name="county"
                control={control}
                render={({ field }) => (
                  <TextInput label="County" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => <TextInput label="Email" readOnly={isDisabled} {...field} />}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneInput label="Phone Number" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="dealership"
                control={control}
                render={({ field }) => (
                  <TextInput label="Dealership" readOnly={isDisabled} {...field} />
                )}
              />
              <div className={applicationTabStyle.inputRow}>
                <label className={applicationTabStyle.inputLabel}>Current Customer</label>
                <div className={applicationTabStyle.radioButton}>
                  <Controller
                    name="currentCustomer"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          {...restProps}
                          label="N/A"
                          defaultChecked={watch('currentCustomer') === 'N/A'}
                          value="N/A"
                          disabled={isDisabled}
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="currentCustomer"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          {...restProps}
                          label="Current"
                          defaultChecked={watch('currentCustomer') === 'Current'}
                          value="Current"
                          disabled={isDisabled}
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="currentCustomer"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          {...restProps}
                          label="Previous"
                          defaultChecked={watch('currentCustomer') === 'Previous'}
                          value="Previous"
                          disabled={isDisabled}
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.submitButton} style={{ gap: '8px' }}>
          {props.isEdit && (
            <Button
              label="Delete Reference"
              themeColor="error"
              onClick={deleteReference}
              disabled={isDisabled}
              loading={deleteReferenceLoading}
            />
          )}
          <Button
            label={props.isEdit ? 'Update Reference' : 'Submit'}
            buttonStyle={{ width: '140px' }}
            disabled={isDisabled}
            loading={postReferenceLoading}
          />
        </div>
      </form>
    );
  };

  // @todo move component to file
  const EditReferenceModal: FC = () => {
    return (
      <Modal
        isOpen={editReferenceOpen}
        closeButton={true}
        centerModal
        title="Edit Reference"
        onCloseButtonClick={onEditModalClose}
      >
        <ReferenceForm isEdit={true} />
      </Modal>
    );
  };

  // @todo move nested components to separate files
  return (
    <div>
      <ReferenceForm isEdit={false} />
      <section className={applicationTabStyle.section}>
        <SectionHeader title="Full Reference List" />
        {getReferencesLoading ? (
          <div className={applicationTabStyle.loading}>
            <Loader size="large" />
          </div>
        ) : (
          <BasicTable data={referenceList} columns={columns} />
        )}
      </section>
      <EditReferenceModal />
    </div>
  );
};
