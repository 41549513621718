import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
import { accountsService } from '@/services/accountsService';
// interfaces
import { StatusFilterKey, TransTypeKey } from './types';
import { SamTask } from '@/interfaces/sam';

export interface IHotlistCtx {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  hotlistData: SamTask[];
  setHotlistData: (newHotlistMap: SamTask[]) => void;
  statusFilter: StatusFilterKey;
  setStatusFilter: (statusFilter: StatusFilterKey) => void;
  fetchHotlist: (
    /** @note Currently only supports AppRecId and VehRecId */
    recId: number | null,
    transType: TransTypeKey,
    statusFilter: StatusFilterKey
  ) => Promise<void>;
}

const HotlistCtx = createContext<IHotlistCtx | null>(null);

const HotlistProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState<StatusFilterKey>('Active');
  const [hotlistData, setHotlistData] = useState<SamTask[]>([]);

  // Actions
  /**
   * @note Currently only supports AppRecId and VehRecId
   * @todo control `hotlistKey` and `transType` with browser/view route (once view-state is implemented)
   */
  const fetchHotlist = async (
    /** @note Currently only supports AppRecId and VehRecId */
    recId: number | null,
    transType: TransTypeKey,
    statusFilter: StatusFilterKey
  ) => {
    if (!recId) {
      console.error('fetchHotlist - No recId provided');
      return;
    }

    setIsLoading(true);
    // @todo handle errors
    const hotListResponse =
      (await accountsService.getHotlist(recId, transType, statusFilter)) || [];
    setIsLoading(false);

    setHotlistData(hotListResponse);
  };

  return (
    <HotlistCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        statusFilter,
        setStatusFilter,
        hotlistData,
        setHotlistData,
        fetchHotlist,
      }}
    >
      {children}
    </HotlistCtx.Provider>
  );
};

export default HotlistProvider;

export const useHotlistCtx = <T,>(selector: (state: IHotlistCtx) => T): T => {
  const ctx = useContext(HotlistCtx);
  if (!ctx) {
    throw new Error('useHotlistCtx must be used within HotlistProvider');
  }
  return selector(ctx);
};
