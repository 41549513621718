import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import { Button, DropdownInput, NumberInput, PhoneInput, TextInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { BasicTable } from '@/components/table/Table';
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import { MultipleInputRowRadioButton } from '@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { contactStatusData } from '@/utils/helpers/sales';
import { useVerificationForm } from '../useVerificationForm';
// style
import styles from '../../Verification.module.scss';

export const LandlordVerificationForm = () => {
  const { verificationListItem, verificationItemLoading } = useSalesSelector((s) => s);
  const { contactType, contactRecId } = verificationListItem;
  const {
    landlordVerificationForm,
    addressColumn,
    employerColumn,
    personalContactColumn,
    buyerInformationVerificationItems,
    employerInformationVerificationItems,
    personalContactVerificationItems,
    updateLandlordVerification,
    updateVerificationLoading,
    typeOfHomeData,
  } = useVerificationForm(contactType, contactRecId, verificationListItem);
  const { control, watch, handleSubmit, setValue } = landlordVerificationForm;

  if (verificationItemLoading) {
    return (
      <div className={styles.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(updateLandlordVerification)}>
      <section className={styles.section}>
        <SectionHeader title="Reference Information" />
        <div className={styles.bodyContainer}>
          <div className={styles.bodyChild}>
            <Controller
              name="landlordsName"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={
                    watch('rentOrOwn') === 'Rent' ? "Landlord's Name" : "Mortgage Holder's Name"
                  }
                  {...field}
                />
              )}
            />
            <MultipleInputRowRadioButton label="Rent or Own">
              <Controller
                name="rentOrOwn"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Rent"
                      defaultChecked={watch('rentOrOwn') === 'Rent'}
                      value="Rent"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Controller
                name="rentOrOwn"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Own"
                      defaultChecked={watch('rentOrOwn') === 'Own'}
                      value="Own"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            <MultipleInputRow label="Lived at Years/Months">
              <Controller
                name="livedAtYears"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
              <Controller
                name="livedAtMonths"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
            </MultipleInputRow>
            <Controller
              name="typeOfHome"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Type of Home" data={typeOfHomeData} {...field} />
              )}
            />
            <Controller
              name="rentAmount"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label={watch('rentOrOwn') === 'Rent' ? 'Rent Amount' : 'Mortgage Payment'}
                  {...field}
                />
              )}
            />
            {watch('rentOrOwn') === 'Own' && (
              <Controller
                name="mortgageBalance"
                control={control}
                render={({ field }) => <CurrencyInput label="Mortgage Balance" {...field} />}
              />
            )}
            <MultipleInputRowRadioButton label="Current/Past Due">
              <Controller
                name="currentOrPastDue"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Current"
                      defaultChecked
                      value="Current"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Controller
                name="currentOrPastDue"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Past Due"
                      value="Past Due"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
          </div>
          <div className={styles.bodyChild}>
            <div className={styles.bodyChild}>
              <Controller
                name="spokeTo"
                control={control}
                render={({ field }) => <TextInput label="Spoke To" {...field} />}
              />
              <Controller
                name="lastContact"
                control={control}
                render={({ field }) => <TextInput label="Last Contact" readOnly {...field} />}
              />
              <Controller
                name="contactStatus"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <DropdownInput
                    label="Contact Status"
                    data={contactStatusData}
                    {...restProps}
                    onChange={(e) => {
                      onChange(e);
                      setValue('lastContact', dayjs().format('MM/DD/YYYY hh:mm A'));
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.bodyChild}>
              <Controller
                name="landlordsPhoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    label={
                      watch('rentOrOwn') === 'Rent' ? "Landlord's Phone #" : "Mortgage Co's Phone #"
                    }
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Submit"
            fillMode="outline"
            buttonStyle={{ width: '160px' }}
            loading={updateVerificationLoading}
            secondary
          />
        </div>
      </section>
      <section className={styles.section}>
        <SectionHeader title="Contact Information" />
        <BasicTable data={buyerInformationVerificationItems} columns={addressColumn} />
        <BasicTable data={employerInformationVerificationItems} columns={employerColumn} />
        <BasicTable data={personalContactVerificationItems} columns={personalContactColumn} />
      </section>
    </form>
  );
};
