import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
import { AccountDb, newAccountDb } from './interfaces';

export interface IAcctSubviewCtx {
  accountList: AccountDb[];
  setAccountList: (accountList: AccountDb[]) => void;
  accountMap: Map<AccountDb['recId'], AccountDb>;
  getAccountById: (lookupKey: AccountDb['recId']) => AccountDb;
}

const AcctSubviewCtx = createContext<IAcctSubviewCtx | null>(null);

export const AcctSubviewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [accountList, setAccountList_] = useState<AccountDb[]>([]);

  return (
    <AcctSubviewCtx.Provider
      value={{
        accountList,
        setAccountList: (accountDbArr: AccountDb[]) => {
          const accountList = accountDbArr.map((a) => newAccountDb(a));
          setAccountList_(accountList);
        },
        get accountMap(): Map<AccountDb['recId'], AccountDb> {
          return new Map<AccountDb['recId'], AccountDb>(accountList.map((c) => [c.recId, c]));
        },
        getAccountById(lookupKey: AccountDb['recId']) {
          const acct = this.accountMap.get(lookupKey);
          if (!acct) throw new Error(`No Account found for recId: ${lookupKey}`);
          return acct;
        },
      }}
    >
      {children}
    </AcctSubviewCtx.Provider>
  );
};

export const useAcctSubviewCtx = <T,>(selector: (state: IAcctSubviewCtx) => T): T => {
  const ctx = useContext(AcctSubviewCtx);
  if (!ctx) {
    throw new Error('useAcctSubviewCtx must be used within AcctSubviewProvider');
  }
  return selector(ctx);
};
