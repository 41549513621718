// components
import ExportCell from './ExportCell';
// utils
import { Column } from '@/components/table/TableInterface';
import { IReport } from '@/interfaces/Reports';

export const defaultTableColumns: (Column & { field: keyof IReport | 'export' })[] = [
  {
    field: 'reportNameOrg',
    title: 'Name',
    width: 'auto',
    filter: 'text',
  },
  {
    field: 'reportType', 
    title: 'Category',
    width: '250px',
    //filter: 'text',
  },
  // @note this field is NOT from the API response
  {
    field: 'export',
    title: ' ',
    width: '210px',
    cell: (e) => <ExportCell report={e.dataItem as IReport} />,
  },
];
