import { FC, useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
// mui
import { DCS_BLUE } from '@/mui/theme/colors';
// kendo
import { BackLink } from '@components/backLink/BackLink';
import { Button } from '@components/button/Button';
import { Text } from '@components/text/Text';
import { Checkbox } from '@components/inputs/checkbox/CheckboxInput';
// components
import LedgerCardBuyerInfo from './LedgerCardBuyerInfo';
import LedgerCardAcctStatus from './LedgerCardAcctStatus';
import LedgerCardPaymentHistory from './LedgerCardPaymentHistory';
import LedgerCardContactHistory from './LedgerCardContactHistory';
import LedgerCardFinanceContractInformation from './LedgerCardFinanceContractInformation';
import LedgerCardInsuranceInformation from './LedgerCardInsuranceInformation';
import LedgerCardReferences from './LedgerCardReferences';
import LedgerCardExpenses from './LedgerCardExpenses';
import LedgerCardLeaseContractInformation from './LedgerCardLeaseContractInformation';
import LedgerCardHeader from './LedgerCardHeader';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { customerService } from '@services/customerService';
import { companyService } from '@services/companyService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { Ledger } from './interfaces';
import { Company } from '@/interfaces/Company';
// style
import styles from './PrintLedger.module.scss';
import { ledgerCss, printCss } from './ledgerStyles';

interface AppliedSettings {
  buyers: boolean;
  sam: boolean;
  acctStatus: boolean;
  payments: boolean;
  showNewPmts: boolean;
  contactHistory: boolean;
  contract: boolean;
  insurance: boolean;
  references: boolean;
  expenses: boolean;
}

/** @deprecated Needs refactor */
const PrintLedger: FC = () => {
  const [buyers, setBuyers] = useState(true);
  const [sam, setSam] = useState(true);
  const [acctStatus, setAcctStatus] = useState(true);
  const [payments, setPayments] = useState(true);
  const [showNewPmts, setShowNewPmts] = useState(true);
  const [contactHistory, setContactHistory] = useState(true);
  const [contract, setContract] = useState(true);
  const [insurance, setInsurance] = useState(true);
  const [references, setReferences] = useState(true);
  const [expenses, setExpenses] = useState(true);
  const [settingsLoader, setSettingsLoader] = useState(true);
  const [dataLoader, setDataLoader] = useState(false);
  const [fetchedSettings, setFetchedSettings] = useState(false);
  const [ledger, setLedger] = useState<Ledger | undefined>();
  const [printing, setPrinting] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [appliedSettings, setAppliedSettings] = useState<AppliedSettings>();

  const colRecId = getRouteParamNum(useParams().colRecId);

  const accountInformation = useAccountSelector((s) => s.accountInformation)!;

  const fetchSettings = async () => {
    try {
      setSettingsLoader(true);
      const settings = await customerService.getLedgerCardSettings();
      setBuyers(settings.buyer);
      setSam(settings.sam);
      setAcctStatus(settings.status);
      setPayments(settings.payments);
      setShowNewPmts(settings.newPayments);
      setContactHistory(settings.contact);
      setContract(settings.contract);
      setInsurance(settings.insurance);
      setReferences(settings.references);
      setExpenses(settings.expenses);

      setAppliedSettings({
        buyers: settings.buyer,
        sam: settings.sam,
        acctStatus: settings.status,
        payments: settings.payments,
        showNewPmts: settings.newPayments,
        contactHistory: settings.contact,
        contract: settings.contract,
        insurance: settings.insurance,
        references: settings.references,
        expenses: settings.expenses,
      });
    } finally {
      setSettingsLoader(false);
      setFetchedSettings(true);
    }
  };

  /** @deprecated unnecessary optimization - remove callback */
  const saveSettings = useCallback(async () => {
    try {
      setSettingsLoader(true);

      await customerService.updateLedgerCardSettings({
        buyer: buyers,
        sam,
        status: acctStatus,
        payments,
        newPayments: showNewPmts,
        contact: contactHistory,
        contract,
        insurance,
        references,
        expenses,
      });
    } finally {
      setSettingsLoader(false);
    }
  }, [
    buyers,
    sam,
    acctStatus,
    payments,
    showNewPmts,
    contactHistory,
    contract,
    insurance,
    references,
    expenses,
  ]);

  /** @deprecated unnecessary optimization - remove callback */
  const getLedger = useCallback(async () => {
    if (!colRecId) return;

    try {
      setDataLoader(true);
      setAppliedSettings({
        buyers,
        sam,
        acctStatus,
        payments,
        showNewPmts,
        contactHistory,
        contract,
        insurance,
        references,
        expenses,
      });
      const ledger = await customerService.getLedger(colRecId, {
        buyers,
        sam,
        acctStatus,
        payments,
        showNewPmts,
        contactHistory,
        contract,
        insurance,
        references,
        expenses,
      });
      setLedger(ledger);
    } finally {
      setDataLoader(false);
    }
  }, [
    buyers,
    sam,
    acctStatus,
    payments,
    showNewPmts,
    contactHistory,
    contract,
    insurance,
    references,
    expenses,
    colRecId,
  ]);

  /** @deprecated unnecessary optimization - remove callback */
  const printLedger = useCallback(async () => {
    try {
      setPrinting(true);
      const markup = ledger ? (
        <div className="ledger">
          <style>{printCss}</style>
          <LedgerCardHeader
            details={ledger.details}
            status={ledger.accountDetails?.accountStatus}
            accountInformation={accountInformation}
          />
          {appliedSettings?.buyers ? (
            <LedgerCardBuyerInfo buyer={ledger.buyer} type={'buyer'} />
          ) : null}
          {appliedSettings?.buyers &&
          ledger.coBuyer &&
          ledger.coBuyer.firstName &&
          ledger.coBuyer.lastName ? (
            <LedgerCardBuyerInfo buyer={ledger.coBuyer} type={'cobuyer'} />
          ) : null}
          {appliedSettings?.acctStatus ? (
            <LedgerCardAcctStatus
              accountDetails={ledger.accountDetails}
              originalPaymentSchedule={ledger.originalPaymentSchedule}
              currentPaymentSchedule={ledger.currentPaymentSchedule}
            />
          ) : null}
          {appliedSettings?.payments || appliedSettings?.showNewPmts ? (
            <LedgerCardPaymentHistory
              paymentHistory={ledger.paymentHistories}
              totals={ledger.totals}
              showNewPmts={appliedSettings.showNewPmts}
            />
          ) : null}
          {appliedSettings?.contactHistory ? (
            <LedgerCardContactHistory contactHistory={ledger.contactRecs} />
          ) : null}
          {appliedSettings?.contract ? (
            <LedgerCardFinanceContractInformation
              originalContractInformation={ledger.originalContractInformation}
            />
          ) : null}
          {appliedSettings?.insurance ? (
            <LedgerCardInsuranceInformation insuranceInformation={ledger.insuranceInformation} />
          ) : null}
          {appliedSettings?.references ? (
            <LedgerCardReferences personalReferences={ledger.appReferences} />
          ) : null}
          {appliedSettings?.expenses ? (
            <LedgerCardExpenses vehicleExpenses={ledger.invExpenses} />
          ) : null}
        </div>
      ) : null;
      if (markup) {
        const htmlMarkup = ReactDOMServer.renderToStaticMarkup(markup);
        const pdfData = await customerService.printLedger(htmlMarkup);

        const fileURL = URL.createObjectURL(pdfData);
        const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      }
    } finally {
      setPrinting(false);
    }
  }, [ledger, company, accountInformation]);

  /** @deprecated unnecessary optimization - remove callback */
  const fetchCompanyInformation = useCallback(async () => {
    if (!ledger?.accountDetails?.companyId) {
      return;
    }
    try {
      const companyResp = await companyService.getCompanyDetails(ledger.accountDetails.companyId);
      setCompany(companyResp);
    } finally {
      setDataLoader(false);
    }
  }, [ledger?.accountDetails]);

  useEffect(() => {
    fetchSettings();
    fetchCompanyInformation();
  }, []);

  useEffect(() => {
    fetchCompanyInformation();
  }, [ledger?.accountDetails]);

  useEffect(() => {
    // fetch ledger after settings have been applied
    if (fetchedSettings) {
      getLedger();
    }
  }, [fetchedSettings]);

  return (
    <div className={styles.container}>
      <style>{ledgerCss}</style>
      <div style={{ marginTop: '-30px' }}>
        <BackLink to=".." title="Back to Account" />
      </div>
      <h1 style={{ marginTop: 0 }}>Ledger Card</h1>
      <div className={styles.formContainer}>
        <div className={styles.settingsContainer}>
          <Text type={'h2'}>Select Sections</Text>
          {settingsLoader ? (
            <CircleSpinner color={DCS_BLUE} />
          ) : (
            /** @deprecated move jsx to separate component */
            <>
              <Checkbox label={'Buyer(s)'} checked={buyers} onClick={() => setBuyers(!buyers)} />
              <Checkbox label={'SAM'} checked={sam} onClick={() => setSam(!sam)} />
              <Checkbox
                label={'Acct. Status'}
                checked={acctStatus}
                onClick={() => setAcctStatus(!acctStatus)}
              />
              <Checkbox
                label={'Payments'}
                checked={payments}
                onClick={() => setPayments(!payments)}
              />
              <Checkbox
                label={'Show New Pmts'}
                checked={showNewPmts}
                onClick={() => setShowNewPmts(!showNewPmts)}
              />
              <Checkbox
                label={'Contact History'}
                checked={contactHistory}
                onClick={() => setContactHistory(!contactHistory)}
              />
              <Checkbox
                label={'Contract'}
                checked={contract}
                onClick={() => setContract(!contract)}
              />
              <Checkbox
                label={'Insurance'}
                checked={insurance}
                onClick={() => setInsurance(!insurance)}
              />
              <Checkbox
                label={'References'}
                checked={references}
                onClick={() => setReferences(!references)}
              />
              <Checkbox
                label={'Expenses'}
                checked={expenses}
                onClick={() => setExpenses(!expenses)}
              />
              <div>
                <Button label={'Run Report'} onClick={() => getLedger()} disabled={dataLoader} />
                <br />
                <Button
                  label={'Save Settings'}
                  secondary={true}
                  onClick={() => saveSettings()}
                  disabled={settingsLoader}
                />
              </div>
            </>
          )}
        </div>

        {/** @deprecated move jsx to separate component */}
        <div className={styles.ledgerContainer}>
          {dataLoader ? (
            <CircleSpinner color={DCS_BLUE} />
          ) : ledger ? (
            <>
              <div style={{ width: 250, marginBottom: 10 }}>
                <Button
                  label={'Print Ledger'}
                  icon={'print'}
                  onClick={() => printLedger()}
                  loading={printing}
                />
              </div>
              {appliedSettings?.buyers ? (
                <LedgerCardBuyerInfo buyer={ledger.buyer} type={'buyer'} />
              ) : null}
              {appliedSettings?.buyers &&
              ledger.coBuyer &&
              ledger.coBuyer.firstName &&
              ledger.coBuyer.lastName ? (
                <LedgerCardBuyerInfo buyer={ledger.coBuyer} type={'cobuyer'} />
              ) : null}
              {appliedSettings?.acctStatus ? (
                <LedgerCardAcctStatus
                  accountDetails={ledger.accountDetails}
                  originalPaymentSchedule={ledger.originalPaymentSchedule}
                  currentPaymentSchedule={ledger.currentPaymentSchedule}
                />
              ) : null}
              {appliedSettings?.payments || appliedSettings?.showNewPmts ? (
                <LedgerCardPaymentHistory
                  paymentHistory={ledger.paymentHistories}
                  totals={ledger.totals}
                  showNewPmts={appliedSettings.showNewPmts}
                />
              ) : null}
              {appliedSettings?.contactHistory ? (
                <LedgerCardContactHistory contactHistory={ledger.contactRecs} />
              ) : null}
              {appliedSettings?.contract ? (
                ledger.originalContractInformation.colType === 'LS' ? (
                  <LedgerCardLeaseContractInformation
                    originalContractInformation={ledger.originalContractInformation}
                  />
                ) : (
                  <LedgerCardFinanceContractInformation
                    originalContractInformation={ledger.originalContractInformation}
                  />
                )
              ) : null}
              {appliedSettings?.insurance ? (
                <LedgerCardInsuranceInformation
                  insuranceInformation={ledger.insuranceInformation}
                />
              ) : null}
              {appliedSettings?.references ? (
                <LedgerCardReferences personalReferences={ledger.appReferences} />
              ) : null}
              {appliedSettings?.expenses ? (
                <LedgerCardExpenses vehicleExpenses={ledger.invExpenses} />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PrintLedger;
