import { z } from "zod";
import { toast } from "react-toastify";
// interfaces
import {
  AchPmtProps,
  CollectJsConfigCallbackProps,
  CreditCardPmtProps,
  CssIdSelector,
  IFieldProps,
} from "./cliqInterfaces";

export const scriptElemId = "cliqScript";
export const scriptElemSelector: CssIdSelector = `#${scriptElemId}`;

export const submitButtonId = "submitButton";
export const submitButtonSelector: CssIdSelector = `#${submitButtonId}`;

export const formId = "cliqForm";
export const formSelector: CssIdSelector = `#${submitButtonId}`;

export const cliqBaseConfig: CollectJsConfigCallbackProps = {
  variant: "inline",
  styleSniffer: true,
  theme: "material",

  paymentSelector: submitButtonSelector,
  timeoutDuration: 5000,
  customCss: {
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "unset",
    borderStyle: "unset",
    borderWidth: "unset",
    border: "none",
    // @ts-ignore
    "font-weight": "300",
    "font-size": "16px",
  },

  // Callbacks
  timeoutCallback: () => {
    toast.error("Cliq: Request timed out");
    console.warn("Cliq: Request timed out");
  },
};

export type CreditCardFieldConfig = {
  [key in keyof CreditCardPmtProps]: IFieldProps & { id: string };
};

const fieldConfig = z.object({ selector: z.string().startsWith("#") }).strip();
export const creditCardFieldSchema = z.object({
  ccnumber: fieldConfig,
  ccexp: fieldConfig,
  cvv: fieldConfig,
});

export const achFields: AchPmtProps = {
  checkname: { selector: "#checkname" },
  checkaccount: { selector: "#checkaccount" },
  checkaba: { selector: "#checkaba" },
  checktransit: { selector: "#checktransit" },
  checkinstitution: { selector: "#checkinstitution" },
};
