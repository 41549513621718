import { FC } from "react";
import { Outlet, Route, Routes, matchRoutes, useLocation, useMatch } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import MuiViewHeader from "@/mui/components/layout/ViewHeader";
// components
import CollectionQueuesList from "./CollectionQueuesList";
import { QueueRunList } from "./QueueRunList";
import { RunQueue } from "./runQueue/RunQueue";
import AddNewButton from "./AddNewButton";
// state
import CollectionQueuesProvider, { useCollectionQueuesCtx } from "./CollectionQueuesProvider";

const ViewHeader: FC = () => {
  const currentLocation = useLocation();
  const queues = useCollectionQueuesCtx((s) => s.queues);
  const isLoading = useCollectionQueuesCtx((s) => s.isLoading);
  const activeQueueName = useCollectionQueuesCtx((s) => s.activeQueue?.queueName);
  const someRoutes = [{ path: "/collections/:queueRecId" }]; // @todo replace with `useMatch({ path: "/collections/:queueRecId" })`
  const matches = matchRoutes(someRoutes, currentLocation);
  const isMatch = matches ? matches.length > 0 : false;
  const dbCt = queues.length;

  return (
    <MuiViewHeader
      primaryText="Collection Queues"
      centerText={
        isMatch && (
          <Grid container direction="row" gap={1.5} flex={1} px={1} wrap="nowrap">
            <Typography fontSize={26} fontWeight={300} letterSpacing="-0.52px" noWrap>
              - Viewing queue:
            </Typography>
            <Typography fontSize={26} fontWeight={700} letterSpacing="-0.52px" noWrap>
              {activeQueueName ? `"${activeQueueName}"` : "-"}
            </Typography>
          </Grid>
        )
      }
      isLoading={isLoading}
      dbCt={isMatch ? undefined : dbCt}
      viewSelector={!isMatch ? <AddNewButton /> : <></>}
      centered={false}
    />
  );
};

const CollectionQueuesLayout: FC = () => (
  <Grid px="45px">
    <ViewHeader />
    <Outlet />
  </Grid>
);

/** ### @deprecated needs refactor */
const CollectionQueuesRouter: FC = () => (
  <CollectionQueuesProvider>
    <Routes>
      <Route element={<CollectionQueuesLayout />}>
        <Route path="/" element={<CollectionQueuesList />} />
        <Route path="/:queueRecId" element={<QueueRunList />} />
      </Route>

      {/* @todo If not adding more routes, consolidate to single route component with `path="/:queueRecId/run/:colRecId/*"` */}
      <Route path="/:queueRecId/run">
        <Route path=":colRecId/*" element={<RunQueue />} />
      </Route>
    </Routes>
  </CollectionQueuesProvider>
);

export default CollectionQueuesRouter;
