import { FC, ReactNode, createContext, useContext, useState } from 'react';
// kendo
import {
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { Filter } from '@/interfaces/requests';
import { SortKendo, IKendoPaginationState } from '@/interfaces/kendo';

// eslint-disable-next-line
const initSort: SortKendo[] = [];
const initPage: IKendoPaginationState = { skip: 0, take: 25 };
const initFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };

export interface ITableContext<SortKey = string> {
  /** Kendo-data-grid filters */
  filters: CompositeFilterDescriptor;
  categoryFilters: Filter[];
  setFilters: (filters: CompositeFilterDescriptor) => void;
  addCategoryFilter: (filter: Filter) => void;
  removeCategoryFilter: (filter: Filter) => void;
  textFilter: string; //@todo: This was created temporally due to Email does not use search on Server Side
  setTextFilter: (textSearch: string) => void;
  textSearch: string;
  setTextSearch: (textSearch: string) => void;
  page: IKendoPaginationState;
  setPage: (page: IKendoPaginationState) => void;
  //@todo: this should be expand to custom SortKey when work on emailReports views
  sort: SortKendo<SortKey>[]; 
  setSort: (sort: SortKendo<SortKey>[]) => void;
  sortClientSide: SortKendo<SortKey>[];  //@todo: remove this sort when Email reports goes Server side sorting 
  setSortClientSide: (sort: SortKendo<SortKey>[]) => void;
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: number) => void;
  queryPageNumber: number;
}

const TableContext = createContext<ITableContext | null>(null);

export const TableProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<CompositeFilterDescriptor>(initFilters);
  const [categoryFilters, setCategoryFilters] = useState<Filter[]>([]);
  const [textSearch, setTextSearch] = useState<ITableContext['textSearch']>('');
  const [textFilter, setTextFilter_] = useState<ITableContext['textSearch']>('');
  const [page, setPage] = useState(initPage);
  const [sort, setSort] = useState(initSort);
  const [sortClientSide, setSortClientSide] = useState(initSort);

  const addCategoryFilter = (newFilter: Filter) => 
    {        
      const updatedFilters = [
        ...categoryFilters,
        newFilter
      ];

      setCategoryFilters(updatedFilters);
    };

  const removeCategoryFilter = (filterToRemove: Filter) => {
    const updatedFilter = [...categoryFilters].filter(
      (f) =>
        !(
          f.field === filterToRemove.field &&
          f.value === filterToRemove.value &&
          f.operator === filterToRemove.operator
        )
    );
    setCategoryFilters(updatedFilter); 
  };
  

  return (
    <TableContext.Provider
      value={{
        filters,
        categoryFilters,
        setFilters,
        addCategoryFilter,
        removeCategoryFilter,
        textFilter,
        setTextFilter(newTextSearchFilter: string) {
          setPage({ ...page, skip: 0 });
          setTextFilter_(newTextSearchFilter);
        },
        textSearch,
        setTextSearch,
        page,
        setPage,
        sort,
        setSort,
        sortClientSide,
        setSortClientSide,
        get pageSizeValue(): number {
          return page.take!;
        },
        setPageSizeValue(take: number) {
          setPage({ ...page, take });
        },
        queryPageNumber: page.skip! / page.take! + 1,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTableCtx = <T,>(selector: (state: ITableContext) => T): T => {
  const ctx = useContext(TableContext);
  if (!ctx) throw new Error('useTableCtx must be used within TableProvider');

  return selector(ctx);
};
