import { SalesSubview } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  CashSalesFilterKey,
  CashSalesSortKey,
  cashSalesFilterFields,
  cashSalesSortFields,
} from './dataModelConfig';
import { CashSalesDb } from './interfaces';
import { cashSalesTableColumns } from './tableColumns';

export const cashSalesSubviewConfig: SubviewInfo<CashSalesSortKey, CashSalesFilterKey> = {
  text: 'Cash Sales',
  route: SalesSubview.enum.cashSales,
  columns: cashSalesTableColumns,
  dateFilterField: { field: 'saleDate', label: 'Date Sold' },
  initSortField: 'saleDate',
  sortableFields: cashSalesSortFields,
  filterableFields: cashSalesFilterFields,
  dbRowValidationFxn: (row: CashSalesDb) => CashSalesDb.new(row),
};
