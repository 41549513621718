import { FC, PropsWithChildren, createContext, useEffect, useMemo } from "react";
import { usePDF } from "@react-pdf/renderer";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// interfaces
import AiUnderwritingPdfLayout from "./AiUnderwritingPdfLayout";

const useCtxState = () => {
  const currentAppEval = useAiUnderwritingCtx((s) =>
    s.isModalOpen ? s.selectedAppEval : s.latestAppEval
  );

  const application = useSalesSelector((s) => s.applicationData);
  const vehRecId = application.vehRecID;
  const vehicle = useSalesSelector((s) => s.availableVehicles.find((v) => v.recId === vehRecId));

  const appEvalId = `${currentAppEval?.applicationId}-${currentAppEval?.dateCreated}`;
  const areDepsInvalid = !currentAppEval || !application || !vehicle;

  const pdfComponent = useMemo(
    () =>
      areDepsInvalid ? (
        <></>
      ) : (
        <AiUnderwritingPdfLayout
          currentAppEval={currentAppEval}
          application={application}
          vehicle={vehicle}
        />
      ),
    [appEvalId, areDepsInvalid]
  );

  const [instance, updateInstance] = usePDF({ document: pdfComponent });

  useEffect(() => {
    if (!areDepsInvalid) {
      updateInstance(pdfComponent);
    }
  }, [appEvalId, areDepsInvalid]);

  return {
    instance,
    updateInstance,
  };
};
type IPdfCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<IPdfCtx | null>(null);
export const usePdfCtx = useCtxFactory(Ctx);

const PdfProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default PdfProvider;
