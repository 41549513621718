import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
// kendo
import { Icons } from '@/components/icons';
import { Column } from '@/components/table/TableInterface';
// state
import { useAppDispatch } from '@/store/store';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
import { getVerificationList } from '@/features/Sales/salesActionCreator';
// interfaces
import { VerificationList } from '@/interfaces';
import { GridCustomCellProps } from '@progress/kendo-react-all';

/** @deprecated convert to context */
export const useVerification = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const appRecId = Number(params.id);
  const verificationList = useSalesSelector((s) => s.verificationList);
  const showVerificationForm = useSalesSelector((s) => s.showVerificationForm);

  useEffect(() => {
    dispatch(getVerificationList(appRecId));
  }, [showVerificationForm]);

  const onVerificationItemClick = (props: any) => {
    const verificationListItem: VerificationList = props.dataItem;
    dispatch(salesActions.setVerificationListItem(verificationListItem));
    dispatch(salesActions.setShowVerificationForm(true));
  };

  const LastCalledColumn: FC<GridCustomCellProps> = ({ dataItem, field }) => {
    const value = field && dataItem[field];
    return <td>{value ? dayjs(value).format('MM/DD/YYYY hh:mm A') : ''}</td>;
  };

  const VerifiedColumn: FC<GridCustomCellProps> = ({ dataItem, field }) => {
    const value = field && dataItem[field];
    return (
      <td>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {value === true ? <Icons.FilledCheckmark /> : value === false ? <Icons.XFilled /> : '-'}
        </div>
      </td>
    );
  };

  const columns: Column[] = [
    { field: 'contact', title: 'Name' },
    { field: 'contactType', title: 'Contact Type' },
    { field: 'callResults', title: 'Call Result' },
    { field: 'lastCall', title: 'Last Called', cells: { data: LastCalledColumn } },
    { field: 'verifiedAddress', title: 'Address', cells: { data: VerifiedColumn }, width: '90px' },
    {
      field: 'verifiedEmployer',
      title: 'Employer',
      cells: { data: VerifiedColumn },
      width: '95px',
    },
    { field: 'verifiedPhone', title: 'Phone', cells: { data: VerifiedColumn }, width: '75px' },
    {
      field: 'verifiedContact',
      title: 'Can Contact',
      cells: { data: VerifiedColumn },
      width: '120px',
    },
  ];

  return {
    columns,
    verificationList,
    onVerificationItemClick,
  };
};
