import { FC } from 'react';
import { Link } from 'react-router-dom';
// utils
import { useReportsViewCtx } from '../state/view';
import { useERSContext } from '../ERSDetailView/state';
import { ERSFmt } from '../ERSDetailView/interfaces';
// style
import styles from '../ERSDetailView/EmailReportsSchedules.module.scss';

/** ### Layout and logic for cells in the `Name` column.
 * - Onclick: open modal
 * - Style the cursor as `pointer` to indicate clickable element.
 */
const NameCell: FC<{ emailReportSchedule: ERSFmt }> = ({ emailReportSchedule }) => {
  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const { setSelectedERS } = useERSContext();

  // Event handlers
  // @todo adapt for reacto router usage in future sub-ticket
  const handleClickNav = () => {
    setSelectedERS(emailReportSchedule);
    setIsModalOpen(true);
  };

  return (
    <td className={styles.nameCell}>
      {/* @todo when implementing router, set to `/schedules/:recId` i.e. `/schedules/${emailReportSchedule.recId}` */}
      <Link to={''} onClick={handleClickNav}>
        {emailReportSchedule.reportName}
      </Link>
    </td>
  );
};

export default NameCell;
