import { useNavigate } from 'react-router-dom';

/** ### Returns an event-handler callback that navigates back one page - previous element in browser history
 * @note Only for use in DOM-element event-handlers (i.e. `onClick={navBack()}`/`onSubmit={navBack()}`)
 * @note For use in component body: see `useNavBack()` hook in `useNavBack.tsx`
 *
 * @todo add ability to enter number of pages - `(numberOfPages: number = 1)`
 */
export const useNavBack = () => {
  const navigate = useNavigate();

  return () => navigate(-1);
};
