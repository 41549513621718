import dayjs from 'dayjs';
import {
  FC,
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import tabStyles from '@features/Sales/components/salesTabs/SalesTab.module.scss';
import { DealData, QuoteData } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { useAuthSelector } from '@/features/auth/authSlice';

export interface IDealsQuotesDetailCtx {
  headerTitle: ReactNode;
  setHeaderTitle: (headerTitle: ReactNode) => void;
  printTargetRef: MutableRefObject<any>;
  dealQuoteFirstLoad: boolean;
  setDealQuoteFirstLoad: (isLoading: boolean) => void;
  isQuoteInfoLoading: boolean;
  dealData: DealData | null;
  quoteData: QuoteData[] | null;
  setDealData: (data: DealData) => void;
}

const DealsQuotesDetailCtx = createContext<IDealsQuotesDetailCtx | null>(null);

export const useHandlePrint = (printTargetRef: MutableRefObject<any>) => {
  var quoteTitle = useParams()['*'];

  return useReactToPrint({
    content: () => printTargetRef.current,
    documentTitle: `${quoteTitle}-quote-${dayjs().format('MM-DD-YYYY_hh-mm-a')}.pdf`,
    onBeforeGetContent: () => {
      if (!printTargetRef?.current) {
        toast.error('Print error');
        return;
      }
      printTargetRef.current.classList.add(tabStyles.printing!);
    },
    onBeforePrint: () => {
      if (!printTargetRef?.current) return;
      printTargetRef.current.classList.remove(tabStyles.printing!);
    },
  });
};

const DealsQuotesDetailProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { compId } = useAuthSelector((s) => s);
  const recId = useParams().appRecId;

  const [headerTitle, setHeaderTitle] = useState<ReactNode>(null);
  const printTargetRef = useRef<HTMLDivElement>(null);
  const [dealQuoteFirstLoad, setDealQuoteFirstLoad] = useState(true);
  const [isQuoteInfoLoading, setIsQuoteInfoLoading] = useState(false);
  const [quoteData, setQuoteData] = useState<QuoteData[] | null>(null);
  const [dealData, setDealData] = useState<DealData | null>(null);

  // only re-runs when compId changes
  useEffect(() => {
    // Early-exit if no/invalid compId in state
    if (isNaN(Number(compId))) return;
    setIsQuoteInfoLoading(true);

    salesService
      .getQuoteData(compId!)
      .then((response) => {
        setQuoteData(response);
      })
      .catch((e) => {
        toast.error('There was an error receiving company quote data');
        throw e;
      });
    setIsQuoteInfoLoading(false);
  }, [compId]);

  useEffect(() => {
    // Early-exit if no/invalid appRecId in browser
    if (isNaN(Number(recId))) return;
    setIsQuoteInfoLoading(true);

    salesService
      .getDealsQuotesForm(recId!)
      .then((response) => {
        setDealData(response);
      })
      .catch((e) => {
        toast.error('There was an error receiving deal data');
        throw e;
      });
    setIsQuoteInfoLoading(false);
  }, [recId]);

  return (
    <DealsQuotesDetailCtx.Provider
      value={{
        headerTitle,
        setHeaderTitle,
        printTargetRef,
        dealQuoteFirstLoad,
        setDealQuoteFirstLoad,
        isQuoteInfoLoading,
        dealData,
        quoteData,
        setDealData,
      }}
    >
      {children}
    </DealsQuotesDetailCtx.Provider>
  );
};

export default DealsQuotesDetailProvider;

export const useDealsQuotesDetailCtx = <T,>(selector: (state: IDealsQuotesDetailCtx) => T): T => {
  const ctx = useContext(DealsQuotesDetailCtx);
  if (!ctx) {
    throw new Error('useDealsQuotesDetailCtx must be used within DealsQuotesDetailProvider');
  }
  return selector(ctx);
};
