import { WsCompany } from '../../interfaces';

export type WsCompanyForm = CreateWsCompanyReq | UpdateWsCompanyReq;

export class CreateWsCompanyReq implements WsCompany {
  constructor(
    public address?: string,
    public city?: string,
    public companyName?: string,
    public contactEmail?: string,
    public contactName?: string,
    public contactPhone?: string,
    public county?: string,
    public fax?: string,
    public phone?: string,
    public state?: string,
    public taxId?: string,
    public zip?: string
  ) {}

  static new(newWsCompany: WsCompany): CreateWsCompanyReq {
    return new CreateWsCompanyReq(
      newWsCompany.address || undefined,
      newWsCompany.city || undefined,
      newWsCompany.companyName || undefined,
      newWsCompany.contactEmail || undefined,
      newWsCompany.contactName || undefined,
      newWsCompany.contactPhone || undefined,
      newWsCompany.county || undefined,
      newWsCompany.fax || undefined,
      newWsCompany.phone || undefined,
      newWsCompany.state || undefined,
      newWsCompany.taxId || undefined,
      newWsCompany.zip || undefined
    );
  }

  static toReq(wsCompanyForm: WsCompany, _browserWsRecId?: number): CreateWsCompanyReq {
    return CreateWsCompanyReq.new(wsCompanyForm);
  }
}

export class UpdateWsCompanyReq extends CreateWsCompanyReq {
  public recId: number;

  constructor(newWsCompany: WsCompany, recId: number) {
    super(
      newWsCompany.address || undefined,
      newWsCompany.city || undefined,
      newWsCompany.companyName || undefined,
      newWsCompany.contactEmail || undefined,
      newWsCompany.contactName || undefined,
      newWsCompany.contactPhone || undefined,
      newWsCompany.county || undefined,
      newWsCompany.fax || undefined,
      newWsCompany.phone || undefined,
      newWsCompany.state || undefined,
      newWsCompany.taxId || undefined,
      newWsCompany.zip || undefined
    );
    this.recId = recId;
  }

  static override new(newWsCompany: Omit<UpdateWsCompanyReq, 'toReq'>): UpdateWsCompanyReq {
    return new UpdateWsCompanyReq(newWsCompany, newWsCompany.recId);
  }

  static override toReq(wsCompanyForm: WsCompany, browserWsRecId?: number): UpdateWsCompanyReq {
    if (!browserWsRecId) throw new Error(`Invalid recId: ${browserWsRecId}`);

    return new UpdateWsCompanyReq(wsCompanyForm, browserWsRecId);
  }
}
