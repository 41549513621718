import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// kendo
import { Button } from '@/components/button/Button';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { Spacer } from '@/components/spacer/Spacer';
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { TextInput } from '@/components/inputs/text/TextInput';
// kendo
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// state
import { useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// services
import { accountsService } from '@/services/accountsService';
import { paymentService } from '@/services/paymentService';
// utils
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { genericRequired } from '@/utils/helpers/formValidation';
// interfaces
import {
  ChargeOffInformation,
  ReactivateChargeOffForm,
  ReactivateChargeOffPayload,
} from '@/interfaces/Accounts';
import { ColTypeCode } from '@/enums/general';
import { EmployeeField } from '@/interfaces/System';
// style
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

/** @deprecated needs refactor */
export const ReactivateChargeOff = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const compId = useAuthSelector((s) => s.compId);
  const userId = useAuthSelector((s) => s.userId);
  const accountInformation = useAccountSelector((s) => s.accountInformation);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [chargeOffInfo, setChargeOffInfo] = useState<ChargeOffInformation>();
  const [employees, setEmployees] = useState<EmployeeField[]>([]);

  const colRecId = Number(params.colRecId);
  const isLease = accountInformation?.colType === ColTypeCode.enum.LS;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ReactivateChargeOffForm>();

  const initData = async () => {
    setLoading(true);
    try {
      const chargeOffInfoRes = await accountsService.getChargeOffInformation(colRecId);
      setChargeOffInfo(chargeOffInfoRes);
      const employeesRes = await paymentService.getUsersByCompanyId(compId!);
      setEmployees(employeesRes);
      const currentEmployee = employeesRes.find((e) => e.recId === userId);
      if (currentEmployee) {
        setValue('employee', currentEmployee);
      }
    } finally {
      setLoading(false);
    }
  };

  const submit = async (data: ReactivateChargeOffForm) => {
    try {
      setSubmitting(true);
      const payload: ReactivateChargeOffPayload = {
        userId: data.employee!.recId,
        password: data.password,
        colRecId,
      };
      await accountsService.postReactivateChargeOff(payload);
      toast.success('Account reactivated');
      dispatch(getAccountInformation(colRecId));
      navigate('..');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <AccountsMainPanel
      navBarTitle={isLease ? 'Reactivate Lease' : 'Reactivate Charge-Off'}
      loading={loading}
    >
      {!chargeOffInfo ? (
        <div>Unable to load charge off information for this account</div>
      ) : (
        <form className={styles.columns} onSubmit={handleSubmit(submit)}>
          <div className={styles.column}>
            <DateInput
              readOnly
              label="Date"
              value={formatDate(new Date(), { pattern: DateFormat.DateInput })}
            />
            <CurrencyInput
              readOnly
              label="Principal Balance"
              value={chargeOffInfo.principalBalance ?? 0}
            />
            <CurrencyInput readOnly label="Misc Balance" value={chargeOffInfo.miscBalance ?? 0} />
            <CurrencyInput
              readOnly
              label="Total"
              value={(chargeOffInfo.principalBalance ?? 0) + (chargeOffInfo.miscBalance ?? 0)}
            />
            <TextInput readOnly label="Type" value={chargeOffInfo.chgOffCat ?? ''} />
            <Controller
              name="employee"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <DropdownInput
                  required
                  label="Employee"
                  data={employees}
                  dataItemKey="recId"
                  textField="shortName"
                  errors={errors.employee?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextInput
                  label="Password"
                  required
                  errors={errors.password?.message}
                  type="password"
                  {...field}
                />
              )}
            />
            <Controller
              name="note"
              control={control}
              render={({ field }) => <TextArea label="Notes" rows={3} {...field} />}
            />
          </div>
          <div className={styles.column}>
            <Spacer expand />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button label="Submit" loading={submitting} fullWidth={false} />
            </div>
          </div>
        </form>
      )}
    </AccountsMainPanel>
  );
};
