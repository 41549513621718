import { FC, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from '@/mui/components/form/MuiKendoDatePicker';
// components
import DateRangeDropdown from './DateRangeDropdown';
// state
import { ITableCtx, useTableCtx } from '../../tableState';
// interfaces
import { Filter, FilterOperators } from '@/interfaces/requests';
import { AllowedFilterKey as AllowedAcctFilterKey } from '../../accountsSubviews/default';
import { AllowedFilterKey as AllowedPmtFilterKey } from '../../pmtSubviews/default';
// utils
import { DateRangeOptionKey, dateRangeOptions, formatDateTz } from '@/general/dates';

dayjs.extend(utc);

// @todo move maps to respective definition files, combine either here, or in a separate file at view-root, or in view-info file
const filterLabelMap = new Map<AllowedAcctFilterKey | AllowedPmtFilterKey, { label: string }>([
  ['dateSold', { label: 'Date Sold' }],
  ['chgOffDate', { label: 'C/O Date' }],
  ['pmtDate', { label: 'Payment Date' }],
]);

// Set initial date range
const initDateRangeKey: DateRangeOptionKey = 'This Month-to-date';
const { beginDate, endDate } = dateRangeOptions[initDateRangeKey];

/** ### Two fields: 'date from' and 'date to'
 * @note Date range should always be set
 */
const DateFilter: FC<{ field: AllowedAcctFilterKey | AllowedPmtFilterKey }> = ({ field }) => {
  const [inputValue, setInputValue] = useState<DateRangeOptionKey>(initDateRangeKey);
  const updateDateFilter = useTableCtx((s) => s.updateDateFilter);
  const filter = useTableCtx((s) => s.filter);
  const setFilter = useTableCtx((s) => s.setFilter);
  const isDateFilterError = useTableCtx((s) => s.isDateFilterError);

  const dateFromState = filter.filter((f) => f.field === field && f.operator === '>=')[0]!;
  const dateToState = filter.filter((f) => f.field === field && f.operator === '<=')[0]!;

  // Event handlers
  const handleChange = (formType: 'from' | 'to') => (e: dayjs.Dayjs | null) => {
    setInputValue('Custom');
    if (formType === 'from') {
      // Create "Date from" filter
      const newDateFilter: Filter = {
        field,
        operator: '>=',
        value: formatDateTz(e),
      };
      const nonCurrentDateFilters: ITableCtx['filter'] = [...filter].filter(
        (f) => f.field !== field || f.operator !== '>='
      );
      const updatedFilterArr: ITableCtx['filter'] = [...nonCurrentDateFilters, newDateFilter];
      setFilter(updatedFilterArr);
    } else if (formType === 'to') {
      // Create "Date to" filter
      const newDateFilter: Filter = {
        field,
        operator: '<=',
        value: formatDateTz(endDate),
      };
      const nonCurrentDateFilters: ITableCtx['filter'] = [...filter].filter(
        (f) => f.field !== field || f.operator !== '<='
      );
      const updatedFilterArr: ITableCtx['filter'] = [...nonCurrentDateFilters, newDateFilter];
      setFilter(updatedFilterArr);
    }

    const operator: FilterOperators = formType === 'from' ? '>=' : '<=';

    const newDateFilter: Filter = { field, operator, value: formatDateTz(e!) };
    updateDateFilter(newDateFilter);
  };
  const handleUpdateFilters = (fromFilter: Filter, toFilter: Filter) => {
    const newDateFilters: ITableCtx['filter'] = [fromFilter, toFilter];
    const nonDateFilters: ITableCtx['filter'] = [...filter].filter((f) => f.field !== field);
    const updatedFilterArr: ITableCtx['filter'] = [...nonDateFilters, ...newDateFilters];
    setFilter(updatedFilterArr);
  };

  const fieldLabel = filterLabelMap.get(field)!.label;
  const dateFromValue = formatDateTz(dateFromState ? dateFromState.value : beginDate, true);
  const dateToValue = formatDateTz(dateToState ? new Date(dateToState.value) : endDate, true);

  // @todo should prevent rendering when filters don't exist

  return (
    <Grid container direction="row">
      <DatePicker
        label={`'${fieldLabel}' From`}
        value={dateFromValue}
        onChange={handleChange('from')}
        error={isDateFilterError}
      />
      <DatePicker
        label={`'${fieldLabel}' To`}
        value={dateToValue}
        onChange={handleChange('to')}
        error={isDateFilterError}
      />
      <DateRangeDropdown
        field={field}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setForm={handleUpdateFilters}
      />
    </Grid>
  );
};

export default DateFilter;
