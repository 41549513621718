import { CSSProperties, FC, ForwardedRef } from "react";
// kendo
import {
  ComboBox,
  ComboBoxHandle,
  ComboBoxBlurEvent,
  ComboBoxChangeEvent,
} from "@progress/kendo-react-all";
// interfaces
import { DropdownInputProps } from "./interfaces";
// style
import styles from "@components/inputs/dropdown/DropdownInput.module.scss";

const inputStyle: CSSProperties = { width: "100%" };

/** ###  */
const InternalDropdownInput: FC<
  DropdownInputProps & { inputRef: ForwardedRef<ComboBoxHandle>; editorId: string }
> = ({
  // @ts-ignore
  display,
  inputRef,
  editorId,
  suggest = true,
  errors,
  data,
  onChange,
  dataItemKey,
  textField,
  ...props
}) => {
  if (dataItemKey && !textField) {
    throw new Error(
      "DropdownInput: Both `dataItemKey` and `textField` are required. Missing: `textField`"
    );
  }
  if (!dataItemKey && textField) {
    throw new Error(
      "DropdownInput: Both `dataItemKey` and `textField` are required. Missing: `dataItemKey`"
    );
  }
  // Event handlers
  const handleBlur = (e: ComboBoxBlurEvent) => {
    e.nativeEvent.preventDefault();
    if (props.onBlur) props.onBlur(e);
  };

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (onChange) onChange(event);
  };

  const errorStyle = styles.input + (errors ? ` ${styles.errors}` : "");

  const isDataObjArray = dataItemKey && textField;

  const displayValue = isDataObjArray
    ? data?.find((item) => item[dataItemKey] === (props.value && props.value[dataItemKey]))
    : props.value;

  return (
    <ComboBox
      {...props}
      textField={textField}
      dataItemKey={dataItemKey}
      value={displayValue}
      defaultValue={props.defaultValue || displayValue}
      suggest={suggest}
      data={(data ?? []) as any[]}
      onBlur={handleBlur}
      onChange={handleChange}
      style={inputStyle}
      id={editorId}
      className={errorStyle}
      ref={inputRef}
    />
  );
};

export default InternalDropdownInput;
