import { FC } from "react";
// mui
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import Typography from "@mui/material/Typography";
import CommonModal from "@/mui/components/CommonModal";

const modalSx = { p: "50px", overflow: "hidden", gap: 6 };
const titleSx: SxProps = { fontSize: 31, fontWeight: 700, lineHeight: "42px" };

/** ### Simple styling wrapper */
const PaymentModal: FC<Parameters<typeof CommonModal>["0"]> = ({ containerProps, ...props }) => (
  <CommonModal {...props} containerProps={modalSx}>
    <Typography variant="h3" sx={titleSx}>
      {props.title}
    </Typography>

    {props.children}
  </CommonModal>
);

export default PaymentModal;
