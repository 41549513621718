import dayjs from "dayjs";
import { SidenoteForm } from "./forms";
import { type AppliedFieldOutput } from "@/utils/forms/useValidatedForm/interfaces";
import { type PaymentInterval } from "@/enums/payment";

function addMonths(theDate: string | Date, numMonths: number): Date {
  return dayjs(theDate).add(numMonths, "month").toDate();
}

function numDays(bDate: string | Date, eDate: string | Date): number {
  const start = dayjs(bDate).startOf("day");
  const end = dayjs(eDate).startOf("day");
  return end.diff(start, "day");
}

function compareDate(bDate: string | Date, cSign: string, eDate: string | Date): number {
  const start = dayjs(bDate);
  const end = dayjs(eDate);

  switch (cSign) {
    case ">":
      return start.isAfter(end) ? 1 : 0;
    case "<":
      return start.isBefore(end) ? 1 : 0;
    default:
      return 0;
  }
}

function getTermDays(schedule: PaymentInterval): number {
  switch (schedule) {
    case "Weekly":
      return 7;
    case "Bi-Weekly":
      return 14;
    case "Semi-Monthly":
      return 15;
    default:
      return 30;
  }
}

function getDaysInYear(schedule: PaymentInterval): number {
  switch (schedule) {
    case "Weekly":
    case "Bi-Weekly":
      return 364;
    case "Semi-Monthly":
    default:
      return 360;
  }
}

function getOddDays(schedule: PaymentInterval, bDate: string | Date, eDate: string | Date): number {
  let days = numDays(bDate, eDate);
  let units: number;
  let estimatedUnits;
  let newDate;

  switch (schedule) {
    case "Weekly":
      units = Math.floor(days / 7);
      return days - units * 7;

    case "Bi-Weekly":
      units = Math.floor(days / 14);
      return days - units * 14;

    case "Semi-Monthly":
      estimatedUnits = Math.floor(days / 15);
      newDate = addSMDays(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addSMDays(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addSMDays(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 15) {
        estimatedUnits++;
        days = 0;
      }
      return days;

    default:
      estimatedUnits = Math.floor(days / 30);
      newDate = addMonths(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addMonths(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addMonths(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 29) {
        estimatedUnits++;
        days = 0;
      }
      return days;
  }
}

function getOddUnits(
  schedule: PaymentInterval,
  bDate: string | Date,
  eDate: string | Date
): number {
  let days = numDays(bDate, eDate);
  let estimatedUnits: number;
  let newDate;

  switch (schedule) {
    case "Weekly":
      return Math.floor((days - 7) / 7);

    case "Bi-Weekly":
      return Math.floor((days - 14) / 14);

    case "Semi-Monthly":
      estimatedUnits = Math.floor(days / 15);
      newDate = addSMDays(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addSMDays(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addSMDays(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 15) {
        estimatedUnits++;
        days = 0;
      }
      return estimatedUnits - 1;

    default:
      estimatedUnits = Math.floor(days / 30);
      newDate = addMonths(eDate, -estimatedUnits);
      while (compareDate(newDate, ">", bDate)) {
        estimatedUnits++;
        newDate = addMonths(eDate, -estimatedUnits);
      }
      estimatedUnits--;
      newDate = addMonths(eDate, -estimatedUnits);
      days = numDays(bDate, newDate);
      if (days > 29) {
        estimatedUnits++;
        days = 0;
      }
      return estimatedUnits - 1;
  }
}

function pmtTerm(principal: number, interestRate: number, payment: number): number {
  if (interestRate === 0) return principal / payment;

  return -Math.log(1 - (interestRate * principal) / payment) / Math.log(1 + interestRate);
}

function getFinalDate(begDate: string | Date, sched: PaymentInterval, nnpp: number): Date {
  switch (sched) {
    case "Weekly":
      return dayjs(begDate)
        .add(nnpp * 7, "day")
        .toDate();

    case "Bi-Weekly":
      return dayjs(begDate)
        .add(nnpp * 14, "day")
        .toDate();

    case "Semi-Monthly":
      const eo = nnpp % 2;
      const theDate = dayjs(begDate);
      let days = 0;

      if (eo > 0) {
        days = theDate.date() < 16 ? 15 : -15;
      }

      if (theDate.date() < 16) {
        return dayjs(addMonths(begDate, Math.floor(nnpp / 2)))
          .add(days, "day")
          .toDate();
      } else {
        return dayjs(addMonths(begDate, Math.floor((nnpp + 1) / 2)))
          .add(days, "day")
          .toDate();
      }
      break;

    default: // Monthly
      return dayjs(begDate).add(nnpp, "month").toDate();
  }
}

function addSMDays(theDate: string | Date, numUnits: number): Date {
  let smDate = dayjs(theDate);
  const smDay = smDate.date();
  let sDate: dayjs.Dayjs;

  if (numUnits % 2 === 0) {
    sDate = smDate;
    numUnits /= 2;
  } else {
    if (smDay > 15) {
      sDate = smDate.subtract(15, "day");
      numUnits = Math.floor(numUnits / 2) + 1;
    } else {
      sDate = smDate.add(15, "day");
      numUnits = Math.floor(numUnits / 2);
    }
  }

  let newDate = sDate.add(numUnits, "month");
  return newDate.toDate();
}

export const calcPlanLong = (
  xnum: number,
  amt: number,
  dd: string | Date,
  sched: PaymentInterval | "Final"
): string => {
  const formatted = dayjs(dd).format("MMM D, YYYY");

  switch (xnum) {
    case 0:
      return "";
    case 1:
      if (sched === "Final") {
        return `Final Payment of ${formatDollar(amt)} Due ${formatted}`;
      } else {
        return `1 Payment of ${formatDollar(amt)} Due ${formatted}`;
      }
    default:
      return `${xnum} Payments of ${formatDollar(amt)} ${sched} Beginning ${formatted}`;
  }
};

function formatDollar(num: number): string {
  const p = num.toFixed(2).split(".");
  return (
    "$" +
    p[0]!
      .split("")
      .reverse()
      .reduce((acc, digit, i) => {
        return digit + (i && i % 3 === 0 ? "," : "") + acc;
      }, "") +
    "." +
    p[1]
  );
}

function calcNextDueDate(
  nextDueDate: string | Date,
  saleDate: string | Date,
  schedule: PaymentInterval
): Date {
  const convertedDate = dayjs(saleDate);

  if (
    dayjs(nextDueDate).isBefore(convertedDate, "day") ||
    dayjs(nextDueDate).isSame(convertedDate, "day")
  ) {
    switch (schedule) {
      case "Weekly":
        return convertedDate.add(7, "day").toDate();

      case "Bi-Weekly":
        return convertedDate.add(14, "day").toDate();

      case "Semi-Monthly":
        if (convertedDate.date() > 15) {
          return convertedDate.add(1, "month").subtract(15, "day").toDate();
        } else {
          return convertedDate.add(15, "day").toDate();
        }

      default:
        return convertedDate.add(1, "month").toDate();
    }
  }

  return dayjs(nextDueDate).toDate(); // return the original date if the condition is not met
}
type SnForm = AppliedFieldOutput<typeof SidenoteForm>;
export const useSNReCalc = (form: SnForm, isNew: boolean) => {
  const saledate = dayjs().toDate();
  const nextDueDate = form.nextDueDate.toDate();
  const { pmtSched } = form;
  let numpmts = Math.max(0, form.numPmts ?? 0); // 0 or value from db
  const intrate = Math.max(0, form.intRate); // 0 or input
  const firstpmtdue = calcNextDueDate(nextDueDate, saledate, pmtSched);

  // prettier-ignore
  const { addOn1, addOn2, addOn3, addOn4, addOn5, addOn6, addOn7, addOn8, addOn9, addOn10 } = form;
  const tsp =
    addOn1 + addOn2 + addOn3 + addOn4 + addOn5 + addOn6 + addOn7 + addOn8 + addOn9 + addOn10;

  const cod = Math.min(Math.max(0, form.snDown), tsp);
  const amountFinanced = Math.max(0, tsp - cod);
  let pmtamt = form.pmtAmt <= 0 ? amountFinanced : form.pmtAmt;

  let final;
  let finalduedate;

  var edate = firstpmtdue;
  var bdate = saledate;
  var odds = getOddDays(pmtSched, bdate, edate);
  var units = getOddUnits(pmtSched, bdate, edate);
  var tdays = getTermDays(pmtSched);
  var Daysinyear = getDaysInYear(pmtSched);
  var perrate = intrate / 100 / (Daysinyear / tdays);
  let Days2First = numDays(edate, bdate);
  let ii = perrate;
  let ii1 = 1 + ii;
  let irate = intrate / 100;
  let finaldue;

  if (intrate === 0) {
    numpmts = Math.floor(amountFinanced / pmtamt);
    final = amountFinanced - pmtamt * numpmts;

    if (final === 0) {
      finaldue = getFinalDate(firstpmtdue, pmtSched, numpmts - 1);
    } else {
      finaldue = getFinalDate(firstpmtdue, pmtSched, numpmts);
    }

    final = Math.round(final * 100) / 100;
    finalduedate = finaldue.toLocaleDateString();
  } else {
    if (odds < 0) {
      var FirstBal = amountFinanced * (1 + (Days2First * irate) / 365) - pmtamt;

      numpmts = Math.floor(pmtTerm(FirstBal, ii, pmtamt));

      var iiN = Math.pow(ii1, numpmts);
      final = FirstBal * iiN - (pmtamt / ii) * (iiN - 1);

      finaldue = getFinalDate(firstpmtdue, pmtSched, numpmts + 1);
      numpmts = numpmts + 1;
    } else {
      var uprin = amountFinanced * Math.pow(1 + ii, units);
      var FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
      numpmts = Math.floor(pmtTerm(FirstBal, ii, pmtamt));
      var iiN = Math.pow(ii1, numpmts);
      final =
        FirstBal * iiN -
        (pmtamt / ii) * (iiN - 1) +
        (FirstBal * iiN - (pmtamt / ii) * (iiN - 1)) * ii;
      finaldue = getFinalDate(firstpmtdue, pmtSched, numpmts);
    }

    final = Math.round(final * 100) / 100;
    finalduedate = finaldue.toLocaleDateString();
  }
  const financeCharge = intrate === 0 ? 0 : numpmts * pmtamt + final - amountFinanced;
  const plan1: string = isNew
    ? calcPlanLong(numpmts, pmtamt, firstpmtdue, pmtSched)
    : form.pmtDue.isValid()
    ? calcPlanLong(form.numPmts, form.pmtAmt, form.pmtDue.format("YYYY-MM-DD"), form.pmtSched)
    : ""; // @note Default value is empty string

  const plan2: string = isNew
    ? final > 0
      ? calcPlanLong(1, final, finalduedate, "Final")
      : ""
    : form.pmtDue.isValid() && form.finalAmt > 0
    ? calcPlanLong(1, form.finalAmt, form.finalDate.format("YYYY-MM-DD"), "Final")
    : ""; // @note Default value is empty string

  return {
    numpmts,
    pmtamt,
    finalduedate: dayjs(finalduedate),
    tsp,
    amountFinanced,
    final,
    financeCharge,
    plan1,
    plan2,
    firstpmtdue,
  };
};
