import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import dayjs from "dayjs";
// state
import { useAppDispatch } from "@/store/store";
import { useAuthSelector } from "@/features/auth/authSlice";
import { getSaleData } from "@/features/Sales/salesActionCreator";
import { useSalesSelector } from "@/features/Sales/salesSlice";
// utils
import { salesService } from "@/services/salesService";
// interfaces
import { Declined } from "@/interfaces/Sales";

/** @deprecated convert to context */
const useDeclineNotice = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const newSale = params.id === "newSale";
  const appRecId = Number(params.id);
  const { saleData } = useSalesSelector((s) => s);
  const { userId, userName } = useAuthSelector((s) => s);
  const [getDeclineLoading, setGetDeclineLoading] = useState(false);
  const [updateDeclineLoading, setUpdateDeclineLoading] = useState(false);
  const [_printDeclineLoading, setPrintDeclineLoading] = useState(false);
  const [unDeclineLoading, setUnDeclineLoading] = useState(false);
  const defaultFormValues = {
    status: saleData.sale?.appstatus,
    dateDeclined: dayjs(saleData.sale?.decdate || "").format("YYYY-MM-DD"),
    declinedBy: saleData.sale?.declineByName,
    creditApplication1: false,
    creditApplication2: false,
    creditApplication3: false,
    creditApplication4: false,
    income1: false,
    income2: false,
    income3: false,
    income4: false,
    income5: false,
    residence1: false,
    residence2: false,
    residence3: false,
    residence4: false,
    employment1: false,
    employment2: false,
    employment3: false,
    creditFile1: false,
    creditFile2: false,
    creditFile3: false,
    creditFile4: false,
    creditFile5: false,
    creditFile6: false,
    creditFile7: false,
    other: "",
  };
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm({ defaultValues: defaultFormValues });

  const resetForm = (res: Declined) => {
    reset({
      status: saleData.sale?.appstatus,
      dateDeclined: dayjs(saleData.sale?.decdate || "").format("YYYY-MM-DD"),
      declinedBy: saleData.sale?.declineByName,
      creditApplication1: res.reF1,
      creditApplication2: res.reF2,
      creditApplication3: res.reF3,
      creditApplication4: res.reF4,
      income1: res.i1,
      income2: res.i2,
      income3: res.i3,
      income4: res.i4,
      income5: res.i5,
      residence1: res.reS1,
      residence2: res.reS2,
      residence3: res.reS3,
      residence4: res.reS4,
      employment1: res.emP1,
      employment2: res.emP2,
      employment3: res.emP3,
      creditFile1: res.c1,
      creditFile2: res.c2,
      creditFile3: res.c3,
      creditFile4: res.c4,
      creditFile5: res.c5,
      creditFile6: res.c6,
      creditFile7: res.c7,
      other: res.otheR1,
    });
  };

  const updateDeclineNotice = (data: typeof defaultFormValues) => {
    setUpdateDeclineLoading(true);
    salesService
      .updateDeclined({
        appRecID: appRecId,
        reF1: data.creditApplication1,
        reF2: data.creditApplication2,
        reF3: data.creditApplication3,
        reF4: data.creditApplication4,
        i1: data.income1,
        i2: data.income2,
        i3: data.income3,
        i4: data.income4,
        i5: data.income5,
        reS1: data.residence1,
        reS2: data.residence2,
        reS3: data.residence3,
        reS4: data.residence4,
        emP1: data.employment1,
        emP2: data.employment2,
        emP3: data.employment3,
        c1: data.creditFile1,
        c2: data.creditFile2,
        c3: data.creditFile3,
        c4: data.creditFile4,
        c5: data.creditFile5,
        c6: data.creditFile6,
        c7: data.creditFile7,
        otheR1: data.other,
      })
      .then(() => toast.success("Decline Notice Updated"))
      .catch(() => toast.error("Unable to update decline notice"))
      .finally(() => setUpdateDeclineLoading(false));
  };

  useEffect(() => {
    if (newSale) return;
    setGetDeclineLoading(true);
    salesService
      .getDeclined(appRecId)
      .then((res) => resetForm(res))
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) return;
        toast.error("Unable to retrieve decline record");
      })
      .finally(() => setGetDeclineLoading(false));
  }, []);

  const printDeclineNotice = () => {
    setPrintDeclineLoading(true);
    salesService
      .printSalesDoc({ formIds: [427], appRecId: appRecId })
      .then((res) => window.open(res))
      .catch(() => toast.error("Unable to print decline notice"))
      .finally(() => setPrintDeclineLoading(false));
  };

  const unDeclineApplication = () => {
    setUnDeclineLoading(true);
    salesService
      .declineApplication({
        appRecId: appRecId,
        status: "Pending",
        dateDeclined: new Date(),
        declinedById: userId!,
        declinedByName: userName!,
      })
      .then(() => dispatch(getSaleData(appRecId)))
      .catch(() => toast.error("Unable to undecline application"))
      .finally(() => setUnDeclineLoading(false));
  };

  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  return {
    control,
    getDeclineLoading,
    handleSubmit,
    isDirty,
    printDeclineNotice,
    unDeclineApplication,
    unDeclineLoading,
    updateDeclineLoading,
    updateDeclineNotice,
  };
};

/** @deprecated convert to context */
export default useDeclineNotice;
