import { config } from '@/config';
import * as Sentry from '@sentry/react';

export const sentryOptions: Sentry.BrowserOptions = {
  dsn: 'https://b6464aa7678653dae189a8585dd14428@o4505353181069312.ingest.sentry.io/4505999495593984',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [config.apiUrl],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.01, // Transaction monitoring. 1.0 === 100%
  // Session Replay
  replaysSessionSampleRate: 0.01, // 1.0 === Sample 100% of sessions
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
