import { FC } from 'react';
// components
import ViewSelector from './ViewSelector';
// state
import { useAccountsViewCtx } from './accountsViewState';
// style
import MuiViewHeader from '@/mui/components/layout/ViewHeader';

const ViewHeader: FC = () => {
  const dbCt = useAccountsViewCtx((s) => s.dbCt);
  const isLoading = useAccountsViewCtx((s) => s.isLoading);
  const { text } = useAccountsViewCtx((s) => s.subviewInfo);

  return (
    <MuiViewHeader
      primaryText="Accounts"
      secondaryText={text}
      isLoading={isLoading}
      dbCt={dbCt}
      viewSelector={<ViewSelector />}
    />
  );
};

export default ViewHeader;
