import { FC } from "react";
import { CircleSpinner } from "react-spinners-kit";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";

const LoadingSpinner: FC = () => (
  <Grid>
    <CircleSpinner color="#3299df" size={50} />
  </Grid>
);

const OpenEdgeField: FC<{ fieldId: string; label: string }> = ({ fieldId, label }) => (
  <Grid container direction="row">
    <Grid container justifyContent="center" width="100%" fontSize={14}>
      {label}
    </Grid>
    <Grid container justifyContent="center" width="100%" height="47.5px" id={fieldId} />
  </Grid>
);

/** ###  */
const OpenEdgeLayout: FC<{ isRefreshing: boolean; isPostReqLoading: boolean }> = ({
  isRefreshing,
  isPostReqLoading,
}) => {
  if (isRefreshing) return <LoadingSpinner />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight="400px"
      fontFamily="Nunito, sans-serif"
      // @note needed for OpenEdge form
      id="openEdgeElements"
    >
      <OpenEdgeField label="Card Number" fieldId="card-number" />
      <OpenEdgeField label="Expiration Date" fieldId="card-expiration" />
      <OpenEdgeField label="CVV" fieldId="card-cvv" />

      <Spacer size={20} />

      {isPostReqLoading ? (
        <Loader size="small" />
      ) : (
        <Grid
          container
          id="submit"
          sx={{ height: "45.5px", width: "100%", justifyContent: "center" }}
        ></Grid>
      )}
    </Grid>
  );
};

export default OpenEdgeLayout;
