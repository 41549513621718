import { FC, useEffect, useState } from 'react';
// mui
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// kendo
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
// state
import { useTableCtx } from './TableProvider';
// interfaces
import { AppStatusFilterKey } from '../subviewConfigs/applications/dataModelConfig';

/** ###  */
const CategoryFilterOption: FC<{ label: string; value: AppStatusFilterKey }> = ({
  label,
  value,
}) => {
  const isChecked = useTableCtx((s) => s.activeAppStatus.includes(value));
  const addAppStatusOption = useTableCtx((s) => s.addAppStatusOption);
  const removeAppStatusOption = useTableCtx((s) => s.removeAppStatusOption);

  // Event handlers
  // Remove filter if checked, else add
  const handleCheck = () => (isChecked ? removeAppStatusOption(value) : addAppStatusOption(value));

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleCheck} role={undefined} dense>
        <ListItemIcon>
          <Checkbox checked={isChecked} edge="start" disableRipple sx={{ py: 0 }} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            letterSpacing: '-0.20px',
            lineHeight: '20px',
            fontSize: '14px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

// @todo move to general location
// @todo add types, making these types generic would help
const AppStatusFilter: FC<{
  filterLabel: string;
  optionsMap: Map<AppStatusFilterKey, string>;
}> = ({ filterLabel, optionsMap }) => {
  const [isOpen, setIsOpen] = useState(false);
  const resetAppStatusFilter = useTableCtx((s) => s.resetAppStatusFilter);
  useEffect(() => {
    resetAppStatusFilter();
    return () => {
      resetAppStatusFilter();
    };
  }, []);

  // Build options
  const optionElems: JSX.Element[] = [];
  optionsMap.forEach((optLabel, optValue) => {
    optionElems.push(
      <CategoryFilterOption value={optValue} label={optLabel} key={`c-f-o${optValue}`} />
    );
  });

  return (
    <DropDownButton
      label={`${filterLabel} Filter ↓`}
      open={isOpen}
      setOpen={setIsOpen}
      buttonProps={{ secondary: true }}
    >
      <List component={Paper}>{optionElems}</List>
    </DropDownButton>
  );
};

export default AppStatusFilter;
