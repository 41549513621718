import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Grid2Props from '@mui/material/Unstable_Grid2/Grid2Props';
import Paper from '@mui/material/Paper';

/** ###  */
const WsFormSection: FC<Grid2Props.Grid2Props> = ({ children, sx: sxProps, ...props }) => {
  return (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      flex={1}
      px="32px"
      py="20px"
      pb={2}
      borderRadius="10px"
      sx={{ backgroundColor: 'rgb(239, 240, 244)', ...sxProps }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default WsFormSection;
