import React, { forwardRef, useMemo } from "react";
import styles from "./NumberInput.module.scss";
import { Label } from "@progress/kendo-react-all";
import textStyles from "@components/text/Text.module.scss";
import {
  NumericTextBox,
  NumericTextBoxFocusEvent,
  NumericTextBoxHandle,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";

export interface NumberInputProps extends NumericTextBoxProps {
  label?: string;
  errors?: string | boolean;
  value?: number | null;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  format?: string;
  inputStyles?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  rightAlignInput?: boolean;
  isPercentage?: boolean; // New prop for percentage input
  isPercentageLarge?: boolean; // New prop for percentage input
  hidden?: boolean;
}

export const NumberInput = forwardRef<NumericTextBoxHandle, NumberInputProps>((props, ref) => {
  const {
    name,
    label,
    errors,
    value,
    readOnly,
    required,
    spinners = false,
    format = props.isPercentage ? (props.isPercentageLarge ? '0.00"%"' : "p2") : "n",
    boldLabel = true,
    horizontalLabel = true,
    inputStyles,
    rightAlignInput,
    containerStyle,
    ...rest
  } = props;

  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  const inputClassName = rightAlignInput ? styles.rightAlignInput : styles.input;

  const onFocus = (e: NumericTextBoxFocusEvent) => {
    setTimeout(() => {
      if (e.target.element) {
        e.target.element.select();
      }
    }, 0);
  };

  const Input = (
    <NumericTextBox
      id={editorId}
      readOnly={readOnly}
      className={errors ? `${styles.errors} ${inputClassName}` : inputClassName}
      value={value}
      spinners={spinners}
      format={format}
      style={inputStyles}
      ref={ref}
      onFocus={onFocus}
      {...rest}
    />
  );

  return (
    <div className={styles.textContainer} style={containerStyle} hidden={props.hidden}>
      {label ? (
        horizontalLabel ? (
          <div className={textStyles.row}>
            <Label
              editorId={editorId}
              className={textStyles.label}
              style={{ fontWeight: boldLabel ? 700 : 400 }}
            >{`${label} ${required ? "*" : ""}`}</Label>
            {Input}
          </div>
        ) : (
          <Label
            editorId={editorId}
            className={textStyles.label}
            style={{ fontWeight: boldLabel ? 700 : 400 }}
          >
            {`${label} ${required ? "*" : ""}`}
            {Input}
          </Label>
        )
      ) : (
        <>{Input}</>
      )}
      {!!errors && typeof errors === "string" && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
