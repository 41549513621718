import { Switch, SwitchProps } from '@/components';
import styles from './CollectionsSwitch.module.scss';

interface CollectionsSwitchProps extends SwitchProps {
  errors: string | undefined;
}

export const CollectionsSwitch: React.FC<CollectionsSwitchProps> = (props) => {
  const { ...rest } = props;

  const S = props.errors ? `${styles.error} ${styles.collectionSwitch}` : styles.switch;

  return (
    <div className={S}>
      <Switch size={'small'} trackRounded={'full'} thumbRounded={'full'} {...rest} />
    </div>
  );
};
