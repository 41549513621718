import { useMemo } from 'react';
// kendo
import { Loader } from '@progress/kendo-react-all';
// components
import FinanceTerm from './financeTerm/FinanceTerm';
import { LeaseTerm } from './leaseTerm/LeaseTerm';
import  CashTerm  from './cashTerm/CashTerm';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
// interfaces
import { SaleType } from '@/enums';
// style
import salesTabStyles from '../SalesTab.module.scss';

export const Terms = () => {
  const { saleData, saleDataLoading } = useSalesSelector((s) => s);

  const { saletype } = saleData.sale ?? {};

  const RenderedTerm = useMemo(() => {
    if (!saletype && saleDataLoading) {
      return (
        <div className={salesTabStyles.loader}>
          <Loader size="large" />
        </div>
      );
    } else if (saletype === SaleType.Lease) {
      return <LeaseTerm />;
    } else if (saletype === SaleType.Finance) {
      return <FinanceTerm />;
    } else if (saletype === SaleType.Cash) {
      return <CashTerm />;
    } else {
      return <div>Terms page not finished yet</div>;
    }
  }, [saletype, saleDataLoading]);

  return <div className={salesTabStyles.container}>{RenderedTerm}</div>;
};
