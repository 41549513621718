import dayjs from "dayjs";
// validation
import { PaymentMethod } from "@/enums/payment";
import { ExternalFields } from "../formValidation";
// utils
// interfaces
import { IPaymentProviderConfigRes, PaymentDataRes } from "@/interfaces/payment";
import { PmtFieldValues } from "./interfaces";

type PmtFormCalc = Pick<
  PmtFieldValues,
  | "paidBy"
  | "carPmt"
  | "totalPayment"
  | "totalReceived"
  | "lcPaid"
  | "lcWaived"
  | "provider"
  | "waiveConvFee"
>;

/** @toReplace `cpiDueDate` */
export const calcCpiDueDate = (
  paymentData: PaymentDataRes | null,
  cpiPayment: number | null
): string | null => {
  if (!paymentData || !cpiPayment || cpiPayment < 0) return null;

  const { cpiRate, cpiSchedule, cpiTotalPaid } = paymentData;
  let cpiDueDate = dayjs(paymentData.cpiFirstDueDate).utc();

  if (cpiRate === null || cpiSchedule === null || cpiTotalPaid === null || !cpiDueDate.isValid())
    return null;

  const numPayments = Math.max(
    0,
    Math.floor((cpiTotalPaid * 100 + cpiPayment * 100) / (cpiRate * 100))
  );
  const cpiUnits = numPayments - 1;

  if (cpiSchedule === "Weekly") {
    cpiDueDate = cpiDueDate.add(cpiUnits, "w");
  } else if (cpiSchedule === "Bi-Weekly") {
    cpiDueDate = cpiDueDate.add(cpiUnits * 2, "w");
  } else if (cpiSchedule === "Semi-Monthly") {
    let returnDate = cpiDueDate;
    let numMonths = 0;

    if (cpiUnits - 2 * Math.floor(cpiUnits / 2) === 0) {
      numMonths = Math.floor(cpiUnits / 2);
    } else {
      const isAfterThe15th = cpiDueDate.get("D") > 15;
      if (isAfterThe15th) {
        returnDate = returnDate.subtract(15, "day");
        numMonths = 1 + Math.floor(cpiUnits / 2);
      } else {
        returnDate = returnDate.add(15, "day");
        numMonths = Math.floor(cpiUnits / 2);
      }
    }

    return returnDate.add(numMonths, "month").format("MM-DD-YYYY");
  } else if (cpiSchedule === "Monthly") {
    return cpiDueDate.add(cpiUnits, "M").format("MM-DD-YYYY");
  }

  return cpiDueDate.format("MM-DD-YYYY");
};

type CalcCcAch = Parameters<typeof calcCcAch>[0];
/** @toReplace `isCc`/`isAch`/`isCcOrAch` */
export const calcCcAch = (form: Pick<PmtFormCalc, "paidBy">) => {
  const paidBy = form.paidBy;

  const isCc = paidBy === PaymentMethod.enum["Credit Card"];
  const isAch = paidBy === PaymentMethod.enum.ACH;
  return { isCc, isAch, isCcOrAch: isCc || isAch };
};

/** `totalPaid` */
export const calcTotalPaid = (form: PmtFormCalc, external: { isPrincipalOnly: boolean }) =>
  external.isPrincipalOnly ? form.carPmt : form.totalPayment;

/** `change`/`changeDue`
 * @deprecated Remove and calculate on backend. This is derived from fields: [form.paidBy, form.totalReceived, form.totalPayment, form.isPrincipalOnly, form.carPmt].
 */
export const calcChangeDue = (
  form: PmtFormCalc & Parameters<typeof calcTotalPaid>[0] & CalcCcAch,
  external: { isPrincipalOnly: boolean }
) => {
  const totalPaid = calcTotalPaid(form, external);
  const { isCcOrAch } = calcCcAch(form);

  // Conditionals
  const isTotRecvGtTotPmt = form.totalReceived > form.totalPayment;
  const shouldReturnChange = !isCcOrAch && isTotRecvGtTotPmt;

  return shouldReturnChange ? form.totalReceived - totalPaid : 0;
};

/** `lcOwed` */
export const calcLcOwed = (form: PmtFormCalc & Pick<ExternalFields, "lcDue">) =>
  form.lcDue - (form.lcPaid ?? 0) - (form.lcWaived ?? 0);

/** @toReplace `convFee` - @todo add cliq fee */
export const calcConvFee = (
  form: PmtFormCalc,
  /** orig. field name: `paymentData` to be renamed to `paymentConfig`/`pmtConfig` */
  pmtConfig:
    | Pick<
        PaymentDataRes,
        | "achConvenienceFee"
        | "convenienceFee"
        | "repayTestMode"
        | "repayConvFeeTest"
        | "repayConvFeeProd"
      >
    | null
    | undefined
): number | null => {
  const { isAch, isCcOrAch } = calcCcAch(form);

  if (!pmtConfig || !form.provider || !form.paidBy) return null;
  if (form.waiveConvFee || !isCcOrAch) return 0;

  if (form.provider === "OpenEdge" || form.provider === "Cliq") {
    return isAch ? pmtConfig.achConvenienceFee : pmtConfig.convenienceFee;
  }
  if (form.provider === "REPAY") {
    return pmtConfig.repayTestMode ? pmtConfig.repayConvFeeTest : pmtConfig.repayConvFeeProd;
  }

  return null;
};

export const getConvenienceFeeByProviderMisc = (
  form: PmtFormCalc,
  providerData: IPaymentProviderConfigRes | null | undefined
): number | null => {
  const { isAch, isCcOrAch } = calcCcAch(form);
  if (!form.provider || !form.paidBy) return null;
  if (form.waiveConvFee || !isCcOrAch) return 0;

  if (form.provider === "OpenEdge" || form.provider === "Cliq") {
    if (isAch) return providerData?.openEdgeDmsAchConvFee ?? null;
    if (isCcOrAch) return providerData?.openEdgeDmsCcConvFee ?? null;
  }
  if (form.provider === "REPAY") {
    return (
      (providerData?.repayTestMode
        ? providerData?.repayConvFeeTest
        : providerData?.repayConvFeeProd) ?? null
    );
  }
  return null;
};
