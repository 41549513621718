import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const TotalPmtField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("totalPayment"));
  const external = usePmtFormCtx((s) => s.formState.config.external);
  const formState = usePmtFormCtx((s) => s.formState);
  const isPrinOnly = usePmtFormCtx((s) => s.formState.config.external.isPrincipalOnly);

  const totalPaymentLabel = "Total Payment";
  const miscPaymentLabel = `Amount ${formState.form.carPmt >= 0 ? "(Pay In)" : "(Pay Out)"}`;
  const finalTotalLabel = external.isMiscPmt ? miscPaymentLabel : totalPaymentLabel;

  // @todo move to `registerOn` logic
  if (isPrinOnly) return <></>;

  return (
    <CurrencyInput
      label={finalTotalLabel}
      required
      {...fieldProps}
      allowNegative={external.isMiscPmt}
    />
  );
};

export default TotalPmtField;
