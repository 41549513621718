import { FC, createRef, useState } from 'react';
// kendo
import { Editor, EditorUtils, Tooltip } from '@progress/kendo-react-all';
import { Button, DropDownButton } from '@/components';
import { Icons } from '@/components/icons';
// components
import {
  TextEditor,
  setEmailOutput,
} from '../../features/collections/components/textEditor/TextEditor';
// interfaces
import { DynamicData, EmailTemplate } from '@/services/collectionsService';
// style
import styles from './EmailEditor.module.scss';

const dropDownButtonIconStyle = (flip: boolean) => {
  return {
    transform: flip ? 'scaleY(-1)' : 'scaleY(1)',
    transition: 'transform 150ms ease',
  };
};

const EmailEditor: FC<{
  dynamicData: DynamicData[];
  emailTemplate: EmailTemplate[];
  emailSubject: string;
  sending?: boolean;

  sendEmail: (emailSubject: string, emailMessage: string) => void;
  closeEmail: () => void;
}> = ({ emailSubject, dynamicData, emailTemplate, sendEmail, closeEmail, sending = false }) => {
  const emailEditor = createRef<Editor>();

  const [emailMessage, setEmailMessage] = useState('');
  const [showVariables, setShowVariables] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);

  const handleEmailDynamicDataClick = (dd: DynamicData) => {
    let html = EditorUtils.getHtml(emailEditor.current!.view!.state);
    html = html.slice(0, -4);
    html = html + dd.code + '</p>';

    setEmailOutput(html, setEmailMessage);
    EditorUtils.setHtml(emailEditor.current!.view!, html);
  };

  const handleEmailTemplateClick = (e: EmailTemplate) => {
    const html = `<p>${e.body}</p>`;
    EditorUtils.setHtml(emailEditor.current!.view!, html);

    setEmailOutput(html, setEmailMessage);
  };

  const handleSendEmail = (emailSubject: string) => {
    const html = EditorUtils.getHtml(emailEditor.current!.view!.state);

    const msg = setEmailOutput(html, setEmailMessage);

    msg && sendEmail(emailSubject, msg);
  };

  return (
    <div className={styles.emailEditor}>
      <div>
        <div className={styles.emailAreaHeader}>
          <DropDownButton
            label={
              <div className={styles.DDBTitle}>
                Dynamic Data
                <div style={dropDownButtonIconStyle(showVariables)}>
                  <Icons.DownArrow />
                </div>
              </div>
            }
            open={showVariables}
            setOpen={setShowVariables}
            outsideClickClassExceptions={['dontClose']}
          >
            <div className={styles.templateDropDown}>
              {dynamicData?.map((d) => {
                return (
                  <div className="dontClose" onClick={() => handleEmailDynamicDataClick(d)}>
                    <Tooltip
                      content={() => <DynamicDataToolTip data={d} />}
                      position="left"
                      anchorElement="target"
                    >
                      <div className={'dontClose'} title={d.code}>
                        {d.code}
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </DropDownButton>

          <DropDownButton
            label={
              <div className={styles.DDBTitle}>
                Templates
                <div style={dropDownButtonIconStyle(showTemplate)}>
                  <Icons.DownArrow />
                </div>
              </div>
            }
            open={showTemplate}
            setOpen={setShowTemplate}
            outsideClickClassExceptions={['dontClose']}
          >
            <div className={styles.templateDropDown}>
              {emailTemplate.map((t) => {
                return (
                  <div className="dontClose" onClick={() => handleEmailTemplateClick(t)}>
                    <div className="dontClose" title={t.name}>
                      {t.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </DropDownButton>
        </div>
        <TextEditor editor={emailEditor} dynamicData={dynamicData!} setValue={setEmailMessage} />
      </div>
      <div className={styles.buttonRow}>
        <Button label={'Cancel'} onClick={() => closeEmail()} disabled={sending} />
        <Button label={'Send'} onClick={() => handleSendEmail(emailSubject)} loading={sending} />
      </div>
    </div>
  );
};

const DynamicDataToolTip = (props: { data: DynamicData }) => {
  const { data } = props;

  return (
    <div>
      <div>
        <div>
          <strong>Code: </strong>
          {data.code}
        </div>
        <div>
          <strong>Sample: </strong>
          {data.sample}
        </div>
        <div>
          <strong>Description: </strong>
          {data.description}
        </div>
      </div>
    </div>
  );
};

export default EmailEditor;
