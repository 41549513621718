import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
// components
import { Button } from '@/components';
// utils
import { useReportsViewCtx } from '../state/view';
import { useERSContext } from './state';
import { reportsService } from '@/services/reportsService';
// style
import styles from './EmailReportsSchedules.module.scss';

/** ### Layout and logic for buttons which make CRUD requests on the\
 * `Reports/emailReportSchedules` endpoint
 */
const ModalSubmitButtons: FC = () => {
  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const { isEdit, setHttpVerb, selectedERS, removeERS, setSelectedERS } = useERSContext();
  const { formState, reset: resetForm } = useFormContext();
  const { isValid, isDirty } = formState;

  const buttonMsg = isEdit ? 'Update schedule' : 'Create new schedule';

  const handleDelete = async () => {
    try {
      const res = await reportsService.deleteEmailReportSchedule(selectedERS!.recId);

      if (res.status !== 204) {
        toast.error(`Incorrect response: ${res.status} ${res.statusText}`);
        console.error('ERROR - DELETE request:', selectedERS!.recId);
        throw new Error(JSON.stringify(res));
      }

      removeERS(selectedERS!.recId);
      toast.success('Schedule deleted');
      setIsModalOpen(false);
      setSelectedERS(null);
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.submitButtonsContainer}>
      <Button
        label={buttonMsg}
        onClick={() => setHttpVerb(isEdit ? 'PUT' : 'POST')}
        type="submit"
        disabled={!isValid || !isDirty}
      />
      {isEdit && (
        <Button
          label="Delete schedule"
          onClick={handleDelete}
          style={{ backgroundColor: 'red', borderColor: 'red' }}
        />
      )}
    </div>
  );
};

export default ModalSubmitButtons;
