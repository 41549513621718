import { Button } from '@/components';
import { FC, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useOutForRepoCtx } from './OutForRepoProvider';
import { salesService } from '@/services/salesService';

export type PrintType = 'Internal' | 'External';

interface PrintButtonProps {
  isDisabled: boolean;
}

export const PrintButton: FC<PrintButtonProps> = ({ isDisabled }) => {
  const activeRepoRow = useOutForRepoCtx((s) => s.activeRepoRow);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);
  const [externalLoading, setExternalLoading] = useState<boolean>(false);

  const handlePrintInternal = async (appRecId: number) => {
    setInternalLoading(true);
    let formIdInternal: number;
    if (activeRepoRow?.colType == 'FS') {
      formIdInternal = activeRepoRow.repoOrderIntIdR ? activeRepoRow.repoOrderIntIdR : 314;
      const internalFsDoc = await salesService.printSalesDoc({
        formIds: [formIdInternal],
        appRecId,
      });
      window.open(internalFsDoc);
      setInternalLoading(false);
    } else if (activeRepoRow?.colType == 'LS') {
      formIdInternal = activeRepoRow.repoOrderIntIdL ? activeRepoRow.repoOrderIntIdL : 312;
      const internalLsDoc = await salesService.printSalesDoc({
        formIds: [formIdInternal],
        appRecId,
      });
      window.open(internalLsDoc);
      setInternalLoading(false);
    }
  };

  const handlePrintExternal = async (appRecId: number) => {
    setExternalLoading(true);
    let formIdExternal: number;
    if (activeRepoRow?.colType == 'FS') {
      formIdExternal = activeRepoRow.repoOrderExtIdR ? activeRepoRow.repoOrderExtIdR : 315;
      const externalFsDoc = await salesService.printSalesDoc({
        formIds: [formIdExternal],
        appRecId,
      });
      window.open(externalFsDoc);
      setExternalLoading(false);
    } else if (activeRepoRow?.colType == 'LS') {
      formIdExternal = activeRepoRow.repoOrderExtIdL ? activeRepoRow.repoOrderExtIdL : 313;
      const externalLsDoc = await salesService.printSalesDoc({
        formIds: [formIdExternal],
        appRecId,
      });
      window.open(externalLsDoc);
      setExternalLoading(false);
    }
  };

  return (
    <Grid2 container direction="row" flex={1} justifyContent="flex-start" pr="32px">
      <Button
        label="Print - Internal"
        type="button"
        secondary
        disabled={isDisabled}
        onClick={() => handlePrintInternal(activeRepoRow?.appRecId!)}
        buttonStyle={{ width: '134px', margin: '10px 10px 0px 0px' }}
        loading={internalLoading}
      />
      <Button
        label="Print - External"
        type="button"
        secondary
        disabled={isDisabled}
        onClick={() => handlePrintExternal(activeRepoRow?.appRecId!)}
        buttonStyle={{ width: '134px', margin: '10px 0px 0px 0px' }}
        loading={externalLoading}
      />
    </Grid2>
  );
};
