import { CSSProperties, FC } from "react";
// kendo
import { TextInput } from "@/components";
// interfaces
import { AiUnderwritingReportDb } from "../interfaces";

const TextFieldDisplay: FC<{
  label: string;
  value?: AiUnderwritingReportDb[keyof AiUnderwritingReportDb];
  placeholder?: string;
  isFlex1?: boolean;
  isHidden?: boolean;
  isReadOnly?: boolean;
}> = ({
  label,
  value,
  placeholder = "-",
  isFlex1 = false,
  isHidden = false,
  isReadOnly = true,
}) => {
  const displayValue = value?.toString() ?? placeholder;
  const hiddenStyle: CSSProperties = isHidden ? { opacity: 0 } : {};
  const flexStyle: CSSProperties = isFlex1 ? { flex: 1 } : {};

  return (
    <TextInput
      label={label}
      value={displayValue}
      readOnly={isReadOnly}
      containerStyles={{ ...hiddenStyle, ...flexStyle }}
    />
  );
};

export default TextFieldDisplay;
