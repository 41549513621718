import { CustomFieldsUpdateInterface } from './CustomFieldsUpdateInterface';

export class CustomFields implements CustomFieldsUpdateInterface {
  constructor(newForm?: Partial<CustomFields>) {
    this.custField1L = newForm?.custField1L ?? undefined;
    this.custField1 = newForm?.custField1 ?? undefined;
    this.custField2L = newForm?.custField2L ?? undefined;
    this.custField2 = newForm?.custField2 ?? undefined;
    this.custField3L = newForm?.custField3L ?? undefined;
    this.custField3 = newForm?.custField3 ?? undefined;
    this.custField4L = newForm?.custField4L ?? undefined;
    this.custField4 = newForm?.custField4 ?? undefined;
    this.custField5L = newForm?.custField5L ?? undefined;
    this.custField5 = newForm?.custField5 ?? undefined;
    this.custField6L = newForm?.custField6L ?? undefined;
    this.custField6 = newForm?.custField6 ?? undefined;
    this.custField7L = newForm?.custField7L ?? undefined;
    this.custField7 = newForm?.custField7 ?? undefined;
    this.custField8L = newForm?.custField8L ?? undefined;
    this.custField8 = newForm?.custField8 ?? undefined;
    this.custField9L = newForm?.custField9L ?? undefined;
    this.custField9 = newForm?.custField9 ?? undefined;
    this.custField10L = newForm?.custField10L ?? undefined;
    this.custField10 = newForm?.custField10 ?? undefined;
    this.custDate1L = newForm?.custDate1L ?? undefined;
    this.custDate1 = newForm?.custDate1 ?? undefined;
    this.custDate2L = newForm?.custDate2L ?? undefined;
    this.custDate2 = newForm?.custDate2 ?? undefined;
    this.custDate3L = newForm?.custDate3L ?? undefined;
    this.custDate3 = newForm?.custDate3 ?? undefined;
    this.custDate4L = newForm?.custDate4L ?? undefined;
    this.custDate4 = newForm?.custDate4 ?? undefined;
    this.custDate5L = newForm?.custDate5L ?? undefined;
    this.custDate5 = newForm?.custDate5 ?? undefined;
    this.custNumL1 = newForm?.custNumL1 ?? undefined;
    this.custNum1 = newForm?.custNum1 ?? undefined;
    this.custNumL2 = newForm?.custNumL2 ?? undefined;
    this.custNum2 = newForm?.custNum2 ?? undefined;
    this.custNumL3 = newForm?.custNumL3 ?? undefined;
    this.custNum3 = newForm?.custNum3 ?? undefined;
    this.custNumL4 = newForm?.custNumL4 ?? undefined;
    this.custNum4 = newForm?.custNum4 ?? undefined;
    this.custNumL5 = newForm?.custNumL5 ?? undefined;
    this.custNum5 = newForm?.custNum5 ?? undefined;
    this.custYNL1 = newForm?.custYNL1 ?? undefined;
    this.custYN1 = newForm?.custYN1 ?? undefined;
    this.custYNL2 = newForm?.custYNL2 ?? undefined;
    this.custYN2 = newForm?.custYN2 ?? undefined;
    this.custYNL3 = newForm?.custYNL3 ?? undefined;
    this.custYN3 = newForm?.custYN3 ?? undefined;
    this.custYNL4 = newForm?.custYNL4 ?? undefined;
    this.custYN4 = newForm?.custYN4 ?? undefined;
    this.custYNL5 = newForm?.custYNL5 ?? undefined;
    this.custYN5 = newForm?.custYN5 ?? undefined;
  }

  custField1L: string | undefined;
  custField1: string | undefined;
  custField2L: string | undefined;
  custField2: string | undefined;
  custField3L: string | undefined;
  custField3: string | undefined;
  custField4L: string | undefined;
  custField4: string | undefined;
  custField5L: string | undefined;
  custField5: string | undefined;
  custField6L: string | undefined;
  custField6: string | undefined;
  custField7L: string | undefined;
  custField7: string | undefined;
  custField8L: string | undefined;
  custField8: string | undefined;
  custField9L: string | undefined;
  custField9: string | undefined;
  custField10L: string | undefined;
  custField10: string | undefined;
  custDate1L: string | undefined;
  custDate1: string | undefined;
  custDate2L: string | undefined;
  custDate2: string | undefined;
  custDate3L: string | undefined;
  custDate3: string | undefined;
  custDate4L: string | undefined;
  custDate4: string | undefined;
  custDate5L: string | undefined;
  custDate5: string | undefined;
  custNumL1: string | undefined;
  custNum1: string | undefined;
  custNumL2: string | undefined;
  custNum2: string | undefined;
  custNumL3: string | undefined;
  custNum3: string | undefined;
  custNumL4: string | undefined;
  custNum4: string | undefined;
  custNumL5: string | undefined;
  custNum5: string | undefined;
  custYNL1: string | undefined;
  custYN1: string | undefined;
  custYNL2: string | undefined;
  custYN2: string | undefined;
  custYNL3: string | undefined;
  custYN3: string | undefined;
  custYNL4: string | undefined;
  custYN4: string | undefined;
  custYNL5: string | undefined;
  custYN5: string | undefined;
}
