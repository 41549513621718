import { z } from "zod";
// interfaces
import { ActivityLog } from "@/interfaces/Accounts";
import { Call, Email, Messages, Note, Payment } from "@/services/customerService";

/** Derived directly from response type `CustomerActivityResponse` - from: `customerService.getCustomerActivity(colRecId)`
 * @deprecated Rename to `ActivityLogItem`
 * @deprecated Convert to zod schema + type
 * @deprecated Convert to base type - use this schema/type to derive each `ActivityType`-based schema
 */
export interface CustomerActivity {
  /** @note This is a `recId`
   * @todo Update fieldname to `recId` on backend response-dto-class
   */
  id: number;
  /** @deprecated redundant - should be contained within `activity` prop */
  activityType: ActivityType;
  activity: Email | Messages | Note | Payment | Call | ActivityLog;
  /** @todo use `dayjs` */
  timestamp: string;
}

/** @deprecated Convert to zod enum */
export const ActivityType = z.enum(["All", "Email", "Message", "Note", "Payment", "Call", "Log"]);
export type ActivityType = z.infer<typeof ActivityType>;
