import { FC } from "react";
import { StyleSheet, View, ViewProps } from "@react-pdf/renderer";
// components
import PdfTableRow from "./PdfTableRow";
import PdfTableCell from "./PdfTableCell";
// interfaces
import { PdfColumnMap } from "./interfaces";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    borderTop: "1px solid #000",
  },
});

/** ### Displays the rows of a table */
const PdfTableBody: FC<
  ViewProps & {
    columnLabelMap: PdfColumnMap;
    rows: any[];
    cellProps?: Omit<Parameters<typeof PdfTableCell>[0], "columnCt" | "value">;
  }
> = ({ columnLabelMap, rows, cellProps, style: rowStyle, ...rowProps }) => {
  const columnCt = columnLabelMap.size;

  const rowElems: JSX.Element[] = [];

  // Add row elements to table
  rows.forEach((r) => {
    const rowCells: JSX.Element[] = [];
    columnLabelMap.forEach((columnInfo, columnKey) =>
      // @note Apply width style last to keep consistent table layout
      rowCells.push(<PdfTableCell {...cellProps} value={r[columnKey]} columnInfo={columnInfo} columnCt={columnCt} key={columnInfo.label} />)
    );
    rowElems.push(<PdfTableRow key={JSON.stringify(r)}>{rowCells}</PdfTableRow>);
  });

  return (
    <View {...rowProps} style={{ ...styles.row, ...rowStyle }}>
      {rowElems}
    </View>
  );
};

export default PdfTableBody;
