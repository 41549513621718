import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import MuiLink from '@mui/material/Link';
import { DCS_BLUE } from '@/mui/theme/colors';
// kendo
import { Icons } from '@/components/icons';
import { BasicTable } from '@/components/table/Table';
import { Column } from '@/components/table/TableInterface';
// components
import DateCell from '@/components/kendoCells/DateCell';
import CurrencyCell from '@/components/kendoCells/CurrencyCell';
// state
import { useSalesViewCtx } from '@/features/Sales/SalesViewProvider';
// utils
import { paymentService } from '@/services/paymentService';
// interfaces
import { GridCellProps } from '@progress/kendo-react-all';
import { KendoColumnDef } from '@/utils/tableLayout/utils';
import { DownPayment } from '@/interfaces';

const PrintCell: FC<Omit<GridCellProps, 'dataItem'> & { dataItem: DownPayment }> = ({
  dataItem,
}) => {
  const pmtRecId = dataItem.recId;
  const handleClick = async () => {
    try {
      const res = await paymentService.getReceiptUrl(pmtRecId);
      window.open(res);
    } catch (error) {
      console.error(`Error fetching receipt URL. pmtRecId: ${pmtRecId}`);
    }
  };

  return (
    <td className="k-table-td" style={{ textAlign: 'center' }}>
      <MuiLink
        component={Grid}
        onClick={handleClick}
        color="#9e9e9e"
        sx={{ '& svg:hover': { color: DCS_BLUE, cursor: 'pointer' } }}
      >
        <Icons.Print />
      </MuiLink>
    </td>
  );
};

const downPaymentColumns: KendoColumnDef<keyof DownPayment | 'reprintReceipt'>[] = [
  { field: 'pmtDate', title: 'Payment Date', cells: { data: DateCell } },
  { field: 'payToFrom', title: 'Paid By' },
  { field: 'totalApplied', title: 'Amt. Paid', cells: { data: CurrencyCell } },
  { field: 'paidBy', title: 'Pay Method' },
  { field: 'takenBy', title: 'Taken By' },
  { field: 'reprintReceipt', title: ' ', cells: { data: PrintCell } },
];

/** ###  */
const PaymentsTable: FC<{ rows: DownPayment[] }> = ({ rows }) => {
  const isLoading = useSalesViewCtx((s) => s.isLoading);

  return (
    <Grid pt={1}>
      <BasicTable data={rows || []} columns={downPaymentColumns as Column[]} loading={isLoading} />
    </Grid>
  );
};

export default PaymentsTable;
