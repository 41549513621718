import { FC, useState } from "react";
// mui
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import { Grid2Props } from "@mui/material/Unstable_Grid2";;
import { getRowId } from "@/mui/components/dataTable/utils";
// components
import { DropDownButton, Loader } from "@/components";
// state
import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";
// utils
import { tableColumns } from "./tableColumns";
import { initPage, tableStyle } from "@/features/Settings/default";
// interfaces
import RepoCompanyDetail from "../RepoCompanyDetail";
import { toast } from "react-toastify";
import CommonModal from "@/mui/components/CommonModal";

const modalSx: Grid2Props | undefined = {
  display: "flex",
  p: 3,
  overflow: "hidden",
  gap: 2,
  width: "50%",
};

const AddNewButton: FC = () => {  
  const loadRepoCompanies = useRepoCompanyProviderCtx((s) => s.loadRepoCompanies);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropDownButton 
      open={isOpen} 
      setOpen={(open) => open && setIsOpen(true)}
      label="Add New Repo Company"
    >
      <CommonModal 
        open={isOpen} 
        onClose={() => setIsOpen(false) }
        containerProps={modalSx}
      >
        <RepoCompanyDetail 
          isNew={true} 
          onSubmit={() => { 
            setIsOpen(false);
            loadRepoCompanies.load();
            toast.success("Repo Company created successfully");
          }} 
          onCancel={() => setIsOpen(false)}
        /> 
      </CommonModal>
    </DropDownButton>
  );
};

const RepoCompanyTable: FC = () => {
  const repoCompanyRows = useRepoCompanyProviderCtx((s) => s.repoCompanies ?? []);
  const isLoading = useRepoCompanyProviderCtx((s) => s.isLoading);
  const onRowClick = useRepoCompanyProviderCtx((s) => s.handleRowClick);

  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageModel, setPageModel] = useState(initPage);

  return (
    isLoading ? (
      <Loader size="large" />
    ) : (
      <DataGrid
        autoHeight
        loading={isLoading}
        rows={repoCompanyRows}
        columns={tableColumns}
        getRowId={getRowId("recId")}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        paginationModel={pageModel}
        onPaginationModelChange={setPageModel}
        slots={{ toolbar: TableToolbar }}
        slotProps={{ toolbar: { placeholder: "Search filter", ButtonGroup: AddNewButton }}}
        sx={tableStyle}
        onRowClick={(params) => onRowClick(params.row.recId)}
      />
    )
  );
};

export default RepoCompanyTable;
