import dayjs from 'dayjs';

export interface SpecialPaymentPlan {
  numberOfPayments: number;
  amount: number;
  dateStarted: string;
  schedule: string;
  selectedIndex: number;
  hasValue?: boolean;
}

export interface IFinanceTermQuoteBase {
  salesPrice: number;
  salesTax: number;
  license: number;
  title: number;
  registration: number;
  customField1: number;
  customField2: number;
  customField3: number;
  customField4: number;
  customField5: number;
  customField6: number;
  serviceContract: number;
  creditInsurance: number;
  creditDisability: number;
  totalSalePrice: number;
  tradeAllowance: number;
  tradePayoff: number;
  depositDown: number;
  cashOnDelivery: number;
  totalDown: number;
  interestRate: number;
  amountFinanced: number;
  schedule: string; // 'Weekly',
  dateStarted: string; // dayjs().add(7, 'day').format('YYYY-MM-DD'),
  term: number;
  amount: number;
  numberOfPayments: number;
  final: number; // 0;
  finalDueOn: string; // '';
  financeCharge: number; // 0;
  totalPayments: number; // 0;
}
export interface IFinanceTerm extends IFinanceTermQuoteBase {
  dateOfSale: string; // dayjs().format('YYYY-MM-DD')
}
export const financeTermFormDefaults = {
  dateOfSale: dayjs().format('YYYY-MM-DD'),
  salesPrice: 0,
  salesTax: 0,
  license: 0,
  title: 0,
  registration: 0,
  customField1: 0,
  customField2: 0,
  customField3: 0,
  customField4: 0,
  customField5: 0,
  customField6: 0,
  serviceContract: 0,
  creditInsurance: 0,
  creditDisability: 0,
  totalSalePrice: 0,
  tradeAllowance: 0,
  tradePayoff: 0,
  depositDown: 0,
  cashOnDelivery: 0,
  totalDown: 0,
  interestRate: 0,
  amountFinanced: 0,
  schedule: 'Weekly',
  dateStarted: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  term: 0,
  amount: 0,
  numberOfPayments: 258,
  final: 0,
  finalDueOn: '',
  financeCharge: 0,
  totalPayments: 0,
};

export type FinanceTermData = typeof financeTermFormDefaults;

export enum TermsLockType {
  Num = 'Num',
  Amt = 'Amt',
}
