/*
  Ledger card styles need to use tsx instead of css. This is because we have to send the rendered HTML back to the
  server in order to generate the PDF. We cannot send the HTML + CSS, so we need to use inline CSS. Thus, TS files
  for the styling.
 */

import { CSSProperties } from 'react';

export const ledgerStyles: { [key: string]: CSSProperties } = {
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2em',
  },
  settingsContainer: {
    position: 'sticky',
  },
  ledgerTable: {
    borderSpacing: '4px 8px',
    fontSize: '14px',
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: 1072,
    marginTop: 10,
  },
  sectionHeader: {
    background: '#19194B',
    color: 'white',
    padding: '8px',
    borderRadius: '6px',
    marginTop: '16px',
  },
  label: {
    fontWeight: 'bold',
  },
  labelRow: {
    fontWeight: 'bold',
  },
  labelRowTd: {
    fontWeight: 'bold',
  },
  pmtHistoryRow: {
    borderBottom: '1px solid black',
  },
  pmtHistoryCell: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  verticalTableRow: {
    paddingTop: '8px',
    borderBottom: '1px solid black',
  },
  pullRight: {
    textAlign: 'right',
  },
  tableSpacer: {
    width: 50,
  },
  topLine: {
    borderTop: '1px solid black',
    marginTop: 5,
  },
  minHeight: {
    minHeight: 16,
    lineHeight: '16px',
  },
};

export const ledgerCss = `
td, p {
  font-size: 12px;
  height: 16px;
}

.top-line td {
  border-top: 1px solid black;
}

.bot-line td {
  border-bottom: 1px solid black;
}
`;

export const printCss = `
  ${ledgerCss}
  
  .ledger * {
    font-family: Arial, sans-serif !important;
    letter-spacing: 0.75px;
  }
  
  tr {
    width: 100%;
  }
  
  h2, h3 {
    margin: 0
  }
  
`;
