import { FC, Fragment } from 'react';
import dayjs from 'dayjs';
// utils
import { formatCurrency, formatPhoneNumber } from '@/utils/helpers/general';
// interfaces
import { ApplicationDb } from '@/features/Sales/interfaces/application';
// style
import { appPdfCss } from './appPdfCss';

/** ### @note moved for readability - simple abstractions can be used to reduce the size ***substantially***. */
const AppPdfLayout: FC<{ applicationData: ApplicationDb }> = ({ applicationData }) => {
  return (
    <>
      <style>{appPdfCss}</style>
      <body>
        <table>
          <tbody>
            <tr>
              <td className="headerSection">PROGRAM APPLICATION</td>
            </tr>
          </tbody>
        </table>
        <div style={{ lineHeight: 1 }}>&nbsp;</div>
        <div className="sec2" style={{ textAlign: 'center', fontSize: '14px' }}>
          <strong>
            You may apply for credit in your name alone, whether or not you are married.
          </strong>
        </div>
        <div className="sec2">
          Please indicate whether you are applying individually, or with another
          person.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Individual&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Joint
          Application
        </div>
        <div className="sec2">
          If you are applying for credit with another person please complete all sections.
        </div>
        <table
          className="MsoTableGrid"
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ borderCollapse: 'collapse', border: 'none' }}
        >
          <tbody>
            <tr style={{ height: '.125in' }}>
              <td
                width={156}
                valign="bottom"
                style={{
                  width: '117.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                  height: '.25in',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  We intend to apply for joint credit.
                </p>
              </td>
              <td
                width={174}
                valign="bottom"
                style={{
                  width: '130.5pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                  height: '.25in',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'white' }}>
                    /buy_sig/ {'{'}
                    {'{'}signature|req|signer1{'}'}
                    {'}'}
                  </span>
                </p>
              </td>
              <td
                width={17}
                valign="bottom"
                style={{
                  width: '13.1pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                  height: '.25in',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={193}
                valign="bottom"
                style={{
                  width: '144.4pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                  height: '.25in',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'white' }}> </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '.125in' }}></tr>
            <tr>
              <td
                width={156}
                valign="top"
                style={{ width: '117.0pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={174}
                valign="top"
                style={{
                  width: '130.5pt',
                  border: 'none',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'center',
                    lineHeight: 'normal',
                  }}
                >
                  Applicant
                </p>
              </td>
              <td
                width={17}
                valign="top"
                style={{ width: '13.1pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'center',
                    lineHeight: 'normal',
                  }}
                >
                  &nbsp;
                </p>
              </td>
              <td
                width={193}
                valign="top"
                style={{
                  width: '144.4pt',
                  border: 'none',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'center',
                    lineHeight: 'normal',
                  }}
                >
                  Joint Applicant
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">PRIMARY APPLICANT</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.5in' }}>Primary Buyer's Legal Name</th>
              <th style={{ width: '1.25in' }}>Nick Name</th>
              <th style={{ width: '1.25in' }}>DOB</th>
              <th style={{ width: '1.25in' }}># of Dependents</th>
              <th style={{ width: '1.25in' }}>Application Date</th>
            </tr>
            <tr>
              <td style={{ width: '2.5in' }}>
                {applicationData.b_FIRST} {applicationData.b_LAST}
              </td>
              <td style={{ width: '1.25in' }}>{applicationData.b_NICK}</td>
              <td style={{ width: '1.25in' }}>
                {applicationData.b_DOB ? dayjs(applicationData.b_DOB).format('MM/DD/YYYY') : ''}
              </td>
              <td style={{ width: '1.25in' }}>{applicationData.b_NUMDEP}</td>
              <td style={{ width: '1.25in' }}>
                {applicationData.appdate ? dayjs(applicationData.appdate).format('MM/DD/YYYY') : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.5in' }}>Social Security No.</th>
              <th style={{ width: '1.25in' }}>Driver's License No.</th>
              <th style={{ width: '1.25in' }}>State</th>
              <th style={{ width: '2.5in' }}>Expiration Date</th>
            </tr>
            <tr>
              <td style={{ width: '2.5in' }}>{applicationData.b_SSN}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_DL}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_DLSTATE}</td>
              <td style={{ width: '2.5in' }}>
                {applicationData.b_DLEXP ? dayjs(applicationData.b_DLEXP).format('MM/DD/YYYY') : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '5in' }}>Present Address</th>
              <th style={{ width: '1.25in' }}>County</th>
              <th style={{ width: '1.25in' }}>How Long</th>
            </tr>
            <tr>
              <td style={{ width: '5in' }}>{applicationData.buyerAddressCSZ}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_COUNTY}</td>
              <td style={{ width: '1.2in' }}>
                {`${applicationData.b_RYEARS ? applicationData.b_RYEARS + ' Years' : ''} ${
                  applicationData.b_RMONTHS ? applicationData.b_RMONTHS + ' Months' : ''
                }`}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
            <col width="7.5in" />
          </colgroup>
          <tbody>
            <tr>
              <th>Mailing Address</th>
            </tr>
            <tr>
              <td>{applicationData.buyerMAddressCSZ}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '1.75in' }}>Home Phone</th>
              <th style={{ width: '1.75in' }}>Cell Phone</th>
              <th style={{ width: '4.5in' }}>Email Address</th>
            </tr>
            <tr>
              <td style={{ width: '1.75in' }}>{formatPhoneNumber(applicationData.b_HPHONE)}</td>
              <td style={{ width: '1.75in' }}>{formatPhoneNumber(applicationData.b_CPHONE)}</td>
              <td style={{ width: '4.5in' }}>{applicationData.b_EMAIL}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.5in' }}>Landlord/Mortgage Holder</th>
              <th style={{ width: '1.5in' }}>Phone Number</th>
              <th style={{ width: '1in' }}>Rent/Own</th>
              <th style={{ width: '2.5in' }}>Rent/Mortgage Payment</th>
            </tr>
            <tr>
              <td style={{ width: '2.5in' }}>{applicationData.b_LANDMORG}</td>
              <td style={{ width: '1.5in' }}>{formatPhoneNumber(applicationData.b_LLPHONE)}</td>
              <td style={{ width: '1in' }}>{applicationData.b_RENTOWN}</td>
              <td style={{ width: '2.5in' }}>{applicationData.b_RENTMORG}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.5in' }}>Mortgage Balance</th>
              <th style={{ width: '5in' }}>Type of Residence</th>
            </tr>
            <tr>
              <td style={{ width: '2.5in' }}>{applicationData.b_MORGBAL}</td>
              <td style={{ width: '5in' }}>{applicationData.b_HOMETYPE}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '1.5in' }}>Current Employer</th>
              <th style={{ width: '3.5in' }}>Employer Address</th>
              <th style={{ width: '1.25in' }}>Work Phone Number</th>
              <th style={{ width: '1.25in' }}>Occupation</th>
            </tr>
            <tr>
              <td style={{ width: '1.5in' }}>{applicationData.b_EMPLOYER}</td>
              <td style={{ width: '3.5in' }}>{applicationData.employerAddressCSZ}</td>
              <td style={{ width: '1.25in' }}>{formatPhoneNumber(applicationData.b_WPHONE)}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_OCCUPATION}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '1.5in' }}>How Long</th>
              <th style={{ width: '2.25in' }}>Supervisor's Name</th>
              <th style={{ width: '1.25in' }}>Paydays</th>
              <th style={{ width: '1.25in' }}>Paid On</th>
              <th style={{ width: '1.25in' }}>Take Home Pay</th>
            </tr>
            <tr>
              <td style={{ width: '1.5in' }}>{`${
                applicationData.b_EYEARS ? applicationData.b_EYEARS + ' Years' : ''
              } ${applicationData.b_EMONTHS ? applicationData.b_EMONTHS + ' Months' : ''}`}</td>
              <td style={{ width: '2.25in' }}>{applicationData.b_SUPERVISOR}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_PAYPERIOD}</td>
              <td style={{ width: '1.25in' }}>{applicationData.b_PAIDON}</td>
              <td style={{ width: '1.25in' }}>{formatCurrency(applicationData.b_TAKEHOME)}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '4in', overflow: 'visible' }}>
                Note: Alimony, Child Support or Seperate Maintenance Income need not be revealed if
                you do not wish to have it considered as a basis for repaying this obligation.
              </th>
              <th style={{ width: '2in' }}>Source</th>
              <th style={{ width: '1.5in' }}>Other Income</th>
            </tr>
            <tr>
              <td style={{ width: '4in' }}>&nbsp;</td>
              <td style={{ width: '2in' }}>{applicationData.b_OSOURCE}</td>
              <td style={{ width: '1.5in' }}>{formatCurrency(applicationData.b_OINCOME)}</td>
            </tr>
          </tbody>
        </table>
        {applicationData.buyerPrevAddresses?.map((value, index) => (
          <table key={index}>
            <tbody>
              <tr>
                <th style={{ width: '5.5in' }}>Previous Address</th>
                <th style={{ width: '2in' }}>How Long</th>
              </tr>
              <tr>
                <td style={{ width: '5.5in' }}>{value.buyerAddressCSZ}</td>
                <td style={{ width: '2in' }}>{`${value.b_RYEARS ? value.b_RYEARS + ' Years' : ''} ${
                  value.b_RMONTHS ? value.b_RMONTHS + ' Months' : ''
                }`}</td>
              </tr>
            </tbody>
          </table>
        ))}
        {applicationData.buyerEmploymentHistories?.map((value) => (
          <Fragment key={value.recID}>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '1.5in' }}>Previous Employer</th>
                  <th style={{ width: '3.5in' }}>Employer Address</th>
                  <th style={{ width: '1.25in' }}>Work Phone Number</th>
                  <th style={{ width: '1.25in' }}>Occupation</th>
                </tr>
                <tr>
                  <td style={{ width: '1.5in' }}>{value.b_EMPLOYER}</td>
                  <td style={{ width: '3.5in' }}>{value.employerAddressCSZ}</td>
                  <td style={{ width: '1.25in' }}>{value.b_WPHONE}</td>
                  <td style={{ width: '1.25in' }}>{value.b_OCCUPATION}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '1.5in' }}>How Long</th>
                  <th style={{ width: '2.25in' }}>Supervisor's Name</th>
                  <th style={{ width: '1.25in' }}>Paydays</th>
                  <th style={{ width: '1.25in' }}>Paid On</th>
                  <th style={{ width: '1.25in' }}>Take Home Pay</th>
                </tr>
                <tr>
                  <td style={{ width: '1.5in' }}>{`${
                    value.b_EYEARS ? value.b_EYEARS + ' Years' : ''
                  } ${value.b_EMONTHS ? value.b_EMONTHS + ' Months' : ''}`}</td>
                  <td style={{ width: '2.25in' }}>{value.b_SUPERVISOR}</td>
                  <td style={{ width: '1.25in' }}>{value.b_ETYPE}</td>
                  <td style={{ width: '1.25in' }}>{value.b_PAIDON}</td>
                  <td style={{ width: '1.25in' }}>{formatCurrency(value.b_TAKEHOME)}</td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        ))}
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">PERSONAL REFERENCES</td>
            </tr>
          </tbody>
        </table>
        {applicationData.references ? (
          applicationData.references.map((value) => (
            <table key={value.recID}>
              <tbody>
                <tr>
                  <th style={{ width: '1.25in' }}>Reference For</th>
                  <th style={{ width: '2.25in' }}>Personal Reference Name</th>
                  <th style={{ width: '3.5in' }}>Address</th>
                </tr>
                <tr>
                  <td style={{ width: '1.5in' }}>{value.reffor}</td>
                  <td style={{ width: '2.25in' }}>{value.rname}</td>
                  <td style={{ width: '1.25in' }}>{value.refAddressCSZ}</td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <table>
            <colgroup>
              <col width="7.5in" />
            </colgroup>
            <tbody>
              <tr>
                <th>&nbsp;</th>
              </tr>
              <tr>
                <td>No References On-File</td>
              </tr>
            </tbody>
          </table>
        )}
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">VEHICLES</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '3.5in' }}>
                Present or Last Vehicle Owned (Year, Make &amp; Model)
              </th>
              <th style={{ width: '1in' }}>Financed With</th>
              <th style={{ width: '1.25in' }}>Titled In Your Name</th>
              <th style={{ width: '1.75in' }}>Payment Amount</th>
            </tr>
            <tr>
              <td style={{ width: '3.5in' }}>
                {applicationData.lvyear} {applicationData.lvmake} {applicationData.lvmodel}
              </td>
              <td style={{ width: '1in' }}>{applicationData.lvwith}</td>
              <td style={{ width: '1.25in' }}>{applicationData.lvtitlename}</td>
              <td style={{ width: '1.75in' }}>{applicationData.lvpayment}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '1in' }}>Amount Owed</th>
              <th style={{ width: '.75in' }}>Trading In</th>
              <th style={{ width: '1.75in' }}>If Not, Why:</th>
              <th style={{ width: '2.25in' }}>Insured By (Company)</th>
              <th style={{ width: '1.75in' }}>Type of Coverage</th>
            </tr>
            <tr>
              <td style={{ width: '1in' }}>{formatCurrency(applicationData.lvbalance)}</td>
              <td style={{ width: '.75in' }}>{applicationData.lvtradein}</td>
              <td style={{ width: '1.75in' }}>{applicationData.lvwhy}</td>
              <td style={{ width: '2.25in' }}>{applicationData.lvinscom}</td>
              <td style={{ width: '1.75in' }}>{applicationData.lvinscov}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '3.5in' }}>
                Present or Last Vehicle Owned (Year, Make &amp; Model)
              </th>
              <th style={{ width: '1in' }}>Financed With</th>
              <th style={{ width: '1.25in' }}>Titled In Your Name</th>
              <th style={{ width: '1.75in' }}>Payment Amount</th>
            </tr>
            <tr>
              <td style={{ width: '3.5in' }}>
                {applicationData.otvehyear} {applicationData.otvehmake} {applicationData.otmodel}
              </td>
              <td style={{ width: '1in' }}>{applicationData.otfinwith}</td>
              <td style={{ width: '1.25in' }}>{applicationData.ottitlename}</td>
              <td style={{ width: '1.75in' }}>{applicationData.otpayment}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '1in' }}>Amount Owed</th>
              <th style={{ width: '.75in' }}>Trading In</th>
              <th style={{ width: '1.75in' }}>If Not, Why:</th>
              <th style={{ width: '2.25in' }}>Insured By (Company)</th>
              <th style={{ width: '1.75in' }}>Type of Coverage</th>
            </tr>
            <tr>
              <td style={{ width: '1in' }}>{formatCurrency(applicationData.otbal)}</td>
              {/* This data seems to be missing from API */}
              <td style={{ width: '.75in' }}></td>
              <td style={{ width: '1.75in' }}>{applicationData.otwhy}</td>
              <td style={{ width: '2.25in' }}>{applicationData.otinsco}</td>
              <td style={{ width: '1.75in' }}>{applicationData.otinscov}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">BANKING/CHECKING INFORMATION</td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
            <col width="1.5in" />
            <col width="1.5in" />
            <col width="4.5in" />
          </colgroup>
          <tbody>
            <tr>
              <th>Name of Bank</th>
              <th>Current Checking Balance</th>
              <th>Current Savings Balance</th>
            </tr>
            <tr>
              <td>None</td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">CREDIT INFORMATION</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Are you currently in bankruptcy</th>
              <th style={{ width: '1.25in' }}>Buyer</th>
              <th style={{ width: '3.5in' }}>Co-Buyer</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>&nbsp;</td>
              <td style={{ width: '1.25in' }}>{applicationData.bkYesNo}</td>
              <td style={{ width: '3.5in' }}>{applicationData.bkYesNo1}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '5in' }}>
                Have You Discussed Filing For Bankruptcy With An Attorney Within The Last 90 Days
              </th>
              <th style={{ width: '2.5in' }}>Applies To</th>
            </tr>
            <tr>
              <td style={{ width: '4in' }}>{applicationData.bkDisYesNo}</td>
              <td style={{ width: '3.5in' }}>{applicationData.bkbcBoth}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Number of repossessions in the last 3 years</th>
              <th style={{ width: '1.25in' }}>Buyer</th>
              <th style={{ width: '3.5in' }}>Co-Buyer</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>&nbsp;</td>
              <td style={{ width: '1.25in' }}>{applicationData.numRepo}</td>
              <td style={{ width: '3.5in' }}>{applicationData.numRepo1}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Have You Had Any Judgements In The Last 5 Years</th>
              <th style={{ width: '1.25in' }}>Applies To</th>
              <th style={{ width: '3.5in' }}>If Yes, How Many</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>{applicationData.jdYesNo}</td>
              <td style={{ width: '1.25in' }}>{applicationData.jdbcBoth}</td>
              <td style={{ width: '3.5in' }}>{applicationData.jdNum}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Do you have any garnishments?</th>
              <th style={{ width: '1.25in' }}>Applies To</th>
              <th style={{ width: '1.25in' }}>If Yes, Total Amount</th>
              <th style={{ width: '2.25in' }}>With</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>{applicationData.grYesNo}</td>
              <td style={{ width: '1.25in' }}>{applicationData.grbcBoth}</td>
              <td style={{ width: '1.25in' }}>{formatCurrency(applicationData.grAmount)}</td>
              <td style={{ width: '2.25in' }}>{applicationData.grWith}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Do you owe child support?</th>
              <th style={{ width: '1.25in' }}>Applies To</th>
              <th style={{ width: '1.25in' }}>If Yes, Amount Monthly</th>
              <th style={{ width: '2.25in' }}>Amount Past Due</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>{applicationData.csYesNo}</td>
              <td style={{ width: '1.25in' }}>{applicationData.csbcBoth}</td>
              <td style={{ width: '1.25in' }}>{formatCurrency(applicationData.csAmount)}</td>
              <td style={{ width: '2.25in' }}>{formatCurrency(applicationData.csPastdue)}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Do you owe student loans?</th>
              <th style={{ width: '1.25in' }}>Applies To</th>
              <th style={{ width: '1.25in' }}>If Yes, Total Amount </th>
              <th style={{ width: '2.25in' }}>Amount Past Due</th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>{applicationData.slYesNo}</td>
              <td style={{ width: '1.25in' }}>{applicationData.slbcBoth}</td>
              <td style={{ width: '1.25in' }}>{formatCurrency(applicationData.slAmount)}</td>
              <td style={{ width: '2.25in' }}>{formatCurrency(applicationData.slPastDue)}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th style={{ width: '2.75in' }}>Do you owe the IRS?</th>
              <th style={{ width: '1.25in' }}>Applies To</th>
              <th style={{ width: '3.5in' }}>If Yes, Total Amount </th>
            </tr>
            <tr>
              <td style={{ width: '2.75in' }}>{applicationData.irYesNo}</td>
              <td style={{ width: '1.25in' }}>{applicationData.irbcBoth}</td>
              <td style={{ width: '3.5in' }}>{formatCurrency(applicationData.irAmount)}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">CREDITORS</td>
            </tr>
          </tbody>
        </table>
        {/* Seems to be no html generation for this section even though we have correct data */}
        <table>
          <tbody>
            <tr>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>No Creditors On-File</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="headerSection2">AUTHORIZATION</td>
            </tr>
          </tbody>
        </table>
        <p>
          Federal and state laws against discrimination require that all creditors make credit
          equally available to all creditworthy customers and prohibit creditors from discriminating
          against credit applications on the basis of race, color, religion, national origin, sex,
          marital status, age(provided the applicant has the capacity to enter in to a binding
          contract); because all or part of the applicant's income derives from any public
          assistance program; or because the applicant has in good faith exercised any right under
          the Consumer Credit Protection Act. Credit reporting agencies are required to maintain
          separate credit reports on each individual available as prescribed by law.
        </p>
        <p>
          I/We certify that all the information is true and complete and understand that this
          dealership will rely on the truth and completeness of the information provided in granting
          credit. I/We hereby authorize this dealership to investigate the accuracy of these
          statements and to obtain credit reports though a credit reporting agency. This report may
          check outstanding credit accounts, employment and residential information, checking and/or
          savings accounts, obligations and any and all other credit matters.
        </p>
        <p>
          I/We hereby authorize this dealership and its affiliates to contact us by text, email or
          cell phone. This electronic communications authorization is effective for the duration of
          the retail installment sales contract or lease for purpose related to those contracts.
          I/We further authorize this dealership and its affiliates to contact us by text, email or
          cell phone for marketing purpose for a period of 3 months if no vehicle is purchased or
          leased or 18 months if a vehicle is purchased or leased. This electronic communication
          authorization will remain in effect unless withdrawn in writting by sending the
          notification of withdrawal to the dealership.
        </p>
        <p>
          Credit will be extended to you based on the honesty and accuracy of the information
          provided along with your ability to pay.
        </p>
        <table
          className="MsoTableGrid"
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ borderCollapse: 'collapse', border: 'none' }}
        >
          <tbody>
            <tr>
              <td
                width={125}
                valign="top"
                style={{ width: '93.5pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'right',
                    lineHeight: 'normal',
                  }}
                >
                  Applicant:
                </p>
              </td>
              <td
                width={125}
                valign="top"
                style={{
                  width: '93.5pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'white' }}>
                    /buy_sig/ {'{'}
                    {'{'}signature|req|signer1{'}'}
                    {'}'}
                  </span>
                </p>
              </td>
              <td
                width={16}
                valign="top"
                style={{ width: '11.8pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={41}
                valign="top"
                style={{ width: '30.7pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  Date:
                </p>
              </td>
              <td
                width={101}
                valign="top"
                style={{
                  width: '76.1pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  {dayjs().format('MM/DD/YYYY')}
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={125}
                valign="top"
                style={{ width: '93.5pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'right',
                    lineHeight: 'normal',
                  }}
                >
                  Joint Applicant:
                </p>
              </td>
              <td
                width={125}
                valign="top"
                style={{
                  width: '93.5pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'white' }}> </span>
                </p>
              </td>
              <td
                width={16}
                valign="top"
                style={{ width: '11.8pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={41}
                valign="top"
                style={{ width: '30.7pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  Date:
                </p>
              </td>
              <td
                width={101}
                valign="top"
                style={{
                  width: '76.1pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  {dayjs().format('MM/DD/YYYY')}
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={125}
                valign="top"
                style={{ width: '93.5pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'right',
                    lineHeight: 'normal',
                  }}
                >
                  Dealership Representative:
                </p>
              </td>
              <td
                width={125}
                valign="top"
                style={{
                  width: '93.5pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'white' }}>
                    /dlr_sig/ {'{'}
                    {'{'}signature|req|sender{'}'}
                    {'}'}
                  </span>
                </p>
              </td>
              <td
                width={16}
                valign="top"
                style={{ width: '11.8pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={41}
                valign="top"
                style={{ width: '30.7pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={101}
                valign="top"
                style={{
                  width: '76.1pt',
                  border: 'none',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={125}
                valign="top"
                style={{ width: '93.5pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: '.0001pt',
                    textAlign: 'right',
                    lineHeight: 'normal',
                  }}
                >
                  Referred By:
                </p>
              </td>
              <td
                width={125}
                valign="top"
                style={{
                  width: '93.5pt',
                  border: 'none',
                  borderBottom: 'solid windowtext 1.0pt',
                  background: 'white',
                  padding: '0in 5.4pt 0in 5.4pt',
                }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  <span style={{ color: 'black' }} />
                </p>
              </td>
              <td
                width={16}
                valign="top"
                style={{ width: '11.8pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  {applicationData.referredBy}
                </p>
              </td>
              <td
                width={41}
                valign="top"
                style={{ width: '30.7pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
              <td
                width={101}
                valign="top"
                style={{ width: '76.1pt', padding: '0in 5.4pt 0in 5.4pt' }}
              >
                <p className="MsoNormal" style={{ marginBottom: '.0001pt', lineHeight: 'normal' }}>
                  &nbsp;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </>
  );
};

export default AppPdfLayout;
