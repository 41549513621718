import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Icons } from '@/components/icons';

/** ###  */
const SupportButton: FC = () => {
  return (
    <Grid
      container
      direction="column"
      height={50}
      width={50}
      justifyContent="center"
      alignItems="center"
    >
      <Icons.QuestionMark />
    </Grid>
  );
};

export default SupportButton;
