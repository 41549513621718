// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const cpiAccountsColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('cpiStatus', 'CPI Status', 'auto'),
  newColumnDef('code3', 'Lot', 80),
];
