import { FC } from "react";
// mui
import Typography from "@mui/material/Typography";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";

/** ###  */
const CreditScoreCell: FC<GridCellProps> = ({ dataItem, field, style }) => (
  <td className="k-table-td" style={{ ...style, textAlign: "center" }}>
    <Typography component="span" variant="tableCell" sx={{ color: "#737373" }}>
      {dataItem[field!] ?? "-"}
    </Typography>
  </td>
);

export default CreditScoreCell;
