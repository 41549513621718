import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import {
  LedgerAccountDetails,
  LedgerCurrentPaymentSchedule,
  LedgerOriginalPaymentSchedule,
} from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### Needs refactor */
const LedgerCardAcctStatus: FC<{
  accountDetails: LedgerAccountDetails;
  originalPaymentSchedule: LedgerOriginalPaymentSchedule;
  currentPaymentSchedule: LedgerCurrentPaymentSchedule;
}> = ({ accountDetails, originalPaymentSchedule, currentPaymentSchedule }) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Account Details</div>
    <table style={{ ...ledgerStyles.ledgerTable, width: '60%' }}>
      <tbody>
        <tr>
          <td style={ledgerStyles.label}>Account Status</td>
          <td style={ledgerStyles.pullRight}>{accountDetails.accountStatus}</td>
          <td style={ledgerStyles.tableSpacer}></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Principal Balance</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.principalBalance || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>P&I Balance</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.piBalance || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Accrued Interest</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.accruedInterest || 0)}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Late Charge Due</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.lateChargeDue || 0)}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>NSF Due</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.nsfDue || 0)}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Misc. Due</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.miscDue || 0)}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Today's Payoff</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.payoff || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Per Diem</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.perDiem || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Payment Due</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.paymentDue || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Days Late</td>
          <td style={ledgerStyles.pullRight}>{accountDetails.daysLate}</td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Next Due Date</td>
          <td style={ledgerStyles.pullRight}>{formatDate(accountDetails.nextDueDate)}</td>
          <td></td>
          <td style={ledgerStyles.label}>Next Due Amount</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.nextDueAmount || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Last Paid Date</td>
          <td style={ledgerStyles.pullRight}>{formatDate(accountDetails.lastPaidDate)}</td>
          <td></td>
          <td style={ledgerStyles.label}>Last Paid Amount</td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(accountDetails.lastPaidAmount || 0)}
          </td>
        </tr>
      </tbody>
    </table>
    <div style={ledgerStyles.sectionHeader}>Original Payment Schedule</div>
    <div style={ledgerStyles.ledgerTable}>
      {originalPaymentSchedule.plan7 ? <p>{originalPaymentSchedule.plan7}</p> : null}
      {originalPaymentSchedule.plan8 ? <p>{originalPaymentSchedule.plan8}</p> : null}
      {originalPaymentSchedule.plan9 ? <p>{originalPaymentSchedule.plan9}</p> : null}
      {originalPaymentSchedule.plan10 ? <p>{originalPaymentSchedule.plan10}</p> : null}
      {originalPaymentSchedule.plan11 ? <p>{originalPaymentSchedule.plan11}</p> : null}
      {originalPaymentSchedule.plan12 ? <p>{originalPaymentSchedule.plan12}</p> : null}
    </div>
    <div style={ledgerStyles.sectionHeader}>Current Payment Schedule</div>
    <div style={ledgerStyles.ledgerTable}>
      {currentPaymentSchedule.plan1 ? <p>{currentPaymentSchedule.plan1}</p> : null}
      {currentPaymentSchedule.plan2 ? <p>{currentPaymentSchedule.plan2}</p> : null}
      {currentPaymentSchedule.plan3 ? <p>{currentPaymentSchedule.plan3}</p> : null}
      {currentPaymentSchedule.plan4 ? <p>{currentPaymentSchedule.plan4}</p> : null}
      {currentPaymentSchedule.plan5 ? <p>{currentPaymentSchedule.plan5}</p> : null}
      {currentPaymentSchedule.plan6 ? <p>{currentPaymentSchedule.plan6}</p> : null}
    </div>
  </>
);

export default LedgerCardAcctStatus;
