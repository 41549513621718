import dayjs, { Dayjs } from 'dayjs';
// interfaces
import { DateFormat } from '@/utils/helpers/general';
// utils
import { InsuranceCommon } from '@/features/Sales/detailView/tabs/ApplicationTab/subviews/insurance/interfaces';
import { PaymentInterval } from '@/enums/payment';

export interface InsuranceClaim {
  // There are a lot of other fields returned, add as needed
  recId: number;
  claimDate: string | null;
  claimNum: string | null; // @note Unused, but sent from backend
  custIns: string | null;
  claimStatus: string | null;
  claimNotes: string | null; // @note Unused, but sent from backend
  custDed: number | null;
  setDate: string | null;
  setAmt: number | null;
  insCName: string | null;
  totalLoss: boolean; // @note Unused, but sent from backend
  cpiClaim: boolean;
  policeRep: boolean; // @note Unused, but sent from backend
  policeRepSent: boolean; // @note Unused, but sent from backend
  driveable: boolean; // @note Unused, but sent from backend
  logRecv: boolean; // @note Unused, but sent from backend
}

class AcctInsuranceCommon<TDate extends string | Dayjs = Dayjs> extends InsuranceCommon<TDate> {
  insCancelled: boolean | null;
  insCancelledDate: TDate | null;

  constructor(input?: AcctInsuranceCommon) {
    super(input);

    this.insCancelled = input?.insCancelled || null;
    this.insCancelledDate = (
      input?.insCancelledDate ? dayjs(input.insCancelledDate) : null
    ) as TDate;
  }
}

export class AcctsInsuranceRes<
  TDate extends string | Dayjs = Dayjs
> extends AcctInsuranceCommon<TDate> {
  cpiRate: number | null;
  buyers: string | null; // Used only on backend
  colRecId: number | null; // Used only on backend
  cpiFirstDue: TDate | null;
  cpiOnDate: TDate | null;
  cpiOrgDate: TDate | null; // Used only on backend
  cpiSched: PaymentInterval | null;
  cpiTPaid: number | null; // Used only on backend
  daysLate: number | null; // Used only on backend
  nextDueAmount: number | null; // Used only on backend
  nextDueDate: TDate | null;
  nextPmtDue: TDate | null;
  schedule: PaymentInterval | null;
  vin: string | null;

  constructor(input?: AcctsInsuranceRes) {
    super(input);

    this.buyers = input?.buyers || null;
    this.cpiRate = input?.cpiRate || null;
    this.colRecId = input?.colRecId || null;
    this.cpiFirstDue = (input?.cpiFirstDue ? dayjs(input.cpiFirstDue) : null) as TDate;
    this.cpiOnDate = (input?.cpiOnDate ? dayjs(input.cpiOnDate) : null) as TDate;
    this.cpiOrgDate = (input?.cpiOrgDate ? dayjs(input.cpiOrgDate) : null) as TDate;
    this.cpiSched = input?.cpiSched || null;
    this.cpiTPaid = input?.cpiTPaid || null;
    this.daysLate = input?.daysLate || null;
    this.nextDueAmount = input?.nextDueAmount || null;
    this.nextDueDate = (input?.nextDueDate ? dayjs(input.nextDueDate) : null) as TDate;
    this.nextPmtDue = (input?.nextPmtDue ? dayjs(input.nextPmtDue) : null) as TDate;
    this.schedule = input?.schedule || null;
    this.vin = input?.vin || null;
  }
}

export class AcctInsuranceForm extends AcctInsuranceCommon<Dayjs> {
  override insCancelled: boolean;

  constructor(input?: AcctInsuranceCommon<Dayjs>) {
    super(input);

    this.insCancelled = input?.insCancelled || false;
  }
}

export class UpdateInsuranceReq extends AcctInsuranceCommon<string> {
  colRecId: number;

  constructor(form: AcctInsuranceForm, colRecId: number) {
    super(form);

    this.colRecId = colRecId;
    this.insExpire = dayjs(form.insExpire as Dayjs).format(DateFormat.YearMonthDayDash);

    this.onCpi = form.onCpi || false;
    this.insRequired = form.insRequired || false;
    this.insCancelled = form.insCancelled || false;

    this.insCancelledDate =
      form.insCancelled === false
        ? null
        : dayjs(form.insCancelledDate as Dayjs).format(DateFormat.YearMonthDayDash);

    this.cpiYearlyPremium = form.onCpi === false ? null : form.cpiYearlyPremium;
  }
}
