import { FC } from 'react';
import { NavLink } from 'react-router-dom';
// kendo
import { ListViewItemProps } from '@progress/kendo-react-all';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions } from '@/features/Accounts/accountSlice';
// style
import styles from '../AccountDetail.module.scss';

const CustomerAdminItemView: FC<ListViewItemProps> = ({ dataItem }) => {
  const dispatch = useAppDispatch();
  const handleClick = () => dispatch(accountActions.setCustomerAdminListOpen(false));

  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? styles.customerAdminItemActive : styles.customerAdminItem
      }
      to={dataItem.route}
      onClick={handleClick}
    >
      {dataItem.text}
    </NavLink>
  );
};

export default CustomerAdminItemView;
