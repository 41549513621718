import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
import { ICompany } from '@/interfaces';
import { ERSDb, ERSFmt, ERSForm } from './interfaces';

type RequestType = 'PUT' | 'POST';

export interface IEmailReportScheduleContext {
  emailReportSchedules: ERSFmt[];
  setEmailReportSchedules: (ersArr: ERSFmt[]) => void;
  /** Add a new email report schedule item to state */
  addERS: (ersToAdd: ERSDb) => void;
  updateERS: (ersToUpdate: ERSFmt) => void;
  removeERS: (ersToRemoveId: ERSFmt['recId']) => void;
  companies: ICompanyERS[];
  setCompanies: (companies: ICompanyERS[]) => void;
  companiesMap: Map<ICompanyERS['compId'], ICompanyERS['companyName']>;
  isEdit: boolean;
  // Modal variables
  selectedERS: ERSFmt | null;
  setSelectedERS: <T extends ERSDb | ERSFmt = ERSDb>(ers: T | null) => void;
  selectedERSForm: ERSForm | null;
  httpVerb?: RequestType;
  setHttpVerb: (httpVerb?: RequestType) => void;
}

/** ## Companies as they are represented in the Email Report Schedules subview
 * @note `recId` is mapped to `compId` on backend request
 */
export type ICompanyERS = Pick<ICompany, 'companyName'> & { compId: ICompany['recId'] };

const EmailReportScheduleContext = createContext<IEmailReportScheduleContext | null>(null);

export const EmailReportScheduleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [emailReportSchedules, setEmailReportSchedules] = useState<ERSFmt[]>([]);
  const [companies, setCompanies] = useState<ICompanyERS[]>([]);
  // Modal variables
  const [httpVerb, setHttpVerb] = useState<RequestType | undefined>();
  const [selectedERS, setSelectedERS_] = useState<ERSFmt | null>(null);
  const setSelectedERS = <T extends ERSDb | ERSFmt = ERSDb>(ers: T | null) => {
    if (ers === null) {
      setSelectedERS_(ers);
    } else if (ers instanceof ERSDb) {
      setSelectedERS_(ERSFmt.fromERSDb(ers));
    } else if (ers instanceof ERSFmt) {
      setSelectedERS_(ers);
    } else {
      throw new Error(`setSelectedERS - incorrect type: ${JSON.stringify(ers)}`);
    }
  };

  return (
    <EmailReportScheduleContext.Provider
      value={{
        emailReportSchedules,
        setEmailReportSchedules,
        addERS: (ersToAdd: ERSDb) => {
          const newErs = ERSFmt.fromERSDb(ersToAdd);

          setEmailReportSchedules([newErs, ...emailReportSchedules]);
        },
        updateERS: (ersToUpdate: ERSFmt) => {
          const ersIdx = emailReportSchedules.findIndex((ers) => ers.recId === ersToUpdate.recId);
          // Shallow-clone arr - avoid potential state-update issues w/ using orig arr
          const updatedErsArr = [...emailReportSchedules];
          updatedErsArr[ersIdx] = ersToUpdate;
          setEmailReportSchedules(updatedErsArr);
        },
        removeERS: (ersToRemoveId: ERSFmt['recId']) => {
          const updatedErsArr = emailReportSchedules.filter((ers) => ers.recId !== ersToRemoveId);
          setEmailReportSchedules(updatedErsArr);
        },
        companies,
        setCompanies,
        selectedERS,
        // Modal variables
        setSelectedERS,
        get companiesMap(): Map<ICompanyERS['compId'], ICompanyERS['companyName']> {
          return new Map<ICompanyERS['compId'], ICompanyERS['companyName']>(
            companies.map((c) => [c.compId, c.companyName])
          );
        },
        get isEdit() {
          return selectedERS !== null;
        },
        get selectedERSForm(): ERSForm | null {
          if (selectedERS === null) {
            return null;
          } else if (selectedERS instanceof ERSFmt) {
            return ERSForm.fromFmt(selectedERS);
          } else {
            console.error(selectedERS);
            throw new Error(`selectedERSFmt - incorrect type: ${JSON.stringify(selectedERS)}`);
          }
        },
        httpVerb,
        setHttpVerb,
      }}
    >
      {children}
    </EmailReportScheduleContext.Provider>
  );
};

export const useERSContext = () =>
  useContext(EmailReportScheduleContext) as IEmailReportScheduleContext;
