import { FC } from 'react';
// kendo
import { Icons } from '@/components/icons';
// style
import styles from './Pager.module.scss';

interface PagerProps {
  totalItems: number;
  currentIdx: number;
  onBeginningClick: () => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  onEndClick: () => void;
}

enum PagerClickType {
  Beginning = 'Beginning',
  Prev = 'Prev',
  Next = 'Next',
  End = 'End',
}

export const Pager: FC<PagerProps> = (props) => {
  const { totalItems, currentIdx, onBeginningClick, onPrevClick, onNextClick, onEndClick } = props;

  const canNavigate = (clickType: PagerClickType) => {
    if (currentIdx <= 0 && [PagerClickType.Beginning, PagerClickType.Prev].includes(clickType))
      return false;
    if (
      currentIdx >= totalItems - 1 &&
      [PagerClickType.Next, PagerClickType.End].includes(clickType)
    )
      return false;
    return true;
  };

  const handleNavigate = (clickType: PagerClickType, clickFn: () => void) => {
    if (!canNavigate(clickType)) return;
    clickFn();
  };

  const getArrowStyle = (clickType: PagerClickType) => {
    let arrowStyle = styles.arrowContainer;
    if (!canNavigate(clickType)) arrowStyle += ` ${styles.arrowDisabled}`;
    return arrowStyle;
  };

  return (
    <div className={styles.pagerContainer}>
      <div className={styles.arrowGroup}>
        <div
          className={getArrowStyle(PagerClickType.Beginning)}
          onClick={() => handleNavigate(PagerClickType.Beginning, onBeginningClick)}
        >
          <Icons.ArrowLeftDouble />
        </div>
        <div
          className={getArrowStyle(PagerClickType.Prev)}
          onClick={() => handleNavigate(PagerClickType.Prev, onPrevClick)}
        >
          <Icons.ArrowLeft />
        </div>
      </div>
      <div className={styles.counter}>
        {currentIdx + 1} of {totalItems}
      </div>
      <div className={styles.arrowGroup}>
        <div
          className={getArrowStyle(PagerClickType.Next)}
          onClick={() => handleNavigate(PagerClickType.Next, onNextClick)}
        >
          <Icons.ArrowRight />
        </div>
        <div
          className={getArrowStyle(PagerClickType.End)}
          onClick={() => handleNavigate(PagerClickType.End, onEndClick)}
        >
          <Icons.ArrowRightDouble />
        </div>
      </div>
    </div>
  );
};
