import { FC, ReactNode } from "react";
// mui
import { Grid2Props } from "@mui/material/Unstable_Grid2";
import MuiKendoFormControl from "@/mui/components/form/MuiKendoFormControl";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { FormControlProps } from "@mui/material/FormControl";
import { FormLabelProps } from "@mui/material/FormLabel";

/** Example implementation:
 *
 * Original implementation:
 * ```tsx
 * <FormControlLabel
 *   label="Not Actual"
 *   control={
 *     <Checkbox
 *       checked={wsForm.notActual}
 *       onChange={(event: ChangeEvent<HTMLInputElement>) =>
 *         setWsFormField('notActual', event.target.checked)
 *       }
 *       disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
 *     />
 *   }
 *   labelPlacement="end"
 * />
 * ```
 *
 * Original implementation:
 * ```tsx
 * <FormControlLabel
 *   label="Not Actual"
 *   control={
 *     <Checkbox
 *       checked={wsForm.notActual}
 *       onChange={(event: ChangeEvent<HTMLInputElement>) =>
 *         setWsFormField('notActual', event.target.checked)
 *       }
 *       disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
 *     />
 *   }
 *   labelPlacement="end"
 * />
 * ```
 */

/** ###  */
const MuiCheckboxField: FC<
  Omit<CheckboxProps, "checked"> & {
    // TEXT FIELD (below)
    // errorText?: string;
    // helperText?: string;
    // helperTextProps?: FormHelperTextProps;
    // keepHelperTextSpacing?: boolean;
    // helperComponent?: ReactNode;
    // TEXT FIELD (above)
    //
    // DATE FIELD (below)
    name?: string;
    value?: boolean | null | undefined;
    required?: boolean;
    errors?: string | boolean;
    containerProps?: Grid2Props;
    formControlProps?: FormControlProps;
    labelProps?: FormLabelProps;
    label: ReactNode;
    // onChange: ChangeEventHandler<HTMLInputElement>;
    // DATE FIELD (above)
  }
> = ({
  name,
  value: inputValue,
  required = false,
  errors,
  containerProps,
  formControlProps,
  labelProps,
  label,
  ...inputProps
}) => {
  return (
    <MuiKendoFormControl
      {...formControlProps}
      label={required ? `${label} *` : label}
      disabled={inputProps.disabled}
      containerProps={containerProps}
      labelProps={{
        ...labelProps,
        sx: { px: 0, flex: 1, fontWeight: required ? 800 : 700, ...labelProps?.sx },
      }}
      error={!!errors}
    >
      <Checkbox
        {...inputProps}
        checked={inputValue ?? false}
        value={inputValue ?? false}
        // onChange={onChange}
      />
    </MuiKendoFormControl>
  );
};

export default MuiCheckboxField;
