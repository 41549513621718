import { FC } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
// interfaces
import { AccountInformation } from '@/interfaces/Accounts';
import { LedgerDealershipDetails } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

dayjs.extend(advancedFormat);

/** ### @deprecated Needs refactor */
const LedgerCardHeader: FC<{
  status: string;
  details: LedgerDealershipDetails | null;
  accountInformation: AccountInformation;
}> = ({ status, details, accountInformation: { buyer, coBuyer } }) => (
  <>
    <table>
      <tbody>
        <tr>
          <td style={{ width: '25%' }} rowSpan={3}>
            {details ? (
              <img
                style={{ width: '100%', objectFit: 'contain' }}
                src={details.companyLogo}
                alt={details!.dealershipName}
              />
            ) : null}
          </td>
          <td style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 24, fontWeight: 800 }}>{details?.dealershipName}</span>
          </td>
          <td style={{ width: '25%' }}></td>
        </tr>
        <tr>
          <td style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 18, fontWeight: 800 }}>{details?.dealershipAddressCsz}</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 18, fontWeight: 800 }}>{details?.dealershipPhoneFax}</span>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div style={ledgerStyles.sectionHeader}>
      <h1 style={{ textAlign: 'center' }}>Ledger Card</h1>
    </div>
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontWeight: 'bold' }}>
        {buyer.firstName} {buyer.lastName}{' '}
        {coBuyer && coBuyer.firstName && coBuyer.lastName
          ? `and ${coBuyer.firstName} ${coBuyer.lastName}`
          : ''}
      </div>
      <div style={{ fontWeight: 'bold' }}>Status: {status}</div>
      <div style={{ fontWeight: 'bold' }}>{details?.acctYrMakeModelVin}</div>
      <div style={{ fontWeight: 'bold' }}>
        {dayjs().format('ddd. MMMM Do, YYYY [at] hh:mm:ss A')}
      </div>
    </div>
  </>
);

export default LedgerCardHeader;
