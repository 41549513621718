import { FC } from "react";
// components
import ViewHeader from "./ViewHeader";
import SalesListViewTable from "./SalesListViewTable";
// style
import styles from "./Sales.module.scss";

/** ### General-use table for any top-level list-view within `Sales` */
const SalesListViewLayout: FC = () => (
  <div className={styles.container}>
    <ViewHeader />
    <SalesListViewTable />
  </div>
);

export default SalesListViewLayout;
