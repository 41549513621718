export class CashSalesDb {
  constructor(
    public appRecId: number,
    public bNames: string | null,
    public buyerRecId: number,
    public cobuyRecId: number,
    public code3: string | null,
    public companyName: string | null,
    public compAppId: number,
    public compId: number,
    public invDays: number,
    public isCobuyer: boolean,
    public locId: number,
    public manLocation: number,
    public numTd: number,
    public orgId: number,
    public purPrice: number | null,
    public profit: number,
    public region: string | null,
    public ror: number,
    public saleDate: Date,
    public saleStatus: string | null,
    public saleType: string | null,
    public shortName: string | null,
    public soldPrice: number | null,
    public stockNum: string | null,
    public totalCost: number,
    public totExp: number | null,
    public vehRecId: number,
    public vehYmm: string | null,
    public vin?: string
  ) {}

  static new(newCashSalesRow: CashSalesDb): CashSalesDb {
    return new CashSalesDb(
      newCashSalesRow.appRecId,
      newCashSalesRow.bNames,
      newCashSalesRow.buyerRecId,
      newCashSalesRow.cobuyRecId,
      newCashSalesRow.code3,
      newCashSalesRow.companyName,
      newCashSalesRow.compAppId,
      newCashSalesRow.compId,
      newCashSalesRow.invDays,
      newCashSalesRow.isCobuyer,
      newCashSalesRow.locId,
      newCashSalesRow.manLocation,
      newCashSalesRow.numTd,
      newCashSalesRow.orgId,
      newCashSalesRow.purPrice,
      newCashSalesRow.profit,
      newCashSalesRow.region,
      newCashSalesRow.ror,
      newCashSalesRow.saleDate,
      newCashSalesRow.saleStatus,
      newCashSalesRow.saleType,
      newCashSalesRow.shortName,
      newCashSalesRow.soldPrice,
      newCashSalesRow.stockNum,
      newCashSalesRow.totalCost,
      newCashSalesRow.totExp,
      newCashSalesRow.vehRecId,
      newCashSalesRow.vehYmm,
      newCashSalesRow.vin
    );
  }
}

export type ICashSalesDb = CashSalesDb;
