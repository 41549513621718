import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import Header from "./Header";
import EvalResultsSection from "./EvalResultsSection";
import AlertsSection from "./AlertsSection";
import DealSummarySection from "./DealSummarySection";
// state
import AiUnderwritingProvider from "./AiUnderwritingProvider";

/** ### Layout for "AutoLend IQ" (AI-Assisted Underwriting) tab-view
 * - Breadcrumb: Sales (view) > Applications (list) > Application (tab) > AI-Assisted Underwriting
 */
const AiUnderwritingTab: FC = () => (
  <AiUnderwritingProvider>
    <Grid container direction="column" flex={1}>
      <Header />

      <Grid container direction="column">
        <EvalResultsSection isInModal={false} />
        <AlertsSection isInModal={false} />
        <DealSummarySection />
      </Grid>
    </Grid>
  </AiUnderwritingProvider>
);

export default AiUnderwritingTab;
