import { Button, DropdownInput, NumberInput, PhoneInput, TextArea, TextInput } from '@/components';
import { BasicTable } from '@/components/table/Table';
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import { MultipleInputRowRadioButton } from '@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { contactStatusData } from '@/utils/helpers/sales';
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import styles from '../../Verification.module.scss';
import { useVerificationForm } from '../useVerificationForm';
import { usaStateCodes } from '@/general/regions';

export const ReferenceVerificationForm = () => {
  const { verificationListItem, verificationItemLoading } = useSalesSelector((s) => s);
  const { contactType, contactRecId } = verificationListItem;
  const {
    referenceVerificationForm,
    addressColumn,
    employerColumn,
    personalContactColumn,
    buyerInformationVerificationItems,
    employerInformationVerificationItems,
    personalContactVerificationItems,
    updateReferenceVerification,
    updateVerificationLoading,
  } = useVerificationForm(contactType, contactRecId, verificationListItem);
  const { control, watch, handleSubmit, setValue } = referenceVerificationForm;

  if (verificationItemLoading) {
    return (
      <div className={styles.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(updateReferenceVerification)}>
      <section className={styles.section}>
        <SectionHeader title="Reference Information" />
        <div className={styles.bodyContainer}>
          <div className={styles.bodyChild}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextInput label="Name" {...field} />}
            />
            <Controller
              name="relationship"
              control={control}
              render={({ field }) => <TextInput label="Relationship" {...field} />}
            />
            <Controller
              name="referenceFor"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Reference For" data={['Buyer', 'Co-Buyer']} {...field} />
              )}
            />
            <MultipleInputRow label="Years/Months Known">
              <Controller
                name="yearsKnown"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
              <Controller
                name="monthsKnown"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
            </MultipleInputRow>
            <Controller
              name="address"
              control={control}
              render={({ field }) => <TextInput label="Address" {...field} />}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => <TextInput label="City" {...field} />}
            />
            <MultipleInputRow label="State/Zip">
              <Controller
                name="state"
                control={control}
                render={({ field }) => <DropdownInput suggest data={usaStateCodes} {...field} />}
              />
              <Controller
                name="zip"
                control={control}
                render={({ field }) => <TextInput {...field} />}
              />
            </MultipleInputRow>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => <TextArea rows={3} label="Comments" {...field} />}
            />
          </div>
          <div className={styles.bodyChild}>
            <div className={styles.bodyChild}>
              <Controller
                name="spokeTo"
                control={control}
                render={({ field }) => <TextInput label="Spoke To" {...field} />}
              />
              <Controller
                name="lastContact"
                control={control}
                render={({ field }) => <TextInput label="Last Contact" readOnly {...field} />}
              />
              <Controller
                name="contactStatus"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <DropdownInput
                    label="Contact Status"
                    data={contactStatusData}
                    {...restProps}
                    onChange={(e) => {
                      onChange(e);
                      setValue('lastContact', dayjs().format('MM/DD/YYYY hh:mm A'));
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.bodyChild}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <TextInput label="Email" {...field} />}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => <PhoneInput label="Phone Number" {...field} />}
              />
              <Controller
                name="dealership"
                control={control}
                render={({ field }) => <TextInput label="Dealership" {...field} />}
              />
              <MultipleInputRowRadioButton label="Current Customer">
                <Controller
                  name="currentCustomer"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="N/A"
                        defaultChecked={watch('currentCustomer') === 'N/A'}
                        value="N/A"
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="currentCustomer"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Current"
                        defaultChecked={watch('currentCustomer') === 'Current'}
                        value="Current"
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="currentCustomer"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Previous"
                        defaultChecked={watch('currentCustomer') === 'Previous'}
                        value="Previous"
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </MultipleInputRowRadioButton>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Submit"
            fillMode="outline"
            buttonStyle={{ width: '160px' }}
            loading={updateVerificationLoading}
            secondary
          />
        </div>
      </section>
      <section className={styles.section}>
        <SectionHeader title="Contact Information" />
        <BasicTable data={buyerInformationVerificationItems} columns={addressColumn} />
        <BasicTable data={employerInformationVerificationItems} columns={employerColumn} />
        <BasicTable data={personalContactVerificationItems} columns={personalContactColumn} />
      </section>
    </form>
  );
};
