// validation
import { BasePmtFormSchema, ExternalFields } from "./formValidation";
import { defineFormConfig } from "@/utils/forms/useValidatedForm/fieldConfig/defineFormConfig";
import { zAddIssue, zAddRulesIssue } from "@/utils/forms/zod";
// utils
import {
  calcCcAch,
  calcChangeDue,
  calcConvFee,
  getConvenienceFeeByProviderMisc,
} from "./formCalculations/readonlyValues";
// utils
import {
  registerAchPmtFields,
  registerBillingFields,
  registerCcBillingFields,
  registerLcFields,
} from "./formCalculations/pmtFormRegisterOn";
import {
  calcCarPmtEffect,
  calcTotalReceived,
  calcTotalReceivedOnTotalPaymentChange,
  lcPaidEffect,
  lcWaivedEffect,
  transactionTypeEffect,
} from "./formCalculations/fieldEffects";
import { getEnabledPmtMethodsFormCalc } from "./pmtFormProviderUtils";
// interfaces
import { type IPaymentProviderConfigRes } from "@/interfaces/payment";

/** Calculation which runs within an effect
 * Runs when any of the following fields changes:
 * - [cpiPaid, ddPmt, lcPaid, nsfPaid, carPmt, paidBy, convFee, waiveConvFee]
 */
export const paymentFormCfg = defineFormConfig({
  formSchema: BasePmtFormSchema,
  externalSchema: ExternalFields,
  fields: {
    useSavedPaymentMethod: { registerOn: (p) => calcCcAch(p.form).isCcOrAch }, //
    paidBy: {
      changeEvent: (p) => {
        // @todo add cond:
        //   - if enabledPmtMethods, useSavedPmtMethod should default to true & the existing/first pmt-method should be selected

        // Reset if not CC or ACH, or no pmt methods available
        const arePmtMethodsAvailable =
          p.calculated.enabledPmtMethods && p.calculated.enabledPmtMethods.length > 0;
        const newUseSavedPmtMethod =
          !calcCcAch(p.form).isCcOrAch || !arePmtMethodsAvailable
            ? false
            : p.form.useSavedPaymentMethod;
        return { totalReceived: calcTotalReceived(p), useSavedPaymentMethod: newUseSavedPmtMethod };
      },
    },
    //
    billAddress: { registerOn: (p) => registerCcBillingFields(p) },
    billCity: { registerOn: (p) => registerCcBillingFields(p) },
    billState: { registerOn: (p) => registerCcBillingFields(p) },
    billZip: { registerOn: (p) => registerCcBillingFields(p) },
    //
    billFirstName: { registerOn: (p) => registerBillingFields(p) && !p.external.isWsPmt },
    billLastName: { registerOn: (p) => registerBillingFields(p) },
    billEmail: { registerOn: (p) => registerBillingFields(p) },
    //
    ddPmt: {
      registerOn: (p) => !p.external.isPrincipalOnly && !!p.external.defDownBal,
      changeEvent: (params) => ({
        carPmt: calcCarPmtEffect(params),
        totalReceived: calcTotalReceived(params),
      }),
    },

    // Late Charge fields
    lcPaid: {
      registerOn: (p) => registerLcFields(p),
      changeEvent: (params) => lcPaidEffect(params),
    },
    mCat: {
      registerOn: (p) => p.external.isMiscPmt,
    },
    payToFrom: {
      registerOn: (p) => p.external.isMiscPmt,
    },
    lcWaived: {
      registerOn: (p) => registerLcFields(p),
      changeEvent: (p) => ({ ...lcWaivedEffect(p) }),
    },
    //
    mpd: { registerOn: (p) => p.form.provider === "OpenEdge" && registerAchPmtFields(p) },
    saveCard: { registerOn: (p) => !p.external.isMiscPmt && registerBillingFields(p) },
    mpdRecId: {
      registerOn: (p) => p.form.useSavedPaymentMethod,
      rules: (p, ctx, k) => {
        const args = [p.form, ctx] as [typeof p.form, typeof ctx];
        if (p.form.useSavedPaymentMethod && !p.form.mpdRecId) {
          zAddIssue(args, k, "No mpdRecId");
        }
      },
    },
    //
    transactionType: {
      registerOn: (p) => !p.external.isPrincipalOnly && !p.external.isDownPmt,
      changeEvent: (p) => transactionTypeEffect(p),
    },
    nsfPaid: {
      registerOn: (p) => !p.external.isPrincipalOnly && !!p.external.nsfDue,
      changeEvent: (params) => ({
        carPmt: calcCarPmtEffect(params),
        totalReceived: calcTotalReceived(params),
      }),
    }, // registerNsfPaid
    achAcctType: { registerOn: (p) => registerAchPmtFields(p) }, //
    // registerCpiPaid
    cpiPaid: {
      registerOn: (p) => !p.external.isPrincipalOnly && !!p.external.onCpi,
      changeEvent: (params) => ({
        carPmt: calcCarPmtEffect(params),
        totalReceived: calcTotalReceived(params),
      }),
    },
    sendB: { registerOn: (p) => !!p.form.billEmail && !p.external.buyerNoEmail },
    sendC: { registerOn: (p) => !!p.external.cobuyerEmail && !p.external.cobuyerNoEmail },

    waiveConvFee: {
      registerOn: (p) => calcCcAch(p.form).isCcOrAch && p.external.canWaiveFee,
      changeEvent: (params) => ({ totalReceived: calcTotalReceived(params) }),
    },

    totalReceived: {
      changeEvent: (params) => ({ carPmt: calcCarPmtEffect(params) }),
      rules: (values, ...args) => {
        const { form: f, external: e, calculated: c } = values;

        if (e.isPrincipalOnly) {
          f.totalReceived < f.carPmt &&
            zAddRulesIssue(args, "Amount tendered cannot be less than the principal payment");
        } else {
          f.totalReceived < f.totalPayment &&
            zAddRulesIssue(args, "Amount tendered cannot be less than the total payment");
        }
      },
    },
    totalPayment: {
      changeEvent: (p) => ({
        carPmt: calcCarPmtEffect(p),
        totalReceived: calcTotalReceivedOnTotalPaymentChange(p),
      }),
      rules: ({ form, external }, _ctx, k) => {
        const args = [form, _ctx] as [typeof form, typeof _ctx];

        if (!external.isZeroAuth && !external.isMiscPmt) {
          if (!external.isPrincipalOnly) {
            if (!form.totalPayment || form.totalPayment < 0) {
              zAddIssue(args, k, "Total Payment must be a positive number");
            }
            if (form.totalPayment > external.maxPayment) {
              const msg = `Total Payment cannot exceed maximum payment of ${external.maxPayment}`;
              zAddIssue(args, k, msg);
            }
          }
        }
      },
    },
    carPmt: {
      registerOn: (p) => !p.external.isDownPmt,
      changeEvent: (params) => ({ totalReceived: calcTotalReceived(params) }),
      rules: ({ form: f, external: e, calculated: c }, _ctx, k) => {
        const args = [f, _ctx] as [typeof f, typeof _ctx];

        if (!e.isZeroAuth && !e.isMiscPmt) {
          if (e.isPrincipalOnly) {
            f.carPmt <= 0 && zAddIssue(args, k, "Payment must be greater than 0");
            f.carPmt > (e.prinBal ?? 0) && zAddIssue(args, k, "Cannot exceed principal balance");
          }
          if (!e.isPrincipalOnly) {
            f.carPmt < 0 && zAddIssue(args, k, "Payment cannot be negative");
          }
        }
      },
    },
  },
  calcValues: (form, ext) => {
    const convFee = ext?.isMiscPmt
      ? getConvenienceFeeByProviderMisc(form, ext.paymentProviders as IPaymentProviderConfigRes)
      : calcConvFee(form, ext);
    const maxLateCharge = ext?.lcDue ?? 0;
    const lcOwed = maxLateCharge - form.lcPaid - form.lcWaived;
    const changeDue = calcChangeDue(form, { isPrincipalOnly: ext?.isPrincipalOnly ?? false });

    const enabledPmtMethods = getEnabledPmtMethodsFormCalc(
      ext?.enabledPmtProviders,
      ext?.validPmtMethods,
      form
    );

    return { convFee, lcOwed, changeDue, enabledPmtMethods, ...calcCcAch(form) } as const;
  },
});
