import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
// state
import { IAccountsViewCtx } from '../accountsViewState';
import { ITableCtx } from '../tableState';
import { IPmtSubviewCtx } from './pmtSubviewState';
// utils
import { paymentService } from '@/services/paymentService';
// interfaces
import { IPmtSubviewReq } from './interfaces';
import { AllowedFilterKey } from './default';
import { PmtSubviewKey } from '../viewInfo';
import { Filter } from '@/interfaces/requests';

export const fetchPmtSubviewList = async (
  viewState: IAccountsViewCtx,
  tableState: ITableCtx,
  pmtSubviewState: IPmtSubviewCtx
) => {
  const { setDbCt, setIsLoading, subview } = viewState;
  const { textSearchFilter, page, queryPageNumber, sort, filter } = tableState;
  const { setPmtSubviewList } = pmtSubviewState;

  if (tableState.isDateFilterError) return;

  setIsLoading(true);

  // @note this is a jury rig - collect CATEGORY filters into arrays grouped by category/field
  // if there are more than one filters with identical field names, convert string to parentheses
  const filterMap = new Map<AllowedFilterKey, string[]>();
  filter
    .filter((f) => f.operator === 'IN')
    .forEach((f) => {
      const field = f.field as AllowedFilterKey;
      const origValues = filterMap.get(field);
      const sqlValue = `'${f.value}'`;
      if (origValues) {
        const updated = [...origValues, sqlValue];
        filterMap.set(field, updated);
      } else {
        filterMap.set(field, [sqlValue]);
      }
    });

  const updatedFilter = filter.filter((f) => f.operator !== 'IN');
  // Only category filters (i.e. with the `IN` operator) exist within the map.
  filterMap.forEach((f, k) => {
    const newSqlStr = `(${f.join(',')})`;
    const newCombinedCategoryFilter: Filter = {
      field: k,
      operator: 'IN',
      value: newSqlStr,
    };
    updatedFilter.push(newCombinedCategoryFilter);
  });

  const payload: IPmtSubviewReq = {
    subview: subview as PmtSubviewKey,
    search: textSearchFilter || '',
    page: queryPageNumber,
    perPage: page.take,
    sorts: sort.map((s) => s.toSortApi()),
    filters: updatedFilter,
  };

  try {
    const { data, total } = await paymentService.getPaymentSubviewList(payload);
    setPmtSubviewList(data);
    setDbCt(total);
  } catch (err_: unknown) {
    const err = err_ as AxiosError<IPmtSubviewReq>;
    if (err.code === 'ERR_NETWORK') {
      toast.error('Network error');
    } else {
      console.error(err.code);
      toast.error('Failed to load payments');
    }
  }

  setIsLoading(false);
};
