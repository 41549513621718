import { FC, createRef, useState } from 'react';
import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror,
  EditorChangeEvent,
} from '@progress/kendo-react-editor';
import styles from '../activityPanel.module.scss';
import { Button } from '../../button/Button';

interface NotesTextBoxProps {
  handleNoteSubmit: (submittedText: string) => Promise<boolean>;
}

const { Indent, Outdent } = EditorTools;

export const NotesTextBox: FC<NotesTextBoxProps> = (props) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [value, setValue] = useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      '<p></p>'
    )
  );
  const editor = createRef<Editor>();
  const onChange = (event: EditorChangeEvent) => {
    setValue(event.value);
  };

  const onMount = (event: any) => {
    const styles = `
      p { font-family: Arial }
    `;
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    if (!editor.current?.view) return;
    //TODO: FIND WAY TO RESET EDITOR AFTER SUBMIT

    const selectionHtml = EditorUtils.getHtml(editor.current?.view?.state) || '<div></div>';
    EditorUtils.setHtml(editor.current?.view, '<p></p>');

    // eslint-disable-next-line prettier/prettier
    let strippedHtml = selectionHtml.replaceAll('</p>', '\n</p>');
    strippedHtml = strippedHtml.replace(/<[^>]+>/g, '');

    await props.handleNoteSubmit(strippedHtml);
    setButtonLoading(false);
  };

  return (
    <div className={styles.notesTextBoxContainer}>
      <Editor
        value={value}
        onChange={onChange}
        onMount={onMount}
        tools={[[Indent, Outdent]]}
        contentStyle={{ height: '15rem ', fontFamily: 'sans-serif' }}
        ref={editor}
      />
      <div className={styles.footer}>
        <div className={styles.button}>
          <Button
            loading={buttonLoading}
            label="Submit"
            buttonStyle={{ width: '100%' }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
