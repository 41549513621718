import { FC, PropsWithChildren, useEffect, useRef } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

function useOutsideAlerter(ref: any, closeModal: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      event.stopPropagation();

      if (ref.current && ref.current === event.target) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const modalContainer: SxProps = {
  position: "fixed",
  zIndex: "3",
  left: "0",
  top: "0",
  width: "100%",
  height: "100%",
  overflow: "auto",
  transition: "visibility 0s, opacity 0.2s ease",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",

  "& > div": {
    position: "relative",
    width: "fit-content",
    opacity: "1 !important",
    zIndex: "4 !important",
    cursor: "default",
  },
};
const modalContainerBlur: SxProps = {
  backgroundColor: "#848282",
  background: "rgba(0,0,0,0.2)",
  backdropFilter: "blur(2px)",
};

/** ### Simple modal
 * #### Usage:
 * ```tsx
 * <Modal open={open} closeModal={closeModal}>
 *   <div>Modal Content</div>
 * </Modal>
 * ```
 *
 * @deprecated use `CommonModal` (MUI implementation)
 */
const LightModal: FC<
  PropsWithChildren & { open: boolean; closeModal: () => void; blur?: boolean }
> = ({ open, closeModal, children, blur = true }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeModal);
  if (!open) return null;

  return (
    <Grid
      container
      direction="column"
      sx={{ ...modalContainer, ...(blur ? modalContainerBlur : {}) }}
      ref={wrapperRef}
    >
      {children}
    </Grid>
  );
};

export default LightModal;
