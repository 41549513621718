export const calculateAdjCapCost = (
  sellingPrice: number,
  downPayment: number,
  miscFees: number[]
) => {
  let adjCapCost = sellingPrice;
  miscFees.forEach((fee) => (adjCapCost += fee));
  adjCapCost = adjCapCost - downPayment;
  return adjCapCost;
};

export const calculateMonthlyRentCharge = (
  adjCapCost: number,
  residualValue: number,
  interestRate: number
) => {
  return (adjCapCost + residualValue) * (interestRate / 24);
};

export const calculateMonthlyDepreciation = (
  adjCapCost: number,
  residualValue: number,
  term: number
) => {
  return (adjCapCost - residualValue) / term;
};

export const calculateLeasePayment = (
  residualValue: number,
  adjCapCost: number,
  term: number,
  interestRate: number,
  numberOfPayments: number
) => {
  const totalDepreciation = calculateMonthlyDepreciation(adjCapCost, residualValue, term) * term;
  const totalRentCharge =
    calculateMonthlyRentCharge(adjCapCost, residualValue, interestRate) * term;

  return (totalDepreciation + totalRentCharge) / numberOfPayments;
};

export const calculateFutureValue = (
  rate: number,
  numPeriods: number,
  paymentAmount: number,
  presentValue: number,
  type?: number
) => {
  const tempType = type ? type : 0;
  if (rate === 0) {
    return -presentValue - paymentAmount * numPeriods;
  } else {
    const templet = tempType !== 0 ? 1 + rate : 1;
    const tempVar2 = 1 + rate;
    const tempVar3 = Math.pow(tempVar2, numPeriods);

    return -presentValue * tempVar3 - (paymentAmount / rate) * templet * (tempVar3 - 1);
  }
};

export const calculateFinancePayment = (
  totalFinanced: number,
  interestRate: number,
  term: number
) => {
  let rval = 0;
  if (term === 0) {
    rval = 0;
  } else if (interestRate === 0) {
    rval = totalFinanced / term;
  } else {
    rval = (interestRate * totalFinanced) / (1 - Math.pow(1 + interestRate, term * -1));
  }
  return rval;
};

export const calculateLeasePayoff = (
  netCapCost: number,
  afterNumberOfMonths: number,
  residualValue: number,
  interestRate: number,
  term: number,
  securityDeposit: number
) => {
  const monthlyDepreciation = (netCapCost - residualValue) / term;
  const oneMonthsRent = (netCapCost + residualValue) * (interestRate / 24);
  return netCapCost - afterNumberOfMonths * monthlyDepreciation + oneMonthsRent - securityDeposit;
};

export const compareDate = (
  beginningDate: Date,
  comparisonSign: '>' | '<',
  endDate: string | Date
) => {
  let rrl = 0;
  const BegDate = new Date(beginningDate);
  const EndDate = new Date(endDate);
  switch (comparisonSign) {
    case '>':
      if (BegDate > EndDate) {
        rrl = 1;
      }
      break;
    case '<':
      if (BegDate < EndDate) {
        rrl = 1;
      }
      break;
    default:
      rrl = 0;
  }
  return rrl;
};

/** @deprecated this does not need to exist - use dayjs */
export const addDays = (begdate: string | Date, numberOfDays: number) => {
  const startDate = new Date(begdate);
  const returnDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() + numberOfDays,
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds()
  );
  return returnDate;
};

/** @deprecated this is slightly different than the implementation in financeTerms.ts */
const addSMDays = (date: Date, numsu: number) => {
  const SMDate = new Date(date);
  const SM1 = SMDate.getDate();
  let SDate;
  let numm = 0;
  if (numsu - 2 * Math.floor(numsu / 2) == 0) {
    SDate = date;
    numm = Math.floor(numsu / 2);
  } else {
    if (SM1 > 15) {
      SDate = addDays(date, -15);
      numm = 1 + Math.floor(numsu / 2);
    } else {
      SDate = addDays(date, 15);
      numm = Math.floor(numsu / 2);
    }
  }
  const newdate = new Date(SDate);
  let mm = newdate.getMonth();
  let yy = newdate.getFullYear();
  let xx = 0;
  mm = mm + numm;
  xx = Math.floor((mm - 1) / 12);
  mm = mm - xx * 12;
  yy = yy + xx;
  newdate.setFullYear(yy, mm);
  const n = newdate; // .toLocaleDateString();
  return n;
};

/** @deprecated this does not need to exist and may be inaccurate - use dayjs */
export const addMonths = (date: string | Date, number: number) => {
  const newdate = new Date(date);
  let mm = newdate.getMonth();
  let yy = newdate.getFullYear();
  let xx = 0;
  mm = mm + number;
  xx = Math.floor((mm - 1) / 12);
  mm = mm - xx * 12;
  yy = yy + xx;
  newdate.setFullYear(yy, mm);
  const n = newdate; // .toLocaleDateString();
  return n;
};

/** @deprecated this does not need to exist and may be inaccurate - use dayjs */
export const numDays = (beginningDate: string | Date, endDate: string | Date) => {
  const OneDay = 1000 * 60 * 60 * 24;
  const BegDate = new Date(beginningDate);
  const EndDate = new Date(endDate);
  BegDate.setHours(0, 0, 0, 0);
  EndDate.setHours(0, 0, 0, 0);
  const begday = Math.floor(BegDate.getTime() / OneDay);
  const endday = Math.floor(EndDate.getTime() / OneDay);
  const n = endday - begday;
  return n;
};

export const getOddDays = (schedule: string, beginningDate: Date, endDate: Date) => {
  let rval = 0;
  let units = 0;
  let daze = 0;
  let estunits = 0;
  let newDate;
  switch (schedule) {
    case 'Weekly':
      daze = numDays(beginningDate, endDate) - 7;
      units = Math.floor(daze / 7);
      rval = daze - units * 7;
      break;
    case 'Bi-Weekly':
      daze = numDays(beginningDate, endDate) - 14;
      units = Math.floor(daze / 14);
      rval = daze - units * 14;
      break;
    case 'Semi-Monthly':
      estunits = Math.floor(numDays(beginningDate, endDate) / 15);
      newDate = addSMDays(endDate, -estunits);
      while (compareDate(newDate, '>', beginningDate)) {
        estunits = estunits + 1;
        newDate = addSMDays(endDate, -estunits);
      }
      estunits = estunits - 1;
      newDate = addSMDays(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      if (daze > 15) {
        estunits = estunits + 1;
        daze = 0;
      }
      newDate = addSMDays(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      rval = daze;
      break;
    default:
      estunits = Math.floor(numDays(endDate, beginningDate) / 30);
      newDate = addMonths(endDate, -estunits);
      while (compareDate(newDate, '>', beginningDate)) {
        estunits = estunits + 1;
        newDate = addMonths(endDate, -estunits);
      }
      estunits = estunits - 1;
      newDate = addMonths(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      if (daze > 29) {
        estunits = estunits + 1;
        daze = 0;
      }
      units = estunits - 1;
      rval = daze;
  }
  return rval;
};

/** @deprecated move to appropriate scope */
export const contactStatusData = [
  'Made Contact',
  'Left Voicemail',
  'No Answer',
  'Disconnected',
  'Voicemail Full',
  'Wrong Number',
  'Busy',
  'Pickup/Hangup',
  'Number changed no forward',
  'Number changed with forward',
  'Other',
];
