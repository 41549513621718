import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// interfaces
import { UnreadSMS } from './interfaces';
import { companyService } from '@/services/companyService';
import { useAuthSelector } from '../auth/authSlice';
import { useAuthCtx } from '@/AppProviders/AuthProvider';

export interface INotificationsCtx {
  loadUnreadSms: (currentLocId?: number) => Promise<void>;
  unreadSms: UnreadSMS[];
  setUnreadSms: Dispatch<UnreadSMS[]>;
  unreadSmsOpen: boolean;
  setUnreadSmsOpen: Dispatch<SetStateAction<boolean>>;
  selectedSms: UnreadSMS | null;
  setSelectedSms: Dispatch<SetStateAction<UnreadSMS | null>>;
}

const NotificationsCtx = createContext<INotificationsCtx | null>(null);

const NotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [unreadSms, setUnreadSms] = useState<UnreadSMS[]>([]);
  const [selectedSms, setSelectedSms] = useState<UnreadSMS | null>(null);
  const [unreadSmsOpen, setUnreadSmsOpen] = useState(false);

  const stateRef = useRef<UnreadSMS[]>([]);
  const locId = useAuthSelector((s) => s.locId);
  const { setUnreadSmsCount } = useAuthCtx((s) => s);

  const loadUnreadSms = useCallback(async () => {
    if (!locId) return;

    try {
      const unreadSms = await companyService.getUnreadSMS(locId);
      stateRef.current = unreadSms;
      setUnreadSms(unreadSms);
    } catch (error) {
      console.error('Issue loading unread SMS:', error);
    }
  }, [locId]);

  useEffect(() => {
    loadUnreadSms()
      .then(() => {
        const unreadCount = stateRef.current
          ? stateRef.current.reduce((accumulator, current) => {
              return accumulator + (current.unreadCount ?? 0);
            }, 0)
          : 0;

        setUnreadSmsCount(unreadCount);
      });
  }, [locId]);

  return (
    <NotificationsCtx.Provider
      value={{
        selectedSms,
        setSelectedSms,
        loadUnreadSms,
        unreadSms,
        setUnreadSms,
        unreadSmsOpen,
        setUnreadSmsOpen,
      }}
    >
      {children}
    </NotificationsCtx.Provider>
  );
};

export default NotificationsProvider;

export const useNotificationsCtx = <T,>(selector: (state: INotificationsCtx) => T): T => {
  const ctx = useContext(NotificationsCtx);
  if (!ctx) {
    throw new Error('useNotificationsCtx must be used within NotificationsProvider');
  }
  return selector(ctx);
};
