import { FC } from 'react';
import { TextInput } from '../../inputs';
import { useAuthSelector } from '@/features/auth/authSlice';
import Grid from '@mui/material/Unstable_Grid2';
import { CreditEducationSectionProps } from '@/interfaces/CreditEducation';
import TitleHeader from './TitleHeader';

const IntegrationInformation: FC<CreditEducationSectionProps> = (props) => {
  const { formData } = props;
  const hasAfsLevel = useAuthSelector((s) => s.hasAfsLevel);

  return (
    <>
      {!hasAfsLevel && (
        <Grid container direction="column" flex={1} gap={3}>
          <TitleHeader label="Integration Information" />

          <Grid container direction="row" flex={1} gap={4} marginTop={3}>
            <Grid container direction="column" flex={1} gap={2}>
              <TextInput
                disabled={true}
                label="AppBuyers.Rec_Id"
                value={formData?.appBuyersRecID ?? ''}
              />
              <TextInput
                disabled={true}
                label="CreditRepairCloud ID"
                value={formData?.creditRepairCloudID ?? ''}
              />
            </Grid>

            <Grid container direction="column" flex={1} gap={2}>
              <TextInput
                disabled={true}
                label="Tracking Token"
                value={formData?.trackingToken ?? ''}
              />
              <TextInput
                disabled={true}
                label="Customer Token"
                value={formData?.customerToken ?? ''}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default IntegrationInformation;
