import { DynamicData } from '@/services/collectionsService';
import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorProps,
  EditorTools,
  EditorUtils,
} from '@progress/kendo-react-all';

interface TextEditorProps extends EditorProps {
  setValue: (value: string) => void;
  editor: React.RefObject<Editor>;
  dynamicData: DynamicData[];
}

const editorStyles = `
p {
  font-family: 'Arial';
}
.variable {
    color: #53d2fa;
    position: relative;
}
.variable::before {
    content: '{';
}
.variable::after {
    content: '}';
}
.tooltip{
  display:none;

  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;

  position: absolute;
  z-index: 100;

  top: 1em;
  right: 2em;

  border-radius: .4em;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

`;

export const TextEditor = (props: TextEditorProps) => {
  const { setValue, editor, dynamicData, ...rest } = props;

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
  } = EditorTools;

  const dynamicStyles = dynamicData.map((d, i) => {
    const index = i.toString().padStart(4, '0');

    return `
    .dd${index}:hover ~ .dd${index} {
      display: inline-block;
    }
    `;
  });

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(editorStyles + dynamicStyles.join('')));
    iframeDocument.head.appendChild(style);
  };

  const handleOnBlur = () => {
    const html = EditorUtils.getHtml(editor.current!.view!.state);

    setEmailOutput(html, setValue);
  };

  const handleOnChange = (e: EditorChangeEvent) => {
    const html = e.html;

    const hiddenSpan = (keyword: string) => {
      const data = dynamicData.find((d) => d.code === keyword);
      let index = dynamicData.findIndex((d) => d.code === keyword).toString();

      index = index.padStart(4, '0');

      return `<span class="tooltip dd${index}" spellcheck="false">${data?.description}</span>`;
    };

    dynamicData.forEach((k, i) => {
      const keyword = k.code;
      if (html.includes(keyword)) {
        let k = keyword.slice(1);
        k = k.slice(0, k.length - 1);

        const index = i.toString().padStart(4, '0');

        const temp = html.replace(
          keyword,
          `<span title="Test" class="variable dd${index}">` + k + hiddenSpan(keyword) + `</span> `
        );

        EditorUtils.setHtml(editor.current!.view!, temp);
      }
    });
  };

  return (
    <div style={{ width: '100%', height: '25em' }}>
      <Editor
        style={{ width: '100%', height: '100%' }}
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [Subscript, Superscript],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize,
          FontName,
          FormatBlock,
          [Undo, Redo],
          [Link, Unlink, InsertImage, ViewHtml],
        ]}
        ref={editor}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onMount={onMount}
        {...rest}
      />
    </div>
  );
};

const removeHiddenSpan = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const toolTipLength = doc.getElementsByClassName('tooltip').length;

  for (let index = 0; index < toolTipLength; index++) {
    const element = doc.getElementsByClassName('tooltip')[0];
    element!.remove();
  }

  return doc.body.innerHTML;
};

const removeVariableSpan = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const variableLength = doc.getElementsByClassName('variable').length;

  for (let index = 0; index < variableLength; index++) {
    const element = doc.getElementsByClassName('variable')[0];

    const dd = element!.innerHTML;

    element!.replaceWith(`{${dd}}`);
  }

  return doc.body.innerHTML;
};

const removeCustomSpans = (html: string): string => {
  html = removeHiddenSpan(html);
  html = removeVariableSpan(html);

  return html;
};

export const setEmailOutput = (html: string, setEmail: (e: string) => void): string | undefined => {
  try {
    html = removeCustomSpans(html);
    setEmail(html);

    return html;
  } catch (error) {
    // @todo handle error properly
    console.error(error);

    return undefined;
  }
};
