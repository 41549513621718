import { toast } from 'react-toastify';
// utils
import { AxiosService } from '@services/axiosService';
import { CustomGridParams } from '@/hooks';
// interfaces
import { IDateRangeFilter } from '@/interfaces/tableState';
import { GridResponse, ServerGridOptions } from '@/interfaces/ServerGrids';
import { ApiResponse } from '@/interfaces/Api';
import {
  CollectionActivityRowData,
  OutForRepoRowData,
  TerminationRowData,
  IDashboardDataReq,
  IDashboardDataRes,
} from '@/features/Dashboard/interfaces';
import { ICompanyForm } from '@/interfaces/Company';
import { AgingByCompanyRes } from '@/features/Dashboard/dataDisplays/AgingReportChart/interfaces';

class DashboardService extends AxiosService {
  public constructor() {
    super();
  }

  async getCompanies(): Promise<ICompanyForm[]> {
    try {
      const { data } = await this.axios.get<{ data: ICompanyForm[] }>('/Dashboard/Companies');
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async fetchCardData(payload: IDashboardDataReq): Promise<IDashboardDataRes> {
    const reqBody = {
      ...payload,
      startDate: payload.dateRange.startDate.utcOffset(0).set('h', 0).toISOString(),
      endDate: payload.dateRange.endDate.utcOffset(0).set('h', 0).endOf('day').toISOString(),
    };

    try {
      const { data } = await this.axios.post<{ data: IDashboardDataRes }>(
        '/Dashboard/GetCardDisplayData',
        reqBody
      );
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** @deprecated not used */
  async getTotalPaymentsByCompany(dateRange: IDateRangeFilter, orgId: number) {
    try {
      const { data } = await this.axios.get<{ data: TotalPaymentsByCompany[] }>(
        `/Dashboard/TotalPaymentsByCompany?startDate=${dateRange.from}&endDate=${dateRange.to}&orgId=${orgId}`
      );
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** @deprecated move to GetCardDisplayData req */
  async getCollectionsAgingByCompany(orgId: number) {
    try {
      const { data } = await this.axios.get<{ data: AgingByCompanyRes[] }>(
        `/Dashboard/CollectionsAgingByCompany?orgId=${orgId}`
      );
      return data.data[0];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** @deprecated fix - convert to post req and pass in req body */
  async getPendingRepos(gridOptions: ServerGridOptions, customParams?: CustomGridParams) {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<OutForRepoRowData>>>(
        `/Dashboard/OutForRepoList`,
        {
          params: {
            ...gridOptions.toQueryParams(),
            ...customParams,
          },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load repossession data');
      throw e;
    }
  }

  /** @deprecated fix - convert to post req and pass in req body */
  async getTerminations(gridOptions: ServerGridOptions, customParams?: CustomGridParams) {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<TerminationRowData>>>(
        `/Dashboard/TerminationsList`,
        {
          params: {
            ...gridOptions.toQueryParams(),
            ...customParams,
          },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load termination data');
      throw e;
    }
  }

  /** @deprecated fix - convert to post req and pass in req body */
  // modify by collector
  async getCollectionActivity(gridOptions: ServerGridOptions, customParams?: CustomGridParams) {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<CollectionActivityRowData>>>(
        `/Dashboard/CollectionActivity`,
        {
          params: {
            ...gridOptions.toQueryParams(),
            ...customParams,
          },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load collection activity data');
      throw e;
    }
  }
}

export const dashboardService = new DashboardService();

export interface TotalPaymentsByCompany {
  compId?: number;
  companyName?: string;
  totalPayments?: number;
}
