import { FC, ReactNode } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { GridRenderCellParams } from '@mui/x-data-grid';
// interfaces
import { CollectionQueue } from '@/services/collectionsService';

/** ###  */
const BoolCell: FC<GridRenderCellParams<CollectionQueue>> = ({ row }) => {
  const valueFmt: ReactNode = row.autoTTP ? (
    <FaCheckCircle style={{ marginRight: '0.5em' }} />
  ) : (
    '--'
  );

  return (
    <Grid container alignItems="center" justifyContent="center">
      {valueFmt}
    </Grid>
  );
};

export default BoolCell;
