// @todo remove post-alpha
export const UNSAFE_getAlphaWindowCurrentValues = () => {
  // Alpha has some values we might want (e.g. the current user) on window.Solutions.currentValues[0]
  // We should not assume that they exist,
  // and we should never use them for anything sensitive since they are user-editable
  console.warn('Getting current values from alpha - this should not be used in the new UI');
  const alphaCurrentValues = window.Solutions?.currentValues ?? [];
  return alphaCurrentValues[0] ?? {};
};

export const alphaLogout = () => {
  const navTabs: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('td a');
  const logoutTab = Array.from(navTabs).find((navTab) => {
    const tabText = (navTab.textContent ?? '').toLowerCase();
    return tabText.includes('logout');
  });
  if (!logoutTab) {
    alert('Something went wrong. Please toggle to the old UI and use the logout button there');
    return;
  }
  logoutTab.click();
};
