import { FC } from 'react';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import { DropdownInput, NumberInput, TextInput, Button, PhoneInput, ZipInput } from '@/components';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { BasicTable } from '@/components/table/Table';
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import { MultipleInputRowRadioButton } from '@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { useVerificationForm } from '../useVerificationForm';
// utils
import { scheduleData } from '@/utils/helpers/general';
import { contactStatusData } from '@/utils/helpers/sales';
import { usaStateCodes } from '@/general/regions';
// style
import styles from '../../Verification.module.scss';

/** ###  */
export const EmployerVerificationForm: FC = () => {
  const { verificationListItem, verificationItemLoading } = useSalesSelector((s) => s);
  const { contactType, contactRecId } = verificationListItem;
  const {
    employerVerificationForm,
    addressColumn,
    employerColumn,
    personalContactColumn,
    buyerInformationVerificationItems,
    employerInformationVerificationItems,
    personalContactVerificationItems,
    updateEmployerVerification,
    updateVerificationLoading,
  } = useVerificationForm(contactType, contactRecId, verificationListItem);
  const { control, handleSubmit, setValue, watch } = employerVerificationForm;

  if (verificationItemLoading) {
    return (
      <div className={styles.loader}>
        <Loader size="large" />
      </div>
    );
  }

  // @todo move nested components to separate files
  return (
    <form onSubmit={handleSubmit(updateEmployerVerification)}>
      <section className={styles.section}>
        <SectionHeader title="Employer Information" />
        <div className={styles.bodyContainer}>
          <div className={styles.bodyChild}>
            <Controller
              name="primaryEmployer"
              control={control}
              render={({ field }) => <TextInput label="Primary Employer" {...field} />}
            />
            <Controller
              name="occupation"
              control={control}
              render={({ field }) => <TextInput label="Occupation" {...field} />}
            />
            <Controller
              name="supervisor"
              control={control}
              render={({ field }) => <TextInput label="Supervisor" {...field} />}
            />
            <MultipleInputRow label="Years/Months Worked">
              <Controller
                name="yearsWorked"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
              <Controller
                name="monthsWorked"
                control={control}
                render={({ field }) => <NumberInput {...field} />}
              />
            </MultipleInputRow>
            <MultipleInputRowRadioButton label="Employment Type">
              <Controller
                name="employmentType"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Full Time"
                      defaultChecked={watch('employmentType') === 'Full Time'}
                      value="Full Time"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Controller
                name="employmentType"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Part Time"
                      defaultChecked={watch('employmentType') === 'Part Time'}
                      value="Part Time"
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            <Controller
              name="payPeriod"
              control={control}
              render={({ field }) => (
                <DropdownInput data={scheduleData} label="Pay Period" {...field} />
              )}
            />
            <Controller
              name="takeHomePay"
              control={control}
              render={({ field }) => <CurrencyInput label="Take Home Pay" {...field} />}
            />
            <Controller
              name="nextPayday"
              control={control}
              render={({ field }) => <DateInput label="Next Payday" {...field} />}
            />
            <Controller
              name="paidOn"
              control={control}
              render={({ field }) => <TextInput label="Paid On" {...field} />}
            />
            <Controller
              name="hoursOrShift"
              control={control}
              render={({ field }) => <TextInput label="Hours/Shift" {...field} />}
            />
          </div>
          <div className={styles.bodyChild}>
            <div className={styles.bodyChild}>
              <Controller
                name="spokeTo"
                control={control}
                render={({ field }) => <TextInput label="Spoke To" {...field} />}
              />
              <Controller
                name="lastContact"
                control={control}
                render={({ field }) => <TextInput label="Last Contact" readOnly {...field} />}
              />
              <Controller
                name="contactStatus"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <DropdownInput
                    label="Contact Status"
                    data={contactStatusData}
                    {...restProps}
                    onChange={(e) => {
                      onChange(e);
                      setValue('lastContact', dayjs().format('MM/DD/YYYY hh:mm A'));
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.bodyChild}>
              <Controller
                name="employersAddress"
                control={control}
                render={({ field }) => <TextInput label="Employer's Address" {...field} />}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => <TextInput label="City" {...field} />}
              />
              <MultipleInputRow label="State/Zip">
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => <DropdownInput suggest data={usaStateCodes} {...field} />}
                />
                <Controller
                  name="zip"
                  control={control}
                  render={({ field }) => <ZipInput {...field} />}
                />
              </MultipleInputRow>
              <Controller
                name="employerPhoneNumber"
                control={control}
                render={({ field }) => <PhoneInput label="Phone Number" {...field} />}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Submit"
            fillMode="outline"
            buttonStyle={{ width: '140px' }}
            loading={updateVerificationLoading}
            secondary
          />
        </div>
      </section>
      <section className={styles.section}>
        <SectionHeader title="Contact Information" />
        <BasicTable data={buyerInformationVerificationItems} columns={addressColumn} />
        <BasicTable data={employerInformationVerificationItems} columns={employerColumn} />
        <BasicTable data={personalContactVerificationItems} columns={personalContactColumn} />
      </section>
    </form>
  );
};
