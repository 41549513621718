import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { ReportsSubviews, SubviewInfo, SubviewKey, viewInfoMap } from '../viewInfo';

// replace with `SubviewKey`
// export type ReportView = 'default' | 'emailReportSchedules';

export interface IReportsViewContext {
  subview: SubviewKey;
  setView: (subview: SubviewKey) => void;
  viewInfo: SubviewInfo;
  // Request variables
  dbCt: number;
  setDbCt: (dbCt: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  // Modal variables
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const ReportsViewContext = createContext<IReportsViewContext | null>(null);

export const ReportsViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [subview, setView] = useState<SubviewKey>(ReportsSubviews.default);
  // Request variables
  const [isLoading, setIsLoading] = useState(false);
  const [dbCt, setDbCt] = useState(0);
  // Modal variables
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ReportsViewContext.Provider
      value={{
        subview,
        setView,
        get viewInfo(): SubviewInfo {
          if (!this.subview) throw new Error(`Invalid subview: '${this.subview}'`);
          const viewInfoItem = viewInfoMap.get(this.subview);
          if (!viewInfoItem) throw new Error(`Invalid viewInfoItem- subview: '${this.subview}'`);

          return viewInfoItem;
        },
        // Request variables
        isLoading,
        setIsLoading,
        dbCt,
        setDbCt,
        // Modal variables
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </ReportsViewContext.Provider>
  );
};

export const useReportsViewCtx = <T,>(selector: (state: IReportsViewContext) => T): T => {
  const ctx = useContext(ReportsViewContext);
  if (!ctx) {
    throw new Error('useReportsViewCtx must be used within ReportsViewProvider');
  }
  return selector(ctx);
};
