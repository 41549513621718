import { FC } from "react";
import dayjs from "dayjs";
// icons
import { FaBan, FaCheck, FaExclamation, FaReply, FaSyncAlt } from "react-icons/fa";
// mui
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// state
import { useAcctDetailsCtx } from "../../AcctDetailProvider";

const CommonButton: FC<IconButtonProps> = ({ sx, children, ...props }) => (
  <IconButton
    type="button"
    sx={{
      color: "#fff",
      padding: "0px",
      minWidth: "30px",
      border: "1px solid",
      borderColor: "#00000029",
      borderLeft: "none",
      borderRadius: "0px 8px 8px 0px",
      ...sx,
    }}
    {...props}
  >
    {children}
  </IconButton>
);

export const UnsentButton: FC<IconButtonProps> = ({ sx, ...props }) => (
  <CommonButton sx={{ backgroundColor: "#294694", ...sx }} {...props}>
    <FaReply />
  </CommonButton>
);

export const DeniedButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);

  return (
    <Tooltip title={`Denied on ${dayjs(optIn.dcWorkflowEndUtc).utc().format("MM/DD/YYYY")}`} arrow>
      <CommonButton sx={{ backgroundColor: "#fbcc3e", ...sx }} {...props}>
        <FaBan />
      </CommonButton>
    </Tooltip>
  );
};

export const AcceptedButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);

  return (
    <Tooltip
      title={`Accepted on ${dayjs(optIn.dcWorkflowEndUtc).utc().format("MM/DD/YYYY")}`}
      arrow
    >
      <CommonButton sx={{ backgroundColor: "#129a38", ...sx }} {...props}>
        <FaCheck />
      </CommonButton>
    </Tooltip>
  );
};

export const PendingButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);

  return (
    <Tooltip
      title={`Pending since ${dayjs(optIn.dcWorkflowBeginUtc).utc().format("MM/DD/YYYY")}`}
      arrow
    >
      <CommonButton sx={{ backgroundColor: "#dddddd", ...sx }} {...props}>
        <FaSyncAlt />
      </CommonButton>
    </Tooltip>
  );
};

export const ErrorButton: FC<IconButtonProps> = ({ sx, ...props }) => (
  <Tooltip title="Error" arrow>
    <CommonButton sx={{ backgroundColor: "#c60000", ...sx }} {...props}>
      <FaExclamation />
    </CommonButton>
  </Tooltip>
);
