import { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import {  Button, DropdownInput, NumberInput, SocialSecurityInput, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import { MultipleInputRowRadioButton } from '@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { UnderwritingScore } from '@/features/Sales/components/underwritingScore/UnderwritingScore';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { formatCurrency } from '@/utils/helpers/general';
import {
  InterviewQuestionCoverageInsuranceValues,
  InterviewQuestionCreditCoachValues,
  useApplicationUnderwriting,
} from './useApplicationUnderwriting';
// style
import applicationTabStyle from '../ApplicationTabs.module.scss';
import styles from './ApplicationUnderwriting.module.scss';

export const ApplicationUnderwriting: FC = () => {
  const {
    control,
    handleSubmit,
    updateUnderwriting,
    updateUnderwritingLoading,
    hasCobuyer,
    watch,
    buyerCobuyerBothData,
    errors,
    isDisabled,
  } = useApplicationUnderwriting();
  const { saleData, saleDataLoading } = useSalesSelector((s) => s);

  const InterviewQuestion = (props: {
    question: string;
    questionNum: number;
    questionAnswerComponent: ReactNode;
    borderBottom?: boolean;
  }) => {
    const { borderBottom = true } = props;
    return (
      <div
        className={styles.questionContainer}
        style={{ borderBottom: borderBottom ? '1px solid #D9D9D9' : 'none' }}
      >
        <div className={styles.question}>
          {props.questionNum}. {props.question}
        </div>
        <div className={styles.answer}>{props.questionAnswerComponent}</div>
      </div>
    );
  };

  if (saleDataLoading) {
    return (
      <div className={applicationTabStyle.loading}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <div className={applicationTabStyle.tabContainer}>
      <form onSubmit={handleSubmit(updateUnderwriting)}>
        <section className={applicationTabStyle.section}>
          <SectionHeader title="Basic Buyer Information" />
          <div className={styles.buyerInfoRow}>
            <Controller
              name="buyerSsn"
              control={control}
              render={({ field }) => <SocialSecurityInput readOnly label="SSN/ITN" {...field} />}
            />
            <Controller
              name="buyerDriversLicenseNumber"
              control={control}
              render={({ field }) => <TextInput readOnly label="Driver's License #" {...field} />}
            />
            <Controller
              name="buyerDriversExpiration"
              control={control}
              render={({ field }) => <DateInput readOnly label="DL Expiration Date" {...field} />}
            />
          </div>
        </section>
        <section className={applicationTabStyle.section}>
          <SectionHeader title="General Questions" />
          <div className={applicationTabStyle.bodyContainer}>
            <MultipleInputRowRadioButton label="1. Do you own a checking account?">
              <Controller
                name="ownCheckingAccount"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      value="Yes"
                      disabled={isDisabled}
                      defaultChecked={watch('ownCheckingAccount') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="ownCheckingAccount"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      value="No"
                      disabled={isDisabled}
                      defaultChecked={watch('ownCheckingAccount') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('ownCheckingAccount') && (
              <div className={applicationTabStyle.questionChild}>
                {hasCobuyer && (
                  <Controller
                    name="checkingAccountBoth"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Buyer, Co-buyer, or Both"
                        disabled={isDisabled}
                        data={buyerCobuyerBothData}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch('ownCheckingAccount') === 'Yes' && (
                  <>
                    <Controller
                      name="checkingAccountWith"
                      control={control}
                      render={({ field }) => (
                        <TextInput label="Checking Account With" readOnly={isDisabled} {...field} />
                      )}
                    />
                    <Controller
                      name="checkingAccountBalance"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          label="Checking Account Balance"
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            )}
            <MultipleInputRowRadioButton label="2. Do you own a savings account?">
              <Controller
                name="ownSavingsAccount"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      value="Yes"
                      disabled={isDisabled}
                      defaultChecked={watch('ownSavingsAccount') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="ownSavingsAccount"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      disabled={isDisabled}
                      value="No"
                      defaultChecked={watch('ownSavingsAccount') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('ownSavingsAccount') && (
              <div className={applicationTabStyle.questionChild}>
                {hasCobuyer && (
                  <Controller
                    name="savingsAccountBoth"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Buyer, Co-buyer, or Both"
                        disabled={isDisabled}
                        data={buyerCobuyerBothData}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch('ownSavingsAccount') === 'Yes' && (
                  <>
                    <Controller
                      name="savingsAccountWith"
                      control={control}
                      render={({ field }) => (
                        <TextInput label="Savings Account With" readOnly={isDisabled} {...field} />
                      )}
                    />
                    <Controller
                      name="savingsAccountBalance"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          label="Savings Account Balance"
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            )}
            <MultipleInputRowRadioButton label="3. Do you drive for a ride-sharing or delivery service?">
              <Controller
                name="driveForRideSharing"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      value="Yes"
                      disabled={isDisabled}
                      defaultChecked={watch('driveForRideSharing') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="driveForRideSharing"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      value="No"
                      disabled={isDisabled}
                      defaultChecked={watch('driveForRideSharing') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('driveForRideSharing') && hasCobuyer && (
              <div className={applicationTabStyle.questionChild}>
                <Controller
                  name="garnishmentBoth"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      label="Buyer, Co-buyer, or Both"
                      disabled={isDisabled}
                      data={buyerCobuyerBothData}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
            <MultipleInputRowRadioButton label="4. Do you have any garnishments">
              <Controller
                name="haveAnyGarnishments"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      value="Yes"
                      disabled={isDisabled}
                      defaultChecked={watch('haveAnyGarnishments') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="haveAnyGarnishments"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      value="No"
                      disabled={isDisabled}
                      defaultChecked={watch('haveAnyGarnishments') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('haveAnyGarnishments') && (
              <div className={applicationTabStyle.questionChild}>
                {hasCobuyer && (
                  <Controller
                    name="garnishmentBoth"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Buyer, Co-buyer, or Both"
                        disabled={isDisabled}
                        data={buyerCobuyerBothData}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch('haveAnyGarnishments') === 'Yes' && (
                  <>
                    <Controller
                      name="garnishmentWith"
                      control={control}
                      render={({ field }) => (
                        <TextInput label="Garnishment With" readOnly={isDisabled} {...field} />
                      )}
                    />
                    <Controller
                      name="garnishmentAmount"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          label="Garnishment Amount"
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            )}
            <MultipleInputRowRadioButton label="5. Do you owe the IRS?">
              <Controller
                name="oweIrs"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      disabled={isDisabled}
                      value="Yes"
                      defaultChecked={watch('oweIrs') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="oweIrs"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      disabled={isDisabled}
                      value="No"
                      defaultChecked={watch('oweIrs') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('oweIrs') && (
              <div className={applicationTabStyle.questionChild}>
                {hasCobuyer && (
                  <Controller
                    name="oweIrsBoth"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Buyer, Co-buyer, or Both"
                        disabled={isDisabled}
                        data={buyerCobuyerBothData}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch('oweIrs') === 'Yes' && (
                  <>
                    <Controller
                      name="oweIrsAmount"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput label="Owe IRS Amount" readOnly={isDisabled} {...field} />
                      )}
                    />
                  </>
                )}
              </div>
            )}
            <MultipleInputRowRadioButton label="6. Have you ever filed for bankruptcy?">
              <Controller
                name="everFiledForBankruptcy"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="Yes"
                      disabled={isDisabled}
                      value="Yes"
                      defaultChecked={watch('everFiledForBankruptcy') === 'Yes'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
              <Spacer size={100} horizontal />
              <Controller
                name="everFiledForBankruptcy"
                control={control}
                render={({ field: { onChange, ...restProps } }) => (
                  <div>
                    <RadioButton
                      {...restProps}
                      label="No"
                      disabled={isDisabled}
                      value="No"
                      defaultChecked={watch('everFiledForBankruptcy') === 'No'}
                      onChange={(e) => onChange(e.value)}
                    />
                  </div>
                )}
              />
            </MultipleInputRowRadioButton>
            {!!watch('everFiledForBankruptcy') && (
              <div className={applicationTabStyle.questionChild}>
                {hasCobuyer && (
                  <Controller
                    name="bankruptcyBoth"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Buyer, Co-buyer, or Both"
                        disabled={isDisabled}
                        data={buyerCobuyerBothData}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch('everFiledForBankruptcy') === 'Yes' && (
                  <>
                    <MultipleInputRowRadioButton label="Bankruptcy Discharged?">
                      <Controller
                        name="bankruptcyDischargedYesNo"
                        control={control}
                        render={({ field: { onChange, ...restProps } }) => (
                          <div>
                            <RadioButton
                              {...restProps}
                              disabled={isDisabled}
                              label="Yes"
                              value="Yes"
                              onChange={(e) => onChange(e.value)}
                            />
                          </div>
                        )}
                      />
                      <Controller
                        name="bankruptcyDischargedYesNo"
                        control={control}
                        render={({ field: { onChange, ...restProps } }) => (
                          <div>
                            <RadioButton
                              {...restProps}
                              disabled={isDisabled}
                              label="No"
                              value="No"
                              onChange={(e) => onChange(e.value)}
                            />
                          </div>
                        )}
                      />
                    </MultipleInputRowRadioButton>
                    <Controller
                      name="bankruptcyDischargedDate"
                      control={control}
                      render={({ field }) => (
                        <DateInput label="Discharged Date" readOnly={isDisabled} {...field} />
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </section>
        <section>
          <SectionHeader title="Interview Questions" />
          <InterviewQuestion
            question="On a scale of 1 - 10, how important is improving your credit score?"
            questionNum={1}
            questionAnswerComponent={
              <Controller
                name="interviewQuestionOne"
                control={control}
                rules={{
                  max: { value: 10, message: 'Value cannot be greater than 10' },
                  min: { value: 1, message: 'Value cannot be less than 1' },
                }}
                render={({ field }) => (
                  <NumberInput
                    inputStyles={{ maxWidth: '175px' }}
                    errors={errors.interviewQuestionOne?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
            }
          />
          <InterviewQuestion
            question="On a scale of 1 - 10, how important is an early refinance/trade option?"
            questionNum={2}
            questionAnswerComponent={
              <Controller
                name="interviewQuestionThree"
                control={control}
                rules={{
                  max: { value: 10, message: 'Value cannot be greater than 10' },
                  min: { value: 1, message: 'Value cannot be less than 1' },
                }}
                render={({ field }) => (
                  <NumberInput
                    inputStyles={{ maxWidth: '175px' }}
                    readOnly={isDisabled}
                    errors={errors.interviewQuestionThree?.message}
                    {...field}
                  />
                )}
              />
            }
          />
          <InterviewQuestion
            question="Are you willing and able to work with a free credit coach today?"
            questionNum={3}
            questionAnswerComponent={
              <div className={styles.radioButtonGroup}>
                <Controller
                  name="interviewQuestionTwo"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Yes, I am willing to work with a free credit coach today."
                        defaultChecked={
                          saleData.sale?.interviewQuestionCreditCoach ===
                          InterviewQuestionCreditCoachValues.Yes
                        }
                        disabled={isDisabled}
                        value="Yes, I am willing to work with a free credit coach today."
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="interviewQuestionTwo"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Thank you, but I am not interested in working with a free credit coach."
                        value="Thank you, but I am not interested in working with a free credit coach."
                        defaultChecked={
                          saleData.sale?.interviewQuestionCreditCoach ===
                          InterviewQuestionCreditCoachValues.No
                        }
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="interviewQuestionTwo"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div className={styles.radioButton}>
                      <RadioButton
                        {...restProps}
                        label="I am willing to work with a free credit coach but not in a financial position to do so today."
                        value="I am willing to work with a free credit coach but not in a financial position to do so today."
                        defaultChecked={
                          saleData.sale?.interviewQuestionCreditCoach ===
                          InterviewQuestionCreditCoachValues.YesButCannot
                        }
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </div>
            }
          />
          <InterviewQuestion
            question="Are your credit difficulties behind you?"
            questionNum={4}
            questionAnswerComponent={
              <div className={styles.radioButtonGroup}>
                <Controller
                  name="interviewQuestionFour"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Yes, my credit difficulties are behind me."
                        defaultChecked={saleData.sale?.interviewQuestionCreditDifficulties === true}
                        disabled={isDisabled}
                        value="Yes, my credit difficulties are behind me."
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="interviewQuestionFour"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="No, I am still going through a credit difficulty situation."
                        value="No, I am still going through a credit difficulty situation."
                        defaultChecked={
                          saleData.sale?.interviewQuestionCreditDifficulties === false
                        }
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </div>
            }
          />
          <InterviewQuestion
            question="Full coverage auto insurance will be required for my next purchase."
            questionNum={5}
            questionAnswerComponent={
              <div className={styles.radioButtonGroup}>
                <Controller
                  name="interviewQuestionFive"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label={InterviewQuestionCoverageInsuranceValues.No}
                        defaultChecked={saleData.sale?.interviewQuestionCoverageInsurance === false}
                        value={InterviewQuestionCoverageInsuranceValues.No}
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="interviewQuestionFive"
                  control={control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label={InterviewQuestionCoverageInsuranceValues.Yes}
                        value={InterviewQuestionCoverageInsuranceValues.Yes}
                        defaultChecked={saleData.sale?.interviewQuestionCoverageInsurance === true}
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </div>
            }
          />
          <InterviewQuestion
            question="My estimated mileage is..."
            questionNum={6}
            borderBottom={false}
            questionAnswerComponent={
              <Controller
                name="interviewQuestionSix"
                control={control}
                rules={{ min: { value: 0, message: 'Value cannot be less than 0' } }}
                render={({ field }) => (
                  <NumberInput
                    inputStyles={{ maxWidth: '175px' }}
                    errors={errors.interviewQuestionSix?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
            }
          />
        </section>
        <section className={applicationTabStyle.section}>
          <SectionHeader title="Income Assessment" />
          <div className={applicationTabStyle.bodyContainer}>
            <div className={styles.income}>
              <MultipleInputRow>
                <div className={styles.columnLabel}>Buyer</div>
                {hasCobuyer && <div className={styles.columnLabel}>Co-Buyer</div>}
                <div className={styles.columnLabel}>Total</div>
              </MultipleInputRow>
              <MultipleInputRow label="Total Monthly Net Income">
                <Controller
                  name="buyerMonthlyNetIncome"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerMonthlyNetIncome"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalMonthlyNetIncome"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Rent/Mortgage">
                <Controller
                  name="buyerRentOrMortgage"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerRentOrMortgage"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalRentOrMortgage"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Other Auto Payment">
                <Controller
                  name="buyerOtherAutoPayment"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerOtherAutoPayment"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalOtherAutoPayment"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Auto Insurance Payment">
                <Controller
                  name="buyerAutoInsurancePayment"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerAutoInsurancePayment"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalAutoInsurancePayment"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Electric (Avg.)">
                <Controller
                  name="buyerElectric"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerElectric"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalElectric"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Utility/Gas Avg.">
                <Controller
                  name="buyerUtilityGas"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerUtilityGas"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalUtilityGas"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Credit Cards">
                <Controller
                  name="buyerCreditCards"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerCreditCards"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalCreditCards"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Miscellaneous">
                <Controller
                  name="buyerMiscellaneous"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerMiscellaneous"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalMiscellaneous"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Student Loans">
                <Controller
                  name="buyerStudentLoans"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerStudentLoans"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalStudentLoans"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Alimony/Child Support">
                <Controller
                  name="buyerAlimonyChildSupport"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerAlimonyChildSupport"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly={isDisabled} {...field} />}
                  />
                )}
                <Controller
                  name="totalAlimonyChildSupport"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Total Expenses">
                <Controller
                  name="buyerTotalExpenses"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerTotalExpenses"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly {...field} />}
                  />
                )}
                <Controller
                  name="totalExpenses"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Disposable Income">
                <Controller
                  name="buyerDisposableIncome"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
                {hasCobuyer && (
                  <Controller
                    name="coBuyerDisposableIncome"
                    control={control}
                    render={({ field }) => <CurrencyInput readOnly {...field} />}
                  />
                )}
                <Controller
                  name="totalDisposableIncome"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly {...field} />}
                />
              </MultipleInputRow>
            </div>
            <div className={styles.otherIncomeAssessment}>
              <div className={styles.otherIncomeAssessmentChild}>
                <h4 className={applicationTabStyle.subHeadingTwo}>Maximum Allowed Payment</h4>
                <Controller
                  name="maximumAllowedWeekly"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Weekly" readOnly {...field} />}
                />
                <Controller
                  name="maximumAllowedBiWeekly"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Bi-Weekly" readOnly {...field} />}
                />
                <Controller
                  name="maximumAllowedSemiMonthly"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Semi-Monthly" readOnly {...field} />}
                />
                <Controller
                  name="maximumAllowedMonthly"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Monthly" readOnly {...field} />}
                />
                <div className={styles.subLabel}>
                  {saleData.sale?.saletype} Payment: {formatCurrency(saleData.sale?.pmtAmt)}{' '}
                  {saleData.sale?.sched}
                </div>
              </div>
              <div className={styles.otherIncomeAssessmentChild}>
                <h4 className={applicationTabStyle.subHeadingTwo}>Credit Score</h4>
                <MultipleInputRow>
                  <div className={styles.columnLabel}>Buyer</div>
                  {hasCobuyer && <div className={styles.columnLabel}>Co-Buyer</div>}
                </MultipleInputRow>
                <MultipleInputRow label="Equifax">
                  <Controller
                    name="buyerEquifax"
                    control={control}
                    rules={{
                      min: { value: 300, message: 'Credit score cannot be less than 300' },
                      max: { value: 850, message: 'Credit score cannot be greater than 850' },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        errors={errors.buyerEquifax?.message}
                        readOnly={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  {hasCobuyer && (
                    <Controller
                      name="coBuyerEquifax"
                      control={control}
                      rules={{
                        min: { value: 300, message: 'Credit score cannot be less than 300' },
                        max: { value: 850, message: 'Credit score cannot be greater than 850' },
                      }}
                      render={({ field }) => (
                        <NumberInput
                          errors={errors.coBuyerEquifax?.message}
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  )}
                </MultipleInputRow>
                <MultipleInputRow label="TransUnion">
                  <Controller
                    name="buyerTransUnion"
                    control={control}
                    rules={{
                      min: { value: 300, message: 'Credit score cannot be less than 300' },
                      max: { value: 850, message: 'Credit score cannot be greater than 850' },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        errors={errors.buyerTransUnion?.message}
                        readOnly={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  {hasCobuyer && (
                    <Controller
                      name="coBuyerTransUnion"
                      control={control}
                      rules={{
                        min: { value: 300, message: 'Credit score cannot be less than 300' },
                        max: { value: 850, message: 'Credit score cannot be greater than 850' },
                      }}
                      render={({ field }) => (
                        <NumberInput
                          errors={errors.coBuyerTransUnion?.message}
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  )}
                </MultipleInputRow>
                <MultipleInputRow label="Experian">
                  <Controller
                    name="buyerExperian"
                    control={control}
                    rules={{
                      min: { value: 300, message: 'Credit score cannot be less than 300' },
                      max: { value: 850, message: 'Credit score cannot be greater than 850' },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        errors={errors.buyerExperian?.message}
                        readOnly={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  {hasCobuyer && (
                    <Controller
                      name="coBuyerExperian"
                      control={control}
                      rules={{
                        min: { value: 300, message: 'Credit score cannot be less than 300' },
                        max: { value: 850, message: 'Credit score cannot be greater than 850' },
                      }}
                      render={({ field }) => (
                        <NumberInput
                          errors={errors.coBuyerExperian?.message}
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  )}
                </MultipleInputRow>
              </div>
            </div>
          </div>
        </section>
        <section className={applicationTabStyle.section}>
          <SectionHeader title="DCL Sales Scoring Survey" />
          <UnderwritingScore />
        </section>
        <div className={applicationTabStyle.submitButton}>
          <div className={applicationTabStyle.sectionBar}></div>
          <Button
            label="Submit"
            buttonStyle={{ width: '140px' }}
            loading={updateUnderwritingLoading}
          />
        </div>
      </form>
    </div>
  );
};
