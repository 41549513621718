import { forwardRef, useMemo } from 'react';
// kendo
import { Label } from '@progress/kendo-react-all';
// interfaces
import { TextInputProps } from './interfaces';
// style
import styles from '@/components/inputs/text/TextInput.module.scss';
import textStyles from '@/components/text/Text.module.scss';

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    // @ts-ignore
    display,
    name,
    label,
    errors,
    required,
    boldLabel = true,
    horizontalLabel = true,
    className,
    inputStyles,
    containerStyles = {},
    value,
    placeholder = '',
    hidden,
    ...rest
  } = props;

  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  let fullClassName = errors ? styles.inputBoxError : styles.inputBox;

  if (className) {
    fullClassName += ` ${className}`;
  }

  const Input = (
    <input
      id={editorId}
      className={fullClassName}
      style={inputStyles}
      ref={ref}
      placeholder={placeholder}
      value={value === null ? placeholder : value}
      {...rest}
    />
  );

  return (
    <div className={styles.textContainer} style={containerStyles} hidden={hidden}>
      {label ? (
        horizontalLabel ? (
          <div className={styles.row}>
            <Label
              editorId={editorId}
              className={textStyles.label}
              style={{ fontWeight: boldLabel ? 700 : 400 }}
            >
              {`${label} ${required ? '*' : ''}`}
            </Label>
            <div className={styles.inputBoxContainer}>{Input}</div>
          </div>
        ) : (
          <Label
            editorId={editorId}
            className={textStyles.label}
            style={{ fontWeight: boldLabel ? 700 : 400 }}
          >
            {`${label} ${required ? '*' : ''}`}
            <div className={styles.inputBoxContainer}>{Input}</div>
          </Label>
        )
      ) : (
        <div className={styles.inputBoxContainer}>{Input}</div>
      )}
      {!!errors && typeof errors === 'string' && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
