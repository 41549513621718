import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateFilter from './tableFilterComponents/DateFilter/DateFilter';
import AppStatusFilter from './AppStatusFilter';
// state
import { useSalesListCtx } from '../SalesListProvider';
// interfaces
import { AppStatusFilterKey } from '../subviewConfigs/applications/dataModelConfig';

/** ### Set table filters here
 * - Renders filter(s) based on the types set in the column spec
 * - Handles conditional logic for determining if current subview allows filters
 * - String filters have a set amount column filter is a string filter
 */
const TableFilters: FC = () => {
  const dateRangeFilterLabel = useSalesListCtx((s) => s.subviewInfo.dateFilterField?.label);
  const categoryFilter = useSalesListCtx((s) => s.subviewInfo.categoryFilter);

  return (
    <Grid container direction="row" gap={1} alignItems="center">
      {/* <CategoryFilter field={categoryFilterField} /> */}
      {categoryFilter?.field === 'appStatus' && (
        <AppStatusFilter
          filterLabel={categoryFilter.label}
          optionsMap={categoryFilter.options as Map<AppStatusFilterKey, string>}
        />
      )}
      {dateRangeFilterLabel && <DateFilter label={dateRangeFilterLabel} />}
    </Grid>
  );
};

export default TableFilters;
