import { DynamicData } from '@/services/collectionsService';
import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorProps,
  EditorUtils,
  ProseMirror,
} from '@progress/kendo-react-all';
import { placeholder } from './Placeholder';
import { isNumber } from '@/utils/helpers/general';

interface TextEditorProps extends EditorProps {
  setValue: (value: string) => void;
  editor: React.RefObject<Editor>;
}

const editorStyles = `
    p.placeholder:first-child:before {
      content: attr(data-placeholder);
      float: left;
      color: rgb(0, 0, 0, 0.5);
      cursor: text;
      height: 0;
      font-style: italic;
    }
    p {
      font-family: 'Arial';
    }
    .variable {
        color: #53d2fa;
        position: relative;
    }
    .variable::before {
        content: '{';
    }
    .variable::after {
        content: '}';
    }
    .tooltip{
      display:none;

      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px 5px;

      position: absolute;
      z-index: 100;

      top: 1em;
      right: 2em;

      border-radius: .4em;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

`;

interface SmsEditorProps extends TextEditorProps {
  dynamicData: DynamicData[];
}

export const HTMLtoSms = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const toolTipLength = doc.getElementsByClassName('tooltip').length;

  for (let index = 0; index < toolTipLength; index++) {
    const element = doc.getElementsByClassName('tooltip')[0];
    element!.remove();
  }

  const variableLength = doc.getElementsByClassName('variable').length;

  for (let index = 0; index < variableLength; index++) {
    const element = doc.getElementsByClassName('variable')[0];

    const dd = element!.innerHTML;

    element!.replaceWith(`{${dd}}`);
  }

  // eslint-disable-next-line prettier/prettier
  let strippedHtml = doc.body.innerHTML.replaceAll('</p>', '\n</p>');
  strippedHtml = strippedHtml.replaceAll('<br>', '\n<br>');

  strippedHtml = strippedHtml.replace(/<[^>]+>/g, '');

  return strippedHtml;
};

const padIndex = (index: number | string) => {
  if (!isNumber(index)) {
    return '0000';
  }

  return index.toString().padStart(4, '0');
};

export const SmsEditor = (props: SmsEditorProps) => {
  const { setValue, dynamicData, editor, ...rest } = props;

  const dynamicStyles = dynamicData.map((d, i) => {
    const index = padIndex(i);

    return `
    .dd${index}:hover ~ .dd${index} {
      display: inline-block;
    }
    `;
  });

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');

    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      placeholder(
        'Type your text message here... Multiple text messages may be sent if there are greater that 160 characters.'
      ),
    ];

    style.appendChild(iframeDocument.createTextNode(editorStyles + dynamicStyles.join('')));
    iframeDocument.head.appendChild(style);

    return new ProseMirror.EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({ doc: state.doc, plugins }),
      }
    );
  };

  const handleOnBlur = () => {
    try {
      const html = EditorUtils.getHtml(editor.current!.view!.state);

      const strippedHtml = HTMLtoSms(html);

      setValue(strippedHtml);
    } catch (error) {
      // @todo handle error properly
      console.error(error);
    }
  };

  const handleOnChange = (e: EditorChangeEvent) => {
    const html = e.html;

    const hiddenSpan = (keyword: string) => {
      const data = dynamicData.find((d) => d.code === keyword);
      const index = padIndex(dynamicData.findIndex((d) => d.code === keyword));

      return `<span class="tooltip dd${index}" spellcheck="false">${data?.description}</span>`;
    };

    dynamicData.forEach((dd, i) => {
      const keyWord = dd.code;
      if (html.includes(keyWord)) {
        let k = keyWord.slice(1);
        k = k.slice(0, k.length - 1);

        const index = padIndex(i);

        const temp = html.replace(
          keyWord,
          `<span title="Test" class="variable dd${index}">` + k + hiddenSpan(keyWord) + '</span> '
        );

        EditorUtils.setHtml(editor.current!.view!, temp);
      }
    });
  };

  return (
    <>
      <Editor
        style={{ width: '100%', height: '100%' }}
        tools={[]}
        ref={editor}
        onBlur={handleOnBlur}
        onChange={(e) => handleOnChange(e)}
        onMount={onMount}
        {...rest}
      />
    </>
  );
};
