import { CSSProperties, FC } from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';
// kendo
import { Button } from '@/components/old/button/Button';
import { Icons } from '@/components/icons';
// style
import styles from './DmsNumberInput.module.scss';

export const DmsNumberInput: FC<
  NumericFormatProps<InputAttributes> & {
    inputStyles?: CSSProperties;
    inputLabel?: string;
    editButton?: boolean;
    editButtonFunction?: () => void;
    containerStyle?: CSSProperties;
    inputContainerStyle?: CSSProperties;
    inputLabelContainerStyle?: CSSProperties;
    errors?: string;
    followingText?: string;
  }
> = ({
  inputLabel,
  inputStyles = {},
  editButton,
  editButtonFunction,
  containerStyle = {},
  inputContainerStyle = {},
  inputLabelContainerStyle = {},
  errors,
  followingText,
  fixedDecimalScale,
  ...restInputProps
}) => {
  let inputClassName = styles.inputBox;
  if (errors) {
    inputClassName += ` ${styles.errors}`;
  }
  if (fixedDecimalScale) {
    inputClassName += ` ${styles.currencyInput}`;
  }

  return (
    <div style={containerStyle}>
      <div className={styles.container}>
        {inputLabel && (
          <div className={styles.labelContainer} style={inputLabelContainerStyle}>
            <div className={styles.label}>{inputLabel}</div>
            {restInputProps.required && (
              <Icons.Star color="#bc5757" width="10px" height="10px" className={styles.icon} />
            )}
          </div>
        )}
        <div className={styles.inputContainer} style={inputContainerStyle}>
          <NumericFormat
            className={inputClassName}
            style={inputStyles}
            fixedDecimalScale={fixedDecimalScale}
            {...restInputProps}
          />

          {editButton && (
            <Button
              buttonLabel="Edit"
              buttonStyles={{
                width: '50px',
                height: '27.5px',
                padding: '4.5px 9.75px',
              }}
              onClick={editButtonFunction}
            />
          )}

          {followingText && <div className={styles.followingText}>{followingText}</div>}
        </div>
      </div>
      {inputLabel && errors ? <div className={styles.errorMessage}>{errors}</div> : null}
    </div>
  );
};
