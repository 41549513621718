import { SalesSubview } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  LhPhFundingFilterKey,
  LhPhFundingSortKey,
  lhPhFundingFilterFields,
  lhPhFundingSortFields,
} from './dataModelConfig';
import { LhPhFundingDb } from './interfaces';
import { lhPhFundingTableColumns } from './tableColumns';

export const lhPhFundingSubviewConfig: SubviewInfo<LhPhFundingSortKey, LhPhFundingFilterKey> = {
  text: 'LHPH Funding',
  route: SalesSubview.enum.lhPhFunding,
  columns: lhPhFundingTableColumns,
  dateFilterField: { field: 'saleDate', label: 'Date Sold' },
  initSortField: 'saleDate',
  sortableFields: lhPhFundingSortFields,
  filterableFields: lhPhFundingFilterFields,
  dbRowValidationFxn: (row: LhPhFundingDb) => LhPhFundingDb.new(row),
};
