import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// components
import ProviderField from "./ProviderField";
import PaidInField from "./PaidInField";
import TotalPmtField from "./TotalPmtField";
import PrinBalField from "./PrinBalField";
import CarPmtField from "./CarPmtField";
import TxnTypeField from "./TxnTypeField";
import LateChargeFields from "./LateChargeFields";
import NsfPaidField from "./NsfPaidField";
import CpiPaidField from "./CpiPaidField";
import DdPmtField from "./DdPmtField";
import PaidByField from "./PaidByField";
import WaiveConvFeeField from "./WaiveConvFeeField";
import EmailCheckboxFields from "./EmailCheckboxFields";
import TotalReceivedField from "./TotalReceivedField";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

const ChangeDueField: FC = () => {
  const changeDue = usePmtFormCtx((s) => s.formState.config.calculated.changeDue);
  return <CurrencyInput label="Change Due" readOnly value={changeDue} />;
};

/** ###  */
const FormLeftColumn: FC = () => (
  <Grid container direction="column" sx={{ width: "500px", gap: "10px" }}>
    <ProviderField />
    <TxnTypeField />
    <PaidInField />
    <TotalPmtField />
    <PrinBalField />
    <CarPmtField />
    <CpiPaidField />
    <LateChargeFields />
    <NsfPaidField />
    <DdPmtField />
    <PaidByField />
    <WaiveConvFeeField />
    <TotalReceivedField />
    <ChangeDueField />
    <EmailCheckboxFields />
  </Grid>
);

export default FormLeftColumn;
