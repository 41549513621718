import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseFormReturn, useForm } from 'react-hook-form';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { usePmtReversalCtx } from '../PmtReversalProvider';
// interfaces
import { ReversiblePayment, ReversiblePaymentForm } from '../interfaces';
import { ReversalType, reversalLookupMap, reversalTypeLabels } from '@/enums/payment';

export interface IPmtReversalDetailCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  pmtForm: UseFormReturn<ReversiblePaymentForm, any, undefined>;
  resetFormState: () => void;
  isConfirmOpen: boolean;
  setIsConfirmOpen: (_: boolean) => void;
  // getters
  selectedPmt: ReversiblePayment | undefined;
  reversalLabelOptions: string[];
  reversalValueOptions: ReversalType[];
  isSystemOnlyDisabled: boolean;
}
const defaultValues = new ReversiblePaymentForm({ userRecId: 0 });

const PmtReversalDetailCtx = createContext<IPmtReversalDetailCtx | null>(null);

const PmtReversalDetailProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { colRecId: colRecIdStr, pmtRecId: pmtRecIdStr } = useParams();
  const colRecId = Number(colRecIdStr);
  const pmtRecId = Number(pmtRecIdStr);
  const employees = usePmtReversalCtx((s) => s.employees);
  const userId = useAuthSelector((s) => s.userId);

  const selectedPmt = usePmtReversalCtx((s) => s.rows.find((pmt) => pmt.recId === pmtRecId));

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const pmtForm = useForm<ReversiblePaymentForm>({ defaultValues, mode: 'onChange' });
  const reversalType = pmtForm.watch().reversalType;

  const isCreditCard = selectedPmt?.paidBy === 'Credit Card';
  const isAch = selectedPmt?.paidBy === 'ACH';
  const reversalValueOptions: ReversalType[] = isCreditCard
    ? ['chargeBack', 'reapply', 'refund']
    : isAch
    ? ['nsfCheck']
    : ['nsfCheck', 'reversal'];
  const isSystemOnlyDisabled = !(isCreditCard && reversalType === 'refund');

  const reversalLabelOptions = reversalTypeLabels.filter((label) => {
    const revType = reversalLookupMap.get(label)!;

    return reversalValueOptions.includes(revType);
  });

  const resetFormState = () =>
    pmtForm.reset(ReversiblePaymentForm.new(selectedPmt, reversalValueOptions, userId));

  useEffect(() => {
    // If both recIds are invalid numbers
    if (isNaN(colRecId) && isNaN(pmtRecId)) {
      resetFormState();
    }

    // Cleanup - reset all states to default WHEN form-state (which wraps form-component) is de-rendered
    return () => {
      resetFormState();
    };
  }, [colRecId, pmtRecId]);

  useEffect(() => {
    resetFormState();
  }, [selectedPmt, employees]);

  return (
    <PmtReversalDetailCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        pmtForm,
        resetFormState,
        isConfirmOpen,
        setIsConfirmOpen,

        // Getters
        selectedPmt,
        reversalLabelOptions,
        reversalValueOptions,
        isSystemOnlyDisabled,
      }}
    >
      {children}
    </PmtReversalDetailCtx.Provider>
  );
};

export default PmtReversalDetailProvider;

export const usePmtReversalDetailCtx = <T,>(selector: (state: IPmtReversalDetailCtx) => T): T => {
  const ctx = useContext(PmtReversalDetailCtx);
  if (!ctx) {
    throw new Error('usePmtReversalDetailCtx must be used within PmtReversalDetailProvider');
  }
  return selector(ctx);
};
