import { FC, SVGProps } from 'react';
// mui
// import Grid from '@mui/material/Unstable_Grid2';
// components
import NavigationItemContainer from './NavigationItemContainer';
// style
import styles from '@/components/authenticatedContainer/AuthenticatedContainer.module.scss';

/** ###  */
const NavigationItem: FC<{
  Icon: FC<SVGProps<SVGSVGElement>>;
  name: string;
  route?: string;
  action?: () => void;
}> = ({ Icon, name, route, action }) => {
  const style = ['Admin', 'Notifications', 'Inventory', 'Dashboard'].includes(name)
    ? { width: '23px', height: '23px' }
    : {};

  return (
    <NavigationItemContainer route={route} action={action}>
      <span className={styles.line} />
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} style={style} />
      </div>
      <span className={styles.navigationItemTitle}>{name}</span>
    </NavigationItemContainer>
  );
};

export default NavigationItem;
