import { FC } from "react";
// kendo
import { Grid, GridColumn, GridCustomCellProps, GridNoRecords, Loader } from "@progress/kendo-react-all";
import LinkCell from "@/components/kendoCells/LinkCell";
import DateCell from "@/components/kendoCells/DateCell";
// components
import { GridCard } from "@/features/Accounts/accountsSubviews/AccountDetail/components/outForRepo/GridCard";
// state
import { useOutForRepoCtx } from "./OutForRepoProvider";
// interfaces
import { OrderForRepoRowData } from "@/features/Dashboard/interfaces";

// @todo implement
const buildDetailRoute: (dataItem: OrderForRepoRowData) => string = ({ recId }) => "";

const AccountLinkCell = (props: GridCustomCellProps) => {
  const setActiveRowId = useOutForRepoCtx((s) => s.setActiveRowId);
  const handleClick = () => {
    setActiveRowId(props.dataItem.recId);
  };
  return <LinkCell navRouteBuilder={buildDetailRoute} {...props} onClick={handleClick} />;
};

// Mapping function to get RepoCompanyName based on repoCompID
const getRepoCompanyName = (repoCompID: number, repoCompanyList: { recId: number; company: string }[]) => {
  if (repoCompID === 0) {
    return "";
  }
  const company = repoCompanyList.find((c) => c.recId === repoCompID);
  return company ? company.company : repoCompID;
};

const RepoCompanyCell: FC<GridCustomCellProps> = (props) => {
  const repoCompanyList = useOutForRepoCtx((s) => s.repoCompanyList);
  const repoCompID = props.dataItem[props.field!];

  return <td className="k-table-td">{getRepoCompanyName(repoCompID, repoCompanyList)}</td>;
};

export const OutForRepoGridPerApp: FC = () => {
  const repoRows = useOutForRepoCtx((s) => s.repoRows);
  const repoTotal = useOutForRepoCtx((s) => s.repoTotal);
  const sort = useOutForRepoCtx((s) => s.sorts);
  const isLoading = useOutForRepoCtx((s) => s.isLoading);

  return (
    <GridCard header={`Out For Repo ${isLoading ? "" : `(${repoTotal})`}`} subText="">
      <Grid
        data={repoRows}
        total={repoTotal}
        scrollable="none"
        sortable={true}
        sort={sort}
        // onSortChange={onSortChange}
        style={{ maxHeight: "200px", maxWidth: "100%", overflow: "auto" }}
      >
        <GridNoRecords>{isLoading ? <Loader size="large" /> : "No records found"}</GridNoRecords>
        <GridColumn field="recId" title="RecId" key="recId" cells={{ data: AccountLinkCell }} />
        <GridColumn field="openTime" title="Open Date" key="openTime" cells={{ data: (p) => <DateCell {...p} dtFormat="M/D/YYYY" /> }} />
        <GridColumn field="openByName" title="Ordered By" key="openByName" />
        <GridColumn field="assgnToName" title="Assigned To" key="assgnToName" />
        <GridColumn field="fcStatus" title="Status" key="fcStatus" />
        <GridColumn
          field="repoCompID"
          title="Repo Company Name"
          key="repoCompID"
          cell={RepoCompanyCell} // Use the custom cell renderer
        />
        <GridColumn field="closeTime" title="Close Date" key="closeTime" cells={{ data: (p) => <DateCell {...p} dtFormat="M/D/YYYY" /> }} />
      </Grid>
    </GridCard>
  );
};
