import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// components
import PmtReversalForm from './PmtReversalForm';
// state
import PmtReversalDetailProvider from './PmtReversalDetailProvider';

/** ###  */
const PmtReversalDetail: FC = () => {
  return (
    <PmtReversalDetailProvider>
      <Grid container direction="column" gap={3}>
        <Grid container direction="row" fontSize={30} fontWeight={700}>
          Payment Reversal
        </Grid>
        <PmtReversalForm />
      </Grid>
    </PmtReversalDetailProvider>
  );
};

export default PmtReversalDetail;
