import { FC } from "react";
// mui
import Typography from "@mui/material/Typography";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
// utils
import { formatCurrency } from "@/utils/helpers/general";
import { getRouteParamNum } from "@/utils/routing/formatting";

/** ### Kendo cell */
const CurrencyCell: FC<GridCellProps> = ({ dataItem, field, style }) => {
  if (!field) throw new Error(`CurrencyCell: invalid field. Input: "${field}"`);

  const cellValue = getRouteParamNum(dataItem[field]);
  const valueFmt = cellValue === null ? "-" : formatCurrency(cellValue);

  return (
    <td className="k-table-td" style={{ ...style, textAlign: "end" }}>
      <Typography component="span" variant="tableCell">
        {valueFmt}
      </Typography>
    </td>
  );
};

export default CurrencyCell;
