import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// Components
import { TextInput } from "@/components/inputs/text/TextInput";
import { PhoneInput } from "@/components/inputs/phoneInput/PhoneInput";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { Button } from "@/components/button/Button";
import CompanyDefaults from "./CompanyDefaults"; // Import the CompanyDefaults component
import { useCompanyMgmtCtx } from "./CompanyMgmtProvider"; // Import the context hook
import { Grid, Checkbox, FormControlLabel, Typography } from "@mui/material"; // Import MUI components
import { Loader } from "@/components";
import { toast } from "react-toastify";
import { companyService } from "@/services/companyService";
import { NumberInput } from "@/components";

interface FormValues {
  recId:number;
  orgRecId: number;          // Organization Record ID
  compId: number;            // Company ID
  companyName: string;
  shortName: string;
  legalName: string;
  abbreviation: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  phoneNumber: string;
  phone800: string;
  fax: string;
  federalId: string;
  dealerNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  lessorNumber: string;
  timeZone: string;
  adjustDaylightSavingsTime: boolean;
  companyLogo: FileList | null;
  minCreditCardAmount: number;

  // Ecom Setup Fields
  voicePhone: string;
  forwardCallsTo: string;
  emailFrom: string;
  emailDisplayName: string;
}

const CompanyDetails: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Tracks the currently selected tab

  // Access company details from context
  const { companyDetails, error } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
    error: state.error,
  }));

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues: {
      orgRecId: 0,
      compId: 0,
      companyName: "",
      shortName: "",
      legalName: "",
      abbreviation: "",
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      phoneNumber: "",
      phone800: "",
      fax: "",
      federalId: "",
      dealerNumber: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      lessorNumber: "",
      timeZone: "",
      adjustDaylightSavingsTime: false,
      companyLogo: null,

      // Ecom Setup Fields
      voicePhone: "",
      forwardCallsTo: "",
      emailFrom: "",
      emailDisplayName: "",
    },
  });

  const companyLogoFile: FileList | null = watch("companyLogo");

  // Map company details to form fields when companyDetails change
  useEffect(() => {
    if (companyDetails) {
      reset({
        orgRecId: companyDetails.orgRecId || 0,
        compId: companyDetails.compId || 0,
        companyName: companyDetails.companyName || "",
        shortName: companyDetails.shortName || "",
        legalName: companyDetails.legalName || "",
        abbreviation: companyDetails.abbreviation || "",
        addressLine1: companyDetails.address || "",
        city: companyDetails.city || "",
        state: companyDetails.state || "",
        zip: companyDetails.zip || "",
        county: companyDetails.county || "",
        phoneNumber: companyDetails.phone || "",
        phone800: companyDetails.phone800 || "",
        fax: companyDetails.fax || "",
        federalId: companyDetails.federalId || "",
        dealerNumber: companyDetails.dealerNumber || "",
        contactName: companyDetails.contactName || "",
        contactEmail: companyDetails.contactEmail || "",
        contactPhone: companyDetails.contactPhone || "",
        lessorNumber: companyDetails.lessorNumber || "",
        timeZone: companyDetails.timeZone || "",
        adjustDaylightSavingsTime: companyDetails.dayLight ?? false,
        companyLogo: null,

        // Ecom Setup Fields
        voicePhone: companyDetails.voicePhone || "",
        forwardCallsTo: companyDetails.forwardCallsTo || "",
        emailFrom: companyDetails.emailFrom || "",
        emailDisplayName: companyDetails.emailDisplayName || "",
      });
    }
  }, [companyDetails, reset]);

  const onSubmit = async (data: FormValues) => {
    console.log("Form Submitted:", data);
  
    let companyLogoUrl: string = "";
  
    // Handle file upload
    if (data.companyLogo && data.companyLogo[0]) {
      const file = data.companyLogo[0];
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const uploadResponse = await companyService.uploadFile(formData);
        companyLogoUrl = uploadResponse.url;
      } catch (error) {
        console.error("Error uploading company logo:", error);
        toast.error("Failed to upload company logo. Please try again.");
        return;
      }
    }
  
    // Map form data to match API payload
    const apiPayload = {
      RecId: data.compId, // Use compId as RecId
      orgRecId: data.orgRecId || 0, // Add orgRecId with a fallback to 0
      companyName: data.companyName,
      shortName: data.shortName,
      legalName: data.legalName,
      abbreviation: data.abbreviation,
      address: data.addressLine1,
      city: data.city,
      state: data.state,
      zip: data.zip,
      county: data.county,
      phone: data.phoneNumber,
      phone800: data.phone800 || data.phoneNumber, // Fallback to phoneNumber if empty
      minCreditCardAmount: data.minCreditCardAmount,
      fax: data.fax,
      federalId: data.federalId,
      dealerNumber: data.dealerNumber,
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      lessorNumber: data.lessorNumber,
      timeZone: data.timeZone,
      dayLight: data.adjustDaylightSavingsTime,
      companyLogo: companyLogoUrl,
      voicePhone: data.voicePhone,
      forwardCallsTo: data.forwardCallsTo,
      emailFrom: data.emailFrom,
      emailDisplayName: data.emailDisplayName,
    };
    try {
      await companyService.updateCompanyDetails(apiPayload);
      toast.success("Company details updated successfully!");
    } catch (error) {
      console.error("Error updating company details:", error);
      toast.error("Failed to update company details. Please try again.");
    }
  };

  if (!companyDetails) {
    return (    
        <Loader size="large"/>
    );
  }

  return (
    <>
      {/* Tab Content */}
      {selectedTab === 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* General Information Section */}
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SectionHeader title="General Information" />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                      <Controller
                        name="compId"
                        control={control}
                        render={({ field }) => (
                          <NumberInput 
                            label="Company ID" 
                            disabled 
                            format="" // Disable formatting to remove commas
                            {...field} 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Company Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="shortName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Short Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="legalName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Legal Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="abbreviation"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Abbreviation" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="addressLine1"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Address" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="City" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="State" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="zip"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Zip" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="county"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="County" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Phone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="fax"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Fax" {...field} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Right Column */}
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Controller
                        name="federalId"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Federal ID" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="dealerNumber"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Dealer Number" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactName"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Contact Name" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactEmail"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Contact Email" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="contactPhone"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput label="Contact Phone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="lessorNumber"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Lessor Number" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="timeZone"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Time Zone" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="adjustDaylightSavingsTime"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                              />
                            }
                            label="Adjust Daylight Savings Time"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="companyLogo"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Typography variant="subtitle1">Company Logo</Typography>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => field.onChange(e.target.files)}
                            />
                            {/* Display a preview if a file is selected */}
                            {companyLogoFile && companyLogoFile.length > 0 && companyLogoFile[0] && (
                              <Grid item sx={{ mt: 2 }}>
                                <img
                                  src={URL.createObjectURL(companyLogoFile[0])}
                                  alt="Company Logo Preview"
                                  style={{ width: "100px" }}
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Ecom Setup Section */}
            <Grid container direction="column" spacing={2} sx={{ mt: 4 }}>
              <Grid item>
                <SectionHeader title="Ecom Setup" />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Controller
                          name="voicePhone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput label="Voice/Text Phone" {...field} />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Controller
                          name="forwardCallsTo"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput label="Forward Calls To" {...field} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Controller
                          name="emailFrom"
                          control={control}
                          render={({ field }) => (
                            <TextInput label="Email From" {...field} />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Controller
                          name="emailDisplayName"
                          control={control}
                          render={({ field }) => (
                            <TextInput label="Email Display Name" {...field} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
              <Button type="submit" label="Submit" buttonStyle={{ width: "140px" }} />
            </Grid>
          </Grid>
        </form>
      )}

      {selectedTab === 1 && <CompanyDefaults />} {/* Company Defaults Tab */}
    </>
  );
};

export default CompanyDetails;
