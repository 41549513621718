import { FC } from "react";
// kendo
import { RadioGroupInput } from "@/components/inputs/radioGroupInput/RadioGroupInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// validation
import { PmtPaidIn } from "@/enums/payment";

const options = PmtPaidIn.options.map((opt) => ({ label: opt, value: opt }));

/** ###  */
const PaidInField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("paidIn"));

  return (
    <RadioGroupInput
      label="Paid In"
      layout="horizontal"
      data={options}
      {...fieldProps}
      // @todo fix component
      inputStyles={{ rowGap: 0 }}
    />
  );
};

export default PaidInField;
