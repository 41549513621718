import { FC, useEffect } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Button } from "@/components/button/Button";
import { ConfirmButton } from "@/components/confirmButton/ConfirmButton";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// utils
import { submitPayment, submitPaymentDeprec } from "./reqUtils";

const defaultTitle = "Post Payment";

/** ###  */
const SubmitButtonGroup: FC = () => {
  const pmtFormCtx = usePmtFormCtx((s) => s);
  const areReqsLoading = usePmtFormCtx((s) => s.isLoading);
  const isSubmitting = usePmtFormCtx((s) => s.isSubmitting);
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);
  const continueSubmitting = usePmtFormCtx((s) => s.continueSubmitting);

  //
  const isCcOrAch = usePmtFormCtx((s) => s.isCcOrAch);
  const form = usePmtFormCtx((s) => s.formState.form);
  const isValid = usePmtFormCtx((s) => s.formState.isValid);

  // Bools for title and button conditionals
  const isNewPmt = !form.useSavedPaymentMethod;
  const isCliq = form.provider === "Cliq";
  const isNewCliqPmt = isCliq && isCcOrAch && isNewPmt;

  // Event handlers
  const handleSubmit = async () => {
    if (!isCcOrAch || !isCliq) setIsSubmitting(true);

    try {
      if (isCliq) {
        await submitPayment(pmtFormCtx);
      } else {
        await submitPaymentDeprec(pmtFormCtx);
      }
    } catch {
      setIsSubmitting(false);
    }
  };

  // @todo Add all cases which open payment modals
  const title = isNewCliqPmt ? "Enter Payment Information" : defaultTitle;

  // Button states
  const isLoading = isSubmitting || areReqsLoading;
  const isDisabled = isLoading || !isValid;

  useEffect(() => {
    continueSubmitting && handleSubmit();
  }, [continueSubmitting]);

  return (
    <Grid container direction="row" sx={{ justifyContent: "flex-end", gap: "40px" }}>
      <ConfirmButton
        triggerElement={(onClick) => (
          <Grid container alignSelf="end">
            <Button
              label={title}
              disabled={isDisabled}
              loading={isLoading}
              style={{ width: "211px" }}
              onClick={onClick}
            />
          </Grid>
        )}
        confirmButtonProps={{ onClick: handleSubmit }}
        cancelButtonProps={{}}
        modalContents="Please confirm that you want to post this payment"
      />
    </Grid>
  );
};

export default SubmitButtonGroup;
