import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { DateFormat, extractTextFromHtmlString, formatDate } from "@/utils/helpers/general";
import { SetState } from "@/interfaces/utilityTypes";
import { Icons } from "@/components/icons";

import styles from "../activityPanel.module.scss";
import { ActivityLog } from "@/interfaces";

/** ###  */
const LogActivityCard: FC<{
  activity: ActivityLog;
  setSelectedLog: Function;
}> = ({ activity, setSelectedLog }) => {
  const logBody = activity.detailLong ? (
    <div className={styles.link} onClick={() => setSelectedLog(activity)}>
      {activity.detailShort}
    </div>
  ) : (
    <div>{activity.detailShort}</div>
  );
  return (
    <Grid container direction="column">
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div className={styles.messageHeader}>
                  <div>{activity.transType}</div>
                </div>
              </div>
              {logBody}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(activity.contactOn, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    </Grid>
  );
};

export default LogActivityCard;
