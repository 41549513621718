import React, { forwardRef, useMemo } from 'react';
import styles from '@components/inputs/currency/CurrencyInput.module.scss';
import { Label, NumericTextBox, NumericTextBoxProps } from '@progress/kendo-react-all';
import textStyles from '@components/text/Text.module.scss';
import { NumericTextBoxFocusEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';

export interface CurrencyInputProps extends NumericTextBoxProps {
  errors?: string | boolean;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  inputStyles?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  rightAlignInput?: boolean;
  allowNegative?: boolean;
  hidden?: boolean;
}

export const CurrencyInput = forwardRef<NumericTextBoxHandle, CurrencyInputProps>((props, ref) => {
  const {
    // @ts-ignore
    display,
    name,
    label,
    errors,
    value,
    placeholder,
    readOnly,
    required,
    spinners = false,
    boldLabel = true,
    horizontalLabel = true,
    inputStyles,
    containerStyle,
    rightAlignInput,
    min,
    allowNegative = false,
    ...rest
  } = props;
  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  const inputClassName = rightAlignInput ? styles.rightAlignInput : styles.input;

  let minimum: number | undefined = 0;
  if (min) {
    minimum = min;
  } else if (allowNegative) {
    minimum = undefined;
  }

  const onFocus = (e: NumericTextBoxFocusEvent) => {
    // select all of the contents - otherwise when you click into an input, the cursor can end up before
    // the $ mask, and then you have to type once to get past that
    // the timeout seems to make it work more consistently
    setTimeout(() => {
      if (e.target.element) {
        e.target.element.select();
      }
    }, 0);
    return;
  };

  const Input = (
    <NumericTextBox
      id={editorId}
      format={'c2'}
      inputType={'text'}
      readOnly={readOnly}
      spinners={spinners}
      className={errors ? `${styles.errors} ${inputClassName}` : inputClassName}
      value={value}
      placeholder={placeholder}
      ref={ref}
      style={inputStyles}
      min={minimum}
      onFocus={onFocus}
      {...rest}
    />
  );

  return (
    <div className={styles.currencyContainer} style={containerStyle} hidden={props.hidden}>
      {label ? (
        horizontalLabel ? (
          <div className={textStyles.row}>
            <Label
              editorId={editorId}
              className={textStyles.label}
              style={{ fontWeight: boldLabel ? 700 : 400 }}
            >
              {`${label} ${required ? '*' : ''}`}
            </Label>
            {Input}
          </div>
        ) : (
          <Label
            editorId={editorId}
            className={textStyles.label}
            style={{ fontWeight: boldLabel ? 700 : 400 }}
          >
            {`${label} ${required ? '*' : ''}`}
            {Input}
          </Label>
        )
      ) : (
        <>{Input}</>
      )}
      {!!errors && typeof errors === 'string' && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
