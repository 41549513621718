// interfaces
import { AppliedFieldOutput, OptionalAppliedFieldOutput } from "../interfaces";
import { ExtOut, ExtSchemaProp, ZObj } from "./interfaces";
import { InputConfig } from "./input";
import { EvProp, FormCfgReturnCb } from "./returnTypes";

/** Only for use in `useValidatedForm`
 * For fields on `externalSchema`, `null` is applied to fields where a catch is not provided
 */
export const defineFormConfig = <
  TBase extends ZObj,
  TExt extends ExtSchemaProp,
  TCalc extends Record<string, any>
>(
  inputConfig: InputConfig<TBase, TCalc, TExt>
) => {
  const { fields, calcValues, externalSchema } = inputConfig;

  return ((ev?: EvProp<ExtOut<TExt>>) => ({
    fields,
    calcValues,
    externalValues: externalSchema?.parse(ev ?? {}),
  })) as FormCfgReturnCb<
    AppliedFieldOutput<TBase>,
    typeof inputConfig.calcValues, // @note having issues propagating return type throughout config
    OptionalAppliedFieldOutput<TExt>
  >;
};
