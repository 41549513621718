import { z } from "zod";

export const BaseRoute = z.enum([
  "auth",
  "accounts",
  "admin",
  "collections",
  "reports",
  "sales",
  "settings",
  "inventory",
  "dashboard",
]);
export type BaseRoute = z.infer<typeof BaseRoute>;

// @todo look at examples to implement
// export type RouteTemplate = `/${BaseRoute}${string}`;
