import { FC } from "react";
//mui
import Divider from "@mui/material/Divider";
import DetailViewLayout from "@/mui/components/layout/DetailViewLayout";
import DetailViewBody from "@/mui/components/layout/DetailViewLayout/DetailViewBody";
import DetailViewHeader from "@/mui/components/layout/DetailViewLayout/DetailViewHeader";
// components
import PaymentModalGroup from "@/components/modals/PaymentModalGroup";
import FormLeftColumn from "./FormLeftColumn";
import FormRightColumn from "./FormRightColumn";
// state
import PmtFormProvider, {
  usePmtFormCtx,
} from "../../accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
import useNavBlock from "@/utils/forms/useNavBlock";

const MiscPaymentDetailView: FC = () => {
  const isLoading = usePmtFormCtx((s) => s.isLoading);
  const areFetchReqsSuccessfulMisc = usePmtFormCtx((s) => s.areFetchReqsSuccessfulMisc);
  const formState = usePmtFormCtx((s) => s.formState);
  const receiptUrl = usePmtFormCtx((s) => s.receiptUrl);
  const { NavBlockElem, blocker } = useNavBlock(formState.isDirty && !receiptUrl);
  // const { NavBlockElem: Blocker } = useNavBlock(isDirty || isSubmitting);
  const isCliqModalOpen = usePmtFormCtx((s) => s.isCliqModalOpen);

  if (!areFetchReqsSuccessfulMisc) return <div>Unable to load payment data</div>;

  return (
    <DetailViewLayout backLink="..">
      <DetailViewHeader title="Miscellaneous Payment"></DetailViewHeader>
      <DetailViewBody direction="row" px="32px" py="24px" gap={2} isLoading={isLoading}>
        <FormLeftColumn />
        <Divider
          flexItem
          orientation="vertical"
          sx={{ borderWidth: 1.5, borderColor: "#e6e6e6" }}
        />
        <FormRightColumn />
      </DetailViewBody>

      <PaymentModalGroup />

      {!isCliqModalOpen && <NavBlockElem />}
    </DetailViewLayout>
  );
};

const MiscPaymentDetail: FC = () => (
  <PmtFormProvider>
    <MiscPaymentDetailView />
  </PmtFormProvider>
);

export default MiscPaymentDetail;
