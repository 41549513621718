import { CSSProperties, FC } from "react";
import { Controller } from "react-hook-form";
// mui
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// kendo
import { DropdownInput, TextInput } from "@/components/inputs";
import { Button, Checkbox } from "@/components";
// components
import Section from "@/mui/components/form/Section";
import SectionHeader from "@/mui/components/form/Section/SectionHeader";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import Subsection from "@/mui/components/form/Section/Subsection";
import { PrintButton } from "../PrintButton";
// state
import { useOutForRepoCtx } from "../OutForRepoProvider";
// utils
// interfaces
import { OrderForRepoFormData } from "../interfaces";
import { useParams } from "react-router-dom";
import { collectionsService } from "@/services/collectionsService";
import { OrderForRepoSubmitReq } from "@/features/Dashboard/interfaces";
import dayjs from "dayjs";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { useAuthSelector } from "@/features/auth/authSlice";
import { toast } from "react-toastify";
import AddNewButton from "@/features/collections/AddNewButton";
import MuiKendoButton from "@/mui/components/buttons/MuiKendoButton";
import { Grid } from "@mui/material";

const OutForRepoForm: FC<{
  showTitle?: boolean;
  containerStyle?: CSSProperties;
}> = () => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isDirty, isValid },
  } = useOutForRepoCtx((s) => s.outForRepoForm);
  const acctInfo = useAccountSelector((s) => s.accountInformation);
  const acctNum = useAccountSelector((s) => s.accountInformation!.accountNum);
  const userName = useAuthSelector((s) => s.userName);
  const userId = useAuthSelector((s) => s.userId);
  const paramsAppRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const { fcStatus, recId, assgnTo, repoCompID } = watch();
  const params = useParams();
  const activeRowId = useOutForRepoCtx((s) => s.activeRowId);
  const setActiveRowId = useOutForRepoCtx((s) => s.setActiveRowId);
  const isLoading = useOutForRepoCtx((s) => s.isLoading);
  const setIsLoading = useOutForRepoCtx((s) => s.setIsLoading);
  const setIsSubmitSuccessful = useOutForRepoCtx((s) => s.setIsSubmitSuccessful);
  const activeRepoRow = useOutForRepoCtx((s) => s.activeRepoRow);
  const employeeList = useOutForRepoCtx((s) => s.employeeList);
  const repoCompanyList = useOutForRepoCtx((s) => s.repoCompanyList);
  const isExpanded = useOutForRepoCtx((s) => s.isExpanded);
  const setIsExpanded = useOutForRepoCtx((s) => s.setIsExpanded);
  const paramsColRecId = Number(params.colRecId);

  const handleExpand = () => setIsExpanded(!isExpanded);

  const onSubmit = async (formData: OrderForRepoFormData) => {
    setIsLoading(true);
    try {
      const payload: OrderForRepoSubmitReq = {
        recId: formData.recId !== 0 ? formData.recId : null,
        orgId: formData.orgId !== 0 ? formData.orgId : null,
        locId: formData.locId !== 0 ? formData.locId : null,
        compId: formData.compId !== 0 ? formData.compId : null,
        colRecId: formData.colRecId !== 0 ? formData.colRecId : paramsColRecId,
        appRecId: formData.appRecId !== 0 ? formData.appRecId : paramsAppRecId!,
        openTime: formData.openTime ? dayjs(formData.openTime).toDate() : dayjs().toDate(),
        fcStatus: formData.fcStatus,
        assgnTo: formData.assgnTo,
        assgnToName: formData.assgnToName,
        sText: formData.sText,
        openByName: activeRepoRow?.openByName || userName || "",
        fcRes: formData.fcRes,
        fcWork: formData.fcWork,
        fcOther: formData.fcOther,
        fcWhere: formData.fcOther ? formData.fcWhere : "",
        spIns: formData.spIns,
        closedReson: formData.closeIt ? formData.closedReson : "",
        repoCompID: formData.repoCompID,
        colType: formData.colType,
        acctNum,
        fcComplete: formData.vehicleRecovered!,
        closeIt: formData.closeIt,
      };
      const res = await collectionsService.updateOutForRepoOrder(payload);
      toast.success(`Updating repo order successful`);
      setIsSubmitSuccessful(res);
    } catch (_) {
      setIsLoading(false);
      toast.error(`Updating repo order failed`);
    } finally {
      setIsLoading(false);
    }
  };

  const disabledStatus = ["closed", "recovered"].includes(fcStatus.toLowerCase());

  const handleCancel = () => {
    reset();
    setActiveRowId(null);
  };

  const handleAddNew = () => {
    setIsExpanded(true);
    setActiveRowId(null);
  };

  const sectionTitle = recId !== 0 ? "Existing Repo Order" : "Add New Repo Order";
  return (
    <>
      <Grid container direction="row" justifyContent="flex-end">
        <MuiKendoButton onClick={handleAddNew}>Add New +</MuiKendoButton>
      </Grid>
      <Section
        expanded={isExpanded}
        onChange={handleExpand}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SectionHeader label={sectionTitle} wrap="nowrap" isExpandable={false} />
        <SectionBody
          sx={{ py: 0 }}
          leftColElems={[
            <Subsection key="left">
              <Controller
                name="assgnTo"
                control={control}
                disabled={disabledStatus}
                rules={{ required: true }}
                render={({ field: { value, onChange, ...field } }) => (
                  <DropdownInput
                    {...field}
                    label="Employee Assigned To"
                    data={employeeList || []}
                    dataItemKey="recId"
                    textField="shortName"
                    onChange={(e) => {
                      if (!e.target.value) return onChange("");
                      const newEmp = e.target.value?.recId || null;
                      setValue("assgnToName", e.target.value?.shortName || "");
                      onChange(newEmp);
                    }}
                    value={value === 0 ? "" : employeeList.find((e) => e.recId === value)}
                    defaultValue={value === 0 ? "" : employeeList.find((e) => e.recId === value)}
                  />
                )}
              />
              <TextInput
                value={activeRepoRow?.openByName || userName || ""}
                style={{ marginTop: "1px", marginBottom: "1px", height: "38px" }}
                label="Ordered By"
                defaultValue=""
                required
                readOnly
              />
              <Grid2
                container
                direction="row"
                flex={1}
                gap={2}
                justifyContent="space-between"
                alignItems="stretch"
              >
                <span
                  style={{
                    padding: "8px 0 8px 0",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Repo At:
                </span>
                <Controller
                  name="fcRes"
                  disabled={disabledStatus}
                  control={control}
                  render={({ field }) => (
                    <Checkbox containerStyles={{ height: "38px" }} label="Residence" {...field} />
                  )}
                />
                <Controller
                  name="fcWork"
                  disabled={disabledStatus}
                  control={control}
                  render={({ field }) => (
                    <Checkbox containerStyles={{ height: "38px" }} label="Work" {...field} />
                  )}
                />
                <Controller
                  name="fcOther"
                  disabled={disabledStatus}
                  control={control}
                  render={({ field }) => (
                    <Checkbox containerStyles={{ height: "38px" }} label="Other:" {...field} />
                  )}
                />
              </Grid2>
              <Grid2
                alignSelf="end"
                py="8.5px"
                sx={{
                  color: "#111827",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Special Instructions:
              </Grid2>
              <Grid2
                container
                direction="row"
                flex={1}
                gap={2}
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Controller
                  name="vehicleRecovered"
                  control={control}
                  disabled={disabledStatus}
                  render={({ field: { onChange, ...field } }) => (
                    <Checkbox
                      {...field}
                      label="Vehicle Recovered"
                      containerStyles={{ height: "38px" }}
                      onChange={(e) => {
                        onChange(e.value);
                        if (watch().closeIt) {
                          setValue("closeIt", !e.value);
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  name="closeIt"
                  control={control}
                  disabled={disabledStatus}
                  render={({ field: { onChange, ...field } }) => (
                    <Checkbox
                      {...field}
                      label="Close Order Repo"
                      containerStyles={{ height: "38px" }}
                      onChange={(e) => {
                        onChange(e.value);
                        if (watch().vehicleRecovered) {
                          setValue("vehicleRecovered", !e.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid2>
              {recId !== 0 && <PrintButton isDisabled={false} />}
            </Subsection>,
          ]}
          rightColElems={[
            <Subsection key="right">
              <Grid2
                container
                direction="row"
                flex={1}
                gap={2}
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Controller
                  name="sText"
                  control={control}
                  disabled={disabledStatus}
                  render={({ field }) => (
                    <Checkbox
                      containerStyles={{ height: "38px" }}
                      label="Text When Closed"
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Controller
                name="repoCompID"
                control={control}
                disabled={disabledStatus}
                rules={{ required: true }}
                render={({ field: { value, onChange, ...field } }) => (
                  <DropdownInput
                    {...field}
                    label="Repo Company Assigned"
                    data={repoCompanyList || []}
                    dataItemKey="recId"
                    textField="company"
                    onChange={(e) => {
                      if (!e.target.value) return onChange("");
                      const newRepoCo = e.target.value?.recId || null;
                      onChange(newRepoCo);
                    }}
                    value={value === 0 ? "" : repoCompanyList.find((e) => e.recId === value)}
                    defaultValue={value === 0 ? "" : repoCompanyList.find((e) => e.recId === value)}
                  />
                )}
              />
              <Controller
                name="fcWhere"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    style={{ marginTop: "1px", marginBottom: "1px" }}
                    required
                    defaultValue=""
                    disabled={disabledStatus || !watch().fcOther}
                  />
                )}
              />
              <Controller
                name="spIns"
                control={control}
                disabled={disabledStatus}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    style={{ marginTop: "1px", marginBottom: "1px" }}
                    required
                    defaultValue=""
                  />
                )}
              />
              <Controller
                name="closedReson"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    style={{ marginTop: "1px", marginBottom: "1px" }}
                    required
                    defaultValue=""
                    disabled={disabledStatus || !watch().closeIt}
                  />
                )}
              />
              <Grid2 container direction="row" flex={1} justifyContent="flex-end">
                <Button
                  label="Submit"
                  type="submit"
                  loading={isLoading}
                  disabled={disabledStatus}
                  buttonStyle={{ width: "134px", margin: "10px 10px 0px 0px" }}
                />
                <Button
                  label="Cancel"
                  type="button"
                  onClick={handleCancel}
                  buttonStyle={{ width: "134px", margin: "10px 0px 0px 0px" }}
                />
              </Grid2>
            </Subsection>,
          ]}
        />
      </Section>
    </>
  );
};

export default OutForRepoForm;
