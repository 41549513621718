import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { RadioButton } from '@progress/kendo-react-inputs';
import {  Button, Checkbox, DropdownInput, TextInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
// components
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// state
import { useAppDispatch } from '@/store/store';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { LegalResponse, legalService } from '@/services/legalService';
// style
import styles from './LegalAccount.module.scss';
import { usaStateNames } from '@/general/regions';

type FormProps = {
  assignToLegal: boolean;
  legalType: string;
  category: string;
  status: string;
  fileDate: string;
  caseNumber: string;
  filingState: string;
  filingDistrict: string;
  filingDivision: string;
  code: string;
  pocFileDate: string;
};

// @todo use class constructor
const defaultValues = {
  assignToLegal: false,
  legalType: '',
  category: '',
  status: '',
  fileDate: '',
  caseNumber: '',
  filingState: '',
  filingDistrict: '',
  filingDivision: '',
  code: '',
  pocFileDate: '',
};

// @todo use context to manage state
export const LegalAccount: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const dispatch = useAppDispatch();
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [legalResponse, setLegalResponse] = useState<LegalResponse>({} as LegalResponse);
  const [legalCategoryOptions, setLegalCategoryOptions] = useState<string[]>([]);
  const [skipCategoryOptions, setSkipCategoryOptions] = useState<string[]>([]);
  const [insuranceCategoryOptions, setInsuranceCategoryOptions] = useState<string[]>([]);
  const [bankruptcyCategoryOptions, setBankruptcyCategoryOptions] = useState<string[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const { control, handleSubmit, watch, reset, setValue, formState } = useForm<FormProps>({
    defaultValues,
    mode: 'onChange',
  });
  const assignToLegalChecked = watch('assignToLegal');

  useEffect(() => {
    // @todo use async/await
    legalService.getLegal(colRecId, accountInformation.compId!).then((res) => {
      setLegalResponse(res);
      const legalTypeObject = res.categories.find((value) => value.recId === res.legal.category);
      const legalType = legalTypeObject?.field;
      reset({
        assignToLegal: res.legal.isLegal,
        legalType: legalType,
        category: res.legal.legalType,
        status: res.legal.status,
        fileDate: dayjs(res.legal.bankruptcy.fileDate).utc().format('YYYY-MM-DD'),
        caseNumber: res.legal.bankruptcy.caseNumber,
        filingState: res.legal.bankruptcy.filingState,
        filingDistrict: res.legal.bankruptcy.filingDistrict,
        filingDivision: res.legal.bankruptcy.filingDivision,
        code: res.legal.bankruptcy.code,
        pocFileDate: dayjs(res.legal.bankruptcy.pocFileDate).utc().format('YYYY-MM-DD'),
      });
      const tempLegalCategoryOptions: string[] = [];
      const tempBankruptcyCategoryOptions: string[] = [];
      const tempInsuranceCategoryOptions: string[] = [];
      const tempSkipCategoryOptions: string[] = [];
      res.categories.forEach(({ field, item }) => {
        if (field === 'Legal') {
          tempLegalCategoryOptions.push(item);
        }
        if (field === 'Bankruptcy') {
          tempBankruptcyCategoryOptions.push(item);
        }
        if (field === 'Insurance') {
          tempInsuranceCategoryOptions.push(item);
        }
        if (field === 'Skip') {
          tempSkipCategoryOptions.push(item);
        }
      });

      if (legalType === 'Bankruptcy') {
        setCategoryOptions(tempBankruptcyCategoryOptions);
      } else if (legalType === 'Legal') {
        setCategoryOptions(tempLegalCategoryOptions);
      } else if (legalType === 'Skip') {
        setCategoryOptions(tempSkipCategoryOptions);
      } else if (legalType === 'Insurance') {
        setCategoryOptions(tempInsuranceCategoryOptions);
      }

      setBankruptcyCategoryOptions(tempBankruptcyCategoryOptions);
      setInsuranceCategoryOptions(tempInsuranceCategoryOptions);
      setSkipCategoryOptions(tempSkipCategoryOptions);
      setLegalCategoryOptions(tempLegalCategoryOptions);
    });
  }, []);

  const submitLegal = (data: FormProps) => {
    setSubmitLoading(true);
    const category = legalResponse.categories.find((value) => value.item === data.category);
    const payload = !data.assignToLegal
      ? {
          appRecId: accountInformation.appRecId!,
          colRecId: colRecId,
          compId: accountInformation.compId!,
          isLegal: data.assignToLegal,
        }
      : data.legalType === 'Bankruptcy'
      ? {
          isLegal: data.assignToLegal,
          legalType: data.legalType,
          category: category!.recId,
          status: data.status,
          compId: accountInformation.compId!,
          colRecId: colRecId,
          appRecId: accountInformation.appRecId!,
          note: null,
          bankruptcy: {
            fileDate: data.fileDate,
            pocFileDate: data.pocFileDate,
            caseNumber: data.caseNumber,
            filingState: data.filingState,
            filingDistrict: data.filingDistrict,
            filingDivision: data.filingDivision,
            code: data.code,
          },
        }
      : {
          isLegal: data.assignToLegal,
          legalType: data.legalType,
          category: category!.recId,
          status: data.status,
          compId: accountInformation.compId!,
          colRecId: colRecId,
          appRecId: accountInformation.appRecId!,
          note: null,
        };

    // @todo use async/await
    legalService
      .updateLegal(payload)
      .then(() => {
        dispatch(getAccountInformation(colRecId));
        navigate('..');
      })
      .finally(() => setSubmitLoading(false));
  };

  // @todo move nested components to separate files
  return (
    <AccountsMainPanel navBarTitle="Legal Account">
      <div className={styles.container}>
        <form
          className={`${styles.formContainer} ${styles.formStyles}`}
          onSubmit={handleSubmit(submitLegal)}
        >
          <Controller
            name="assignToLegal"
            control={control}
            render={({ field }) => (
              <Checkbox label="Assign To Legal?" labelStyles={{ fontSize: '16px' }} {...field} />
            )}
          />
          {assignToLegalChecked && (
            <div className={styles.formStyles}>
              <div className={styles.formStyles}>
                <div className={styles.radioButtonGroup}>
                  <Controller
                    name="legalType"
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <div>
                        <RadioButton
                          label="Bankruptcy"
                          {...props}
                          value="Bankruptcy"
                          defaultChecked={
                            watch('legalType') === 'Bankruptcy' || watch('legalType') === ''
                          }
                          onChange={(e) => {
                            onChange(e.value);
                            setValue('category', '');
                            setCategoryOptions(bankruptcyCategoryOptions);
                          }}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="legalType"
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <div>
                        <RadioButton
                          label="Legal"
                          {...props}
                          value="Legal"
                          defaultChecked={watch('legalType') === 'Legal'}
                          onChange={(e) => {
                            onChange(e.value);
                            setValue('category', '');
                            setCategoryOptions(legalCategoryOptions);
                          }}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="legalType"
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <div>
                        <RadioButton
                          label="Skip"
                          {...props}
                          value="Skip"
                          defaultChecked={watch('legalType') === 'Skip'}
                          onChange={(e) => {
                            onChange(e.value);
                            setValue('category', '');
                            setCategoryOptions(skipCategoryOptions);
                          }}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="legalType"
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <div>
                        <RadioButton
                          label="Insurance"
                          {...props}
                          value="Insurance"
                          defaultChecked={watch('legalType') === 'Insurance'}
                          onChange={(e) => {
                            onChange(e.value);
                            setValue('category', '');
                            setCategoryOptions(insuranceCategoryOptions);
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput label="Category" data={categoryOptions} {...field} />
                  )}
                />
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => <TextInput label="Status" {...field} />}
                />
              </div>
              {watch('legalType') === 'Bankruptcy' && (
                <div className={styles.formStyles}>
                  <Controller
                    name="fileDate"
                    control={control}
                    render={({ field }) => <DateInput label="File Date" {...field} />}
                  />
                  <Controller
                    name="caseNumber"
                    control={control}
                    render={({ field }) => <TextInput label="Case Number" {...field} />}
                  />
                  <Controller
                    name="filingState"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput data={usaStateNames} suggest label="Filing State" {...field} />
                    )}
                  />
                  <Controller
                    name="filingDistrict"
                    control={control}
                    render={({ field }) => <TextInput label="Filing District" {...field} />}
                  />
                  <Controller
                    name="filingDivision"
                    control={control}
                    render={({ field }) => <TextInput label="Filing Division" {...field} />}
                  />
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => <TextInput label="Code" {...field} />}
                  />
                  <Controller
                    name="pocFileDate"
                    control={control}
                    render={({ field }) => <DateInput label="POC File Date" {...field} />}
                  />
                </div>
              )}
            </div>
          )}
          <Button
            label="Submit"
            loading={submitLoading}
            buttonStyle={{ width: '250px' }}
            disabled={!formState.isDirty}
          />
        </form>
      </div>
    </AccountsMainPanel>
  );
};
