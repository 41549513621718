export class ServerSideFiltering {
  constructor(
    readonly page: number,
    readonly perPage: number,
    readonly sorts: SortValue[] | null,
    readonly filter: FilterValue[]
  ) {}

  static fromTableFilter = (
    page: number,
    perPage: number,
    sort: SortValue[] | null,
    filter: FilterValue[]
  ) => {
    return new ServerSideFiltering(page, perPage, sort, filter);
  };
}

export class FilterValue {
  constructor(
    readonly field: string,
    readonly value: string[],
    readonly operator: 'IN' | 'AND' = 'IN'
  ) {}
  get valueFmt(): string {
    const valueFmt = this.value.map((v) => `'${v}'`);
    return `(${valueFmt.join(',')})`;
  }
}

export class FilterValueFmt {
  constructor(
    readonly field: string,
    readonly value: string,
    readonly operator: 'IN' | 'AND' = 'IN'
  ) {}
}

export class SortValue {
  constructor(readonly field: string, readonly direction: 'DESC' | 'ASC' = 'DESC') {}
}

export interface GetInventoryPayload {
  listType: string;
  page: number;
  perPage: number;
  search: string | null;
  soldDateBegin: string | null;
  soldDateEnd: string | null;
  filters: FilterValueFmt[] | null;
  sorts: SortValue[] | null;
}

export interface IFormErrors {
  date: boolean;
  int: boolean;
  amt: boolean;
}

export interface IModalFilterParams {
  beginDate: string | null;
  endDate: string | null;
  amt: number | null;
  int: number | null;
}
class ModalFilterParams {
  constructor(
    public beginDate: string | null = null,
    public endDate: string | null = null,
    public amt: number | null = null,
    public int: number | null = null
  ) {}
}
export const newModalFilterParams = (
  filterParams: Partial<ModalFilterParams> | null
): IModalFilterParams => {
  return new ModalFilterParams(
    filterParams?.beginDate || null,
    filterParams?.endDate || null,
    filterParams?.amt || null,
    filterParams?.int || null
  );
};

export type CheckboxItem = { title: string; display: boolean };
