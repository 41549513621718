import { FC, ReactNode } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import ToastNotiLayout from "@/toast/ToastNotiLayout";
// interfaces
import { ReversiblePaymentPostReq } from "../../interfaces";

/** ###  */
export const ReversalSuccessNoti: FC<{ req: ReversiblePaymentPostReq }> = ({ req }) => {
  const isCc = ["chargeBack", "reapply", "refund"].includes(req.reversalType);
  const msg =
    req.systemOnly && isCc ? (
      <Grid fontSize="14px">Refund must be submitted through payment provider</Grid>
    ) : null;

  return (
    <ToastNotiLayout title={`Successfully reversed payent${isCc ? ":" : ""}`}>
      {msg}
    </ToastNotiLayout>
  );
};

export const RepayErrorNoti: ReactNode = (
  <ToastNotiLayout title="This payment cannot be reversed:">
    <Grid fontSize="14px">Payment was not submitted via Repay.</Grid>
    <Grid container direction="column">
      <Grid fontSize="14px" fontWeight={700}>
        To reverse this payment:
      </Grid>
      <Grid fontSize="14px">1. Select "System Only".</Grid>
      <Grid fontSize="14px">2. Manually reverse payment through the provider.</Grid>
    </Grid>
  </ToastNotiLayout>
);
