import dayjs, { Dayjs } from 'dayjs';
// interfaces
import { PaymentInterval } from '@/enums/payment';
import { InsuranceCoverageOption } from '@/enums/general';
import { CpiSettings } from '@/interfaces/applications';
import { UsaStateCode } from '@/general/regions';

export class InsuranceCommon<TDate extends string | Dayjs = Dayjs> {
  cpiYearlyPremium: number | null;
  insAddress: string | null;
  insCity: string | null;
  insCollisionDeductible: number | null;
  insComprehensiveDeductible: number | null;
  insContact: string | null;
  insContactFax: string | null;
  insContactPhone: string | null;
  insCoverage: InsuranceCoverageOption | null;
  insEmail: string | null;
  insExpire: TDate | null; // datetime
  insFax: string | null;
  insFireTheftDeductible: number | null;
  insInsuranceCompany: string | null;
  insPhone: string | null;
  insPolicyNumber: string | null;
  insRequired: boolean = false;
  insState: UsaStateCode | null;
  insZip: string | null;
  onCpi: boolean = false;

  constructor(input?: InsuranceCommon<string | Dayjs>) {
    this.cpiYearlyPremium = input?.cpiYearlyPremium || null;
    this.insAddress = input?.insAddress || null;
    this.insCity = input?.insCity || null;
    this.insCollisionDeductible = input?.insCollisionDeductible || null;
    this.insComprehensiveDeductible = input?.insComprehensiveDeductible || null;
    this.insContact = input?.insContact || null;
    this.insContactFax = input?.insContactFax || null;
    this.insContactPhone = input?.insContactPhone || null;
    this.insCoverage = input?.insCoverage || null;
    this.insEmail = input?.insEmail || null;
    this.insExpire = (input?.insExpire ? dayjs(input.insExpire) : null) as TDate;
    this.insFax = input?.insFax || null;
    this.insFireTheftDeductible = input?.insFireTheftDeductible || null;
    this.insInsuranceCompany = input?.insInsuranceCompany || null;
    this.insPhone = input?.insPhone || null;
    this.insPolicyNumber = input?.insPolicyNumber || null;
    this.insRequired = input?.insRequired || false;
    this.insState = input?.insState || null;
    this.insZip = input?.insZip || null;
    this.onCpi = input?.onCpi || false;
  }
}

export class SalesInsuranceCommon<TDate extends string | Dayjs = Dayjs> {
  cpiYearlyPremium: number | null;
  insAddress: string | null;
  insCity: string | null;
  insCollisionDeductible: number | null;
  insComprehensiveDeductible: number | null;
  insContact: string | null;
  insContactFax: string | null;
  insContactPhone: string | null;
  insCoverage: InsuranceCoverageOption | null;
  insEmail: string | null;
  insExpire: TDate | null; // datetime
  insFax: string | null;
  insFireTheftDeductible: number | null;
  insInsuranceCompany: string | null;
  insPhone: string | null;
  insPolicyNumber: string | null;
  insRequired: boolean;
  insState: UsaStateCode | null;
  insZip: string | null;
  onCpi: boolean;

  constructor(input?: SalesInsuranceCommon<string | Dayjs>) {
    this.cpiYearlyPremium = input?.cpiYearlyPremium || null;
    this.insAddress = input?.insAddress || null;
    this.insCity = input?.insCity || null;
    this.insCollisionDeductible = input?.insCollisionDeductible || null;
    this.insComprehensiveDeductible = input?.insComprehensiveDeductible || null;
    this.insContact = input?.insContact || null;
    this.insContactFax = input?.insContactFax || null;
    this.insContactPhone = input?.insContactPhone || null;
    this.insCoverage = input?.insCoverage || null;
    this.insEmail = input?.insEmail || null;
    this.insExpire = (input?.insExpire ? dayjs(input.insExpire) : null) as TDate;
    this.insFax = input?.insFax || null;
    this.insFireTheftDeductible = input?.insFireTheftDeductible || null;
    this.insInsuranceCompany = input?.insInsuranceCompany || null;
    this.insPhone = input?.insPhone || null;
    this.insPolicyNumber = input?.insPolicyNumber || null;
    this.insRequired = input?.insRequired || false;
    this.insState = input?.insState || null;
    this.insZip = input?.insZip || null;
    this.onCpi = input?.onCpi || false;
  }
}

export class SalesInsuranceRes extends SalesInsuranceCommon {
  appRecId: number;
  compId: number;
  cpiRate: number | null;
  sched: PaymentInterval | null;

  constructor(res?: SalesInsuranceRes & SalesInsuranceCommon<string>) {
    super(res);

    this.appRecId = res?.appRecId || 0;
    this.compId = res?.compId || 0;
    this.cpiRate = res?.cpiRate || null;
    this.sched = res?.sched || null;
  }
}

export class SalesInsuranceForm extends SalesInsuranceCommon {
  constructor(res?: SalesInsuranceCommon) {
    super(res);

    const insExpireCalc = dayjs(res?.insExpire)?.isValid() ? dayjs(res?.insExpire) : null;

    this.insRequired = res?.insRequired || false;
    this.insPolicyNumber = res?.insPolicyNumber || null;
    this.insCoverage = res?.insCoverage || InsuranceCoverageOption.enum.None;
    this.insExpire = insExpireCalc;
    this.insComprehensiveDeductible = res?.insComprehensiveDeductible || null;
    this.insCollisionDeductible = res?.insCollisionDeductible || null;
    this.insFireTheftDeductible = res?.insFireTheftDeductible || null;
    this.onCpi = res?.onCpi || false;
    this.cpiYearlyPremium = res?.cpiYearlyPremium || null;
    this.insInsuranceCompany = res?.insInsuranceCompany || null;
    this.insAddress = res?.insAddress || null;
    this.insZip = res?.insZip || null;
    this.insCity = res?.insCity || null;
    this.insState = res?.insState || null;
    this.insPhone = res?.insPhone || null;
    this.insFax = res?.insFax || null;
    this.insEmail = res?.insEmail || null;
    this.insContact = res?.insContact || null;
    this.insContactPhone = res?.insContactPhone || null;
    this.insContactFax = res?.insContactFax || null;
  }
}

export class SalesInsurancePutReq extends SalesInsuranceForm {
  appRecId: number;

  constructor(form: SalesInsuranceForm, appRecId: number, cpiSettings: CpiSettings | null) {
    super(form);

    this.insAddress = form?.insAddress;
    this.appRecId = appRecId;
    this.insCity = form.insCity;
    this.insCollisionDeductible = form.insCollisionDeductible;
    this.insComprehensiveDeductible = form.insComprehensiveDeductible;
    this.insContact = form.insContact;
    this.insContactFax = form.insContactFax;
    this.insContactPhone = form.insContactPhone;
    this.insCoverage = form.insCoverage;

    // CPI calcs
    this.onCpi = form.onCpi || false;
    this.cpiYearlyPremium = cpiSettings?.cpiYearlyPremiumEnabled ? form.cpiYearlyPremium : null;

    this.insEmail = form.insEmail;
    this.insExpire = form.insExpire;
    this.insFax = form.insFax;
    this.insFireTheftDeductible = form.insFireTheftDeductible;
    this.insInsuranceCompany = form.insInsuranceCompany;
    this.insPhone = form.insPhone;
    this.insPolicyNumber = form.insPolicyNumber;
    this.insRequired = form.insRequired || false;
    this.insState = form.insState;
    this.insZip = form.insZip;
  }
}
