import { forwardRef } from 'react';
import {
  Loader as KendoLoader,
  LoaderProps as KendoLoaderProps,
  LoaderHandle as KendoLoaderHandle,
} from '@progress/kendo-react-all';

interface LoaderProps extends KendoLoaderProps {
  positionHorizontal?: 'left' | 'middle' | 'right';
  positionVertical?: 'top' | 'middle' | 'bottom';
}

export const Loader = forwardRef<KendoLoaderHandle, LoaderProps>((props, ref) => {
  const { positionHorizontal = 'middle', positionVertical = 'middle', ...restProps } = props;

  let justifyContent = 'center';
  switch (positionHorizontal) {
    case 'left':
      justifyContent = 'flex-start';
      break;
    case 'right':
      justifyContent = 'flex-end';
      break;
  }

  let alignItems = 'center';
  switch (positionVertical) {
    case 'top':
      alignItems = 'flex-start';
      break;
    case 'bottom':
      alignItems = 'flex-end';
      break;
  }

  return (
    <div style={{ display: 'flex', justifyContent: justifyContent, alignItems: alignItems }}>
      <KendoLoader ref={ref} {...restProps} />
    </div>
  );
});
