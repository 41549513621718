import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// kendo
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-all";
import { Button } from "@/components/button/Button";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { TextInput } from "@/components/inputs/text/TextInput";
// components
import { MultipleInputRow } from "@/features/Sales/components/multipleInputRow/MultipleInputRow";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useHotlistCtx } from "@/components/hotlist/HotlistProvider";
// utils
import { inventoryService } from "@/services/inventoryService";
import { optionalNotGreaterThanTodayValidation } from "@/utils/helpers/formValidation";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { SetState } from "@/interfaces/utilityTypes";
import { VehicleCost } from "@/interfaces/Inventory";
// style
import styles from "./InventoryVehicle.module.scss";

/** ### */
const VehicleCostForm: FC<{ vehicleCost: VehicleCost; setDirty: SetState<boolean> }> = ({
  vehicleCost,
  setDirty,
}) => {
  const invRecId = getRouteParamNum(useParams().invRecId);

  const invPrice = useAuthSelector((s) => s.invPrice);
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<VehicleCost>({
    defaultValues: vehicleCost,
  });

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);
  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  const updateCalculatedFields = (
    event: NumericTextBoxChangeEvent,
    fieldName: keyof VehicleCost
  ) => {
    setValue(fieldName, event.target.value ?? 0, { shouldDirty: true });

    const [purPrice, totExp, psiAmt, buyerFee, aucFee, dpack] = getValues([
      "purPrice",
      "totExp",
      "psiAmt",
      "buyerFee",
      "aucFee",
      "dpack",
    ]);

    const totCost =
      purPrice + (totExp ?? 0) + (psiAmt ?? 0) + (buyerFee ?? 0) + (aucFee ?? 0) + (dpack ?? 0);
    const vehCost = purPrice + (psiAmt ?? 0) + (aucFee ?? 0);

    setValue("totCost", totCost);
    setValue("vehCost", vehCost);
  };

  const submitVehicleCostDetailsForm = async (vehicleCostForm: VehicleCost) => {
    // Validate
    if (!vehicleCostForm || !invRecId) throw new Error("Form is undefined");

    try {
      setIsLoading(true);
      const updatedVehicleCostDetails = await inventoryService.updateVehicleCostDetails(
        invRecId.toString(),
        vehicleCostForm
      );

      reset(updatedVehicleCostDetails);

      // Update relevant SAM hotlist AFTER response is received
      fetchHotlist(invRecId, "Inventory", "Active");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const vehicleSold = vehicleCost.saleStatus?.toLowerCase() === "s";

  return (
    <>
      <form onSubmit={handleSubmit(submitVehicleCostDetailsForm)} style={{ marginTop: "20px" }}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <Controller
              name="purPrice"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  defaultValue={0}
                  label="Purchase Price"
                  disabled={!invPrice}
                  {...field}
                  onChange={(event) => updateCalculatedFields(event, "purPrice")}
                />
              )}
            />
            <MultipleInputRow label="PSI Cost/Days">
              <Controller
                name="psiAmt"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    onChange={(event) => updateCalculatedFields(event, "psiAmt")}
                  />
                )}
              />
              <Controller
                name="psi"
                control={control}
                render={({ field }) => <TextInput disabled={vehicleSold} {...field} />}
              />
            </MultipleInputRow>
            <Controller
              name="aucFee"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label="Auction Fee"
                  {...field}
                  onChange={(event) => updateCalculatedFields(event, "aucFee")}
                />
              )}
            />
            <Controller
              name="vehCost"
              control={control}
              render={({ field }) => (
                <CurrencyInput label="Vehicle Cost" disabled={true} {...field} />
              )}
            />
            <Controller
              name="paidForDate"
              control={control}
              rules={{ ...optionalNotGreaterThanTodayValidation }}
              render={({ field }) => (
                <DateInput label="Date Paid" errors={errors.paidForDate?.message} {...field} />
              )}
            />
          </div>
          <div className={styles.column}>
            <Controller
              name="paidForNum"
              control={control}
              render={({ field }) => <TextInput label="Check/Ref. #" {...field} />}
            />
            <Controller
              name="buyerFee"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label="Buyer Fee"
                  {...field}
                  onChange={(event) => updateCalculatedFields(event, "buyerFee")}
                />
              )}
            />
            <Controller
              name="dpack"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label="Pack"
                  {...field}
                  onChange={(event) => updateCalculatedFields(event, "dpack")}
                />
              )}
            />
            <Controller
              name="totExp"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label="Total Expenses"
                  disabled={true}
                  {...field}
                  onChange={(event) => updateCalculatedFields(event, "totExp")}
                />
              )}
            />
            {vehicleCost.transport > 0 && (
              <Controller
                name="transport"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Includes Transport of"
                    disabled={true}
                    {...field}
                    onChange={(event) => updateCalculatedFields(event, "totExp")}
                  />
                )}
              />
            )}
            <Controller
              name="totCost"
              control={control}
              render={({ field }) => (
                <CurrencyInput label="Total Cost" disabled={true} {...field} />
              )}
            />
          </div>
        </div>
        <div className={styles.buttonRow}>
          <Button label="Submit" secondary={!isDirty} disabled={!isDirty} loading={isLoading} />
          <Button label="Cancel" secondary={!isDirty} disabled={!isDirty} onClick={() => reset()} />
        </div>
      </form>
    </>
  );
};

export default VehicleCostForm;
