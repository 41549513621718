import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
// kendo
import { Button } from '@/components/button/Button';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { TextArea } from '@/components/inputs/textarea/TextArea';
// components
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// state
import { useAppDispatch } from '@/store/store';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { accountsService } from '@/services/accountsService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { genericRequired, dateGTETodayValidation } from '@/utils/helpers/formValidation';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { LeaseAmendmentForm, LeaseAmendmentGetRes } from './interfaces';
// style
import styles from './LeaseAmendment.module.scss';
import accountStyles from '../accountsMainPanel/AccountsMainPanel.module.scss';

/** ###  */
const LeaseAmendment: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loadingLeaseAmendmentData, setLoadingLeaseAmendmentData] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [leaseAmendmentData, setLeaseAmendmentData] = useState<LeaseAmendmentGetRes>();

  const colRecId = getRouteParamNum(useParams().colRecId);

  const submit = async (data: LeaseAmendmentForm) => {
    if (!colRecId) return;

    setSubmitting(true);
    try {
      await accountsService.postLeaseAmendment({
        colRecId,
        schedule: data.schedule.schedule,
        notes: data.notes,
        firstPaymentDue: data.firstPaymentDue,
        rewriteCategory: data.rewriteCategory,
      });
      // Update state
      dispatch(getAccountInformation(colRecId));
      // Nav back
      navigate('..');
    } finally {
      setSubmitting(false);
    }
  };

  const { handleSubmit, reset, control } = useForm<LeaseAmendmentForm>();

  const loadLeaseAmendmentData = async () => {
    if (!colRecId) return;

    try {
      setLoadingLeaseAmendmentData(true);
      const leaseAmendmentRes = await accountsService.getLeaseAmendmentData(colRecId);

      setLeaseAmendmentData(leaseAmendmentRes);

      const existingSchedule = leaseAmendmentRes?.leaseAmendmentSchedules?.find(
        (leaseSchedule) => leaseSchedule.schedule === leaseAmendmentRes?.existingSchedule
      );
      reset({
        colRecId,
        schedule: existingSchedule,
        rewriteCategory: '',
        notes: '',
        firstPaymentDue:
          formatDate(leaseAmendmentRes?.firstPaymentDue, { pattern: DateFormat.DateInput }) ??
          undefined,
      });
    } finally {
      setLoadingLeaseAmendmentData(false);
    }
  };

  useEffect(() => {
    loadLeaseAmendmentData();
  }, []);

  const { schedule, firstPaymentDue } = useWatch({ control });

  const firstDue = dayjs.utc(leaseAmendmentData?.firstPaymentDue);

  const firstDueDaysFromToday = dayjs.utc(firstPaymentDue).diff(firstDue, 'days') ?? 0;

  const finalPaymentDue = dayjs.utc(schedule?.finalPaymentDue).add(firstDueDaysFromToday, 'days');

  return (
    <AccountsMainPanel navBarTitle="Lease Amendment" loading={loadingLeaseAmendmentData}>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(submit)} className={styles.form}>
          <div className={accountStyles.columns}>
            <div className={accountStyles.column}>
              <Controller
                name="schedule"
                control={control}
                rules={{ required: 'Schedule is required' }}
                render={({ field, fieldState }) => (
                  <DropdownInput
                    {...field}
                    label="Schedule"
                    required
                    data={leaseAmendmentData?.leaseAmendmentSchedules || []}
                    dataItemKey="schedule"
                    textField="schedule"
                    errors={!!fieldState.error}
                  />
                )}
              />
              <Controller
                name="firstPaymentDue"
                control={control}
                rules={{ ...dateGTETodayValidation, ...genericRequired }}
                render={({ field, fieldState }) => (
                  <DateInput
                    {...field}
                    label="Next Due Date"
                    required
                    errors={!!fieldState.error}
                  />
                )}
              />
              <Controller
                name="rewriteCategory"
                control={control}
                rules={{ required: 'Rewrite Category is required' }}
                render={({ field, fieldState }) => (
                  <DropdownInput
                    {...field}
                    label="Rewrite Category"
                    required
                    data={leaseAmendmentData?.rewriteCategories || []}
                    errors={!!fieldState.error}
                  />
                )}
              />
              <Controller
                name="notes"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    label="Notes"
                    required
                    rows={4}
                    errors={!!fieldState.error}
                  />
                )}
              />
            </div>
            <div className={accountStyles.column}>
              <CurrencyInput value={schedule?.paymentAmount} label="Payment Amount" readOnly />
              <NumberInput value={schedule?.numberOfPayments} label="# of Payments" readOnly />
              <CurrencyInput value={schedule?.finalPayment} label="Final Payment" readOnly />
              <TextInput
                value={formatDate(finalPaymentDue.toDate())}
                label="Final Payment Due"
                readOnly
              />
              <CurrencyInput value={leaseAmendmentData?.residual} label="Residual" readOnly />
              <Button label="Submit" loading={submitting} />
            </div>
          </div>
        </form>
      </div>
    </AccountsMainPanel>
  );
};

export default LeaseAmendment;
