import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
// state
import { useGpsCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsProvider";
// utils
import { inventoryService } from "@/services/inventoryService";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { SetState } from "@/interfaces/utilityTypes";
import {
  CheckInItem,
  CurrentInspection,
  GeneralInformation,
  InventoryVehicle,
  OptionsItem,
  VehicleInspector,
} from "@/interfaces/Inventory";

type ICtx = IInventoryDetailCtx;
export interface IInventoryDetailCtx {
  inventoryVehicle: InventoryVehicle;
  setInventoryVehicle: SetState<ICtx["inventoryVehicle"]>;

  inspectorList: VehicleInspector[];
  setInspectorList: SetState<ICtx["inspectorList"]>;

  currentInspection: CurrentInspection;
  setCurrentInspection: SetState<ICtx["currentInspection"]>;

  checkState: boolean[];
  setCheckState: (idx: number, isChecked: boolean) => void;
  initCheckState: (checkState: boolean[]) => void;

  verifyState: boolean[];
  setVerifyState: (idx: number, isVerified: boolean) => void;
  initVerifyState: (verifyState: boolean[]) => void;

  commentState: string[];
  setCommentState: (idx: number, isComment: string) => void;
  initCommentState: (commentState: string[]) => void;

  checkInState: CheckInItem[];
  setCheckInState: SetState<ICtx["checkInState"]>;

  checkInList: CheckInItem[];
  setCheckInList: SetState<ICtx["checkInList"]>;

  optionsState: OptionsItem[];
  setOptionsState: SetState<ICtx["optionsState"]>;

  optionsList: OptionsItem[];
  setOptionsList: SetState<ICtx["optionsList"]>;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  isDeleting: boolean;
  setIsDeleting: (isDeleting: boolean) => void;

  inputValue: string;
  setInputValue: (inputValue: string) => void;

  generalInformation: GeneralInformation | null;
  setGeneralInformation: SetState<ICtx["generalInformation"]>;
}

const Ctx = createContext<IInventoryDetailCtx | null>(null);

const InventoryDetailProvider: FC<PropsWithChildren> = ({ children }) => {
  const invRecId = getRouteParamNum(useMatch({ path: "/:root/:invRecId/*" })?.params.invRecId);

  const loadGpsInfo = useGpsCtx((s) => s.loadGpsInfo);

  const [checkState, setCheckState_] = useState<boolean[]>([]);
  const [verifyState, setVerifyState_] = useState<boolean[]>([]);
  const [commentState, setCommentState_] = useState<string[]>([]);
  const [checkInState, setCheckInState] = useState<CheckInItem[]>([]);
  const [checkInList, setCheckInList] = useState<CheckInItem[]>([]);
  const [optionsState, setOptionsState] = useState<OptionsItem[]>([]);
  const [optionsList, setOptionsList] = useState<OptionsItem[]>([]);
  const [inventoryVehicle, setInventoryVehicle] = useState<InventoryVehicle>(
    new InventoryVehicle(0)
  );
  const [inspectorList, setInspectorList] = useState<VehicleInspector[]>([
    new VehicleInspector("", 0),
  ]);
  const [currentInspection, setCurrentInspection] = useState<CurrentInspection>(
    new CurrentInspection(undefined, undefined, undefined, undefined, undefined)
  );

  // @note use useReq
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // @note use useReq
  const [generalInformation, setGeneralInformation] = useState<ICtx["generalInformation"]>(null);

  const loadGpsData = async () => {
    if (!invRecId) return;

    const genInfo = await inventoryService.getGeneralInformation(invRecId.toString());
    setGeneralInformation(genInfo || null);

    await loadGpsInfo(invRecId, "vehRecId");
  };

  useEffect(() => {
    loadGpsData();
  }, [invRecId]);

  return (
    <Ctx.Provider
      value={{
        inventoryVehicle,
        setInventoryVehicle,
        inspectorList,
        setInspectorList,
        currentInspection,
        setCurrentInspection,
        checkState,
        setCheckState: (idx: number, isChecked: boolean) => {
          const updatedCheckState = [...checkState];
          updatedCheckState[idx] = isChecked;
          setCheckState_(updatedCheckState);
        },
        initCheckState: (checkState: boolean[]) => {
          setCheckState_(checkState);
        },
        verifyState,
        setVerifyState: (idx: number, isVerified: boolean) => {
          const updatedCheckState = [...verifyState];
          updatedCheckState[idx] = isVerified;
          setVerifyState_(updatedCheckState);
        },
        initVerifyState: (verifyState: boolean[]) => {
          setVerifyState_(verifyState);
        },
        commentState,
        setCommentState: (idx: number, isComment: string) => {
          const updatedCommentState = [...commentState];
          updatedCommentState[idx] = isComment;
          setCommentState_(updatedCommentState);
        },
        initCommentState: (commentState: string[]) => {
          setCommentState_(commentState);
        },
        checkInState,
        setCheckInState,
        checkInList,
        setCheckInList,
        optionsState,
        setOptionsState,
        optionsList,
        setOptionsList,
        isLoading,
        setIsLoading,
        inputValue,
        setInputValue,
        isDeleting,
        setIsDeleting,

        generalInformation,
        setGeneralInformation,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};
export default InventoryDetailProvider;

export const useInventoryDetailCtx = useCtxFactory(Ctx);
