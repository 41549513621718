import { NavigateOptions, useNavigate } from 'react-router-dom';

/** ### Returns an event-handler callback that navigates up one level, relative to the current URL path NOT component heirarchy
 * @note Only for use in DOM-element event-handlers (i.e. `onClick={navUp()}`/`onSubmit={navUp()}`)
 * @note For use in component body: see `useNavUp()` component in `useNavUp.tsx`
 *
 * @todo add ability to enter number of levels - `(numberOfLevels: number = 1)`
 */
export const useNavUp = (options?: NavigateOptions) => {
  const navigate = useNavigate();

  return () => navigate('..', { relative: 'path', ...options });
};
