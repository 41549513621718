import { useEffect, useState } from 'react';
import {paymentService,Employee,PaymentProviders,createMiscPaymentPayload,} from '@services/paymentService';
import styles from './AcMiscPayment.module.scss';
import commonStyles from '@features/old/payment/Payment.module.scss';
import { CircleSpinner } from 'react-spinners-kit';
import {
  Button,
  DmsDropDown,
  DmsModal,
  DmsNumberInput,
  DmsRadioButtonGroup,
  DmsTextInput,
  OpenEdge,
  Repay,
  SavedACH,
  SavedCreditCard,
} from '@components/old';
import { Nullable } from '@utils/types';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getCanWaiveFeeDeprec,
  getConvenienceFeeByProviderData,
  getIsNewPayment,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  pollForReceiptOld,
} from '@/utils/helpers/payment';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials } from '@/utils/helpers/general';
import { emailRegex } from '@/utils/helpers/formValidation';
import { CardProcessorName, PaymentType } from '@/enums';
import { useOldPaymentSelector } from '../oldPaymentSlice';
import levelUpImage from '@assets/level_up.png';
import { Spacer } from "@/components/spacer/Spacer";
import { PostPaymentPayloadOld } from '../interfaces';

interface FormError {
  inputName?: string;
  message?: string;
}

const navigateToList = () => {
  window.ACMPaySelect?.panelSetActive('MASTER', true);
  setTimeout(() => {
    window.ACMPaySelect?.runAction('RefreshList');
  }, 1000);
};

export const ACMiscPayment = () => {
  const { orgId, compId, locId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [formErrors, setFormErrors] = useState([] as FormError[]);
  const [ccOrAchFormErrors, setCcOrAchFormErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dmsPaymentPayload, setDmsPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [transactionType, setTransactionType] = useState<string>('In-Person');
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Cash);
  const [paymentName, setPaymentName] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [referenceNumber, setReferenceNumber] = useState<string>('');
  const [amountTendered, setAmountTendered] = useState<number>(0);
  const [changeDue, setChangeDue] = useState<number | null>(0);
  const [employee, setEmployee] = useState<Employee>();
  const [paymentFor, setPaymentFor] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  /** @todo rename to `pmtProvider` - similar field-names: `['processor', ]` */
  const [processor, setProcessor] = useState<CardProcessorName | undefined>();
  const [processors, setProcessors] = useState<CardProcessorName[] | undefined>();
  const [providerData, setProviderData] = useState<PaymentProviders>({} as PaymentProviders);
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const [repayModal, setRepayModal] = useState<boolean>(false);
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [openEdgeAchModal, setOpenEdgeAchModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);

  const [convenienceFee, setConvenienceFee] = useState(0);
  const [waiveFee, setWaiveFee] = useState<boolean>(false);
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [newCard, setNewCard] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('AL');
  const [billEmail, setBillEmail] = useState('');

  const [saveAccount, setSaveAccount] = useState<boolean>(false);
  const [newAccount, setNewAccount] = useState<boolean>(true);
  const [accountType, setAccountType] = useState<string>('Checking');

  const [miscCategories, setMiscCategories] = useState<string[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<Employee[]>([]);

  const shortInputStyle = { width: '100%' };
  const longInputStyle = { width: '100%' };

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  const initData = async () => {
    setLoading(true);

    try {
      paymentService.getPaymentProvidersByCompany(compId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        setProcessors(enabledProviders);
        setProcessor(preferredProviderName);
      });

      const users = await paymentService.getUsersByCompanyId(compId!);
      setEmployeeOptions(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (defaultEmployee) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      const miscCategories = await paymentService.getMiscCategories(orgId!);
      setMiscCategories(miscCategories);
      if (miscCategories[0]) {
        setCategory(miscCategories[0]);
      }

      const dms = createMiscPaymentPayload();
      setDmsPaymentPayload(dms);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const submit = () => {
    const isAch = paymentType === PaymentType.Ach;
    const isCC = paymentType === PaymentType.CreditCard;

    const tempDmsPayload = dmsPaymentPayload;

    const takenBy = getInitials(employee!.shortName);

    tempDmsPayload!.OrgId = orgId!;
    tempDmsPayload!.CompId = compId!;
    tempDmsPayload!.LocId = locId!;
    tempDmsPayload!.ColRecId = 0;
    tempDmsPayload!.AppRecId = 0;
    tempDmsPayload!.AchAcctType = getAchAcctType(paymentType, accountType);
    tempDmsPayload!.AchConvFee = isAch ? convenienceFee : 0;
    tempDmsPayload!.BillAddress = address;
    tempDmsPayload!.BillCity = city;
    tempDmsPayload!.BillEmail = billEmail;
    tempDmsPayload!.BillFirstName = firstName;
    tempDmsPayload!.BillLastName = lastName;
    tempDmsPayload!.BillState = state;
    tempDmsPayload!.BillZip = zip;
    tempDmsPayload!.CarPmt = amount;
    tempDmsPayload!.CcConvFee = isCC ? convenienceFee : 0;
    tempDmsPayload!.Change = changeDue ?? 0;
    tempDmsPayload!.ConvFee = convenienceFee;
    tempDmsPayload!.IsAch = isAch;
    tempDmsPayload!.IsNewCard = getIsNewPayment(paymentType, newCard, newAccount);
    tempDmsPayload!.MCat = category;
    tempDmsPayload!.Mpd.Token = '';
    tempDmsPayload!.PaidBy = paymentType;
    tempDmsPayload!.PaidIn = transactionType;
    tempDmsPayload!.PaidRef = referenceNumber;
    tempDmsPayload!.PayNote = paymentFor;
    tempDmsPayload!.PayToFrom = paymentName;
    tempDmsPayload!.PmtContext = 'AC_MISC';
    tempDmsPayload!.PmtType = paymentType;
    tempDmsPayload!.ProcessorType = getProcessorIntByName(processor!);
    tempDmsPayload!.SaveCard = false;
    tempDmsPayload!.TakenBy = takenBy;
    tempDmsPayload!.TakenByPassword = password;
    tempDmsPayload!.TotalReceived = amountTendered;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;
    tempDmsPayload!.UserShortName = employee!.shortName;
    tempDmsPayload!.WaiveAchConvFee = isAch && waiveFee;
    tempDmsPayload!.WaiveCCConvFee = isCC && waiveFee;
    tempDmsPayload!.WaiveConvFee = waiveFee;

    setDmsPaymentPayload(tempDmsPayload);

    const errors = [] as FormError[];

    !paymentName && errors.push({ inputName: 'paymentName', message: 'Name is required' });
    !amount && errors.push({ inputName: 'amount', message: 'Amount is required' });
    !employee?.recId && errors.push({ inputName: 'employee', message: 'Employee is required' });
    !paymentFor && errors.push({ inputName: 'paymentFor', message: 'Payment For is required' });
    !password && errors.push({ inputName: 'password', message: 'Password is required' });
    (isCC || isAch) &&
      ccOrAchFormErrors &&
      errors.push({ inputName: 'billingInfoError', message: 'Billing information is required' });

    isAch &&
      processor === CardProcessorName.Repay &&
      !emailRegex.test(billEmail || '') &&
      errors.push({ inputName: 'billEmail', message: 'Payment email must be a valid email' });

    if (errors.length) {
      console.error(errors);
    }

    setFormErrors(errors);

    if (errors.length) return;

    if (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) {
      setShowModal(true);
    } else if (processor === CardProcessorName.Repay && tempDmsPayload) {
      // Get iframe url
      paymentService.postPaymentSubmit(tempDmsPayload as any).then((res) => {
        setPaymentLogRecId(res.paymentLogRecId);
        setIframeUrl(res.iFrameUrl);
        setRepayModal(true);
      });
    } else if (processor === CardProcessorName.OpenEdge && paymentType === PaymentType.CreditCard) {
      paymentService
        .postPaymentSubmit(tempDmsPayload as any)
        .then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setOpenEdgeModal(true);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setOpenEdgeAchModal(true);
    }
  };

  const errorMessage = (value: Nullable<number>) => {
    if (Number.isNaN(value) || value === null) {
      return 'Value cannot be empty';
    } else {
      return;
    }
  };

  const handleTotalPaymentBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setAmountTendered(parseFloat(e.target.value) + convenienceFee);
  };

  const handleAmountTenderedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = parseFloat(e.target.value);
    if (value < amount) {
      setAmountTendered(amount + convenienceFee);
    } else {
      setAmountTendered(value + convenienceFee);
    }
  };

  const changeDueStyle = { width: '100%' } as React.CSSProperties;
  if (paymentType === 'Credit Card') {
    changeDueStyle.display = 'none';
  } else if (changeDue !== null) {
    changeDueStyle.backgroundColor = '#f5f5f5';
  } else if (changeDue === null) {
    changeDueStyle.color = 'transparent';
  }

  useEffect(() => {
    setAmountTendered(amount + convenienceFee);
  }, [waiveFee, paymentType, convenienceFee]);

  useEffect(() => {
    if (!providerData || !processor) return;
    setConvenienceFee(
      getConvenienceFeeByProviderData(providerData!, processor, paymentType, waiveFee)
    );
  }, [paymentType, processor, waiveFee]);

  useEffect(() => {
    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setChangeDue(0);
    } else if (amountTendered > amount) {
      setChangeDue(amountTendered - amount);
    } else {
      setChangeDue(0);
    }
  }, [amountTendered, amount, paymentType]);

  useEffect(() => {
    if (!providerData) return;
    let tempPaymentTypes = getAllowedPaymentTypes(providerData);
    if (amount < 0) {
      // if amount is negative, it's a pay out, and cc/ach cannot be used
      tempPaymentTypes = tempPaymentTypes.filter(
        (type) => ![PaymentType.Ach, PaymentType.CreditCard].includes(type)
      );
      if ([PaymentType.Ach, PaymentType.CreditCard].includes(paymentType)) {
        setPaymentType(PaymentType.Cash);
      }
    }
    setPaymentTypes(tempPaymentTypes);
  }, [providerData, amount, paymentType]);

  useEffect(() => {
    initData();
  }, []);

  if (loading) {
    return (
      <div className={styles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  const canWaiveFee = getCanWaiveFeeDeprec(processor, providerData);

  return (
    <div>
      {postPaymentLoading && (
        <div className={styles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={styles.header}>
        <img src={levelUpImage} onClick={navigateToList} />
        Miscellaneous Payment
      </div>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          {processors && processors.length > 1 && (
            <DmsDropDown
              name="processor"
              label="Processor"
              values={processors}
              value={processor}
              inputContainerStyle={{ width: '155px' }}
              onChange={(e) => setProcessor(e.target.value as CardProcessorName)}
            />
          )}
          <DmsRadioButtonGroup
            name="transactionType"
            inputLabel="Accepted In"
            buttons={['In-Person', 'Phone', 'Mail', 'Night Drop']}
            radioButtonGroupName="Transaction"
            required
            onChange={(e) => setTransactionType(e.target.value)}
          />

          <DmsDropDown
            name="paymentType"
            label="Payment Type"
            values={paymentTypes}
            value={paymentType}
            inputContainerStyle={longInputStyle}
            onChange={(e) => setPaymentType(e.target.value as PaymentType)}
          />

          <DmsTextInput
            name="paymentName"
            inputLabel="Name"
            inputStyles={longInputStyle}
            onChange={(e) => setPaymentName(e.target.value)}
            value={paymentName}
            required
            errorMessage={formErrors.find((error) => error.inputName === 'paymentName')?.message}
          />

          <DmsDropDown
            name="category"
            label="Category"
            values={miscCategories}
            value={category}
            inputContainerStyle={{ width: '20rem' }}
            onChange={(e) => {
              setCategory(e.target.value);
              setPaymentFor(e.target.value);
            }}
            required
          />

          <DmsNumberInput
            name="amount"
            inputLabel="Amount"
            inputContainerStyle={{ width: '100%' }}
            inputStyles={shortInputStyle}
            fixedDecimalScale
            decimalScale={2}
            errors={formErrors.find((error) => error.inputName === 'amount')?.message}
            onBlur={(e) => {
              parseFloat(e.target.value);
              setDmsPaymentPayload({
                ...dmsPaymentPayload!,
                ColType: parseFloat(e.target.value) >= 0 ? 'MI' : 'MO',
              });
              handleTotalPaymentBlur(e);
            }}
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
            }}
            value={amount}
            followingText={amount >= 0 ? 'Pay In' : 'Pay Out'}
            required
          />

          <DmsTextInput
            name="referenceNumber"
            inputLabel="Reference #"
            inputStyles={longInputStyle}
            onChange={(e) => setReferenceNumber(e.target.value)}
            value={referenceNumber}
          />

          <DmsNumberInput
            name="amountTendered"
            inputLabel="Amount Tendered"
            inputStyles={shortInputStyle}
            fixedDecimalScale
            decimalScale={2}
            errors={errorMessage(amount)}
            onBlur={(e) => {
              parseFloat(e.target.value);
              handleAmountTenderedBlur(e);
            }}
            onChange={(e) => setAmountTendered(parseFloat(e.target.value))}
            value={amountTendered}
            readOnly={paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach}
            required
          />

          <DmsNumberInput
            name="changeDue"
            inputLabel="Change Due"
            inputStyles={longInputStyle}
            fixedDecimalScale
            decimalScale={2}
            errors={errorMessage(amount)}
            onBlur={(e) => parseFloat(e.target.value)}
            onChange={(e) => setChangeDue(parseFloat(e.target.value))}
            value={changeDue}
            readOnly
          />

          <DmsTextInput
            inputLabel="Password"
            inputStyles={longInputStyle}
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            errorMessage={formErrors.find((error) => error.inputName === 'password')?.message}
          />

          <DmsDropDown
            name="employee"
            label="Employee"
            values={employeeOptions.map((employee) => {
              return employee.shortName;
            })}
            value={employee?.shortName}
            inputContainerStyle={longInputStyle}
            onChange={(e) => setEmployee(employeeOptions[e.target.options.selectedIndex])}
          />

          <DmsTextInput
            name="paymentFor"
            inputLabel="Payment For"
            inputStyles={{ width: '100%' }}
            onChange={(e) => setPaymentFor(e.target.value)}
            value={paymentFor}
            required
            errorMessage={formErrors.find((error) => error.inputName === 'paymentFor')?.message}
          />

          {paymentType === PaymentType.Ach && processor === CardProcessorName.Repay && (
            <DmsTextInput
              name="billEmail"
              inputLabel="Payment Email"
              inputStyles={{ width: '100%' }}
              onChange={(e) => setBillEmail(e.target.value)}
              value={billEmail}
              required
              errorMessage={formErrors.find((error) => error.inputName === 'billEmail')?.message}
            />
          )}
          <Button
            buttonLabel="Send Payment"
            onClick={submit}
            style={{
              padding: '4px 0',
              width: '100%',
              height: '36px',
              fontSize: '18px',
              marginTop: '10px',
            }}
          />
        </div>
        <div className={styles.rightContainer}>
          {paymentType === 'Credit Card' && (
            <SavedCreditCard
              convenienceFee={convenienceFee}
              waiveFee={waiveFee}
              onWaiveFee={setWaiveFee}
              saveCard={saveCard}
              onSaveCard={setSaveCard}
              newCard={newCard}
              onNewCard={setNewCard}
              firstName={firstName}
              onFirstName={setFirstName}
              lastName={lastName}
              onLastName={setLastName}
              zip={zip}
              onZip={setZip}
              address={address}
              onAddress={setAddress}
              city={city}
              onCity={setCity}
              state={state}
              onState={setState}
              onSetFormErrors={setCcOrAchFormErrors}
              useCardOnFile={false}
              savedPaymentMethods={[]}
              paymentProvider={processor!}
              canWaiveFee={canWaiveFee}
            />
          )}

          {paymentType === 'ACH' && (
            <SavedACH
              convenienceFee={convenienceFee}
              waiveFee={waiveFee}
              onWaiveFee={setWaiveFee}
              saveAccount={saveAccount}
              onSaveAccount={setSaveAccount}
              newAccount={newAccount}
              onNewAccount={setNewAccount}
              firstName={firstName}
              onFirstName={setFirstName}
              lastName={lastName}
              onLastName={setLastName}
              onAccountType={setAccountType}
              onSetFormErrors={setCcOrAchFormErrors}
              useAchOnFile={false}
              savedPaymentMethods={[]}
              paymentProvider={processor!}
              canWaiveFee={canWaiveFee}
            />
          )}
        </div>
      </div>
      <DmsModal
        title="Confirm Payment"
        message="Are you sure you want to post this payment?"
        onAcceptLabel="Yes"
        onDeclineLabel="No"
        isOpen={showModal}
        closeFunction={() => {
          setShowModal(false);
        }}
        onDeclineFunction={() => {
          setShowModal(false);
        }}
        onAcceptFunction={() => {
          paymentService
            .postPaymentSubmit(dmsPaymentPayload as any)
            .then((res) => {
              pollForReceiptOld(res.paymentLogRecId, () => {
                navigateToList();
                setShowModal(false);
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      />
      <DmsModal
        isOpen={openEdgeAchModal}
        closeFunction={() => setOpenEdgeAchModal(false)}
        title="OpenEdge Payment"
        creditCardPaymentProvider="OpenEdge"
      >
        <div className={commonStyles.achContainer}>
          <div className={commonStyles.achInputLabel}>Routing Number</div>
          <DmsNumberInput
            name="routingNumber"
            inputContainerStyle={{ width: '100%' }}
            inputStyles={{ width: '100%' }}
            onChange={(e) => setRoutingNumber(e.target.value)}
            maxLength={9}
          />
          <Spacer size={10} />
          <div className={commonStyles.achInputLabel}>Account Number</div>
          <DmsNumberInput
            name="accountNumber"
            inputContainerStyle={{ width: '100%' }}
            inputStyles={{ width: '100%' }}
            onChange={(e) => setAccountNumber(e.target.value)}
            maxLength={17}
          />
          <Spacer size={10} />
          <Button
            buttonStyles={{ width: '300px', height: '30px' }}
            buttonLabel="Submit"
            onClick={() => {
              dmsPaymentPayload!.Mpd.AccountNumber = accountNumber;
              dmsPaymentPayload!.Mpd.RoutingNumber = routingNumber;
              paymentService
                .postPaymentSubmit(dmsPaymentPayload as any)
                .then((res) => {
                  pollForReceiptOld(res.paymentLogRecId, () => {
                    setOpenEdgeAchModal(false);
                    navigateToList();
                  });
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          />
        </div>
      </DmsModal>
      {processors && processors.includes(CardProcessorName.OpenEdge) && openEdgeModal && (
        <DmsModal
          isOpen={openEdgeModal}
          closeFunction={() => {
            setOpenEdgeModal(false);
          }}
          title="OpenEdge Payment"
          creditCardPaymentProvider={CardProcessorName.OpenEdge}
        >
          {paymentLogRecId !== 0 && (
            <OpenEdge
              paymentLogRecId={paymentLogRecId}
              onComplete={navigateToList}
              apiKey={providerData.payfieldsApiKey}
            />
          )}
        </DmsModal>
      )}
      {repayModal && (
        <DmsModal
          isOpen={repayModal}
          closeFunction={() => {
            setRepayModal(false);
          }}
          title="Repay Payment"
          creditCardPaymentProvider={CardProcessorName.Repay}
        >
          <Repay iframe={iframeUrl} paymentLogRecId={paymentLogRecId} onComplete={navigateToList} />
        </DmsModal>
      )}
    </div>
  );
};
