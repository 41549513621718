import {
  DropDownList,
  Grid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
  GridPagerSettings,
  GridSortChangeEvent,
  GridToolbar,
  Input,
  Loader,
  PagerTargetEvent,
} from '@progress/kendo-react-all';
import { FC, useState } from 'react';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
  filterBy,
  orderBy,
} from '@progress/kendo-data-query';
import { Column, PageState } from './TableInterface';
import styles from './Table.module.scss';

interface TableProps {
  columns: Column[];
  data: any[];
  onRowClick?: (e: any) => void;
  pageable?: boolean | GridPagerSettings;
  sortable?: boolean;
  filter?: CompositeFilterDescriptor;
  noResultsComponent?: JSX.Element;
  initPageSize?: number;
  initSort?: Array<SortDescriptor>;
  loading?: boolean;
}

export const DropdownFilterCell: FC = (props: any) => {
  const { column, onChange, value } = props;

  if (!column.filter) {
    return null;
  }

  // custom logic
  return (
    <DropDownList
      data={column.filter.options}
      onChange={(e) => onChange({ value: e.target.value })}
      value={value}
    />
  );
};

/**
 * @param columns: Column[]
 * @param data: any[]
 * @param onRowClick?: (e: any) => void
 * @param pageable?: boolean
 * @param sortable?: boolean
 * @example
 * <BasicTable
    data={[{
      date: '1/1/1111',
      name: 'Erika',
      ...
    }]}
    columns={[{
      field: 'date',
      title: 'Date',
    }]}
    pageable={true}
    sortable={true}
    onRowClick={(e) => console. log(e.dataItem)}
/>
 */
export const BasicTable: FC<TableProps> = ({
  columns,
  data,
  onRowClick,
  pageable = false,
  sortable = false,
  noResultsComponent,
  filter,
  initPageSize = 10,
  initSort = [{ field: 'ProductName', dir: 'asc' }],
  loading = false,
}) => {
  const [filterText, setFilterText] = useState('');
  const [sort, setSort] = useState(initSort);
  const [page, setPage] = useState<PageState>({
    skip: 0,
    take: initPageSize,
  });
  const [pageSizeValue, setPageSizeValue] = useState(initPageSize);
  const tableFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [...(filter?.filters || [])],
  };

  const handleChangePage = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({ ...event.page, take });
  };
  const handleChangeSort = (e: GridSortChangeEvent) => {
    setSort(e.sort);
  };

  const pageValues = !pageable
    ? false
    : {
        buttonCount: 4,
        pageSizes: [10, 25, 50],
        pageSizeValue: pageSizeValue,
      };

  const updatedTableFilter: CompositeFilterDescriptor | undefined = filter && {
    ...tableFilter,
    filters: [
      ...tableFilter.filters,
      { ...(filter!.filters[0] as FilterDescriptor), value: filterText },
    ],
  };
  // Prepare displayed data
  const filteredData = updatedTableFilter ? filterBy(data, updatedTableFilter) : data;
  const dataPage = pageable ? filteredData.slice(page.skip, page.take + page.skip) : filteredData;
  const orderedData = orderBy(dataPage, sort);

  const columnElems = columns.map((column) => {
    const filterConfig = column.filter ? { filter: column.filter } : {};

    return (
      <GridColumn
        field={column.field}
        title={column.title}
        // @ts-ignore
        cells={column.cells}
        cell={column.cell}
        width={column?.width}
        filter={column.filter}
        // @ts-ignore
        columnMenu={column.columnMenu}
        {...filterConfig}
        key={column.field}
      />
    );
  });

  return (
    <div className={styles.grid}>
      <Grid
        style={{ height: '100%', width: '100%' }}
        data={orderedData}
        sortable={sortable}
        sort={sort}
        onSortChange={handleChangeSort}
        pageable={pageValues}
        onPageChange={handleChangePage}
        skip={page.skip}
        take={page.take}
        total={filteredData.length}
        onRowClick={onRowClick}
        scrollable='scrollable'
      >
        {/* Conditionally render if filter is provided */}
        {filter && (
          <GridToolbar>
            <Input onChange={(e) => setFilterText(e.value)} placeholder="Search filter" />
          </GridToolbar>
        )}
        {loading && (
          <GridNoRecords>
            <Loader size="large" />
          </GridNoRecords>
        )}
        {noResultsComponent && <GridNoRecords>{noResultsComponent}</GridNoRecords>}
        {columnElems}
      </Grid>
    </div>
  );
};
