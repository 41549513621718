import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// components
import ColumnLayout from './ColumnLayout';
import ColumnStats from './ColumnStats';

const axisMarks = [100, 75, 50, 25, 0];
const AxisMarks: FC = () => {
  const axisMarkElems = axisMarks.map((m) => (
    <Grid fontSize={14} fontWeight={700} alignContent="end">
      {m}
    </Grid>
  ));

  return (
    <Grid container direction="column" gap={5.5} flex={1} justifyContent="space-between">
      {axisMarkElems}
    </Grid>
  );
};

/** ###  */
const AxisColumn: FC = () => {
  return (
    <ColumnLayout title="" gap={1}>
      <AxisMarks />

      <ColumnStats
        isAxis
        percent="Percentage"
        accountCount="Account Count"
        payDelq="Payments Delq."
        ar="A/R"
      />
    </ColumnLayout>
  );
};

export default AxisColumn;
