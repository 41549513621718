import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { usePDF } from 'react-to-pdf';
import dayjs from 'dayjs';
// kendo
import { RadioButton } from '@progress/kendo-react-inputs';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { Button } from '@/components/button/Button';
import { Icons } from '@/components/icons';
import { Loader } from '@/components/loader/Loader';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { Spacer } from '@/components/spacer/Spacer';
// components
import { MultipleInputRowRadioButton } from '../../../multipleInputRow/MultipleInputRowRadioButton';
import UserInfo from '../../UserInfo';
// state
import { useComparisonTool } from './useComparisonTool';
import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
// style
import styles from './ComparisonTool.module.scss';
import tabStyles from '../../../salesTabs/SalesTab.module.scss';

export const ComparisonTool: FC = () => {
  const {
    control,
    errors,
    financeDownPaymentState,
    financeMonthlyPayments,
    handleSubmit,
    calculateComparison,
    financeTermState,
    totalFinancePayments,
    refinanceAfterNumberOfMonths,
    dueAtInception,
    leaseMonthlyPayments,
    leaseTermState,
    leasePurchaseOption,
    leaseDoc,
    leaseResidual,
    refinanceApr,
    showGraphic,
    leaseConversionTerm,
    refinancePayment,
    leaseResidualTax,
    downPayment,
    payoffAfterMonthsFinance,
    leasePayoff,
    totalLeaseBuyout,
    totalCostOfOwnershipFinance,
    totalCostOfOwnershipLease,
    totalCostOfOwnershipRefinance,
    leaseSecurityDeposit,
    leaseBuyoutResidual,
    showFullLeaseOptions,
    setShowFullLeaseOptions,
    moneyDueAtInceptionField1Name,
    moneyDueAtInceptionField2Name,
    moneyDueAtInceptionField3Name,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    setIsSaving,
    isDirty,
    isLoading,
    reset,
    isQuoteDataLoading,
    isQuoteInfoLoading,
    is30Days,
    is45Days,
  } = useComparisonTool();

  const { toPDF, targetRef } = usePDF({
    filename: `comparison-${dayjs().format('MM-DD-YYYY_hh-mm-a')}.pdf`,
  });

  const printTargetRef = useDealsQuotesDetailCtx((s) => s.printTargetRef);

  const ComparisonVisual: FC = () => (
    <table className={styles.comparisonVisualContainer} ref={targetRef}>
      <tr>
        <th className={styles.columnHeader}>RETAIL TRADITIONAL SUBPRIME LOAN</th>
        <th className={styles.columnHeader}>
          LEASE WITH REFINANCE OPTION AT {refinanceAfterNumberOfMonths} MONTHS
        </th>
        <th className={styles.columnHeader}>
          LEASE AT {leaseTermState} MONTHS W/ LEASE AND PURCHASE OPTION
        </th>
      </tr>
      <tr className={styles.blueRow}>
        <td>DOWN PAYMENT</td>
        <td>$ DUE AT INCEPTION</td>
        <td>$ DUE AT INCEPTION</td>
      </tr>
      <tr>
        <td
          className={`${styles.greyRow} ${styles.greyRowSmall}`}
          style={{ height: '203px', boxSizing: 'border-box' }}
        >
          <div className={styles.row}>
            <Icons.CarWithKey />
            <div>{formatCurrency(financeDownPaymentState)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <Icons.CarWithKey />
              <div>{formatCurrency(dueAtInception)}</div>
            </div>
            <div className={styles.subText}>{formatCurrency(downPayment)} CAP. COST REDUCTION</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <Icons.CarWithKey />
              <div>{formatCurrency(dueAtInception)}</div>
            </div>
            <div className={styles.subText}>{formatCurrency(downPayment)} CAP. COST REDUCTION</div>
          </div>
        </td>
      </tr>
      <tr className={styles.blueRow}>
        <td>MONTHLY PAYMENTS</td>
        <td>MONTHLY PAYMENTS</td>
        <td>MONTHLY PAYMENTS</td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowLarge}`}>
          <div className={styles.row}>
            <Icons.Calendar />
            <div>{formatCurrency(financeMonthlyPayments)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowLarge}`}>
          <div className={styles.row}>
            <Icons.Calendar />
            <div>{formatCurrency(leaseMonthlyPayments)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowLarge}`}>
          <div className={styles.row}>
            <Icons.Calendar />
            <div>{formatCurrency(leaseMonthlyPayments)}</div>
          </div>
        </td>
      </tr>
      <tr className={styles.blueRow}>
        <td>TOTAL OF PAYMENTS</td>
        <td>TOTAL OF PAYMENTS</td>
        <td>TOTAL OF PAYMENTS</td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(totalFinancePayments)}</div>
            </div>
            <div className={styles.subText}>
              ({formatCurrency(financeMonthlyPayments)} * {financeTermState})
            </div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(leaseMonthlyPayments * refinanceAfterNumberOfMonths)}</div>
            </div>
            <div className={styles.subText}>
              ({formatCurrency(leaseMonthlyPayments)} * {refinanceAfterNumberOfMonths})
            </div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainer}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(leaseMonthlyPayments * leaseTermState)}</div>
            </div>
            <div className={styles.subText}>
              ({formatCurrency(leaseMonthlyPayments)} * {leaseTermState})
            </div>
          </div>
        </td>
      </tr>
      <tr className={styles.blueRow}>
        <td>ESTIMATED PAYOFF AT {refinanceAfterNumberOfMonths} MONTHS</td>
        <td>ESTIMATED PAYOFF AT {refinanceAfterNumberOfMonths} MONTHS</td>
        <td>RESIDUAL</td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainerMultiple}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(payoffAfterMonthsFinance)}</div>
            </div>
            <div className={styles.greyRowLarge} style={{ marginTop: 'auto', padding: 0 }}>
              {formatCurrency(payoffAfterMonthsFinance)}
            </div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainerMultiple}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(leasePayoff)}</div>
            </div>
            <div className={styles.netSecurityDeposit}>**Net Security Deposit</div>
            <Spacer size={20} />
            <div
              className={styles.subText}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              <div>{formatCurrency(leasePurchaseOption)} PURCHASE OPTION</div>
            </div>
            <div className={styles.greyRowLarge} style={{ marginTop: 'auto', padding: 0 }}>
              {formatCurrency(totalLeaseBuyout)}
            </div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.rowContainerMultiple}>
            <div className={styles.row}>
              <Icons.DollarDouble />
              <div>{formatCurrency(leaseResidual - leaseSecurityDeposit)}</div>
            </div>
            <Spacer size={20} />
            <div
              className={styles.subText}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              <div>{formatCurrency(leasePurchaseOption)} PURCHASE OPTION</div>
              <div>{formatCurrency(leaseResidualTax)} TAX</div>
              <div>{formatCurrency(leaseDoc)} DOC FEE</div>
            </div>
            <div className={styles.greyRowLarge} style={{ marginTop: 'auto', padding: 0 }}>
              {formatCurrency(leaseBuyoutResidual)}
            </div>
          </div>
        </td>
      </tr>
      <tr className={styles.blueRow}>
        <td></td>
        <td>
          REFINANCE PAYMENTS AT {(refinanceApr * 100).toFixed(2)}% FOR {leaseConversionTerm} MONTHS
        </td>
        <td></td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>N/A</td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.row}>
            <Icons.DollarDouble />
            <div>{formatCurrency(refinancePayment)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>N/A</td>
      </tr>
      <tr className={styles.blueRow}>
        <td></td>
        <td>TOTAL OF PAYMENTS</td>
        <td></td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>N/A</td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.row}>
            <Icons.DollarDouble />
            <div>{formatCurrency(refinancePayment * leaseConversionTerm)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>N/A</td>
      </tr>
      <tr className={styles.blueRow}>
        <td>TOTAL COST OF OWNERSHIP</td>
        <td>TOTAL COST OF OWNERSHIP</td>
        <td>TOTAL COST OF OWNERSHIP</td>
      </tr>
      <tr>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.row}>
            <Icons.DollarDouble />
            <div>{formatCurrency(totalCostOfOwnershipFinance)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.row}>
            <Icons.DollarDouble />
            <div>{formatCurrency(totalCostOfOwnershipRefinance)}</div>
          </div>
        </td>
        <td className={`${styles.greyRow} ${styles.greyRowSmall}`}>
          <div className={styles.row}>
            <Icons.DollarDouble />
            <div>{formatCurrency(totalCostOfOwnershipLease)}</div>
          </div>
        </td>
      </tr>
      <tr className={styles.totalSavings}>
        <td>
          <div className={styles.savingsTitle}>ESTIMATED TOTAL SAVINGS</div>
          <div className={styles.savingsAmount}>$0</div>
        </td>
        <td>
          <div className={styles.savingsTitle}>ESTIMATED TOTAL SAVINGS</div>
          <div className={styles.savingsAmount}>
            {formatCurrency(totalCostOfOwnershipFinance - totalCostOfOwnershipRefinance)}
          </div>
        </td>
        <td>
          <div className={styles.savingsTitle}>ESTIMATED TOTAL SAVINGS</div>
          <div className={styles.savingsAmount}>
            {' '}
            {formatCurrency(totalCostOfOwnershipFinance - totalCostOfOwnershipLease)}
          </div>
        </td>
      </tr>
    </table>
  );

  return (
    <>
      {isQuoteDataLoading || isQuoteInfoLoading ? (
        <div className={tabStyles.body}>
          <div className={tabStyles.loading}>
            <Loader size="large" />
          </div>
        </div>
      ) : (
        <div className={tabStyles.bodyPrintTarget} ref={printTargetRef}>
          <UserInfo control={control} errors={errors} />

          <Spacer size={20} />
          <div className={styles.body}>
            <form className={styles.mainInfo} onSubmit={handleSubmit(calculateComparison)}>
              <div className={styles.inputContainer}>
                <div className={styles.inputContainerChild}>
                  <h2 className={styles.subHeading}>Loan Options</h2>
                  <Controller
                    name="grossAmountFinanced"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="Total Sales Amount"
                        errors={errors.grossAmountFinanced?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="downPayment"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="Down Payment"
                        errors={errors.downPayment?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="netAmountFinanced"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        readOnly
                        label="Amount Financed"
                        errors={errors.netAmountFinanced?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="apr"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        format="p2"
                        label="APR"
                        errors={errors.apr?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="term"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        label="Term (months)"
                        errors={errors.term?.message}
                        {...field}
                      />
                    )}
                  />
                  <MultipleInputRowRadioButton label="Days to First Payment">
                    <Controller
                      name="daysToFirstPayment"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            defaultChecked={is30Days}
                            label="30 days"
                            value={30}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="daysToFirstPayment"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            defaultChecked={is45Days}
                            label="45 days"
                            value={45}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                  </MultipleInputRowRadioButton>
                  <Controller
                    name="financePayment"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput label="Finance Payment" readOnly rightAlignInput {...field} />
                    )}
                  />
                </div>
                <div className={styles.inputContainerChild}>
                  <h2 className={styles.subHeading}>Lease Conversion Options</h2>
                  <Controller
                    name="afterNumberOfMonths"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        label="After # of Months"
                        errors={errors.afterNumberOfMonths?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="estimatedApr"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        format="p2"
                        label="Estimated APR"
                        errors={errors.estimatedApr?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseConversionTerm"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        label="Term (months)"
                        errors={errors.leaseConversionTerm?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="reFinancePayment"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        readOnly
                        label="Refinance Payment"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputContainerChild}>
                  <h2 className={styles.subHeading}>Lease Options</h2>
                  <Controller
                    name="leaseMmr"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="MMR"
                        errors={errors.leaseMmr?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseSellingPrice"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="Selling Price"
                        errors={errors.leaseSellingPrice?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseDueAtInception"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="Due at Inception"
                        errors={errors.leaseDueAtInception?.message}
                        {...field}
                      />
                    )}
                  />
                
                  {showFullLeaseOptions && (
                    <>
                      <Controller
                        name="moneyDueAtInceptionField1"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField1Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="moneyDueAtInceptionField2"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField2Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="moneyDueAtInceptionField3"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField3Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="securityDeposit"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label="Security Deposit" {...field} />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="leaseDownPayment"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        readOnly
                        label="Cap. Cost Reduction"
                        errors={errors.leaseDownPayment?.message}
                        {...field}
                      />
                    )}
                  />
                  {showFullLeaseOptions && (
                    <>
                      <Controller
                        name="customField1"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField1Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField2"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField2Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField3"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField3Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField4"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField4Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField5"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField5Name} {...field} />
                        )}
                      />
                      <Controller
                        name="serviceContract"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label="Service Contract" {...field} />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="leaseAddedCapCost"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        readOnly
                        label="Added Cap. Cost"
                        errors={errors.leaseAddedCapCost?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseResidualRate"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        format="p2"
                        label="Residual Rate"
                        errors={errors.leaseResidualRate?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseResidual"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        required
                        label="Residual"
                        readOnly
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseApr"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        format="p2"
                        label="APR"
                        errors={errors.leaseApr?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leaseTaxRate"
                    control={control}
                    render={({ field }) => (
                      <NumberInput rightAlignInput format="p4" label="Tax Rate" {...field} />
                    )}
                  />
                  <Controller
                    name="leaseTerm"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        required
                        label="Term"
                        errors={errors.leaseTerm?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="leasePayment"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput rightAlignInput readOnly label="Lease Payment" {...field} />
                    )}
                  />
                  <a
                    className={tabStyles.anchorText}
                    onClick={() => setShowFullLeaseOptions((prev) => !prev)}
                  >
                    {showFullLeaseOptions ? 'Hide' : 'Show'} Full Lease Quote Options
                  </a>
                </div>
              </div>
              <div className={styles.calculateButtonContainer}>
                <div className={styles.bar}></div>
                <Icons.Document
                  className={showGraphic ? styles.print : styles.printDisabled}
                  onClick={() => toPDF()}
                />
                <Button label="Calculate" fillMode="outline" onClick={() => setIsSaving(false)} />
              </div>
              <div className={tabStyles.bottomRow}>
                <Button
                  label="Submit"
                  secondary={!isDirty}
                  disabled={!isDirty}
                  type="submit"
                  fullWidth={false}
                  loading={isLoading}
                  onClick={() => setIsSaving(true)}
                />
                <Spacer horizontal={true} size={10} />
                <Button
                  label="Cancel"
                  secondary={true}
                  disabled={!isDirty}
                  onClick={() => reset()}
                  fullWidth={false}
                />
              </div>
              {showGraphic && (
                <>
                  <ComparisonVisual />
                  <div className={styles.disclaimer}>
                    *The lease to purchase example is for illustration purposes only. The dealer
                    cannot guarantee any future rates or terms, which would affect the numbers
                    presented.
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};
