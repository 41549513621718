export class WholesalesDb {
  public totalCost: number;
  public profit: number;

  constructor(
    public appRecId: number, // int
    public bNames: string,
    public buyerRecId: number, // int
    public cobuyRecId: number, // int
    public code3: string,
    public companyName: string,
    public compAppId: number, // int
    public compId: number, // int
    public invDays: number, // int
    public isCobuyer: string,
    public lAppStatus: string,
    public locId: number, // int
    public manLocation: number, // int
    public orgId: number, // int
    public purPrice: number, // decimal
    public region: string,
    /** Rate of return */
    public ror: number, // decimal
    public saleDate: Date,
    public saleStatus: string,
    public saleType: string,
    public shortName: string,
    public soldPrice: number, // decimal
    public stockNum: string,
    public totExp: number, // decimal
    public vehRecId: number, // int
    public vehYmm: string,
    public vin: string
  ) {
    this.totalCost = purPrice + totExp;
    this.profit = soldPrice - this.totalCost;
  }

  static new(newWholesalesDb: WholesalesDb): WholesalesDb {
    return new WholesalesDb(
      newWholesalesDb.appRecId,
      newWholesalesDb.bNames,
      newWholesalesDb.buyerRecId,
      newWholesalesDb.cobuyRecId,
      newWholesalesDb.code3,
      newWholesalesDb.companyName,
      newWholesalesDb.compAppId,
      newWholesalesDb.compId,
      newWholesalesDb.invDays,
      newWholesalesDb.isCobuyer,
      newWholesalesDb.lAppStatus,
      newWholesalesDb.locId,
      newWholesalesDb.manLocation,
      newWholesalesDb.orgId,
      newWholesalesDb.purPrice,
      newWholesalesDb.region,
      newWholesalesDb.ror,
      newWholesalesDb.saleDate,
      newWholesalesDb.saleStatus,
      newWholesalesDb.saleType,
      newWholesalesDb.shortName,
      newWholesalesDb.soldPrice,
      newWholesalesDb.stockNum,
      newWholesalesDb.totExp,
      newWholesalesDb.vehRecId,
      newWholesalesDb.vehYmm,
      newWholesalesDb.vin
    );
  }
}

export type IWholesalesDb = WholesalesDb;
