const filterFields = ['recId', 'quoteDate', 'customerName', 'customerPhone'] as const;

export type SalesQuotesFilterKey = (typeof filterFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const salesQuotesFilterFields: SalesQuotesFilterKey[] = [...filterFields];

const sortFields = ['recId', 'quoteDate'] as const;

export type SalesQuotesSortKey = (typeof sortFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const salesQuotesSortFields: SalesQuotesSortKey[] = [...sortFields];
