import { FC, ReactNode, isValidElement } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
// components
import DetailViewHeader from "./DetailViewHeader";
import DetailViewBody from "./DetailViewBody";
import BackLink from "../../buttons/BackLink";
import { borderRadiusHeader } from "./style";

/** ###
 *
 * ## Usage:
 * ### Example #1
 * ```tsx
 * const AccountDetailExample1: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout>
 *       <DetailViewHeader>...</DetailViewHeader>
 *       <DetailViewBody isLoading={isLoading}>...</DetailViewBody>
 *     </DetailViewLayout>
 *   );
 * };
 * ```
 * ### Example #2
 * ```tsx
 * const AccountDetailExample2: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout headerComponent={<DetailViewHeader>...</DetailViewHeader>}>
 *       <DetailViewBody isLoading={isLoading}>...</DetailViewBody>
 *     </DetailViewLayout>
 *   );
 * };
 * ```
 * ### Example #3
 * ```tsx
 * const AccountDetailExample3: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout
 *       headerComponent={<DetailViewHeader>...</DetailViewHeader>}
 *       bodyComponent={<DetailViewBody isLoading={isLoading}>...</DetailViewBody>}
 *     />
 *   );
 * };
 * ```
 */
const DetailViewLayout: FC<
  Grid2Props & {
    headerComponent?: ReturnType<typeof DetailViewHeader>;
    bodyComponent?: ReturnType<typeof DetailViewBody>;
    backLink?: boolean | string | ReactNode;
  }
> = ({ headerComponent, bodyComponent, backLink = true, children, sx, ...gridProps }) => {
  if (!children && !headerComponent && !bodyComponent)
    throw new Error(
      "Must provide: 1. `children` element or 2. Both `headerComponent` and `bodyComponent` properties."
    );

  const appliedElems: ReactNode = children ? children : [headerComponent, bodyComponent];
  // @todo If nullish, render an invisible, disabled button to maintain height
  const appliedBackLink =
    backLink === true ? (
      <BackLink />
    ) : typeof backLink === "string" ? (
      <BackLink to={backLink} />
    ) : isValidElement(backLink) ? (
      backLink
    ) : (
      <></>
    );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ flex: 1, px: "45px", pb: "45px", ...sx }}
      {...gridProps}
    >
      {appliedBackLink}

      <Grid
        container
        direction="column"
        component={Paper}
        sx={{
          flex: 1,
          color: "#111827",
          background: "#fff",
          borderRadius: borderRadiusHeader,
        }}
      >
        {appliedElems}
      </Grid>
    </Grid>
  );
};

export default DetailViewLayout;
