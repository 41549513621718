import { FC } from "react";
// mui
import Section from "@/mui/components/form/Section";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import Subsection from "@/mui/components/form/Section/Subsection";
// components
import PreviousEvals from "./PreviousEvals";
import ButtonGroup from "./ButtonGroup";
import TextFieldDisplay from "../layout/TextFieldDisplay";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";

/** ###  */
const EvalResultsSection: FC<{ isInModal: boolean }> = ({ isInModal }) => {
  const currentAppEval = useAiUnderwritingCtx((s) => (isInModal ? s.selectedAppEval : s.latestAppEval));

  return (
    <Section isExpandable header="Results">
      <ButtonGroup isLatestEval={!isInModal} isDeclinedStatus= {currentAppEval?.finalDecision === 'Decline'}/>

      <SectionBody
        leftColElems={
          <Subsection label="Final Decision">
            <TextFieldDisplay label="App Id" value={currentAppEval?.applicationId} />
            <TextFieldDisplay label="Decision" value={currentAppEval?.finalDecision} />
            <TextFieldDisplay label="Score" value={currentAppEval?.finalScore} />
            <TextFieldDisplay label="Grade" value={currentAppEval?.finalGrade} />
          </Subsection>
        }
        rightColElems={[
          <Subsection label="Buyer Results" isSubsectionSplit key="1">
            <TextFieldDisplay label="Score" value={currentAppEval?.applicantScore} isFlex1 />
            <TextFieldDisplay label="Grade" value={currentAppEval?.applicantGrade} isFlex1 />
          </Subsection>,
          <Subsection label="Co-buyer Results" isSubsectionSplit key="2">
            <TextFieldDisplay label="Score" value={currentAppEval?.coApplicantScore} isFlex1 />
            <TextFieldDisplay label="Grade" value={currentAppEval?.coApplicantGrade} isFlex1 />
          </Subsection>,
        ]}
      />

      {!isInModal && <PreviousEvals />}
    </Section>
  );
};

export default EvalResultsSection;
