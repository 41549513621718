import { FC, useEffect, useState } from 'react';
import { useAccountSelector } from '../../../../accountSlice';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import CreditEducation from '@/components/creditEducation/CreditEducation';
import { BuyerType } from '@/enums';

const CreditEducationDetail: FC = () => {
  const { accountInformation } = useAccountSelector((s) => s);
  const [buyerType, setBuyerType] = useState<BuyerType>(BuyerType.Buyer);
  const [isCobuyer, setIsCobuyer] = useState(false);

  const hasCoBuyer = !!(
    accountInformation?.coBuyer?.appBuyerRecId && accountInformation?.coBuyer?.firstName
  );

  const navBarItems = hasCoBuyer
    ? [
        {
          title: BuyerType.Buyer,
          isActive: buyerType === BuyerType.Buyer,
          onClick: () => setBuyerType(BuyerType.Buyer),
        },
        {
          title: BuyerType.CoBuyer,
          isActive: buyerType === BuyerType.CoBuyer,
          onClick: () => setBuyerType(BuyerType.CoBuyer),
        },
      ]
    : [];

  useEffect(() => {
    var activeItem = navBarItems.find(item => item.isActive);

    if(activeItem?.title == BuyerType.CoBuyer) setIsCobuyer(true);
    else setIsCobuyer(false);
  }, [buyerType]);

  return (
    <AccountsMainPanel
      navBarTitle="Credit Education"
      navBarItems={navBarItems}
    >
      <CreditEducation appRecId={accountInformation?.appRecId} isCobuyer={isCobuyer}/>
    </AccountsMainPanel>
  );
};

export default CreditEducationDetail;
