import { FC } from 'react';
import { StyleSheet, Text, TextProps, View, ViewProps } from '@react-pdf/renderer';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { PdfColumnInfo } from './interfaces';

const styles = StyleSheet.create({
  tableCellContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    border: '1px solid #00000055',
    borderRight: '1px solid #ababab',
    borderBottom: '1px solid #000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  cellText: {
    paddingVertical: '8px',
    fontSize: '10px',
    textAlign: 'center',
  },
});

/** ###  */
const PdfTableCell: FC<
  ViewProps & {
    value: string;
    columnInfo?: PdfColumnInfo;
    columnCt: number;
    textProps?: TextProps;
  }
> = ({ value, columnInfo, columnCt, textProps, style: viewStyle, ...viewProps }) => {
  let displayValue = value;
  const isNum = !isNaN(Number(value));

  if (columnInfo?.columnType === 'currency')
    displayValue = `${isNum ? formatCurrency(Number(value)) : '$ -'}`;
  else if (columnInfo?.columnType === 'percent') displayValue = `${isNum ? value : '- '}%`;
  return (
    <View
      {...viewProps}
      // @note Apply width style last to keep consistent table layout
      style={{ ...styles.tableCellContainer, ...viewStyle, width: `${100 / columnCt}%` }}
    >
      <Text style={{ ...styles.cellText, ...textProps?.style }}>{displayValue}</Text>
    </View>
  );
};

export default PdfTableCell;
