import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { TextInput } from "@/components/inputs/text/TextInput";
import { Loader, NumberInput } from "@/components";
import { Button } from "@/components/button/Button";
import { toast } from "react-toastify";

import { useCompanyMgmtCtx } from "./CompanyMgmtProvider";
import { companyService } from "@/services/companyService";
import { ICompanyDefaults } from "@/interfaces/CompanyDefaults";

interface FormValues {
  // General Defaults
  lotType: string;
  intType: string;
  stockOpt: string;
  nextSkNum: string;
  useAcctNum: boolean;
  acctNum: string;
  maxPayPer: number;
  defaultGapPaidTo: string;

  // Default Terms
  def_APR: number;
  def_SP: number;
  def_PMT: number;
  defDown: number;
  defPDown: number;
  defSchedule: string;
  def_Term: number;

  // Late Defaults
  lateChgUsed: boolean;
  lateChgPerPmt: boolean;
  lateChgGrace: boolean;
  lateChgRate: number;
  lateChgMin: number;
  lateChgMax: number;
  lateGraceDays: number;

  // CP Defaults
  cP_Weekly: number;
  cP_BiWeekly: number;
  cP_SemiMonthly: number;
  cP_Monthly: number;

  // Lease Defaults
  leaseDispositionFee: number;
  leaseMilesAllowed: number;
  lease_MonthMiles: number;
  leaseMilesPer: number;
  lease_SecurityDeposit: number;

  // Default Fees
  nsfFee: number;
  cpI_Deductable: number;
  repoTitleFees: number;
  ins_Deductable: number;

  // GLBA Defaults
  glbA_OwnerName: string;
  glbA_ShortName: string;

  // Service Defaults
  contractInfo: string;
  defaultSCPaidTo: string;

  // Ecom Alert Defaults
  maxTotExp: number;
  maxExp: number;

  // Other Defaults
  mainMsg: string;

  // Taxes and Doc Stamps
  useFlDoc: boolean;
  useTXTax: boolean;
  useTXInvTax: boolean;
  invTaxRate: number | null;
}

const CompanyDefaults: FC = () => {
  const [isLoading,setIsLoading] = useState(false);
  const { companyDetails } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
  }));

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      lotType: "",
      intType: "",
      stockOpt: "",
      nextSkNum: "",
      useAcctNum: false,
      acctNum: "",
      maxPayPer: 0,
      defaultGapPaidTo: "",

      def_APR: 0,
      def_SP: 0,
      def_PMT: 0,
      defDown: 0,
      defPDown: 0,
      defSchedule: "",
      def_Term: 0,

      lateChgUsed: false,
      lateChgPerPmt: false,
      lateChgGrace: false,
      lateChgRate: 0,
      lateChgMin: 0,
      lateChgMax: 0,
      lateGraceDays: 0,

      cP_Weekly: 0,
      cP_BiWeekly: 0,
      cP_SemiMonthly: 0,
      cP_Monthly: 0,

      leaseDispositionFee: 0,
      leaseMilesAllowed: 0,
      lease_MonthMiles: 0,
      leaseMilesPer: 0,
      lease_SecurityDeposit: 0,

      nsfFee: 0,
      cpI_Deductable: 0,
      repoTitleFees: 0,
      ins_Deductable: 0,

      glbA_OwnerName: "",
      glbA_ShortName: "",

      contractInfo: "",
      defaultSCPaidTo: "",

      maxTotExp: 0,
      maxExp: 0,

      mainMsg: "",

      useFlDoc: false,
      useTXTax: false,
      useTXInvTax: false,
      invTaxRate: null,
    },
  });

  useEffect(() => {
    const fetchDefaults = async () => {
      if (!companyDetails?.recId) return;
      try {
        setIsLoading(true);
        const data = await companyService.getCompanyDefaults(companyDetails.recId);

        const mappedData: FormValues = {
          lotType: data.lotType || "",
          intType: data.intType || "",
          stockOpt: data.stockOpt || "",
          nextSkNum: data.nextSkNum || "",
          useAcctNum: data.useAcctNum ?? false,
          acctNum: data.acctNum || "",
          maxPayPer: data.maxPayPer ?? 0,
          defaultGapPaidTo: data.defaultGapPaidTo || "",

          def_APR: data.def_APR ?? 0,
          def_SP: data.def_SP ?? 0,
          def_PMT: data.def_PMT ?? 0,
          defDown: data.defDown ?? 0,
          defPDown: data.defPDown ?? 0,
          defSchedule: data.defSchedule || "",
          def_Term: data.def_Term ?? 0,

          lateChgUsed: data.lateChgUsed ?? false,
          lateChgPerPmt: data.lateChgPerPmt ?? false,
          lateChgGrace: data.lateChgGrace ?? false,
          lateChgRate: data.lateChgRate ?? 0,
          lateChgMin: data.lateChgMin ?? 0,
          lateChgMax: data.lateChgMax ?? 0,
          lateGraceDays: data.lateGraceDays ?? 0,

          cP_Weekly: data.cP_Weekly ?? 0,
          cP_BiWeekly: data.cP_BiWeekly ?? 0,
          cP_SemiMonthly: data.cP_SemiMonthly ?? 0,
          cP_Monthly: data.cP_Monthly ?? 0,

          leaseDispositionFee: data.leaseDispositionFee ?? 0,
          leaseMilesAllowed: data.leaseMilesAllowed ?? 0,
          lease_MonthMiles: data.lease_MonthMiles ?? 0,
          leaseMilesPer: data.leaseMilesPer ?? 0,
          lease_SecurityDeposit: data.lease_SecurityDeposit ?? 0,

          nsfFee: data.nsfFee ?? 0,
          cpI_Deductable: data.cpI_Deductable ?? 0,
          repoTitleFees: data.repoTitleFees ?? 0,
          ins_Deductable: data.ins_Deductable ?? 0,

          glbA_OwnerName: data.glbA_OwnerName || "",
          glbA_ShortName: data.glbA_ShortName || "",

          contractInfo: "",
          defaultSCPaidTo: data.defaultSCPaidTo || "",

          maxTotExp: data.maxTotExp ?? 0,
          maxExp: data.maxExp ?? 0,

          mainMsg: data.mainMsg || "",

          useFlDoc: data.useFlDoc ?? false,
          useTXTax: data.useTXTax ?? false,
          useTXInvTax: data.useTXInvTax ?? false,
          invTaxRate: data.invTaxRate ?? 0,
        };

        reset(mappedData);
      } catch (err) {
        console.error("Failed to fetch company defaults:", err);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchDefaults();
  }, [companyDetails, reset]);

  const onSubmit = async (formData: FormValues) => {
    if (!companyDetails?.recId) {
      toast.error("No company selected.");
      return;
    }

    const updateModel: ICompanyDefaults = {
      recId: companyDetails.recId,
      lotType: formData.lotType,
      intType: formData.intType,
      stockOpt: formData.stockOpt,
      nextSkNum: formData.nextSkNum,
      useAcctNum: formData.useAcctNum,
      acctNum: formData.acctNum,
      maxPayPer: formData.maxPayPer,
      defaultGapPaidTo: formData.defaultGapPaidTo,

      def_APR: formData.def_APR,
      def_SP: formData.def_SP,
      def_PMT: formData.def_PMT,
      defDown: formData.defDown,
      defPDown: formData.defPDown,
      defSchedule: formData.defSchedule,
      def_Term: formData.def_Term,

      lateChgUsed: formData.lateChgUsed,
      lateChgPerPmt: formData.lateChgPerPmt,
      lateChgGrace: formData.lateChgGrace,
      lateChgRate: formData.lateChgRate,
      lateChgMin: formData.lateChgMin,
      lateChgMax: formData.lateChgMax,
      lateGraceDays: formData.lateGraceDays,

      cP_Weekly: formData.cP_Weekly,
      cP_BiWeekly: formData.cP_BiWeekly,
      cP_SemiMonthly: formData.cP_SemiMonthly,
      cP_Monthly: formData.cP_Monthly,

      leaseDispositionFee: formData.leaseDispositionFee,
      leaseMilesAllowed: formData.leaseMilesAllowed,
      lease_MonthMiles: formData.lease_MonthMiles,
      leaseMilesPer: formData.leaseMilesPer,
      lease_SecurityDeposit: formData.lease_SecurityDeposit,

      nsfFee: formData.nsfFee,
      cpI_Deductable: formData.cpI_Deductable,
      repoTitleFees: formData.repoTitleFees,
      ins_Deductable: formData.ins_Deductable,

      glbA_OwnerName: formData.glbA_OwnerName,
      glbA_ShortName: formData.glbA_ShortName,

      defaultSCPaidTo: formData.defaultSCPaidTo,

      maxTotExp: formData.maxTotExp,
      maxExp: formData.maxExp,

      mainMsg: formData.mainMsg,

      useFlDoc: formData.useFlDoc,
      useTXTax: formData.useTXTax,
      useTXInvTax: formData.useTXInvTax,
      invTaxRate: formData.invTaxRate ?? 0
    };

    try {
      await companyService.updateCompanyDefaults(updateModel);
      toast.success("Company defaults updated successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Error updating company defaults.");
    }
  };
  if (isLoading) {    
    return (    
        <Loader size="large"/>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        
        {/* General Defaults Section */}
        <Grid item>
          <SectionHeader title="General Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {/* Row 1 */}
            <Grid item xs={6}>
              <Controller
                name="lotType"
                control={control}
                render={({ field }) => <TextInput label="Type of Dealership" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="intType"
                control={control}
                render={({ field }) => <TextInput label="Interest Type" {...field} />}
              />
            </Grid>

            {/* Row 2 */}
            <Grid item xs={6}>
              <Controller
                name="stockOpt"
                control={control}
                render={({ field }) => <TextInput label="Stock Options" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nextSkNum"
                control={control}
                render={({ field }) => <TextInput label="Next Stock Number" {...field} />}
              />
            </Grid>

            {/* Row 3 */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    name="useAcctNum"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Use Account Number"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="acctNum"
                control={control}
                render={({ field }) => <TextInput label="Account Number" {...field} />}
              />
              
            </Grid>

            {/* Row 4 */}
            <Grid item xs={6}>
              <Controller
                name="maxPayPer"
                control={control}
                render={({ field }) => <NumberInput label="Max Payment %" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="defaultGapPaidTo"
                control={control}
                render={({ field }) => <TextInput label="GAP Paid To" {...field} />}
              />
            </Grid>

            {/* Row 5 - Taxes */}
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Controller
                    name="useFlDoc"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Use FL Doc Stamps"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Controller
                    name="useTXTax"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Use TX Tax"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Controller
                    name="useTXInvTax"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Use TX Inv. Tax"
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="invTaxRate"
                control={control}
                render={({ field }) => <NumberInput label="TX Inv. Tax Rate" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        
        {/* Default Terms Section */}
        <Grid item>
          <SectionHeader title="Default Terms" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="def_APR"
                control={control}
                render={({ field }) => <NumberInput label="APR" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="def_SP"
                control={control}
                render={({ field }) => <NumberInput label="Sales Price" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="def_PMT"
                control={control}
                render={({ field }) => <NumberInput label="Payment Amount" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="defDown"
                control={control}
                render={({ field }) => <NumberInput label="Down Payment (Fixed)" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="defPDown"
                control={control}
                render={({ field }) => <NumberInput label="Down Payment (%)" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="defSchedule"
                control={control}
                render={({ field }) => <TextInput label="Schedule (e.g. Weekly)" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="def_Term"
                control={control}
                render={({ field }) => <NumberInput label="Term (Months)" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Late Defaults Section */}
        <Grid item>
          <SectionHeader title="Late Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Controller
                    name="lateChgUsed"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Charge Used"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Controller
                    name="lateChgPerPmt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Charge per Payment"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Controller
                    name="lateChgGrace"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                  />
                }
                label="Charge Grace"
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lateChgRate"
                control={control}
                render={({ field }) => <NumberInput label="Charge Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lateChgMin"
                control={control}
                render={({ field }) => <NumberInput label="Charge Min" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lateChgMax"
                control={control}
                render={({ field }) => <NumberInput label="Charge Max" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lateGraceDays"
                control={control}
                render={({ field }) => <NumberInput label="Grace Days" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* CP Defaults Section */}
        <Grid item>
          <SectionHeader title="CP Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="cP_Weekly"
                control={control}
                render={({ field }) => <NumberInput label="Weekly Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="cP_BiWeekly"
                control={control}
                render={({ field }) => <NumberInput label="Bi-Weekly Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="cP_SemiMonthly"
                control={control}
                render={({ field }) => <NumberInput label="Semi-Monthly Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="cP_Monthly"
                control={control}
                render={({ field }) => <NumberInput label="Monthly Rate" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Lease Defaults Section */}
        <Grid item>
          <SectionHeader title="Lease Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="leaseDispositionFee"
                control={control}
                render={({ field }) => <NumberInput label="Disposition Fee" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="leaseMilesAllowed"
                control={control}
                render={({ field }) => <NumberInput label="Miles Allowed" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lease_MonthMiles"
                control={control}
                render={({ field }) => <NumberInput label="Month Miles" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="leaseMilesPer"
                control={control}
                render={({ field }) => <NumberInput label="Excess Miles Cost" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="lease_SecurityDeposit"
                control={control}
                render={({ field }) => <NumberInput label="Security Deposit / Early Term Fee" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Default Fees Section */}
        <Grid item>
          <SectionHeader title="Default Fees" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="nsfFee"
                control={control}
                render={({ field }) => <NumberInput label="NSF Fee" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="cpI_Deductable"
                control={control}
                render={({ field }) => <NumberInput label="CPI Deductible" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="repoTitleFees"
                control={control}
                render={({ field }) => <NumberInput label="Repo Title Fees" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="ins_Deductable"
                control={control}
                render={({ field }) => <NumberInput label="Insurance Deductible" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* GLBA Defaults Section */}
        <Grid item>
          <SectionHeader title="GLBA Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="glbA_OwnerName"
                control={control}
                render={({ field }) => <TextInput label="Owner's Name" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="glbA_ShortName"
                control={control}
                render={({ field }) => <TextInput label="Company Short Name" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Service Defaults */}
        <Grid item>
          <SectionHeader title="Service Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="contractInfo"
                control={control}
                render={({ field }) => <TextInput label="Contract Info" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="defaultSCPaidTo"
                control={control}
                render={({ field }) => <TextInput label="Contract Paid To" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Ecom Alert Defaults */}
        <Grid item>
          <SectionHeader title="Ecom Alert Defaults" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="maxTotExp"
                control={control}
                render={({ field }) => <NumberInput label="Max Exp. Amount" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="maxExp"
                control={control}
                render={({ field }) => <NumberInput label="Total Exp. Amount" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Other Defaults */}
        <Grid item>
          <SectionHeader title="Other Defaults" />
        </Grid>
        <Grid item>
          <Controller
            name="mainMsg"
            control={control}
            render={({ field }) => <TextInput label="Note for All Payment Receipts" {...field} />}
          />
        </Grid>

        {/* Buttons */}
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 4 }}>
          <Grid item>
            <Button type="submit" label="Save" buttonStyle={{ width: "140px" }} />
          </Grid>
          <Grid item>
            <Button label="Cancel" color="secondary" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyDefaults;
