import { CSSProperties, FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import {   Button, Checkbox, TextInput, NumberInput, } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { useLhPhDetailCtx } from './LhPhDetailProvider';
import dayjs from 'dayjs';
import { LhPhSectionHeader } from './LhPhSectionHeader';
import { getFundDates, getLHPHPayment } from './utils';

const subListStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
};

const subListColumn: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  flex: '1',
  padding: '20px',
};

const headingSubColumn: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  flex: '1',
};

const checkboxLabelStyle: CSSProperties = { flex: '1' };

const checkDivStyle: CSSProperties = { flex: '1.25' };

const LhPhFundingForm: FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useLhPhDetailCtx((s) => s.lhPhForm);
  const lhPhDetail = useLhPhDetailCtx((s) => s.lhPhDetail);
  const fundAllowance = useLhPhDetailCtx((s) => s.fundAllowance);
  const getFundAllowance = useLhPhDetailCtx((s) => s.getFundAllowance);
  const saveLhPhData = useLhPhDetailCtx((s) => s.saveLhPhData);
  const {
    fundExpAllow,
    fundAmt,
    orgid,
    lhphFundDay,
    lhphCutOffDay,
    lhphIntRate,
    fundRepoSwap,
    purprice,
    lhphFundRate,
    totexp,
  } = watch();

  const isFunded = lhPhDetail?.fundDone === 'FUNDING';

  useEffect(() => {
    const dateUpdates = getFundDates(orgid ?? 0, lhphFundDay ?? 0, lhphCutOffDay ?? 0);

    setValue('fundDate', dateUpdates.FundDate.format('YYYY-MM-DD'));
    setValue('fundDueDate', dateUpdates.FundDueDate.format('YYYY-MM-DD'));

    const pmtAmt =
      getLHPHPayment(
        fundAmt ?? 0,
        Math.min(lhPhDetail?.lhphMaxTerm ?? 0, lhPhDetail?.term ?? 0) || 0,
        dateUpdates.FundDate,
        dateUpdates.FundDueDate,
        lhphIntRate ?? 0
      ) ?? 0;

    setValue('fundPmt', Math.round(pmtAmt * 100) / 100);
    if (fundAllowance < fundAmt!) {
      setError('fundAmt', { message: 'Cannot be greater than Fund Allowance' });
    } else if (fundAllowance >= fundAmt!) {
      clearErrors('fundAmt');
    }
    if (lhPhDetail?.totexp! < fundExpAllow!) {
      setValue('fundExpAllow', lhPhDetail?.totexp!);
    }
  }, [fundExpAllow, fundAmt]);

  return (
    <Grid
      container
      direction="column"
      flex={1}
      sx={{
        padding: '40px',
        background: '#EFF0F4',
        borderRadius: '0px 0px 12px 12px',
        overflowX: 'auto',
        overflowY: 'scroll',
      }}
      component="form"
      onSubmit={handleSubmit(saveLhPhData)}
    >
      <Grid container direction="column" flex={1}>
        <LhPhSectionHeader title="Vehicle Cost" showBar />
        <Grid sx={subListStyles} container direction="row" flex={1}>
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <CurrencyInput readOnly label="Vehicle Purchase Price" value={lhPhDetail?.purprice} />
            <CurrencyInput readOnly label="Total Expense" value={lhPhDetail?.totexp} />
            <Controller
              name="fundExpAllow"
              control={control}
              disabled={isFunded}
              rules={{
                max: {
                  value: Number(fundExpAllow),
                  message: 'Cannot be greater than Total Expenses',
                },
              }}
              render={({ field: { onChange, ...field } }) => (
                <CurrencyInput
                  {...field}
                  required
                  label="Funded Recon (exp)"
                  onChange={(e) => {
                    const newFundAllowance = getFundAllowance(
                      purprice || 0,
                      lhphFundRate || 0,
                      e.value || 0,
                      totexp || 0
                    );
                    onChange(e.value);
                    setValue('fundAmt', newFundAllowance);
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <CurrencyInput readOnly label="Fund Allowance" value={fundAllowance} />
            <NumberInput
              readOnly
              isPercentage
              label="LHPH Interest Rate"
              value={lhPhDetail?.lhphIntRate ? lhPhDetail?.lhphIntRate / 100 : 0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" flex={1}>
        <LhPhSectionHeader title="Funding Request" showBar />
        <Grid sx={subListStyles} container direction="row">
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <Controller
              name="fundRequested"
              control={control}
              disabled={isFunded}
              render={({ field: { onChange, ...field } }) => (
                <Checkbox
                  {...field}
                  checkboxInputWraperStyle={checkDivStyle}
                  labelStyles={checkboxLabelStyle}
                  labelPlacement={'before'}
                  label="Request Funding"
                  onChange={(e) => {
                    onChange(e.value);
                    setValue('fundRepoSwap', !e.value);
                  }}
                  // onChange={(e) => setValue("fundPO", !e.target.value)}
                  // onChange={(e) => field.onChange(transform.output(e))}
                />
              )}
            />
            <TextInput
              readOnly
              label="Funding Status"
              value={!lhPhDetail?.fundDone ? '' : lhPhDetail?.fundDone.toString()}
            />
            <Controller
              name="fundRepoSwap"
              control={control}
              disabled={isFunded}
              render={({ field: { onChange, ...field } }) => (
                <Checkbox
                  {...field}
                  labelStyles={checkboxLabelStyle}
                  checkboxInputWraperStyle={checkDivStyle}
                  labelPlacement={'before'}
                  label="Repo Swap"
                  onChange={(e) => {
                    onChange(e.value);
                    setValue('fundRequested', !e.value);
                  }}
                />
              )}
            />
            <Controller
              name="fundDate"
              control={control}
              rules={{
                required: 'Date is required',
              }}
              render={({ field: { value, ...field } }) => {
                return (
                  <DateInput
                    {...field}
                    value={dayjs(value).format('YYYY-MM-DD')}
                    disabled={isFunded}
                    label="Fund Date"
                    readOnly
                    required
                    errors={errors.fundDate?.message}
                  />
                );
              }}
            />
          </Grid>
          {!fundRepoSwap ? (
            <Grid
              sx={subListColumn}
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <NumberInput
                readOnly
                label="Funding Term"
                value={Math.min(lhPhDetail?.lhphMaxTerm ?? 0, lhPhDetail?.term ?? 0) || 0}
              />
              <Controller
                name="fundPmt"
                control={control}
                disabled={isFunded}
                render={({ field }) => (
                  <CurrencyInput {...field} placeholder="0" readOnly label="Funding Payment" />
                )}
              />
              <Controller
                name="fundDueDate"
                control={control}
                rules={{
                  required: 'Date is required',
                }}
                render={({ field: { value, ...field } }) => {
                  return (
                    <DateInput
                      {...field}
                      value={dayjs(value).format('YYYY-MM-DD')}
                      label="Fund Due Date"
                      required
                      readOnly
                      errors={errors.fundDueDate?.message}
                    />
                  );
                }}
              />
              <Controller
                name="fundAmt"
                control={control}
                disabled={isFunded}
                rules={{
                  required: 'Funding Amount is required',
                  max: { value: fundAllowance, message: 'Cannot be greater than Fund Allowance' },
                }}
                render={({ field, ...other }) => {
                  return (
                    <CurrencyInput
                      label="Funding Amount"
                      required
                      {...field}
                      errors={errors.fundAmt?.message}
                    />
                  );
                }}
              />
            </Grid>
          ) : (
            <Grid sx={subListColumn} container direction="column"></Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" flex={1}>
        <Grid sx={subListStyles} container direction="row">
          <Grid sx={headingSubColumn} container direction="column" flex={1}>
            <LhPhSectionHeader title="Paid Off Information" showBar />
            <Grid
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <Controller
                name="fundPO"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checkboxInputWraperStyle={checkDivStyle}
                    labelStyles={checkboxLabelStyle}
                    labelPlacement={'before'}
                    label="Pay-Off Funded"
                  />
                )}
              />
              <Controller
                name="fundPOAmt"
                control={control}
                render={({ field }) => <CurrencyInput label="Funded Pay Off Amount" {...field} />}
              />
              <Controller
                name="fundPODate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder={field.value}
                    label="Date Pay-Off Funded"
                    errors={errors.fundPODate?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid sx={headingSubColumn} container direction="column" flex={1}>
            <LhPhSectionHeader title="Funded Infromation" showBar />
            <Grid
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <Controller
                name="funded"
                control={control}
                disabled
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checkboxInputWraperStyle={checkDivStyle}
                    labelStyles={checkboxLabelStyle}
                    labelPlacement={'before'}
                    label="Funded"
                  />
                )}
              />
              <Controller
                name="fundedon"
                control={control}
                disabled
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder={field.value}
                    label="Date Funded"
                    errors={errors.fundedon?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Button
            label="Submit"
            type="submit"
            buttonStyle={{ width: '134px', margin: '0px 10px' }}
          />
          <Button
            label="Cancel"
            type="button"
            buttonStyle={{ width: '134px', margin: '0px 10px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LhPhFundingForm;
