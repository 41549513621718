import { Checkbox, DropdownInput, TextInput } from "@/components";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";
import { usaStateCodes } from "@/general/regions";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
import { getAddressFromGoogleMaps } from "@/utils/helpers/general";
import { RepoCompany } from "@/interfaces/RepoCompany";

const buildFullAddress = (
  repoCompany: Pick<RepoCompany, 'address' | 'city' | 'state' | 'zip'> | undefined
): string | null => {
  if (!repoCompany?.address) return null;

  return `${repoCompany?.address}, ${repoCompany?.city}, ${repoCompany?.state} ${repoCompany?.zip}`;
};

const RightContainer: FC = () => {
  const { control, setValue, watch } = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);
  const address = watch('address');
  const city = watch('city');
  const state = watch('state');
  const zip = watch('zip');

  const fullAddress = buildFullAddress({ address, city, state, zip }) ?? '';
  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
  
    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('state', fullAddress.state);
    setValue('zip', fullAddress.zip);
  };

  return (
    <SectionColumn p={0}>
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressAutocomplete
              label="Address"
              onPlaceSelected={handlePlaceSelected}
              fullAddress={fullAddress}
              {...field}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => <TextInput label="City" {...field} />}
      />
      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <DropdownInput
              suggest
              id="state"
              label="State"
              data={usaStateCodes}
              {...field}
          />
        )}
      />
      <Controller
        name="zip"
        control={control}
        render={({ field }) => <TextInput label="Zip Code" {...field} />}
      />
      <Controller
        name={"email"}
        control={control}
        render={({ field }) => <TextInput label="Email Address" {...field} />}
      />
      <Controller
        name={"sendEmail"}
        control={control}
        render={({ field }) => <Checkbox {...field} label="Send Email When Repo is Closed" />}
      />
      <Controller
        name={"insuranceRequired"}
        control={control}
        render={({ field }) => <Checkbox {...field} label="Insurance Required" />}
      />
      <Controller
        name={"bondRequired"}
        control={control}
        render={({ field }) => <Checkbox {...field} label="Bond Required" />}
      />
      <Controller
        name={"licenseRequired"}
        control={control}
        render={({ field }) => <Checkbox {...field} label="License Required" />}
      />   
    </SectionColumn>
  );
};

export default RightContainer;