import { FC, ReactNode } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import { FormLabelProps } from "@mui/material/FormLabel";
// local
import MuiKendoFormLabel from "./MuiKendoFormLabel";

/** ###  */
const MuiKendoFormControl: FC<
  Grid2Props &
    FormControlProps & {
      label: ReactNode;
      labelProps?: FormLabelProps;
      containerProps?: Grid2Props;
    }
> = ({ children, disabled, label, labelProps, error, containerProps, ...props }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    wrap="nowrap"
    component={FormControl}
    disabled={disabled}
    {...props}
    {...containerProps}
  >
    <MuiKendoFormLabel disabled={disabled} error={error} {...labelProps}>
      {label}
    </MuiKendoFormLabel>
    {children}
  </Grid>
);

export default MuiKendoFormControl;
