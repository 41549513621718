// mui
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from '@mui/x-data-grid';

export type ApplyCellStyle<
  TRow extends GridValidRowModel = GridValidRowModel,
  TKey extends keyof TRow = keyof TRow,
  TValue extends TRow[TKey] = TRow[TKey]
> = (value: TValue) => SxProps | undefined;

type CellProps = <
  TRow extends GridValidRowModel,
  TValue extends TRow[keyof TRow] = TRow[keyof TRow]
>(
  p: GridRenderCellParams<TRow, any, any, GridTreeNodeWithRender> & {
    applyCellStyle: ApplyCellStyle<TRow, keyof TRow, TValue>;
  } & Omit<Grid2Props, keyof GridRenderCellParams>
) => JSX.Element;

/** ### Text cell, but with the ability to pass in a custom function to apply styles based on certain conditions
 *
 * ### Examples
 *
 * #### With generics:
 * ```tsx
 * const applyActiveStyles: ApplyCellStyle<UserAuth, 'active'> = (activeValue) => ({
 *   color: activeValue === true ? 'green' : 'red',
 * });
 *
 * export const tableColumns: MuiColumnDef<UserAuth>[] = [
 *   // ... more rows
 *   {
 *     field: 'active',
 *     headerName: 'Active',
 *     minWidth: 80,
 *     valueFormatter: (p: GridValueFormatterParams<UserAuth['active']>) =>
 *       p.value === true ? 'Active' : 'Inactive',
 *     renderCell: (p) => <ConditionalFmtCell {...p} applyCellStyle={applyActiveStyles} />,
 *   },
 *   // ... more rows
 * ];
 * ```
 *
 * #### Without generics:
 * ```tsx
 * // Function type `ApplyCellStyle` is also optional
 * const applyActiveStyles: ApplyCellStyle = (activeValue) => ({
 *   color: activeValue === true ? 'green' : 'red',
 * });
 *
 * export const tableColumns: MuiColumnDef<UserAuth>[] = [
 *   // ... more rows
 *   {
 *     field: 'active',
 *     headerName: 'Active',
 *     minWidth: 80,
 *     valueFormatter: (p: GridValueFormatterParams) =>
 *       p.value === true ? 'Active' : 'Inactive',
 *     renderCell: (p) => <ConditionalFmtCell {...p} applyCellStyle={applyActiveStyles} />,
 *   },
 *   // ... more rows
 * ];
 * ```
 */
const ConditionalFmtCell: CellProps = ({
  applyCellStyle,
  value,
  formattedValue,
  sx: gridSx,
  ...cellAndGridProps
}: Parameters<CellProps>[0]) => {
  // Isolate grid props from the spread by removing the cell props
  const {
    api,
    cellMode,
    colDef,
    field,
    focusElementRef,
    hasFocus,
    id,
    isEditable,
    row,
    rowNode,
    tabIndex,
    ...gridProps
  } = cellAndGridProps;

  const appliedSx = { ...gridSx, ...applyCellStyle(value) };
  const appliedValue = formattedValue ?? value;

  return (
    <Grid {...gridProps} container direction="column" sx={appliedSx}>
      {gridProps?.children || appliedValue}
    </Grid>
  );
};

export default ConditionalFmtCell;
