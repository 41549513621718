import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// utils
import { getDefDownDueStatus } from "@/utils/helpers/payment";

/** ###  */
const DdPmtField: FC = () => {
  const paymentData = usePmtFormCtx((s) => s.paymentData.value);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("ddPmt"));

  return (
    <Grid container direction="column" hidden={fieldProps.hidden}>
      <CurrencyInput label="DD Due" containerStyle={{ flex: 1 }} required {...fieldProps} />
      <small style={{ fontSize: "12px", fontStyle: "italic", marginLeft: "auto" }}>
        {getDefDownDueStatus(paymentData)}
      </small>
    </Grid>
  );
};

export default DdPmtField;
