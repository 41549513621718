import styles from './PageHeader.module.scss';

export const PageHeader = (props: { headerLabel: string; children?: React.ReactNode }) => {
  return (
    <header className={styles.headerContainer}>
      <h2 className={styles.header}>{props.headerLabel}</h2>
      {props.children && <div className={styles.headerRightContainer}>{props.children}</div>}
    </header>
  );
};
