import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
// utils
import { config } from '@/config';
// interfaces
import { AxiosService } from './axiosService';
import { BffUserClaim } from '@/interfaces/Auth';

class AuthService extends AxiosService {
  public constructor() {
    super();
  }

  async getBffClaims() {
    try {
      const res = await this.axios.get<BffUserClaim[]>('/bff/user');
      return res.data;
    } catch (err) {
      console.error(err);

      if (!isAxiosError(err)) throw err;

      if (err.code === 'ERR_NETWORK') {
        toast.error('Unable to connect to server: Network error', { toastId: 'auth' });
      } else if (err.code === 'ERR_BAD_RESPONSE' && err?.response?.status === 500) {
        toast.error('Unable to fetch user info: Internal server error', { toastId: 'auth' });
      } else if (err.status === 401) {
        // @note leave for reference - toast.error('User is not logged in', { toastId: 'auth' });
      }

      throw err;
    }
  }

  login() {
    window.location.replace(
      `${config.apiUrl}/bff/login?returnUrl=${window.location.href || config.localUrl}`
    );
  }

  async logout(logoutPath: string) {
    window.location.replace(`${config.apiUrl}${logoutPath}&returnUrl=${config.localUrl}`);
  }
}

export const authService = new AuthService();
