import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import SectionBody from '@/mui/components/form/Section/SectionBody';
import { BG_HIGHLIGHT } from '@/mui/theme/colors';
// components
import InsuranceLeftColumn from './InsuranceLeftColumn';
import InsuranceRightColumn from './InsuranceRightColumn';
import PhysicalDamageColumn from './PhysicalDamageColumn';
import ClaimsModal from './ClaimsModal';
import SubmitButtonGroup from './SubmitButtonGroup';
// state
import AcctInsuranceProvider, { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// style
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

/** @deprecated needs refactor */
const AppInsuranceForm: FC = () => {
  const setIsClaimsModalOpen = useAcctInsuranceCtx((s) => s.setIsClaimsModalOpen);
  const claims = useAcctInsuranceCtx((s) => s.insuranceClaims);

  // @todo move nested components to separate files
  return (
    <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT }}>
      <Grid component="h3">Insurance</Grid>
      <SectionBody
        gap={3}
        sx={{ p: 0 }}
        leftColElems={
          <>
            <InsuranceLeftColumn />
            <PhysicalDamageColumn />
          </>
        }
        rightColElems={<InsuranceRightColumn />}
      />

      <div className={styles.footer}>
        {claims.length > 0 && (
          <div className={styles.link} onClick={() => setIsClaimsModalOpen(true)}>
            View Insurance Claims
          </div>
        )}

        <SubmitButtonGroup />
      </div>

      <ClaimsModal />
    </Grid>
  );
};

const AppInsurance: FC = () => (
  <AcctInsuranceProvider>
    <AppInsuranceForm />
  </AcctInsuranceProvider>
);

export default AppInsurance;
