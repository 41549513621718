import { FC, PropsWithChildren, createContext, useState } from "react";
import { Params, useParams } from "react-router-dom";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// interfaces
import {
  ISalesSubviewDb,
  ISalesSubviewDbCommon,
  SalesFilterKey,
  SalesSortKey,
} from "../interfaces";
import { SubviewInfo, subviewInfoMap } from "./listViewConfig";
import { SalesSubview } from "../enums";

const useCtxState = <TRowModel extends ISalesSubviewDbCommon = ISalesSubviewDb>() => {
  // Route variables
  const routeParams = useParams();
  // Request variables
  const [salesListRows, setSalesListRows_] = useState<TRowModel[]>([]);
  const [dbCt, setDbCt] = useState(0);
  const [mappedCompaniesCt, setMappedCompaniesCt] = useState(0);

  const getSubviewInfoFromRoute = (
    routeParams: Readonly<Params<string>>
  ): SubviewInfo<SalesSortKey, SalesFilterKey> => {
    const subview = (routeParams["*"] as string).replace("/", "") as SalesSubview;
    if (!subview) throw new Error(`Invalid subview: '${subview}'`);

    const viewInfoItem = subviewInfoMap.get(subview);
    if (!viewInfoItem) throw new Error(`Invalid viewInfoItem- subview: '${subview}'`);
    return viewInfoItem;
  };

  const setSalesListRows = (newSalesListRows: TRowModel[]) => {
    const subviewInfo = getSubviewInfoFromRoute(routeParams);
    // Convert to subview-relevant class instances
    const validatedRows = newSalesListRows.map(
      (r) => subviewInfo.dbRowValidationFxn(r) as TRowModel
    );

    // Set validated data
    setSalesListRows_(validatedRows);
  };

  return {
    // Request variables
    salesListRows,
    setSalesListRows,
    dbCt,
    setDbCt,
    mappedCompaniesCt,
    setMappedCompaniesCt,
    // Subview config variables
    getSubviewInfo(routeParams: { "*"?: string; subview?: string }) {
      const subview = routeParams.subview as SalesSubview;
      if (!subview) throw new Error(`Invalid subview: '${subview}'`);
      const subviewInfoItem = subviewInfoMap.get(subview);
      if (!subviewInfoItem) throw new Error(`Invalid subviewInfoItem- subview: '${subview}'`);
      return subviewInfoItem;
    },
    get subviewInfo(): SubviewInfo<SalesSortKey, SalesFilterKey> {
      return getSubviewInfoFromRoute(routeParams);
    },
  };
};

type ICtx<TRowModel extends ISalesSubviewDbCommon = ISalesSubviewDb> = ReturnType<
  typeof useCtxState<TRowModel>
>;
const Ctx = createContext<ICtx | null>(null);
export type ISalesListCtx<TRowModel extends ISalesSubviewDbCommon = ISalesSubviewDb> =
  ICtx<TRowModel>;
export const useSalesListCtx = useCtxFactory(Ctx);
const SalesListProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default SalesListProvider;
