import { CSSProperties } from "react";
import { toast } from "react-toastify";
// utils
import { paymentService } from "@/services/paymentService";
import { displayErrors } from "@/utils/helpers/general";
import { SetState } from "@/interfaces/utilityTypes";
import { DCS_BLUE } from "@/mui/theme/colors";

const formStyle: { [key: string]: CSSProperties } = {
  input: { height: "30px", padding: "5px 10px" },
  "input:focus": { outline: "none", border: "2px solid #4399de" },
  button: {
    height: "40px",
    color: "#fff",
    background: DCS_BLUE,
    cursor: "pointer",
    border: "none",
    borderRadius: "10px",
  },
  "button:hover": { background: "#29469590" },
};

const newCardForm = () =>
  window.GlobalPayments.ui.form({
    styles: formStyle,
    fields: {
      "card-number": { target: "#card-number", placeholder: "•••• •••• •••• ••••" },
      "card-expiration": { target: "#card-expiration", placeholder: "MM / YYYY" },
      "card-cvv": { target: "#card-cvv", placeholder: "•••" },
      submit: { target: "#submit", text: "Submit" },
    },
  });

const postPmt =
  (paymentLogRecId: number, setIsLoading: SetState<boolean>, onComplete?: () => void) =>
  async (tokenRes: any) => {
    setIsLoading(true);

    try {
      const pmtRes = await paymentService.postOpenEdgeCCPayment(
        tokenRes.temporary_token,
        paymentLogRecId
      );

      if (pmtRes.success) {
        const receiptUrlRes = await paymentService.getReceiptUrl(pmtRes.paymentRecId);
        window.open(receiptUrlRes);
        onComplete && onComplete();
      } else {
        toast.error("There was an issue processing your payment");
        // @todo remove once figured out
        console.error(pmtRes, tokenRes);
      }
    } finally {
      setIsLoading(false);
    }
  };

export const getOpenEdgeNewCreditCard = (
  apiKey: string | undefined | null,
  openEdgeEnv: string | undefined | null,
  paymentLogRecId: number | undefined | null,
  setIsRefreshing: SetState<boolean>,
  setIsPostReqLoading: SetState<boolean>,
  onComplete: undefined | (() => void)
) => {
  if (!apiKey || !openEdgeEnv || !paymentLogRecId) return;

  setIsRefreshing(true);

  // Clear out the old script and fields in case this is a rerender
  document.querySelector("#openEdgeScript")?.remove();
  document.querySelectorAll("#openEdgeElements iframe")?.forEach((el) => el.remove());
  const script = document.createElement("script");
  script.src = "https://js.paygateway.com/secure_payment/v1/globalpayments.js";
  script.async = true;
  script.id = "openEdgeScript";
  script.addEventListener("load", () => {
    window.GlobalPayments.configure({
      "X-GP-Api-Key": apiKey,
      "X-GP-Environment": openEdgeEnv,
      enableAutocomplete: true,
    });

    const cardForm = newCardForm();

    cardForm.on("error", (err: any) => {
      displayErrors("There was an issue in processing your payment");
      console.error(err);
    });

    cardForm.on("token-success", postPmt(paymentLogRecId, setIsPostReqLoading, onComplete));
  });

  document.body.appendChild(script);
  setIsRefreshing(false);
};
