import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import ViewLayout from "./ViewLayout";
import AcctSubviewList from "./accountsSubviews/AcctSubviewList";
import PmtSubviewList from "./pmtSubviews/PmtSubviewList";
import AccountDetailsRouter from "./accountsSubviews/AccountDetail";
import MiscPaymentDetail from "./pmtSubviews/miscPayment/MiscPaymentDetail";
// state
import { useAuthSelector } from "../auth/authSlice";
import { AccountsViewProvider, useAccountsViewCtx } from "./accountsViewState";
import { TableProvider } from "./tableState";
import { AcctSubviewProvider } from "./accountsSubviews/acctSubviewState";
// utils
import { systemService } from "@/services/systemService";
// interfaces
import { AcctsSubviewKey, PmtSubviewKey } from "./viewInfo";
import { BaseRoute } from "@/general/router";

/** @deprecated @todo remove and/or replace references with `baseRoutes` - this is included in `baseRoutes` */
export const routeBase = BaseRoute.enum.accounts;

const AccountsRouter: FC = () => {
  const { compId } = useAuthSelector((state) => state);
  const setMappedCompaniesCt = useAccountsViewCtx((s) => s.setMappedCompaniesCt);
  // @todo convert to `useReq`
  // @todo move to AuthProvider
  const fetchMappedCompanies = async () => {
    try {
      const res = await systemService.mappedCompanies();
      setMappedCompaniesCt(res.length);
    } catch (err) {
      // @todo properly handle error
      console.error(err);
    }
  };

  // @todo move to `AuthProvider` - The appropriate scope given its use, dep. arr., and update frequency.
  useEffect(() => {
    fetchMappedCompanies();
  }, [compId]);

  return (
    <Routes>
      <Route element={<ViewLayout />}>
        <Route element={<AcctSubviewList />}>
          <Route path={`/${AcctsSubviewKey.active}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.all}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.cpiAccounts}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.iop}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.legalAccounts}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.outForRepo}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.residualDue}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.terminations}`} element={<></>} />
          <Route path={`/${AcctsSubviewKey.unpaidSales}`} element={<></>} />
        </Route>
        <Route element={<PmtSubviewList />}>
          <Route path={`/${PmtSubviewKey.miscPayment}`} element={<></>} />
          <Route path={`/${PmtSubviewKey.paymentActivity}`} element={<></>} />
        </Route>
      </Route>
      <Route path={`/${PmtSubviewKey.miscPayment}/new`} element={<MiscPaymentDetail />} />
      <Route path="/:colRecId/*" element={<AccountDetailsRouter />} />
      <Route
        path="*"
        element={<Navigate to={`/${routeBase}/${AcctsSubviewKey.active}`} replace={true} />}
      />
    </Routes>
  );
};

export default () => (
  <AccountsViewProvider>
    <TableProvider>
      <AcctSubviewProvider>
        <AccountsRouter />
      </AcctSubviewProvider>
    </TableProvider>
  </AccountsViewProvider>
);
