import { dpi } from './default';

export class PdfData {
  constructor(public pdfUrl: string, public pdfBlob: Blob, public pdfLinkElem: HTMLAnchorElement) {}
}

/** Units in inches */
export class AspectRatio {
  constructor(public w: number, public h: number) {}
  get ratio(): number {
    return this.w / this.h;
  }
  get wPx(): number {
    return this.w * dpi;
  }
  get hPx(): number {
    return this.h * dpi;
  }
}
