import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { isAxiosError } from "axios";
// state
import { authService } from "@/services/authService";
import { authActions } from "@/features/auth/authSlice";
import { useAppDispatch } from "@/store/store";
// utils
import { config } from "@/config";
import { isStandalone } from "@/env";
// interfaces
import { SelectableCompany, UserInfo } from "@/interfaces/System";
import { SetState } from "@/interfaces/utilityTypes";
import { HubConnection } from "@microsoft/signalr";

type ICtx = IAuthCtx;
export interface IAuthCtx {
  // Layout states
  isLoggedIn: boolean | null;
  loadingBffUser: boolean;

  // User state
  userInfo: UserInfo | null;
  setUserInfo: SetState<ICtx["userInfo"]>;

  // Util functions
  logout: () => void;

  // Getters
  selectedCompany: SelectableCompany | null;

  // SignalR
  signalRConnection: HubConnection | null;
  setSignalRConnection: SetState<ICtx["signalRConnection"]>;

  // User state
  unreadSmsCount: number;
  setUnreadSmsCount: SetState<ICtx["unreadSmsCount"]>;
}

const AuthCtx = createContext<IAuthCtx | null>(null);
// @todo streamline via `useCtxFactory` pattern
const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const [userInfo, setUserInfo] = useState<ICtx["userInfo"]>(null);
  const [loadingBffUser, setLoadingBffUser] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [logoutPath, setLogoutPath] = useState<string | null>(null);
  const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null);
  const [unreadSmsCount, setUnreadSmsCount] = useState(0);

  // Util functions
  const tryLoadBffUser = async () => {
    setLoadingBffUser(true);

    try {
      const claims = await authService.getBffClaims();
      const logoutPathValue = claims.find((x) => x.type === "bff:logout_url")?.value.toString();
      if (logoutPathValue) {
        dispatch(authActions.loginDeprec());
        setLogoutPath(logoutPathValue);
        setIsLoggedIn(true);
      }
    } finally {
      setLoadingBffUser(false);
    }
  };

  const trySilentLogin = () => {
    setLoadingBffUser(true);

    window.addEventListener("message", async (e) => {
      if (e.data?.source === "bff-silent-login" && e.data?.isLoggedIn) {
        console.warn("trying silent log in");
        await tryLoadBffUser();
      }
    });

    const iframe = document.createElement("iframe");
    iframe.src = `${config.apiUrl}/bff/silent-login`;
    iframe.style.cssText = "display:none;";
    document.body.appendChild(iframe);
  };

  const getBffClaims = async () => {
    if (loadingBffUser === true) return;

    setLoadingBffUser(true);

    try {
      await tryLoadBffUser();
    } catch (err) {
      if (!isAxiosError(err)) throw err;
      console.error(err);

      const isServerError =
        err.code === "ERR_NETWORK" ||
        (err.code === "ERR_BAD_RESPONSE" && err?.response?.status === 500);

      if (!isServerError) {
        await trySilentLogin();
      }
    } finally {
      setLoadingBffUser(false);
    }
  };

  const logout = async () => {
    if (!logoutPath) {
      authService.login();
      return;
    }

    try {
      await authService.logout(logoutPath!);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // only check for BFF user when we're not integrated into the alpha site
    if (isStandalone && !isLoggedIn) {
      getBffClaims();
    }
  }, [location, isLoggedIn]);

  useEffect(() => {
    if (loadingBffUser === false && isLoggedIn === null) {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, loadingBffUser]);

  // Getters
  // @todo remove post-alpha: `selectedCompId` should be managed with state
  const storedCompId = localStorage.getItem("selectedCompId");

  // Check and apply defCompId - @note parseInt can handle `null`
  if (isNaN(parseInt(storedCompId!))) {
    // Runtime check if valid recId is returned
    if (typeof userInfo?.defaultCompId !== "number") {
      console.error("In AuthProvider: userInfo", userInfo);
    }

    // @todo revert once debugged - testing alpha-qa
    userInfo && localStorage.setItem("selectedCompId", userInfo.defaultCompId.toString());
  }

  const appliedCompId = storedCompId ? Number(storedCompId) : userInfo?.defaultCompId;
  const selectedCompany =
    userInfo?.selectableCompanies?.find((sc) => sc.compId === appliedCompId) ?? null;

  return (
    <AuthCtx.Provider
      value={{
        // Layout
        isLoggedIn,
        loadingBffUser,

        userInfo,
        setUserInfo,

        // Utils
        logout,

        // Getters
        selectedCompany,

        // SignalR
        signalRConnection,
        setSignalRConnection,

        //SMS
        unreadSmsCount,
        setUnreadSmsCount,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export default AuthProvider;

export const useAuthCtx = <T,>(selector: (state: IAuthCtx) => T): T => {
  const ctx = useContext(AuthCtx);
  if (!ctx) {
    throw new Error("useAuthCtx must be used within AuthProvider");
  }
  return selector(ctx);
};
