import dayjs from 'dayjs';
import { DateString } from '../types';

export const shortZipRegex = /^\d{5}$/;
export const phoneRegex = /^$|^\(?\d{3}\)? ?-?\d{3}-?\d{4}$/;
export const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const vinRegex = /[(A-H|J-N|P|R-Z|0-9)]{17}/i;
export const ssnRegex = /\d{3}-\d{2}-\d{4}/;

export const genericRequired = { required: 'Field is required' };

const phoneInputMask = '(000) 000-0000';
export const optionalPhoneValidation = {
  pattern: {
    value: phoneRegex,
    message: `Must be empty or formatted as ${phoneInputMask}`,
  },
};

export const notGreaterThanTodayValidation = {
  validate: (d: string | DateString) =>
    dayjs(d) > dayjs() ? "Cannot be greater than today's date" : undefined,
};

export const optionalNotGreaterThanTodayValidation = {
  validate: (date?: string | DateString | null) =>
    !date
      ? undefined
      : dayjs(date).isAfter(dayjs())
      ? "Cannot be greater than today's date"
      : undefined,
};

export const dateGTETodayValidation = {
  validate: (d: string | DateString) =>
    dayjs(d).diff(dayjs().startOf('day')) < 0 ? 'Date cannot be in the past' : undefined,
};

export const dateStartedDateOfSaleValidation = {
  validate: (d: string | DateString) => {
    return dayjs().startOf('day').diff(dayjs(d), 'day') >= 0
      ? 'Date Started must be after Date of Sale'
      : undefined;
  },
};

export const not18yearsOldValidation = {
  validate: (d: string | DateString | null | undefined) =>
    dayjs(d) > dayjs().subtract(18, 'year') ? "Must be at least 18 years old" : undefined,
};
