import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
// utils
import { DCS_BLUE } from '@/mui/theme/colors';

const FormGroupHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      my="19px"
      lineHeight="24px"
      sx={{ columnGap: '87px', fontSize: '16px', fontWeight: 400 }}
    >
      <Grid>
        <Typography fontWeight={700} color={DCS_BLUE} sx={{ lineHeight: '24px', fontSize: '16px' }}>
          {title}
        </Typography>
      </Grid>
      <Grid sx={{ backgroundColor: '#d3d3d370', flex: 1, height: '1px', mx: 2 }} />
    </Grid>
  );
};

export default FormGroupHeader;
