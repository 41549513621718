import { IFormFieldConfig } from "./cliqInterfaces";

/** ### Remove duplicate `iframe` DOM elements when component rerenders.
 *
 * DOM manipulation util.
 *
 * The injected Cliq/CollectJS `<script>` creates `<iframe>` elements (one for each field).
 * When the component rerenders ()
 * This utility is also used as a fallback in the case that These fields may not be
 */
export const removeDuplicateFields = (fieldConfig: Map<string | number, IFormFieldConfig>) => {
  const frames = document.querySelectorAll<HTMLInputElement>(
    ".input-field iframe.CollectJSInlineIframe"
  );

  if (frames.length > fieldConfig.size) {
    let current: HTMLInputElement | undefined;
    for (let idx = 0; idx < frames.length; idx++) {
      const elem = frames[idx];
      if (elem?.id === current?.id) {
        elem?.remove();
      }

      current = frames[idx];
    }
  }
};

export const addFieldEventListeners = () => {
  const frames = document.querySelectorAll<HTMLInputElement>(
    ".input-field iframe.CollectJSInlineIframe"
  );

  for (let idx = 0; idx < frames.length; idx++) {
    const elem = frames[idx];

    const inputContainer = elem?.parentElement?.parentElement;
    const formLabel = inputContainer?.parentElement?.querySelector("label");

    if (!inputContainer || !formLabel) continue;
    const labelClasses = ["MuiInputLabel-shrink", "Mui-focused"];
    const inputClasses = ["Mui-focused"];

    // Add active/inactive styles
    elem.addEventListener("focus", function (_e: FocusEvent) {
      formLabel.classList.add(...labelClasses);
      inputContainer.classList.add(...inputClasses);
    });
    elem.addEventListener("blur", function (event: FocusEvent & { detail?: { empty?: boolean } }) {
      if (event.detail?.empty) {
        formLabel.classList.remove(...labelClasses);
        inputContainer.classList.remove(...inputClasses);
      }
    });
  }
};
