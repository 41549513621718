import { devConfig } from './config.dev';
import { prodConfig } from './config.prod';
import { stagingConfig } from './config.staging';
import { qaConfig } from './config.qa';

const configMap = {
  production: prodConfig, // nocommit
  staging: stagingConfig, // nocommit
  qa: qaConfig, // nocommit
  development: devConfig, // nocommit
};

// convert `dev-standalone` to `dev`, etc
// @todo clean up post-alpha
const mode = import.meta.env.MODE.split('-')[0] as keyof typeof configMap; // nocommit

export const config = configMap[mode]; // nocommit
