import { FC, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
// components
import ApplicationTab from "../../detailView/tabs/ApplicationTab";
import CreditEducationDetail from "../salesTabs/CreditEducationForm/CreditEducationDetail";
import SaleMgmtTab from "../salesTabs/SaleMgmtTab/SaleManagement";
import { Verification } from "../salesTabs/verification/Verification";
import { VehicleAndTempTag } from "../salesTabs/vehicleAndTempTag/VehicleAndTempTag";
import { Terms } from "../salesTabs/terms/Terms";
import { SalesDocuments } from "../salesTabs/salesDocuments/SalesDocuments";
import { SalesTax } from "../salesTabs/terms/salesTax/SalesTax";
import { TradeIn } from "../salesTabs/tradeIn/TradeIn";
import { CryptoSign } from "../salesTabs/cryptoSign/CryptoSign";
import { SecureClose } from "../salesTabs/secureClose/SecureClose";
import CreditReports from "../salesTabs/creditReports/CreditReports";
import { CashBuyer } from "../../detailView/tabs/ApplicationTab/subviews/buyer/CashBuyer";
// state
import { useAppDispatch } from "@/store/store";
import { salesActions } from "../../salesSlice";
import {
  getApplication,
  getAvailableVehicles,
  getCompanyUnderwriting,
  getLienholders,
  getSaleData,
} from "../../salesActionCreator";
import { BuyerType } from "@/enums";
import { CashCoBuyer } from "../../detailView/tabs/ApplicationTab/subviews/coBuyer/CashCoBuyer";
import AppTabViewProvider from "../../detailView/tabs/ApplicationTab/AppTabViewProvider";
import BuyerInfoTab from "../../detailView/tabs/ApplicationTab/subviews/buyer/BuyerInfoTab";

const SaleDetailsRouter: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const newSale = params.id === "newSale";
  const appRecId = Number(params.id);

  useEffect(() => {
    dispatch(salesActions.reset());
    if (newSale) {
      dispatch(salesActions.setSaleData({}));
    } else {
      dispatch(getSaleData(appRecId)).then((res) => {
        dispatch(getCompanyUnderwriting(res.sale!.compid!));
        dispatch(getAvailableVehicles(res.sale!.compid!));
        dispatch(getLienholders(res.sale!.compid!));
      });
      dispatch(getApplication(appRecId));
    }
  }, [appRecId, dispatch, newSale]);

  return (
    <Routes>
      <Route path="sale-management/*" element={<SaleMgmtTab />} />
      <Route path="creditReports" element={<CreditReports />} />
      <Route path="application/*" element={
        <AppTabViewProvider>
          <ApplicationTab />
        </AppTabViewProvider>
      }>
        <Route index element={<Navigate to="buyer" replace />} />
        <Route path="buyer" element={<CreditEducationDetail isCobuyer={false} />} />
        <Route path="cobuyer" element={<CreditEducationDetail isCobuyer={true} />} />
        <Route path="insurance" element={<div>Insurance Component</div>} />
        <Route path="references" element={<div>References Component</div>} />
        <Route path="underwriting" element={<div>Underwriting Component</div>} />
        <Route path="autolend-iq" element={<div>AutoLend IQ Component</div>} />
        <Route path="*" element={<Navigate to="buyer" replace />} />
      </Route>

      <Route path="buyer-info/*" element={<BuyerInfoTab />}>
        <Route index element={<Navigate to="buyer" replace />} />
        <Route path="*" element={<Navigate to="buyer" replace />} />
      </Route>
      <Route path="verification" element={<Verification />} />
      <Route path="vehicleAndTempTag" element={<VehicleAndTempTag />} />
      <Route path="creditEducation/*">
        <Route path="buyer" element={<CreditEducationDetail isCobuyer={false} />} />
        <Route path="cobuyer" element={<CreditEducationDetail isCobuyer={true} />} />
        <Route path="*" element={<Navigate to="buyer" replace />} />
      </Route>
      <Route path="terms/*">
        <Route path="" element={<Terms />} />
        <Route path="sales-tax" element={<SalesTax />} />
        <Route path="trade-in" element={<TradeIn />} />
        <Route path="*" element={<Navigate to="./" replace />} />
      </Route>
      <Route path="documents/*">
        <Route path="" element={<SalesDocuments />} />
        <Route path="secureclose" element={<SecureClose />} />
        <Route path="cryptosign" element={<CryptoSign />} />
        <Route path="*" element={<Navigate to="./" replace />} />
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="sale-management" replace />} />
      <Route path="/" element={<Navigate to="sale-management" replace />} />
    </Routes>
  );
};

export default SaleDetailsRouter;
