import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { resolvePath, useMatch, useNavigate, useParams } from "react-router-dom";
// state
import PmtFormProvider, {
  usePmtFormCtx,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
import { useSalesDetailCtx } from "../SalesDetailProvider";
// services
import { salesService } from "@/services/salesService";
// utils
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { zNumeric } from "@/utils/forms/fieldSchemas";
// interfaces
import { WsFormVehicle, WsCompany } from "./interfaces";
import { WsFormErrors, WsForm, WsFormBase } from "./formInterfaces";

/** ###  */
const useCtxState = () => {
  const appRecId = useSalesDetailCtx((s) => s.appRecId);
  /** @deprecated When implemented, use `useAuthCtx(s => s.employees.value)` */
  const employees = usePmtFormCtx((s) => s.employees.value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWsCompanyModalOpen, setIsWsCompanyModalOpen] = useState(false);
  // Form data
  const [wsFormDeprec, setWsFormDeprec] = useState<WsForm>(WsForm.fromRes(null));
  // Validation
  const [wsFormErrors, setWsFormErrors] = useState<WsFormErrors>(new WsFormErrors());

  const wholesale = useReq(async () => {
    if (!appRecId) return null;
    return await salesService.getWholesaleForm(appRecId);
  });
  const wsAddlInfo = useReq(async () => await salesService.getWholesaleFormAddlLists());

  useEffect(() => {
    // const initForm = wholesale.value ? WsForm.fromRes(wholesale.value) : null;
    setWsFormDeprec(WsForm.fromRes(wholesale.value));
  }, [wholesale.value]);
  useEffect(() => {
    wholesale.load();
    wsAddlInfo.load();
  }, [appRecId]);

  /** Set a single field on the active form */
  const setWsFormField = <T extends keyof WsFormBase = keyof WsFormBase>(
    field: T,
    value: WsForm[T]
  ) => {
    const updatedWsForm = WsForm.setField(field, value, wsFormDeprec!);
    setWsFormDeprec(updatedWsForm);
  };

  useEffect(() => {
    if (wsFormDeprec !== null && wsAddlInfo.value?.vehicles) {
      // Get vehicle
      const wsVehicle = WsFormVehicle.fromWsFormState(wsFormDeprec, wsAddlInfo.value?.vehicles);
      // only override form values when vehicle exists and sale status is "pending"
      if (!wsVehicle || wsFormDeprec.saleStatus.toLowerCase() !== "pending") {
        return;
      }
      // Get vehicle-props for the form
      const { exceedsLim, exempt, notActual, milesIn, milesOut } = wsVehicle;
      const updatedWsFormWithVehicle = WsForm.new({
        ...wsFormDeprec,
        exceedsLim: exceedsLim || false,
        exempt: exempt || false,
        notActual: notActual || false,
        milesIn: milesIn || "0",
        milesOut: milesOut || "0",
      });

      setWsFormDeprec(updatedWsFormWithVehicle);
    }
  }, [wsFormDeprec?.vehRecId]);

  const setWsFormFieldError = (field: keyof WsFormErrors, msg: string) => {
    setWsFormErrors(WsFormErrors.setField(field, msg, wsFormErrors));
  };

  // Getters
  const isFormLoaded = wsFormDeprec !== null;
  // @todo Convert to use browser parameters
  const isFormEdit = zNumeric.safeParse(wholesale.value?.appRecId).success;

  /** Req.-data-state, including current form values for `company` and `vehicle` */
  const formAddlData = {
    ...wsAddlInfo.value,
    // Add the vehicle provided in the form to the lookup list
    vehicles: [
      ...(wsAddlInfo.value?.vehicles ?? []),
      WsFormVehicle.new({ ...wholesale.value, recId: wholesale.value?.vehRecId || undefined }),
    ],
    // Add the wholesale company provided in the form to the lookup list
    companies: [
      ...(wsAddlInfo.value?.companies ?? []),
      WsCompany.new({ ...wholesale.value, recId: wholesale.value?.wsBuyer || undefined }),
    ],
  };
  /** @deprecated needs refactor */
  const selectedWsCompany = WsCompany.fromWsFormState(wsFormDeprec, formAddlData?.companies);
  /** @deprecated needs refactor */
  const wsFormVehicle = WsFormVehicle.fromWsFormState(wsFormDeprec, formAddlData?.vehicles) ?? null;

  const selectedSalesperson = employees?.find((s) => s.recId === wsFormDeprec?.salesMan) || null;

  return {
    // Req data states
    wholesale,
    wsAddlInfo,

    // Layout states
    isModalOpen,
    setIsModalOpen,
    isWsCompanyModalOpen,
    setIsWsCompanyModalOpen,

    wsFormDeprec,
    setWsFormField,

    wsFormVehicle,
    selectedWsCompany,
    selectedSalesperson,

    wsFormErrors,
    setWsFormFieldError,

    // Getters
    formAddlData,
    isFormLoaded,
    isFormEdit,
  };
};
type ICtx = ReturnType<typeof useCtxState>;
export type IWholesaleFormCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);
export const useWholesaleViewCtx = useCtxFactory(Ctx);

/** @deprecated needs refactor - see comment for `WholesaleViewProvider_` */
const WholesaleViewProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>;
};

/** @deprecated Remove `WholesaleViewProvider_` and move `PmtFormProvider` and logic to `WholesaleViewProvider` after impl. `employee` req-state in `AuthProvider` */
const WholesaleViewProvider_: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const saleData = useSalesDetailCtx((s) => s.saleDataReq);
  const paymentDataReq = useSalesDetailCtx((s) => s.paymentDataReq);
  // Edge case: handle nav-up for wholesale form submit
  // 1. Confirm current path is wholesale form
  // 2. Programmatically Generate path
  const wsPmtFormRouteMatch = useMatch(
    "/sales/:subview/:appRecId/wholesale-management/payments/collect"
  );
  const wsPmtFormNavUpRoute = resolvePath({ pathname: `.${wsPmtFormRouteMatch?.pathname}/..` });

  /** 1. Reload sale detail; 2. Navigate up one level */
  const wsHandleSuccess = async () => {
    saleData.load();
    navigate(wsPmtFormNavUpRoute);
  };

  return (
    <PmtFormProvider handleSuccess={wsHandleSuccess} paymentData={paymentDataReq}>
      <WholesaleViewProvider>{children}</WholesaleViewProvider>
    </PmtFormProvider>
  );
};

export default WholesaleViewProvider_;
