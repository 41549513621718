import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
// components
import { Checkbox, DropDownButton } from '@/components';
// state
import { InventorySubView, useInventoryViewCtx } from '../state';
// interfaces
import { CheckboxItem } from './interfaces';
// styles
import listStyles from '../ListStyles.module.scss';

type LookupTable = {
  [key in InventorySubView]: string[];
};

const lookupTable: LookupTable = {
  unsold: ['Retail', 'Cash', 'Recon', 'Disposal', 'In Transit', 'Wholesale'],
  sold: ['Lease', 'Finance', 'Cash', 'Wholesale'],
  iop: [],
  all: ['Retail', 'Cash', 'Recon', 'Disposal', 'In Transit', 'Wholesale'],
  alllotsretail: [],
  notReceived: [],
  disposal: [],
  titlelogs: [],
};

/** ### Dropdown selector component for inventory types
 *
 * - Renders in the table header.
 * - Clickable button that displays a list types to filter on.
 * - state managed type selections.
 */
const TypeFilter: FC = () => {
  // get view slug
  const params = useParams();
  const subView = params['*'] as InventorySubView;

  const tableFilters = useInventoryViewCtx((s) => s.tableFilters);
  const setTableFilters = useInventoryViewCtx((s) => s.setTableFilters);

  // Generate initialTypeFilters array from unique types
  const initialTypeFilters: CheckboxItem[] = lookupTable[subView].map((value) => ({
    title: value ? value : '',
    display: false,
  }));
  const [typeOpen, setTypeOpen] = useState(false);
  const [typeFilters, setTypeFilters] = useState<CheckboxItem[] | undefined>(initialTypeFilters);

  // Function to extract titles from CheckboxItem objects where display is true
  const extractTitles = (checkboxItems: CheckboxItem[]): string[] => {
    return checkboxItems
      .filter((item) => item.display) // Filter out items where display is false
      .map((item) => item.title); // Extract titles from remaining items
  };

  const handleTypeFilterClick = (clickedCheckbox: CheckboxItem) => {
    const updatedFilters = typeFilters
      ? typeFilters.map((filter) =>
          filter && filter.title === clickedCheckbox.title
            ? { ...filter, display: !filter.display } // Toggle the display property
            : filter
        )
      : undefined;

    const titles: string[] = updatedFilters === undefined ? [] : extractTitles(updatedFilters);
    const filterColumn = ['unsold', 'all'].includes(subView) ? 'invType' : 'saleType';
    setTypeFilters(updatedFilters);
    setTableFilters({ ...tableFilters, [filterColumn]: titles });
  };

  const typeFilterElems = typeFilters ? (
    typeFilters.map((c) => (
      <div
        key={c.title}
        className={listStyles.userSettingsCheckList}
        onClick={() => handleTypeFilterClick(c)}
      >
        <Checkbox checked={c.display} />
        <div>{c.title}</div>
      </div>
    ))
  ) : (
    <div />
  );

  return (
    <div className={listStyles.typeFilterSettingsButton}>
      <div className={listStyles.columnSettings}>
        <DropDownButton
          label="Type Filter"
          open={typeOpen}
          setOpen={setTypeOpen}
          buttonProps={{ secondary: true }}
        >
          <div className={listStyles.userSettingsContent}>{typeFilterElems}</div>
        </DropDownButton>
      </div>
    </div>
  );
};

export default TypeFilter;
