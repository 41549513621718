import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
// components
import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
// state
import { AppDispatch, useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { EmployeeField } from '@/interfaces/System';
import {
  GetPaymentData,
  PostPaymentPayload,
  newPaymentForm,
  paymentService,
} from '@/services/paymentService';
import { pollForReceiptDeprec } from '@/utils/helpers/payment';
import { useNavigationConfirm } from '@/hooks/useNavigationConfirm/useNavigationConfirm';
import { getInitials } from '@/utils/helpers/general';
import { getRouteParamNum } from '@/utils/routing/formatting';
// style
import styles from './CpiRefund.module.scss';

const handleSuccess =
  (colRecId: number | null | undefined, navigate: NavigateFunction, dispatch: AppDispatch) =>
  async () => {
    await dispatch(getAccountInformation(colRecId));
    navigate('.');
  };

/* 
    Form state is initialized with data from getPaymentDetails
    Most of the payment submit payload is contained in the form state
    Some fields shown in the form are not directly part of the form state - the ones not wrapped in a <Controller />
        e.g. employee which is an object that many of the submit payload fields are populated by
*/

/** ###  */
const CpiRefund: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const colRecId = getRouteParamNum(useParams().colRecId);

  const userName = useAuthSelector((s) => s.userName);

  const [paymentData, setPaymentData] = useState<GetPaymentData>({} as GetPaymentData);
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [postPaymentLoading, setPostPaymentLoading] = useState(false);
  const [paymentDataError, setPaymentDataError] = useState('');
  const [employees, setEmployees] = useState<EmployeeField[]>([]);
  const [employee, setEmployee] = useState<EmployeeField>();

  const init = async (colRecId: number | undefined | null) => {
    if (!colRecId) {
      console.warn(`CpiRefund > init(): Invalid colRecId: ${colRecId}`);
      return;
    }

    try {
      setPaymentDataLoading(true);
      setPaymentDataError('');
      const paymentDataResponse = await paymentService.getPaymentDetails(colRecId);

      setPaymentData(paymentDataResponse);

      reset(newPaymentForm(paymentDataResponse));

      const users = await paymentService.getUsersByCompanyId(paymentDataResponse.compId);
      setEmployees(users);
    } catch (err) {
      console.error(err);
      setPaymentDataError('Unable to load payment data');
    } finally {
      setPaymentDataLoading(false);
    }
  };

  useEffect(() => {
    init(colRecId);
  }, [colRecId]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<PostPaymentPayload>({
    defaultValues: newPaymentForm(paymentData),
  });

  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  useEffect(() => {
    // Set the default employee once we have a list of employees
    if (!employees.length) return;
    const defaultEmployee = employees.find((emp) => emp.shortName === userName);
    if (defaultEmployee) {
      setEmployee(defaultEmployee);
    } else {
      setEmployee(employees[0]);
    }
  }, [employees]);

  useEffect(() => {
    // Employee object was updated by dropdown or useEffect defaulting - update the relevant submit payload fields
    if (!employee) return;
    setValue('TakenBy', employee.shortName);
    setValue('UserEmail', employee.userId);
    setValue('UserRecId', employee.recId);
  }, [employee]);

  const onSubmit = async (paymentPayload: PostPaymentPayload) => {
    const takenBy = getInitials(employee!.shortName);

    const finalPayload = cloneDeep(paymentPayload);
    finalPayload.CpiPaid = -Math.abs(paymentPayload.CarPmt);
    finalPayload.DdPmt = 0;
    finalPayload.LcPaid = 0;
    finalPayload.NsfPaid = 0;
    finalPayload.PaidBy = 'Other';
    finalPayload.PaymentType = 'CPI ADJ';
    finalPayload.TakenBy = takenBy;
    finalPayload.TotalReceived = 0;

    try {
      setPostPaymentLoading(true);

      const res = await paymentService.postPaymentSubmit(finalPayload);

      pollForReceiptDeprec(res.paymentLogRecId, handleSuccess(colRecId, navigate, dispatch));
    } finally {
      setPostPaymentLoading(false);
    }
  };

  return (
    <AccountsMainPanel navBarTitle="CPI Refund" loading={paymentDataLoading}>
      {paymentDataError ? (
        <div>{paymentDataError}</div>
      ) : (
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <CurrencyInput label="CPI Paid To Date" readOnly value={paymentData.cpiAvailable ?? 0} />
          <Controller
            name="CarPmt"
            control={control}
            rules={{
              required: 'Amount is required',
              min: { value: 0.01, message: 'Amount cannot be less than or equal to 0' },
              max: {
                value: paymentData.cpiAvailable ?? 0,
                message: 'Amount cannot be greater than CPI paid to date',
              },
            }}
            render={({ field }) => (
              <CurrencyInput label="Amount" required errors={errors.CarPmt?.message} {...field} />
            )}
          />

          <DropdownInput
            label="Employee"
            required
            data={employees}
            dataItemKey="recId"
            textField="shortName"
            onChange={(e) => setEmployee(e.value)}
            value={employee}
            errors={!employee}
          />

          <Controller
            name="TakenByPassword"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                label="Password"
                required
                errors={!!errors.TakenByPassword}
                type="password"
                {...field}
              />
            )}
          />

          <Controller
            name="PayNote"
            control={control}
            render={({ field }) => (
              <TextInput label="Payment Note" horizontalLabel boldLabel {...field} />
            )}
          />

          {paymentData.buyerEmail && !paymentData.buyerNoEmail && (
            <Controller
              name="SendB"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label="Send Buyer Email Receipt"
                  labelStyles={{ fontWeight: '700' }}
                  {...field}
                />
              )}
            />
          )}

          {paymentData.cobuyerEmail && !paymentData.cobuyerNoEmail && (
            <Controller
              name="SendC"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label="Send Cobuyer Email Receipt"
                  labelStyles={{ fontWeight: '700' }}
                  {...field}
                />
              )}
            />
          )}

          <div className={styles.buttonContainer}>
            <Button
              label="Post Payment"
              disabled={postPaymentLoading}
              loading={postPaymentLoading}
              style={{ width: '211px' }}
            />
          </div>
        </form>
      )}
      {NavigationConfirm}
    </AccountsMainPanel>
  );
};

export default CpiRefund;
