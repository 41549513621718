import { FC } from "react";
import { Controller } from "react-hook-form";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Checkbox, TextInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";

const NameSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "addOn1",
    "addOn2",
    "addOn3",
    "addOn4",
    "addOn5",
    "addOn6",
    "addOn7",
    "addOn8",
    "addOn9",
    "addOn10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ height: "30.5px", display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Setting"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value ? field.value.toString() : null} // Ensure the value is a number or null
            />
          )}
        />
      ))}
    </Grid>
  );
};

const PriceSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "price1",
    "price2",
    "price3",
    "price4",
    "price5",
    "price6",
    "price7",
    "price8",
    "price9",
    "price10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Price"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <CurrencyInput
              {...field}
              value={field.value !== null && field.value !== undefined? Number(field.value) : null} // Ensure the value is a number or null
            />
          )}
        />
      ))}
    </Grid>
  );
};

const CostSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "cost1",
    "cost2",
    "cost3",
    "cost4",
    "cost5",
    "cost6",
    "cost7",
    "cost8",
    "cost9",
    "cost10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Cost"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <CurrencyInput
              {...field}
              value={field.value !== null && field.value !== undefined? Number(field.value) : null} // Ensure the value is a number or null
            />
          )}
        />
      ))}
    </Grid>
  );
};

const StateCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "stateTax1",
    "stateTax2",
    "stateTax3",
    "stateTax4",
    "stateTax5",
    "stateTax6",
    "stateTax7",
    "stateTax8",
    "stateTax9",
    "stateTax10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"State"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              containerStyles={{
                height: "36px",
                display: "flex",
                alignSelf: "center",
              }}
            />
          )}
        />
      ))}
    </Grid>
  );
};

const CountyCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "countyTax1",
    "countyTax2",
    "countyTax3",
    "countyTax4",
    "countyTax5",
    "countyTax6",
    "countyTax7",
    "countyTax8",
    "countyTax9",
    "countyTax10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"County"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              containerStyles={{
                height: "36px",
                display: "flex",
                alignSelf: "center",
              }}
            />
          )}
        />
      ))}
    </Grid>
  );
};

const CityCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "cityTax1",
    "cityTax2",
    "cityTax3",
    "cityTax4",
    "cityTax5",
    "cityTax6",
    "cityTax7",
    "cityTax8",
    "cityTax9",
    "cityTax10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"City"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              containerStyles={{
                height: "36px",
                display: "flex",
                alignSelf: "center",
              }}
            />
          )}
        />
      ))}
    </Grid>
  );
};

const LocationCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "locTax1",
    "locTax2",
    "locTax3",
    "locTax4",
    "locTax5",
    "locTax6",
    "locTax7",
    "locTax8",
    "locTax9",
    "locTax10",
  ];

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Location"}
      </Grid>
      {formFields.map((fieldName) => (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              containerStyles={{
                height: "36px",
                display: "flex",
                alignSelf: "center",
              }}
            />
          )}
        />
      ))}
    </Grid>
  );
};

/** ###  */
const SettingsFields: FC = () => [
  <NameSettings key="nameSettingFields" />,
  <PriceSettings key="priceSettingFields" />,
  <CostSettings key="costSettingFields" />,
  <StateCheckboxes key="stateCheckboxes" />,
  <CountyCheckboxes key="countyCheckboxes" />,
  <CityCheckboxes key="cityCheckboxes" />,
  <LocationCheckboxes key="locationCheckboxes" />,
];

export default SettingsFields;
