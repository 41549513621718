import { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';
// mui
import MuiLink, { LinkProps } from '@mui/material/Link';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// style
import { LINK_BLUE } from '@/mui/theme/colors';

const linkStyle: CSSProperties = { color: LINK_BLUE, textDecoration: 'underline' };

/** ###  */
const LinkCell: FC<
  GridCellProps & {
    navRouteBuilder: (dataItem: any) => string;
    onClick?: LinkProps['onClick'];
  }
> = ({ dataItem, field, navRouteBuilder, onClick }) => {
  const route = navRouteBuilder(dataItem);
  const displayValue: string = dataItem[field!] || "-";

  return (
    <td className="k-table-td" style={linkStyle}>
      <MuiLink component={Link} to={route} underline="hover" onClick={onClick}>
        {displayValue}
      </MuiLink>
    </td>
  );
};

export default LinkCell;
