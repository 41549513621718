import { FC, useEffect, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// kendo
import { Spacer } from '@/components/spacer/Spacer';
import { AccountInfo } from '@/components/old/accountInfo/AccountInfo';
import { Button } from '@/components/old/button/Button';
import { DmsDropDown } from '@/components/old/dmsDropDown/DmsDropDown';
import { DmsModal } from '@/components/old/dmsModal/DmsModal';
import { DmsNotes } from '@/components/old/dmsNotes/DmsNotes';
import { DmsNumberInput } from '@/components/old/dmsNumberInput/DmsNumberInput';
import { DmsTextInput } from '@/components/old/dmsTextInput/DmsTextInput';
import { EmailReceipt } from '@/components/old/emailReceipt/EmailReceipt';
// components
import SamTasks from '@/components/old/samTasks/SamTasks';
// state
import { useOldPaymentSelector } from '../oldPaymentSlice';
// utils
import { paymentService, Employee, GetPaymentData, getDefaultPostPaymentPayload } from '@/services/paymentService';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials } from '@/utils/helpers/general';
import levelUpImage from '@/assets/level_up.png';
import { PostPaymentPayloadOld } from '../interfaces';
// style
import commonStyles from '@/features/old/payment/Payment.module.scss';

interface FormError {
  inputName?: string;
  message?: string;
}

const navigateToList = () => window.ACManage?.panelSetActive('MASTER', true);

export const AcCPIPay: FC = () => {
  const { compId, colRecId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [appRecId, setAppRecId] = useState<number | undefined>();
  const [cpiPaidToDate, setCpiPaidToDate] = useState<number>();
  const [amount, setAmount] = useState<string>();
  const [password, setPassword] = useState<string>('');
  const [note, setNote] = useState<string>();
  const [employeeArray, setEmployeeArray] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [refreshing, setRefreshing] = useState(true);
  const [dmsPaymentPayload, setDmsPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [pmtDetails, setPmtDetails] = useState<GetPaymentData>();
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [includeBuyerEmail, setIncludeBuyerEmail] = useState<boolean>(false);
  const [includeCobuyerEmail, setIncludeCobuyerEmail] = useState<boolean>(false);

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  const postPayment = () => {
    if (!appRecId) {
      alert('Error submitting post payment');
      return;
    }
    const tempDmsPayload = dmsPaymentPayload;

    tempDmsPayload!.AppRecId = appRecId!;
    tempDmsPayload!.ColRecId = colRecId!;
    tempDmsPayload!.CarPmt = parseFloat(amount!); // makes no sense but this is how they're doing it in alpha
    tempDmsPayload!.ColType = pmtDetails!.colType;
    tempDmsPayload!.CpiPaid = -Math.abs(parseFloat(amount!));
    tempDmsPayload!.EmailB = pmtDetails!.buyerEmail ?? '';
    tempDmsPayload!.EmailC = pmtDetails!.cobuyerEmail ?? '';
    tempDmsPayload!.LocId = pmtDetails!.locId;
    tempDmsPayload!.PaidBy = 'Other';
    tempDmsPayload!.PaidIn = 'In-Person';
    tempDmsPayload!.PmtContext = 'AC_CPI';
    tempDmsPayload!.PmtType = 'Cash';
    tempDmsPayload!.PaymentType = 'CPI ADJ';
    tempDmsPayload!.SendB = includeBuyerEmail;
    tempDmsPayload!.SendC = includeCobuyerEmail;
    tempDmsPayload!.TakenBy = getInitials(employee!.shortName);
    tempDmsPayload!.TakenByPassword = password;
    tempDmsPayload!.TotalReceived = 0;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;

    setDmsPaymentPayload(tempDmsPayload);

    paymentService.postPaymentSubmit(tempDmsPayload as any).then((res) => {
      paymentService.getReceiptUrl(res.paymentRecId).then((url) => {
        window.open(url);
        navigateToList();
      });
    });
  };

  const initData = async () => {
    setRefreshing(true);

    try {
      const users = await paymentService.getUsersByCompanyId(compId!);
      setEmployeeArray(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (UNSAFE_currentEmployeeShortName) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      const paymentDetails = await paymentService.getPaymentDetails(colRecId!);
      setPmtDetails(paymentDetails);
      setAppRecId(paymentDetails.appRecId);
      setCpiPaidToDate(paymentDetails.cpiAvailable!);

      const dms = getDefaultPostPaymentPayload(paymentDetails);
      setDmsPaymentPayload(dms);
      setIncludeBuyerEmail(paymentDetails.buyerNoEmail);
      setIncludeCobuyerEmail(false);
    } catch (error) {
      console.error(error);
    }

    setRefreshing(false);
  };

  const isAmountGTPaidToDate = (): boolean => {
    return parseFloat(amount!) > cpiPaidToDate!;
  };

  const handleAmountErrors = (amount: string | undefined, errors: FormError[]) => {
    if (!amount) {
      errors.push({ inputName: 'amount', message: 'Amount is required' });
    } else if (isNaN(parseFloat(amount!))) {
      errors.push({ inputName: 'amount', message: 'Amount must be a number' });
    } else if (isAmountGTPaidToDate()) {
      errors.push({
        inputName: 'amount',
        message: 'Amount cannot be greater than CPI Paid to Date',
      });
    } else if (parseFloat(amount!) <= 0) {
      errors.push({ inputName: 'amount', message: 'Amount cannot be less than or equal to 0' });
    }
  };

  const handlePaymentModal = (openModal: boolean) => {
    const errors: FormError[] = [];

    handleAmountErrors(amount, errors);

    if (!password) {
      errors.push({ inputName: 'password', message: 'Field is required' });
    }

    setFormErrors(errors);
    if (errors.length === 0) {
      setPaymentModal(openModal);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  if (refreshing) {
    return (
      <div className={commonStyles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  return (
    <div>
      {postPaymentLoading && (
        <div className={commonStyles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={commonStyles.header}>
        <img src={levelUpImage} onClick={navigateToList} />
        CPI Payment/Refund
      </div>
      <div className={commonStyles.container}>
        <div className={commonStyles.leftContainer}>
          <DmsNumberInput
            name="CPIPaidToDate"
            inputLabel="CPI Paid to Date"
            readOnly={true}
            fixedDecimalScale
            value={cpiPaidToDate?.toFixed(2)}
            style={{ width: '100%' }}
          />
          <Spacer size={5} />
          <DmsNumberInput
            name="amount"
            inputLabel="Amount"
            fixedDecimalScale
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value).toString())}
            onBlur={(e) => setAmount(parseFloat(e.target.value).toFixed(2))}
            errors={formErrors.find((error) => error.inputName === 'amount')?.message}
            style={{ width: '100%' }}
            required
          />
          <Spacer size={5} />
          <DmsTextInput
            name="password"
            type="password"
            inputLabel="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            errorMessage={formErrors.find((error) => error.inputName === 'password')?.message}
            inputStyles={{ width: '100%' }}
            required
          />
          <DmsDropDown
            name="employee"
            label="Employee"
            values={employeeArray.map((employee) => employee.shortName)}
            value={employee!.shortName}
            inputContainerStyle={{ width: '100%' }}
            onChange={(e) => setEmployee(employeeArray[e.target.options.selectedIndex])}
          />
          <DmsTextInput
            name="paymentNote"
            inputLabel="Payment Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            inputStyles={{ width: '100%' }}
          />
          <Spacer size={8} />
          <Spacer size={8} />
          <div></div>
          <Button
            buttonLabel="Post Payment"
            onClick={() => {
              handlePaymentModal(true);
            }}
            style={{
              padding: '4px 0',
              width: '100%',
              height: '36px',
              fontSize: '18px',
            }}
          />
          <EmailReceipt
            buyerEmail={pmtDetails?.buyerEmail || ''}
            coBuyerEmail={pmtDetails?.cobuyerEmail || ''}
            containerStyles={{ marginTop: '16px' }}
            noEmailState={pmtDetails?.buyerNoEmail}
            coBuyerNoEmailState={pmtDetails?.cobuyerNoEmail}
            colRecId={colRecId}
            appRecId={pmtDetails?.appRecId}
            buyerRecId={pmtDetails?.buyerRecId}
            cobuyerRecId={pmtDetails?.coBuyerRecId}
            emailCallback={(val) => pmtDetails && setPmtDetails({ ...pmtDetails, buyerEmail: val })}
            cobuyerEmailCallback={(val) =>
              pmtDetails && setPmtDetails({ ...pmtDetails, cobuyerEmail: val })
            }
            optOutCallback={(val) =>
              pmtDetails && setPmtDetails({ ...pmtDetails, buyerNoEmail: val })
            }
            cobuyerOptOutCallback={(val) =>
              pmtDetails && setPmtDetails({ ...pmtDetails, cobuyerNoEmail: val })
            }
            buyerCheckboxFunction={(val) => setIncludeBuyerEmail(val)}
            cobuyerCheckboxFunction={(val) => setIncludeCobuyerEmail(val)}
            hasCobuyer={pmtDetails?.hasCobuyer ?? false}
          />
        </div>
        <div className={commonStyles.rightContainer}>
          <AccountInfo paymentDetails={pmtDetails} />
          <SamTasks appRecId={dmsPaymentPayload?.AppRecId} colType={dmsPaymentPayload?.ColType} />
          <DmsNotes colRecId={colRecId} />
        </div>
      </div>
      <DmsModal
        title="Confirm Payment"
        message={'Are you sure you want to post this payment?'}
        onAcceptLabel="Yes"
        onDeclineLabel="No"
        isOpen={paymentModal}
        closeFunction={() => {
          setPaymentModal(false);
        }}
        onDeclineFunction={() => {
          setPaymentModal(false);
        }}
        onAcceptFunction={() => {
          postPayment();
          setPaymentModal(false);
        }}
      />
    </div>
  );
};
