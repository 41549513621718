import { FC } from "react";
import { z } from "zod";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";

const NumberCell: FC<GridCellProps> = ({ dataItem, field }) => {
  const cellValue = dataItem[field!];

  const numSchema = z.coerce.number();
  const validated = numSchema.safeParse(cellValue);

  if (validated.error) console.error(`<NumberCell />: Invalid number: '${cellValue}'`);

  const validatedCellValue = validated.success ? validated.data : "NaN";
  const cellValueFmt = cellValue === undefined ? "-" : validatedCellValue;

  return (
    <td className="k-table-td" style={{ textAlign: "end" }}>
      <span>{cellValueFmt}</span>
    </td>
  );
};

export default NumberCell;
