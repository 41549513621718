import { FC, ReactNode, createContext, useContext, useState } from 'react';

export interface ISalesViewCtx {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  // Modal variables
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const SalesViewCtx = createContext<ISalesViewCtx | null>(null);

const SalesViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  // Modal variables
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <SalesViewCtx.Provider
      value={{
        // Request variables
        isLoading,
        setIsLoading,
        // Modal variables
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </SalesViewCtx.Provider>
  );
};

export default SalesViewProvider;

export const useSalesViewCtx = <T,>(selector: (state: ISalesViewCtx) => T): T => {
  const ctx = useContext(SalesViewCtx);
  if (!ctx) {
    throw new Error('useSalesViewCtx must be used within SalesViewProvider');
  }
  return selector(ctx);
};
