import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const CarPmtField: FC = () => {
  const isPrinOnly = usePmtFormCtx((s) => s.formState.config.external.isPrincipalOnly);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("carPmt"));

  return (
    <CurrencyInput
      label={isPrinOnly ? "Principal Payment" : "Car Payment"}
      // @todo replace with: readOnly={fieldProps.disabled}
      readOnly={!isPrinOnly}
      {...fieldProps}
    />
  );
};

export default CarPmtField;
