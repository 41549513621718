import { FC } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

/** ###  */
const AlertHeaderItem: FC<Grid2Props & { label: string; amount: number }> = ({
  label,
  amount,
  color,
  sx: sxProps,
  ...props
}) => (
  <Grid container direction="row" fontSize="16px" fontWeight={400} gap={1} wrap="nowrap" {...props}>
    <Grid component={Typography} sx={{ ...sxProps, color }}>
      {label}
    </Grid>
    <Grid component={Typography} sx={{ ...sxProps, color }}>
      ({amount})
    </Grid>
  </Grid>
);

export default AlertHeaderItem;
