import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';

const PercentCell: FC<GridCellProps> = ({ dataItem, field }) => {
  const cellValue = dataItem[field!];
  let cellValueFmt = '';

  if (cellValue === undefined) {
    throw Error(
      `<PercentCell /> - Invalid field: "${field}" - dataItem: ${JSON.stringify(dataItem)}`
    );
  } else if (typeof cellValue === 'number') {
    const cellValuePct = Math.round(cellValue * 1000) / 1000;
    cellValueFmt = `${cellValuePct}%`;
  } else if (cellValue === null) {
    cellValueFmt = `-`;
  } else {
    throw Error(
      `<PercentCell /> - Invalid field type: "${field}" - cellValue: '${cellValue}' - type: '${typeof cellValue}'`
    );
  }

  return (
    <td className="k-table-td" style={{ textAlign: 'end' }}>
      <span>{cellValueFmt}</span>
    </td>
  );
};

export default PercentCell;
