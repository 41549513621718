import { AxiosService } from './axiosService';
import { displayErrors } from '@/utils/helpers/general';

class NotesService extends AxiosService {
  public constructor() {
    super();
  }

  async getNotes(colRecId: number) {
    const {
      data: { note },
    } = await this.axios.get('/Notes/Customer', {
      params: { colRecId },
    });

    return note;
  }

  async sendNote(colRecId: number, note: string) {
    const payload = {
      colRecId: colRecId,
      note: note,
    };

    try {
      const { data } = await this.axios.post('/Notes/Customer', payload);
      return data;
    } catch (e) {
      displayErrors('Unable to add note');
      throw e;
    }
  }

  async insertNote(appBuyerRecId: number, colRecId: number, note: string) {
    try {
      const { data } = await this.axios.post('/Notes/Insert', {
        appBuyerRecId,
        colRecId,
        note,
      });
      return data;
    } catch (e) {
      displayErrors('Unable to add note');
      throw e;
    }
  }
}

export const notesService = new NotesService();
