import { FC } from 'react';
// mui
import { DataGrid } from '@mui/x-data-grid';
// state
import { usePmtReversalCtx } from '../PmtReversalProvider';
// utils
import { tableColumns } from './tableColumns';

/** ###  */
const PmtReversalTable: FC = () => {
  const pageModel = usePmtReversalCtx((s) => s.pageModel);
  const setPageModel = usePmtReversalCtx((s) => s.setPageModel);
  const rows = usePmtReversalCtx((s) => s.rows);
  const isLoading = usePmtReversalCtx((s) => s.isLoading);

  return (
    <DataGrid
      loading={isLoading}
      rows={rows}
      columns={tableColumns}
      getRowId={(r) => r.recId}
      paginationModel={pageModel}
      onPaginationModelChange={setPageModel}
    />
  );
};

export default PmtReversalTable;
