import * as Sentry from "@sentry/react";
// state
import { authActions } from "@/features/auth/authSlice";
// utils
import { systemService } from "@/services/systemService";
import { isStandalone } from "@/utils/helpers/general";
// interfaces
import { UserInfo } from "@/interfaces/System";
import { IAuthCtx } from "@/AppProviders/AuthProvider";

export const initAuthState = async (
  dispatch: any,
  isLoggedIn: boolean,
  setUserInfo: IAuthCtx["setUserInfo"]
) => {
  if (isStandalone && !isLoggedIn) return;

  const res = await systemService.getUserInfo();
  const userInfo = new UserInfo(res);

  /**
   * If they had previously selected a non-default company, use that one
   * This will be cleared on logout by localStorage.reset(),
   * but to be safe, ensure that the company exists in their list of selectable companies
   *
   * @note If no company exists in local-storage at `selectedCompId` (when signing in, browser storage is cleared, etc.):
   * - Apply default company ID (defCompId/defaultCompId) from response
   * - Set localStorage to this value
   *
   * @todo remove post-alpha: `selectedCompId` should be managed with state
   */
  const storedCompId = localStorage.getItem("selectedCompId");

  // @note remove this conditional block when alpha & redux-auth-state is removed - this is handled in AuthProvider
  // Check and apply defCompId
  if (isNaN(parseInt(storedCompId!))) {
    // Runtime check if valid recId is returned
    if (typeof userInfo.defaultCompId !== "number") {
      console.error("In authenticatedContainer/request.ts: userInfo", userInfo);
      // throw new Error(
      //   "No default company ID (selectedCompId | userInfo.defaultCompId) set for current user"
      // );
    }

    localStorage.setItem("selectedCompId", userInfo.defaultCompId.toString());
  }

  setUserInfo(userInfo);

  dispatch(authActions.setHasAfsLevel(userInfo.afsLevel === 1));
  dispatch(authActions.setHasDclAccess(userInfo.dclAccess ?? false));
  dispatch(authActions.setInvChangeStkNum(userInfo.invChangeStkNum ?? false));
  dispatch(authActions.setInvPrice(userInfo.invPrice ?? false));
  dispatch(authActions.setInvDelete(userInfo.invDelete ?? false));
  dispatch(authActions.setBackDateInv(userInfo.backDateInv ?? false));
  dispatch(authActions.setBackDateExp(userInfo.backDateExp ?? false));

  // @todo post-alpha this will need to happen all the time
  // in alpha, we just want to use the values alpha passed in
  if (!isStandalone) return;

  const appliedCompId = storedCompId ? Number(storedCompId) : userInfo.defaultCompId;
  const foundCompany = userInfo.selectableCompanies?.find((sc) => sc.compId === appliedCompId);

  dispatch(
    authActions.setState({
      userId: userInfo.recId,
      userEmail: userInfo.userId,
      userName: userInfo.firstName + " " + userInfo.lastName,
      orgId: foundCompany?.orgId || userInfo.defaultOrgId,
      compId: foundCompany?.compId || userInfo.defaultCompId,
      locId: foundCompany?.locId || userInfo.defaultLocId,
      selectableCompanies: userInfo.selectableCompanies,
    })
  );
  Sentry.setUser({ email: userInfo.userId, id: userInfo.recId });
};
