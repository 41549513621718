import { FC, useEffect } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { usePmtFormCtx } from "../PaymentForm/PmtFormProvider";
import PaymentModalGroup from "@/components/modals/PaymentModalGroup";
import { useSavedPaymentsCtx } from "./SavedPaymentProvider";
import BillingInfoFields from "../PaymentForm/FormRightColumn/BillingInfoFields";
import CcBillingFields from "../PaymentForm/FormRightColumn/CcBillingFields";
import OpenEdgeMpdFields from "../PaymentForm/FormRightColumn/OpenEdgeMpdFields";
import AchAcctTypeField from "../PaymentForm/FormRightColumn/AchAcctTypeField";
import AuthAndNotesSection from "../PaymentForm/FormRightColumn/AuthAndNotesSection";
import SubmitButtonGroup from "../PaymentForm/FormRightColumn/SubmitButtonGroup";
import { Divider } from "@mui/material";

/** ###  */
const SavedPaymentForm: FC = () => {
  const addingNewCard = useSavedPaymentsCtx((s) => s.addingNewCard);
  const formState = usePmtFormCtx((s) => s.formState);

  useEffect(() => {
    if (!addingNewCard) return; 
    formState.setForm({
      ...formState.form,

      //values we want to set
      carPmt: 0, // formState.form.carPmt,
      cpiPaid: 0, // formState.form.cpiPaid,
      ddPmt: 0, // formState.form.ddPmt,
      lcPaid: 0, // formState.form.lcPaid,
      lcWaived: 0, // formState.form.lcWaived,
      nsfPaid: 0, // formState.form.nsfPaid,
      paidBy: "Credit Card", // formState.form.paidBy,
      paidRef: "ZeroAuth", // formState.form.paidRef,
      payNote: "ZeroAuth", // formState.form.payNote,
      totalPayment: 0, // formState.form.totalPayment,
      totalReceived: 0, // formState.form.totalReceived,
      transactionType: "Payment", // formState.form.transactionType,
      waiveConvFee: true, // formState.form.waiveConvFee,
      provider: "Cliq", // formState.form.provider,
    });
  }, [addingNewCard]);

  return (
    <>
      <Grid container direction="column" flex={1} sx={{ gap: "10px" }}>
        <BillingInfoFields />
        <CcBillingFields />
        <OpenEdgeMpdFields />
        <AchAcctTypeField />
      </Grid>
      <Divider flexItem orientation="vertical" sx={{ borderWidth: 1.5, borderColor: "#e6e6e6" }} />

      <Grid container direction="column" flex={1} sx={{ gap: "10px" }}>
        <AuthAndNotesSection />
        <SubmitButtonGroup />
      </Grid>
      <PaymentModalGroup />
    </>
  );
};

export default SavedPaymentForm;
