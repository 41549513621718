import { FC } from "react";
// components
import Section from "@/mui/components/form/Section";
import SectionHeader from "@/mui/components/form/Section/SectionHeader";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import Subsection from "@/mui/components/form/Section/Subsection";
import TextFieldDisplay from "./layout/TextFieldDisplay";
import CurrencyFieldDisplay from "./layout/CurrencyFieldDisplay";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";

/** ###  */
const DealSummarySection: FC = () => {
  const sale = useSalesSelector((s) => s);
  const application = useSalesSelector((s) => s.applicationData);
  const vehRecId = application.vehRecID;
  const vehicle = sale.availableVehicles.find((v) => v.recId === vehRecId);

  // @note See calc in comment where var is used
  const ttl = application.title + application.ldowntax + application.registration;

  return (
    <Section disabled>
      <SectionHeader label="Deal Summary" />

      {/* @todo Add correct values */}
      <SectionBody>
        <Subsection label="Submitted Deal Review" isSubsectionSplit>
          {/* from: consolidatedApp.AmtFin */}
          <CurrencyFieldDisplay label="Loan Amount" value={application.amtFin} isFlex1 />

          {/* from: consolidatedApp.intrate */}
          <TextFieldDisplay
            label="Interest Rate"
            value={`${application.intrate || "- "}%`}
            isFlex1
          />

          {/* from: consolidatedApp.COD */}
          <CurrencyFieldDisplay label="Down Payment" value={application.cod} isFlex1 />

          {/* from: consolidatedApp.Term */}
          <TextFieldDisplay label="Loan Term" value={application.term} isFlex1 />
        </Subsection>

        <Subsection label="Deal Summary" isSubsectionSplit>
          {/* LeasePrice = consolidatedApp.SalesPrice */}
          <CurrencyFieldDisplay label="Lease Price" value={application?.salesPrice} isFlex1 />

          {/* @todo need to double check with Virginia */}
          <CurrencyFieldDisplay label="Due at Lease Signing" value={application?.cod} isFlex1 />

          {/* AdminFee = consolidatedApp.Addon7 */}
          <CurrencyFieldDisplay label="Acquisition Fee" value={application?.addon7} isFlex1 />

          {/* LeasePaymentAmount = consolidatedApp.SPamt1 */}
          <CurrencyFieldDisplay label="Payment Amount" value={application?.sPamt1} isFlex1 />

          {/* SecurityDeposit = consolidatedApp.SecDeposit */}
          <CurrencyFieldDisplay label="Security Deposit" value={application?.secDeposit} isFlex1 />

          {/* DocFee = consolidatedApp.Addon4 */}
          <CurrencyFieldDisplay label="Doc Fee" value={application?.addon4} isFlex1 />

          {/*
            TitleFee = consolidatedApp.Title
            SalesTaxOnDownPayment = consolidatedApp.Ldowntax
            LicenseAndRegistrationFee = consolidatedApp.Registration
           */}
          <CurrencyFieldDisplay label="T. T. & L." value={ttl} isFlex1 />

          {/* ResidualAmount = consolidatedApp.residual */}
          <CurrencyFieldDisplay label="Residual" value={application?.residual} isFlex1 />
        </Subsection>

        <Subsection label="Vehicle Preview" isSubsectionSplit>
          <TextFieldDisplay label="VIN" value={vehicle?.vin} isFlex1 />
          <TextFieldDisplay label="Make" value={vehicle?.make} isFlex1 />
          <TextFieldDisplay label="Model" value={vehicle?.model} isFlex1 />
          <TextFieldDisplay label="Year" value={vehicle?.year} isFlex1 />
        </Subsection>
      </SectionBody>
    </Section>
  );
};

export default DealSummarySection;
