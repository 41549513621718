import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// components
import AcctMiscCard from "./AcctMiscCard";
import VehicleStatusCard from "./VehicleStatusCard";
import AcctStatusCard from "./AcctStatusCard";
import GpsCard from "../../components/GpsView/GpsCard";

/** ### Layout for left container of account-detail view */
const LeftContainer: FC = () => (
  <Grid
    container
    direction="column"
    sx={{ gap: "20px", width: "380px", minWidth: "350px", maxWidth: "380px" }}
  >
    <AcctMiscCard />
    <VehicleStatusCard />
    <AcctStatusCard />
    <GpsCard />
  </Grid>
);

export default LeftContainer;
