import { FC, PropsWithChildren } from 'react';
// state
import HotlistProvider from '@/components/hotlist/HotlistProvider';
import NotificationsProvider from '@/features/notifications/NotificationsProvider';
import GpsProvider from '@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsProvider';

const AppProviders: FC<PropsWithChildren> = ({ children }) => (
  <NotificationsProvider>
    <GpsProvider>
      <HotlistProvider>{children}</HotlistProvider>
    </GpsProvider>
  </NotificationsProvider>
);

export default AppProviders;
