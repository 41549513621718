import { formatDateTz, getLocalDateFormat } from '@/general/dates';
import {
  LhPhFundingDb,
  LhPhFundingDetailDb,
} from '../../SalesListView/subviewConfigs/lhPhFunding/interfaces';
import dayjs from 'dayjs';
import { LhPhSubmissionModel } from '@/interfaces';

const { dateFormatStr } = getLocalDateFormat();

export class LhPhRes extends LhPhFundingDb {
  constructor(newLhPh: LhPhFundingDb) {
    super(
      newLhPh.appRecId,
      newLhPh.appStatus,
      newLhPh.bNames,
      newLhPh.buyerRecId,
      newLhPh.cobuyRecId,
      newLhPh.cod,
      newLhPh.code3,
      newLhPh.companyName,
      newLhPh.compAppId,
      newLhPh.compId,
      newLhPh.fundAmt,
      newLhPh.fundDate,
      newLhPh.fundDone,
      newLhPh.fundDueDate,
      newLhPh.funded,
      newLhPh.fundedOn,
      newLhPh.fundNum,
      newLhPh.fundPmt,
      newLhPh.fundPO,
      newLhPh.fundPOAmt,
      newLhPh.fundPODate,
      newLhPh.fundRate,
      newLhPh.fundRepoSwap,
      newLhPh.fundRequested,
      newLhPh.invDays,
      newLhPh.isCobuyer,
      newLhPh.locId,
      newLhPh.manLocation,
      newLhPh.orgId,
      newLhPh.realDown,
      newLhPh.region,
      newLhPh.ror,
      newLhPh.saleDate,
      newLhPh.saleStatus,
      newLhPh.saleType,
      newLhPh.shortName,
      newLhPh.stockNum,
      newLhPh.term,
      newLhPh.totalDown,
      newLhPh.vehRecId,
      newLhPh.vehYmm,
      newLhPh.vin,
      newLhPh.webYmm
    );
  }
}

export class LhPhDetail extends LhPhFundingDetailDb {
  constructor(newLhPhDetail?: LhPhFundingDetailDb) {
    super(
      newLhPhDetail?.purprice ?? 0,
      newLhPhDetail?.totexp ?? 0,
      newLhPhDetail?.fundExpAllow ?? 0,
      newLhPhDetail?.lhphIntRate ?? 0,
      newLhPhDetail?.fundRequested ?? false,
      newLhPhDetail?.fundDone ?? null,
      newLhPhDetail?.fundRepoSwap ?? false,
      newLhPhDetail?.fundDate ?? dayjs().format('YYYY-MM-DD'),
      newLhPhDetail?.fundAmt ?? 0,
      newLhPhDetail?.fundNum ?? 0,
      newLhPhDetail?.fundPmt ?? 0,
      newLhPhDetail?.fundDueDate ?? dayjs().format('YYYY-MM-DD'),
      newLhPhDetail?.funded ?? false,
      newLhPhDetail?.fundedon ?? dayjs().format('YYYY-MM-DD'),
      newLhPhDetail?.fundPO ?? false,
      newLhPhDetail?.fundPODate ?? dayjs().format('YYYY-MM-DD'),
      newLhPhDetail?.fundPOAmt ?? 0,
      newLhPhDetail?.appRecId ?? 0,
      newLhPhDetail?.recId ?? 0,
      newLhPhDetail?.saledate ?? dayjs().format('YYYY-MM-DD'),
      newLhPhDetail?.salestatus ?? null,
      newLhPhDetail?.stocknum ?? null,
      newLhPhDetail?.legalName ?? null,
      newLhPhDetail?.leinholder ?? 0,
      newLhPhDetail?.amtFin ?? 0,
      newLhPhDetail?.lhphFundDay ?? 0,
      newLhPhDetail?.lhphCutOffDay ?? 0,
      newLhPhDetail?.lhphMaxTerm ?? 0,
      newLhPhDetail?.lhphMaxRecon ?? 0,
      newLhPhDetail?.lhphFundRate ?? 0,
      newLhPhDetail?.term ?? 0,
      newLhPhDetail?.code3 ?? null,
      newLhPhDetail?.locid ?? 0,
      newLhPhDetail?.orgid ?? 0
    );
  }

  toReq(): LhPhSubmissionModel {
    return {
      fundExpAllow: this.fundExpAllow,
      fundRequested: this.fundRequested,
      fundDone: 'PENDING',
      fundRepoSwap: this.fundRepoSwap,
      fundDate: dayjs(this.fundDate).format(dateFormatStr),
      fundAmt: this.fundAmt,
      fundNum: Math.min(this.lhphMaxTerm ?? 0, this.term ?? 0),
      fundPmt: this.fundPmt,
      fundDueDate: dayjs(this.fundDueDate).format(dateFormatStr),
      funded: this.funded,
      fundedon: !this.fundedon ? null : dayjs(this.fundedon).format(dateFormatStr),
      fundPO: this.fundPO,
      fundPODate: !this.fundPODate ? null : dayjs(this.fundPODate).format(dateFormatStr),
      fundPOAmt: this.fundPOAmt,
      appRecId: this.appRecId,
    };
  }
}
