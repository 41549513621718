// utils
import { getAddressFromGoogleMaps } from '@/utils/helpers/general';
// interfaces
import { ISalesInsuranceCtx } from './SalesInsuranceProvider';
import { UsaStateCode } from '@/general/regions';
import { CpiSettings } from '@/interfaces/applications';
import { PaymentInterval } from '@/enums/payment';

export const handlePlaceSelected =
  (state: ISalesInsuranceCtx) => (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    const setValue = state.insuranceInfoForm.setValue;
    setValue('insAddress', fullAddress.address);
    setValue('insCity', fullAddress.city);
    setValue('insState', fullAddress.state as UsaStateCode);
    setValue('insZip', fullAddress.zip);
  };

export const calcCpiRateDisabled = (
  cpiSettings: CpiSettings | null,
  schedule: PaymentInterval | null
) => {
  if (!cpiSettings || !schedule) return null;

  const cpiRateMap: { [key in PaymentInterval]: number | null } = {
    Weekly: cpiSettings.cpiWeeklyAmt,
    'Bi-Weekly': cpiSettings.cpiBiWeeklyAmt,
    'Semi-Monthly': cpiSettings.cpiSemiMonthlyAmt,
    Monthly: cpiSettings.cpiMonthlyAmt,
  };

  return cpiRateMap[schedule];
};
