// kendo
import { GridColumnProps } from '@progress/kendo-react-all';
// components
import TotalAppliedCell from '../TotalAppliedCell';
// interfaces
import { PmtSubviewDb } from '../interfaces';
import { newColumnDef } from '../../../../utils/tableLayout/utils';

export type PmtActivityColumn = GridColumnProps & { display?: boolean; field: keyof PmtSubviewDb };
export const paymentActivityColumns: PmtActivityColumn[] = [
  newColumnDef('pmtDate', 'Payment Date', 140, 'date'),
  newColumnDef('mPayToFrom', 'Customer Name'),
  newColumnDef('totalApplied', 'Amount', 125, TotalAppliedCell),
  newColumnDef('takenBy', 'By', 80),
  newColumnDef('mPayNote', 'Note', 'auto'),
  newColumnDef('paidIn', 'Where', 120),
  newColumnDef('paidBy', 'Paid By', 150),
  newColumnDef('code3', 'Lot', 80),
];
