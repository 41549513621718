import { FC, ReactNode } from "react";
import { Id, ToastOptions, toast } from "react-toastify";
// mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// components
import ToastNotiLayout from "./ToastNotiLayout";
// interfaces
import { ToastNotiProps } from "./interfaces";

const ToastNotiList: FC<ToastNotiProps & { items: string[] }> = ({ items, ...props }) => {
  const itemElems = items.map((item, idx) => (
    <ListItem dense divider={idx < items.length - 1} sx={{ pr: 0 }} key={item}>
      <ListItemText primary={item} />
    </ListItem>
  ));

  return (
    <ToastNotiLayout m={0} {...props}>
      <List sx={{ width: "100%", bgcolor: "background.paper" }} dense>
        {itemElems}
      </List>
    </ToastNotiLayout>
  );
};

export const showToastList = (
  title: string | ReactNode,
  items: string[],
  options?: ToastOptions
): Id => toast(() => <ToastNotiList title={title} items={items} />, options);

export default ToastNotiList;
