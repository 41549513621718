import React, { FC, useEffect, useState } from 'react';
import { GridToolbar } from '@progress/kendo-react-all';
import { Button, DropdownInput } from '@/components';
import styles from './CheckInGrid.module.scss';
import { useInventoryDetailCtx } from '../../../InventoryDetailProvider';
import { CurrentInspection, VehicleInspector } from '@/interfaces';
import MuiKendoDatePicker from '@/mui/components/form/MuiKendoDatePicker';
import dayjs from 'dayjs';
import { inventoryService } from '@/services/inventoryService';
import { useParams } from 'react-router-dom';

const InspectorSelector: FC = () => {
  const inspectorList = useInventoryDetailCtx((s) => s.inspectorList);
  const currentInspection = useInventoryDetailCtx((s) => s.currentInspection);
  const setCurrentInspection = useInventoryDetailCtx((s) => s.setCurrentInspection);
  const params = useParams();
  const getNameFromRecId = (list: VehicleInspector[], inspectorId: number) => {
    const item = list.find((item) => item.recId === inspectorId);
    return item ? item.shortName : null; // Return null if no matching item is found
  };

  const currentName = (inspectItem: CurrentInspection) => {
    if (params['*'] === 'vehicleCheckIn') {
      return {
        recId: inspectItem.ckInBy!,
        shortName: getNameFromRecId(inspectorList, inspectItem.ckInBy!),
      };
    } else if (params['*'] === 'vehicleOptions') {
      return {
        recId: inspectItem.optBy!,
        shortName: getNameFromRecId(inspectorList, inspectItem.optBy!),
      };
    } else {
      return '';
    }
  };

  const currentDate = (inspectItem: CurrentInspection) => {
    if (params['*'] === 'vehicleCheckIn') {
      return inspectItem.ckInDate;
    } else if (params['*'] === 'vehicleOptions') {
      return inspectItem.optDate;
    } else {
      return '';
    }
  };
  const [inspectorId, setInspectorId] = useState(0);
  const [inspectionDate, setInspectionDate] = useState<dayjs.Dayjs | null>(
    dayjs(currentDate(currentInspection))
  );

  const handleInspectorSelection = (e: any) => {
    setInspectorId(e.value.recId!);
  };

  const handleDateSelect = (e: dayjs.Dayjs | null) => {
    setInspectionDate(e);
  };

  const getCurrentInspection = async () => {
    try {
      const initVehicleInspection = await inventoryService.getCurrentInspection(
        Number(params.invRecId)
      );
      setCurrentInspection(initVehicleInspection as CurrentInspection);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (inspectionDate === null) return;
    try {
      if (params['*'] === 'vehicleCheckIn') {
        await inventoryService.submitCheckInInspector({
          vehRecid: Number(params.invRecId),
          ckInBy: inspectorId === 0 ? currentInspection.ckInBy : inspectorId,
          ckInDate: inspectionDate?.toISOString(),
        });
        getCurrentInspection();
      } else if (params['*'] === 'vehicleOptions') {
        await inventoryService.submitOptionsInspector({
          vehRecid: Number(params.invRecId),
          optBy: inspectorId === 0 ? currentInspection.optBy : inspectorId,
          optDate: inspectionDate?.toISOString(),
        });
        getCurrentInspection();
      } else {
        throw new Error('Invalid list choice (valid options: CheckIn, Options)');
      }
    } catch (e) {
      throw new Error('Unable to submit inspection data.');
    }
  };

  useEffect(() => {
    currentName(currentInspection);
  }, [currentInspection]);

  return (
    <GridToolbar>
      <div className={styles.inspectorButton}>
        <div className={styles.columnSettings}>
          <DropdownInput
            defaultValue={currentName(currentInspection)}
            data={inspectorList}
            dataItemKey="recId"
            textField="shortName"
            onChange={handleInspectorSelection}
          />
        </div>
      </div>
      <MuiKendoDatePicker
        sx={{ width: '170px', fontSize: '14px' }}
        label={`Inspection Date`}
        value={inspectionDate}
        onChange={handleDateSelect}
      />
      <Button secondary label="Submit" themeColor="dark" onClick={handleSubmit} />
    </GridToolbar>
  );
};

export default InspectorSelector;
