import { FC } from "react";
import { useParams } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import BackLink from "@/mui/components/buttons/BackLink";
// components
import SaleDetailsRouter from "./SaleDetailsRouter";
import SalesDetailNavTabs from "./SalesDetailNavTabs";
// state
import { useSalesSelector } from "../../salesSlice";
// style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

/** @deprecated convert to navtabs */
const SaleDetailsContainer: FC = () => {
  const params = useParams();
  /** @deprecated use common-convention: `new` */
  const newSale = params.id === "newSale";
  const saleData = useSalesSelector((s) => s.saleData);
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);

  const headerText = [
    saleData.sale?.stocknum ? `#${saleData.sale?.stocknum}` : null,
    saleData.sale?.vehYMM,
    saleData.sale?.salestatus,
    saleData.sale?.saletype,
    saleData.sale?.bNames,
  ]
    .filter((titlePiece) => !!titlePiece)
    .join(" - ");

  return (
    <Grid container direction="column" sx={{ p: 4, pt: 0, flex: 1, width: "100%" }}>
      <BackLink />

      <Grid
        container
        direction="row"
        sx={{
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          alignItems: "center",
          borderBottom: "4px solid #e6e6e6",
          background: BG_HIGHLIGHT,
        }}
      >
        {!newSale && (
          <Grid component="h1" sx={{ m: 0, py: 1.5, px: 4.5, fontSize: 24, fontWeight: 700 }}>
            {headerText}
          </Grid>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        overflow="hidden"
        sx={{ border: `1px solid ${BG_HIGHLIGHT}`, borderRadius: 1 }}
      >
        {!saleDataLoading && <SalesDetailNavTabs />}

        <Grid container flex={1} overflow="auto">
          <SaleDetailsRouter />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SaleDetailsContainer;
