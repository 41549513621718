import dayjs from 'dayjs';
import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// state
import { useSalesViewCtx } from '@/features/Sales/SalesViewProvider';
import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
// utils
import { roundToTwoDecimals } from '@/utils/helpers/general';
import { getsalestax, reCalcFinanceQuote } from '@/utils/helpers/financeTerms';
// interfaces
import { SpecialPaymentPlan } from '@/features/Sales/components/salesTabs/terms/financeTerm/interfaces';

const formDefaultValues = {
  dateOfQuote: '',
  fullName: '',
  phoneNumber: '',
  salesPrice: 0,
  salesTax: 0,
  license: 0,
  title: 0,
  registration: 0,
  customField1: 0,
  customField2: 0,
  customField3: 0,
  customField4: 0,
  customField5: 0,
  customField6: 0,
  serviceContract: 0,
  creditInsurance: 0,
  creditDisability: 0,
  totalSalePrice: 0,
  tradeAllowance: 0,
  tradePayoff: 0,
  depositDown: 0,
  cashOnDelivery: 0,
  totalDown: 0,
  interestRate: 0,
  amountFinanced: 0,
  schedule: 'Weekly',
  dateStarted: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  term: 0,
  amount: 0,
  numberOfPayments: 258,
  final: 0,
  finalDueOn: '',
  financeCharge: 0,
  totalPayments: 0,
};

/** @deprecated convert to context */
export const useFinance = () => {
  const setIsLoading = useSalesViewCtx((s) => s.setIsLoading);
  const isLoading = useSalesViewCtx((s) => s.isLoading);
  const { quoteData, dealData, setDealData, isQuoteInfoLoading } = useDealsQuotesDetailCtx(
    (s) => s
  );

  const [specialPaymentPlanList, setSpecialPaymentPlanList] = useState<SpecialPaymentPlan[]>([]);
  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [showFullFinanceOptions, setShowFullFinanceOptions] = useState(false);
  const [customField6Name, setCustomField6Name] = useState('');
  const [isQuoteDataLoading, setIsQuoteDataLoading] = useState(true);

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: formDefaultValues,
  });
  const financeSaleType = 'Retail Sale';
  const financeData = useMemo(
    () => quoteData?.find((value) => value.stype === financeSaleType),
    [quoteData]
  );
  const salesPrice = watch('salesPrice');
  const license = watch('license');
  const title = watch('title');
  const registration = watch('registration');
  const customField1 = watch('customField1');
  const customField2 = watch('customField2');
  const customField3 = watch('customField3');
  const customField4 = watch('customField4');
  const customField5 = watch('customField5');
  const customField6 = watch('customField6');
  const serviceContract = watch('serviceContract');
  const tradeAllowance = watch('tradeAllowance');
  const tradePayoff = watch('tradePayoff');
  const depositDown = watch('depositDown');
  const cashOnDelivery = watch('cashOnDelivery');
  const totalDown = watch('totalDown');
  const interestRate = watch('interestRate');
  const schedule = watch('schedule');
  const dateStarted = watch('dateStarted');
  const term = watch('term');
  const amount = watch('amount');
  const numberOfPayments = watch('numberOfPayments');
  const totalPayments = watch('totalPayments');
  const dateOfQuote = watch('dateOfQuote');
  const creditDisability = watch('creditDisability');
  const creditInsurance = watch('creditInsurance');
  const final = watch('final');
  const finalDueOn = watch('finalDueOn');
  const financeCharge = watch('financeCharge');

  const addSpecialPaymentPlan = useForm({
    defaultValues: {
      numberOfPayments: 0,
      paymentAmount: 0,
      beginningDate: '',
      schedule: 'Weekly',
    },
    mode: 'onChange',
  });

  const submitSpecialPaymentPlan = (formData: {
    numberOfPayments: number;
    paymentAmount: number;
    beginningDate: string;
    schedule: string;
  }) => {
    const { numberOfPayments, paymentAmount, beginningDate, schedule } = formData;
    if (specialPaymentPlanList.length >= 4) {
      toast.error('Cannot have more than 4 special payment plans');
    } else {
      let newSpecialPaymentPlanList = specialPaymentPlanList;
      setSpecialPaymentPlanList((prevList) => {
        newSpecialPaymentPlanList = [
          ...prevList,
          {
            numberOfPayments: numberOfPayments,
            amount: paymentAmount,
            dateStarted: dayjs(beginningDate).format('YYYY-MM-DD'),
            schedule: schedule,
            selectedIndex: -1,
            hasValue: true,
          },
        ];
        onFieldBlur(undefined, newSpecialPaymentPlanList);
        return newSpecialPaymentPlanList;
      });
    }
  };

  const onFieldBlur = (
    scheduleValue?: string,
    specialPaymentPlanListValue?: SpecialPaymentPlan[],
    changeTerm?: boolean,
    changeAmount?: boolean
  ) => {
    const addon1 = Math.max(0, license);
    const addon2 = Math.max(0, title);
    const addon3 = Math.max(0, registration);
    const addon4 = Math.max(0, customField1);
    const addon5 = Math.max(0, customField2);
    const addon6 = Math.max(0, customField3);
    const addon7 = Math.max(0, customField4);
    const addon8 = Math.max(0, customField5);
    const addon9 = Math.max(0, customField6);
    const addon10 = Math.max(0, serviceContract);
    const stax1 = financeData!.staX1!;
    const stax2 = financeData!.staX2!;
    const stax3 = financeData!.staX3!;
    const stax4 = financeData!.staX4!;
    const stax5 = financeData!.staX5!;
    const stax6 = financeData!.staX6!;
    const stax7 = financeData!.staX7!;
    const stax8 = financeData!.staX8!;
    const stax9 = financeData!.staX9!;
    const stax10 = financeData!.staX10!;
    const ctax1 = financeData!.cityTaX1!;
    const ctax2 = financeData!.cityTaX2!;
    const ctax3 = financeData!.cityTaX3!;
    const ctax4 = financeData!.cityTaX4!;
    const ctax5 = financeData!.cityTaX5!;
    const ctax6 = financeData!.cityTaX6!;
    const ctax7 = financeData!.cityTaX7!;
    const ctax8 = financeData!.cityTaX8!;
    const ctax9 = financeData!.cityTaX9!;
    const ctax10 = financeData!.cityTaX10!;
    const ltax1 = financeData!.locTaX1!;
    const ltax2 = financeData!.locTaX2!;
    const ltax3 = financeData!.locTaX3!;
    const ltax4 = financeData!.locTaX4!;
    const ltax5 = financeData!.locTaX5!;
    const ltax6 = financeData!.locTaX6!;
    const ltax7 = financeData!.locTaX7!;
    const ltax8 = financeData!.locTaX8!;
    const ltax9 = financeData!.locTaX9!;
    const ltax10 = financeData!.locTaX10!;
    const otax1 = financeData!.countyTaX1!;
    const otax2 = financeData!.countyTaX2!;
    const otax3 = financeData!.countyTaX3!;
    const otax4 = financeData!.countyTaX4!;
    const otax5 = financeData!.countyTaX5!;
    const otax6 = financeData!.countyTaX6!;
    const otax7 = financeData!.countyTaX7!;
    const otax8 = financeData!.countyTaX8!;
    const otax9 = financeData!.countyTaX9!;
    const otax10 = financeData!.countyTaX10!;

    const staterate = financeData!.staterate!;
    const stateon = financeData!.stateon!;
    const statemin = financeData!.statemin!;
    const statemax = financeData!.statemax!;

    const countyrate = financeData!.countyrate!;
    const countyon = financeData!.countyon!;
    const countymin = financeData!.countymin!;
    const countymax = financeData!.countymax!;

    const cityrate = financeData!.cityrate!;
    const cityon = financeData!.cityon!;
    const citymin = financeData!.citymin!;
    const citymax = financeData!.citymax!;

    const locrate = financeData!.locrate!;
    const locon = financeData!.locon!;
    const locmin = financeData!.locmin!;
    const locmax = financeData!.locmax!;

    const statesalestax = getsalestax(
      salesPrice,
      tradeAllowance,
      staterate,
      statemax,
      statemin,
      stateon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      stax1,
      stax2,
      stax3,
      stax4,
      stax5,
      stax6,
      stax7,
      stax8,
      stax9,
      stax10
    );
    const countysalestax = getsalestax(
      salesPrice,
      tradeAllowance,
      countyrate,
      countymax,
      countymin,
      countyon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      otax1,
      otax2,
      otax3,
      otax4,
      otax5,
      otax6,
      otax7,
      otax8,
      otax9,
      otax10
    );
    const citysalestax = getsalestax(
      salesPrice,
      tradeAllowance,
      cityrate,
      citymax,
      citymin,
      cityon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ctax1,
      ctax2,
      ctax3,
      ctax4,
      ctax5,
      ctax6,
      ctax7,
      ctax8,
      ctax9,
      ctax10
    );
    const locsalestax = getsalestax(
      salesPrice,
      tradeAllowance,
      locrate,
      locmax,
      locmin,
      locon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ltax1,
      ltax2,
      ltax3,
      ltax4,
      ltax5,
      ltax6,
      ltax7,
      ltax8,
      ltax9,
      ltax10
    );
    const salesTax = statesalestax + countysalestax + citysalestax + locsalestax;
    // const salesTax = 0;
    const totalSalePrice =
      salesPrice +
      license +
      title +
      registration +
      customField1 +
      customField2 +
      customField3 +
      customField4 +
      customField5 +
      customField6 +
      serviceContract +
      salesTax;
    const amountFinanced = totalSalePrice - (totalDown || 0);
    setValue('amountFinanced', totalSalePrice - (totalDown || 0));

    let numPayments = numberOfPayments;
    if (changeTerm) {
      switch (schedule) {
        case 'Weekly':
          numPayments = Math.round(term * 4.3);
          break;
        case 'Bi-Weekly':
          numPayments = Math.round(term * 2.15);
          break;
        case 'Semi-Monthly':
          numPayments = Math.round(term * 2);
          break;
        default:
          numPayments = Math.round(term);
          break;
      }
    }

    let lock = 'Num';
    if (changeAmount && amount !== 0) {
      lock = 'Amt';
    }

    const newSchedule = scheduleValue ? scheduleValue : schedule;

    const newSpecialPaymentPlanList = specialPaymentPlanListValue
      ? specialPaymentPlanListValue
      : specialPaymentPlanList;

    const newData = reCalcFinanceQuote(
      {
        dateOfSale: dateOfQuote,
        salesPrice: salesPrice,
        salesTax: salesTax,
        license: license,
        title: title,
        registration: registration,
        customField1: customField1,
        customField2: customField2,
        customField3: customField3,
        customField4: customField4,
        customField5: customField5,
        customField6: customField6,
        serviceContract: serviceContract,
        creditInsurance: creditInsurance,
        creditDisability: creditDisability,
        totalSalePrice: totalSalePrice,
        tradeAllowance: tradeAllowance,
        tradePayoff: tradePayoff,
        depositDown: depositDown,
        cashOnDelivery: cashOnDelivery,
        totalDown: totalDown,
        interestRate: interestRate,
        amountFinanced: amountFinanced,
        schedule: newSchedule,
        dateStarted: dateStarted,
        term: term,
        amount: amount,
        numberOfPayments: numPayments,
        final: final,
        finalDueOn: finalDueOn,
        financeCharge: financeCharge,
        totalPayments: totalPayments,
      },
      newSpecialPaymentPlanList,
      financeData!,
      lock
    );

    setValue('salesPrice', newData!.salesPrice);
    setValue('salesTax', newData!.salesTax);
    setValue('license', newData!.license);
    setValue('title', newData!.title);
    setValue('registration', newData!.registration);
    setValue('customField1', newData!.addon4);
    setValue('customField2', newData!.addon5);
    setValue('customField3', newData!.addon6);
    setValue('customField4', newData!.addon7);
    setValue('customField5', newData!.addon8);
    setValue('customField6', newData!.addon9);
    setValue('serviceContract', newData!.serviceContract);
    setValue('creditInsurance', newData!.crLife);
    setValue('creditDisability', newData!.crDisb);
    setValue('totalSalePrice', newData!.totalSp);
    setValue('cashOnDelivery', newData!.cod);
    setValue('totalDown', newData!.totalDown);
    setValue('amountFinanced', newData!.amountFinanced);
    setValue('term', roundToTwoDecimals(newData!.term));
    setValue('amount', newData!.paymentAmount);
    setValue('numberOfPayments', newData!.numberPayments);
    setValue('final', newData!.finalPayment);
    setValue('finalDueOn', newData!.finalPaymentDue);
    setValue('financeCharge', newData!.financeCharge);
    setValue('totalPayments', newData!.totalOfPayments);
  };

  const handleReset = () => {
    reset({
      dateOfQuote: dayjs().format('YYYY-MM-DD'),
      salesPrice: financeData?.deF_SP ?? 0,
      salesTax: 0,
      license: financeData?.pricE1 ?? 0,
      title: financeData?.pricE2 ?? 0,
      registration: financeData?.pricE3 ?? 0,
      customField1: financeData?.pricE4 ?? 0,
      customField2: financeData?.pricE5 ?? 0,
      customField3: financeData?.pricE6 ?? 0,
      customField4: financeData?.pricE7 ?? 0,
      customField5: financeData?.pricE8 ?? 0,
      customField6: financeData?.pricE9 ?? 0,
      serviceContract: financeData?.pricE10 ?? 0,
      creditInsurance: 0,
      creditDisability: 0,
      totalSalePrice: 0,
      tradeAllowance: 0,
      tradePayoff: 0,
      cashOnDelivery: financeData?.def_Down ?? 0,
      depositDown: 0,
      totalDown: 0,
      amountFinanced: 0,
      interestRate: financeData?.def_APR ? financeData.def_APR / 100 : 0,
      dateStarted: dayjs().add(7, 'day').format('YYYY-MM-DD'),
      schedule: 'Weekly',
      term: 59.26,
      amount: financeData?.def_PM ?? 0,
      numberOfPayments: 258,
      finalDueOn: '',
      final: 0,
      financeCharge: 0,
      totalPayments: 0,
    });
  };

  useEffect(() => {
    if (!quoteData) return;
    setCustomField1Name(financeData?.adD4 || '');
    setCustomField2Name(financeData?.adD5 || '');
    setCustomField3Name(financeData?.adD6 || '');
    setCustomField4Name(financeData?.adD7 || '');
    setCustomField5Name(financeData?.adD8 || '');
    setCustomField6Name(financeData?.adD9 || '');

    reset({
      fullName: dealData?.customerName ?? '',
      phoneNumber: dealData?.customerPhone ?? '',
      dateOfQuote: dealData?.quoteDate
        ? dayjs(dealData?.quoteDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      salesPrice: dealData?.salesPrice ?? financeData?.deF_SP ?? 0,
      salesTax: dealData?.salesTax ?? 0,
      license: dealData?.license ?? financeData?.pricE1 ?? 0,
      title: dealData?.title ?? financeData?.pricE2 ?? 0,
      registration: dealData?.registration ?? financeData?.pricE3 ?? 0,
      customField1: dealData?.customField1 ?? financeData?.pricE4 ?? 0,
      customField2: dealData?.customField2 ?? financeData?.pricE5 ?? 0,
      customField3: dealData?.customField3 ?? financeData?.pricE6 ?? 0,
      customField4: dealData?.customField4 ?? financeData?.pricE7 ?? 0,
      customField5: dealData?.customField5 ?? financeData?.pricE8 ?? 0,
      customField6: dealData?.customField6 ?? financeData?.pricE9 ?? 0,
      serviceContract: dealData?.serviceContract ?? financeData?.pricE10 ?? 0,
      creditInsurance: dealData?.credityInsurance ?? 0,
      creditDisability: dealData?.creditDisability ?? 0,
      totalSalePrice: dealData?.totalSalesPrice ?? 0,
      tradeAllowance: dealData?.tradeAllowance ?? 0,
      tradePayoff: dealData?.tradePayoff ?? 0,
      cashOnDelivery: dealData?.cashOnDelivery ?? financeData?.def_Down ?? 0,
      depositDown: dealData?.depositDown ?? 0,
      totalDown: dealData?.totalDown ?? 0,
      amountFinanced: dealData?.amountFinanced ?? 0,
      interestRate:
        dealData?.interestRate ?? (financeData?.def_APR ? financeData.def_APR / 100 : 0),
      dateStarted: dealData?.firstPaymentDue
        ? dayjs(dealData?.firstPaymentDue, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : dayjs().add(7, 'day').format('YYYY-MM-DD'),
      schedule: dealData?.schedule ?? 'Weekly',
      term: dealData?.term ?? 99,
      amount: dealData?.paymentAmount ?? financeData?.def_PM ?? 0,
      numberOfPayments: dealData?.numberOfPayments ?? 258,
      finalDueOn: dealData?.finalPaymentDue
        ? dayjs(dealData?.finalPaymentDue, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : '',
      final: dealData?.finalPayment ?? 0,
      financeCharge: dealData?.financeCharge ?? 0,
      totalPayments: dealData?.totalPayments ?? 0,
    });

    const specialPayment1 = {
      amount: dealData?.specialPaymentAmount1,
      dateStarted: dealData?.specialPaymentBeginningDate1,
      numberOfPayments: dealData?.specialPaymentNum1,
      schedule: dealData?.specialPaymentSchedule1,
      selectedIndex: 1,
      hasValue:
        !!dealData?.specialPaymentAmount1 &&
        !!dealData?.specialPaymentBeginningDate1 &&
        !!dealData?.specialPaymentNum1 &&
        !!dealData?.specialPaymentSchedule1,
    } as SpecialPaymentPlan;

    const specialPayment2 = {
      amount: dealData?.specialPaymentAmount2,
      dateStarted: dealData?.specialPaymentBeginningDate2,
      numberOfPayments: dealData?.specialPaymentNum2,
      schedule: dealData?.specialPaymentSchedule2,
      selectedIndex: 2,
      hasValue:
        !!dealData?.specialPaymentAmount2 &&
        !!dealData?.specialPaymentBeginningDate2 &&
        !!dealData?.specialPaymentNum2 &&
        !!dealData?.specialPaymentSchedule2,
    } as SpecialPaymentPlan;

    const specialPayment3 = {
      amount: dealData?.specialPaymentAmount3,
      dateStarted: dealData?.specialPaymentBeginningDate3,
      numberOfPayments: dealData?.specialPaymentNum3,
      schedule: dealData?.specialPaymentSchedule3,
      selectedIndex: 3,
      hasValue:
        !!dealData?.specialPaymentAmount3 &&
        !!dealData?.specialPaymentBeginningDate3 &&
        !!dealData?.specialPaymentNum3 &&
        !!dealData?.specialPaymentSchedule3,
    } as SpecialPaymentPlan;

    const specialPayment4 = {
      amount: dealData?.specialPaymentAmount4,
      dateStarted: dealData?.specialPaymentBeginningDate4,
      numberOfPayments: dealData?.specialPaymentNum4,
      schedule: dealData?.specialPaymentSchedule4,
      selectedIndex: 4,
      hasValue:
        !!dealData?.specialPaymentAmount4 &&
        !!dealData?.specialPaymentBeginningDate4 &&
        !!dealData?.specialPaymentNum4 &&
        !!dealData?.specialPaymentSchedule4,
    } as SpecialPaymentPlan;

    const tempSpecialPaymentsList = [
      specialPayment1,
      specialPayment2,
      specialPayment3,
      specialPayment4,
    ] as SpecialPaymentPlan[];

    setSpecialPaymentPlanList(tempSpecialPaymentsList.filter((sp) => sp.hasValue));
    setIsQuoteDataLoading(false);
  }, [quoteData, dealData]);

  /* Way to initially calculate all data after state loads since onFieldBlur() 
  calculates based on the form state, couldn't think of a better solution so lmk if you have one */
  useEffect(() => {
    if (
      customField1Name ||
      customField2Name ||
      customField3Name ||
      customField4Name ||
      customField5Name ||
      customField6Name
    ) {
      onFieldBlur();
    }
  }, [customField1Name]);

  useEffect(() => {
    setValue('totalDown', cashOnDelivery + tradeAllowance - tradePayoff + depositDown);
  }, [cashOnDelivery, tradeAllowance, tradePayoff, depositDown]);

  // reset form after successful submission
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(watch(), { keepDirty: false });
    }
  }, [isSubmitSuccessful]);

  return {
    control,
    reset,
    watch,
    setValue,
    addSpecialPaymentPlan,
    specialPaymentPlanList,
    setSpecialPaymentPlanList,
    submitSpecialPaymentPlan,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    customField6Name,
    showFullFinanceOptions,
    setShowFullFinanceOptions,
    onFieldBlur,
    handleReset,
    handleSubmit,
    isDirty,
    isLoading,
    errors,
    setIsLoading,
    dealData,
    setDealData,
    isQuoteDataLoading,
    isQuoteInfoLoading,
  };
};
