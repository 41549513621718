import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import { Button, DropdownInput, TextArea, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAppDispatch } from "@/store/store";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { getAccountInformation } from "@/features/Accounts/accountActionCreators";
// services
import { accountsService } from "@/services/accountsService";
import { paymentService } from "@/services/paymentService";
// utils
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { genericRequired } from "@/utils/helpers/formValidation";
// interfaces
import { EmployeeField } from '@/interfaces/System';
import { ChargeOffForm, ChargeOffInformation, ChargeOffPayload } from "@/interfaces";
import { ColTypeCode } from "@/enums/general";
// style
import styles from "../accountsMainPanel/AccountsMainPanel.module.scss";

export const ChargeOff: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [chargeOffInfo, setChargeOffInfo] = useState<ChargeOffInformation>();
  const [employees, setEmployees] = useState<EmployeeField[]>([]);
  const { compId, userId } = useAuthSelector((s) => s);
  const { accountInformation } = useAccountSelector((s) => s);
  const params = useParams();
  const colRecId = Number(params.colRecId);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ChargeOffForm>();

  const {
    type,
    repoCharges,
    towing,
    storage,
    disposalCost,
    repairs,
    other,
    vehicleAcv,
    chargeOffAmt,
    totalRepoExpense,
    netAmountDue,
    totalRepoFees,
    payoff,
  } = useWatch({ control });

  const isRepo = !!chargeOffInfo?.repoVehRecId;
  const isLease = accountInformation?.colType === ColTypeCode.enum.LS;
  const isLoan = accountInformation?.colType === ColTypeCode.enum.FS;
  const isLoanInsuranceType = type?.type === "Insurance" && isLoan;

  const submit = async (data: ChargeOffForm) => {
    try {
      setSubmitting(true);
      // strip off fields that are not needed in the payload, or that need to be mapped to a different type
      const {
        employee,
        type,
        principalBalance,
        miscBalance,
        chargeOffAmt,
        netChargeOff,
        lessAcv,
        netAmountDue,
        ...rest
      } = data;
      const payload: ChargeOffPayload = {
        userId: employee!.recId,
        type: type!.type,
        ...rest,
      };
      await accountsService.postChargeOff(payload);
      toast.success(isLease ? "Lease terminated" : "Account charged off");
      dispatch(getAccountInformation(colRecId));
      navigate("..");
    } finally {
      setSubmitting(false);
    }
  };

  const initData = async () => {
    setLoading(true);
    try {
      const chargeOffInfoRes = await accountsService.getChargeOffInformation(colRecId);
      setChargeOffInfo(chargeOffInfoRes);
      const employeesRes = await paymentService.getUsersByCompanyId(compId!);
      setEmployees(employeesRes);
      const currentEmployee = employeesRes.find((e) => e.recId === userId);
      if (currentEmployee) {
        setValue("employee", currentEmployee);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (!chargeOffInfo || !employees || !employees.length) return;

    // Other things (maybe just take possession?) can set a chgOffCat onto the collections record
    // If one exists, we need to pre-populate it
    const existingChgOffCat =
      !!chargeOffInfo.chgOffCat &&
      chargeOffInfo.coRepoTypes.find((crt) => crt.type === chargeOffInfo.chgOffCat);

    const resetFields: ChargeOffForm = {
      colRecId,
      date: formatDate(new Date(), { pattern: DateFormat.DateInput }),
      type: existingChgOffCat || null,
      password: "",
      employee: employees.find((emp) => emp.recId === userId) ?? null,
      note: "",
      vehicleAcv: chargeOffInfo.repoSp ?? 0,
      totalRepoExpense: chargeOffInfo.totalRepoE ?? 0,
      payoff: chargeOffInfo.payOff ?? 0,
      repoCharges: chargeOffInfo.drExp ?? 0,
      towing: chargeOffInfo.towing ?? 0,
      storage: chargeOffInfo.storage ?? 0,
      disposalCost: chargeOffInfo.disposalCost ?? 0,
      repairs: chargeOffInfo.repairs ?? 0,
      other: chargeOffInfo.other ?? 0,
      totalRepoFees: chargeOffInfo.totalRepoFees ?? 0,
      deferredBalance: chargeOffInfo.defBal ?? 0,
      principalBalance: chargeOffInfo.principalBalance ?? 0,
      miscBalance: chargeOffInfo.miscBalance ?? 0,
      chargeOffAmt: (chargeOffInfo.principalBalance ?? 0) + (chargeOffInfo.miscBalance ?? 0),
      netChargeOff:
        (chargeOffInfo.principalBalance ?? 0) +
        (chargeOffInfo.miscBalance ?? 0) -
        (chargeOffInfo.repoSp ?? 0) +
        (chargeOffInfo.totalRepoE ?? 0),
      lessAcv: chargeOffInfo.repoSp ?? 0,
      netAmountDue: (chargeOffInfo.payOff ?? 0) - (chargeOffInfo.repoSp ?? 0),
    };

    // todo store resetFields in state in case we need to reset the form

    reset(resetFields);
  }, [chargeOffInfo, employees]);

  useEffect(() => {
    if (!isLoan) return;
    setValue("lessAcv", vehicleAcv!);
    setValue("netChargeOff", chargeOffAmt! - vehicleAcv! + totalRepoExpense!);
    setValue(
      "totalRepoFees",
      repoCharges! + towing! + storage! + disposalCost! + repairs! + other!
    );
    setValue("deferredBalance", netAmountDue! + totalRepoFees!);
    setValue("netAmountDue", payoff! - vehicleAcv!);
  }, [
    repoCharges,
    towing,
    storage,
    disposalCost,
    repairs,
    other,
    vehicleAcv,
    chargeOffAmt,
    totalRepoExpense,
    netAmountDue,
    totalRepoFees,
    payoff,
  ]);

  return (
    <AccountsMainPanel navBarTitle={isLease ? "Terminate Lease" : "Charge Off"} loading={loading}>
      {!chargeOffInfo ? (
        <div>Unable to load charge off information for this account</div>
      ) : (
        <form onSubmit={handleSubmit(submit)} className={styles.columns}>
          <div className={styles.column}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DateInput
                  readOnly
                  label={isLease ? "Terminated Date" : "Charge-Off Date"}
                  {...field}
                />
              )}
            />
            <Controller
              name="principalBalance"
              control={control}
              render={({ field }) => (
                <CurrencyInput readOnly label="Principal Balance" {...field} />
              )}
            />
            <Controller
              name="miscBalance"
              control={control}
              render={({ field }) => <CurrencyInput readOnly label="Sidenote Balance" {...field} />}
            />
            <Controller
              name="chargeOffAmt"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  readOnly
                  label={isLease ? "Terminated Amt" : "Charge-Off Amt"}
                  {...field}
                />
              )}
            />
            {(isRepo || isLoanInsuranceType) && (
              <Controller
                name="vehicleAcv"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={isLoanInsuranceType ? "Insurance Amt" : "Vehicle ACV"}
                    {...field}
                  />
                )}
              />
            )}

            {isRepo && (
              <Controller
                name="totalRepoExpense"
                control={control}
                render={({ field }) => (
                  <CurrencyInput readOnly={!isLease} label="Repo expenses" {...field} />
                )}
              />
            )}

            {(isRepo || isLoanInsuranceType) && (
              <Controller
                name="netChargeOff"
                control={control}
                render={({ field }) => <CurrencyInput readOnly label="Net Charge-Off" {...field} />}
              />
            )}

            <Controller
              name="type"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <DropdownInput
                  required
                  label="Type"
                  data={chargeOffInfo?.coRepoTypes}
                  dataItemKey="recId"
                  textField="type"
                  errors={errors.type?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="employee"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <DropdownInput
                  required
                  label="Employee"
                  data={employees}
                  dataItemKey="recId"
                  textField="shortName"
                  errors={errors.employee?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextInput
                  label="Password"
                  required
                  errors={errors.password?.message}
                  type="password"
                  {...field}
                />
              )}
            />
            <Controller
              name="note"
              control={control}
              render={({ field }) => <TextArea label="Notes" rows={3} {...field} />}
            />
          </div>
          <div className={styles.column}>
            {(isRepo || isLoanInsuranceType) && (
              <>
                <Controller
                  name="payoff"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly label="Pay Off" {...field} />}
                />
                <Controller
                  name="lessAcv"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly label="Less ACV" {...field} />}
                />
                <Controller
                  name="netAmountDue"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput readOnly label="Net Amount Due" {...field} />
                  )}
                />
                <Controller
                  name="repoCharges"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Repo Charges" {...field} />}
                />
                <Controller
                  name="towing"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Towing" {...field} />}
                />
                <Controller
                  name="storage"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Storage" {...field} />}
                />
                <Controller
                  name="disposalCost"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Disposal Cost" {...field} />}
                />
                <Controller
                  name="repairs"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Repairs" {...field} />}
                />
                <Controller
                  name="other"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Other" {...field} />}
                />
                <Controller
                  name="totalRepoFees"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly label="Total" {...field} />}
                />
                <Controller
                  name="deferredBalance"
                  control={control}
                  render={({ field }) => <CurrencyInput readOnly label="Def. Balance" {...field} />}
                />
              </>
            )}
            <Spacer expand />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button label="Submit" loading={submitting} fullWidth={false} />
            </div>
          </div>
        </form>
      )}
    </AccountsMainPanel>
  );
};
