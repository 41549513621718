// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
// components
import ButtonLinkCell from '@/mui/components/dataTable/cells/ButtonLinkCell';
// utils
import { datetimeCellFmt, datetimeCellGetter } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { RoleDb } from '@/features/Settings/interfaces/role';

export const tableColumns: readonly MuiColumnDef<RoleDb>[] = [
  { field: 'templateName', headerName: 'Role Name', flex: 1, minWidth: 120 },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    minWidth: 170,
    type: 'datetime',
    valueFormatter: datetimeCellFmt,
    valueGetter: datetimeCellGetter,
    align: 'left',
  },
  {
    field: 'recId',
    headerName: 'Actions',
    renderCell: ButtonLinkCell,
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
  },
];
