import { FC } from 'react';
// components
import EmailReportsViewButton from './EmailReportsViewButton';
// utils
import { useReportsViewCtx } from '../state/view';
import { ReportsSubviews } from '../viewInfo';
// style
import styles from '../Reports.module.scss';

const HeaderTitle: FC = () => {
  const subview = useReportsViewCtx((s) => s.subview);

  const title = `Reports${subview === ReportsSubviews.ers ? ' - Email Report Schedules' : ''}`;
  return <div className={styles.title}>{title}</div>;
};

/** ### Header component for "Reports" subview
 *
 * Contains:
 * - Title
 * - Subview selector
 */
const Header: FC = () => {
  return (
    <div className={styles.header}>
      <HeaderTitle />

      <div className={styles.headerRight}>
        <EmailReportsViewButton />
      </div>
    </div>
  );
};

export default Header;
