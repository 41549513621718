import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
// kendo
import DefaultDateCell from "@/components/kendoCells/DateCell";
import { Grid, GridColumn } from "@progress/kendo-react-all";
import { Spacer } from "@/components/spacer/Spacer";
import { Column } from "@/components/table/TableInterface";
import { AccountsMainPanel } from "./components/accountsMainPanel/AccountsMainPanel";
import TextCell from "@/components/kendoCells/TextCell";
// utils
import { accountsService } from "@/services/accountsService";
import { DateFormat, formatCurrency } from "@/utils/helpers/general";
import { getRouteParamNum } from "@/utils/routing/formatting";
import useReq from "@/utils/useReq";
// interfaces
import { AccountFullInformation as AcctFullInfo } from "@/interfaces/Accounts";
// style
import styles from "@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel.module.scss";

class AcctInfo {
  header: string;
  value: number | string;
  constructor(
    header: string,
    val:
      | string
      | null
      | undefined
      | {
          date?: Dayjs | string | null;
          currency?: number | null;
          normal?: any | null;
          number?: number | null;
        }
  ) {
    this.header = header;
    if (typeof val === "string" || !val) {
      this.value = val ?? "";
    } else if (val.date) this.value = dayjs(val.date).format(DateFormat.Default);
    else if (val.currency) this.value = formatCurrency(val.currency || 0);
    else if (val.number) this.value = val.number.toLocaleString();
    else {
      this.value = val.normal ?? "";
    }
  }
}
const cellStyle = { padding: "14px 0", textAlign: "center" };

const AccountInfoRow: FC<{ data: AcctInfo[] }> = ({ data }) => (
  <table className={styles.accountInfoRow}>
    <thead className={styles.accountInfoRowHeader}>
      <tr>
        {data.map((data) => (
          <th className={styles.cellData}>{data.header.toUpperCase()}</th>
        ))}
      </tr>
    </thead>
    <tbody className={styles.accountInfoRowBody}>
      <tr>
        {data.map((data) => (
          <td className={styles.cellData}>{data.value}</td>
        ))}
      </tr>
    </tbody>
  </table>
);

const CurrencyCell: FC<any> = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td {...props} className={styles.cellTableData} style={cellStyle}>
      {formatCurrency(value || 0)}
    </td>
  );
};

const DateCell: typeof DefaultDateCell = (props) => (
  <DefaultDateCell {...props} dtFormat={DateFormat.Default} />
);

const LeaseInformationRows: FC<{ acctInfo: AcctFullInfo | null }> = ({ acctInfo }) => {
  if (!acctInfo) return <></>;

  const leaseRowData1: AcctInfo[] = [
    new AcctInfo("Lease Payoff", { currency: acctInfo.leasePayoff }),
    new AcctInfo("Estimated Vehicle Sale", { currency: acctInfo.estimatedVehicleSale }),
    new AcctInfo("Gross Lease Buyout", { currency: acctInfo.grossLeaseBuyout }),
    new AcctInfo("Security Deposit", { currency: acctInfo.securityDeposit }),
    new AcctInfo("Net Lease Buyout", { currency: acctInfo.netLeaseBuyout }),
  ];

  const leaseRowData2: AcctInfo[] = [
    new AcctInfo("Date Sold", { date: dayjs(acctInfo.dateSold).utc() }),
    new AcctInfo("Vehicle Agreed Val.", { currency: acctInfo.vehicleAgreedValue }),
    new AcctInfo("Gross Cap. Cost", { currency: acctInfo.grossCapitalizedCost }),
    new AcctInfo("Cap. Cost Reduction", { currency: acctInfo.capitalizedCostReduction }),
    new AcctInfo("Adj. Cap Cost", { currency: acctInfo.adjustedCapitalizedCost }),
    new AcctInfo("Residual Value", { currency: acctInfo.residualValue }),
    new AcctInfo("Total Base Pmts.", { currency: acctInfo.totalBasePayments }),
  ];

  const leaseRowData3: AcctInfo[] = [
    new AcctInfo("Lease Term", `${acctInfo.termTotal ?? 0}/${acctInfo.termPaid ?? 0}`),
    new AcctInfo("Lease Payments", `${acctInfo.paymentsMade ?? 0}/${acctInfo.paymentsTotal ?? 0}`),
    new AcctInfo("Base Payments", { currency: acctInfo.basePayments }),
    new AcctInfo("Sales/Use Tax", { currency: acctInfo.salesUseTax }),
    new AcctInfo("Total Payment", { currency: acctInfo.totalPayment }),
    new AcctInfo("Mileage Per Year", acctInfo.mileagePerYear?.toLocaleString() ?? ""),
    new AcctInfo("Excess Mileage Charge", acctInfo.excessMileageCharge?.toLocaleString() ?? ""),
    new AcctInfo("Termination Fee", { currency: acctInfo.terminationFee }),
    new AcctInfo("Disposition Fee", { currency: acctInfo.dispositionFee }),
    new AcctInfo("Purchase Option Fee", { currency: acctInfo.purchaseOptionFee }),
  ];

  const leaseRowData4: AcctInfo[] = [
    new AcctInfo("Depreciation Balance", { currency: acctInfo.depreciationBalance }),
    new AcctInfo("Rent Balance", { currency: acctInfo.rentBalance }),
    new AcctInfo("Down Pmt. Due", { currency: acctInfo.downPaymentDue }),
    new AcctInfo("Def. Down Pmt. Due", { currency: acctInfo.deferredDownPaymentDue }),
    new AcctInfo("Sidenote Due", { currency: acctInfo.sidenoteDue }),
    new AcctInfo("CPI Due", { currency: acctInfo.cpiDue }),
    new AcctInfo("Sales Tax Balance", { currency: acctInfo.salesTaxDue }),
    new AcctInfo("NSF Fees", { currency: acctInfo.nsfFees }),
    new AcctInfo("Late Fees", { currency: acctInfo.lateFees }),
    new AcctInfo("Total Balance", { currency: acctInfo.totalBalance }),
  ];

  return (
    <>
      <AccountInfoRow data={leaseRowData1} />
      <AccountInfoRow data={leaseRowData2} />
      <AccountInfoRow data={leaseRowData3} />
      <AccountInfoRow data={leaseRowData4} />
    </>
  );
};

const FinanceOrOtherInformationRows: FC<{ acctInfo: AcctFullInfo | null }> = ({ acctInfo }) => {
  if (!acctInfo) return <></>;

  const financeRowData1: AcctInfo[] = [
    new AcctInfo("Date Sold", dayjs(acctInfo.dateSold).utc().format("MM/DD/YYYY")),
    new AcctInfo("Orig. Maturity", dayjs(acctInfo.originalMaturity).utc().format("MM/DD/YYYY")),
    new AcctInfo("Interest Rate", acctInfo.interestRate ? `${acctInfo.interestRate.toFixed(2)}%` : ""),
    new AcctInfo("Accrued Interest", { currency: acctInfo.accruedInterest }),
    new AcctInfo("Payoff", { currency: acctInfo.leasePayoff }),
    new AcctInfo("Per Diem", { currency: acctInfo.perDiem }),
  ];

  const financeRowData2: AcctInfo[] = [
    new AcctInfo("Principal Balance", { currency: acctInfo.principalBalance }),
    new AcctInfo("Interest Balance", { currency: acctInfo.interestBalance }),
    new AcctInfo("Down Pmt. Due", { currency: acctInfo.downPaymentDue }),
    new AcctInfo("Def. Down Pmt. Due", { currency: acctInfo.deferredDownPaymentDue }),
    new AcctInfo("Sidenote Due", { currency: acctInfo.sidenoteDue }),
    new AcctInfo("CPI Due", { currency: acctInfo.cpiDue }),
    new AcctInfo("Sales Tax Balance", { currency: acctInfo.salesTaxDue }),
    new AcctInfo("NSF Fees", { currency: acctInfo.nsfFees }),
    new AcctInfo("Late Fees", { currency: acctInfo.lateFees }),
    new AcctInfo("Total Balance", { currency: acctInfo.totalBalance }),
  ];

  return (
    <>
      <AccountInfoRow data={financeRowData1} />
      <AccountInfoRow data={financeRowData2} />
    </>
  );
};

const AccountInfo: FC = () => {
  const colRecId = getRouteParamNum(useParams().colRecId);

  const { value: acctInfo, ...acctInfoRes } = useReq(async () => {
    if (!colRecId) return;
    return await accountsService.getAccountFullInformation(colRecId);
  });
  const { value: paymentHistory, ...paymentHistoryRes } = useReq(async () => {
    if (!colRecId) return;

    return await accountsService.getPaymentHistory({
      colRecId,
      page: 1,
      perPage: 6,
      sort: [
        {
          field: "PmtDate",
          direction: "DESC",
        },
      ],
    });
  });
  const { value: next6Payments, ...next6PaymentsRes } = useReq(async () => {
    if (!colRecId) return;

    return await accountsService.getNext6Payments(colRecId);
  });

  useEffect(() => {
    acctInfoRes.load();
    paymentHistoryRes.load();
    next6PaymentsRes.load();
  }, [colRecId]);

  const recentPayHistoryColumns: Column[] = [
    { field: "pmtDate", title: "DATE", cells: { data: DateCell } },
    {
      field: "amountDue",
      title: "DUE",
      cell: (props: any) => {
        const { totalApplied, entryType } = props.dataItem;
        return (
          <td {...props} className={styles.cellTableData} style={cellStyle}>
            {formatCurrency(entryType === "New Pmt Due" ? totalApplied : 0 || 0)}
          </td>
        );
      },
    },
    {
      field: "totalReceived",
      title: "PAID",
      cell: (props: any) => {
        const { totalApplied, entryType } = props.dataItem;
        return (
          <td {...props} className={styles.cellTableData} style={cellStyle}>
            {formatCurrency(entryType === "New Pmt Due" ? 0 : totalApplied || 0)}
          </td>
        );
      },
    },
    { field: "entryType", title: "PAYMENT TYPE", cells: { data: TextCell } },
  ];

  const nextPaymentColumns: Column[] = [
    { field: "dueDate", title: "DATE", cells: { data: DateCell } },
    { field: "paymentType", title: "TYPE", cells: { data: TextCell } },
    { field: "paymentBalance", title: "AMOUNT", cells: { data: CurrencyCell } },
  ];

  return (
    <AccountsMainPanel navBarTitle="" loading={acctInfoRes.isLoading}>
      <div className={styles.accountInfo}>
        {acctInfo?.colType === "LS" ? (
          <LeaseInformationRows acctInfo={acctInfo} />
        ) : (
          <FinanceOrOtherInformationRows acctInfo={acctInfo} />
        )}
        <div className={styles.gridContainer}>
          <div className={`${styles.grid} ${styles.leftGrid}`}>
            <h2 className={styles.subHeading}>Next 6 Payments Due</h2>
            <Spacer size={18} />
            <Grid data={next6Payments}>
              {nextPaymentColumns.map((column, index) => (
                <GridColumn
                  key={index}
                  field={column.field}
                  title={column.title ? column.title : " "}
                  cells={column.cells}
                  width={column.width}
                />
              ))}
            </Grid>
          </div>
          <div className={`${styles.grid} ${styles.rightGrid}`}>
            <h2 className={styles.subHeading}>Recent Payment History</h2>
            <Spacer size={18} />
            <Grid data={paymentHistory}>
              {recentPayHistoryColumns.map((column, index) => (
                <GridColumn
                  key={index}
                  field={column.field}
                  title={column.title ? column.title : " "}
                  cells={column.cells}
                  cell={column.cell}
                  width={column.width}
                />
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </AccountsMainPanel>
  );
};

export default AccountInfo;
