import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inventoryService } from '@/services/inventoryService';
import CheckInGrid from './checkInGrid/checkInGrid';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
import { CheckInItem } from '@/interfaces';
import styles from './CheckInOptions.module.scss';

const loadCheckInViewData = async (
  invRecId: number | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setCheckInState: (checkInState: CheckInItem[]) => void,
  setCheckInList: (checkInList: CheckInItem[]) => void
) => {
  if (!invRecId) {
    return;
  }

  try {
    setIsLoading(true);
    const initCheckInList = await inventoryService.getCheckInList(invRecId);
    const validatedCheckInList = initCheckInList.map(
      (c) =>
        new CheckInItem(
          c.recId,
          c.orgId,
          c.locationId,
          c.compId,
          c.vehRecId,
          c.sOrder,
          c.ckItem,
          c.fix,
          c.comments,
          c.fixed,
          c.fixedOn,
          c.fixedById,
          c.fixedByName
        )
    );

    setCheckInState(validatedCheckInList);
    setCheckInList(validatedCheckInList);

    setIsLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const CheckIn = () => {
  const { invRecId } = useParams();
  const setIsLoading = useInventoryDetailCtx((s) => s.setIsLoading);
  const setCheckInState = useInventoryDetailCtx((s) => s.setCheckInState);
  const setCheckInList = useInventoryDetailCtx((s) => s.setCheckInList);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);

  useEffect(() => {
    loadCheckInViewData(Number(invRecId), setIsLoading, setCheckInState, setCheckInList);
  }, [inventoryVehicle.vin]);

  return (
    <div className={styles.container}>
      <div className={styles.vehicleHeaderCard}>
        <span className={styles.subtitle}>Vehicle Check-In</span>
      </div>
      <CheckInGrid />
    </div>
  );
};

export default CheckIn;
