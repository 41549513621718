import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import SectionColumn from '@/mui/components/form/Section/SectionColumn';
// kendo
import { TextInput } from '@/components/inputs/text/TextInput';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
// state
import { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// utils
import { optionalPhoneValidation } from '@/utils/helpers/formValidation';
import { UsaStateCode } from '@/general/regions';

/** ###  */
const InsuranceRightColumn: FC = () => {
  const control = useAcctInsuranceCtx((s) => s.insuranceInfoForm.control);

  return (
    <SectionColumn p={0}>
      <Grid component="h3">Insurance Company</Grid>

      <Controller
        name="insInsuranceCompany"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            label="Insurance Company Name"
            errors={!!fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="insAddress"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput {...field} label="Address" errors={!!fieldState.error?.message} />
        )}
      />

      <Controller
        name="insCity"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput {...field} label="City" errors={!!fieldState.error?.message} />
        )}
      />

      <Controller
        name="insState"
        control={control}
        render={({ field, fieldState }) => (
          <DropdownInput
            {...field}
            label="State"
            suggest
            data={UsaStateCode.options}
            errors={!!fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="insZip"
        control={control}
        rules={{ maxLength: 5, minLength: 5 }}
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            type="number"
            label="Zip"
            errors={fieldState.error?.message && 'Please enter a valid Zip code'}
          />
        )}
      />

      <Controller
        name="insPhone"
        control={control}
        rules={optionalPhoneValidation}
        render={({ field, fieldState }) => (
          <PhoneInput
            {...field}
            label="Phone"
            errors={fieldState.error?.message}
            value={field.value || ''}
          />
        )}
      />

      <Controller
        name="insFax"
        control={control}
        rules={optionalPhoneValidation}
        render={({ field, fieldState }) => (
          <PhoneInput
            {...field}
            label="Company Fax"
            errors={fieldState.error?.message}
            value={field.value || ''}
          />
        )}
      />

      <Controller
        name="insEmail"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput {...field} label="Email Address" errors={fieldState.error?.message} />
        )}
      />

      <Controller
        name="insContact"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput {...field} label="Contact" errors={fieldState.error?.message} />
        )}
      />

      <Controller
        name="insContactPhone"
        control={control}
        rules={optionalPhoneValidation}
        render={({ field, fieldState }) => (
          <PhoneInput
            {...field}
            label="Contact Phone"
            errors={fieldState.error?.message}
            value={field.value || ''}
          />
        )}
      />

      <Controller
        name="insContactFax"
        control={control}
        rules={optionalPhoneValidation}
        render={({ field, fieldState }) => (
          <PhoneInput
            {...field}
            label="Contact Fax"
            errors={fieldState.error?.message}
            value={field.value || ''}
          />
        )}
      />
    </SectionColumn>
  );
};

export default InsuranceRightColumn;
