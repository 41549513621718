import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@/mui/components/buttons/MuiKendoButton";
// components
import PrintPdfButton from "../pdfUtils/PrintPdfButton";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";

/** ###  */
const ButtonGroup: FC<{ isLatestEval: boolean; isDeclinedStatus: boolean }> = ({
  isLatestEval,
  isDeclinedStatus,
}) => {
  const reportsHistory = useAiUnderwritingCtx((s) => s.reportsHistory);
  const hasMultipleAppEvals = reportsHistory.value && reportsHistory.value.length > 1;
  const setIsModalOpen = useAiUnderwritingCtx((s) => s.setIsModalOpen);

  // Event handlers
  const handleOpenModal = () => setIsModalOpen(true);

  const displayPastResultsBtn = () => {
    // Don't render button if [there is only one eval (or none)] OR [parent component is rendered in "Show Previous Evals" modal]
    if (isLatestEval === false || !hasMultipleAppEvals) {
      return <></>;
    }
    return <Button onClick={handleOpenModal}>See past results</Button>;
  };

  return (
    <Grid
      container
      direction="row"
      flex={1}
      justifyContent="end"
      gap={1}
      style={{ paddingRight: "15px" }}
    >
      <PrintPdfButton isInModal={false} isDeclinedStatus={isDeclinedStatus} />
      {displayPastResultsBtn()}
    </Grid>
  );
};

export default ButtonGroup;
