import { FC, ReactNode } from 'react';
import { MdExpandMore } from 'react-icons/md';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DCS_BLUE } from '@/mui/theme/colors';

/** ###  */
const SectionHeader: FC<
  Grid2Props & { isExpandable?: boolean; label?: string; dateLabel?: ReactNode }
> = ({ isExpandable = false, label = '', dateLabel, children, sx: sxProps, ...containerProps }) => {
  const cursor = isExpandable ? 'pointer' : 'default';

  return (
    <AccordionSummary
      expandIcon={isExpandable ? <MdExpandMore style={{ fontSize: '24px' }} /> : undefined}
      role={undefined}
      sx={{
        '& *': { cursor },
        '&*': { cursor },
        '&.MuiButtonBase-root.MuiAccordionSummary-root': { cursor },
        ...sxProps,
      }}
    >
      <Grid
        container
        direction="row"
        flex={1}
        justifyContent="start"
        alignItems="center"
        wrap="nowrap"
        {...containerProps}
      >
        <Grid
          component={Typography}
          color={DCS_BLUE}
          fontSize={18}
          fontWeight={700}
          alignItems="center"
        >
          {label}
        </Grid>
        {dateLabel}

        {children || (
          <Divider
            sx={{ alignSelf: 'center', flex: 1, maxHeight: '1px', mx: 2, bgcolor: DCS_BLUE }}
          />
        )}
      </Grid>
    </AccordionSummary>
  );
};

export default SectionHeader;
