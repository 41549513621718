import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// components
import PmtReversalList from './PmtReversalList';
import PmtReversalDetail from './PmtReversalDetail';
// state
import PmtReversalProvider from './PmtReversalProvider';
import PmtReversalDetailProvider from './PmtReversalDetail/PmtReversalDetailProvider';

/** ###  */
const PaymentReversal: FC = () => {
  return (
    <PmtReversalProvider>
      <Routes>
        <Route index element={<PmtReversalList />} />
        <Route path="/:pmtRecId" element={<PmtReversalDetail />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Routes>
    </PmtReversalProvider>
  );
};

export default PaymentReversal;
