import { FC, ReactNode, createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// utils
import { dateRangeOptions, getLocalDateFormat } from '@/general/dates';
// interfaces
import { SortKendo, IKendoPaginationState } from '@/interfaces/kendo';
import { Filter } from '@/interfaces/requests';
import { AllowedSortKey } from './pmtSubviews/default';

dayjs.extend(utc);

const initSort: SortKendo[] = [];
export const initPage: IKendoPaginationState = { skip: 0, take: 25 };

export interface ITableCtx<SortKey = string, FilterKey = string> {
  textSearchFilter: string | null;
  setTextSearchFilter: (textSearchFilter: string | null) => void;
  page: IKendoPaginationState;
  setPage: (page: IKendoPaginationState) => void;
  sort: SortKendo<SortKey>[];
  setSort: (sort: SortKendo<SortKey>[]) => void;
  filter: Filter<FilterKey>[];
  setFilter: (filter: Filter<FilterKey>[]) => void;
  addFilter: (newFilter: Filter) => void;
  removeFilter: (filterToRemove: Filter) => void;
  updateDateFilter: (newDateFilter: Filter) => void;
  isDateFilterError: boolean;
  initDateFilters: (field: FilterKey, shouldSetState?: boolean) => ITableCtx['filter'];
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: number) => void;
  queryPageNumber: number;
  resetTableState: ({
    sortFields,
    dateFilterFields,
  }: {
    sortFields: string[];
    dateFilterFields?: string[];
  }) => void;
}

const TableCtx = createContext<ITableCtx | null>(null);

export const TableProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Table variables
  const [filter, setFilter] = useState<ITableCtx['filter']>([]);
  const [textSearchFilter, setTextSearchFilter_] = useState<ITableCtx['textSearchFilter']>('');
  const [page, setPage] = useState(initPage);
  const [sort, setSort_] = useState(initSort);
  const setSort = (sortsToSet: (Omit<SortKendo, 'dir'> & { dir?: SortKendo['dir'] })[]) => {
    const newSortArr = sortsToSet.map((s) => new SortKendo(s.field, s.dir));
    setSort_(newSortArr);
  };
  const initDateFilters = (field: string, shouldSetState = true): ITableCtx['filter'] => {
    const { beginDate, endDate } = dateRangeOptions['This Month-to-date'];
    const { dateFormatStr } = getLocalDateFormat();

    // Create "Date from" filter
    const initDateFromFilter: Filter = {
      field,
      operator: '>=',
      value: dayjs(beginDate).format(dateFormatStr),
    };
    // Create "Date to" filter
    const initDateToFilter: Filter = {
      field,
      operator: '<=',
      value: dayjs(endDate).format(dateFormatStr),
    };
    const newDateFilters: ITableCtx['filter'] = [initDateFromFilter, initDateToFilter];
    const nonDateFilters: ITableCtx['filter'] = [...filter].filter((f) => f.field !== field);
    const updatedFilterArr: ITableCtx['filter'] = [...nonDateFilters, ...newDateFilters];
    if (shouldSetState) setFilter(updatedFilterArr);
    return updatedFilterArr;
  };

  return (
    <TableCtx.Provider
      value={{
        filter,
        setFilter,
        // @todo need to fix logic - should use a Map type
        addFilter(newFilter: Filter) {
          const updatedFilter = [...filter, newFilter];
          setFilter(updatedFilter);
        },
        removeFilter(filterToRemove: Filter) {
          const updatedFilter = [...filter].filter(
            (f) =>
              !(
                f.field === filterToRemove.field &&
                f.value === filterToRemove.value &&
                f.operator === filterToRemove.operator
              )
          );
          setFilter(updatedFilter);
        },
        updateDateFilter(newDateFilter: Filter) {
          // Remove original date filter
          const updatedFilter = [...filter].filter(
            (f) => !(f.field === newDateFilter.field && f.operator === newDateFilter.operator)
          );
          updatedFilter.push(newDateFilter);
          setFilter(updatedFilter);
        },
        // @note this is temporary - really inefficient
        get isDateFilterError(): boolean {
          // 1. get all date-filters @note temp logic - currently the only date-filter fields are: ['chgOffDate', 'pmtDate', 'dateSold']
          const dateFilterFields = ['chgOffDate', 'pmtDate', 'dateSold'];
          const dateFilters = filter.filter((f) => dateFilterFields.includes(f.field));
          // 2. group date-filters by field
          const dateFilterGroups: Map<
            'chgOffDate' | 'pmtDate' | 'dateSold',
            { dateFrom: string; dateTo: string }
          > = new Map<'chgOffDate' | 'pmtDate' | 'dateSold', { dateFrom: string; dateTo: string }>([
            [
              'chgOffDate',
              {
                dateFrom:
                  dateFilters.filter((df) => df.field === 'chgOffDate' && df.operator === '>=')[0]
                    ?.value || '',
                dateTo:
                  dateFilters.filter((df) => df.field === 'chgOffDate' && df.operator === '<=')[0]
                    ?.value || '',
              },
            ],
            [
              'pmtDate',
              {
                dateFrom:
                  dateFilters.filter((df) => df.field === 'pmtDate' && df.operator === '>=')[0]
                    ?.value || '',
                dateTo:
                  dateFilters.filter((df) => df.field === 'pmtDate' && df.operator === '<=')[0]
                    ?.value || '',
              },
            ],
            [
              'dateSold',
              {
                dateFrom:
                  dateFilters.filter((df) => df.field === 'dateSold' && df.operator === '>=')[0]
                    ?.value || '',
                dateTo:
                  dateFilters.filter((df) => df.field === 'dateSold' && df.operator === '<=')[0]
                    ?.value || '',
              },
            ],
          ]);

          // 3. for each group, validate if:
          //    - Both filter values are valid dates;
          //    - 'From' filter is <= 'To' filter;
          let isDateFilterValid = true;
          dateFilterGroups.forEach((df, _key) => {
            // Both dates must be valid dates
            if (
              (df.dateFrom !== '' && !dayjs(df.dateFrom).isValid()) ||
              (df.dateTo !== '' && !dayjs(df.dateTo).isValid())
            ) {
              isDateFilterValid = false;
              return;
            }
            // 'From' filter must be less than "To" filter
            if (dayjs(df.dateFrom).toDate().getTime() > dayjs(df.dateTo).toDate().getTime()) {
              isDateFilterValid = false;
              return;
            }
          });

          return !isDateFilterValid;
        },
        /** Initialize date-filters for a single field */
        initDateFilters,
        textSearchFilter,
        setTextSearchFilter(newTextSearchFilter: string | null) {
          setPage({ ...page, skip: 0 });
          setTextSearchFilter_(newTextSearchFilter);
        },
        page,
        setPage,
        sort,
        setSort,
        get pageSizeValue(): number {
          return page.take!;
        },
        setPageSizeValue(take: number) {
          setPage({ ...page, take });
        },
        queryPageNumber: page.skip! / page.take! + 1,
        resetTableState({
          sortFields,
          dateFilterFields = [],
        }: {
          sortFields: string[];
          dateFilterFields?: string[];
        }) {
          const newSorts: SortKendo[] = sortFields.map((s) => new SortKendo(s, 'desc'));
          setSort(newSorts);

          setPage({ ...initPage, take: page.take });
          setTextSearchFilter_('');

          const newDateFilters: Filter[] = [];
          dateFilterFields.length > 0
            ? dateFilterFields.forEach((f) => {
                newDateFilters.push(...initDateFilters(f, false));
              })
            : [];
          setFilter(newDateFilters);
        },
      }}
    >
      {children}
    </TableCtx.Provider>
  );
};

export const useTableCtx = <T,>(selector: (state: ITableCtx) => T): T => {
  const ctx = useContext(TableCtx);
  if (!ctx) throw new Error('useTableCtx must be used within TableProvider');

  return selector(ctx);
};
export const usePmtTableCtx = <T,>(selector: (state: ITableCtx<AllowedSortKey>) => T): T => {
  const ctx = useContext(TableCtx) as ITableCtx<AllowedSortKey> | null;
  if (!ctx) throw new Error('useTableCtx must be used within TableProvider');

  return selector(ctx);
};
