export const formFieldStyle = {
  width: '50%',
  backgroundColor: 'rgb(255, 255, 255)',
  '& .MuiOutlinedInput-input': {
    '& fieldset': { borderColor: 'white' },
    '&.Mui-disabled': {
      backgroundColor: 'rgb(220 220 220)',
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.16)',
  },
};
