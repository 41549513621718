import { FC } from "react";
import { toast } from "react-toastify";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
import { BasicTable } from "@/components/table/Table";
import { Button } from "@/components/button/Button";
import { Column } from "@/components/table/TableInterface";
import ScOpenCell from "@/components/kendoCells/ScOpenCell";
// state
import { useWholesaleViewCtx } from "../WholesaleViewProvider";
import { useVehicleForm } from "./useVehicleForm";
// interfaces
import { type IDefaultFormValues } from "./default";

type VehicleCtx = ReturnType<typeof useVehicleForm>;

const vehicleColumnsInit: Column[] = [
  { field: "stockNum", title: "Stock #" },
  { field: "scOpen", title: "Sc", cell: ScOpenCell },
  { field: "year", title: "Year" },
  { field: "make", title: "Make" },
  { field: "model", title: "Model" },
  { field: "color", title: "Color" },
  { field: "vin", title: "Vin" },
] as const;

/** ###  */
const VehicleTable: FC<VehicleCtx> = ({ ...props }) => {
  const availableVehicles = useWholesaleViewCtx((s) => s.formAddlData?.vehicles);
  const setWsFormField = useWholesaleViewCtx((s) => s.setWsFormField);

  /** @deprecated use standard impl */
  const SelectCell: FC<GridCellProps> = ({ dataItem, ...cellProps }) => {
    const vehicle: IDefaultFormValues = dataItem;
    return (
      <td>
        <Button
          secondary
          label="Select"
          fillMode="outline"
          loading={props.selectVehicleLoading}
          onClick={async () => {
            props.setSelectVehicleLoading(true);
            try {
              setWsFormField("vehRecId", vehicle.recId || null);
              props.resetVehicleFields(vehicle);
            } catch {
              toast.error("Unable to select vehicle");
            } finally {
              props.setSelectVehicleLoading(false);
            }
          }}
        />
      </td>
    );
  };

  const tableColumns = [
    ...vehicleColumnsInit,
    { field: "selectButton", title: " ", cells: { data: SelectCell } },
  ];

  return (
    <BasicTable
      data={availableVehicles || []}
      columns={tableColumns}
      pageable
      sortable
      // @note keeping for reference filters={tableFilters}
    />
  );
};

export default VehicleTable;
