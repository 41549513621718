import {  DropDownButton, Switch } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { useAppDispatch } from '@/store/store';
import { Loader } from '@progress/kendo-react-all';
import { FaInfoCircle, FaLock, FaLockOpen } from 'react-icons/fa';
import { salesActions } from '../../salesSlice';
import styles from './UnderwritingScore.module.scss';
import { useUnderwritingScore } from './useUnderwritingScore';

export const UnderwritingScore = () => {
  const {
    underwritingScore,
    totalScore,
    maxScore,
    getScoringQuestionsLoading,
    toggleManualEntryOff,
    toggleManualEntryOn,
    switchOn,
    grade,
    gradeRanges,
  } = useUnderwritingScore();
  const dispatch = useAppDispatch();

  const UnderwritingScoreCategory = (props: { categoryId: number; categoryTitle: string }) => {
    const { categoryId, categoryTitle } = props;

    return underwritingScore ? (
      <>
        <tr>
          <td></td>
          <td></td>
          <td className={styles.categoryRowHeader}>
            Part {categoryId}: {categoryTitle}
          </td>
          <td className={styles.rowHeaderData}>
            <span className={styles.rowHeaderTextItalics}>Higher Risk</span> ↑
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td className={styles.rowHeaderData}>
            <span className={styles.rowHeaderTextItalics}>Lower Risk</span> ↓
          </td>
        </tr>
        {underwritingScore?.map(
          (value, index) =>
            value.categoryId === categoryId && (
              <tr className={styles.questionsContainer} key={index}>
                <td>{value.canBeSelected ? <FaLockOpen /> : <FaLock />} </td>
                <td className={styles.questionNumber}>{index + 1}</td>
                <td key={0}>{value.question}</td>
                {value.answers.map((answer, answerIndex) => (
                  <td
                    onClick={() => {
                      if (!value.canBeSelected) return;

                      const prevScore = [...underwritingScore];
                      const newScoring = prevScore?.map((value) => {
                        return {
                          ...value,
                          answers: value.answers.map((answerValue) => {
                            return {
                              ...answerValue,
                              selected:
                                answerValue.id === answer.id
                                  ? true
                                  : answerValue.questionId === answer.questionId
                                  ? false
                                  : answerValue.selected,
                            };
                          }),
                        };
                      });
                      dispatch(salesActions.setUnderwritingScore(newScoring));
                    }}
                    key={answerIndex + 1}
                  >
                    <div
                      className={
                        answer.selected
                          ? value.canBeSelected
                            ? `${styles.tableDataSelected} ${styles.tableData}`
                            : `${styles.tableDataSelectedButDisabled} ${styles.tableData}`
                          : value.canBeSelected
                          ? styles.tableData
                          : styles.tableDataCannotBeSelected
                      }
                    >
                      {answer.answer}
                    </div>
                  </td>
                ))}
              </tr>
            )
        )}
        <tr>
          <td className={styles.sectionScore} colSpan={9}></td>
        </tr>
      </>
    ) : null;
  };

  return (
    <>
      <Switch
        label="Toggle Manual Entry"
        checked={switchOn}
        onChange={async (e) => {
          if (e.value) {
            await toggleManualEntryOn();
          } else {
            await toggleManualEntryOff();
          }
        }}
      />
      <Spacer size={8} />
      <table className={styles.tableContainer}>
        <thead className={styles.tableHeader}>
          <tr>
            <th></th>
            <th></th>
            <th>Category</th>
            <th className={styles.tableHeaderCenter}>Range 1</th>
            <th className={styles.tableHeaderCenter}>Range 2</th>
            <th className={styles.tableHeaderCenter}>Range 3</th>
            <th className={styles.tableHeaderCenter}>Range 4</th>
            <th className={styles.tableHeaderCenter}>Range 5</th>
          </tr>
        </thead>
        {getScoringQuestionsLoading ? (
          <div className={styles.loading}>
            <Loader size="large" />
          </div>
        ) : (
          <>
            <tbody className={styles.tableBody}>
              <UnderwritingScoreCategory categoryId={1} categoryTitle="Residency and Job" />
              <UnderwritingScoreCategory categoryId={2} categoryTitle="Car and Credit" />
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={8} className={styles.score}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 4 }}>
                    {/* <td colSpan={8} className={styles.score}> */}
                    Total Score:{' '}
                    <span className={styles.totalScore}>
                      {!getScoringQuestionsLoading && `${totalScore} out of ${maxScore} (${grade})`}
                    </span>
                    <DropDownButton
                      popUpProps={{
                        popupAlign: { vertical: 'bottom', horizontal: 'center' },
                        anchorAlign: { vertical: 'top', horizontal: 'center' },
                      }}
                      label={
                        <div>
                          <FaInfoCircle className={styles.informationCircle} />
                        </div>
                      }
                    >
                      <div className={styles.informationPopup}>
                        <strong>Grades</strong>
                        {gradeRanges.map((value) => (
                          <div>
                            {value.grade}: {value.minScore} - {value.maxScore}
                          </div>
                        ))}
                      </div>
                    </DropDownButton>
                  </div>
                </td>
              </tr>
            </tfoot>
          </>
        )}
      </table>
    </>
  );
};
