import { z } from 'zod';

export const RoleForm = z.object({
  templateName: z.string().min(1).default(' '),
  dms_Accounts: z.boolean().default(false),
  dms_Collections: z.boolean().default(false),
  dms_Inventory: z.boolean().default(false),
  dms_Sales: z.boolean().default(false),
});
export type RoleForm = z.infer<typeof RoleForm>;
export const deregister: { [key in keyof RoleForm]?: () => boolean } = {
  dms_Accounts: () => true,
};

export const UpdateRoleReq = RoleForm.extend({
  recId: z.number(),
});
export type UpdateRoleReq = z.infer<typeof UpdateRoleReq>;

export const AddRoleReq = RoleForm;
export type AddRoleReq = z.infer<typeof AddRoleReq>;
