/** ## (V3) Generic request-body Dto interface for *P*agination, *F*iltering and *S*orting (PFS)
 * The third iteration of request-body-type (I know) which:
 * 1. Consolidates the page params into a single object;
 * 1. Simplifies the page, sort and filter property names to match Kendo;
 * 1. Uses a more accurate name schema - an acronym that describes what it is (a DTO) and the backend functions it supports;
 */
export class PfsReqDto<SortKey = string, FilterKey = string> {
  constructor(
    public page: DtoPage,
    public sorts: DtoSort<SortKey>[],
    public filters?: DtoFilter<FilterKey>[],
    public search?: string
  ) {}

  /** ### Constructor method that takes an object rather than individual params */
  static new<SortKey = string, FilterKey = string>(
    newPfsReqDto: PfsReqDto<SortKey, FilterKey>
  ): PfsReqDto<SortKey, FilterKey> {
    return new PfsReqDto<SortKey, FilterKey>(
      newPfsReqDto.page,
      newPfsReqDto.sorts,
      newPfsReqDto.filters,
      newPfsReqDto.search
    );
  }
}
export class PfsSubviewReqDto<
  SubviewKey = string,
  SortKey = string,
  FilterKey = string
> extends PfsReqDto<SortKey, FilterKey> {
  constructor(
    public subview: SubviewKey,
    page: PfsReqDto<SortKey, FilterKey>['page'],
    sorts: PfsReqDto<SortKey, FilterKey>['sorts'],
    filters?: PfsReqDto<SortKey, FilterKey>['filters'],
    search?: PfsReqDto<SortKey, FilterKey>['search']
  ) {
    super(page, sorts, filters, search);
  }

  static override new<SubviewKey = string, SortKey = string, FilterKey = string>(
    newPfsSubviewReqDto: PfsSubviewReqDto<SubviewKey, SortKey, FilterKey>
  ): PfsSubviewReqDto<SubviewKey, SortKey, FilterKey> {
    return new PfsSubviewReqDto<SubviewKey, SortKey, FilterKey>(
      newPfsSubviewReqDto.subview,
      newPfsSubviewReqDto.page,
      newPfsSubviewReqDto.sorts,
      newPfsSubviewReqDto.filters,
      newPfsSubviewReqDto.search
    );
  }
}

/** ## (V3) Pagination parameters for the request body DTO
 */
export class DtoPage {
  constructor(
    /** Number of rows to skip */
    public skip: number,
    /** Amount of rows per page */
    public take: number
  ) {}

  static new(newDtoPage: DtoPage): DtoPage {
    return new DtoPage(newDtoPage.skip, newDtoPage.take);
  }
}
/** ## (V3) Sort parameters for the request body DTO
 * @note uses `dir` instead of `direction` (from V2)
 */
export class DtoSort<SortKey = string> {
  constructor(
    // @note case insensitive on the backend
    public field: SortKey,
    // @note case insensitive on the backend
    public dir: 'ASC' | 'DESC' | 'asc' | 'desc'
  ) {}

  static new(newDtoSort: DtoSort): DtoSort {
    return new DtoSort(newDtoSort.field, newDtoSort.dir);
  }
}
/** ## (V3) Filter parameters for the request body DTO
 * @note improves on the name and includes `new` constructor fxn
 */
export class DtoFilter<ColumnKey = string> {
  constructor(public field: ColumnKey, public operator: FilterOperators, public value: string) {}

  static new<ColumnKey = string>(newDtoFilter: DtoFilter<ColumnKey>): DtoFilter<ColumnKey> {
    return new DtoFilter(newDtoFilter.field, newDtoFilter.operator, newDtoFilter.value);
  }
}

/** ## (V2) Sort value for API payload sorting (on the backend)
 * For type-setting the `field` property, add `keyof <place-interface-here>` in the generic param
 */
export interface Sort<SortKey = string> {
  // @note case insensitive on the backend
  field: SortKey;
  // @note case insensitive on the backend
  direction: 'ASC' | 'DESC';
}

/** @note These are case insensitive on the backend */
export const filterOperators = [
  '<',
  '>',
  '<>',
  '=',
  '<=',
  '>=',
  'IN',
  'LIKE',
  'IS NULL',
  'IS NOT NULL',
] as const;
export type FilterOperators = (typeof filterOperators)[number];

/** ## Pagination value for API payload filtering (on the backend)
 * For type-setting the `field` property, add `keyof <place-interface-here>` in the generic param
 */
export interface Filter<ColumnKey = string> {
  field: ColumnKey;
  operator: FilterOperators;
  value: string;
}

/** ## (V2) Generic request-body interface for *P*agination, *F*iltering and *S*orting (PFS)
 * - `sorts` instead of `sort` (from V1)
 * - `filters` instead of `filter` (from V1)
 */
export interface IPaginatedReq<SortColumnKey = string, FilterColumnKey = string> {
  page: number;
  perPage: number;
  sorts: Sort<SortColumnKey>[];
  filters?: Filter<FilterColumnKey>[];
}

/** ## (V1) Routes with pagination are being converted to handle properties:
 * - `sorts` instead of `sort`
 * - `filters` instead of `filter`
 * @deprecated
 */
export interface IPaginatedReqDeprec<SortColumnKey = string, FilterColumnKey = string>
  extends Omit<IPaginatedReq<SortColumnKey, FilterColumnKey>, 'sorts' | 'filters'> {
  sort?: Sort<SortColumnKey>[];
  filter?: Filter<FilterColumnKey>[];
}

// eslint-disable-next-line
export interface IPaginatedRes<DbReturnType = any> {
  total: number;
  page: number;
  perPage: number;
  data: DbReturnType[];
  totals?: DbReturnType;
}

// status, saleType=lease
