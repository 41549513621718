import { FC } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { Hint } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { TextInput } from '@/components/inputs/text/TextInput';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
// utils
import { customerService } from '@/services/customerService';
import useFetchAndNavAccountDetail from '@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/useFetchAndNav';
import { emailRegex } from '@/utils/helpers/formValidation';
// interfaces
import { PaymentInterval } from '@/enums/payment';
import { InAtOption } from '../interfaces';
import { CreatePaymentArrangementForm, PaymentArrangementPayload } from './interfaces';
// style
import styles from './PaymentArrangement.module.scss';

/**
 * @note `accountInformation !== null` is validated in parent component: `Quick-Actions-Modal`
 */
const PaymentArrangement: FC = () => {
  const params = useParams();
  const loc = useLocation();

  const colRecId = Number(params.colRecId);
  const dispatch = useAppDispatch();
  const fetchAndNav = useFetchAndNavAccountDetail();

  // Account state
  const postPaymentArrangementLoading = useAccountSelector((s) => s.postPaymentArrangementLoading);
  const appBuyerRecId = useAccountSelector((s) => s.accountInformation!.buyer.appBuyerRecId);
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const customerContacts = useAccountSelector((s) => s.customerContacts);
  const buyerEmail = useAccountSelector((s) => s.accountInformation!.buyer.email);
  // build buyer-name
  const buyerFirstName = useAccountSelector((s) => s.accountInformation!.buyer.firstName);
  const buyerLastName = useAccountSelector((s) => s.accountInformation!.buyer.lastName);
  const buyerName = `${buyerFirstName} ${buyerLastName}`;
  // SAM-hotlist state
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);

  // Build list of customer contacts
  const customerContactDisplay = customerContacts.map((value, index) => {
    if (index === 0) return buyerName;

    return `${value.contact} - ${value.contactType}`;
  });

  // Init form
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CreatePaymentArrangementForm>({
    defaultValues: {
      speakingTo: customerContactDisplay[0] ?? buyerName,
      firstPaymentDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      firstPaymentTime: '00:00',
      numberOfPayments: '',
      // paymentSchedule: '',
      inAt: 'at',
      paymentAmount: 0,
      note: '',
      documentsToBring: '',
      sendBuyerEmail: true,
      buyerEmail: buyerEmail,
    },
  });

  /** Contains all of the logic of the redux method: `postPaymentArrangement`
   * Domain-specific logic should be grouped together to avoid adding ambiguity to common/generic/abstracted/general use code.
   * `postPaymentArrangement` is only used here, so it doesn't warrant moving to a differerent part of the codebase.
   * Additionally, putting the request here allows us to better handle the possible outcomes.
   */
  const handleSubmitCreateAppointment = async (data: CreatePaymentArrangementForm) => {
    const firstPaymentDateAndTime = new Date(`${data.firstPaymentDate} ${data.firstPaymentTime}`);
    const now = new Date();

    if (firstPaymentDateAndTime <= now) {
      setError('firstPaymentDate', {
        type: 'valueAsDate',
        message: "Date/time can't be in the past",
      });
    }
    if (appBuyerRecId === null) {
      throw new Error(
        `appBuyerRecId is null. Acct info: ${JSON.stringify(
          useAccountSelector((s) => s.accountInformation),
          null,
          2
        )}`
      );
    }

    // Build payload
    const payload: PaymentArrangementPayload = {
      buyerEmail: data.buyerEmail,
      coBuyerEmail: '',
      colRecId,
      dateTime: firstPaymentDateAndTime,
      documents: data.documentsToBring,
      inAt: data.inAt,
      note: data.note,
      numberOfPayments: Number(data.numberOfPayments),
      paymentAmount: data.paymentAmount,
      paymentSchedule: data.paymentSchedule,
      sendBuyerEmail: data.sendBuyerEmail,
      sendCoBuyerEmail: false,
      speakingTo: data.speakingTo,
      userRecId: appBuyerRecId,
    };

    try {
      // Set state to "loading"
      dispatch(accountActions.setPostPaymentArrangementLoading(true));

      // Send request and await its response
      await customerService.postPaymentArrangement(payload);

      toast.success('Payment Arrangement Posted');
      dispatch(accountActions.setQuickActionsModalOpened(false));
      dispatch(accountActions.setQuickActionType(''));

      // Update relevant SAM hotlist AFTER response is received
      fetchHotlist(appRecId!, 'Account', 'Active');
      // Fetch info, update state and navigate to acct-detail base-route
      const browserPath = loc.pathname.replace(params['*'] || ' ', '');
      fetchAndNav(browserPath, colRecId);
    } catch {
      toast.error('There was an error posting your payment');
    } finally {
      // Revert state to "not loading"
      dispatch(accountActions.setPostPaymentArrangementLoading(false));
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit(handleSubmitCreateAppointment)}>
      <Controller
        name="speakingTo"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DropdownInput
            errors={errors.speakingTo ? 'Select an option' : undefined}
            required
            label="Speaking To"
            horizontalLabel={false}
            data={customerContacts.length !== 0 ? customerContactDisplay : [buyerName]}
            {...field}
          />
        )}
      />
      <div className={styles.row}>
        <Controller
          name="firstPaymentDate"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              errors={
                errors.firstPaymentDate
                  ? errors.firstPaymentDate.message
                    ? errors.firstPaymentDate.message
                    : 'Enter a valid date'
                  : undefined
              }
              required
              label="First payment date"
              horizontalLabel={false}
              type="date"
              min={dayjs().format('YYYY-MM-DD')}
              {...field}
            />
          )}
        />
        <Controller
          name="inAt"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownInput
              errors={errors.inAt ? 'Select an option' : undefined}
              label="Timing"
              horizontalLabel={false}
              data={InAtOption.options}
              {...field}
            />
          )}
        />
        <Controller
          name="firstPaymentTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              errors={errors.firstPaymentTime ? 'Enter a valid time' : undefined}
              required
              label="Time"
              horizontalLabel={false}
              type="time"
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="numberOfPayments"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              errors={errors.numberOfPayments ? 'Enter a value' : undefined}
              label="Number of Payments"
              horizontalLabel={false}
              type="number"
              {...field}
            />
          )}
        />
        <Controller
          name="paymentSchedule"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownInput
              required
              errors={errors.paymentSchedule ? 'Select a value' : undefined}
              label="Schedule"
              horizontalLabel={false}
              data={PaymentInterval.options}
              {...field}
            />
          )}
        />
        <Controller
          name="paymentAmount"
          control={control}
          rules={{ required: true, validate: (value) => value > 0 }}
          render={({ field }) => (
            <CurrencyInput
              errors={errors.paymentAmount ? 'Enter a valid amount' : undefined}
              required
              label="Payment Amount"
              horizontalLabel={false}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="note"
          control={control}
          render={({ field }) => <TextArea label="Payment Arrangement Notes" rows={3} {...field} />}
        />
        <Hint>This will print on the confirmation email</Hint>
      </div>
      <div>
        <Controller
          name="documentsToBring"
          control={control}
          render={({ field }) => (
            <TextArea label="Documents Required from Customer" rows={3} {...field} />
          )}
        />
        <Hint>This will print on the confirmation email</Hint>
      </div>
      <Controller
        name="buyerEmail"
        control={control}
        rules={{ required: true, pattern: emailRegex }}
        render={({ field }) => (
          <TextInput
            required
            errors={errors.buyerEmail ? 'Enter a valid email' : undefined}
            label="Email"
            horizontalLabel={false}
            {...field}
          />
        )}
      />
      <Controller
        name="sendBuyerEmail"
        control={control}
        render={({ field }) => <Checkbox label="Send buyer email" {...field} />}
      />
      <Button
        type="submit"
        label="Create Payment Arrangment"
        loading={postPaymentArrangementLoading}
      />
    </form>
  );
};

export default PaymentArrangement;
