import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// utils
import { formatCurrency } from '@/utils/helpers/general';

/** ###  */
const TotalAppliedCell: FC<GridCellProps> = ({ dataItem }) => {
  const { totalApplied } = dataItem;
  return (
    <td className="k-table-td" style={{ textAlign: 'end' }}>
      <span>{formatCurrency(totalApplied)}</span>
    </td>
  );
};

export default TotalAppliedCell;
