import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseFormReturn, useForm } from 'react-hook-form';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { companyService } from '@/services/companyService';
import { salesService } from '@/services/salesService';
import { formatAddress } from '@/utils/helpers/general';
import { getYearlyCpiRate } from '@/utils/helpers/payment';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { calcCpiRateDisabled } from './utils';
// interfaces
import { CpiSettings } from '@/interfaces/applications';
import { SaleType } from '@/enums/general';
import { SalesInsuranceForm, SalesInsuranceRes } from './interfaces';
import { useAccountSelector } from '@/features/Accounts/accountSlice';

type ICtx = ISalesInsuranceCtx;
export interface ISalesInsuranceCtx {
  isLoading: boolean;
  loadingError: boolean;

  insuranceInfoForm: UseFormReturn<SalesInsuranceForm>;
  insuranceInfo: SalesInsuranceRes | null;
  cpiSettings: CpiSettings | null;

  // Getters
  fullAddress: string | null;
  canHaveCpi: boolean;
  isDisabled: boolean;
  cpiRate: number | null;
}

const SalesInsuranceCtx = createContext<ICtx | null>(null);

const SalesInsuranceProvider: FC<PropsWithChildren> = ({ children }) => {
  const appRecId = getRouteParamNum(useParams().id);
  const saleData = useSalesSelector((s) => s.saleData);

  const insuranceInfoForm = useForm<SalesInsuranceForm>({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState<ICtx['isLoading']>(false);
  const [loadingError, setLoadingError] = useState(false);

  const [cpiSettings, setCpiSettings] = useState<ICtx['cpiSettings']>(null);
  const [insuranceInfo, setInsuranceInfo] = useState<ICtx['insuranceInfo']>(null);

  const cpiYearlyPremium = insuranceInfoForm.watch('cpiYearlyPremium');
  const sched = insuranceInfo?.sched;
  const onCpi = insuranceInfoForm.watch('onCpi');

  const initData = async () => {
    if (!appRecId) return;

    try {
      setIsLoading(true);
      setLoadingError(false);

      const cpiSettingsRes = await companyService.getCpiSettings(saleData.buyer?.compID ?? saleData.coBuyer?.compID);

      setCpiSettings(cpiSettingsRes || null);

      const insuranceInformationRes = await salesService.getSaleInsuranceInfo(appRecId);
      insuranceInformationRes && setInsuranceInfo(insuranceInformationRes);

      const newForm = new SalesInsuranceForm(insuranceInformationRes);
      insuranceInfoForm.reset(newForm, { keepDirty: false });
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    } finally {
      setIsLoading(false);
      setLoadingError(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (!onCpi) {
      const newCpiYearlyPremiumCalc = cpiYearlyPremiumEnabled ? cpiYearlyPremium : null;
      insuranceInfoForm.setValue('cpiYearlyPremium', newCpiYearlyPremiumCalc);
    } else {
      const newCpiYearlyPremiumCalc = cpiYearlyPremium || insuranceInfo?.cpiYearlyPremium || null;
      insuranceInfoForm.setValue('cpiYearlyPremium', newCpiYearlyPremiumCalc);
    }
  }, [onCpi]);

  const cpiYearlyPremiumEnabled = cpiSettings?.cpiYearlyPremiumEnabled;
  const isCalcAvailable = onCpi && sched && !!insuranceInfo && !!cpiSettings;

  const newCpiRateCalc = cpiYearlyPremiumEnabled
    ? getYearlyCpiRate(cpiYearlyPremium ?? 0, sched || 'Weekly')
    : calcCpiRateDisabled(cpiSettings, insuranceInfo?.sched || null);
  const cpiRate = isCalcAvailable ? newCpiRateCalc : null;

  // Getters
  const fullAddress =
    formatAddress(
      insuranceInfo?.insAddress,
      insuranceInfo?.insCity,
      insuranceInfo?.insState,
      insuranceInfo?.insZip
    ) || null;

  const canHaveCpi = saleData.sale?.saletype !== SaleType.Cash;
  const isDisabled = saleData.sale?.salestatus?.toLowerCase() !== 'pending';

  return (
    <SalesInsuranceCtx.Provider
      value={{
        isLoading,
        loadingError,

        insuranceInfoForm,
        insuranceInfo,
        cpiSettings,

        // Getters
        fullAddress,
        canHaveCpi,
        isDisabled,
        cpiRate,
      }}
    >
      {children}
    </SalesInsuranceCtx.Provider>
  );
};

export default SalesInsuranceProvider;

export const useSalesInsuranceCtx = <T,>(selector: (state: ICtx) => T): T => {
  const ctx = useContext(SalesInsuranceCtx);
  if (!ctx) {
    throw new Error('useSalesInsuranceCtx must be used within SalesInsuranceProvider');
  }
  return selector(ctx);
};
