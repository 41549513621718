import { FC } from 'react';
import { InputAttributes, PatternFormat, PatternFormatProps } from 'react-number-format';
// kendo
import { Icons } from '@/components/icons';
import { Button } from '@/components/old';
// style
import styles from './PhoneNumberInput.module.scss';

export const PhoneNumberInput: FC<
  PatternFormatProps<InputAttributes> & {
    inputStyles?: React.CSSProperties;
    inputLabel?: string;
    editButton?: boolean;
    editButtonFunction?: () => void;
    containerStyle?: React.CSSProperties;
    inputContainerStyle?: React.CSSProperties;
    inputLabelContainerStyle?: React.CSSProperties;
    errors?: string;
    followingText?: string;
    phoneNumber?: string;
  }
> = ({
  inputLabel,
  inputStyles = {},
  editButton,
  editButtonFunction,
  containerStyle = {},
  inputContainerStyle = {},
  inputLabelContainerStyle = {},
  errors,
  followingText,
  phoneNumber,
  ...restInputProps
}) => (
  <div style={containerStyle}>
    <div className={styles.container}>
      {inputLabel && (
        <div className={styles.labelContainer} style={inputLabelContainerStyle}>
          <div className={styles.label}>{inputLabel}</div>
          {restInputProps.required && (
            <Icons.Star color="#bc5757" width="10px" height="10px" className={styles.icon} />
          )}
        </div>
      )}
      <div className={styles.inputContainer} style={inputContainerStyle}>
        <PatternFormat
          value={phoneNumber}
          className={errors ? `${styles.errors} ${styles.inputBox}` : styles.inputBox}
          style={inputStyles}
          {...restInputProps}
        />

        {editButton ? (
          <Button
            buttonLabel="Edit"
            buttonStyles={{
              width: '50px',
              height: '27.5px',
              padding: '4.5px 9.75px',
            }}
            onClick={editButtonFunction}
          />
        ) : (
          <div></div>
        )}

        {followingText && <div className={styles.followingText}>{followingText}</div>}
      </div>
    </div>
    {inputLabel && errors ? <div className={styles.errorMessage}>{errors}</div> : null}
  </div>
);
