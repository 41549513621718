import { forwardRef, useMemo } from 'react';
// kendo
import { ComboBoxHandle } from '@progress/kendo-react-all';
// components
import InternalDropdownInput from './InternalDropdownInput';
import LabelDropdown from './LabelDropdown';
// interfaces
import { DropdownInputProps } from './interfaces';
// style
import styles from '@components/inputs/dropdown/DropdownInput.module.scss';

/** @deprecated use mui AutocompleteField */
export const DropdownInput = forwardRef<ComboBoxHandle, DropdownInputProps>(
  (inputProps, inputRef) => {
    const {
      // @ts-ignore
      hidden,
      name,
      label,
      errors,
      containerStyle,
    } = inputProps;

    const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

    return (
      <div className={styles.dropContainer} style={containerStyle} hidden={hidden}>
        {label ? (
          <LabelDropdown {...inputProps} editorId={editorId} inputRef={inputRef} />
        ) : (
          <InternalDropdownInput {...inputProps} editorId={editorId} inputRef={inputRef} />
        )}
        {!!errors && typeof errors === 'string' && (
          <div className={styles.errorMessage}>{errors}</div>
        )}
      </div>
    );
  }
);
