import { BG_HIGHLIGHT } from "@/mui/theme/colors";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

export const formContainer: SxProps = {
  p: "20px 32px",
  backgroundColor: BG_HIGHLIGHT,
  borderRadius: 2,
  gap: 2,
  flex: 1,
};

export const headerContainer: SxProps = {
  padding: "20px 32px",
  backgroundColor: BG_HIGHLIGHT,
  borderRadius: 2,
  gap: 4,
  alignItems: "center",
  minHeight: 80,
  minWidth: 750,
};
