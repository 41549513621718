import { FC, PropsWithChildren } from 'react';
import { StyleSheet, View, ViewProps } from '@react-pdf/renderer';
// components
import PdfSectionHeader from './PdfSectionHeader';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '8px',
    borderBottom: '1px solid #dedede',
  },
  headerText: {
    fontSize: '20px',
    width: '100%',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

/** ### Wrapper which provides a standard header and body layout for a section within the PDF */
const PdfSection: FC<
  PropsWithChildren<ViewProps> & {
    header?: string;
    headerProps?: Parameters<typeof PdfSectionHeader>[0];
  }
> = ({ header, headerProps, children }) => {
  // If no header is provided, collapse header container
  return (
    <View style={styles.container}>
      {header && (
        <PdfSectionHeader
          {...headerProps}
          style={{ ...styles.headerContainer, ...headerProps?.style }}
          textProps={{ style: { ...styles.headerText, ...headerProps?.textProps?.style } }}
        >
          {header}
        </PdfSectionHeader>
      )}
      <View style={styles.body}>{children}</View>
    </View>
  );
};

export default PdfSection;
