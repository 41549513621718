import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
// kendo
import { Loader } from '@progress/kendo-react-all';
// interfaces
import { StateNavTab } from './interfaces';
// style
import styles from './AccountsMainPanel.module.scss';

interface AccountsMainPanelProps {
  children: ReactNode;
  navBarTitle: string;
  navBarItems?: StateNavTab[];
  navBarItemsStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  headerRight?: ReactNode;
  loading?: boolean;
}

/** @deprecated avoid using going forward. This will be removed. */
export const AccountsMainPanel: FC<AccountsMainPanelProps> = ({
  children,
  navBarItems,
  navBarTitle,
  headerRight,
  navBarItemsStyle = {},
  loading,
  containerStyle = {},
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const navBarRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(0);
  const [panelWidth, setPanelWidth] = useState(0);
  const [navBarHeight, setNavBarHeight] = useState(0);

  // @todo remove. CSS handles this.
  useEffect(() => {
    if (!panelRef?.current || !navBarRef?.current) return;
    // Resize when panel size changes
    // TODO: This properly handles when the window size increases, but does not when it decreases

    const handleResize = () => {
      if (panelRef?.current?.clientHeight) {
        setPanelHeight(panelRef.current.clientHeight);
        setPanelWidth(panelRef.current.clientWidth);
      }
      if (navBarRef?.current?.clientHeight) {
        setNavBarHeight(navBarRef.current.clientHeight);
      }
    };

    handleResize(); // Run once on initial render

    const debouncedHandleResize = debounce(handleResize, 250); // Debounce so it doesn't get triggered rapidly during resizing

    const resizeObserver = new ResizeObserver(debouncedHandleResize);

    resizeObserver.observe(panelRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [panelRef?.current, navBarRef?.current]);

  return (
    <div className={styles.panelContainer} style={containerStyle} ref={panelRef}>
      <div className={styles.body}>
        <div className={styles.navBar} style={containerStyle} ref={navBarRef}>
          <div className={styles.navBarTitle}>{navBarTitle}</div>
          {!!navBarItems && !!navBarItems.length && (
            <div className={styles.navBarItemsContainer} style={navBarItemsStyle}>
              {navBarItems.map((item) => (
                <div
                  className={item.isActive ? styles.itemActive : styles.item}
                  key={item.title}
                  onClick={item.onClick}
                >
                  <div>{item.title}</div>
                </div>
              ))}
            </div>
          )}
          {!!headerRight && headerRight}
        </div>
        <div
          className={styles.mainContents}
          style={{
            // height: panelHeight - navBarHeight,
            height: containerStyle?.height ? (containerStyle?.height) : (panelHeight - navBarHeight),
            maxHeight: panelHeight - navBarHeight,
            width: panelWidth,
          }}
        >
          {loading ? (
            <div className={styles.loadingContainer}>
              <Loader size="large" />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};
