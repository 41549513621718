import { FC } from "react";
import { Controller } from "react-hook-form";
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { Icons } from "@/components/icons";
import { Spacer } from "@/components/spacer/Spacer";
import { TextArea } from "@/components/inputs/textarea/TextArea";
import { TextInput } from "@/components/inputs/text/TextInput";
// components
import FormLoader from "../../layout/FormLoader";
// state
import useDeclineNotice from "./useDeclineNotice";
// utils
import { useNavigationConfirm } from "@/hooks/useNavigationConfirm/useNavigationConfirm";
// style
import styles from "../SaleManagement.module.scss";

/**
 * @deprecated needs refactor
 * - Remove scss
 * - Logically separate components
 * - Remove RHF
 * - Replace state-hook with context-state
 * - Update navblocker
 */
const DeclineNotice: FC = () => {
  const {
    control,
    getDeclineLoading,
    handleSubmit,
    isDirty,
    printDeclineNotice,
    unDeclineApplication,
    unDeclineLoading,
    updateDeclineLoading,
    updateDeclineNotice,
  } = useDeclineNotice();

  // todo update to also block navigation if it is currently submitting
  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  if (getDeclineLoading) return <FormLoader />;
  return (
    <>
      <header className={styles.headerContainer}>
        <h2 className={styles.header}>Decline Notice</h2>
        <div className={styles.buttonContainer}>
          <Button label="Un-Decline" loading={unDeclineLoading} onClick={unDeclineApplication} />
          <div onClick={printDeclineNotice}>
            <Icons.Print className={styles.printCell} />
          </div>
        </div>
      </header>
      <form className={styles.formContainer} onSubmit={handleSubmit(updateDeclineNotice)}>
        <div className={styles.declineNoticeHeader}>
          <Controller
            name="status"
            control={control}
            render={({ field }) => <TextInput readOnly label="Status" {...field} />}
          />
          <Controller
            name="dateDeclined"
            control={control}
            render={({ field }) => <DateInput readOnly label="Date Declined" {...field} />}
          />
          <Controller
            name="declinedBy"
            control={control}
            render={({ field }) => <TextInput readOnly label="Declined By" {...field} />}
          />
        </div>
        <Spacer size={10} />
        <div className={styles.bodyContainer}>
          <div className={styles.paymentContainer}>
            <h3 className={styles.declineNoticeSectionHeader}>
              Credit Application & Credit References
            </h3>
            <Controller
              name="creditApplication1"
              control={control}
              render={({ field }) => <Checkbox label="Application not completed" {...field} />}
            />
            <Controller
              name="creditApplication2"
              control={control}
              render={({ field }) => <Checkbox label="Insufficient references" {...field} />}
            />
            <Controller
              name="creditApplication3"
              control={control}
              render={({ field }) => (
                <Checkbox label="Credit references too new to verify" {...field} />
              )}
            />
            <Controller
              name="creditApplication4"
              control={control}
              render={({ field }) => (
                <Checkbox label="Unable to verify credit references" {...field} />
              )}
            />
            <Spacer size={10} />
            <h3 className={styles.declineNoticeSectionHeader}>
              Income/Cash Flow or Other Repayment Options
            </h3>
            <Controller
              name="income1"
              control={control}
              render={({ field }) => <Checkbox label="Unable to verify" {...field} />}
            />
            <Controller
              name="income2"
              control={control}
              render={({ field }) => <Checkbox label="Information refused" {...field} />}
            />
            <Controller
              name="income3"
              control={control}
              render={({ field }) => <Checkbox label="Excessive obligations" {...field} />}
            />
            <Controller
              name="income4"
              control={control}
              render={({ field }) => <Checkbox label="Lack of established record" {...field} />}
            />
            <Controller
              name="income5"
              control={control}
              render={({ field }) => (
                <Checkbox label="Inadequate financial information" {...field} />
              )}
            />
            <Spacer size={10} />
            <h3 className={styles.declineNoticeSectionHeader}>Residence</h3>
            <Controller
              name="residence1"
              control={control}
              render={({ field }) => <Checkbox label="Too short a period" {...field} />}
            />
            <Controller
              name="residence2"
              control={control}
              render={({ field }) => <Checkbox label="Temporary" {...field} />}
            />
            <Controller
              name="residence3"
              control={control}
              render={({ field }) => <Checkbox label="Unable to verify" {...field} />}
            />
            <Controller
              name="residence4"
              control={control}
              render={({ field }) => <Checkbox label="Out of our servicing area" {...field} />}
            />
          </div>
          <div className={styles.paymentContainer}>
            <h3 className={styles.declineNoticeSectionHeader}>Employment</h3>
            <Controller
              name="employment1"
              control={control}
              render={({ field }) => <Checkbox label="Unemployed" {...field} />}
            />
            <Controller
              name="employment2"
              control={control}
              render={({ field }) => <Checkbox label="Unable to verify" {...field} />}
            />
            <Controller
              name="employment3"
              control={control}
              render={({ field }) => <Checkbox label="Length of employment too short" {...field} />}
            />
            <Spacer size={10} />
            <h3 className={styles.declineNoticeSectionHeader}>Credit File</h3>
            <Controller
              name="creditFile1"
              control={control}
              render={({ field }) => <Checkbox label="No credit history" {...field} />}
            />
            <Controller
              name="creditFile2"
              control={control}
              render={({ field }) => <Checkbox label="Insufficient credit history" {...field} />}
            />
            <Controller
              name="creditFile3"
              control={control}
              render={({ field }) => <Checkbox label="Unsatisfactory credit with us" {...field} />}
            />
            <Controller
              name="creditFile4"
              control={control}
              render={({ field }) => (
                <Checkbox label="Delinquent obligations; past or present" {...field} />
              )}
            />
            <Controller
              name="creditFile5"
              control={control}
              render={({ field }) => (
                <Checkbox label="Slow or past due in trade or loan payments" {...field} />
              )}
            />
            <Controller
              name="creditFile6"
              control={control}
              render={({ field }) => (
                <Checkbox label="Garnishment, foreclosures, repossessions" {...field} />
              )}
            />
            <Controller
              name="creditFile7"
              control={control}
              render={({ field }) => (
                <Checkbox label="Sufficient credit already available" {...field} />
              )}
            />
            <Controller
              name="creditFile5"
              control={control}
              render={({ field }) => (
                <Checkbox label="Inadequate financial information" {...field} />
              )}
            />
            <Spacer size={10} />
            <h3 className={styles.declineNoticeSectionHeader}>Other</h3>
            <Controller
              name="other"
              control={control}
              render={({ field }) => <TextArea rows={2} {...field} />}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button label="Submit" buttonStyle={{ width: "140px" }} loading={updateDeclineLoading} />
        </div>
      </form>
      {NavigationConfirm}
    </>
  );
};

export default DeclineNotice;
