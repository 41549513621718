import { FC } from "react";
// kendo
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const AccountNumField: FC = () => {
  const { form, setField } = usePmtFormCtx((s) => s.formState);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("mpd"));
  return (
    <TextInput
      label="Account Number"
      required
      {...fieldProps}
      value={fieldProps.value?.accountNumber}
      onChange={(e) => {
        setField("mpd", { ...form.mpd, accountNumber: e.target.value });
      }}
    />
  );
};
const RoutingNumField: FC = () => {
  const { form, setField } = usePmtFormCtx((s) => s.formState);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("mpd"));

  return (
    <TextInput
      label="Routing Number"
      required
      {...fieldProps}
      value={fieldProps.value?.routingNumber}
      onChange={(e) => {
        setField("mpd", { ...form.mpd, routingNumber: e.target.value });
      }}
    />
  );
};
const OpenEdgeMpdFields: FC = () => [
  <AccountNumField key="accountNum" />,
  <RoutingNumField key="routingNum" />,
];
export default OpenEdgeMpdFields;
