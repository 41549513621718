import { FC, useEffect } from 'react';
// components
import AccountsViewTable from '../AccountsViewTable';
import TableFilters from './TableFilters';
// state
import { useTableCtx } from '../tableState';
import { useAccountsViewCtx } from '../accountsViewState';
// utils
import { useAcctSubviewData } from './useAcctSubviewData';

/** ### Data-table/grid which displays data from the `Accounts` database table
 * @note This component should **only** be used for any subview that fetches data from the `Accounts` table.
 */
const AcctSubviewList: FC = () => {
  const accountList = useAcctSubviewData();
  const viewState = useAccountsViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const { initSortFields, initDateFilterFields } = viewState.subviewInfo;

  // Reset table state whenever the subview changes
  useEffect(() => {
    tableState.resetTableState({
      sortFields: initSortFields,
      dateFilterFields: initDateFilterFields,
    });

    //Call resetTableState when unmount
    return () => {
      tableState.resetTableState({
        sortFields: initSortFields,
        dateFilterFields: initDateFilterFields,
      });
    };
  }, [viewState.subview]);

  return <AccountsViewTable rows={accountList} filterGroupComponent={<TableFilters />} />;
};

export default AcctSubviewList;
