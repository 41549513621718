import { z } from "zod";

// @note update labels to: ['dev', 'qa', 'staging', 'prod']
export const RuntimeEnvKey = z.enum(["development", "qa", "staging", "production"]);
export type RuntimeEnvKey = z.infer<typeof RuntimeEnvKey>;

export const RuntimeEnvConfigValidation = z.object({
  key: RuntimeEnvKey,
  level: z.number(),
  label: z.string().default(""),
  isStandalone: z.boolean(),
});
export type RuntimeEnvConfigValidation = z.infer<typeof RuntimeEnvConfigValidation>;

export interface AppConfig {
  localUrl: string;
  apiUrl: string;
  openEdgeEnvironment: "test" | "prod";
  signalrUrl: string;
  googleMapsApiKey: string;
  idsUrl: string;
}
