import { FC, ReactNode } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { BG_HIGHLIGHT } from '../../theme/colors';

/** ###  */
const NestedDetailHeaderLayout: FC<Grid2Props & { headerTitle: ReactNode }> = ({
  headerTitle,
  children,
  sx,
  ...gridProps
}) => {
  return (
    <Grid
      container
      direction="row"
      flex={1}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      gap={2}
      py={2.4}
      px={4}
      borderRadius={2.5}
      sx={{
        backgroundColor: BG_HIGHLIGHT,
        [`& *::-webkit-scrollbar`]: { height: '3px', width: '3px' },
        [`& *::-webkit-scrollbar-thumb`]: { height: '3px', width: '3px' },
        [`& *::-webkit-scrollbar-track`]: { height: '3px', width: '3px' },
        ...sx,
      }}
      {...gridProps}
    >
      <Grid fontSize={20} fontWeight={700}>
        {headerTitle}
      </Grid>

      {children}
    </Grid>
  );
};

export default NestedDetailHeaderLayout;
