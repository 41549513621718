import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';

const SalesCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const salesCt = useDashboardViewCtx((s) => s.dashboardData?.sales?.salesCt);
  const salesTotal = useDashboardViewCtx((s) => s.dashboardData?.sales?.salesTotal || 0);

  const areInputsValid = !isNaN(Number(salesCt)) && !isNaN(Number(salesTotal));
  const secondary = areInputsValid ? `${formatCurrency(salesTotal)} New AR` : 'Data unavailable';

  return (
    <DashboardCardLayout
      header="Number Sold"
      primary={salesCt?.toString() || 'N/A'}
      secondary={secondary}
      isLoading={isLoading}
    />
  );
};

export default SalesCard;
