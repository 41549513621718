import { FC } from 'react';
// components
import ExportButton from './ExportButton';
// utils
import { IReport } from '@/interfaces/Reports';

/** ### `Export` cell in the `export` column of `Reports` table.
 * Contains a set of buttons for exporting the row's report - one for each format.
 * - Supports Excel, CSV and PDF
 * - When user clicks on one of the icons, fetch the report via\
 * `Report-export` (use correct name) Azure service in its respective file-type.
 */
const ExportCell: FC<{ report: IReport }> = ({ report }) => {
  return (
    <td style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
      <ExportButton report={report} fileType="xlsx" />
      <ExportButton report={report} fileType="csv" />
      <ExportButton report={report} fileType="pdf" />
    </td>
  );
};

export default ExportCell;
