import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';
import DOMPurify from 'dompurify';
// interfaces
import { PaymentInterval } from '@/enums/payment';
import { zNumeric } from '../forms/fieldSchemas';

// @todo move to ./formatting.ts
export const formatCurrency = (
  amount: number | null | undefined,
  placeholder: string | undefined = ''
) => {
  if (!amount && amount !== 0) return placeholder;

  const currencyString = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });

  return currencyString === '' ? placeholder : currencyString;
};

// @todo move to ./formatting.ts
export const formatPhoneE164 = (inputPhone: string) => {
  // var inputTel = document.getElementById('fieldParamTel').value;
  const inputTel = '+1' + inputPhone.replace(/[^0-9a-z]/gi, '');
  return inputTel.toString();
};

/** @deprecated - use formatPercent */
export const formatPercentage = (decimal: number, options?: { decimalPlaces?: number }): string => {
  const { decimalPlaces = 2 } = options ?? {};

  // Round the decimal number to the specified number of decimal places
  const roundedPercentage = (decimal * 100).toFixed(decimalPlaces);

  // Append '%' to the rounded percentage
  const percentage = roundedPercentage + '%';

  return percentage;
};

export const sanitizePhoneInput = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '').slice(-10);
    return sanitizedPhoneNumber;
  }
  return '';
};

/** @deprecated use method inherited from service class */
export const displayErrors = (error: string) => {
  if (!isDev) {
    window.toastr.error(error, 'Error');
  } else {
    toast.error(error);
  }
};

export const displayMessage = (message: string) => {
  if (!isDev) {
    window.toastr.success(message);
  } else {
    alert(message);
  }
};

export const getInitials = (name: string) => {
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i++) {
    if (parts[i]!.length > 0 && parts[i] !== '') {
      initials += parts[i]![0];
    }
  }
  return initials;
};

// @todo move to ./formatting.ts
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// @todo move to ./formatting.ts
export enum DateFormat {
  Default = 'MM/DD/YYYY',
  Long = 'dddd, MMMM D, YYYY',
  YearMonthDayDash = 'YYYY-MM-DD',
  DateTime = 'MM/DD/YYYY h:mm a',
  DateInput = 'YYYY-MM-DD',
  DateFullTime = 'MM/DD/YYYY hh:mm a',
  DateTimeDayOfWeek = 'ddd MM/DD/YYYY h:mm a',
  CondensedDate = 'MM/DD/YY',
}

export interface FormatDateOptions {
  pattern?: DateFormat;
  utc?: boolean;
  placeholder?: string;
}

// @todo move to ./formatting.ts
export const formatDate = (
  d: Date | Dayjs | string | null | undefined,
  options?: FormatDateOptions
) => {
  const { pattern = DateFormat.Default, utc = true, placeholder = '' } = options ?? {};
  if (!d) return placeholder;

  const dayJsDate = utc ? dayjs.utc(d) : dayjs(d);
  if (!dayJsDate.isValid()) return placeholder;
  return dayJsDate.format(pattern);
};

export const extractTextFromHtmlString = (htmlString?: string | null) => {
  if (!htmlString) return '';
  try {
    const safeHtmlString = DOMPurify.sanitize(htmlString);
    const containerElement = document.createElement('span');
    containerElement.innerHTML = safeHtmlString;
    return containerElement.textContent ?? containerElement.innerText ?? '';
  } catch (err) {
    console.error('Unable to extract text from HTML string', htmlString);
    return '';
  }
};

/** @deprecated use enum */
export const scheduleData = PaymentInterval.options;

export const getTimeAgo = (sinceDate?: string) => {
  if (!sinceDate) return '';
  const sinceDateDayJs = dayjs.utc(sinceDate);
  if (!sinceDateDayJs.isValid()) return '';
  const minutesDiff = Math.ceil(dayjs.utc().diff(sinceDateDayJs, 'minutes'));
  if (minutesDiff === 0) return 'Just now';
  if (minutesDiff === 1) return minutesDiff + ' minute ago';
  if (minutesDiff < 60) return minutesDiff + ' minutes ago';
  const hoursDiff = Math.ceil(minutesDiff / 60);
  if (hoursDiff <= 1) return hoursDiff + ' hour ago';
  if (hoursDiff < 24) return hoursDiff + ' hours ago';
  const daysDiff = Math.ceil(hoursDiff / 24);
  if (daysDiff <= 1) return daysDiff + ' day ago';
  return daysDiff + ' days ago';
};

export function isNumber(value: string | number | boolean | null | undefined): boolean {
  if (value === null || value === undefined || value === '') return false;

  return !isNaN(Number(value));
}

/** Convert input of types [`string`, `number`, `boolean`] to `number`
 * - Uses `zNumeric` validation
 * - Returns `null` if value is invalid
 */
export const getNumber = (value: any): number | null => zNumeric.safeParse(value).data ?? null;

export const exists = (value: any) => value !== undefined && value !== null;

/** @deprecated do not use this. use react router's routeMatch */
export const inCollectionsRoute = () => location.hash.startsWith('#/collections');

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return undefined;
};

export const formatSSN = (ssn: string | undefined) => {
  const cleaned = ('' + ssn).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }

  return undefined;
};

export const roundToTwoDecimals = (number?: number | null) => {
  if (number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }
  return 0;
};

export const checkIfNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};

/** @deprecated use var from src/env */
export const isProd = import.meta.env.MODE.includes('production');
/** @deprecated use var from src/env */
export const isQA = import.meta.env.MODE.includes('qa');
/** @deprecated use var from src/env */
export const isStaging = import.meta.env.MODE.includes('staging');
/** @deprecated use var from src/env */
export const isDev = import.meta.env.MODE.includes('development');
/** @deprecated use var from src/env */
export const isStandalone = import.meta.env.MODE.includes('standalone');

export const slugify = (str: string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

export const handleRequestError = (err: any, fallbackMsg: string) => {
  if (err?.response?.status >= 400 && err?.response?.status < 500 && err?.response?.data?.message) {
    toast.error(err.response.data.message);
  } else {
    toast.error(fallbackMsg);
  }
  console.error(err);
};

export const getAddressFromGoogleMaps = (place: google.maps.places.PlaceResult) => {
  const addressComponents = place.address_components;
  const streetNumber =
    addressComponents?.find((value) => value.types[0] === 'street_number')?.long_name || '';
  const street = addressComponents?.find((value) => value.types[0] === 'route')?.short_name;
  const address =
    streetNumber && street
      ? `${streetNumber} ${street}`
      : streetNumber
      ? streetNumber
      : street
      ? street
      : '';
  const address2 =
    addressComponents?.find((value) => value.types[0] === 'subpremise')?.long_name || '';
  const city =
    addressComponents?.find((value) => value.types[0] === 'locality')?.long_name ||
    addressComponents?.find((value) => value.types[0] === 'administrative_area_level_3')?.long_name;
  const state = addressComponents?.find(
    (value) => value.types[0] === 'administrative_area_level_1'
  )?.short_name;
  const zip = addressComponents?.find((value) => value.types[0] === 'postal_code')?.long_name;
  const county = addressComponents?.find(
    (value) => value.types[0] === 'administrative_area_level_2'
  )?.long_name;

  return {
    address: address || '',
    address2: address2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
    county: county || '',
  };
};

export const formatAddress = (
  address: string | undefined | null,
  city: string | undefined | null,
  state: string | undefined | null,
  zip: string | number | undefined | null
) => {
  if (!address) return undefined;

  return `${address}, ${city}, ${state} ${zip}`;
};
