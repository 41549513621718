import { FC, ReactNode } from 'react';
// mui
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// local
import { lightTheme } from './theme';

const muiCache = createCache({ key: 'css', prepend: true });
/** ###  */
const MuiProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MuiProvider;
