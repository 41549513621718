import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const PrinBalField: FC = () => {
  const isPrinOnly = usePmtFormCtx((s) => s.formState.config.external.isPrincipalOnly);
  const prinBal = usePmtFormCtx((s) => s.formState.config.external.prinBal);

  if (!isPrinOnly) return <></>;

  return <CurrencyInput label="Principal Balance" readOnly value={prinBal} />;
};

export default PrinBalField;
