// validation
import { ColTypeCode } from "@/enums/general";
// utils
import { calcCcAch } from "./readonlyValues";
// interfaces
import { PmtFormCb } from "./interfaces";

export const registerAchPmtFields: PmtFormCb = ({ form }) =>
  calcCcAch(form).isAch && !form.useSavedPaymentMethod;

export const registerBillingFields: PmtFormCb = ({ form }) =>
  calcCcAch(form).isCcOrAch && !form.useSavedPaymentMethod;

export const registerCcBillingFields: PmtFormCb = ({ calculated, form }) =>
  calculated.isCc && !form.useSavedPaymentMethod;

export const registerLcFields: PmtFormCb = ({ external }) => {
  const isValidColType = ColTypeCode.extract(["SN", "FS", "LS"]).safeParse(
    external.colType
  ).success;
  return !external.isPrincipalOnly && !!external.lcDue && isValidColType;
};
