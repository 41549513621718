import {  Button, Checkbox, NumberInput, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { FC, useEffect, useState } from "react";
import styles from "./CustomFields.module.scss";
import { applicationService } from "@/services/applicationService";
import { CustomFields, CustomFieldsUpdateInterface } from "@/interfaces";
import { useAccountSelector } from "../../../../accountSlice";
import { Control, Controller, useForm } from "react-hook-form";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { useNavigationConfirm } from "@/hooks/useNavigationConfirm/useNavigationConfirm";

const StringCustomField: FC<{ control: Control<CustomFields>; label: string; name: string }> = ({
  control,
  label,
  name,
}) => (
  <Controller
    name={name as keyof CustomFields}
    control={control}
    render={({ field }) => {
      return <TextInput {...field} key={name} label={label} />;
    }}
  />
);

const DateCustomField: FC<{ control: Control<CustomFields>; label: string; name: string }> = ({
  control,
  label,
  name,
}) => (
  <Controller
    name={name as keyof CustomFields}
    control={control}
    render={({ field: { onChange, ...field } }) => {
      return <DateInput {...field} key={name} label={label} onChange={(e) => onChange(e)} />;
    }}
  />
);

const NumberCustomField: FC<{ control: Control<CustomFields>; label: string; name: string }> = ({
  control,
  label,
  name,
}) => (
  <Controller
    name={name as keyof CustomFields}
    control={control}
    render={({ field: { value, onChange, ...field } }) => {
      return (
        <NumberInput
          {...field}
          label={label}
          value={Number(value)}
          key={name}
          onChange={(e) => {
            if (!e.target.value) return onChange("");
            onChange(e.target.value.toString());
          }}
        />
      );
    }}
  />
);

const BoolCustomField: FC<{ control: Control<CustomFields>; label: string; name: string }> = ({
  control,
  label,
  name,
}) => (
  <Controller
    name={name as keyof CustomFields}
    control={control}
    render={({ field: { value, onChange, ...field } }) => {
      return (
        <Checkbox
          {...field}
          key={name}
          label={label}
          checked={value == "True"}
          labelStyles={{ fontWeight: 700 }}
          labelPlacement="before"
          onChange={(e) => {
            onChange(e.value ? "True" : "False");
          }}
        />
      );
    }}
  />
);

export const CustomFieldsForm = () => {
  const { accountInformation, accountInformationLoading } = useAccountSelector((s) => s);

  const [customFields, setCustomFields] = useState<CustomFields>({} as CustomFields);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(false);
  const [custFieldsSaveLoading, setCustFieldsSaveLoading] = useState(false);
  const [customFieldLabels, setCustomFieldLabels] = useState([] as string[]);
  const [customDateLabels, setCustomDateLabels] = useState([] as string[]);
  const [customNumLabels, setCustomNumLabels] = useState([] as string[]);
  const [customYNLabels, setCustomYNLabels] = useState([] as string[]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
    watch,
  } = useForm<CustomFields>({
    defaultValues: new CustomFields(),
    mode: "onChange",
  });

  useEffect(() => {
    if (accountInformation == null || accountInformation.appRecId == null) {
      return;
    }

    setCustomFieldsLoading(true);
    applicationService
      .getCustomFields(accountInformation.appRecId)
      .then((res) => {
        setCustomFields(res);
        reset(res);

        // Clear out custom field label arrays
        setCustomFieldLabels([]);
        setCustomDateLabels([]);
        setCustomNumLabels([]);
        setCustomYNLabels([]);

        // Not all companies will have all labels - filter out nonexistent ones
        const tempCustomFieldLabels = [];
        const tempCustomDateLabels = [];
        const tempCustomNumLabels = [];
        const tempCustomYNLabels = [];

        for (let i = 1; i <= 10; i++) {
          tempCustomFieldLabels.push((res as any)[`custField${i}L`]);
        }

        for (let i = 1; i <= 5; i++) {
          tempCustomDateLabels.push((res as any)[`custDate${i}L`]);
          tempCustomNumLabels.push((res as any)[`custNumL${i}`]);
          tempCustomYNLabels.push((res as any)[`custYNL${i}`]);
        }
        setCustomFieldLabels(tempCustomFieldLabels);
        setCustomDateLabels(tempCustomDateLabels);
        setCustomNumLabels(tempCustomNumLabels);
        setCustomYNLabels(tempCustomYNLabels);
      })
      .finally(() => {
        setCustomFieldsLoading(false);
      });
  }, [accountInformation?.appRecId]);

  // reset form after successful submission
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(watch(), { keepDirty: false });
    }
  }, [isSubmitSuccessful]);

  const onSaveButton = async (currentForm: CustomFields) => {
    setCustFieldsSaveLoading(true);

    const updateData: CustomFieldsUpdateInterface = {
      custField1: currentForm.custField1,
      custField2: currentForm.custField2,
      custField3: currentForm.custField3,
      custField4: currentForm.custField4,
      custField5: currentForm.custField5,
      custField6: currentForm.custField6,
      custField7: currentForm.custField7,
      custField8: currentForm.custField8,
      custField9: currentForm.custField9,
      custField10: currentForm.custField10,
      custDate1: currentForm.custDate1,
      custDate2: currentForm.custDate2,
      custDate3: currentForm.custDate3,
      custDate4: currentForm.custDate4,
      custDate5: currentForm.custDate5,
      custNum1: currentForm.custNum1,
      custNum2: currentForm.custNum2,
      custNum3: currentForm.custNum3,
      custNum4: currentForm.custNum4,
      custNum5: currentForm.custNum5,
      custYN1: currentForm.custYN1,
      custYN2: currentForm.custYN2,
      custYN3: currentForm.custYN3,
      custYN4: currentForm.custYN4,
      custYN5: currentForm.custYN5,
    };

    if (!accountInformation) return;

    applicationService.setCustomFields(accountInformation.appRecId!, updateData).finally(() => {
      setCustFieldsSaveLoading(false);
    });
  };

  const { NavigationConfirm } = useNavigationConfirm(isDirty);
  const isDisabled = custFieldsSaveLoading || !isDirty;
  return (
    <AccountsMainPanel
      navBarTitle="Custom Fields"
      loading={customFieldsLoading || accountInformationLoading}
    >
      {!accountInformationLoading && !customFieldsLoading && !customFields ? (
        <div>Unable to load customFields</div>
      ) : (
        <Grid
          container
          direction="column"
          component="form"
          onSubmit={handleSubmit(onSaveButton)}
          flex={1}
        >
          <Grid container direction="row" flex={1} gap={2}>
            <Grid container direction="column" flex={1} gap={2}>
              {customFieldLabels.map((label, index) => {
                if (!label) return;
                return (
                  <StringCustomField
                    key={label}
                    control={control}
                    label={label}
                    name={`custField${index + 1}`}
                  />
                );
              })}
            </Grid>

            <Grid container direction="column" flex={1} gap={2}>
              {customDateLabels.map((label, index) => {
                if (!label) return;
                return (
                  <DateCustomField
                    key={label}
                    control={control}
                    label={label}
                    name={`custDate${index + 1}`}
                  />
                );
              })}

              {customNumLabels.map((label, index) => {
                if (!label) return;
                return (
                  <NumberCustomField
                    key={label}
                    control={control}
                    label={label}
                    name={`custNum${index + 1}`}
                  />
                );
              })}

              {customYNLabels.map((label, index) => {
                if (!label) return;
                return (
                  <BoolCustomField
                    key={label}
                    control={control}
                    label={label}
                    name={`custYN${index + 1}`}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" position="sticky">
            <Button
              label="Submit"
              loading={custFieldsSaveLoading}
              disabled={isDisabled}
              secondary={!isDirty}
              type="submit"
            />
            <Spacer horizontal={true} size={10} />
            <Button
              label="Cancel"
              secondary={!isDirty}
              disabled={!isDirty}
              onClick={() => reset()}
            />
          </Grid>
        </Grid>
      )}
      {NavigationConfirm}
    </AccountsMainPanel>
  );
};
