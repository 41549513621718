import { FC, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import CommonModal from './CommonModal';
import Button from './buttons/MuiKendoButton';
// kendo
import { Loader } from '@progress/kendo-react-all';

/** ###  */
const ConfirmationModal: FC<{
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
  title: ReactNode;
  message: string;
  onConfirm: () => void | Promise<void>;
  isSubmitting?: boolean;
}> = ({ isOpen, setIsOpen, title, message, onConfirm, isSubmitting }) => {
  const handleClose = () => setIsOpen(false);
  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Grid container direction="column" flex={1} gap={3} px={3}>
        {typeof title === 'string' ? <h2>{title}</h2> : title}
        <Grid container direction="column" gap={1}>
          <div>{message}</div>
          <div>Continue?</div>
        </Grid>

        <Grid container direction="row" gap={2} alignSelf="center">
          <Button type='submit' onClick={handleConfirm}>
            {isSubmitting ? (
              <Loader themeColor="primary" style={{ color: '#d9deec' }} size="medium" />
            ) : (
              'Confirm'
            )}
          </Button>

          {/* @note this will replace below: <Button onClick={handleClose} color="error" sx={{ backgroundColor: 'rgb(245,20,20)' }}> */}
          <Button onClick={handleClose}>Cancel</Button>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default ConfirmationModal;
