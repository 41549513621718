import { FC } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DCS_BLUE } from '@/mui/theme/colors';

const borderRadius = '12px';
const paperColor = '#fff';
const bgStyle = { backgroundColor: DCS_BLUE };
const py = 1;
const px = 2;

/** ###  */
const DataDisplayHeader: FC<{ title: string; secondary?: string }> = ({ title, secondary }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      component={Typography}
      py={py}
      px={px}
      width="100%"
      fontSize={18}
      fontWeight={500}
      alignSelf="center"
      alignItems="center"
      justifyContent="space-between"
      color={paperColor}
      borderRadius={`${borderRadius} ${borderRadius} 0 0`}
      sx={bgStyle}
    >
      <Grid>{title}</Grid>
      <Grid fontSize={12}>{secondary}</Grid>
    </Grid>
  );
};

const displayBgStyle = { backgroundColor: paperColor };

const DataDisplay: FC<
  Grid2Props & { title: string; secondary?: string; bodyProps?: Grid2Props }
> = ({ title, secondary, children, bodyProps, ...props }) => {
  props.sx = { ...displayBgStyle, ...props.sx };

  return (
    <Grid
      container
      direction="column"
      flex={1}
      borderRadius={borderRadius}
      wrap="nowrap"
      {...props}
    >
      <DataDisplayHeader title={title} secondary={secondary} />

      <Grid
        container
        direction="column"
        flex={1}
        borderRadius={`0 0 ${borderRadius} ${borderRadius}`}
        gap={1}
        {...bodyProps}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default DataDisplay;
