import { FC, useState } from "react";
import { IconType } from "react-icons";
import { MdPrint } from "react-icons/md";
// mui
import Button from "@/mui/components/buttons/MuiKendoButton";
import { ButtonProps } from "@mui/material/Button";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
import PdfProvider, { usePdfCtx } from "./PdfProvider";
import { scienapticService } from "@/services/scienapticService";
import { Loader } from "@progress/kendo-react-all";

type IPrintPdfButtonProps = Omit<ButtonProps, "onClick" | "endIcon"> & {
  iconProps?: IconType;
  isInModal: boolean;
  isDeclinedStatus: boolean;
};

/** ###  */
const PrintPdfButtonLayout: FC<IPrintPdfButtonProps> = ({
  iconProps,
  isInModal,
  isDeclinedStatus,
  sx: sxProps,
  ...buttonProps
}) => {
  const currentAppEval = useAiUnderwritingCtx((s) =>
    isInModal ? s.selectedAppEval : s.latestAppEval
  );
  const instance = usePdfCtx((s) => s.instance);
  const [docsPrinting, setDocsPrinting] = useState(false);

  const handleAdverseActionPrint = async (scienapticEvaluationRecId: number) => {
    await scienapticService.PrintAdverseActionReport(scienapticEvaluationRecId);
    setDocsPrinting(false);
  };

  // Event handlers
  const handlePrintPdf = async () => {
    setDocsPrinting(true);

    if (currentAppEval === null) {
      console.error("No app-eval loaded.");
      setDocsPrinting(false);
      return;
    }

    const appEvalId = `${currentAppEval?.applicationId}-${currentAppEval?.dateCreated}'`;

    if (!instance.blob) {
      console.error(`No PDF generated for app-eval: '${appEvalId}'`);
      setDocsPrinting(false);
      return;
    }

    if (isDeclinedStatus) {
      if (!currentAppEval?.rec_id) {
        console.error("Adverse Action rec_id Not Validated");
        setDocsPrinting(false);
        return;
      }
      handleAdverseActionPrint(currentAppEval?.rec_id);
      return;
    }

    const fileLink = document.createElement("a");
    fileLink.href = window.URL.createObjectURL(instance.blob);

    const filename = `ScienapticEval-AppId-${currentAppEval?.applicationId}-${currentAppEval?.dateCreated}.pdf`;
    fileLink.download = filename;

    fileLink.click();
    window.open(fileLink.href);
    setDocsPrinting(false);
  };

  const isButtonDisabled = instance.loading || instance.blob === null;

  const buttonLabel = isDeclinedStatus ? "Print Decline Notice" : "Print Current Evaluation";
  return (
    <Button
      onClick={handlePrintPdf}
      endIcon={<MdPrint {...iconProps} color="white" />}
      disabled={isButtonDisabled}
      {...buttonProps}
      sx={{ ...sxProps }}
    >
      {docsPrinting ? (
        <Loader themeColor="primary" style={{ color: "#d9deec" }} size="large" />
      ) : (
        buttonLabel
      )}
    </Button>
  );
};

const PrintPdfButton: FC<IPrintPdfButtonProps> = (p) => (
  <PdfProvider>
    <PrintPdfButtonLayout {...p} />
  </PdfProvider>
);

export default PrintPdfButton;
