import { FC } from "react";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
// components
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// validation
import { UsaStateCode, usaStateCodes } from "@/general/regions";
// utils
import { getAddressFromGoogleMaps } from "@/utils/helpers/general";

const BillAddressField: FC = () => {
  const { setForm } = usePmtFormCtx((s) => s.formState);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billAddress"));

  return (
    <AddressAutocomplete
      // @todo move into form
      label="Address"
      required
      onPlaceSelected={(place: google.maps.places.PlaceResult) => {
        const fullAddress = getAddressFromGoogleMaps(place);

        // @note With compound city-names, `fullAddress.city` may be an empty string. Example: '123 Main Street, Flushing, NY, USA'
        const altCity = place.address_components && place.address_components[2]?.long_name;
        const appliedCity = fullAddress.city || altCity || "";

        setForm((prevForm) => ({
          ...prevForm,
          billAddress: fullAddress.address ?? "",
          billCity: appliedCity,
          billState: (fullAddress.state ?? "") as UsaStateCode,
          billZip: fullAddress.zip ?? "",
        }));
      }}
      {...fieldProps}
    />
  );
};

const BillCityField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billCity"));
  return <TextInput label="City" required {...fieldProps} />;
};
const BillStateField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billState"));
  return <DropdownInput label="State" required data={usaStateCodes} {...fieldProps} />;
};
const BillZipField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billZip"));
  return <TextInput label="Zip" required {...fieldProps} />;
};

/** ###  */
const CcBillingFields: FC = () => [
  <BillAddressField key="billAddressField" />,
  <BillCityField key="billCityField" />,
  <BillStateField key="billStateField" />,
  <BillZipField key="billZipField" />,
];

export default CcBillingFields;
