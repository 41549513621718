import { Navigate, NavigateProps } from "react-router-dom";

const defaultNavProps: NavigateProps = { to: ".", relative: "route", replace: true } as const;

/** ### Returns a `<Navigate />` component which navigates up one level when the provided condition = `true`
 * @note Only for use in component body
 * @note For use in DOM-element event-handlers: see `useNavUpEvent()` component in `useNavUpEvent.tsx`
 * @default defaultNavProps = `{ to: ".", relative: "route", replace: true }`
 *
 * #### Usage
 * ```tsx
 * const MyComponent: FC = () => {
 *   // ...
 *   // Example #1
 *   if (isCreateUserView) return useNavUpElem();
 *
 *   return (
 *     // Example #2
 *     useNavUpElem(isCreateUserView) || (
 *       <ViewLayout
 *         headerProps={{
 *           primaryText: 'Settings and Security',
 *           secondaryText: secondary,
 *           viewSelector: <UserMgmtViewToggleButton />,
 *         }}
 *       >
 *         <Outlet />
 *       </ViewLayout>
 *     )
 *   );
 * }
 * ```
 *
 * @todo add ability to enter number of levels - `(numberOfLevels: number = 1)`
 */
const useNavUpElem = (condition: boolean = true, navProps?: NavigateProps): JSX.Element | void => {
  if (condition === true) {
    return <Navigate {...defaultNavProps} {...navProps} />;
  }
};

export default useNavUpElem;
