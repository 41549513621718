import dayjs from "dayjs";
import { z } from "zod";
// validation
import { ZMpd } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSchemas";
import { zDayjs } from "@/utils/forms/fieldSchemas";
import { zAddIssue } from "@/utils/forms/zod";

/** Convert concatenated date string "mmyy" to dayjs */
const expDate = z.preprocess((input, ctx) => {
  const dateStrVal = z.string().safeParse(input);

  if (dateStrVal.data && !dateStrVal.error) {
    const dateStr = dateStrVal.data;
    const month = dateStr.slice(0, 2);
    const year = dateStr.slice(2);
    if (dateStr.length === 4 || dateStr.length === 6) return dayjs(`${month}/01/${year}`);
  }

  zAddIssue([input, ctx], "expDate", `Invalid exp date input: '${input}'`);
  return z.NEVER;
}, zDayjs);

const CliqResCard = z.object({
  number: z.string(), // CcObfuscated = `${number}******${number}`
  bin: z.string(), // CcBinStr = `${number}`; // "411111"
  exp: z.string(), // CcExpStr = `${number}`; // "1129"
  type: z.string(), // CcType = "visa" | "mastercard" | "amex";
  hash: z.string(), // string
});
const CliqResCheck = z.object({
  name: z.string().nullish().catch(null),
  account: z.any().nullish().catch(null),
  aba: z.any().nullish().catch(null),
  transit: z.any().nullish().catch(null),
  institution: z.any().nullish().catch(null),
});

// Cliq Response: Wallet fields
const CliqResWalletBillingInfo = z.object({
  address1: z.coerce.string().nullish().catch(null),
  address2: z.coerce.string().nullish().catch(null),
  firstName: z.coerce.string().nullish().catch(null),
  lastName: z.coerce.string().nullish().catch(null),
  postalCode: z.coerce.string().nullish().catch(null),
  city: z.coerce.string().nullish().catch(null),
  state: z.coerce.string().nullish().catch(null),
  country: z.coerce.string().nullish().catch(null),
  phone: z.coerce.string().nullish().catch(null),
});
const CliqResWalletShippingInfo = z.object({
  method: z.coerce.string().nullish().catch(null),
  address1: z.string().nullish().catch(null),
  address2: z.string().nullish().catch(null),
  firstName: z.string().nullish().catch(null),
  lastName: z.string().nullish().catch(null),
  postalCode: z.coerce.string().nullish().catch(null),
  city: z.string().nullish().catch(null),
  state: z.string().nullish().catch(null), // UsaStateCode
  country: z.string().nullish().catch(null),
  phone: z.string().nullish().catch(null),
});
const CliqResWallet = z.object({
  cardDetails: z.any().nullish().catch(null), // @todo fill out
  cardNetwork: z.any().nullish().catch(null), // @todo fill out
  email: z.string().email().nullish().catch(null),
  billingInfo: CliqResWalletBillingInfo.nullish().catch(null),
  shippingInfo: CliqResWalletShippingInfo.nullish().catch(null),
});

export const CollectJsResSchema = z.object({
  tokenType: z.enum(["inline", "lightbox"]),
  token: z.string(), // ApiKey = `${string}-${string}-${string}-${string}`
  // @note `initiatedBy` shares the same name as a field used in the DMS-API. Here, this is specific to the Cliq response.
  // initiatedBy: PointerEvent,
  card: CliqResCard,
  check: CliqResCheck.nullish().catch(null),
  wallet: CliqResWallet.nullish().catch(null),
});
export type CollectJsResSchema = z.infer<typeof CollectJsResSchema>;

export const cliqResToMpd = z.preprocess((cliqRes_) => {
  const cliqRes = cliqRes_ as CollectJsResSchema;

  return {
    token: cliqRes.token,
    lastFour: cliqRes.card.number.slice(cliqRes.card.number.length - 4),
    brand: cliqRes.card.type,
    exp: cliqRes.card.exp,
    accountNumber: cliqRes.check?.account,
    routingNumber: cliqRes.check?.aba,
  } as ZMpd;
}, ZMpd);
