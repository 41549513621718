import { FC, PropsWithChildren } from 'react';
// utils
import { currentEnv, envConfigMap } from '.';
import { RuntimeEnvKey } from './interfaces';

/** ### Flag a feature for a specific environment by wrapping with this component
 * @todo add provider for environment
 * 
 * Usage:
 * ```tsx
 * // Prevent entire set of routes from rendering
 * <FeatureFlag maxAllowedEnv="qa" conditions={[isDcsEmail]}>
 *   <Routes>
 *     <Route path="user-management/*">
 *       <Route index element={<UserAuthTable />} />
 *       <Route path="user/:userRecId" element={<UserAuthDetail />} />
 *       // ...
 *     </Route>
 *   </Routes>
 * </FeatureFlag>
 * ```
 *
 * @todo add environment variables to global state context
 */
const FeatureFlag: FC<
  PropsWithChildren & {
    maxAllowedEnv: RuntimeEnvKey;
    /** If ***all*** conditions are true (excluding `maxAllowedEnv`): render component.
     *
     * Accepts a single boolean or an array of booleans.
     */
    conditions?: boolean | boolean[];
  }
> = ({ maxAllowedEnv, children, conditions = [] }) => {
  const areCustomConditionsMet =
    typeof conditions === 'boolean' ? conditions : conditions.every((c) => c);

  // @note testing - get the name of the component - does this render in prod?
  if (currentEnv.key === 'development' || currentEnv.key === 'qa') {
    try {
      // @ts-ignore
      console.log('Name of component wrapped in feature flag: ', children?.valueOf().type.name);
    } catch (_) {}
  }

  // Check if environment is set properly
  const maxAllowedEnvVal = RuntimeEnvKey.safeParse(maxAllowedEnv);
  if (!maxAllowedEnvVal.success) {
    console.error(maxAllowedEnvVal.error);
    return <></>;
  }
  const maxEnvLevelConfig = envConfigMap.get(maxAllowedEnvVal.data);

  if (!maxEnvLevelConfig) {
    console.error('Invalid config:', maxAllowedEnvVal.data, 'for environment key:', maxAllowedEnv);
    return <></>;
  }

  const shouldRender = currentEnv.level <= maxEnvLevelConfig.level && areCustomConditionsMet;
  console.warn('current:', currentEnv, 'max', maxEnvLevelConfig, { areCustomConditionsMet });

  if (shouldRender) return <>{children}</>;

  return <></>;
};

export default FeatureFlag;
