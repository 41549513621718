import { FC } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";

const TooltipTextDisplay: FC<Grid2Props> = () => {
  return (
    <Grid container direction="column" component="span">
      <Grid fontSize={15} fontWeight={700} maxWidth={150} sx={{ textWrap: 'wrap'}}>To add a new vendor, please navigate to the Settings tab.</Grid>
    </Grid>
  );
};

/** ###  */
const TooltipLayout: FC<Omit<TooltipProps, "title">> = ({ children, sx, ...props }) => {
  return (
    <Tooltip
      title={<TooltipTextDisplay />}
      placement="right-start"
      sx={sx}
      slots={{ tooltip: Paper }}
      slotProps={{
        tooltip: {
          sx: { mx: 0.5, my: 0, px: 1, py: 1, boxShadow: "0px 2px 5px 2px rgba(0,0,0,0.1)" },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipLayout;
