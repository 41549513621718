import { FC, useState } from "react";
// mui
import { DataGrid, GridSortItem, GridSortModel } from "@mui/x-data-grid";
// components
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import AddNewButton from "../AddNewButton";
// state
import { useSettingsViewCtx } from "../../AuthSettingsViewProvider";
// utils
import { getRowId } from "@/mui/components/dataTable/utils";
import { tableColumns } from "./tableColumns";
import { initPage, rowHeightPx, tableStyle } from "../../default";
// interfaces
import { UserAuthRow } from "../../interfaces/user";

const initSort: (GridSortItem & { field: keyof UserAuthRow })[] = [
  { field: "fullName", sort: "asc" },
];

/** ###  */
const UserAuthTable: FC = () => {
  const userRows = useSettingsViewCtx((s) => s.userRows);
  const isUsersReqLoading = useSettingsViewCtx((s) => s.users.isLoading);

  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>(initSort);
  const [pageModel, setPageModel] = useState(initPage);

  return (
    <DataGrid
      loading={isUsersReqLoading}
      rows={userRows}
      columns={tableColumns}
      getRowId={getRowId("recId")}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      paginationModel={pageModel}
      onPaginationModelChange={setPageModel}
      rowHeight={rowHeightPx}
      slots={{ toolbar: TableToolbar }}
      slotProps={{ toolbar: { placeholder: "Search filter", ButtonGroup: AddNewButton } }}
      sx={tableStyle}
    />
  );
};

export default UserAuthTable;
