import { FC } from 'react';
// components
import DateForm from './DateForm';
import AmtForm from './AmtForm';
import IntForm from './IntForm';
// utils
import { useReportsCtx } from '../ReportsList/defaultState';
// style
import styles from '../Reports.module.scss';

/** ### Displays forms in modal which filter the export file across one or multiple parameters.
 * - Handles multiple form types
 * - 1 or multiple forms may appear
 * - Only renders if conditions for filter utilization are met - set in the report body
 *
 * Since component only renders if selected report is set:\
 * We do not need to conditionally render.
 */
const ExportForms: FC = () => {
  const selectedReport = useReportsCtx(s => s.selectedReport);

  return (
    <div className={styles.exportForm}>
      {selectedReport!.useDateRange && <DateForm />}
      {selectedReport!.useAmt && <AmtForm />}
      {selectedReport!.useInt && <IntForm />}
    </div>
  );
};

export default ExportForms;
