import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
// mui
import { GridRenderCellParams } from '@mui/x-data-grid';
// utils
import { currencyCellFmt, dateCellFmt } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import { TerminationRowData } from '../../interfaces';

const LinkCell: FC<GridRenderCellParams<TerminationRowData>> = ({ row }) => {
  return <NavLink to={`/accounts/${row.recId}/`}>{row.accountNum}</NavLink>;
};

export const tableColumns: MuiColumnDef<TerminationRowData>[] = [
  { field: 'accountNum', headerName: 'Account', renderCell: LinkCell }, // AccountLinkCell
  { field: 'buyers', headerName: 'Buyer', flex: 1 },
  { field: 'vehicle', headerName: 'Vehicle' },
  {
    field: 'dateSold',
    headerName: 'Sold',
    type: 'dateTime',
    valueFormatter: dateCellFmt,
    valueGetter: (p) => dayjs(p?.row.dateSold).toDate(),
  },
  {
    field: 'chargeOffDate',
    headerName: 'Terminated',
    type: 'dateTime',
    valueFormatter: dateCellFmt,
    valueGetter: (p) => dayjs(p?.row.chargeOffDate).toDate(),
  },
  { field: 'chargeOffCategory', headerName: 'Reason' },
  {
    field: 'profitLoss',
    headerName: 'Profit/Loss',
    valueFormatter: currencyCellFmt,
    type: 'number',
  },
  { field: 'code3', headerName: 'Lot' },
];
