import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// kendo
import { Loader } from '@/components/loader/Loader';
import { accountsService } from '@/services/accountsService';
import { OdometerTrackingData } from '@/interfaces';
import { formatDate } from '@/utils/helpers/general';
import { BasicTable } from '@/components/table/Table';
import { Column } from '@/components/table/TableInterface';
import { inventoryService } from '@/services/inventoryService';
// style
import styles from './OdometerTracking.module.scss';

const OdometerDataItem: FC<{ title: string; data: string | number | null }> = (props) => (
  <div>
    <span className={styles.dataItemTitle}>{props.title}: </span>
    {props.data}
  </div>
);

const odometerTrackingColumns: Column[] = [
  {
    field: 'dateLocal',
    title: 'Date',
    cell: (props) => {
      const { dateLocal } = props.dataItem;
      return (
        <td className="k-table-td">
          <span>{formatDate(dateLocal)}</span>
        </td>
      );
    },
  },
  {
    field: 'odometerCurrent',
    title: 'Odo. Reading',
  },
  {
    field: 'source',
    title: 'Source',
  },
  {
    field: 'odometerElapsed',
    title: 'Miles Used',
  },
  {
    field: 'daysElapsed',
    title: 'Days',
  },
  {
    field: 'perDay',
    title: 'Per Day',
  },
  {
    field: 'perMonth',
    title: 'Per Month',
  },
  {
    field: 'perYear',
    title: 'Per Year',
  },
];

export const OdometerTrackingList: FC<{ isInventory?: boolean }> = ({ isInventory = false }) => {
  const params = useParams();
  const recId = isInventory ? Number(params.invRecId) : Number(params.colRecId);
  const [odometerData, setOdometerData] = useState<OdometerTrackingData>();
  const [dataLoading, setDataLoading] = useState(false);

  const initData = async () => {
    setDataLoading(true);
    if (isInventory) {
      await inventoryService
        .getOdometerTracking(recId)
        .then((odoData) => setOdometerData(odoData))
        .finally(() => {
          setDataLoading(false);
        });
    } else {
      await accountsService
        .getOdometerTracking(recId)
        .then((odoData) => setOdometerData(odoData))
        .finally(() => {
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!recId) return;
    initData();
  }, [recId]);

  return dataLoading ? (
    <Loader size="large" />
  ) : !odometerData ? (
    <div>Odometer data is unavailable</div>
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.data}>
            <OdometerDataItem title="Miles At Sale" data={odometerData.milesOut} />
            <OdometerDataItem title="Date Sold" data={formatDate(odometerData.dateSold)} />
          </div>
        </div>
        {!odometerData.odometerRecords || !odometerData.odometerRecords.length ? (
          <div>No odometer records found for this {isInventory ? 'vehicle' : 'account'}</div>
        ) : (
          <BasicTable data={odometerData.odometerRecords} columns={odometerTrackingColumns} />
        )}
      </div>
    </>
  );
};
