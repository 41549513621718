import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// interfaces
import { SamTask } from '@/interfaces/sam';

/** ### Row element within a hot-list */
const HotlistItem: FC<{ hotlistItem: SamTask }> = ({ hotlistItem: { item } }) => {
  return (
    <Grid p="10px 5px" sx={{ '&:not(:lastOfType)': { borderBottom: '1px solid #ddd' } }}>
      {item || '-'}
    </Grid>
  );
};

export default HotlistItem;
