import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import CollectionQueuesTable from "./CollectionQueuesTable";
// import QueueDetailForm from './QueueDetailForm';
import CollectionsModal from "../components/CollectionsModal";
import CollectionQueuesListProvider from "./CollectionQueuesListProvider";

/** ###  */
const CollectionQueuesList: FC = () => (
  <Grid container flex={1}>
    <CollectionQueuesListProvider>
      <CollectionQueuesTable />
    </CollectionQueuesListProvider>

    {/* @todo in progress */}
    {/* <QueueDetailForm /> */}

    <CollectionsModal />
  </Grid>
);

export default CollectionQueuesList;
