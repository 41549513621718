import { FC, useEffect } from "react";
import { toast } from "react-toastify";
// state
import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";
// interfaces
import { RepoCompany } from "@/interfaces/RepoCompany";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
// components
import BackLink from "@/mui/components/buttons/BackLink";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { Button, Loader } from "@/components";
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { Controller } from "react-hook-form";
// services
import { companyService } from "@/services/companyService";

const CancelButton: FC<{onClick?: () => void}> = ({ onClick = () => null }) => {
  const { reset } = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);
  const activeRepoCompany = useRepoCompanyProviderCtx((s) => s.activeRepoCompany);

  // Event handlers
  const handleClickCancel = () => {
    reset({ ...activeRepoCompany } as RepoCompany);
    onClick && onClick();
  };

  return (
    <Button type="button" label="Cancel" onClick={handleClickCancel} />
  );
};

const SubmitButton: FC = () => {
  const { isDirty, isSubmitting } = useRepoCompanyProviderCtx(
    (s) => s.repoCompanyDetailForm.formState
  );

  return (
    <Button
      type="submit"
      disabled={!isDirty || isSubmitting}
      loading={isSubmitting}
      label={"Submit"}
    />
  );
};

const RepoCompanyDetail: FC<{isNew?: boolean, onSubmit?: () => void, onCancel?: () => void}> = ({ isNew = false, onSubmit = () => null, onCancel = () => null }) => {
  const setActiveRepoCompany = useRepoCompanyProviderCtx((s) => s.setActiveRepoCompany);
  const repoCompanies = useRepoCompanyProviderCtx((s) => s.repoCompanies);
  const loadRepoCompanies = useRepoCompanyProviderCtx((s) => s.loadRepoCompanies);
  const resetToDefault = useRepoCompanyProviderCtx((s) => s.resetToDefault);
  const { handleSubmit, control, reset } = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);
  
  const handleSubmitForm = async (form: RepoCompany) => {
    try {
      const res = await companyService.upsertRepoCompany(form);

      if (form.recId) {
        reset(form);
        setActiveRepoCompany(form);
        toast.success("Repo Company updated successfully");
      }
      
      onSubmit && onSubmit();
    } catch (error) {
      toast.error("There was an issue saving the repo company")
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (isNew) {
      setActiveRepoCompany(undefined);
      resetToDefault();
    } else {
      !repoCompanies && loadRepoCompanies.load();
    }
  }, [])

  return (
    <>
      {loadRepoCompanies.isLoading 
        ? <Loader size="large"/> 
        : <>
          { !isNew && <BackLink onClick={() => loadRepoCompanies.load()} /> }
          <Grid 
            container 
            direction="column" 
            flex={1} 
            padding={2}
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              direction="column"
              onSubmit={handleSubmit(handleSubmitForm)}
              gap={3}
              flex={1}
              component="form"              
            >
              <Grid container direction="row" flex={1}>
                <Box display="flex" alignItems="center" width="100%" marginTop={2}>
                  <Box fontSize="20px" fontWeight="700" marginRight={2}>
                    {isNew ? "Add New Repo Company" : "Repo Company Details"}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" width="100%" marginTop={2} marginBottom={2}>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      marginRight: "16px",
                      backgroundColor: "#e6e6e6",
                    }}
                  />
                </Box>
              </Grid>

              <Grid container direction="row" flex={1} gap={3}>
                <LeftContainer />
                <RightContainer />         
              </Grid>

              <Grid container direction="column" gap={1}>
                <Controller
                  name="notes"
                  control={control}
                render={({ field }) => <TextArea label="Notes" rows={3} {...field} value={field.value ?? ''}/>}
                />
              </Grid>

              <Grid container direction="row" justifyContent="end" gap={1}>
                <SubmitButton />
                <CancelButton onClick={onCancel} />
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </>
  );
};

export default RepoCompanyDetail;
