import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// kendo
import { Button } from '@/components/button/Button';
import { Spacer } from '@/components/spacer/Spacer';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
// utils
import { accountsService } from '@/services/accountsService';
import useFetchAndNavAccountDetail from '@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/useFetchAndNav';
import { sanitizePhoneInput } from '@/utils/helpers/general';

/**
 * @note `accountInformation !== null` is validated in parent component: `Quick-Actions-Modal`
 */
const SendTextToPay: FC = () => {
  const params = useParams();
  const loc = useLocation();
  const colRecId = Number(params.colRecId);
  const fetchAndNav = useFetchAndNavAccountDetail();
  // Redux state
  const buyerCellPhone = useAccountSelector((s) => s.accountInformation!.buyer.cellPhone);
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const buyerEmail = useAccountSelector((s) => s.accountInformation!.buyer.email);
  const isLegalStatus = useAccountSelector((s) => s.accountInformation!.legalStatus);
  const shouldNotTextBuyer = useAccountSelector((s) => s.contactInformation.buyer.noText);
  const sendTextToPayResponse = useAccountSelector((s) => s.sendTextToPayResponse);
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);

  // Event handlers
  const dispatch = useAppDispatch();
  const onCloseButtonClick = () => dispatch(accountActions.setQuickActionsModalOpened(false));

  const handleSendTextToPay = () => {
    accountsService
      .sendTextToPay(appRecId, colRecId, buyerEmail, sanitizePhoneInput(buyerCellPhone))
      .then((data) => {
        if (data.success) {
          dispatch(
            accountActions.setSendTextToPayResponse('Thank you, your message has been sent')
          );

          // Update relevant SAM hotlist AFTER response is received
          fetchHotlist(appRecId!, 'Account', 'Active');
          // Fetch info, update state and navigate to acct-detail base-route
          const browserPath = loc.pathname.replace(params['*'] || ' ', '');
          fetchAndNav(browserPath, colRecId);
        } else {
          // Display the message from the backend
          dispatch(
            accountActions.setSendTextToPayResponse(data.message || 'Unable to send text-to-pay message')
          );
        }
      })
      .catch((err) => {
        dispatch(
          accountActions.setSendTextToPayResponse('Unable to send text-to-pay message')
        );
      });
  };

  if (shouldNotTextBuyer) {
    return <div>Buyer has no text preference on</div>;
  } else if (isLegalStatus) {
    return <div>Account in legal status, no contact can be made</div>;
  } else if (sendTextToPayResponse) {
    return <div>{sendTextToPayResponse}</div>;
  } else {
    return (
      <div>
        <div>{`Are you sure you want to send a text to pay message to ${
          buyerCellPhone || ''
        }?`}</div>
        <Spacer size={20} />
        <Button
          label="Yes"
          onClick={handleSendTextToPay}
        />
        <Spacer size={4} />
        <Button label="No" fillMode="outline" onClick={onCloseButtonClick} />
      </div>
    );
  }
};

export default SendTextToPay;
