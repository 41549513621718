import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
//mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Button } from '@/components/button/Button';
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
import { FunctionLink } from '@/components/functionLink/FunctionLink';
import ConfirmationModal from '@/components/confirmationModal/confirmationModal';
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextInput } from '@/components/inputs/text/TextInput';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
// utils
import { vinRegex } from '@/utils/helpers/formValidation';
import { inventoryService } from '@/services/inventoryService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { ApiErrorMessage } from '@/interfaces/Api';
import { ColorListItem, VehicleDetails } from '@/interfaces/Inventory';
import { ListItem } from './interfaces';
// style
import styles from './InventoryVehicle.module.scss';

interface DeleteCarForm {
  invRecId: number;
  reason: string;
}

interface CarTruckOption {
  label: 'Car' | 'Truck';
  value: false | true;
}

const carTruckOptions: CarTruckOption[] = [
  { label: 'Car', value: false },
  { label: 'Truck', value: true },
];

const getMpgCombined = (mpgCityString: string | undefined, mpgHwyString: string | undefined) => {
  const mpgCity = parseInt(mpgCityString ?? '');
  const mpgHwy = parseInt(mpgHwyString ?? '');

  let mpgCombined = null;
  if (mpgCity > 0 && mpgHwy > 0) {
    // if(MpgCity > 0 and MpgHwy > 0,round(((MpgCity *.55)+(MpgHwy  *.45)),0),0)
    mpgCombined = Math.round(mpgCity * 0.55 + mpgHwy * 0.45);
  }

  return mpgCombined;
};

const VehicleDetailsForm: FC<{
  vehicleDetails: VehicleDetails;
  setDirty: SetState<boolean>;
  colorOptions: ColorListItem[];
  bodyStyleOptions: ListItem[];
  vehicleTypeOptions: ListItem[];
  transmissionOptions: ListItem[];
  fuelTypeOptions: ListItem[];
  vehicleSizeOptions: ListItem[];
}> = ({
  vehicleDetails,
  setDirty,
  colorOptions,
  bodyStyleOptions,
  vehicleTypeOptions,
  transmissionOptions,
  fuelTypeOptions,
  vehicleSizeOptions,
}) => {
  const navigate = useNavigate();
  const invRecId = getRouteParamNum(useParams().invRecId);

  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);
  const invChangeStkNum = useAuthSelector((s) => s.backDateInv);
  const invDelete = useAuthSelector((s) => s.invDelete);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [showDeleteCarConfirmationModal, setShowDeleteCarConfirmationModal] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<VehicleDetails>({
    defaultValues: {
      ...vehicleDetails,
      mpgCombined: getMpgCombined(vehicleDetails.mpgCity, vehicleDetails.mpgHwy)?.toString(),
    },
  });

  const deleteCarForm = useForm<DeleteCarForm>({
    defaultValues: { reason: '' },
    mode: 'onChange',
  });

  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  const submitVehicleDetailsForm = async (formBody: VehicleDetails) => {
    if (!formBody || !invRecId) return;

    try {
      setIsLoading(true);
      const updatedVehicleDetails = await inventoryService.updateVehicleDetails(
        invRecId.toString(),
        formBody
      );
      reset(updatedVehicleDetails);
      toast.success('Vehicle details updated!');
      reset(watch(), { keepDirty: false });
      // Update relevant SAM hotlist AFTER response is received
      // @note `invRecId` is the same as `generalInformation!.recId`
      fetchHotlist(Number(invRecId), 'Inventory', 'Active');
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (responseData?.message === 'Duplicate VIN not allowed') {
        setError('vin', { type: 'custom', message: responseData?.message });
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const submitDeleteCarForm = async (formData: DeleteCarForm) => {
    const { reason, invRecId } = formData;
    if (!reason || reason.length <= 0) {
      toast.error('Cannot delete car without specifying a reason');
    } else {
      if (!invRecId) return;

      try {
        setIsDeleting(true);
        await inventoryService.deleteVehicle(invRecId, reason);

        toast.success('Vehicle deleted successfully');
        navigate('/inventory');
      } catch (e) {
        const error = e as AxiosError;
        const responseData = error.response?.data as ApiErrorMessage;
        toast.error('There was an error deleting the vehicle');
        throw e;
      } finally {
        setIsDeleting(false);
        setDeletePopUpOpen(false);
      }
    }
  };

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  const getVinAuditData = async () => {
    const validVin = await trigger('vin');
    if (!validVin) return;

    const vin = watch('vin');
    if (!vin) return;
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');

    const res = await inventoryService.vinAuditDecode(vin);

    // The API doesn't throw an error if decoding is unsuccessful, so we'll just have to determine it based on whether most values are null
    if (!res.year && !res.make && !res.model) {
      toast.error('Unable to decode VIN');
    }

    if (res?.year) setValue('year', res.year, { shouldDirty: true, shouldValidate: true });
    if (res?.make) setValue('make', res.make, { shouldDirty: true, shouldValidate: true });
    if (res?.model) setValue('model', res.model, { shouldDirty: true, shouldValidate: true });
    if (res?.shortTrim) setValue('trim', res.shortTrim, { shouldDirty: true });
    if (res?.transmissionType)
      setValue('transmission', res.transmissionType, { shouldDirty: true });
    if (res?.vehicleStyle) setValue('bodyStyle', res.vehicleStyle, { shouldDirty: true });
    if (res?.vehicleType) setValue('vehType', res.vehicleType, { shouldDirty: true });
    if (res?.vehicleSize) setValue('vehSize', res.vehicleSize, { shouldDirty: true });
    if (res?.grossWeight) setValue('weight', res.grossWeight, { shouldDirty: true });
    if (res?.fuelCapacity) setValue('fuelCap', res.fuelCapacity, { shouldDirty: true });
    if (res?.engine) setValue('engine', res.engine, { shouldDirty: true });
    if (res?.engineCylinders) setValue('cylinders', res.engineCylinders, { shouldDirty: true });
    if (res?.drivenWheels) setValue('driveTrain', res.drivenWheels, { shouldDirty: true });
    if (res?.engineSize) setValue('engine_Size', res.engineSize, { shouldDirty: true });
  };

  const updateMpgCombined = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: keyof VehicleDetails
  ) => {
    setValue(fieldName, event.target.value, { shouldDirty: true });

    const [mpgCityValue, mpgHwyValue] = getValues(['mpgCity', 'mpgHwy']);

    const mpgCombined = getMpgCombined(mpgCityValue, mpgHwyValue);
    setValue('mpgCombined', mpgCombined?.toString());
  };

  const clearYearMakeModelFields = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue('vin', event.target.value, { shouldDirty: true });

    const validVin = await trigger('vin');
    if (!validVin) return;
    if (event.target.value === vehicleDetails.vin) return;

    setValue('year', '', { shouldValidate: true });
    setValue('make', '', { shouldValidate: true });
    setValue('model', '', { shouldValidate: true });
  };

  const selectedCarTruckOption = carTruckOptions.find((o) => o.value == getValues().carTruck);
  const vehicleSold = vehicleDetails.saleStatus?.toLowerCase() === 's';
  const canDelete = invRecId && invDelete && vehicleDetails.saleStatus?.toLowerCase() === 'u';

  return (
    <>
      <form onSubmit={handleSubmit(submitVehicleDetailsForm)} style={{ marginTop: '20px' }}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <Controller
              name="stockNum"
              control={control}
              render={({ field }) => (
                <TextInput label="Stock #" disabled={vehicleSold && !invChangeStkNum} {...field} />
              )}
            />
            <Controller
              name="vin"
              control={control}
              rules={{
                validate: (v: string | undefined) =>
                  !vinRegex.test(v ?? '') ? 'Please enter a valid VIN' : undefined,
                required: 'VIN is required',
              }}
              render={({ field }) => (
                <TextInput
                  label="VIN"
                  disabled={vehicleDetails.nonVeh}
                  errors={errors.vin?.message}
                  {...field}
                  onChange={(e) => clearYearMakeModelFields(e)}
                />
              )}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1.25 }}>
                <FunctionLink onClick={getVinAuditData} label="Decode Vin" />
              </div>
            </div>
            <Controller
              name="year"
              control={control}
              rules={{
                required: 'Year is required',
              }}
              render={({ field }) => (
                <TextInput label="Year" errors={errors.year?.message} {...field} />
              )}
            />
            <Controller
              name="make"
              control={control}
              rules={{
                required: 'Make is required',
              }}
              render={({ field }) => (
                <TextInput label="Make" errors={errors.make?.message} {...field} />
              )}
            />
            <Controller
              name="model"
              control={control}
              rules={{
                required: 'Model is required',
              }}
              render={({ field }) => (
                <TextInput label="Model" errors={errors.model?.message} {...field} />
              )}
            />
            <Controller
              name="trim"
              control={control}
              render={({ field }) => <TextInput label="Trim" {...field} />}
            />
            <Controller
              name="bodyStyle"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Body Type"
                  data={bodyStyleOptions.map((c) => c.item)}
                  {...field}
                />
              )}
            />
            <Controller
              name="vehType"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Vehicle Type"
                  data={vehicleTypeOptions.map((c) => c.item)}
                  {...field}
                />
              )}
            />
            <Controller
              name="driveTrain"
              control={control}
              render={({ field }) => <TextInput label="Drivetrain" {...field} />}
            />
            <Controller
              name="transmission"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Transmission"
                  data={transmissionOptions.map((c) => c.item)}
                  {...field}
                />
              )}
            />
            <Controller
              name="carTruck"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Car/Truck"
                  data={carTruckOptions}
                  dataItemKey="value"
                  textField="label"
                  allowCustom={false}
                  {...field}
                  value={selectedCarTruckOption}
                  onChange={(e) =>
                    setValue('carTruck', (e.value as CarTruckOption).value, { shouldDirty: true })
                  }
                />
              )}
            />
            <span className={styles.sectionSubHeader}>Gas Mileage</span>
            <Controller
              name="mpgCity"
              control={control}
              render={({ field }) => (
                <TextInput
                  label="MPG City"
                  {...field}
                  onChange={(e) => updateMpgCombined(e, 'mpgCity')}
                />
              )}
            />
            <Controller
              name="mpgHwy"
              control={control}
              render={({ field }) => (
                <TextInput
                  label="MPG Highway"
                  {...field}
                  onChange={(e) => updateMpgCombined(e, 'mpgHwy')}
                />
              )}
            />
            <Controller
              name="mpgCombined"
              control={control}
              render={({ field }) => <TextInput label="MPG Combined" disabled={true} {...field} />}
            />
          </div>
          <div className={styles.column}>
            <Controller
              name="fuelType"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Fuel" data={fuelTypeOptions.map((c) => c.item)} {...field} />
              )}
            />
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Color" data={colorOptions.map((c) => c.item)} {...field} />
              )}
            />
            <Controller
              name="interior"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Interior Color"
                  data={colorOptions.map((c) => c.item)}
                  {...field}
                />
              )}
            />
            <Controller
              name="weight"
              control={control}
              render={({ field }) => <TextInput label="Weight" {...field} />}
            />
            <Controller
              name="engine"
              control={control}
              render={({ field }) => <TextInput label="Engine" {...field} />}
            />
            <Controller
              name="cylinders"
              control={control}
              render={({ field }) => <TextInput label="Cylinders" {...field} />}
            />
            <Controller
              name="horsepower"
              control={control}
              render={({ field }) => <TextInput label="Horsepower" {...field} />}
            />
            <Controller
              name="vehSize"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Vehicle Size"
                  data={vehicleSizeOptions.map((c) => c.item)}
                  {...field}
                />
              )}
            />
            <Controller
              name="fuelCap"
              control={control}
              render={({ field }) => <TextInput label="Fuel Cap" {...field} />}
            />
            <Controller
              name="engine_Size"
              control={control}
              render={({ field }) => <TextInput label="Engine Size" {...field} />}
            />
          </div>
        </div>
        <div className={styles.buttonRow}>
          <Button label="Submit" secondary={!isDirty} disabled={!isDirty} loading={isLoading} />
          <Button label="Cancel" secondary={!isDirty} disabled={!isDirty} onClick={() => reset()} />

          {canDelete && (
            <DropDownButton
              label="Delete"
              open={deletePopUpOpen}
              setOpen={setDeletePopUpOpen}
              popUpProps={{
                popupAlign: { vertical: 'top', horizontal: 'right' },
                anchorAlign: { vertical: 'bottom', horizontal: 'right' },
              }}
              buttonProps={{ loading: isDeleting, type: 'button' }}
            >
              <Grid
                container
                direction="column"
                flex={1}
                gap={3}
                p={3}
                marginBottom={3}
                bgcolor="#dadde7"
                borderRadius={2}
              >
                <Grid container direction="column" gap={1}>
                  <Controller
                    name="reason"
                    control={deleteCarForm.control}
                    rules={{ required: 'Reason is required' }}
                    render={({ field }) => (
                      <TextArea
                        label="Enter reason for deleting this vehicle"
                        cols={25}
                        rows={10}
                        required
                        rounded={'medium'}
                        spellCheck="true"
                        errors={deleteCarForm.formState.errors.reason?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid container direction="row" gap={2} alignSelf="end">
                  <Button
                    label="Delete Car"
                    loading={isDeleting}
                    onClick={async () => {
                      const isDeleteFormValid = await deleteCarForm.trigger();
                      if (isDeleteFormValid) {
                        setShowDeleteCarConfirmationModal(true);
                      }
                    }}
                    type="button"
                  />
                  <Button
                    label="Cancel"
                    onClick={() => {
                      setDeletePopUpOpen(false);
                      deleteCarForm.reset();
                    }}
                  />
                </Grid>
              </Grid>

              <ConfirmationModal
                opened={showDeleteCarConfirmationModal}
                setOpened={setShowDeleteCarConfirmationModal}
                confirmationAction={deleteCarForm.handleSubmit(submitDeleteCarForm)}
                message="Are you sure you want to delete this vehicle?"
                cancelAction={() => {
                  setDeletePopUpOpen(false);
                  deleteCarForm.reset();
                }}
                dimmerStyle={{ borderRadius: 8, marginBottom: 24 }}
                panelStyle={{ marginLeft: 24, marginRight: 24 }}
              />
            </DropDownButton>
          )}
        </div>
      </form>
    </>
  );
};

export default VehicleDetailsForm;
