import { AxiosService } from './axiosService';
import { ApiResponse } from '@/interfaces';
import { CustomFields } from '@/interfaces';
import { CustomFieldsUpdateInterface } from '@/interfaces';
import { CreditEducationDBInfo, CreditEducationInfo, CreditRepairCloud } from '@/interfaces/CreditEducation';
import { displayErrors } from '@/utils/helpers/general';

class ApplicationService extends AxiosService {
  public constructor() {
    super();
  }

  async getCustomFields(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CustomFields>>(
        '/Application/CustomFields',
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async setCustomFields(appRecId: number, customFields: CustomFieldsUpdateInterface) {
    try {
      const { data } = await this.axios.put<ApiResponse<CustomFieldsUpdateInterface>>(
        '/Application/CustomFields',
        customFields,
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCreditEducationInfo(appRecId: number | null | undefined) {
    try {
      if (appRecId == null) throw new Error('AppRecId cannot be null');

      const { data } = await this.axios.get<ApiResponse<CreditEducationDBInfo>>(
        '/Application/CreditEducationInfo',
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCreditRepairCloudInfo(creditRepairCloudID: string | null | undefined) {
    try {
      if (creditRepairCloudID == null) throw new Error('CreditRepairCloudID cannot be null');

      const { data } = await this.axios.get<ApiResponse<CreditRepairCloud>>(
        '/Application/CreditRepairCloudInfo',
        {
          params: {
            creditRepairCloudId: creditRepairCloudID,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postCreditRepairCloud(req: CreditRepairCloud) {
    try {
      const { data } = await this.axios.post<ApiResponse<CreditRepairCloud>>(
        '/Application/CreditRepairCloud',
        req
      );

      return data.data!;
    } catch (err: any) {
      console.error(err);
      displayErrors(err.message);
      throw err;
    }
  }

  async putCreditRepairCloudId(req: CreditRepairCloud) {
    try {
      const { data } = await this.axios.put<ApiResponse<CreditRepairCloud>>(
        '/Application/UpdateCreditRepairCloudId',
        req
      );

      return data.data!;
    } catch (err: any) {
      console.error(err);
      displayErrors(err.message);
      throw err;
    }
  }

  async deleteCreditEducation(req: CreditEducationInfo) {
    try {
      await this.axios.delete(
        '/Application/DeleteCreditEducation',
        { 
          params: { 
            appBuyersRecID: req.appBuyersRecID,
            creditEducationID: req.creditEducationID,
            creditRepairCloudID: req.creditRepairCloudID, 
          }, 
        }
      );
    } catch (err: any) {
      console.error(err);
      displayErrors(err.message);
      throw err;
    }
  }
}

export const applicationService = new ApplicationService();
