import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LinkButton from "@/mui/components/buttons/LinkButton";
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import { DataGrid } from "@mui/x-data-grid";
import { tableStyle } from "@/features/Settings/default";
import { getRowId } from "@/mui/components/dataTable/utils";
// kendo
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
// state
import { useSidenoteCtx } from "./SidenoteProvider";
// utils
import { tableColumns } from "./tableColumns";

const AddNewButton: FC = () => <LinkButton to="new">Add New Sidenote</LinkButton>;

/** ###  */
const SidenoteTable: FC = () => {
  const sidenoteRes = useSidenoteCtx((s) => s.sidenoteRes);

  return (
    <AccountsMainPanel navBarTitle="Sidenotes" loading={false}>
      <DataGrid
        rows={sidenoteRes}
        columns={tableColumns}
        getRowId={getRowId("recId")}
        rowHeight={40}
        slots={{ toolbar: TableToolbar }}
        slotProps={{ toolbar: { placeholder: "Search filter", ButtonGroup: AddNewButton } }}
        sx={tableStyle}
      />
      <Grid container justifyContent="end" py={1}></Grid>
    </AccountsMainPanel>
  );
};

export default SidenoteTable;
