import { FC, ReactNode, isValidElement } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
// style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";
import { borderRadiusHeader } from "./style";

/** ###  */
const DetailViewHeader: FC<Grid2Props & { title?: string | ReactNode }> = ({
  title,
  // MUI Grid props
  children,
  sx,
  ...gridProps
}) => {
  const appliedTitle =
    typeof title === "string" ? (
      <Grid
        sx={{
          color: "rgb(17,24,39)",
          letterSpacing: -0.52,
          fontSize: 20,
          px: 2,
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        }}
      >
        {title}
      </Grid>
    ) : isValidElement(title) ? (
      title
    ) : (
      <></>
    );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        //
        height: 52,
        minHeight: 52,
        maxHeight: 52,
        px: 4.5,
        borderBottom: "4px solid #e6e6e6",
        borderRadius: borderRadiusHeader,
        backgroundColor: BG_HIGHLIGHT,
        fontWeight: 700,
        lineHeight: "28px",
        ...sx,
      }}
      {...gridProps}
    >
      {appliedTitle}
      {children}
    </Grid>
  );
};

export default DetailViewHeader;
