import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const NsfPaidField: FC = () => {
  const nsfDue = usePmtFormCtx((s) => s.formState.config.external.nsfDue);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("nsfPaid"));

  return (
    <Grid
      container
      sx={{ alignItems: "center", justifyContent: "space-between" }}
      hidden={fieldProps.hidden}
    >
      <Grid component="span" sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>
        NSF Charges
      </Grid>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{ flex: 1.25, gap: "10px", alignItems: "center" }}
      >
        <CurrencyInput
          label="Paid"
          required
          containerStyle={{ flex: 1 }}
          horizontalLabel={false}
          {...fieldProps}
        />
        <CurrencyInput
          label="Due"
          readOnly
          containerStyle={{ flex: 1 }}
          horizontalLabel={false}
          value={nsfDue}
        />
      </Grid>
    </Grid>
  );
};

export default NsfPaidField;
