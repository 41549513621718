import dayjs from 'dayjs';
import {
  CashTermData,
  TermsLockType,
} from '@/features/Sales/components/salesTabs/terms/cashTerm/cashInterfaces';
import { QuoteData, SaleData } from '@/interfaces';

/** @deprecated simplify inputs to object */
export function getsalestax(
  sp: number,
  ta: number,
  rate: number,
  tmax: number,
  tmin: number,
  ton: string,
  add1: number,
  add2: number,
  add3: number,
  add4: number,
  add5: number,
  add6: number,
  add7: number,
  add8: number,
  add9: number,
  add10: number,
  tax1: boolean,
  tax2: boolean,
  tax3: boolean,
  tax4: boolean,
  tax5: boolean,
  tax6: boolean,
  tax7: boolean,
  tax8: boolean,
  tax9: boolean,
  tax10: boolean
) {
  let taxable = 0;
  let taxamt = 0;
  switch (ton) {
    case 'Sales Price less Trade':
      taxable = sp - ta;
      break;
    case 'Sales Price':
      taxable = sp;
      break;
    default:
      taxable = 0;
  }
  if (tax1) {
    taxable = taxable + add1;
  }
  if (tax2) {
    taxable = taxable + add2;
  }
  if (tax3) {
    taxable = taxable + add3;
  }
  if (tax4) {
    taxable = taxable + add4;
  }
  if (tax5) {
    taxable = taxable + add5;
  }
  if (tax6) {
    taxable = taxable + add6;
  }
  if (tax7) {
    taxable = taxable + add7;
  }
  if (tax8) {
    taxable = taxable + add8;
  }
  if (tax9) {
    taxable = taxable + add9;
  }
  if (tax10) {
    taxable = taxable + add10;
  }

  taxamt = (taxable * rate) / 100;
  taxamt = Math.round(0.499 + taxamt * 100) / 100;

  taxamt = Math.max(0, taxamt);
  if (tmin > 0) {
    taxamt = Math.max(taxamt, tmin);
  }
  if (tmax > 0) {
    taxamt = Math.min(taxamt, tmax);
  }

  return taxamt;
}

/** @deprecated extremely large function, high-risk */
export function reCalcCashTerms(
  data: CashTermData,
  saleData: SaleData,
  quoteData: QuoteData,
  lock: TermsLockType
) {
  const SStatus = saleData.sale?.appstatus;

  if (SStatus == 'Pending') {
    const Lock = lock ? lock : (saleData.sale?.lock as TermsLockType);
    let salesprice = Math.max(0, data.salesPrice);
    let saledate = data.dateOfSale;
    // saledate = Date();
    let salestax = data.salesTax;
    const addon1 = Math.max(0, data.license);
    const addon2 = Math.max(0, data.title);
    const addon3 = Math.max(0, data.registration);
    const addon4 = Math.max(0, data.customField1);
    const addon5 = Math.max(0, data.customField2);
    const addon6 = Math.max(0, data.customField3);
    const addon7 = Math.max(0, data.customField4);
    const addon8 = Math.max(0, data.customField5);
    let addon9 = Math.max(0, data.customField6);
    const addon10 = Math.max(0, data.serviceContract);

    const tradeallow = Math.max(0, data.tradeAllowance);
    const tradepayoff = data.tradePayoff;
    const depositdown = Math.max(0, data.depositDown);
    let cod = Math.max(0, data.cashOnDelivery);

    const stax1 = saleData.sale?.staX1 ?? false;
    const stax2 = saleData.sale?.staX2 ?? false;
    const stax3 = saleData.sale?.staX3 ?? false;
    const stax4 = saleData.sale?.staX4 ?? false;
    const stax5 = saleData.sale?.staX5 ?? false;
    const stax6 = saleData.sale?.staX6 ?? false;
    const stax7 = saleData.sale?.staX7 ?? false;
    const stax8 = saleData.sale?.staX8 ?? false;
    const stax9 = saleData.sale?.staX9 ?? false;
    const stax10 = saleData.sale?.staX10 ?? false;
    const ctax1 = saleData.sale?.citytaX1 ?? false;
    const ctax2 = saleData.sale?.citytaX2 ?? false;
    const ctax3 = saleData.sale?.citytaX3 ?? false;
    const ctax4 = saleData.sale?.citytaX4 ?? false;
    const ctax5 = saleData.sale?.citytaX5 ?? false;
    const ctax6 = saleData.sale?.citytaX6 ?? false;
    const ctax7 = saleData.sale?.citytaX7 ?? false;
    const ctax8 = saleData.sale?.citytaX8 ?? false;
    const ctax9 = saleData.sale?.citytaX9 ?? false;
    const ctax10 = saleData.sale?.citytaX10 ?? false;
    const ltax1 = saleData.sale?.loctaX1 ?? false;
    const ltax2 = saleData.sale?.loctaX2 ?? false;
    const ltax3 = saleData.sale?.loctaX3 ?? false;
    const ltax4 = saleData.sale?.loctaX4 ?? false;
    const ltax5 = saleData.sale?.loctaX5 ?? false;
    const ltax6 = saleData.sale?.loctaX6 ?? false;
    const ltax7 = saleData.sale?.loctaX7 ?? false;
    const ltax8 = saleData.sale?.loctaX8 ?? false;
    const ltax9 = saleData.sale?.loctaX9 ?? false;
    const ltax10 = saleData.sale?.loctaX10 ?? false;
    const otax1 = saleData.sale?.countytaX1 ?? false;
    const otax2 = saleData.sale?.countytaX2 ?? false;
    const otax3 = saleData.sale?.countytaX3 ?? false;
    const otax4 = saleData.sale?.countytaX4 ?? false;
    const otax5 = saleData.sale?.countytaX5 ?? false;
    const otax6 = saleData.sale?.countytaX6 ?? false;
    const otax7 = saleData.sale?.countytaX7 ?? false;
    const otax8 = saleData.sale?.countytaX8 ?? false;
    const otax9 = saleData.sale?.countytaX9 ?? false;
    const otax10 = saleData.sale?.countytaX10 ?? false;

    const staterate = saleData.sale?.staterate ?? 0;
    const stateon = saleData.sale?.stateon ?? '';
    const statemin = saleData.sale?.statemin ?? 0;
    const statemax = saleData.sale?.statemax ?? 0;

    const countyrate = saleData.sale?.countyrate ?? 0;
    const countyon = saleData.sale?.countyon ?? '';
    const countymin = saleData.sale?.countymin ?? 0;
    const countymax = saleData.sale?.countymax ?? 0;

    const cityrate = saleData.sale?.cityrate ?? 0;
    const cityon = saleData.sale?.cityon ?? '';
    const citymin = saleData.sale?.citymin ?? 0;
    const citymax = saleData.sale?.citymax ?? 0;

    const locrate = saleData.sale?.locrate ?? 0;
    const locon = saleData.sale?.locon ?? '';
    const locmin = saleData.sale?.locmin ?? 0;
    const locmax = saleData.sale?.locmax ?? 0;
    const usetxtax = quoteData.useTXTax;
    const useinvtax = quoteData.useTXInvTax;
    const invtaxrate = quoteData.invTaxRate!;

    const statesalestax = getsalestax(
      salesprice,
      tradeallow,
      staterate,
      statemax,
      statemin,
      stateon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      stax1,
      stax2,
      stax3,
      stax4,
      stax5,
      stax6,
      stax7,
      stax8,
      stax9,
      stax10
    );
    const countysalestax = getsalestax(
      salesprice,
      tradeallow,
      countyrate,
      countymax,
      countymin,
      countyon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      otax1,
      otax2,
      otax3,
      otax4,
      otax5,
      otax6,
      otax7,
      otax8,
      otax9,
      otax10
    );
    const citysalestax = getsalestax(
      salesprice,
      tradeallow,
      cityrate,
      citymax,
      citymin,
      cityon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ctax1,
      ctax2,
      ctax3,
      ctax4,
      ctax5,
      ctax6,
      ctax7,
      ctax8,
      ctax9,
      ctax10
    );
    const locsalestax = getsalestax(
      salesprice,
      tradeallow,
      locrate,
      locmax,
      locmin,
      locon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ltax1,
      ltax2,
      ltax3,
      ltax4,
      ltax5,
      ltax6,
      ltax7,
      ltax8,
      ltax9,
      ltax10
    );
    salestax = statesalestax + countysalestax + citysalestax + locsalestax;
    // salestax = 0;

    let tsp =
      salesprice +
      addon1 +
      addon2 +
      addon3 +
      addon4 +
      addon5 +
      addon6 +
      addon7 +
      addon8 +
      addon9 +
      addon10 +
      salestax;

    const tradenet = tradeallow - tradepayoff;

    let txpmttax = 0;
    let txdowntax = 0;
    let txtaxbal = 0;
    if (usetxtax == true) {
      txdowntax = Math.round(staterate * (cod + depositdown)) / 100; // 100*rate/100 =rate  (included)
      txtaxbal = salestax; // -txdowntax;
    }

    return {
      saleDate: dayjs(saledate).utc().format('YYYY-MM-DD'),
      salesTax: salestax,
      salesPrice: salesprice,
      totalSp: tsp,
      cod: cod,
      spNum6: 1,
      spSch6: '',
      license: addon1,
      title: addon2,
      registration: addon3,
      addon4: addon4,
      addon5: addon5,
      addon6: addon6,
      addon7: addon7,
      addon8: addon8,
      addon9: addon9,
      serviceContract: addon10,
      goodCalc: true,
      txPmtTax: txpmttax,
      txDownTax: txdowntax,
      taxTaxBal: txtaxbal,
    };
  }

  return;
}

/** @deprecated extremely large function, high-risk */
export function reCalcCashQuote(
  data: CashTermData,
  quoteData: QuoteData,
  lock: string
) {
  const SStatus = 'Pending';

  if (SStatus == 'Pending') {
    const Lock = lock;
    let salesprice = Math.max(0, data.salesPrice);
    let saledate = data.dateOfSale;
    saledate = Date();
    let salestax = data.salesTax;
    const addon1 = Math.max(0, data.license);
    const addon2 = Math.max(0, data.title);
    const addon3 = Math.max(0, data.registration);
    const addon4 = Math.max(0, data.customField1);
    const addon5 = Math.max(0, data.customField2);
    const addon6 = Math.max(0, data.customField3);
    const addon7 = Math.max(0, data.customField4);
    const addon8 = Math.max(0, data.customField5);
    let addon9 = Math.max(0, data.customField6);
    const addon10 = Math.max(0, data.serviceContract);

    const tradeallow = Math.max(0, data.tradeAllowance);
    const tradepayoff = data.tradePayoff;
    const depositdown = Math.max(0, data.depositDown);
    let cod = Math.max(0, data.cashOnDelivery);

    const stax1 = quoteData.staX1;
    const stax2 = quoteData.staX2;
    const stax3 = quoteData.staX3;
    const stax4 = quoteData.staX4;
    const stax5 = quoteData.staX5;
    const stax6 = quoteData.staX6;
    const stax7 = quoteData.staX7;
    const stax8 = quoteData.staX8;
    const stax9 = quoteData.staX9;
    const stax10 = quoteData.staX10;
    const ctax1 = quoteData.cityTaX1;
    const ctax2 = quoteData.cityTaX2;
    const ctax3 = quoteData.cityTaX3;
    const ctax4 = quoteData.cityTaX4;
    const ctax5 = quoteData.cityTaX5;
    const ctax6 = quoteData.cityTaX6;
    const ctax7 = quoteData.cityTaX7;
    const ctax8 = quoteData.cityTaX8;
    const ctax9 = quoteData.cityTaX9;
    const ctax10 = quoteData.cityTaX10;
    const ltax1 = quoteData.locTaX1;
    const ltax2 = quoteData.locTaX2;
    const ltax3 = quoteData.locTaX3;
    const ltax4 = quoteData.locTaX4;
    const ltax5 = quoteData.locTaX5;
    const ltax6 = quoteData.locTaX6;
    const ltax7 = quoteData.locTaX7;
    const ltax8 = quoteData.locTaX8;
    const ltax9 = quoteData.locTaX9;
    const ltax10 = quoteData.locTaX10;
    const otax1 = quoteData.countyTaX1;
    const otax2 = quoteData.countyTaX2;
    const otax3 = quoteData.countyTaX3;
    const otax4 = quoteData.countyTaX4;
    const otax5 = quoteData.countyTaX5;
    const otax6 = quoteData.countyTaX6;
    const otax7 = quoteData.countyTaX7;
    const otax8 = quoteData.countyTaX8;
    const otax9 = quoteData.countyTaX9;
    const otax10 = quoteData.countyTaX10;

    const staterate = quoteData.staterate;
    const stateon = quoteData.stateon;
    const statemin = quoteData.statemin;
    const statemax = quoteData.statemax;

    const countyrate = quoteData.countyrate;
    const countyon = quoteData.countyon;
    const countymin = quoteData.countymin;
    const countymax = quoteData.countymax;

    const cityrate = quoteData.cityrate;
    const cityon = quoteData.cityon;
    const citymin = quoteData.citymin;
    const citymax = quoteData.citymax;

    const locrate = quoteData.locrate;
    const locon = quoteData.locon;
    const locmin = quoteData.locmin;
    const locmax = quoteData.locmax;
    const usetxtax = quoteData.useTXTax;
    const useinvtax = quoteData.useTXInvTax;
    const invtaxrate = quoteData.invTaxRate!;

    const statesalestax = getsalestax(
      salesprice,
      tradeallow,
      staterate,
      statemax,
      statemin,
      stateon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      stax1,
      stax2,
      stax3,
      stax4,
      stax5,
      stax6,
      stax7,
      stax8,
      stax9,
      stax10
    );
    const countysalestax = getsalestax(
      salesprice,
      tradeallow,
      countyrate,
      countymax,
      countymin,
      countyon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      otax1,
      otax2,
      otax3,
      otax4,
      otax5,
      otax6,
      otax7,
      otax8,
      otax9,
      otax10
    );
    const citysalestax = getsalestax(
      salesprice,
      tradeallow,
      cityrate,
      citymax,
      citymin,
      cityon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ctax1,
      ctax2,
      ctax3,
      ctax4,
      ctax5,
      ctax6,
      ctax7,
      ctax8,
      ctax9,
      ctax10
    );
    const locsalestax = getsalestax(
      salesprice,
      tradeallow,
      locrate,
      locmax,
      locmin,
      locon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ltax1,
      ltax2,
      ltax3,
      ltax4,
      ltax5,
      ltax6,
      ltax7,
      ltax8,
      ltax9,
      ltax10
    );
    salestax = statesalestax + countysalestax + citysalestax + locsalestax;
    // salestax = 0;

    const deferreddown = 0;
    if (useinvtax == true) {
      addon9 = Math.round(salesprice * (invtaxrate / 100) * 100) / 100;
    }

    let tsp =
      salesprice +
      addon1 +
      addon2 +
      addon3 +
      addon4 +
      addon5 +
      addon6 +
      addon7 +
      addon8 +
      addon9 +
      addon10 +
      salestax;

    const tradenet = tradeallow - tradepayoff;
    let totaldown = cod + tradenet + depositdown;


    let txpmttax = 0;
    let txdowntax = 0;
    let txtaxbal = 0;
    if (usetxtax == true) {
      txdowntax = Math.round(staterate * (cod + depositdown + deferreddown)) / 100; // 100*rate/100 =rate  (included)
      txtaxbal = salestax; // -txdowntax;
    }

    return {
      saleDate: dayjs(saledate).utc().format('YYYY-MM-DD'),
      salesTax: salestax,
      salesPrice: salesprice,
      totalSp: tsp,
      cod: cod,
      totalDown: totaldown,
      license: addon1,
      title: addon2,
      registration: addon3,
      addon4: addon4,
      addon5: addon5,
      addon6: addon6,
      addon7: addon7,
      addon8: addon8,
      addon9: addon9,
      serviceContract: addon10,
      goodCalc: true,
      txPmtTax: txpmttax,
      txDownTax: txdowntax,
      taxTaxBal: txtaxbal,
    };
  }

  return;
}
