import { z } from 'zod';

export enum QuickActions {
  SendTextToPay = 'Send Text to Pay',
  SendTextToOptIn = 'Send Text to Opt In',
  SetUpPaymentArrangement = 'Payment Arrangement',
  LedgerCard = 'Ledger Card',
  AddAppointment = 'Add an Appointment',
}

/** @deprecated unused */
export enum CustomerAdmin {
  PostNsfCheck = 'Post NSF Check',
  CreditCardChargeback = 'Credit Card Chargeback',
  ReprintReceipt = 'Re-Print Receipt',
  ReversePayment = 'Reverse Payment',
  RequestCustomerAssistance = 'Request Customer Assistance',
  MyCarPayInformation = 'MyCarPay.com Information',
  PrincipalOnlyPayment = 'Principal Only Payment',
  AccountAdjustment = 'Account Adjustment',
  CustomFields = 'Custom Fields',
  CpiRefund = 'CPI Refund',
}

export enum Events {
  ReceivedSMS = 'ReceivedSMS',
}

export enum BuyerType {
  Buyer = 'Buyer',
  CoBuyer = 'Co-Buyer',
}
export type BuyerTypeUnion = `${BuyerType}`;

export enum EmploymentType {
  FullTime = 'Full Time',
  PartTime = 'Part Time',
}

export enum CurrentPrevious {
  Current = 'Current',
  Previous = 'Previous',
}

export enum HomeType {
  Apartment = 'Apartment',
  House = 'House',
  Trailer = 'Trailer',
  Townhome = 'Townhome',
  Duplex = 'Duplex',
  Quadplex = 'Quadplex',
  Other = 'Other',
}

/** @deprecated use `ColTypeCode`, `ColTypeLabel`, `colTypeMap` */
export enum ColType { // Missing CT, not sure what that is
  CashDown = 'CD',
  CashSale = 'CS',
  DepositDown = 'DP',
  Finance = 'FS',
  Lease = 'LS',
  Sidenote = 'SN',
  Wholesale = 'WS',
  DeferredDown = 'DD',
}
/** CashDown = 'CD' CashSale = 'CS' DepositDown = 'DP' Finance = 'FS' Lease = 'LS' Sidenote = 'SN' Wholesale = 'WS' DeferredDown = 'DD' */
export const ColTypeCode = z.enum(['CD', 'CS', 'DP', 'FS', 'LS', 'SN', 'WS', 'DD']);
export type ColTypeCode = z.infer<typeof ColTypeCode>;
export const ColTypeLabel = z.enum([
  'Cash Down',
  'Cash Sale',
  'Deposit Down',
  'Finance',
  'Lease',
  'Sidenote',
  'Wholesale',
  'Deferred Down',
]);
export type ColTypeLabel = z.infer<typeof ColTypeLabel>;
export const colTypeMap: Record<
  ColTypeLabel | ColTypeCode,
  { code: ColTypeCode; label: ColTypeLabel }
> = {
  'Cash Down': { code: 'CD', label: 'Cash Down' },
  'Cash Sale': { code: 'CS', label: 'Cash Sale' },
  'Deposit Down': { code: 'DP', label: 'Deposit Down' },
  Finance: { code: 'FS', label: 'Finance' },
  Lease: { code: 'LS', label: 'Lease' },
  Sidenote: { code: 'SN', label: 'Sidenote' },
  Wholesale: { code: 'WS', label: 'Wholesale' },
  'Deferred Down': { code: 'DD', label: 'Deferred Down' },
  // Reverse lookups
  get CD() {
    return this['Cash Down'];
  },
  get CS() {
    return this['Cash Sale'];
  },
  get DP() {
    return this['Deposit Down'];
  },
  get FS() {
    return this['Finance'];
  },
  get LS() {
    return this['Lease'];
  },
  get SN() {
    return this['Sidenote'];
  },
  get WS() {
    return this['Wholesale'];
  },
  get DD() {
    return this['Deferred Down'];
  },
} as const;

/** @deprecated use zod enum, move to correct scope (sales interfaces file) */
export enum SaleType {
  // Basically the same as ColType, but this is both what we display to the user and what we submit to the API on new sales
  // Users with `userinfo.dclaccess` flag set to 1 can only do leases and wholesales
  Finance = 'Finance',
  Lease = 'Lease',
  Cash = 'Cash',
  Wholesale = 'Wholesale',
}

export const InsuranceCoverageOption = z.enum(['None', 'Liability', 'Full']);
export type InsuranceCoverageOption = z.infer<typeof InsuranceCoverageOption>;

export enum AccountStatus {
  // There are several others, including some which are probably typos. Add them as needed
  Active = 'Active',
  ChargedOff = 'Charged Off',
  Inactive = 'Inactive',
}

export enum ReportCategory {
  Collections = 'Collections',
  Sales = 'Sales',
  Inventory = 'Inventory',
  Management = 'Management',
  AccountingExports = 'Accounting',
  DealerExports = 'Dealer',
  LenderExports = 'Lender',
}
