import { FC } from "react";
// kendo
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

const PayToFromField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("payToFrom"));
  return <TextInput label="Name" required {...fieldProps} />;
};

export default PayToFromField;