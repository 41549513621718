// components
import SaleTypeColumnMenu from '../../SalesListViewTable/SaleTypeColumnMenu';
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { IOutsideFundingDb } from './interfaces';

// const buildDetailRoute: (dataItem: IOutsideFundingDb) => string = (dataItem) => {
//   return `${dataItem.appRecId}/`;
// };

export const outsideFundingTableColumns: KendoColumnDef<keyof IOutsideFundingDb>[] = [
  newColumnDef('stockNum', 'Stk. #', 80),
  // @note disabled while detail views not implemented - keeping for reference
  // newColumnDef('bNames', 'Name', 'auto', 'link', true, true, buildDetailRoute),
  newColumnDef('bNames', 'Name', 'auto'),
  newColumnDef('vehYmm', 'Year/Make/Model', 250),
  { field: 'saleType', title: 'Type', width: 110, columnMenu: SaleTypeColumnMenu },
  newColumnDef('saleDate', 'Date Sold', 130, 'date'),
  newColumnDef('lhPmtAmt', 'Check Amount', 'auto', 'accounting'),
  newColumnDef('fundedOn', 'Paid On', 'auto', 'date'),
  newColumnDef('name', 'Company', 'auto'),
  newColumnDef('code3', 'Lot', 'auto'),
];
