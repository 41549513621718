import { FC, ReactNode, isValidElement } from "react";
import { Blocker } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Grid2Props from "@mui/material/Unstable_Grid2/Grid2Props";
import { ModalProps } from "@mui/material/Modal/Modal";
import Typography from "@mui/material/Typography";
// components
import Button from "@/mui/components/buttons/MuiKendoButton";
import CommonModal from "@/mui/components/CommonModal";
// utils
import { SetState } from "@/interfaces/utilityTypes";

/** ###  */
const NavBlock: FC<{
  /** Accepts `string` or `JSX` */
  title?: ReactNode;
  /** Accepts `string` or `JSX` */
  textBody?: ReactNode;
  blocker: Blocker;
  openState?: { isOpen: boolean; setIsOpen: SetState<boolean> };
  modalProps?: Omit<ModalProps, "children" | "open" | "onClose">;
  containerProps?: Omit<Grid2Props.Grid2Props, "children">;
}> = ({ title, textBody, blocker, openState, modalProps, containerProps }) => {
  const handleCancel = () => {
    blocker.reset && blocker.reset();
    openState?.setIsOpen(false);
  };

  return (
    <CommonModal
      {...modalProps}
      open={openState?.isOpen || blocker.state === "blocked"}
      onClose={handleCancel}
      containerProps={{ py: 0, ...containerProps }}
    >
      <Grid container direction="column" p="50px" gap={6.25}>
        {isValidElement(title) ? (
          title
        ) : (
          // Default title/header layout
          <Typography variant="h3" fontSize={31} fontWeight={700} lineHeight="42px">
            {typeof title === "string" ? title : "Navigation blocked"}
          </Typography>
        )}

        <Grid container direction="column" justifyContent="center" flex={1}>
          <Grid container my="18.72px" flex={1}>
            {isValidElement(textBody) ? (
              textBody
            ) : (
              // Default text body layout
              <Typography variant="h3" fontSize={18.72} fontWeight={700} lineHeight="28px">
                {typeof textBody === "string"
                  ? textBody
                  : "This page may have unsaved data. Do you want to proceed without saving?"}
              </Typography>
            )}
          </Grid>

          <Grid container direction="row" justifyContent="center" gap={6.25}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              onClick={() => {
                blocker.proceed && blocker.proceed();
                openState?.setIsOpen(false);
              }}
              color="warning"
              sx={{
                background: "rgb(253, 206, 62)",
                "&:hover": { background: "rgb(255, 221, 114)" },
              }}
            >
              <Typography fontWeight="inherit" fontSize="inherit" color="black">
                Proceed
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default NavBlock;
