import { FC, PropsWithChildren } from 'react';
// mui
// import Grid from '@mui/material/Unstable_Grid2';
// style
import styles from '@/components/authenticatedContainer/AuthenticatedContainer.module.scss';
import { Link, useLocation } from 'react-router-dom';

/** ###  */
const NavigationItemContainer: FC<PropsWithChildren & { route?: string; action?: () => void }> = ({
  route,
  action,
  children,
}) => {
  if (route) {
    const { pathname } = useLocation();
    const pathRegex = new RegExp(`${route}/?.*`);
    const isCurrentRoute = pathRegex.test(pathname);
    const containerClassName = `${styles.navigationItemContainer} ${
      isCurrentRoute ? styles.active : ''
    }`;
    return (
      <Link className={containerClassName} to={route}>
        {children}
      </Link>
    );
  }
  if (action) {
    return (
      <div onClick={action} className={styles.navigationItemContainer}>
        {children}
      </div>
    );
  }
  throw new Error('NavigationItemContainer requires a route or action');
};

export default NavigationItemContainer;
