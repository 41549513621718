import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
// mui
import Button, { ButtonProps } from "@mui/material/Button";

/** ### DCS-styled button
 *
 * Demos:
 *
 * - [Button Group](https://mui.com/material-ui/react-button-group/)
 * - [Button](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [Button API](https://mui.com/material-ui/api/button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */
const LinkButton: FC<ButtonProps & NavLinkProps> = ({ children, ...props }) => (
  <Button variant="contained" component={NavLink} relative="path" {...props}>
    {children}
  </Button>
);

export default LinkButton;
