import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
// interfaces
import { ToastNotiProps } from "./interfaces";

/** ###  */
const ToastNotiLayout: FC<ToastNotiProps> = ({ title, titleProps, children, ...props }) => (
  <Grid container direction="column" gap={1} {...props}>
    {typeof title === "string" ? (
      <Grid component="h6" sx={{ fontSize: "16px", fontWeight: 700, m: 0 }} {...titleProps}>
        {title}
      </Grid>
    ) : (
      // Allow for custom title component to be passed in
      title
    )}

    {children && <Divider flexItem />}

    {children}
  </Grid>
);

export default ToastNotiLayout;
