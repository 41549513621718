// components
import SaleTypeColumnMenu from '../../SalesListViewTable/SaleTypeColumnMenu';
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { ICashSalesDb } from './interfaces';

// const buildDetailRoute: (dataItem: ICashSalesDb) => string = (dataItem) => {
//   return `${dataItem.appRecId}/`;
// };

export const cashSalesTableColumns: KendoColumnDef<keyof ICashSalesDb>[] = [
  newColumnDef('stockNum', 'Stk. #', 80),
  // @note disabled while detail views not implemented - keeping for reference
  // newColumnDef('bNames', 'Name', 'auto', 'link', true, true, buildDetailRoute),
  newColumnDef('bNames', 'Name', 'auto'),
  newColumnDef('vehYmm', 'Year/Make/Model', 250),
  { field: 'saleType', title: 'Type', width: 110, columnMenu: SaleTypeColumnMenu },
  newColumnDef('saleDate', 'Date Sold', 130, 'date'),
  newColumnDef('invDays', 'Days', 90, 'number'),
  newColumnDef('purPrice', 'Purchase', 'auto', 'accounting'),
  newColumnDef('totExp', 'Expense', 'auto', 'accounting'),
  newColumnDef('soldPrice', 'Sale Price', 'auto', 'accounting'),
  newColumnDef('totalCost', 'Total Cost', 'auto', 'accounting'),
  newColumnDef('profit', 'Profit', 'auto', 'accounting'),
  newColumnDef('ror', 'ROR', 'auto', 'percent'),
  newColumnDef('shortName', 'Sales Person', 'auto'),
  newColumnDef('code3', 'Lot', 'auto'),
  //
];
