import styles from './SectionHeader.module.scss';

export const SectionHeader = (props: {
  title: string;
  showBar?: boolean;
  children?: React.ReactNode;
}) => {
  const { showBar = true, title } = props;
  return (
    <div
      className={styles.sectionHeader}
      style={{ marginTop: showBar ? 19 : 0, marginBottom: showBar ? 19 : 0 }}
    >
      <h3 className={styles.sectionHeaderText}>{title}</h3>
      {props.showBar && <div className={styles.sectionBar}></div>}
      {props.children && props.children}
    </div>
  );
};
