import { CSSProperties, FC } from 'react';
// components
import { Button } from '@/components';
// utils
import { useReportsViewCtx } from '../state/view';
import { ReportsSubviews } from '../viewInfo';

const buttonStyle: CSSProperties = {
  borderRadius: '16px',
  boxShadow: '0px 2px 4px 0px rgba(41, 70, 149, 0.50)',
};

/** ### Button to activate the "Email Report Schedules" subview
 */
const EmailReportsViewButton: FC = () => {
  const subview = useReportsViewCtx((s) => s.subview);
  const setView = useReportsViewCtx((s) => s.setView);

  // Event handler
  const handleClickChangeView = () => {
    const switchTo =
      subview === ReportsSubviews.default ? ReportsSubviews.ers : ReportsSubviews.default;
    setView(switchTo);
  };

  const buttonLabel =
    subview === ReportsSubviews.default ? 'View Email Report Schedules' : 'View Reports';

  return <Button label={buttonLabel} style={buttonStyle} onClick={handleClickChangeView} />;
};

export default EmailReportsViewButton;
