import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Checkbox } from "@/components/checkbox/Checkbox";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const EmailCheckboxFields: FC = () => {
  const sendBFieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("sendB"));
  const sendCFieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("sendC"));

  return (
    <Grid container direction="column" gap={1.5}>
      <Checkbox label="Send Buyer Email Receipt" {...sendBFieldProps} />
      <Checkbox label="Send Cobuyer Email Receipt" {...sendCFieldProps} />
    </Grid>
  );
};

export default EmailCheckboxFields;
