import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
// components
import { Button } from '@/components';
import ReportsViewTable from '../ReportsViewTable';
// utils
import { useReportsViewCtx } from '../state/view';
import { useERSContext } from '../ERSDetailView/state';
import { reportsService } from '@/services/reportsService';
import { ERSFmt } from '../ERSDetailView/interfaces';

/** ### Table component for "Email Report Schedules" view */
const EmailReportSchedulesTable: FC = () => {
  const setIsLoading = useReportsViewCtx((s) => s.setIsLoading);
  const setDbCt = useReportsViewCtx((s) => s.setDbCt);
  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const {
    selectedERS,
    emailReportSchedules,
    setEmailReportSchedules,
    setCompanies,
    setSelectedERS,
  } = useERSContext();

  // Fetch ALL reports on load
  const fetchEmailSchedules = async () => {
    setIsLoading(true);

    try {
      const {
        data: { companies: companiesFromDb, emailReportSchedules: ersFromDb },
      } = await reportsService.fetchEmailReportSchedules();

      const ersMapped = ersFromDb.map((ers) => ERSFmt.fromERSDb(ers));

      ersMapped.sort((ers1, ers2) => {
        return ers1.recId > ers2.recId ? -1 : 1;
      });
      setEmailReportSchedules(ersMapped);
      setDbCt(ersMapped.length);

      // @todo figure out why were getting duplicate entries (based on the `recId`)
      // const ersIds = ersMapped.map((ers) => ers.recId);
      // const ersUnique = [...new Set(ersIds)];
      // const ersDups = ersMapped.filter((ers) => ersUnique.includes(ers.recId));
      // setEmailReportSchedules(ersMapped.filter((ers) => !ers.fileType));
      setCompanies(companiesFromDb);
      if (ersMapped.length === 0) {
        toast.info('No schedules found');
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error fetching schedules: ${err}`);
    }

    setIsLoading(false);
  };
  /**
   * 1. Open modal
   * 1. Set state to isEdit === false (clear ERS in state)
   */
  const handleCreateNewERS = () => {
    // Sanity check - clear form in state and in layout
    if (selectedERS !== null) setSelectedERS(null);
    setIsModalOpen(true);
  };

  // Effects
  useEffect(() => {
    fetchEmailSchedules();
  }, []);

  return (
    <ReportsViewTable
      rows={emailReportSchedules}
      buttonComponent={<Button label="Create new schedule" onClick={handleCreateNewERS} />}
    />
  );
};

export default EmailReportSchedulesTable;
