import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
// kendo
import FormLoader from "@/features/Sales/components/salesTabs/layout/FormLoader";
// components
import PaymentModalGroup from "@/components/modals/PaymentModalGroup";
import FormLeftColumn from "./FormLeftColumn";
import FormRightColumn from "./FormRightColumn";
import PendingRewriteModal from "./PendingRewriteModal";
// state
import { usePmtFormCtx } from "./PmtFormProvider";
// utils
import useNavBlock from "@/utils/forms/useNavBlock";

/**
 * @note This requires `PmtFormProvider` context
 *
 * - Form state is initialized with data from getPaymentDetails.
 * - Request payload mgmt is contained in the form state.
 * - Some fields shown in the form are not directly part of the form state - the ones not wrapped in a < Controller />
 *    - e.g. `totalPayment` which is not part of the submit payload but needs to be displayed;
 *    - e.g. `employee` which is an object that many of the submit payload fields are populated by;
 *
 * Currently this supports the following payment types:
 * 1. "Regular" payments ("Take A Payment" in old accounts, collection payments), as indicated by an empty `paymentType`;
 * 2. "Principal Only" payments, as indicated by `paymentType` === 'PrinOnly';
 * 3. "Principal Only";
 * 4. "Down Payment";
 *
 * @todo move to separate file
 */
const PaymentFormLayout: FC = () => {
  const isLoading = usePmtFormCtx((s) => s.isLoading);
  const formState = usePmtFormCtx((s) => s.formState);
  const receiptUrl = usePmtFormCtx((s) => s.receiptUrl);
  const areFetchReqsSuccessful = usePmtFormCtx((s) => s.areFetchReqsSuccessful);
  const isCliqModalOpen = usePmtFormCtx((s) => s.isCliqModalOpen);
  const isRepayModalOpen = usePmtFormCtx((s) => s.repayIframeUrl);
  const isOpenEdgeModalOpen = usePmtFormCtx((s) => s.isOpenEdgeModalOpen);
  const isModalOpen = isCliqModalOpen || !!isRepayModalOpen || isOpenEdgeModalOpen;
  const { NavBlockElem } = useNavBlock(formState.isDirty && !isModalOpen && !receiptUrl);

  if (isLoading) return <FormLoader />;
  if (!areFetchReqsSuccessful && !isLoading)
    return (
      <Grid container flex={1} justifySelf="center" alignItems="center">
        Unable to load payment data
      </Grid>
    );

  return (
    <Grid container direction="row" sx={{ gap: "25px", flex: 1 }}>
      <FormLeftColumn />
      <Divider flexItem orientation="vertical" sx={{ borderWidth: 1.5, borderColor: "#e6e6e6" }} />
      <FormRightColumn />

      <PaymentModalGroup />
      <PendingRewriteModal />

      {!isCliqModalOpen && <NavBlockElem />}
    </Grid>
  );
};

export default PaymentFormLayout;
