import MaskedInput from 'react-text-mask';
import { forwardRef } from 'react';
import { MaskedTextInput, MaskedTextInputProps } from '@components';

type SocialSecurityInputProps = Omit<MaskedTextInputProps, 'mask'>;

export const SocialSecurityInput = forwardRef<MaskedInput, SocialSecurityInputProps>(
  (props, ref) => (
    <MaskedTextInput
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="000-00-0000"
      obscureUnlessFocused
      ref={ref}
      {...props}
    />
  )
);
