import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// kendo
import {
  GridPageChangeEvent,
  GridSortChangeEvent,
  PagerTargetEvent,
} from '@progress/kendo-react-all';
// interfaces
import { IKendoPaginationState, SortKendo } from './kendo';
import { DtoFilter } from './requests';
// utils
import { DateRangeOptionKey, dateRangeOptions } from '@/general/dates';

dayjs.extend(utc);

export const defaultPageState = {
  buttonCount: 4,
  pageSizes: [25, 50, 100],
};

export const handlePageChange =
  (setPageSizeValue: (p: number) => void, setPage: (p: IKendoPaginationState) => void) =>
  (e: GridPageChangeEvent) => {
    const targetEvent = e.targetEvent as PagerTargetEvent;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage(e.page);
  };
/** ### Update sort on click - triggers pagination request */
export const handleSortChange =
  <SortKey>(setSort: (s: SortKendo<SortKey>[]) => void) =>
  (e: GridSortChangeEvent) => {
    let updatedSort = [...e.sort];

    if (updatedSort.length > 2) {
      updatedSort.shift();
    } else {
      updatedSort = updatedSort.filter((s) => s.dir !== undefined);
    }
    // @todo validate fields are set
    setSort(updatedSort as SortKendo<SortKey>[]);
  };

export type IDateRangeFilter<T = dayjs.Dayjs> = {
  from: T;
  to: T;
  dateRange: DateRangeOptionKey;
};

export class DateRangeFilter {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  dateRange: DateRangeOptionKey;

  constructor(dateRangeFilter: IDateRangeFilter) {
    this.from = dateRangeFilter.from;
    this.to = dateRangeFilter.to;
    this.dateRange = dateRangeFilter.dateRange;
  }

  static fromDate(
    dateRangeFilter: Omit<IDateRangeFilter<Date>, 'dateRange'> & { dateRange?: DateRangeOptionKey }
  ) {
    return new DateRangeFilter({
      from: dayjs(dateRangeFilter.from),
      to: dayjs(dateRangeFilter.to),
      dateRange: dateRangeFilter.dateRange || 'Custom',
    });
  }
  static fromDateRange(dateRangeKey: DateRangeOptionKey) {
    const { beginDate, endDate } = dateRangeOptions[dateRangeKey]!;

    return new DateRangeFilter({
      from: dayjs(beginDate),
      to: dayjs(endDate),
      dateRange: dateRangeKey,
    });
  }
}

// @note will implement this for sales
export interface ICommonTableCtx<SortKey = string, FilterKey = string> {
  textSearchFilter: string;
  setTextSearchFilter: (textSearchFilter: string) => void;
  // Pagination
  page: IKendoPaginationState;
  setPage: (page: IKendoPaginationState) => void;
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: number) => void;
  queryPageNumber: number;
  // Sorts
  sorts: SortKendo<SortKey>[];
  setSorts: (sorts: SortKendo<SortKey>[]) => void;
  // Filters
  dateRangeFilter: IDateRangeFilter | null;
  setDateRangeFilter: (filter: ICommonTableCtx['dateRangeFilter']) => void;
  updateDateRangeFilter: (
    newDateFilterItem: dayjs.Dayjs,
    filterKey: keyof Omit<IDateRangeFilter, 'dateRange'>
  ) => void;
  /** Table-column filters, unrelated to the text-search-filter */
  filters: DtoFilter<FilterKey>[];
}
