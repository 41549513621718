import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
// state
import { useWholesaleViewCtx } from "./WholesaleViewProvider";

/** ### Displays title */
const WsDetailHeader: FC = () => {
  const wsForm = useWholesaleViewCtx((s) => s.wsFormDeprec);
  const wsFormVehicle = useWholesaleViewCtx((s) => s.wsFormVehicle);
  const code3 = useWholesaleViewCtx((s) => s.wholesale.value?.code3);

  const lotHeaderText = code3 ? `${code3} ` : "";
  const stockNumHeaderText = wsFormVehicle?.stockNum ? `${wsFormVehicle.stockNum} ` : "";
  const saleStatusHeaderText = wsForm?.saleStatus ? ` ${wsForm.saleStatus} ` : " Pending ";
  const ymmHeaderText = wsFormVehicle?.ymm ? ` ${wsFormVehicle.ymm} ` : "";
  const vinHeaderText = wsFormVehicle?.vin ? ` - VIN: ${wsFormVehicle.vin}` : "";

  const headerTitleFmt = `${lotHeaderText}${stockNumHeaderText}-${saleStatusHeaderText}-${ymmHeaderText}${vinHeaderText}`;
  const isEmptyTitle =
    !lotHeaderText &&
    !stockNumHeaderText &&
    !saleStatusHeaderText &&
    !ymmHeaderText &&
    !vinHeaderText;
  const headerTitle = isEmptyTitle ? "Pending" : headerTitleFmt;

  return (
    <Grid>
      <Typography
        variant="h5"
        noWrap
        sx={{ fontWeight: 700, fontSize: 24, textOverflow: "ellipsis", px: 2.5, py: 2 }}
      >
        {headerTitle}
      </Typography>
    </Grid>
  );
};

export default WsDetailHeader;
