import { FC } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
// components
import PdfTableColumnHeaderRow from './PdfTableColumnHeaderRow';
import PdfTableBody from './PdfTableBody';
// interfaces
import { PdfColumnMap } from './interfaces';

const styles = StyleSheet.create({
  tableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #ababab',
  },
});

/** ### Displays a row of column headers, along with the rows of data */
const PdfTable: FC<{ columnLabelMap: PdfColumnMap; rows: any[] }> = ({ columnLabelMap, rows }) => {
  return (
    <View style={styles.tableContainer}>
      <PdfTableColumnHeaderRow columnLabelMap={columnLabelMap} />
      <PdfTableBody columnLabelMap={columnLabelMap} rows={rows} />
    </View>
  );
};

export default PdfTable;
