import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AddOnsSalesTaxProvider from "./AddOnsSalesTaxProvider";
import AddOnsSalesTaxList from "./AddOnsSalesTaxList";
import AddOnsSalesTaxDetail from "./AddOnsSalesTaxDetail";

const AddOnsSalesTaxRouter: FC = () => {
  return (
    <AddOnsSalesTaxProvider>
      <Routes>
        <Route index element={<AddOnsSalesTaxList />} />
        <Route path=":id" element={<AddOnsSalesTaxDetail />} />
      </Routes>
    </AddOnsSalesTaxProvider>
  );
};

export default AddOnsSalesTaxRouter;