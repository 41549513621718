import { FocusEvent, useEffect, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// kendo
import { Spacer } from '@/components/spacer/Spacer';
import { AccountInfo } from '@/components/old/accountInfo/AccountInfo';
import { Button } from '@/components/old/button/Button';
import { DmsDropDown } from '@/components/old/dmsDropDown/DmsDropDown';
import { DmsModal } from '@/components/old/dmsModal/DmsModal';
import { DmsNotes } from '@/components/old/dmsNotes/DmsNotes';
import { DmsNumberInput } from '@/components/old/dmsNumberInput/DmsNumberInput';
import { DmsRadioButtonGroup } from '@/components/old/dmsRadioButtonGroup/DmsRadioButtonGroup';
import { DmsTextInput } from '@/components/old/dmsTextInput/DmsTextInput';
import { EmailReceipt } from '@/components/old/emailReceipt/EmailReceipt';
import { OpenEdge } from '@/components/old/openEdge/OpenEdge';
import { Repay } from '@/components/old/repay/Repay';
import { SavedACH } from '@/components/old/savedAch/SavedACH';
import { SavedCreditCard } from '@/components/old/savedCreditCard/SavedCreditCard';
// components
import SamTasks from '@/components/old/samTasks/SamTasks';
import TextToPay from '@/components/old/textToPay/TextToPay';
import { useOldPaymentSelector } from '../oldPaymentSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { paymentService, GetPaymentData, Employee, getDefaultPostPaymentPayload, PaymentProviders } from '@/services/paymentService';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials } from '@/utils/helpers/general';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getCanWaiveFeeDeprec,
  getConvenienceFee,
  getDdDueStatus,
  getIsNewPayment,
  getMpdToken,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  getSavePayment,
  handleCpiDueChange,
  paymentInRange,
  pollForReceiptOld,
  validateTotalPayment,
} from '@/utils/helpers/payment';
// interfaces
import { Nullable } from '@/utils/types';
import { SavedPmtMethodRes } from '@/interfaces/CreditCard';
import { CardProcessorName, PaymentType, PmtPaidIn } from '@/enums/payment';
// style
import commonStyles from '@/features/old/payment/Payment.module.scss';
import { PostPaymentPayloadOld } from '../interfaces';

const navigateToList = () =>
  document.getElementById('SOLUTIONS_ACCOUNTCENTER_ACDETAILMA_EZHB.V.R1.BUTTON_18')?.click();

export const InPersonPayment = () => {
  const { colRecId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [paymentDetails, setPaymentDetails] = useState<GetPaymentData>();
  const [accepted, setAccepted] = useState<PmtPaidIn>(PmtPaidIn.enum['In-Person']);
  const [inPersonPaymentDetails, setInPersonPaymentDetails] = useState<GetPaymentData>();
  const [amountTendered, setAmountTendered] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [carPayment, setCarPayment] = useState(0);
  const [refreshing, setRefreshing] = useState(true);
  const [cpiDue, setCpiDue] = useState(0);
  const [ddDue, setDdDue] = useState(0);
  const [lateCharge, setLateCharge] = useState(0);
  const [lateChargeWaived, setLateChargeWaived] = useState(0);
  const [lateChargeOwed, setLateChargeOwed] = useState(0);
  const [nsfDue, setNsfDue] = useState(0);
  const [nsfOwed, setNsfOwed] = useState(0);
  const [processor, setProcessor] = useState<CardProcessorName | undefined>();
  const [processors, setProcessors] = useState<CardProcessorName[]>([]);
  const [providerData, setProviderData] = useState<PaymentProviders>({} as PaymentProviders);
  const [transactionType, setTransactionType] = useState('Payment');
  const [paymentType, setPaymentType] = useState('Cash');
  const [changeDue, setChangeDue] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [employeeArray, setEmployeeArray] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [confirmPostModalOpen, setConfirmPostModalOpen] = useState(false);
  const [repayModal, setRepayModal] = useState<boolean>(false);
  const [iframe, setIframe] = useState<string>('');
  const [paymentPayload, setPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [formErrors, setFormErrors] = useState(false);
  const [appRecId, setAppRecId] = useState(0);
  const [buyerEmail, setBuyerEmail] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [includeBuyerEmail, setIncludeBuyerEmail] = useState<boolean>(false);
  const [includeCobuyerEmail, setIncludeCobuyerEmail] = useState<boolean>(false);
  const [takenByPassword, setTakenByPassword] = useState<string>('');
  const [showPendingRewriteModal, setShowPendingRewriteModal] = useState(false);
  const [cancellingRewrite, setCancellingRewrite] = useState(false);

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  // All payment methods
  const [paymentMethods, setPaymentMethods] = useState<SavedPmtMethodRes[]>();

  // New Card
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [waiveFee, setWaiveFee] = useState(false);
  const [newCard, setNewCard] = useState(true);

  const [saveCard, setSaveCard] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('AL'); // New ACH
  const [saveAccount, setSaveAccount] = useState(false);
  const [newAccount, setNewAccount] = useState(true);
  const [accountType, setAccountType] = useState('Checking');

  // Existing Payment Method
  const [mpdId, setMpdId] = useState('');
  const [achMpdId, setAchMpdId] = useState('');
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [openEdgeAchModal, setOpenEdgeAchModal] = useState(false);

  const showChangeDue = paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach;

  const paymentTypes = getAllowedPaymentTypes(providerData);

  const initData = async () => {
    setRefreshing(true);
    try {
      const pmtDetails = await paymentService.getPaymentDetails(colRecId!);
      paymentService.getPaymentProviders(colRecId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        setProcessors(enabledProviders);
        setProcessor(preferredProviderName);
      });
      setPaymentDetails(pmtDetails);
      setInPersonPaymentDetails(pmtDetails);
      setTotalPayment(pmtDetails.dmsNextDueAmount);
      setAmountTendered(pmtDetails.dmsNextDueAmount);
      setCarPayment(pmtDetails.pmtDue);
      setCpiDue(pmtDetails.cpiDueNow);
      setDdDue(pmtDetails.ddDueNow);
      setLateCharge(pmtDetails.lcDue);
      setNsfDue(pmtDetails.nsfDue);
      setBuyerEmail(pmtDetails.buyerEmail);
      setFirstName(pmtDetails.buyerFirstName);
      setLastName(pmtDetails.buyerLastName);
      setZip(pmtDetails.buyerZip);
      setAddress(pmtDetails.buyerAddr);
      setCity(pmtDetails.buyerCity);
      setState(pmtDetails.buyerState);
      setAppRecId(pmtDetails.appRecId);
      setIncludeBuyerEmail(pmtDetails.buyerNoEmail);
      setIncludeCobuyerEmail(false);

      await getSavedPayments(pmtDetails.appRecId!);

      const users = await paymentService.getUsersByCompanyId(pmtDetails.compId!);
      setEmployeeArray(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (defaultEmployee) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      setPaymentPayload(getDefaultPostPaymentPayload(pmtDetails));
    } catch (e) {
      console.error(e);
    }
    setRefreshing(false);
  };

  const getSavedPayments = async (ari: number) => {
    await paymentService.getSavedPaymentMethods(ari).then((res) => {
      setPaymentMethods(res);
    });
  };

  const cancelRewriteAndProceed = async () => {
    setCancellingRewrite(true);
    try {
      await accountsService.cancelPendingRewrite(paymentDetails!.appRecId);
      setShowPendingRewriteModal(false);
      submit();
    } finally {
      setCancellingRewrite(false);
    }
  };

  const submit = async () => {
    const isPendingRewrite = await accountsService.getIsPendingRewrite(paymentDetails!.appRecId);

    if (isPendingRewrite) {
      setShowPendingRewriteModal(true);
      return;
    }

    const tempDmsPayload = paymentPayload;

    const paymentToSend = amountTendered;

    const mpdToken = getMpdToken(paymentType, mpdId, achMpdId);
    const savePayment = mpdToken ? false : getSavePayment(paymentType, saveCard, saveAccount);
    const isNewPayment = getIsNewPayment(paymentType, newCard, newAccount);
    const takenBy = getInitials(employee!.shortName);
    const processorType = getProcessorIntByName(processor!);
    const achAcctType = getAchAcctType(paymentType, accountType);
    const isAch = paymentType === PaymentType.Ach;
    const isCC = paymentType === PaymentType.CreditCard;
    const ccConvFee = isCC ? convenienceFee : 0;
    const achConvFee = isAch ? convenienceFee : 0;
    const waiveCCConvFee = isCC && waiveFee;
    const waiveAchConvFee = isAch && waiveFee;

    tempDmsPayload!.AchAcctType = achAcctType;
    tempDmsPayload!.AchConvFee = achConvFee;
    tempDmsPayload!.BillAddress = address;
    tempDmsPayload!.BillCity = city;
    tempDmsPayload!.BillEmail = buyerEmail;
    tempDmsPayload!.BillFirstName = firstName;
    tempDmsPayload!.BillLastName = lastName;
    tempDmsPayload!.BillState = state;
    tempDmsPayload!.BillZip = zip;
    tempDmsPayload!.CarPmt = carPayment;
    tempDmsPayload!.CcConvFee = ccConvFee;
    tempDmsPayload!.Change = changeDue;
    tempDmsPayload!.ColRecId = colRecId!;
    tempDmsPayload!.ColType = paymentDetails!.colType;
    tempDmsPayload!.ConvFee = convenienceFee;
    tempDmsPayload!.CpiPaid = cpiDue;
    tempDmsPayload!.DdPmt = ddDue;
    tempDmsPayload!.EmailB = inPersonPaymentDetails?.buyerEmail || '';
    tempDmsPayload!.EmailC = inPersonPaymentDetails?.cobuyerEmail || '';
    tempDmsPayload!.IsAch = isAch;
    tempDmsPayload!.IsNewCard = isNewPayment;
    tempDmsPayload!.LcOwed = lateChargeOwed;
    tempDmsPayload!.LcPaid = lateCharge;
    tempDmsPayload!.LcWaived = lateChargeWaived;
    tempDmsPayload!.Mpd.AccountNumber = accountNumber;
    tempDmsPayload!.Mpd.RoutingNumber = routingNumber;
    tempDmsPayload!.Mpd.Token = mpdToken;
    tempDmsPayload!.NsfPaid = nsfDue;
    tempDmsPayload!.PaidBy = paymentType;
    tempDmsPayload!.PaidIn = accepted;
    tempDmsPayload!.PaidRef = referenceNumber;
    tempDmsPayload!.PayNote = paymentNote;
    tempDmsPayload!.PmtContext = 'AC_INPERSON';
    tempDmsPayload!.PmtType = paymentType;
    tempDmsPayload!.ProcessorType = processorType;
    tempDmsPayload!.SaveCard = savePayment;
    tempDmsPayload!.SendB = includeBuyerEmail;
    tempDmsPayload!.SendC = includeCobuyerEmail;
    tempDmsPayload!.TakenBy = takenBy;
    tempDmsPayload!.TakenByPassword = takenByPassword;
    tempDmsPayload!.TotalReceived = paymentToSend;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;
    tempDmsPayload!.WaiveAchConvFee = waiveAchConvFee;
    tempDmsPayload!.WaiveCCConvFee = waiveCCConvFee;
    tempDmsPayload!.WaiveConvFee = waiveFee;

    setPaymentPayload(tempDmsPayload);

    if (!formErrors && takenByPassword) {
      if (
        (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) ||
        paymentPayload!.Mpd.Token
      ) {
        setConfirmPostModalOpen(true);
      } else if (processor === CardProcessorName.Repay && paymentPayload) {
        // Get iframe url
        paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setIframe(res.iFrameUrl);
          setRepayModal(true);
        });
      } else if (
        processor === CardProcessorName.OpenEdge &&
        paymentType === PaymentType.CreditCard
      ) {
        paymentService
          .postPaymentSubmit(paymentPayload as any)
          .then((res) => {
            setPaymentLogRecId(res.paymentLogRecId);
            setOpenEdgeModal(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        setOpenEdgeAchModal(true);
      }
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const validateNumber = (value: number | undefined) => {
    value !== undefined
      ? value >= 0
        ? setFormErrors(false)
        : setFormErrors(true)
      : setFormErrors(true);
  };

  const numericErrorMessage = (value: Nullable<number>, input?: string) => {
    if (input === 'totalPayment') {
      const adjustedMaxPayment =
        paymentDetails!.maxPayment - (paymentDetails!.cpiDueNow - cpiDue) - lateChargeOwed;

      const rangeError = paymentInRange(value!, paymentDetails!, paymentType, adjustedMaxPayment);

      if (rangeError.error) {
        return rangeError.message;
      }
    }

    if (Number.isNaN(value) || value === null) {
      return 'Value cannot be empty';
    } else if (value < 0) {
      return 'Value cannot be negative';
    } else {
      return;
    }
  };

  const handleTotalPaymentBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    validateTotalPayment(parseFloat(e.target.value), paymentDetails!, paymentType, setFormErrors);

    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setTotalPayment(parseFloat(e.target.value));
      setAmountTendered(parseFloat(e.target.value) + convenienceFee);
    }
    if (parseFloat(e.target.value) > amountTendered) {
      setAmountTendered(parseFloat(e.target.value));
    }
  };

  const handleChangeDue = (amountTendered: number, totalPayment: number) => {
    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setChangeDue(0);
    } else if (amountTendered > totalPayment) {
      setChangeDue(amountTendered - totalPayment);
    } else {
      setChangeDue(0);
    }
  };

  const onLateChargeBlur = (value: string) => {
    const maxLateCharge = paymentDetails?.lcDue ? paymentDetails?.lcDue : 0;
    let tempLateCharge = parseFloat(value);

    validateNumber(tempLateCharge);
    let tempLateChargeWaived = lateChargeWaived;
    let tempLateChargeOwed = lateChargeOwed;

    if (tempLateCharge > maxLateCharge) {
      tempLateCharge = maxLateCharge;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = 0;
    } else if (tempLateCharge < 0) {
      tempLateCharge = 0;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = maxLateCharge;
    } else if (tempLateCharge + tempLateChargeWaived > maxLateCharge) {
      tempLateChargeWaived = maxLateCharge - tempLateCharge;
      tempLateChargeOwed = 0;
    }

    tempLateChargeOwed = maxLateCharge - tempLateCharge - tempLateChargeWaived;

    setLateCharge(tempLateCharge);
    setLateChargeWaived(tempLateChargeWaived);
    setLateChargeOwed(tempLateChargeOwed);
  };

  const onLateChargeWaivedBlur = (value: string) => {
    const maxLateCharge = paymentDetails?.lcDue ? paymentDetails?.lcDue : 0;
    let tempLateChargeWaived = parseFloat(value);

    validateNumber(tempLateChargeWaived);
    let tempLateCharge = lateCharge;
    let tempLateChargeOwed = lateChargeOwed;

    if (tempLateChargeWaived > maxLateCharge) {
      tempLateCharge = 0;
      tempLateChargeWaived = maxLateCharge;
      tempLateChargeOwed = 0;
    } else if (tempLateChargeWaived < 0) {
      tempLateCharge = maxLateCharge;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = 0;
    } else if (tempLateCharge + tempLateChargeWaived > maxLateCharge) {
      tempLateCharge = maxLateCharge - tempLateChargeWaived;
      tempLateChargeOwed = 0;
    }

    tempLateChargeOwed = maxLateCharge - tempLateCharge - tempLateChargeWaived;

    setLateCharge(tempLateCharge);
    setLateChargeWaived(tempLateChargeWaived);
    setLateChargeOwed(tempLateChargeOwed);
  };

  useEffect(() => {
    handleChangeDue(amountTendered, totalPayment);
  }, [amountTendered, totalPayment, paymentType]);

  useEffect(() => {
    setCarPayment(totalPayment - (cpiDue + lateCharge + ddDue + nsfDue));
  }, [totalPayment, cpiDue, lateCharge, ddDue, nsfDue]);

  // useEffect to dynamically update total payments and the amount tendered
  useEffect(() => {
    if (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) {
      setAmountTendered(cpiDue + ddDue + lateCharge + nsfDue + carPayment);
    } else if (!waiveFee) {
      setAmountTendered(cpiDue + ddDue + lateCharge + nsfDue + carPayment + convenienceFee);
    } else {
      setAmountTendered(cpiDue + ddDue + lateCharge + nsfDue + carPayment);
    }
  }, [
    cpiDue,
    ddDue,
    lateCharge,
    nsfDue,
    carPayment,
    convenienceFee,
    waiveFee,
    processor,
    paymentType,
  ]);

  // useEffect to update the convenienceFee whenever the paymentType is updated
  useEffect(() => {
    if (!paymentDetails || !processor) return;
    setConvenienceFee(getConvenienceFee(paymentDetails!, processor, paymentType, waiveFee));
  }, [paymentType, processor, waiveFee]);

  // useEffect to update nsfOwed whenever nsfDue is updated
  useEffect(() => {
    if (inPersonPaymentDetails?.nsfDue) {
      setNsfOwed(inPersonPaymentDetails.nsfDue - nsfDue);
    }
  }, [inPersonPaymentDetails?.nsfDue, nsfDue]);

  // useEffect to set payoff vs regular payment
  useEffect(() => {
    if (inPersonPaymentDetails) {
      // payoff is meant if they want to pay off the car inPersonPaymentDetails?.maxPayment
      //TODO: make its so that they cant pay over the max payment

      if (transactionType === 'Payoff') {
        setTotalPayment(inPersonPaymentDetails?.maxPayment);
        setCarPayment(inPersonPaymentDetails.payOff);
        setCpiDue(inPersonPaymentDetails.cpiDueNow);
        setLateCharge(inPersonPaymentDetails.lcDue);
        setDdDue(inPersonPaymentDetails.ddDueNow);
        setNsfDue(inPersonPaymentDetails.nsfDue);
        setAmountTendered(inPersonPaymentDetails.maxPayment);
      } else {
        setTotalPayment(inPersonPaymentDetails?.dmsNextDueAmount);
        setCarPayment(inPersonPaymentDetails.pmtDue);
        setCpiDue(inPersonPaymentDetails.cpiDueNow);
        setLateCharge(inPersonPaymentDetails.lcDue);
        setDdDue(inPersonPaymentDetails.ddDueNow);
        setNsfDue(inPersonPaymentDetails.nsfDue);
        setAmountTendered(inPersonPaymentDetails.dmsNextDueAmount);
      }
    }
  }, [transactionType]);

  useEffect(() => {
    initData();
  }, []);

  if (refreshing) {
    return (
      <div className={commonStyles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  const canWaiveFee = getCanWaiveFeeDeprec(processor, providerData);

  return (
    <div>
      {postPaymentLoading && (
        <div className={commonStyles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={commonStyles.header}>{accepted} Payment</div>
      <div className={commonStyles.container}>
        <div className={commonStyles.leftContainer}>
          <div>
            <form onSubmit={onSubmit} className={commonStyles.paymentDetails}>
              {processors && processors.length > 1 && (
                <DmsDropDown
                  name="processor"
                  label="Processor"
                  values={processors}
                  value={processor}
                  inputContainerStyle={{ width: '155px' }}
                  onChange={(e) => setProcessor(e.target.value as CardProcessorName)}
                />
              )}
              <DmsRadioButtonGroup
                name="transactionType"
                inputLabel="Transaction"
                buttons={['Payment', 'Payoff']}
                radioButtonGroupName="Transaction"
                required
                onChange={(e) => setTransactionType(e.target.value)}
              />
              <DmsRadioButtonGroup
                name="acceptedIn"
                inputLabel="Accepted In"
                buttons={PmtPaidIn.options}
                radioButtonGroupName="AcceptedIn"
                required
                onChange={(e) => setAccepted(e.target.value as PmtPaidIn)}
              />
              <div className={commonStyles.paymentInputContainer}>
                <div className={commonStyles.paymentInputTitle}>Paid</div>
                <div className={commonStyles.paymentInputTitle}>Waived</div>
                <div className={commonStyles.paymentInputTitle}>Owed</div>
              </div>
              <DmsNumberInput
                name="totalPayment"
                inputLabel="Total Payment"
                inputStyles={{ width: '100%' }}
                value={totalPayment}
                fixedDecimalScale
                decimalScale={2}
                errors={numericErrorMessage(totalPayment, 'totalPayment')}
                onBlur={(e) => {
                  handleTotalPaymentBlur(e);
                }}
                onChange={(e) => {
                  setTotalPayment(parseFloat(e.target.value));
                }}
              />
              <DmsNumberInput
                name="carPayment"
                inputLabel="Car Payment"
                inputStyles={{ width: '100%' }}
                readOnly
                errors={numericErrorMessage(carPayment)}
                value={carPayment}
                fixedDecimalScale
                decimalScale={2}
                onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                onChange={(e) => {
                  setCarPayment(parseFloat(e.target.value));
                }}
              />
              {inPersonPaymentDetails?.onCpi ? (
                <div className={commonStyles.cpi}>
                  <DmsNumberInput
                    name="cpiDue"
                    inputLabel="CPI Due"
                    inputStyles={{ width: '100%' }}
                    value={cpiDue}
                    fixedDecimalScale
                    decimalScale={2}
                    errors={numericErrorMessage(cpiDue)}
                    onBlur={(e) => {
                      validateNumber(parseFloat(e.target.value));
                      handleCpiDueChange(
                        parseFloat(e.target.value),
                        inPersonPaymentDetails,
                        setInPersonPaymentDetails
                      );
                    }}
                    onChange={(e) => {
                      setCpiDue(parseFloat(e.target.value));
                    }}
                  />

                  <div className={commonStyles.cpiStatus}>{inPersonPaymentDetails?.cpiStatus}</div>
                </div>
              ) : null}
              {paymentDetails?.defDownBal ? (
                <div className={commonStyles.cpi}>
                  <DmsNumberInput
                    name="ddDue"
                    inputStyles={{ width: '70px' }}
                    value={ddDue}
                    inputLabel="DD Due"
                    fixedDecimalScale
                    decimalScale={2}
                    errors={numericErrorMessage(ddDue)}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setDdDue(parseFloat(e.target.value));
                    }}
                  />
                  <div className={commonStyles.cpiStatus}>{getDdDueStatus(paymentDetails)}</div>
                </div>
              ) : null}
              {inPersonPaymentDetails?.lcDue ? (
                <div className={commonStyles.lateChargeContainer}>
                  <DmsNumberInput
                    name="lateCharge"
                    inputStyles={{ width: '70px' }}
                    value={lateCharge}
                    inputLabel="Late Fee"
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => {
                      onLateChargeBlur(e.target.value);
                    }}
                    onChange={(e) => {
                      setLateCharge(parseFloat(e.target.value));
                    }}
                  />
                  <DmsNumberInput
                    name="lateChargeWaived"
                    inputStyles={{ width: '70px' }}
                    value={lateChargeWaived}
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => {
                      onLateChargeWaivedBlur(e.target.value);
                    }}
                  />
                  <DmsNumberInput
                    name="lateChargeOwed"
                    inputStyles={{ width: '70px' }}
                    value={lateChargeOwed}
                    readOnly
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </div>
              ) : null}
              {inPersonPaymentDetails?.nsfDue ? (
                <div className={commonStyles.nsfContainer}>
                  <DmsNumberInput
                    name="nsfDue"
                    inputStyles={{ width: '70px' }}
                    value={nsfDue}
                    inputLabel="NSF Due"
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setNsfDue(parseFloat(e.target.value));
                    }}
                  />
                  <DmsNumberInput
                    name="nsfOwed"
                    inputStyles={{ width: '70px' }}
                    value={nsfOwed}
                    readOnly
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setNsfOwed(parseFloat(e.target.value));
                    }}
                  />
                </div>
              ) : null}
              <DmsDropDown
                name="paymentType"
                label="Payment Type"
                values={paymentTypes}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setPaymentType(e.target.value)}
                value={paymentType}
              />
              <DmsTextInput
                name="referenceNumber"
                inputLabel="Reference #"
                inputStyles={{ width: '100%' }}
                onChange={(e) => setReferenceNumber(e.target.value)}
                value={referenceNumber}
              />
              <DmsNumberInput
                name="amountTendered"
                inputLabel="Amount Tendered"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%' }}
                fixedDecimalScale
                readOnly={paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach}
                decimalScale={2}
                errors={numericErrorMessage(amountTendered)}
                onBlur={(e) => {
                  validateNumber(parseFloat(e.target.value));
                  if (parseFloat(e.target.value) < totalPayment) {
                    setAmountTendered(totalPayment);
                  }
                }}
                onChange={(e) => setAmountTendered(parseFloat(e.target.value))}
                value={amountTendered}
              />
              <DmsNumberInput
                name="changeDue"
                inputLabel="Change Due"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%', color: showChangeDue ? '#000' : 'transparent' }}
                value={changeDue}
                readOnly
                fixedDecimalScale
                errors={numericErrorMessage(changeDue)}
                decimalScale={2}
              />
              <DmsTextInput
                inputLabel="Password"
                type="password"
                inputStyles={{ width: '100%' }}
                required
                value={takenByPassword}
                onChange={(e) => setTakenByPassword(e.target.value)}
              />
              <DmsDropDown
                name="employee"
                label="Employee"
                values={employeeArray.map((employee) => employee.shortName)}
                value={employee!.shortName}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setEmployee(employeeArray[e.target.options.selectedIndex])}
              />
              <DmsTextInput
                name="paymentNote"
                inputLabel="Payment Note"
                inputStyles={{ width: '100%' }}
                value={paymentNote}
                onChange={(e) => setPaymentNote(e.target.value)}
              />
              <DmsModal
                title="Confirm Payment"
                message="Are you sure you want to post this payment?"
                onAcceptLabel="Yes"
                onDeclineLabel="No"
                isOpen={confirmPostModalOpen}
                closeFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onDeclineFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onAcceptFunction={() => {
                  paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
                    pollForReceiptOld(res.paymentLogRecId, navigateToList);
                  });
                  setConfirmPostModalOpen(false);
                }}
              />
              <Button
                buttonLabel="Post Payment"
                onClick={() => {
                  submit();
                }}
                style={{
                  padding: '4px 0',
                  width: '100%',
                  height: '36px',
                  fontSize: '18px',
                }}
              />
            </form>
            <EmailReceipt
              buyerEmail={inPersonPaymentDetails?.buyerEmail || ''}
              coBuyerEmail={inPersonPaymentDetails?.cobuyerEmail || ''}
              containerStyles={{ marginTop: '16px' }}
              noEmailState={inPersonPaymentDetails?.buyerNoEmail}
              coBuyerNoEmailState={inPersonPaymentDetails?.cobuyerNoEmail}
              colRecId={colRecId}
              appRecId={inPersonPaymentDetails?.appRecId}
              buyerRecId={inPersonPaymentDetails?.buyerRecId}
              cobuyerRecId={inPersonPaymentDetails?.coBuyerRecId}
              emailCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, buyerEmail: val })
              }
              cobuyerEmailCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, cobuyerEmail: val })
              }
              optOutCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, buyerNoEmail: val })
              }
              cobuyerOptOutCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, cobuyerNoEmail: val })
              }
              buyerCheckboxFunction={(val) => setIncludeBuyerEmail(val)}
              cobuyerCheckboxFunction={(val) => setIncludeCobuyerEmail(val)}
              hasCobuyer={inPersonPaymentDetails?.hasCobuyer || false}
            />
            {!!(processors && processors.length) && (
              <TextToPay paymentDetails={paymentDetails!} setPaymentDetails={setPaymentDetails} />
            )}
            {paymentType === PaymentType.CreditCard && (
              <SavedCreditCard
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveCard={saveCard}
                onSaveCard={setSaveCard}
                newCard={newCard}
                onNewCard={setNewCard}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                zip={zip}
                onZip={setZip}
                address={address}
                onAddress={setAddress}
                city={city}
                onCity={setCity}
                state={state}
                onState={setState}
                savedPaymentMethods={paymentMethods!}
                mpdId={mpdId}
                onMpdId={setMpdId}
                onSetFormErrors={setFormErrors}
                useCardOnFile={true}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
            {paymentType === PaymentType.Ach && (
              <SavedACH
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveAccount={saveAccount}
                onSaveAccount={setSaveAccount}
                newAccount={newAccount}
                onNewAccount={setNewAccount}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                onAccountType={setAccountType}
                savedPaymentMethods={paymentMethods!}
                mpdId={achMpdId}
                onMpdId={setAchMpdId}
                onSetFormErrors={setFormErrors}
                useAchOnFile={true}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
          </div>
        </div>
        <DmsModal
          isOpen={openEdgeAchModal}
          closeFunction={() => setOpenEdgeAchModal(false)}
          title="OpenEdge Payment"
          creditCardPaymentProvider="OpenEdge"
        >
          <div className={commonStyles.achContainer}>
            <div className={commonStyles.achInputLabel}>Routing Number</div>
            <DmsNumberInput
              name="routingNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setRoutingNumber(e.target.value)}
              maxLength={9}
            />
            <Spacer size={10} />
            <div className={commonStyles.achInputLabel}>Account Number</div>
            <DmsNumberInput
              name="accountNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setAccountNumber(e.target.value)}
              maxLength={17}
            />
            <Spacer size={10} />
            <Button
              buttonStyles={{ width: '300px', height: '30px' }}
              buttonLabel="Submit"
              onClick={() => {
                paymentPayload!.Mpd.AccountNumber = accountNumber;
                paymentPayload!.Mpd.RoutingNumber = routingNumber;
                paymentService
                  .postPaymentSubmit(paymentPayload as any)
                  .then((res) => {
                    pollForReceiptOld(res.paymentLogRecId, () => {
                      setOpenEdgeAchModal(false);
                      navigateToList();
                    });
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              }}
            />
          </div>
        </DmsModal>
        {processors && processors.includes(CardProcessorName.OpenEdge) && openEdgeModal && (
          <DmsModal
            isOpen={openEdgeModal}
            closeFunction={() => {
              setOpenEdgeModal(false);
            }}
            title="OpenEdge Payment"
            creditCardPaymentProvider={CardProcessorName.OpenEdge}
          >
            {paymentLogRecId !== 0 && (
              <OpenEdge
                paymentLogRecId={paymentLogRecId}
                onComplete={navigateToList}
                apiKey={paymentDetails?.openedgeApiKey}
              />
            )}
          </DmsModal>
        )}
        {repayModal && (
          <DmsModal
            isOpen={repayModal}
            closeFunction={() => {
              setRepayModal(false);
            }}
            title="Repay Payment"
            creditCardPaymentProvider={CardProcessorName.Repay}
          >
            <Repay iframe={iframe} paymentLogRecId={paymentLogRecId} onComplete={navigateToList} />
          </DmsModal>
        )}

        {showPendingRewriteModal && (
          <DmsModal
            onAcceptLabel="Yes - Cancel Rewrite"
            onDeclineLabel="No"
            isOpen={showPendingRewriteModal}
            closeFunction={() => {
              setShowPendingRewriteModal(false);
            }}
            onDeclineFunction={() => {
              setShowPendingRewriteModal(false);
            }}
            onAcceptFunction={cancelRewriteAndProceed}
            containerStyles={{ height: 'unset' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                justifyContent: 'center',
                margin: '20px 0',
              }}
            >
              <div style={{ fontWeight: 700 }}>There is a pending rewrite on this account.</div>
              <div>In order to proceed with the transaction, the rewrite must be canceled</div>
              <div>(Clicking 'No' will cancel this transaction)</div>
            </div>
          </DmsModal>
        )}

        <div className={commonStyles.rightContainer}>
          <AccountInfo paymentDetails={inPersonPaymentDetails} />
          <SamTasks appRecId={appRecId} colType={inPersonPaymentDetails?.colType} />
          <DmsNotes colRecId={colRecId} />
        </div>
      </div>
    </div>
  );
};
