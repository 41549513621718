import { FC, ReactNode, createContext, useContext, useState } from 'react';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
// interfaces
import { ApplicationTab } from './interfaces';
import { ApplicationDb } from '@/features/Sales/interfaces/application';

export interface IAppTabViewCtx {
  applicationData: ApplicationDb;
  currentTab: ApplicationTab;
  setCurrentTab: (v: IAppTabViewCtx['currentTab']) => void;
}

const AppTabViewCtx = createContext<IAppTabViewCtx | null>(null);

const AppTabViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const applicationData = useSalesSelector((s) => s.applicationData);
  const [currentTab, setCurrentTab] = useState<ApplicationTab>('Buyer');

  return (
    <AppTabViewCtx.Provider
      value={{
        applicationData,
        currentTab,
        setCurrentTab,
      }}
    >
      {children}
    </AppTabViewCtx.Provider>
  );
};

export default AppTabViewProvider;

export const useAppTabViewCtx = <T,>(selector: (state: IAppTabViewCtx) => T): T => {
  const ctx = useContext(AppTabViewCtx);
  if (!ctx) {
    throw new Error('useAppTabViewCtx must be used within AppTabViewProvider');
  }
  return selector(ctx);
};
