import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '../../accountSlice';
// interfaces
import { AccountStatus, ColType, QuickActions } from '@/enums';
import { AccountTabRoutes } from './interfaces';

const useAccountDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accountInformation = useAccountSelector((s) => s.accountInformation);

  const isLease = accountInformation?.colType === ColType.Lease;
  const isLoan = accountInformation?.colType === ColType.Finance;
  const isDeferredDown = accountInformation?.colType === ColType.DeferredDown;
  const isSidenote = accountInformation?.colType === ColType.Sidenote;
  const isInactive =
    (accountInformation?.status === 'Charged Off' && accountInformation?.colType === 'LS') ||
    accountInformation?.status === 'Inactive';

  const customerAdminList = useMemo(() => {
    const list = [
      { text: 'Legal Account', route: AccountTabRoutes.LegalAccount },
      { text: 'CBR - Credit Reporting Information', route: AccountTabRoutes.Cbr },
      { text: 'Warranty Request', route: AccountTabRoutes.WarrantyRequest },
      { text: 'Print Documents', route: AccountTabRoutes.FormsAndDocuments },
      { text: 'Payment History', route: AccountTabRoutes.PaymentHistory },
      { text: 'GPS', route: AccountTabRoutes.Gps },
      { text: 'Odometer Tracking', route: AccountTabRoutes.OdometerTracking },
      { text: 'Saved Payment Methods', route: AccountTabRoutes.SavedPaymentMethods },
      { text: 'Payment Reversal', route: AccountTabRoutes.PaymentReversal },
      { text: 'Custom Fields', route: AccountTabRoutes.CustomFields },
    ];

    if (!isSidenote && (isLease || isLoan)) {
      if (!list.some((item) => item.route === AccountTabRoutes.Sidenotes)) {
        list.push({ text: 'Sidenotes', route: AccountTabRoutes.Sidenotes });
      }
    }

    if (!isInactive) {
      if (!list.some((item) => item.route === AccountTabRoutes.CpiRefund)) {
        list.push({ text: 'CPI Refund', route: AccountTabRoutes.CpiRefund });
      }
    }

    if (isLease) {
      list.push({ text: 'Credit Education', route: AccountTabRoutes.CreditEducation });
      if (!isInactive) {
        list.push({ text: 'Lease Amendment', route: AccountTabRoutes.LeaseAmendment });
      }
    }

    if (isLoan && !isInactive) {
      list.push({ text: 'Principal Payment', route: AccountTabRoutes.PrincipalPayment });
    }

    if (!accountInformation?.iopStatus) {
      list.push({ text: 'Take Possession of Vehicle', route: AccountTabRoutes.TakePossession });
    } else {
      list.push({ text: 'Release Possession of Vehicle', route: AccountTabRoutes.ReleasePossession });
    }

    if (!isInactive) {
      list.push({ text: 'Order For Repo', route: AccountTabRoutes.OutForRepo });
    }

    if (accountInformation?.status !== AccountStatus.ChargedOff && (isLease || isLoan || isDeferredDown || isSidenote)) {
      list.push({ text: isLease ? 'Terminate Lease' : 'Charge Off', route: AccountTabRoutes.ChargeOff });
    }

    if (accountInformation?.status === AccountStatus.ChargedOff && (isLease || isLoan || isDeferredDown || isSidenote)) {
      list.push({ text: isLease ? 'Reactivate Lease' : 'Reactivate Charge Off', route: AccountTabRoutes.ReactivateChargeOff });
    }

    list.sort((a, b) => (a.text < b.text ? -1 : 1));
    return list;
  }, [isLease, isLoan, isDeferredDown, isSidenote, isInactive, accountInformation?.iopStatus, accountInformation?.status]);

  const handleClick = (action: QuickActions) => {
    dispatch(accountActions.setQuickActionsListOpen(false));
    dispatch(accountActions.setQuickActionType(action));
    dispatch(accountActions.setQuickActionsModalOpened(true));
  };

  const quickActionList = useMemo(() => {
    const actions = isInactive
      ? [
          { text: QuickActions.SendTextToOptIn, onClick: () => handleClick(QuickActions.SendTextToOptIn) },
          { text: QuickActions.AddAppointment, onClick: () => handleClick(QuickActions.AddAppointment) },
          { text: QuickActions.LedgerCard, onClick: () => navigate(`ledger`) },
        ]
      : [
          { text: QuickActions.SendTextToPay, onClick: () => handleClick(QuickActions.SendTextToPay) },
          { text: QuickActions.SendTextToOptIn, onClick: () => handleClick(QuickActions.SendTextToOptIn) },
          { text: QuickActions.SetUpPaymentArrangement, onClick: () => handleClick(QuickActions.SetUpPaymentArrangement) },
          { text: QuickActions.AddAppointment, onClick: () => handleClick(QuickActions.AddAppointment) },
          { text: QuickActions.LedgerCard, onClick: () => navigate(`ledger`) },
        ];
    
    return actions.sort((a, b) => (a.text < b.text ? -1 : 1));
  }, [isInactive, handleClick, navigate]);

  return { customerAdminList, quickActionList };
};

export default useAccountDetails;
