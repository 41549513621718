import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import SidenoteTable from "./SidenoteTable";
// state
import SidenoteProvider from "./SidenoteProvider";
import SidenoteForm from "./SidenoteForm";

/** ###  */
const Sidenotes: FC = () => {
  return (
    <SidenoteProvider>
      {/* <SidenoteView /> */}
      <Routes>
        <Route path="/" element={<SidenoteTable />} />
        <Route path="/:recId" element={<SidenoteForm />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Routes>
    </SidenoteProvider>
  );
};

export default Sidenotes;
