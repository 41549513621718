import { Checkbox, PhoneInput, TextInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";

const LeftContainer: FC = () => {
  const { control, formState } = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);

  return (
    <SectionColumn p={0}>
      <Controller
        name={"company"}
        control={control}
        rules={{ required: "Company is required" }}
        render={({ field }) => (
          <TextInput
            {...field}
            label="Company"
            required
            errors={formState.errors?.company?.message}
          />
        )}
      />
      <Controller
        name={"contact"}
        control={control}
        render={({ field }) => <TextInput label="Contact Person" {...field} />}
      />
      <Controller
        name={"fax"}
        control={control}
        render={({ field }) => <PhoneInput {...field} label="Fax" value={field.value ? field.value : ''}  />}
      />
      <Controller
        name={"officePhone"}
        control={control}
        render={({ field }) => <PhoneInput {...field} label="Office Phone" value={field.value ? field.value : ''}  />}
      />
      <Controller
        name={"cellPhone"}
        control={control}
        render={({ field }) => <PhoneInput {...field} label="Cell Phone" value={field.value ? field.value : ''}  />}
      />
      <Controller
        name={"sendText"}
        control={control}
        render={({ field }) => <Checkbox {...field} label="Send Text When Repo is Closed" />}
      />
      <Controller
        name={"insuranceExpired"}
        control={control}
        render={({ field }) => (
          <DateInput       
            label="Insurance Expiration"
            {...field}
          />
        )}
      />
      <Controller
        name={"bondExpired"}
        control={control}
        render={({ field }) => (
          <DateInput
            label="Bond Expiration"            
            {...field}
          />
        )}
      />
      <Controller
        name={"licenseExpired"}
        control={control}
        render={({ field }) => (
          <DateInput         
            label="License Expiration"
            {...field}
          />
        )}
      />
    </SectionColumn>
  );
};

export default LeftContainer;