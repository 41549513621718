import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
// utils
// interfaces
import { IKendoPaginationState, SortKendo } from '@/interfaces/kendo';
import { UseFormReturn, useForm } from 'react-hook-form';
import { BuyersGuideComplete, BuyersGuideCurrent, BuyersGuideOptions } from '@/interfaces';
import { inventoryService } from '@/services/inventoryService';
import { isNull } from 'lodash-es';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useParams } from 'react-router-dom';

export const languageFilterKeys = ['English', 'Spanish'] as const;
export type LanguageFilterKey = (typeof languageFilterKeys)[number];

export interface IBuyersGuideCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  printLoading: boolean;
  setPrintLoading: (_: boolean) => void;
  languageFilter: LanguageFilterKey;
  setLanguageFilter: (statusFilter: LanguageFilterKey) => void;
  bgRows: BuyersGuideOptions[];
  setBgRows: (_rows: IBuyersGuideCtx['bgRows']) => void;
  bgCurrent: BuyersGuideCurrent | null;
  setBgCurrent: (_rows: IBuyersGuideCtx['bgCurrent']) => void;
  activeRowId: string | null;
  setActiveRowId: (_activeId: IBuyersGuideCtx['activeRowId']) => void;
  activeBgRow: BuyersGuideOptions | null;
  buyersGuideForm: UseFormReturn<BuyersGuideComplete, any, undefined>;
}

const BuyersGuideCtx = createContext<IBuyersGuideCtx | null>(null);
const defaultValues: BuyersGuideComplete = {
  bgComplete: null,
};

const BuyersGuideProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { invRecId } = useParams();
  const compId = useAuthSelector((s) => s.compId);
  const [isLoading, setIsLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [languageFilter, setLanguageFilter] = useState<LanguageFilterKey>('English');
  const [bgRows, setBgRows] = useState<IBuyersGuideCtx['bgRows']>([]);
  const [bgCurrent, setBgCurrent] = useState<IBuyersGuideCtx['bgCurrent']>(null);
  const [activeRowId, setActiveRowId] = useState<IBuyersGuideCtx['activeRowId']>(null);
  const activeBgRow = bgRows.find((r) => activeRowId === r.bgType) || null;

  const buyersGuideForm = useForm<BuyersGuideComplete>({
    defaultValues,
    mode: 'onChange',
  });

  const { reset } = buyersGuideForm;

  useEffect(() => {
    if (activeBgRow) {
      reset({ bgComplete: activeBgRow, ...activeBgRow });
    } else {
      // setActiveRowId(null);
      reset(defaultValues); // Reset to default values if no open row is found
    }
  }, [activeRowId]);

  const loadBgHistory = async () => {
    if (isNaN(Number(compId))) return;
    if (isNull(Number(compId))) return;
    setIsLoading(true);
    try {
      const bgList = await inventoryService.getBuyersGuideOptions(Number(compId));
      const bgCurrent = await inventoryService.getBuyersGuideCurrent(Number(invRecId));
      setBgRows(bgList || []);
      setBgCurrent(bgCurrent);
      setActiveRowId(bgCurrent.bgType || null);
      setIsLoading(false);
    } catch (_) { 
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBgHistory();
  }, [compId, printLoading]);

  return (
    <BuyersGuideCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        printLoading, 
        setPrintLoading,
        languageFilter,
        setLanguageFilter,
        bgRows,
        setBgRows,
        bgCurrent, 
        setBgCurrent,
        activeBgRow,
        activeRowId,
        setActiveRowId,
        buyersGuideForm,
      }}
    >
      {children}
    </BuyersGuideCtx.Provider>
  );
};

export default BuyersGuideProvider;

export const useBuyersGuideCtx = <T,>(selector: (state: IBuyersGuideCtx) => T): T => {
  const ctx = useContext(BuyersGuideCtx);
  if (!ctx) {
    throw new Error('useBuyersGuideCtx must be used within BuyersGuideProvider');
  }
  return selector(ctx);
};
