import { FC } from "react";

/** @deprecated remove - use CSS props/rules (i.e. `gap`) to consistently space grouped-components */
export const Spacer: FC<{
  expand?: boolean;
  size?: number;
  horizontal?: boolean;
  inlineFlex?: boolean;
}> = ({ expand, size, horizontal, inlineFlex }) => {
  if (expand) return <div style={{ flex: 1 }} />;

  const style = {
    display: inlineFlex ? "inline-flex" : "flex",
    [horizontal ? "width" : "height"]: size ?? 10,
  };

  return <div style={style} />;
};
