import { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// utils
import { DateRangeOptionKey, dateRangeOptions, dateRangeOptionLabels } from '@/general/dates';
// interfaces
import { IDateRangeFilter } from '@/interfaces/tableState';

dayjs.extend(utc);

/** ###  */
const DateRangeDropdown: FC<{
  inputValue: DateRangeOptionKey;
  setInputValue: (v: DateRangeOptionKey) => void;
  setDateRangeFilter: (dateRangeFilter: IDateRangeFilter) => void;
}> = ({ inputValue, setInputValue, setDateRangeFilter }) => {
  return (
    <Autocomplete
      sx={{ minWidth: '200px' }}
      options={dateRangeOptionLabels}
      disableClearable
      value={inputValue}
      onChange={(_, dateRangeLabel) => {
        setInputValue(dateRangeLabel as DateRangeOptionKey);
        const { beginDate, endDate } = dateRangeOptions[dateRangeLabel as DateRangeOptionKey]!;

        // Create new date-range filter instance
        const newDateRange: IDateRangeFilter = {
          from: dayjs(beginDate),
          to: dayjs(endDate),
          dateRange: dateRangeLabel as DateRangeOptionKey,
        };

        setDateRangeFilter(newDateRange);
      }}
      renderInput={(p) => (
        <TextField
          {...p}
          label="Date Range"
          InputProps={{ ...p.InputProps, sx: { fontSize: '14px' } }}
          InputLabelProps={{ sx: { fontSize: '14px' } }}
        />
      )}
    />
  );
};

export default DateRangeDropdown;
