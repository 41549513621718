import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Email } from '@/services/customerService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { SetState } from '@/interfaces/utilityTypes';
import { Icons } from '@/components/icons';

import styles from '../activityPanel.module.scss';


/** ###  */
const EmailActivityCard: FC<{activity:Email, setSelectedRecId:SetState<number | null>}> = ({activity, setSelectedRecId}) => {

  return (
    <Grid container direction="column">
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Mail />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div>Email from {activity.sentBy}</div>
              </div>
              {!!activity.subject && (
                <div>
                  <div>
                    {activity.subject ? `"${activity.subject}" - ` : ''}
                    <span
                      className={styles.link}
                      onClick={() => setSelectedRecId(activity.recId)}
                    >
                      Click to view
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    </Grid>
  );
};

export default EmailActivityCard;
