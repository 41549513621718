import { FC, ReactNode, useState } from 'react';
// mui
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import SectionHeader from './SectionHeader';

/** ###  */
const Section: FC<
  AccordionProps & {
    /** defaults to false */
    isExpandable?: boolean;
    /** defaults to false */
    initExpanded?: boolean;
    /** Header can be the title string or a custom header component of type `SectionHeader` */
    header?: string | ReactNode;
  }
> = ({
  isExpandable = false,
  initExpanded = false,
  header,
  children,
  sx: sxProps,
  expanded,
  onChange,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(
    !isExpandable || initExpanded || expanded === undefined
  );
  const handleExpand = () => isExpandable && setIsExpanded((s) => !s);

  const headerComponent =
    typeof header === 'string' ? (
      <SectionHeader label={header} isExpandable={isExpandable} />
    ) : (
      header
    );

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded === undefined ? isExpanded : expanded}
      disabled={!isExpandable}
      sx={{
        fontSize: 16,
        background: 'unset',
        border: undefined,
        '&::before': { display: 'none' },
        '&.Mui-disabled': { opacity: 1, background: 'unset' },
        '& .Mui-disabled.Mui-disabled': { opacity: 1, background: 'unset' },
        ...sxProps,
      }}
      onChange={onChange || handleExpand}
      {...props}
    >
      {header && headerComponent}
      {children}
    </Accordion>
  );
};

export default Section;
