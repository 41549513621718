import { Button } from '@/components';
import { BuyerType } from '@/enums';
import { CashBuyer } from '../buyer/CashBuyer';
import { useApplicationBuyer } from '../buyer/useApplicationBuyer';

export const CashCoBuyer = () => {
  const { hasCobuyer, handleAddOrRemoveCobuyer, updateHasCobuyerLoading, isDisabled } =
    useApplicationBuyer(BuyerType.CoBuyer);
  return (
    <>
      <Button
        label={`${!hasCobuyer ? 'Add' : 'Remove'} Co-Buyer`}
        buttonStyle={{ width: '150px' }}
        loading={updateHasCobuyerLoading}
        disabled={isDisabled}
        onClick={() => handleAddOrRemoveCobuyer(!hasCobuyer)}
      />
      {hasCobuyer && <CashBuyer buyerOrCobuyer={BuyerType.CoBuyer} />}
    </>
  );
};
