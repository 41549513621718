import { FC, useState } from 'react';
// mui
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// kendo
import { GridColumnMenuProps } from '@progress/kendo-react-all';
import {  DropDownButton } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useTableCtx } from '../state/table';
// interfaces
import { ReportCategoryKey } from '../interfaces';
import { ReportCategory } from '@/enums';
import { Filter } from '@/interfaces/requests';

/**
 * @note All category filters MUST use `IN` operator.
 *
 * @todo make field type generic
 * @todo move to general location
 * @todo need to fix logic on how filter values are stored, updated, accessed:
 * - On change:
 *    - If category exists (i.e. in the Map kept in state):
 *       - If 'adding option': append option to filter.value;
 *       - If 'removing option' && filter.options.length === 1: remove filter from Map;
 *       - If 'removing option' && filter.options.length > 1: remove option from filter.value;
 *    - Else:
 *       If 'adding option': create filter from Map;
 * @todo AllowedPmtCategoryFilterKey
 */
const CategoryFilterOption: FC<{
  label?: string;
  value: string;
}> = ({ label, value }) => {
  const addCategoryFilter = useTableCtx((s) => s.addCategoryFilter);
  const removeCategoryFilter = useTableCtx((s) => s.removeCategoryFilter);
  const categoryFilters = useTableCtx((s) => s.categoryFilters);

  // @note the options list must be re-initialized from context when dropdown is opened again.
  const values = categoryFilters.map((f) => f.value);
  const [isChecked, setIsChecked] = useState(values.includes(value));

  const filterValue: Filter = {
    field: 'reportType',
    operator: 'IN',
    value: value,
  };


  // Event handlers
  const handleCheck = () => {
    // Remove filter if checked, else add
    isChecked ? removeCategoryFilter(filterValue) : addCategoryFilter(filterValue);

    setIsChecked(!isChecked);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleCheck} role={undefined} dense>
        <ListItemIcon>
          <Checkbox checked={isChecked} edge="start" disableRipple sx={{ py: 0 }} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          primary={label || value}
          primaryTypographyProps={{
            letterSpacing: '-0.20px',
            lineHeight: '20px',
            fontSize: '14px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

/** ### Menu options */
const checkBoxes: { label?: string; value: ReportCategoryKey }[] = [
  { value: ReportCategory.Collections },
  { value: ReportCategory.Sales },
  { value: ReportCategory.Inventory },
  { value: ReportCategory.Management },
  { value: ReportCategory.AccountingExports },
  { value: ReportCategory.DealerExports },
  { value: ReportCategory.LenderExports },
];

const ReportCategoryFilter: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const categoryOptions = checkBoxes.map(({ label, value }, idx) => (
    <>
      <CategoryFilterOption label={label} value={value} key={`c-f-o${idx}`}/>
      {idx < checkBoxes.length - 1 && <Spacer size={8} />}
    </>
  ))

  // Build menu options
  return (
    <DropDownButton
      label={'Category Filter'}
      open={isOpen}
      setOpen={setIsOpen}
      buttonProps={{ secondary: true }}
    >
      <List component={Paper}>{categoryOptions}</List>
    </DropDownButton>
  );
};
export default ReportCategoryFilter;
