import { FC, isValidElement, useRef, useState } from 'react';
// kendo
import { Popup, PopupProps } from '@progress/kendo-react-all';
import { Button, CustomButtonProps, ClickOutside } from '@/components';
// style
import styles from './DropDownButton.module.scss';

export interface DropDownButtonProps {
  buttonProps?: CustomButtonProps;
  popUpProps?: PopupProps;
  title?: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  contentRef?: React.MutableRefObject<any>;
  buttonStyles?: React.CSSProperties;
  anchorStyles?: React.CSSProperties;
  onClose?: () => void;
  outsideClickClassExceptions?: string[];
}

export const DropDownButton: FC<DropDownButtonProps> = ({
  buttonProps,
  popUpProps,
  open,
  setOpen,
  title,
  label,
  disabled,
  contentRef,
  children,
  buttonStyles,
  anchorStyles,
  onClose,
  outsideClickClassExceptions,
}) => {
  const anchor = useRef(null);
  const [internalShow, setInternalShow] = useState(false);

  const show = open ?? internalShow;
  const setShow = setOpen ? setOpen : setInternalShow;
  const openToggle = (b: boolean) => setShow(b);

  const close = () => {
    if (onClose) onClose();
    else setShow(false);
  };

  return (
    <>
      <div ref={anchor} style={anchorStyles}>
        {isValidElement(label) ? (
          <button
            title={title}
            className={styles.labelContents}
            onClick={() => openToggle(!show)}
            type="button"
            {...buttonProps}
          >
            {label}
          </button>
        ) : (
          <Button
            title={title}
            disabled={disabled}
            label={label as string}
            style={buttonStyles}
            onClick={() => openToggle(!show)}
            {...buttonProps}
          />
        )}
      </div>

      <Popup
        anchor={anchor.current}
        ref={contentRef}
        show={show}
        style={{ visibility: 'hidden' }}
        {...popUpProps}
      >
        <ClickOutside
          onClick={() => close()}
          exceptionRef={anchor}
          outsideClickClassExceptions={outsideClickClassExceptions}
        >
          <div
            className={styles.popupContent}
            tabIndex={0}
            onBlur={(e) => e.currentTarget === e.target && setShow(false)}
            autoFocus
          >
            {children}
          </div>
        </ClickOutside>
      </Popup>
    </>
  );
};
