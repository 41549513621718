import { FC } from "react";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// utils
import { getAllowedPmtTypes } from "@/utils/helpers/payment";

/** ###  */
const MiscCategoryField: FC = () => {
  const miscCategories = usePmtFormCtx((s) => s.miscCategories);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps('mCat'));

  return <DropdownInput label="Misc. Category" required data={miscCategories.value} {...fieldProps} />;
};

export default MiscCategoryField;