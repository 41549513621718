import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import dayjs from "dayjs";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
// utils
import {
  type CollectionQueue,
  collectionsService,
  getQueueFromIndex,
} from "@/services/collectionsService";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// interfaces
import { CollectionsQueueForm, QueueType } from "./QueueDetailForm/interfaces";

const useCtxState = () => {
  const locId = useAuthSelector((s) => s.locId);
  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);

  const [isLoading, setIsLoading] = useState(false);
  const [queues, setQueues] = useState<CollectionQueue[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeQueue, setActiveQueue_] = useState<CollectionQueue | null>(null);

  // Form state
  /** @deprecated Convert to useValidatedForm */
  const [queueForm, setQueueForm] = useState<CollectionsQueueForm>(CollectionsQueueForm.parse({}));
  /** @deprecated Convert to useValidatedForm */
  const queueFormValidation = CollectionsQueueForm.safeParse(queueForm);
  const setActiveQueue = (newQueue: CollectionQueue | null) => {
    setActiveQueue_(newQueue);
    const defaultForm = CollectionsQueueForm.parse({});
    const queueType = newQueue?.queueType
      ? QueueType.options[newQueue.queueType]!
      : defaultForm.queueType;
    const newFormParsed: CollectionsQueueForm = {
      queueType,
      visibility: newQueue?.visibility ?? defaultForm.visibility,
      queueName: newQueue?.queueName ?? defaultForm.queueName,
      daysFrom: newQueue?.daysFrom ?? defaultForm.daysFrom,
      daysTo: newQueue?.daysTo ?? defaultForm.daysTo,
      alphaFrom: newQueue?.alphaFrom ?? defaultForm.alphaFrom,
      alphaTo: newQueue?.alphaTo ?? defaultForm.alphaTo,
      sortColumn: newQueue?.sortColumn ?? defaultForm.sortColumn,
      sortDirection: newQueue?.sortDirection ?? defaultForm.sortDirection,
      autoTTP: newQueue?.autoTTP ?? defaultForm.autoTTP,
      autoTTPLocalTime: newQueue?.autoTTPLocalTime
        ? dayjs(newQueue.autoTTPLocalTime).toDate()
        : defaultForm.autoTTPLocalTime,
      autoTTPTemplate: newQueue?.autoTTPTemplate ?? defaultForm.autoTTPTemplate,
      includeOutForRepo: newQueue?.includeOutForRepo ?? defaultForm.includeOutForRepo,
      includeInOurPossession:
        newQueue?.includeInOurPossession ?? defaultForm.includeInOurPossession,
      includeFieldCall: newQueue?.includeFieldCall ?? defaultForm.includeFieldCall,
      includePaymentArrangements:
        newQueue?.includePaymentArrangements ?? defaultForm.includePaymentArrangements,
      includeAppointments: newQueue?.includeAppointments ?? defaultForm.includeAppointments,
      includeFinanced: newQueue?.includeFinanced ?? defaultForm.includeFinanced,
      includeLease: newQueue?.includeLease ?? defaultForm.includeLease,
      includeSidenotes: newQueue?.includeSidenotes ?? defaultForm.includeSidenotes,
      includeDepositDown: newQueue?.includeDepositDown ?? defaultForm.includeDepositDown,
    };
    setQueueForm(newFormParsed);
  };
  /** @deprecated Convert to useValidatedForm */
  const setField: (
    field: keyof CollectionsQueueForm,
    value: CollectionsQueueForm[keyof CollectionsQueueForm] | null
  ) => void = (field, value) => setQueueForm((s) => ({ ...s!, [field]: value }));
  /** @deprecated Convert to useValidatedForm */
  const setFields: (_: Partial<CollectionsQueueForm>) => void = (field) =>
    setQueueForm((s) => ({ ...s, ...field }));

  /** @deprecated Convert to useReq */
  const loadQueues = async (
    locId: number | undefined,
    orgId: number | undefined,
    compId: number | undefined
  ) => {
    if (!locId || !orgId || !compId) return;

    setIsLoading(true);
    try {
      const colQueueRes = await collectionsService.getCollectionsQueue(locId, orgId, compId);
      const newCollectionQueues = colQueueRes.data?.map((cq) => ({
        ...cq,
        queueTypeString: getQueueFromIndex(cq.queueType),
      }));
      setQueues(newCollectionQueues || []);
    } catch (error) {
      console.error("Error loading collection queue data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadQueues(locId, orgId, compId);
  }, [locId, orgId, compId]);

  return {
    // Layout states
    isLoading,
    setIsLoading,
    isModalOpen,
    setIsModalOpen,
    // Req. states
    queues,
    setQueues,
    loadQueues,

    activeQueue,
    setActiveQueue,

    // Form states
    queueForm, // @todo move to correct scope (i.e. `CollectionQueueList`)
    setQueueForm, // @todo move to correct scope (i.e. `CollectionQueueList`)
    queueFormValidation, // @todo move to correct scope (i.e. `CollectionQueueList`)
    setField, // @todo move to correct scope (i.e. `CollectionQueueList`)
    setFields, // @todo move to correct scope (i.e. `CollectionQueueList`)
  };
};

type ICtx = ReturnType<typeof useCtxState>;
export type ICollectionQueuesCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);

export const useCollectionQueuesCtx = useCtxFactory(Ctx);
const CollectionQueuesProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default CollectionQueuesProvider;
