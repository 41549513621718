import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import WholesaleCompanyForm from './WholesaleCompanyForm';
import SubmitButtons from './SubmitButtons';

/** ### Form view layout */
const WsCompanyModalDetailView: FC = () => {
  return (
    <Grid container direction="column" flex={1}>
      <WholesaleCompanyForm />
      <SubmitButtons />
    </Grid>
  );
};

export default WsCompanyModalDetailView;
