import { FC, PropsWithChildren, createContext, useState, useEffect, act } from "react";
import { useForm } from "react-hook-form";
import { useMatch, useNavigate } from "react-router-dom";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useReq from "@/utils/useReq";
import { companyService } from "@/services/companyService";
import { useAuthSelector } from "@/features/auth/authSlice";
import { RepoCompany } from "@/interfaces/RepoCompany";

const defaultValues: RepoCompany = {
  recId: 0,
  company: "",
  contact: null,
  cellPhone: null,
  officePhone: null,
  fax: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  email: null,
  notes: null,
  insuranceExpired: null,
  bondExpired: null,
  licenseExpired: null,
  insuranceRequired: false,
  bondRequired: false,
  licenseRequired: false,
  sendText: false,
  sendEmail: false,
};

const useCtxState = () => {
  const navigate = useNavigate();
  const compId = useAuthSelector((s) => s.compId);
  const match = useMatch({ path: "/:root/:viewRoot/:currentView/:id" });
  const repoCompanyId = match?.params?.id && (match.params.id === "new" ? 0 : Number.parseInt(match.params.id))
  const [repoCompanies, setRepoCompanies] = useState<RepoCompany[] | undefined>(undefined);
  const [activeRepoCompany, setActiveRepoCompany] = useState<RepoCompany | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const repoCompanyDetailForm = useForm<RepoCompany>({
    defaultValues,
    mode: "onChange",
  });

  const { reset } = repoCompanyDetailForm;

  const repoCompaniesRows = useReq(async () => {
    if (!compId) return;    
    return await companyService.getRepoCompaniesByCompanyId(compId!);
  });

  const loadRepoCompaniesList = async () => {
    const repoCompaniesData = repoCompaniesRows.value?.sort((a, b) => a.recId! - b.recId!);
    
    if (!repoCompaniesData || !Array.isArray(repoCompaniesData)) {
      setRepoCompanies([]);
      return;
    }
    
    setRepoCompanies(repoCompaniesData);

    if (repoCompanyId) {
      var repoCompany = repoCompaniesData.find(repo => repo.recId == repoCompanyId);
      setActiveRepoCompany(repoCompany);
      reset(repoCompany);
    }    
  };

  const handleRowClick = (recId: number) => {
    if (!repoCompanies) return;
  
    const selectedRepoCompany = repoCompanies.find((repoCompany) => repoCompany.recId === recId);
  
    if (selectedRepoCompany) {
      reset(selectedRepoCompany);
      setActiveRepoCompany(selectedRepoCompany);
      navigate(`${recId}`);
    } else {
      setActiveRepoCompany(undefined);
      console.warn(`Repo Company with recId ${recId} not found`);
    }
  };  

  useEffect(() => {
    repoCompaniesRows.load();
  }, [compId]);

  useEffect(() => {
    loadRepoCompaniesList();
  }, [repoCompaniesRows.value]);

  return {
    isLoading: repoCompaniesRows.isLoading,
    resetToDefault: () => reset(defaultValues),
    repoCompanies, 
    setRepoCompanies,
    activeRepoCompany,
    setActiveRepoCompany,
    repoCompanyDetailForm,
    handleRowClick,
    isSubmitting,
    setIsSubmitting,
    loadRepoCompanies: repoCompaniesRows,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type RepoCompanyProviderCtx = ICtx;
export const useRepoCompanyProviderCtx = useCtxFactory(Ctx);

const RepoCompanyProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default RepoCompanyProvider;
