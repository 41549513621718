import { FC } from "react";
// state
import { useAddOnsSalesTaxCtx } from "./AddOnsSalesTaxProvider";
// interfaces
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Button from "@/mui/components/buttons/MuiKendoButton";
import ConfirmationModal from "@/mui/components/ConfirmationModal";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
// components
import TaxFields from "./customFields/TaxFields";
import SettingsFields from "./customFields/SettingsFields";
import BackLink from "@/mui/components/buttons/BackLink";
// services
import { companyService } from "@/services/companyService";

const CancelButton: FC = () => {
  const { reset } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);
  const activeProfile = useAddOnsSalesTaxCtx((s) => s.activeProfile);

  // Event handlers
  const handleClickCancel = () => {
    reset({ ...activeProfile } as CompanyAddOnsTaxForm);
  };

  return (
    // @note this will replace below: <Button onClick={handleClickCancel} sx={{ backgroundColor: 'rgb(245,20,20)' }} color="error">
    <Button type="button" onClick={handleClickCancel}>
      Cancel
    </Button>
  );
};
const SubmitButton: FC = () => {
  // @note `isDirty` is not updating - need to look into that
  const { isValid, isDirty, isSubmitting } = useAddOnsSalesTaxCtx(
    (s) => s.addOnsSalesTaxForm.formState
  );
  const setIsConfirmOpen = useAddOnsSalesTaxCtx((s) => s.setIsConfirmOpen);

  // Event handlers
  const handleOpenConfirmationModal = () => setIsConfirmOpen(true);

  return (
    <Button
      type="button"
      onClick={handleOpenConfirmationModal}
      disabled={!isValid || !isDirty || isSubmitting}
    >
      Submit
    </Button>
  );
};

const AddOnsSalesTaxDetail = () => {
  const isConfirmOpen = useAddOnsSalesTaxCtx((s) => s.isConfirmOpen);
  const setIsConfirmOpen = useAddOnsSalesTaxCtx((s) => s.setIsConfirmOpen);
  const setActiveProfile = useAddOnsSalesTaxCtx((s) => s.setActiveProfile);
  const { handleSubmit, formState, getValues, reset } = useAddOnsSalesTaxCtx(
    (s) => s.addOnsSalesTaxForm
  );

  const watcher = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm.watch());

  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);
  const handleSubmitForm = async (form: CompanyAddOnsTaxForm) => {
    try {
      const res = await companyService.updateAddOnsSalesTaxSettings(form);
      console.log(res);
      reset(form);
      setActiveProfile(form);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      handleCloseConfirmationModal();
    }
  };

  const handleClearTaxes = () => {
    const currentValues = getValues(); // Get current form values
    const updatedValues: CompanyAddOnsTaxForm = {
      ...currentValues,
      stateRate: 0.0,
      countyRate: 0.0,
      cityRate: 0.0,
      locRate: 0,
      stateOn: null,
      countyOn: null,
      cityOn: null,
      locOn: null,
      stateMax: 0,
      countyMax: 0,
      cityMax: 0,
      locMax: 0,
      stateMin: 0,
      countyMin: 0,
      cityMin: 0,
      locMin: 0,
    };

    reset(updatedValues); // Reset the form with updated values
  };

  return (
    <Grid container direction="column" flex={1} boxSizing={"border-box"} padding={2}>
      <BackLink />
      <Grid
        container
        direction="column"
        // onSubmit={handleSubmit(handleSubmitForm)}
        gap={3}
        flex={1}
        component="form"
      >
        {/* Sales Tax Settings Header */}
        <Box display="flex" alignItems="center" width="100%" marginTop={2} marginBottom={2}>
          <Box fontSize="18px" fontWeight="700" marginRight={2}>
            Sales Tax Settings
          </Box>
          <Divider
            orientation="horizontal"
            sx={{
              flex: 1,
              borderBottomWidth: "1px",
              marginRight: "16px",
              backgroundColor: "#e6e6e6",
            }}
          />
          <Button type="button" onClick={handleClearTaxes}>
            Clear Taxes
          </Button>
        </Box>

        <Grid container direction="row" flex={1}>
          <TaxFields />
        </Grid>

        {/* Taxable Settings for Addons Header */}
        <Box display="flex" alignItems="center" width="100%" marginTop={4} marginBottom={2}>
          <Box fontSize="18px" fontWeight="700" marginRight={2}>
            Taxable Settings for Addons
          </Box>
          <Divider
            orientation="horizontal"
            sx={{
              flex: 1,
              borderBottomWidth: "1px",
              marginRight: "16px",
              backgroundColor: "#e6e6e6",
            }}
          />
        </Box>

        <Grid container direction="row" flex={1}>
          <SettingsFields />
        </Grid>
        <Grid container direction="row" justifyContent="end" gap={1}>
          <SubmitButton />
          <CancelButton />
        </Grid>
        <ConfirmationModal
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          title="Confirm Sales Tax and Add On Setting Change"
          message={"You are about to change the company sales tax and add on settings."}
          onConfirm={handleSubmit(handleSubmitForm)}
          isSubmitting={formState.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

export default AddOnsSalesTaxDetail;
