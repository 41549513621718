// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const terminationsColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('lastPmtPaid', 'Last Paid', 120, 'date'),
  newColumnDef('chgOffDate', 'C/O Date', 120, 'date'),
  newColumnDef('chgOffCat', 'C/O Category', 150),
  // @note This is ambiguous since all values are `null`. But through process of elimination, this seems to be the field used in the alpha-implementation.
  newColumnDef('assignment', 'Lender', 150),
  newColumnDef('code3', 'Lot', 80),
];
