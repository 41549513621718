import { FC, PropsWithChildren } from 'react';
import { StyleSheet, View, ViewProps } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    borderTop: '1px solid #000',
  },
});

/** ###  */
const PdfTableRow: FC<PropsWithChildren<ViewProps>> = ({
  style: styleOverrides,
  children,
  ...rowProps
}) => {
  return (
    <View {...rowProps} wrap={false} style={{ ...styles.row, ...styleOverrides }}>
      {children}
    </View>
  );
};

export default PdfTableRow;
