import { FC } from 'react';
// kendo
import {  TextInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
// mui
import Section from '@/mui/components/form/Section';
import SectionHeader from '@/mui/components/form/Section/SectionHeader';
import SectionBody from '@/mui/components/form/Section/SectionBody';
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';

/** ###  */
const GeneralInfoSection: FC = () => {
  const selectedPmt = usePmtReversalDetailCtx((s) => s.selectedPmt);

  return (
    <Section>
      <SectionHeader label="General Information" wrap="nowrap" isExpandable={false} />

      <SectionBody
        leftColElems={[
          <TextInput value={selectedPmt?.acctNum || '-'} disabled label="Account Number" key={1} />,
          <DateInput value={selectedPmt?.pmtTime} disabled label="Payment Date" key={2} />,
          <TextInput value={selectedPmt?.paidRef || '-'} disabled label="Check Number" key={3} />,
        ]}
        rightColElems={[
          <TextInput value={selectedPmt?.paidBy || '-'} disabled label="Paid By" key={1} />,
          <TextInput value={selectedPmt?.paidIn || '-'} disabled label="Paid In" key={2} />,
          <TextInput
            value={selectedPmt?.paymentProcessor || '-'}
            disabled
            label="Card Processor"
            key={3}
          />,
        ]}
      />
    </Section>
  );
};

export default GeneralInfoSection;
