import { AxiosService } from './axiosService';
// utils
import { displayErrors } from '@/utils/helpers/general';
// interfaces
import { NewSamTask, SamTask } from '@/interfaces/sam';

class SamTasksService extends AxiosService {
  public constructor() {
    super();
  }

  async getSamTasks(
    appRecId: number,
    transType: 'Account' | 'Inventory' | 'Application',
    status: 'Active' | 'Complete' | 'Completed' | 'Dismissed'
  ) {
    try {
      const { data } = await this.axios.get<SamTask[]>('/Users/Hotlist', {
        params: { recId: appRecId, transType, status },
      });

      return data;
    } catch (e) {
      displayErrors('Unable to get SAM tasks');
      throw e;
    }
  }

  async updateSamTask(samTask: SamTask) {
    try {
      const { data } = await this.axios.put('/Users/Hotlist', samTask);
      return data;
    } catch (e) {
      displayErrors('Unable to edit SAM task');
      throw e;
    }
  }

  async createSamTask(samTask: NewSamTask) {
    try {
      const { data } = await this.axios.post('/Users/Hotlist', samTask);
      return data;
    } catch (e) {
      displayErrors('Unable to create SAM task');
      throw e;
    }
  }
}

export const samTasksService = new SamTasksService();
