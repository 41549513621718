import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateFilter from '../tableFilterComponents/DateFilter/DateFilter';
// state
import { useAccountsViewCtx } from '../accountsViewState';
import { PmtSubviewKey, SubviewKey } from '../viewInfo';

// @todo move to view-info
const subviewsWithDateFilters: SubviewKey[] = [
  PmtSubviewKey.paymentActivity,
  PmtSubviewKey.miscPayment,
];

/** ### Set table filters here
 * - Renders filter(s) based on the types set in the column spec
 * - Handles conditional logic for determining if current subview allows filters
 * - String filters have a set amount column filter is a string filter
 */
const TableFilters: FC = () => {
  const subview = useAccountsViewCtx((s) => s.subview);

  // @todo should prevent rendering when filters don't exist
  const isDateFilterEnabled = subviewsWithDateFilters.includes(subview);

  return (
    <Grid container direction="row" gap={1}>
      {/* Category filters not yet requested as a feature */}
      {/* <CategoryFilter field="saleType" /> */}
      {isDateFilterEnabled && <DateFilter field="pmtDate" />}
    </Grid>
  );
};

export default TableFilters;
