// kendo
import { GridColumnProps } from '@progress/kendo-react-all';
// config
import { applicationsSubviewConfig } from './subviewConfigs/applications';
import { cashSalesSubviewConfig } from './subviewConfigs/cashSales';
import { lhPhFundingSubviewConfig } from './subviewConfigs/lhPhFunding';
import { outsideFundingSubviewConfig } from './subviewConfigs/outsideFunding';
import { postedSalesSubviewConfig } from './subviewConfigs/postedSales';
import { wholesalesSubviewConfig } from './subviewConfigs/wholesales';
import { salesQuotesSubviewConfig } from './subviewConfigs/dealsQuotes';
// interfaces
import { ISalesSubviewDbCommon, SalesFilterKey, SalesSortKey } from '../interfaces';
import { SalesSubview } from '../enums';

export const routeBase = 'sales';

export type SubviewInfo<TSortable = SalesSortKey, TFilterable = SalesFilterKey> = {
  /** Subview slug & identifier/lookup-key */
  route: SalesSubview;
  /** View header title */
  text: string;
  /** Table column config */
  columns: (GridColumnProps & { display?: boolean })[];
  dateFilterField?: { field: TFilterable; label: string };
  initSortField?: TSortable;
  initSortDirection?: "asc" | "desc";
  categoryFilter?: { field: TFilterable; label: string; options: Map<string, string> };
  sortableFields: TSortable[];
  filterableFields: TFilterable[];
  dbRowValidationFxn(row: ISalesSubviewDbCommon): ISalesSubviewDbCommon;
};

export const subviewInfo: SubviewInfo[] = [
  applicationsSubviewConfig,
  cashSalesSubviewConfig,
  lhPhFundingSubviewConfig,
  outsideFundingSubviewConfig,
  postedSalesSubviewConfig,
  wholesalesSubviewConfig,
  salesQuotesSubviewConfig,
];

export const subviewInfoMap: Map<SalesSubview, SubviewInfo> = new Map(
  subviewInfo.map((v) => [v.route, v])
);
