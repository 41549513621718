import { FC, PropsWithChildren, createContext, useState } from "react";
// mui
import { GridPaginationModel } from "@mui/x-data-grid";
// kendo
import { SortDescriptor, State } from "@progress/kendo-data-query";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// interfaces
import { type CollectionQueue } from "@/services/collectionsService";

const initialSort: SortDescriptor[] = [{ field: "queueName", dir: "desc" }];
const initialDataState: State = { skip: 0, take: 10 };

const useCtxState = () => {
  // Table state
  const [expandedRowIdx, setExpandedRowIdx] = useState<number | string | null>(null);
  /** @deprecated Remove state, convert to getter (index value using `expandedRowIdx`) */
  const [expandedRow, setExpandedRow] = useState<CollectionQueue | null>(null);
  /** @deprecated Manage table-states with `TableProvider` */
  const [pageModel, setPageModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
  /** @deprecated Manage table-states with `TableProvider` */
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
  /** @deprecated Manage table-states with `TableProvider` */
  const [page, setPage] = useState<State>(initialDataState);
  /** @deprecated Manage table-states with `TableProvider` */
  const [pageSizeValue, setPageSizeValue] = useState<number>(4);

  return {
    // Table states
    expandedRowIdx,
    setExpandedRowIdx,
    expandedRow,
    setExpandedRow,
    pageModel,
    setPageModel,
    sort,
    setSort,
    page,
    setPage,
    pageSizeValue,
    setPageSizeValue,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
export type ICollectionQueuesListCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);

export const useCollectionQueuesListCtx = useCtxFactory(Ctx);
const CollectionQueuesListProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default CollectionQueuesListProvider;
