import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatPercentage } from '@/utils/helpers/general';

/** ###  */
const CollectionsRatioCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const collectionsInfo = useDashboardViewCtx((s) => s.dashboardData?.collectionsInfo);
  const totalPaid = collectionsInfo?.totalPaid;
  const totalDue = collectionsInfo?.totalDue;

  const isRatioNumeric =
    typeof totalPaid === 'number' && typeof totalDue === 'number' && totalDue > 0;

  const collectionRatio = isRatioNumeric ? formatPercentage(totalPaid / totalDue) : '- %';
  const secondary = isRatioNumeric ? 'Total across all locations' : 'No payments for this range';

  return (
    <DashboardCardLayout
      header="Collections Ratio"
      primary={collectionRatio}
      secondary={secondary}
      isLoading={isLoading}
    />
  );
};

export default CollectionsRatioCard;
