/** @deprecated use enum in the Gps directory */
export enum GpsDevice {
  CalAmp = 'CalAmp',
  Ituran = 'Ituran',
  PassTime = 'PassTime',
  Spireon = 'Spireon',
}

/** @deprecated use enum in the Gps directory */
export enum GpsDeviceType {
  None = 'None',
  GpsOnly = 'GPS Only',
  GpsStarterInterrupt = 'GPS & Starter Interrupt',
}
