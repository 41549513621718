import { FC } from "react";
// interfaces
import { DynamicData } from "@/services/collectionsService";

/** ###  */
const DynamicDataToolTip: FC<{ data: DynamicData }> = ({ data }) => (
  <div>
    <div>
      <div>
        <strong>Code: </strong>
        {data.code}
      </div>
      <div>
        <strong>Sample: </strong>
        {data.sample}
      </div>
      <div>
        <strong>Description: </strong>
        {data.description}
      </div>
    </div>
  </div>
);

export default DynamicDataToolTip;
