// kendo
import { GridColumnProps } from '@progress/kendo-react-all';
// utils
import { defaultTableColumns } from './ReportsList/tableColumns';
import { ersTableColumns } from './EmailReportSchedulesTable/tableColumns';

export enum ReportsSubviews {
  default = 'default',
  ers = 'ers',
}

// @note add others (via a union) if necessary
export type SubviewKey = ReportsSubviews;

export type SubviewInfo = {
  /** Subview slug & identifier/lookup-key */
  route: SubviewKey;
  /** View header title */
  text: string;
  /** Table column config */
  columns: (GridColumnProps & { display?: boolean })[];
};

export const viewInfo: SubviewInfo[] = [
  {
    text: 'Default',
    route: ReportsSubviews.default,
    columns: defaultTableColumns,
  },
  {
    text: 'Email Report Schedules',
    route: ReportsSubviews.ers,
    columns: ersTableColumns,
  },
];

export const viewInfoMap: Map<SubviewKey, SubviewInfo> = new Map(viewInfo.map((v) => [v.route, v]));
