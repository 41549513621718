import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Section from '@/mui/components/form/Section';
import SectionHeader from '@/mui/components/form/Section/SectionHeader';
import SectionBody from '@/mui/components/form/Section/SectionBody';
// kendo
import {  Checkbox, DropdownInput, TextInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';
import { usePmtReversalCtx } from '../../PmtReversalProvider';
// interfaces
import { reversalLabelMap, reversalLookupMap } from '@/enums/payment';

/** ###  */
const ReversalInfoSection: FC = () => {
  const employees = usePmtReversalCtx((s) => s.employees);
  const pmtForm = usePmtReversalDetailCtx((s) => s.pmtForm);
  const errors = usePmtReversalDetailCtx((s) => s.pmtForm.formState.errors);
  const reversalLabelOptions = usePmtReversalDetailCtx((s) => s.reversalLabelOptions);
  const isSystemOnlyDisabled = usePmtReversalDetailCtx((s) => s.isSystemOnlyDisabled);

  return (
    <Section>
      <SectionHeader label="Reversal Information" isExpandable={false} />

      <SectionBody
        leftColElems={[
          <Controller
            name="fee"
            control={pmtForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <CurrencyInput {...field} label="NSF/ACH Fee" required errors={!!errors.fee} />
            )}
            key={1}
          />,
          <Controller
            name="reason"
            control={pmtForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Payment Reversal Reason"
                required
                errors={!!errors.reason}
              />
            )}
            key={2}
          />,
          <Controller
            name="systemOnly"
            control={pmtForm.control}
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={isSystemOnlyDisabled}
                label="System Only (No Refund)"
              />
            )}
            key={3}
          />,
          <Controller
            name="reversalType"
            control={pmtForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownInput
                {...field}
                label="Confirm Reversal Type"
                data={reversalLabelOptions}
                value={reversalLabelMap.get(field.value)}
                onChange={(e) => field.onChange(reversalLookupMap.get(e.value))}
                defaultValue={reversalLabelMap.get(field.value)}
                required
                errors={!!errors.reversalType}
              />
            )}
            key={4}
          />,
        ]}
        rightColElems={[
          <Controller
            name="userRecId"
            control={pmtForm.control}
            rules={{ required: true }}
            render={({ field: { value, onChange, ...field } }) => (
              <DropdownInput
                {...field}
                label="Employee"
                required
                data={employees.map((e) => e.shortName) || []}
                onChange={(event) => {
                  onChange(employees.find((emp) => emp.shortName === event.value)?.recId || null);
                }}
                value={employees.find((e) => e.recId === value)?.shortName}
                defaultValue={employees.find((e) => e.recId === value)?.shortName}
                errors={!value}
              />
            )}
            key={1}
          />,
          <Controller
            name="password"
            control={pmtForm.control}
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Password"
                type="password"
                required
                errors={!!errors.password}
              />
            )}
            key={2}
          />,
        ]}
      />
    </Section>
  );
};

export default ReversalInfoSection;
