import { FC } from 'react';
// kendo
import { Modal } from '@/components/modals/Modal';
// components
import { GpsHistory } from '@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsHistory';
// state
import { useGpsCtx } from '@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsProvider';

/** ###  */
const GpsHistoryModal: FC = () => {
  const isGpsModalOpen = useGpsCtx((s) => s.isGpsModalOpen);
  const setIsGpsModalOpen = useGpsCtx((s) => s.setIsGpsModalOpen);

  return (
    <Modal
      isOpen={isGpsModalOpen}
      onCloseButtonClick={() => setIsGpsModalOpen(false)}
      centerModal
      closeButton
      title="GPS History"
      panelStyle={{ maxWidth: '90%' }}
    >
      <GpsHistory isInventory={true} />
    </Modal>
  );
};

export default GpsHistoryModal;
