import { useEffect, useState } from 'react';
import {  Button, TextInput, TextArea } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';
import { accountsService } from '@/services/accountsService';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { genericRequired } from '@/utils/helpers/formValidation';
import { Loader } from '@progress/kendo-react-all';
import { Controller, useForm } from 'react-hook-form';
import { ReleasePossessionInformation, ReleasePossessionPayload } from '@/interfaces';
import { useAppDispatch } from '@/store/store';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';

interface ReleasePossessionForm {
  note: string;
}

export const ReleasePossession = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [releasePossessionInfo, setReleasePossessionInfo] =
    useState<ReleasePossessionInformation>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ReleasePossessionForm>();

  const colRecId = Number(params.colRecId);

  const submit = async (data: ReleasePossessionForm) => {
    setSubmitting(true);
    try {
      const payload: ReleasePossessionPayload = {
        colRecId,
        note: data.note ?? undefined,
      };
      await accountsService.releasePossession(payload);
      toast.success('Release possession successful');
      dispatch(getAccountInformation(colRecId));
      navigate('..');
    } finally {
      setSubmitting(false);
    }
  };

  const initData = async () => {
    setLoading(true);
    try {
      const res = await accountsService.getReleasePossessionInformation(colRecId);
      setReleasePossessionInfo(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <AccountsMainPanel navBarTitle="Release Possession">
      {loading ? (
        <Loader size="large" />
      ) : !releasePossessionInfo ? (
        <div>Unable to load necessary information for this account</div>
      ) : (
        <form className={styles.columns} onSubmit={handleSubmit(submit)}>
          <div className={styles.column}>
            <DateInput
              readOnly
              label="Date Released"
              value={formatDate(new Date(), { pattern: DateFormat.DateInput })}
            />
            <TextInput readOnly label="Miles" value={releasePossessionInfo.iopMiles ?? undefined} />
            <TextInput
              readOnly
              label="Stock #"
              value={releasePossessionInfo.stockNum ?? undefined}
            />
            <CurrencyInput
              readOnly
              label="Principal Balance"
              value={releasePossessionInfo.prinBal ?? undefined}
            />
            <CurrencyInput
              readOnly
              label="Repo Expenses"
              value={releasePossessionInfo.newTotalRepoE ?? undefined}
            />
            <Controller
              name="note"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextArea
                  label="Release Possession Note"
                  required
                  rows={3}
                  errors={errors.note?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className={styles.column}>
            <Spacer expand />
            <Button label="Submit" loading={submitting} fullWidth={false} />
          </div>
        </form>
      )}
    </AccountsMainPanel>
  );
};
