export class SalesQuotesDb {
  constructor(
    public recId: number,
    public orgId: number,
    public locId: number,
    public compId: number,
    public quoteDate: Date | null,
    public customerName: string | null,
    public customerPhone: string | null,
    public code3: string | null,
    public location: string | null
  ) {}

  static new(newSalesQuoteRow: SalesQuotesDb): SalesQuotesDb {
    return new SalesQuotesDb(
      newSalesQuoteRow.recId,
      newSalesQuoteRow.orgId,
      newSalesQuoteRow.locId,
      newSalesQuoteRow.compId,
      newSalesQuoteRow.quoteDate,
      newSalesQuoteRow.customerName,
      newSalesQuoteRow.customerPhone,
      newSalesQuoteRow.code3,
      newSalesQuoteRow.location
    );
  }
}

export type ISalesQuotesDb = SalesQuotesDb;
