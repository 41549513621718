import { FC, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import React from "react";
// MUI Components
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import NestedDetailLayout from "@/mui/components/layout/NestedDetailLayout";
// Custom Components
import { Icons } from "@/components/icons";
import { Loader } from "@/components/loader/Loader";
import { CashBuyer } from "./CashBuyer";
import { CashCoBuyer } from "../coBuyer/CashCoBuyer";
import AppPdfLayout from "../../AppPdfLayout";
// State and Context
import { useAppTabViewCtx } from "../../AppTabViewProvider";
import AppTabViewProvider from "../../AppTabViewProvider";
import { useSalesSelector } from "@/features/Sales/salesSlice";
// Utilities and Enums
import { BuyerType } from "@/enums/general";
import { DCS_BLUE, COLOR_INACTIVE, HOVER_TRANSITION_SPEED } from "@/mui/theme/colors";
import { ApplicationDb } from "@/features/Sales/interfaces/application";
import { useLocation } from "react-router-dom";

// Define the tab names
type BuyerInfoTabName = "Buyer" | "Co-Buyer";

// Map the tabs to their components, mirroring how Application tabs are handled
const buyerInfoTabInfoMap = new Map<BuyerInfoTabName, { component: JSX.Element }>([
  ["Buyer", { component: <CashBuyer buyerOrCobuyer={BuyerType.Buyer} /> }],
  ["Co-Buyer", { component: <CashCoBuyer /> }]
]);

const handlePrintApplication =
  (applicationData: ApplicationDb, setIsPrinting: (v: boolean) => void) => async (_: any) => {
    try {
      setIsPrinting(true);
      const htmlMarkup = ReactDOMServer.renderToStaticMarkup(
        <AppPdfLayout applicationData={applicationData} />
      );

    } finally {
      setIsPrinting(false);
    }
  };

// NavTab for Buyer and Co-Buyer, sets currentTab on click
const NavTab: FC<{ title: BuyerInfoTabName }> = ({ title }) => {
  const currentTab = useAppTabViewCtx((s) => s.currentTab);
  const setCurrentTab = useAppTabViewCtx((s) => s.setCurrentTab);
  const isActive = currentTab === title;

  return (
    <Grid
      fontSize={16}
      sx={{
        color: isActive ? DCS_BLUE : COLOR_INACTIVE,
        fontWeight: isActive ? 700 : 500,
        borderTop: "2px solid transparent",
        borderBottom: isActive ? `2px solid ${DCS_BLUE}` : "2px solid transparent",
        transitionProperty: "border-bottom-color, font-weight",
        transitionDuration: HOVER_TRANSITION_SPEED,
        "&:hover": { borderBottom: `2px solid ${DCS_BLUE}`, cursor: "pointer" }
      }}
      fontWeight={500}
      onClick={() => setCurrentTab(title)}
    >
      {title}
    </Grid>
  );
};

// Header similar to AppTabHeader but for Buyer Info
const BuyerInfoTabHeader: FC = () => {
  const applicationData = useSalesSelector((s) => s.applicationData);
  const [isPrinting, setIsPrinting] = useState(false);

  const navTabElems = [
    <NavTab title="Buyer" key="Buyer" />,
    <NavTab title="Co-Buyer" key="Co-Buyer" />
  ];

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#f5f5f5", 
        padding: "16px",
        borderBottom: "1px solid #e0e0e0"
      }}
    >
      {/* Left Side: Title */}
      <Grid>
        <Grid container direction="row" alignItems="center" gap={3}>
          <Grid fontSize={20} fontWeight={700}>
            Buyer Information
          </Grid>
        </Grid>
      </Grid>

      {/* Right Side: Tabs and Print Button */}
      <Grid>
        <Grid container direction="row" alignItems="center" gap={3}>
          {navTabElems}
          <IconButton onClick={handlePrintApplication(applicationData, setIsPrinting)}>
            {isPrinting ? <Loader /> : <Icons.Print />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BuyerInfoTabView: FC = () => {
  const currentTab = useAppTabViewCtx((s) => s.currentTab);
  const ActiveTabView = buyerInfoTabInfoMap.get(currentTab as BuyerInfoTabName)?.component || (
    <div>Buyer Info Tab not Found</div>
  );

  return (
    <NestedDetailLayout sx={{ flex: 1 }} header={<BuyerInfoTabHeader />}>
      {ActiveTabView}
    </NestedDetailLayout>
  );
};

const BuyerInfoTab: FC = () => (
  <AppTabViewProvider>
    <BuyerInfoTabView />
  </AppTabViewProvider>
);

export default BuyerInfoTab;