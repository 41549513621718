import { FC } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom';
// components
import AuthenticatedContainer from '@/components/authenticatedContainer/AuthenticatedContainer';
import Login from './features/RootView/Login';
import { Admin } from './features/admin/Admin';
import Reports from './features/Reports';
import Sales from './features/Sales';
import Settings from './features/Settings';
import AccountsRouter from './features/Accounts';
import CollectionQueuesRouter from './features/collections/CollectionQueuesRouter';
import InventoryContainer from './features/inventory/InventoryContainer';
import Dashboard from '@/features/Dashboard';
// state
import AppProviders from './AppProviders';
import AuthProvider from './AppProviders/AuthProvider';
// style
import 'react-toastify/dist/ReactToastify.css';

/** @todo implement according to the documentation:
 * - Move `router` const out of the component render
 * - Use `path`, `Component` and `children` properties of `createHashRouter` accurately
 *
 * ### Additional notes
 *
 * `create*Router` has to be used instead of just wrapping your routes in a <*Router>
 * This is because this gives you a "data router", and we use react-router APIs (maybe just useBlocker?)
 * that only work in a data router
 * https://reactrouter.com/en/main/routers/picking-a-router#data-apis
 *
 * Consider moving all other blocks of <Route>s (e.g. AccountsRouter, etc) into this one router definition
 * Having them all in one place would help give a clearer picture of all navigation in the app
 */
const router = createHashRouter([
  {
    children: createRoutesFromElements(
      <>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/*" element={<Navigate to="/auth/login" replace={true} />} />

        <Route
          path="*"
          element={
            <AppProviders>
              <AuthenticatedContainer />
            </AppProviders>
          }
        >
          <Route path="accounts/*" element={<AccountsRouter />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="collections/*" element={<CollectionQueuesRouter />} />
          <Route path="reports/*" element={<Reports />} />
          <Route path="sales/*" element={<Sales />} />
          <Route path="inventory/*" element={<InventoryContainer />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="dashboard/*" element={<Dashboard />} />
          <Route path="" element={<Navigate to="/accounts" replace={true} />} />
          <Route path="*" element={<Navigate to="/accounts" replace={true} />} />
        </Route>
      </>
    ),
  },
]);

const Router: FC = () => (
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

export default Router;
