import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";

/** Zod-field validation for `Dayjs` instances */
export const zDayjsInstance = z.instanceof(dayjs as unknown as typeof Dayjs);

/** Zod-field schema to handle date fields
 * - Handles input types: [dayjs, string, Date]
 * - Outputs:
 * @returns any
 */
export const zDayjs = z.preprocess((val) => {
  const toDayjs = dayjs(val as any);
  return toDayjs.isValid() ? toDayjs : null;
}, zDayjsInstance);

const numberLike = z.union([z.number(), z.string(), z.boolean()]);
export const zNumeric = numberLike.pipe(z.coerce.number());

const formRouteRecIdLike = z.union([z.number(), z.string(), z.enum(["new"])]);
/** RecId for a route which renders a form */
export const zFormRouteRecId = formRouteRecIdLike.pipe(z.coerce.number().or(z.enum(["new"])));
type zFormRouteRecId = z.infer<typeof zFormRouteRecId>;

// Convenience schemas
export const zDeprecNumberField = z.number().nullable().catch(null);
export const zDeprecStringField = z.string().nullable().catch(null);
export const zDeprecBoolField = z.boolean().nullable().catch(null);
export const zCondStringField = z.string().nullable().default(null);
export const zCondNumberField = z.number().nullable().default(null);
export const zCondBoolField = z.boolean().nullable().default(null);
