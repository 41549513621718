import { Link } from 'react-router-dom';

export const Admin = () => (
  <>
    <div>admin</div>
    <div>
      <Link to="/collections">link example</Link>
    </div>
  </>
);
