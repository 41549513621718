import { Button } from '@/components';
import { BasicTable } from '@/components/table/Table';
import { Link } from 'react-router-dom';
import { SectionHeader } from '@components/sectionHeader/SectionHeader';
import salesTabStyles from '../SalesTab.module.scss';
import { useCryptoSign } from './useCryptoSign';

export const CryptoSign = () => {
  const { getCryptoSignSignaturesLoading, cryptoSignSignatures, cryptoSignColumns } =
    useCryptoSign();

  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>CryptoSign</h2>
        <Link to="../" relative="path">
          <Button label="Documents" />
        </Link>
      </header>
      <main className={salesTabStyles.body}>
        <SectionHeader title="CryptoSign Information" />
        <BasicTable
          data={cryptoSignSignatures}
          columns={cryptoSignColumns}
          loading={getCryptoSignSignaturesLoading}
        />
      </main>
    </div>
  );
};
