import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@/components';
import { useBuyersGuideCtx } from '../BuyersGuideProvider';

/** ###  */
const ButtonGroup: FC = () => {
  const { formState: { isDirty, isValid } } = useBuyersGuideCtx((s) => s.buyersGuideForm);

  const isDisabled = !isValid || !isDirty;
  return (
    <Grid container direction="row" flex={1} justifyContent="flex-end">
      <Button
        label="Submit"
        type="submit"
        disabled={isDisabled}
        buttonStyle={{ width: '134px', margin: '10px 10px 0px 0px' }}
      />
      <Button
        label="Cancel"
        disabled={isDisabled}
        buttonStyle={{ width: '134px', margin: '10px 0px 0px 0px' }}
      />
    </Grid>
  );
};

export default ButtonGroup;
