import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// components
import DataDisplay from '../DataDisplay';
import AxisColumn from './AxisColumn';
import DataColumn from './DataColumn';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useDashboardViewCtx } from '../../DashboardViewProvider';
// utils
import { dashboardService } from '@/services/dashboardService';
import { formatPercentage } from '@/utils/helpers/general';
// interfaces
import { AgingByCompanyRes, ColumnData, scrollBarHeight } from './interfaces';

const calcCurrentValues = (data: AgingByCompanyRes | null): Omit<ColumnData, 'title'> => {
  return {
    percent: data?.curPer ?? 0,
    accountCount: data?.curNum ?? 0,
    payDelq: data?.curPmt ?? 0,
    ar: data?.curPrin ?? 0,
  };
};
const calc1to29Values = (data: AgingByCompanyRes | null): Omit<ColumnData, 'title'> => {
  return {
    percent: data?.onePer ?? 0,
    accountCount: data?.oneDayNum ?? 0,
    payDelq: data?.oneDayPmt ?? 0,
    ar: data?.oneDayPrin ?? 0,
  };
};
const calc30to59Values = (data: AgingByCompanyRes | null): Omit<ColumnData, 'title'> => {
  return {
    percent: data?.thirtyPer ?? 0,
    accountCount: data?.thirtyDayNum ?? 0,
    payDelq: data?.thirtyDayPmt ?? 0,
    ar: data?.thirtyDayPrin ?? 0,
  };
};
const calc60PlusValues = (data: AgingByCompanyRes | null): Omit<ColumnData, 'title'> => {
  return {
    percent: data?.sixtyPer ?? 0,
    accountCount: data?.sixtyDayNum ?? 0,
    payDelq: data?.sixtyDayPmt ?? 0,
    ar: data?.sixtyDayPrin ?? 0,
  };
};

/** ###  */
const AgingReportChart: FC = () => {
  const orgId = useAuthSelector((s) => s.orgId);
  const delqAccts = useDashboardViewCtx((s) => s.dashboardData?.delinquency?.delinquentAccounts);
  const totalAccts = useDashboardViewCtx((s) => s.dashboardData?.delinquency?.totalAccounts);
  const isRatioNumeric =
    typeof delqAccts === 'number' && typeof totalAccts === 'number' && totalAccts > 0;
  const totalPercentLate = isRatioNumeric ? formatPercentage(delqAccts / totalAccts) : '- %';

  const [collectionsInfo, setCollectionsInfo] = useState<AgingByCompanyRes | null>(null);

  // @todo keeping for reference
  // const totalAccounts =
  //   (collectionsInfo?.curNum ?? 0) +
  //   (collectionsInfo?.oneDayNum ?? 0) +
  //   (collectionsInfo?.thirtyDayNum ?? 0) +
  //   (collectionsInfo?.sixtyDayNum ?? 0);
  // const totalLateAccounts =
  //   (collectionsInfo?.oneDayNum ?? 0) +
  //   (collectionsInfo?.thirtyDayNum ?? 0) +
  //   (collectionsInfo?.sixtyDayNum ?? 0);
  // const totalPercentLate = totalAccounts > 0 ? (totalLateAccounts / totalAccounts) * 100 : 0;

  const fetchAgingReportData = async (orgId: number | undefined) => {
    if (!orgId) return;

    const collectionsRes = await dashboardService.getCollectionsAgingByCompany(orgId);
    setCollectionsInfo(collectionsRes || null);
  };

  useEffect(() => {
    fetchAgingReportData(orgId);
  }, [orgId]);

  const secondary = `All Locations - Total Percent Late: ${totalPercentLate}`;

  return (
    <DataDisplay
      title="Aging Report"
      bodyProps={{ gap: 3, sx: { flexWrap: 'nowrap', overflowX: 'hidden', py: 1, px: 2 } }}
      wrap="nowrap"
      sx={{
        overflowX: 'scroll',
        '& *::-webkit-scrollbar': { height: scrollBarHeight },
        '& *::-webkit-scrollbar-thumb': { height: scrollBarHeight },
        '& *::-webkit-scrollbar-track': { height: scrollBarHeight },
      }}
    >
      {/* Chart */}
      <Grid container direction="row" flex={1} wrap="nowrap" sx={{ overflow: 'hidden' }}>
        <AxisColumn />
        <Grid container direction="row" flex={1} wrap="nowrap" sx={{ overflow: 'scroll' }}>
          <DataColumn title="Current" {...calcCurrentValues(collectionsInfo)} />
          <DataColumn title="1-29" {...calc1to29Values(collectionsInfo)} isAlt />
          <DataColumn title="30-59" {...calc30to59Values(collectionsInfo)} />
          <DataColumn title="60+" {...calc60PlusValues(collectionsInfo)} isAlt />
        </Grid>
      </Grid>

      <Typography padding="5px" alignItems="center" fontWeight="700" fontSize="12px">
        {secondary}
      </Typography>
    </DataDisplay>
  );
};

export default AgingReportChart;
