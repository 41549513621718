import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// kendo
import { Button } from '@/components';
// components
import AccountsViewTable from '../AccountsViewTable';
import TableFilters from './TableFilters';
// state
import PmtSubviewProvider from './pmtSubviewState';
import { useAccountsViewCtx } from '../accountsViewState';
import { useTableCtx } from '../tableState';
import { usePmtSubviewData } from './usePmtSubviewData';
// interfaces
import { PmtSubviewKey } from '../viewInfo';

const PmtSubviewList: FC = () => {
  const navigate = useNavigate();
  const paymentList = usePmtSubviewData();
  const viewState = useAccountsViewCtx((s) => s);
  const mappedCompaniesCt = useAccountsViewCtx((s) => s.mappedCompaniesCt);
  const tableState = useTableCtx((s) => s);
  const { initSortFields, initDateFilterFields } = viewState.subviewInfo;

  // Event handlers
  const handleNavToPmtDetail = () => navigate('miscPayment/new');

  // Effects
  useEffect(() => {
    tableState.resetTableState({
      sortFields: initSortFields,
      dateFilterFields: initDateFilterFields,
    });
  }, [viewState.subview]);

  return (
    <AccountsViewTable
      rows={paymentList}
      filterGroupComponent={<TableFilters />}
      buttonComponent={
        viewState.subview === PmtSubviewKey.miscPayment && mappedCompaniesCt === 1 ? (
          <Button label="New Misc Payment" onClick={handleNavToPmtDetail} />
        ) : undefined
      }
    />
  );
};

export default () => (
  <PmtSubviewProvider>
    <PmtSubviewList />
  </PmtSubviewProvider>
);
