import { FC } from "react";
import dayjs from "dayjs";
// mui
import Typography from "@mui/material/Typography";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
// utils
import { DateFormat } from "@/utils/helpers/general";

/** ###  */
const CustomDateCell: FC<GridCellProps> = ({ dataItem, field }) => {
  const cellValue: string | undefined = dataItem[field!]?.timestampLocal;

  // Convert to date/datetime format
  const cellValueDt = dayjs(cellValue);
  const isDt = cellValueDt.isValid();

  const cellValueDtFmt = isDt ? cellValueDt.format(DateFormat.CondensedDate) : "Invalid date";

  // Apply placeholder where no data is provided
  const cellValueFmt = !cellValue ? "-" : cellValueDtFmt;

  return (
    <td className="k-table-td" style={{ textAlign: "center" }}>
      <Typography component="span" variant="tableCell">
        {cellValueFmt}
      </Typography>
    </td>
  );
};

export default CustomDateCell;
