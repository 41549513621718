import { FC } from "react";
// components
import WsFormBodyLayout from "../layoutComponents/WsFormBodyLayout";
import GeneralInfoForm from "./GeneralInfoForm";
import WsCompanyInfoForm from "./WsCompanyInfoForm";
// state
import WholesaleCompanyFormProvider from "./WsCompanyModal/WholesaleCompanyFormProvider";
import { useWholesaleViewCtx } from "../WholesaleViewProvider";

/** ### Wholesale Management Tab Layout */
const WholesaleMgmtTab: FC = () => {
  const isFormLoaded = useWholesaleViewCtx((s) => s.isFormLoaded);
  const isAddlDataLoaded = useWholesaleViewCtx((s) => s.formAddlData !== null);

  if (!isAddlDataLoaded || !isFormLoaded) return <></>;

  return (
    <WholesaleCompanyFormProvider>
      <WsFormBodyLayout>
        <GeneralInfoForm />
        <WsCompanyInfoForm />
      </WsFormBodyLayout>
    </WholesaleCompanyFormProvider>
  );
};

export default WholesaleMgmtTab;
