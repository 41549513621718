import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
// kendo
import { BackLink } from '@components/backLink/BackLink';
// components
import InventoryDetailsRouter from './InventoryDetailsRouter';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useInventoryDetailCtx } from './InventoryDetailProvider';
// utils
import { inventoryService } from '@/services/inventoryService';
import { isProd } from '@/utils/helpers/general';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { CurrentInspection, InventoryVehicle } from '@/interfaces/Inventory';
// style
import styles from './InventoryDetailsContainer.module.scss';

const navigationItemsProd = [
  { name: 'Vehicle', route: 'vehicle' },
  { name: 'Expenses', route: 'expenses' },
  { name: 'Vehicle Check-In', route: 'vehicleCheckIn' },
  { name: 'Vehicle Options', route: 'vehicleOptions' },
  { name: 'Title & Tag', route: 'titleTag' },
  { name: 'Gps & Odometer', route: 'gpsOdometer' },
  { name: 'Suggested Sale/Sold Vehicle', route: 'suggestedSold' },
];

const navigationItemsDev = [
  ...navigationItemsProd,
  { name: 'Buyers Guide', route: 'buyersGuide' },
  { name: 'Documents', route: 'documents' },
];

export const InventoryDetailsContainer: FC = () => {
  const { pathname, search } = useLocation();
  const invRecId = getRouteParamNum(useParams().invRecId);
  const { hasAfsLevel } = useAuthSelector((s) => s);

  const navigationItems = !isProd || hasAfsLevel ? navigationItemsDev : navigationItemsProd;

  const query = new URLSearchParams(search)

  const list = query.get('list');
  let listPath = `/inventory`;
  if (list) {
    listPath = `/inventory/${list}`;
  }
  const setInventoryVehicle = useInventoryDetailCtx((s) => s.setInventoryVehicle);
  const setInspectorList = useInventoryDetailCtx((s) => s.setInspectorList);
  const setCurrentInspection = useInventoryDetailCtx((s) => s.setCurrentInspection);

  const [headerText, setHeaderText] = useState<string>('');

  const loadHeaderText = useCallback(async () => {
    if (invRecId === null) return;

    const headerText = await inventoryService.getHeaderText(invRecId.toString());
    setHeaderText(headerText);
  }, [invRecId]);

  const loadVehicle = useCallback(async () => {
    if (invRecId === null) return;

    const vehicle = await inventoryService.getInventoryVehicle(invRecId.toString());
    setInventoryVehicle(vehicle as InventoryVehicle);
  }, [invRecId]);

  const loadInspectors = async () => {
    const inspectors = await inventoryService.getInspectors();
    setInspectorList(inspectors || []);
  };

  const getCurrentInspection = async () => {
    if (invRecId === null) return;

    try {
      const initVehicleInspection = await inventoryService.getCurrentInspection(invRecId);
      setCurrentInspection(initVehicleInspection as CurrentInspection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadHeaderText();
    loadVehicle();
    loadInspectors();
    getCurrentInspection();
  }, [invRecId]);

  return (
    <div className={styles.container}>
      <div className={styles.backLinkContainer}>
        <BackLink to={listPath} title="Back to list" />
      </div>
      <header className={styles.header}>
        <h1 className={styles.headerText}>{headerText}</h1>
      </header>
      <div className={styles.bodyContainer}>
        <nav className={styles.nav}>
          <h3 className={styles.navHeader}>Inventory</h3>
          <ul>
            {navigationItems.map((navItem, index) => {
              const pathRegex = new RegExp(`.*${navItem.route}$`);
              const isCurrentRoute = pathRegex.test(pathname);
              return (
                <li
                  key={index}
                  className={isCurrentRoute ? styles.listItemActive : styles.listItem}
                >
                  <Link to={navItem.route + search} className={styles.navLink}>
                    {navItem.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={styles.body}>
          <InventoryDetailsRouter />
        </div>
      </div>
    </div>
  );
};
