import { useParams } from 'react-router-dom';
// interfaces
import { SalesRouteParams } from './interfaces';

/** ### React-Router `useParams` typed for 'Sales' subroutes
 * Returns an object of key/value pairs of the dynamic params from the current
 * URL that were matched by the route path.
 *
 * @see https://reactrouter.com/hooks/use-params
 */
export const useSalesParams = useParams<SalesRouteParams>;
