import { useParams } from 'react-router-dom';
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import { PageHeader } from '@/components/pageHeader/PageHeader';
import { useEffect, useState } from 'react';
import { inventoryService } from '@/services/inventoryService';
import ScannedDocuments from './ScannedDocuments';
import Photos from './Photos';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const InventoryDocuments = () => {
  const { invRecId } = useParams();
  const [saleStatus, setSaleStatus] = useState<string>('');
  const [stockNum, setStockNum] = useState<string>('');

  const loadSaleStatus = async () => {
    if (!invRecId) {
      return;
    }

    try {
      const response = await inventoryService.getVehicleDetails(invRecId);
      setSaleStatus(response?.saleStatus ?? '');
      setStockNum(response?.stockNum ?? '');
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    loadSaleStatus();
  }, [invRecId]);

  if (!invRecId) {
    return <></>;
  }

  return (
    <Grid2 className={inventoryPageStyles.container}>
      <Grid2 className={inventoryPageStyles.header}>
        <PageHeader headerLabel="Documents" />
      </Grid2>
      <Grid2 className={inventoryPageStyles.body}>
        <Grid2 className={inventoryPageStyles.bodyContainer}>
          <Grid2 className={inventoryPageStyles.bodyChildMultipleSections}>
            <ScannedDocuments />
            <Photos />
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
