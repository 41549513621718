import { useEffect, useState } from 'react';
import { paymentService, Employee, getDefaultPostPaymentPayload, GetPaymentData, PaymentProviders } from '@services/paymentService';
import commonStyles from '@features/old/payment/Payment.module.scss';
import styles from './salesCashDown.module.scss';
import { CircleSpinner } from 'react-spinners-kit';
import {
  Button,
  DmsDropDown,
  DmsModal,
  DmsNumberInput,
  DmsRadioButtonGroup,
  DmsTextInput,
  EmailReceipt,
  OpenEdge,
  Repay,
  SavedACH,
  SavedCreditCard,
} from '@components/old';
import { SavedPmtMethodRes } from '@/interfaces/CreditCard';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getCanWaiveFeeDeprec,
  getConvenienceFee,
  getIsNewPayment,
  getMpdToken,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  getSavePayment,
  pollForReceiptOld,
} from '@/utils/helpers/payment';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials } from '@/utils/helpers/general';
import { CardProcessorName, PaymentType } from '@/enums';
import { Spacer } from "@/components/spacer/Spacer";
import { useOldPaymentSelector } from '../oldPaymentSlice';
import levelUpImage from '@assets/level_up.png';
import { PostPaymentPayloadOld } from '../interfaces';

const navigateToList = () => window.PmtReturn?.panelSetActive('MASTER', true);

const afterPayment = () => {
  window.PmtReturn?.runAction('RefreshData');
  window.PmtReturn?.refreshListData('Payments');
  navigateToList();
};

interface FormError {
  inputName?: string;
  message?: string;
}

/** @deprecated - needs refactor */
export const SalesCashDown = () => {
  const { compId, colRecId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [appRecId, setAppRecId] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState<GetPaymentData>();
  const [paymentMethods, setPaymentMethods] = useState<SavedPmtMethodRes[]>([]);
  const [formErrors, setFormErrors] = useState([] as FormError[]);
  const [ccOrAchFormErrors, setCcOrAchFormErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dmsPaymentPayload, setDmsPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [transactionType, setTransactionType] = useState<string>('In-Person');
  const [downPayment, setDownPayment] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<string>('Cash');
  const [referenceNumber, setReferenceNumber] = useState<string>('');
  const [amountTendered, setAmountTendered] = useState<number>(0); //Amount given to cashier
  const [changeDue, setChangeDue] = useState<number | null>(0); //Amount of change to be given to customer
  const [employee, setEmployee] = useState<Employee>();
  const [password, setPassword] = useState<string>('');
  const [confirmPostModalOpen, setConfirmPostModalOpen] = useState<boolean>(false);
  const [totalPaid, setTotalPaid] = useState<number>(0); //Amount to be paid
  const [paymentNote, setPaymentNote] = useState<string>('');
  const [includeBuyerEmail, setIncludeBuyerEmail] = useState<boolean>(false);
  const [includeCobuyerEmail, setIncludeCobuyerEmail] = useState<boolean>(false);
  const [processor, setProcessor] = useState<CardProcessorName | undefined>();
  const [processors, setProcessors] = useState<CardProcessorName[]>([]);
  const [providerData, setProviderData] = useState<PaymentProviders>({} as PaymentProviders);

  const [convenienceFee, setConvenienceFee] = useState(0);

  const [waiveFee, setWaiveFee] = useState<boolean>(false);
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [newCard, setNewCard] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('AL');
  const [mpdId, setMpdId] = useState<string>('');
  const [achMpdId, setAchMpdId] = useState<string>('');

  const [saveAccount, setSaveAccount] = useState<boolean>(false);
  const [newAccount, setNewAccount] = useState<boolean>(true);
  const [accountType, setAccountType] = useState<string>('Checking');
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [openEdgeAchModal, setOpenEdgeAchModal] = useState(false);
  const [repayModal, setRepayModal] = useState(false);
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [iframe, setIframe] = useState('');

  const [employeeOptions, setEmployeeOptions] = useState<Employee[]>([]);

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  const paymentTypes = getAllowedPaymentTypes(providerData);

  const longInputStyle = { width: '100%' };

  const initData = async () => {
    setLoading(true);

    try {
      const users = await paymentService.getUsersByCompanyId(compId!);
      setEmployeeOptions(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (defaultEmployee) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      const paymentDetails = await paymentService.getPaymentDetails(colRecId);
      setPaymentDetails(paymentDetails);
      setFirstName(paymentDetails.buyerFirstName);
      setLastName(paymentDetails.buyerLastName);
      setAddress(paymentDetails.buyerAddr);
      setCity(paymentDetails.buyerCity);
      setZip(paymentDetails.buyerZip);
      setState(paymentDetails.buyerState);
      setDownPayment(paymentDetails.tOfPBal || 0);
      setAppRecId(paymentDetails.appRecId);
      setTotalPaid(paymentDetails.tOfPBal || 0);
      setAmountTendered(paymentDetails.tOfPBal || 0);

      paymentService.getPaymentProviders(colRecId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        setProcessors(enabledProviders);
        setProcessor(preferredProviderName);
      });
      // @note this is not an async function
      const dms = await getDefaultPostPaymentPayload(paymentDetails);
      setDmsPaymentPayload(dms);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const submit = () => {
    const tempDmsPayload = dmsPaymentPayload;

    const paymentToSend = amountTendered;

    const mpdToken = getMpdToken(paymentType, mpdId, achMpdId);
    const savePayment = mpdToken ? false : getSavePayment(paymentType, saveCard, saveAccount);
    const isNewPayment = getIsNewPayment(paymentType, newCard, newAccount);
    const takenBy = getInitials(employee!.shortName);
    const processorType = getProcessorIntByName(processor!);
    const achAcctType = getAchAcctType(paymentType, accountType);
    const isAch = paymentType === PaymentType.Ach;
    const isCC = paymentType === PaymentType.CreditCard;
    const ccConvFee = isCC ? convenienceFee : 0;
    const achConvFee = isAch ? convenienceFee : 0;
    const waiveCCConvFee = isCC && waiveFee;
    const waiveAchConvFee = isAch && waiveFee;

    tempDmsPayload!.AchAcctType = achAcctType;
    tempDmsPayload!.AchConvFee = achConvFee;
    tempDmsPayload!.BillAddress = address;
    tempDmsPayload!.BillCity = city;
    tempDmsPayload!.BillEmail = paymentDetails!.buyerEmail;
    tempDmsPayload!.BillFirstName = firstName;
    tempDmsPayload!.BillLastName = lastName;
    tempDmsPayload!.BillState = state;
    tempDmsPayload!.BillZip = zip;
    tempDmsPayload!.CarPmt = totalPaid;
    tempDmsPayload!.CcConvFee = ccConvFee;
    tempDmsPayload!.Change = changeDue ?? 0;
    tempDmsPayload!.ColRecId = paymentDetails!.colRecId;
    tempDmsPayload!.ColType = paymentDetails!.colType;
    tempDmsPayload!.ConvFee = convenienceFee;
    tempDmsPayload!.EmailB = paymentDetails!.buyerEmail;
    tempDmsPayload!.EmailC = paymentDetails!.cobuyerEmail;
    tempDmsPayload!.IsAch = isAch;
    tempDmsPayload!.IsNewCard = isNewPayment;
    tempDmsPayload!.Mpd.AccountNumber = accountNumber;
    tempDmsPayload!.Mpd.RoutingNumber = routingNumber;
    tempDmsPayload!.Mpd.Token = mpdToken;
    tempDmsPayload!.PaidBy = paymentType;
    tempDmsPayload!.PaidIn = transactionType;
    tempDmsPayload!.PaidRef = referenceNumber;
    tempDmsPayload!.PayNote = paymentNote;
    tempDmsPayload!.PmtContext = 'SALE_CASHDOWN';
    tempDmsPayload!.PmtType = paymentType;
    tempDmsPayload!.ProcessorType = processorType;
    tempDmsPayload!.SaveCard = savePayment;
    tempDmsPayload!.SendB = includeBuyerEmail;
    tempDmsPayload!.SendC = includeCobuyerEmail;
    tempDmsPayload!.TakenBy = takenBy;
    tempDmsPayload!.TakenByPassword = password;
    tempDmsPayload!.TotalReceived = paymentToSend;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;
    tempDmsPayload!.UserShortName = employee?.shortName || '';
    tempDmsPayload!.WaiveAchConvFee = waiveAchConvFee;
    tempDmsPayload!.WaiveCCConvFee = waiveCCConvFee;
    tempDmsPayload!.WaiveConvFee = waiveFee;

    setDmsPaymentPayload(tempDmsPayload);

    if (
      (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) ||
      dmsPaymentPayload?.Mpd.Token
    ) {
      setConfirmPostModalOpen(true);
    } else if (processor === CardProcessorName.Repay && dmsPaymentPayload) {
      // Get iframe url
      paymentService.postPaymentSubmit(dmsPaymentPayload as any).then((res) => {
        setPaymentLogRecId(res.paymentLogRecId);
        setIframe(res.iFrameUrl);
        setRepayModal(true);
      });
    } else if (processor === CardProcessorName.OpenEdge && paymentType === PaymentType.CreditCard) {
      paymentService
        .postPaymentSubmit(dmsPaymentPayload as any)
        .then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setOpenEdgeModal(true);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setOpenEdgeAchModal(true);
    }
  };

  const handleShowModal = () => {
    const errors = [] as FormError[];

    if (downPayment < totalPaid) {
      errors.push({
        inputName: 'totalPaid',
        message: 'Total paid cannot be greater than down payment',
      });
    }
    if (paymentType === PaymentType.CreditCard && totalPaid < paymentDetails!.minCreditCardAmount) {
      errors.push({
        inputName: 'totalPaid',
        message: 'Payment must be greater than the min credit card payment',
      });
    }
    if (paymentType === PaymentType.Ach && totalPaid < paymentDetails!.minAchAmount) {
      errors.push({
        inputName: 'totalPaid',
        message: 'Payment must be greater than the min ach payment',
      });
    }

    amountTendered < 0.0 &&
      errors.push({ inputName: 'amountTendered', message: 'Amount Tendered cannot be negative' });
    !employee?.recId && errors.push({ inputName: 'employee', message: 'Employee is required' });
    !password && errors.push({ inputName: 'password', message: 'Password is required' });

    ccOrAchFormErrors &&
      errors.push({ inputName: 'billingInfoError', message: 'Billing information is required' });

    setFormErrors(errors);

    if (errors.length === 0) submit();
  };

  useEffect(() => {
    if (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) {
      setAmountTendered(totalPaid);
    } else if (!waiveFee) {
      setAmountTendered(totalPaid + convenienceFee);
    } else {
      setAmountTendered(totalPaid);
    }
  }, [convenienceFee, waiveFee, processor, totalPaid]);

  const handleAmountTenderedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (parseFloat(e.target.value) < totalPaid) {
      setAmountTendered(totalPaid);
    }
  };

  const changeDueStyle = { width: '100%' } as React.CSSProperties;
  changeDueStyle.width = '100%';
  if (paymentType === 'Credit Card') {
    changeDueStyle.color = 'transparent';
  } else if (changeDue !== null) {
    changeDueStyle.backgroundColor = '#f5f5f5';
  } else if (changeDue === null) {
    changeDueStyle.color = 'transparent';
  }

  useEffect(() => {
    if (!paymentDetails || !processor) return;
    setConvenienceFee(getConvenienceFee(paymentDetails!, processor, paymentType, waiveFee));
  }, [paymentType, processor, waiveFee]);

  useEffect(() => {
    if (appRecId) {
      paymentService.getSavedPaymentMethods(appRecId!).then((res) => {
        setPaymentMethods(res);
      });
    }
  }, [appRecId]);

  useEffect(() => {
    const amountDelta = amountTendered - totalPaid;
    if (
      amountDelta < 0 ||
      paymentType === PaymentType.CreditCard ||
      paymentType === PaymentType.Ach
    ) {
      setChangeDue(null);
    } else {
      setChangeDue(amountDelta);
    }
  }, [totalPaid, amountTendered]);

  useEffect(() => {
    initData();
  }, []);

  if (loading) {
    return (
      <div className={commonStyles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  const canWaiveFee = getCanWaiveFeeDeprec(processor, providerData);

  return (
    <div>
      {postPaymentLoading && (
        <div className={commonStyles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={commonStyles.header}>
        <img src={levelUpImage} onClick={navigateToList} />
        {!!(paymentDetails?.buyerFirstName && paymentDetails?.buyerLastName) &&
          `Payment For: ${paymentDetails.buyerLastName}, ${paymentDetails.buyerFirstName}`}
      </div>
      <div className={commonStyles.container}>
        <div className={styles.leftContainer}>
          {processors && processors.length > 1 && (
            <DmsDropDown
              name="processor"
              label="Processor"
              values={processors}
              value={processor}
              onChange={(e) => setProcessor(e.target.value as CardProcessorName)}
            />
          )}
          <DmsRadioButtonGroup
            name="transactionType"
            inputLabel="Accepted In"
            buttons={['In-Person', 'Phone', 'Mail', 'Night Drop']}
            radioButtonGroupName="Transaction"
            required
            onChange={(e) => setTransactionType(e.target.value)}
          />

          <DmsNumberInput
            name="downPayment"
            inputLabel="Down Payment"
            inputStyles={longInputStyle}
            fixedDecimalScale
            decimalScale={2}
            onBlur={(e) => parseFloat(e.target.value)}
            value={downPayment}
            readOnly={true}
          />

          <DmsNumberInput
            name="totalPaid"
            inputLabel="Total Paid"
            inputStyles={longInputStyle}
            fixedDecimalScale
            decimalScale={2}
            errors={formErrors.find((x) => x.inputName === 'totalPaid')?.message}
            onChange={(e) => setTotalPaid(parseFloat(e.target.value))}
            value={totalPaid}
            required
          />

          <DmsDropDown
            name="paymentType"
            label="Payment Type"
            values={paymentTypes}
            value={paymentType}
            inputContainerStyle={longInputStyle}
            onChange={(e) => setPaymentType(e.target.value)}
          />

          <DmsTextInput
            name="referenceNumber"
            inputLabel="Reference #"
            inputStyles={longInputStyle}
            onChange={(e) => setReferenceNumber(e.target.value)}
            value={referenceNumber}
          />

          <DmsNumberInput
            name="amountTendered"
            inputLabel="Amount Tendered"
            inputStyles={longInputStyle}
            fixedDecimalScale
            decimalScale={2}
            errors={formErrors.find((x) => x.inputName === 'amountTendered')?.message}
            onBlur={(e) => handleAmountTenderedBlur(e)}
            onChange={(e) => setAmountTendered(parseFloat(e.target.value))}
            value={amountTendered}
            required
            readOnly={paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach}
          />

          <DmsNumberInput
            name="changeDue"
            inputLabel="Change Due"
            inputStyles={changeDueStyle}
            fixedDecimalScale
            decimalScale={2}
            onBlur={(e) => parseFloat(e.target.value)}
            onChange={(e) => setChangeDue(parseFloat(e.target.value))}
            value={changeDue}
            readOnly
          />

          <DmsTextInput
            inputLabel="Password"
            inputStyles={longInputStyle}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
            required
            errorMessage={formErrors.find((error) => error.inputName === 'password')?.message}
          />

          <DmsDropDown
            name="employee"
            label="Employee"
            values={employeeOptions.map((employee) => {
              return employee.shortName;
            })}
            value={employee?.shortName}
            inputContainerStyle={longInputStyle}
            onChange={(e) =>
              setEmployee(
                employeeOptions.find((employee) => employee.shortName === e.target.value)!
              )
            }
          />

          <DmsTextInput
            name="paymentNote"
            inputLabel="Payment Note"
            inputStyles={{ width: '100%' }}
            onChange={(e) => setPaymentNote(e.target.value)}
            value={paymentNote}
          />
          <Button
            buttonLabel="Post Payment"
            onClick={() => {
              handleShowModal();
            }}
            style={{
              marginTop: '10px',
              padding: '4px 0',
              width: '100%',
              height: '36px',
              fontSize: '18px',
            }}
            className={styles.submitButton}
          />

          <EmailReceipt
            buyerEmail={paymentDetails?.buyerEmail || ''}
            coBuyerEmail={paymentDetails?.cobuyerEmail || ''}
            containerStyles={{ marginTop: '16px' }}
            noEmailState={paymentDetails?.buyerNoEmail}
            coBuyerNoEmailState={paymentDetails?.cobuyerNoEmail}
            colRecId={colRecId}
            appRecId={paymentDetails?.appRecId}
            buyerRecId={paymentDetails?.buyerRecId}
            cobuyerRecId={paymentDetails?.coBuyerRecId}
            emailCallback={(val) =>
              paymentDetails && setPaymentDetails({ ...paymentDetails, buyerEmail: val })
            }
            cobuyerEmailCallback={(val) =>
              paymentDetails && setPaymentDetails({ ...paymentDetails, cobuyerEmail: val })
            }
            optOutCallback={(val) =>
              paymentDetails && setPaymentDetails({ ...paymentDetails, buyerNoEmail: val })
            }
            cobuyerOptOutCallback={(val) =>
              paymentDetails && setPaymentDetails({ ...paymentDetails, cobuyerNoEmail: val })
            }
            buyerCheckboxFunction={(val) => setIncludeBuyerEmail(val)}
            cobuyerCheckboxFunction={(val) => setIncludeCobuyerEmail(val)}
            hasCobuyer={paymentDetails?.hasCobuyer ?? false}
          />
        </div>
        <div className={styles.rightContainer}>
          {paymentType === 'Credit Card' && (
            <SavedCreditCard
              convenienceFee={convenienceFee}
              waiveFee={waiveFee}
              onWaiveFee={setWaiveFee}
              saveCard={saveCard}
              onSaveCard={setSaveCard}
              newCard={newCard}
              onNewCard={setNewCard}
              firstName={firstName}
              onFirstName={setFirstName}
              lastName={lastName}
              onLastName={setLastName}
              zip={zip}
              onZip={setZip}
              address={address}
              onAddress={setAddress}
              city={city}
              onCity={setCity}
              state={state}
              onState={setState}
              mpdId={mpdId}
              onMpdId={setMpdId}
              onSetFormErrors={setCcOrAchFormErrors}
              useCardOnFile={true}
              savedPaymentMethods={paymentMethods!}
              paymentProvider={processor!}
              canWaiveFee={canWaiveFee}
            />
          )}

          {paymentType === 'ACH' && (
            <SavedACH
              convenienceFee={convenienceFee}
              waiveFee={waiveFee}
              onWaiveFee={setWaiveFee}
              saveAccount={saveAccount}
              onSaveAccount={setSaveAccount}
              newAccount={newAccount}
              onNewAccount={setNewAccount}
              firstName={firstName}
              onFirstName={setFirstName}
              lastName={lastName}
              onLastName={setLastName}
              onAccountType={setAccountType}
              mpdId={achMpdId}
              onMpdId={setAchMpdId}
              onSetFormErrors={setCcOrAchFormErrors}
              useAchOnFile={true}
              savedPaymentMethods={paymentMethods!}
              paymentProvider={processor!}
              canWaiveFee={canWaiveFee}
            />
          )}
          {openEdgeAchModal && (
            <DmsModal
              isOpen={openEdgeAchModal}
              closeFunction={() => setOpenEdgeAchModal(false)}
              title="OpenEdge Payment"
              creditCardPaymentProvider="OpenEdge"
            >
              <div className={commonStyles.achContainer}>
                <div className={commonStyles.achInputLabel}>Routing Number</div>
                <DmsNumberInput
                  name="routingNumber"
                  inputContainerStyle={{ width: '100%' }}
                  inputStyles={{ width: '100%' }}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                  maxLength={9}
                />
                <Spacer size={10} />
                <div className={commonStyles.achInputLabel}>Account Number</div>
                <DmsNumberInput
                  name="accountNumber"
                  inputContainerStyle={{ width: '100%' }}
                  inputStyles={{ width: '100%' }}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  maxLength={17}
                />
                <Spacer size={10} />
                <Button
                  buttonStyles={{ width: '300px', height: '30px' }}
                  buttonLabel="Submit"
                  onClick={() => {
                    dmsPaymentPayload!.Mpd.AccountNumber = accountNumber;
                    dmsPaymentPayload!.Mpd.RoutingNumber = routingNumber;
                    paymentService
                      .postPaymentSubmit(dmsPaymentPayload as any)
                      .then((res) => {
                        pollForReceiptOld(res.paymentLogRecId, () => {
                          setPaymentLogRecId(res.paymentLogRecId);
                          setOpenEdgeAchModal(false);
                          afterPayment();
                        });
                      })
                      .catch((e) => {
                        console.error(e);
                      });
                  }}
                />
              </div>
            </DmsModal>
          )}
          {openEdgeModal && (
            <DmsModal
              isOpen={openEdgeModal}
              closeFunction={() => {
                setOpenEdgeModal(false);
              }}
              title="OpenEdge Payment"
              creditCardPaymentProvider={CardProcessorName.OpenEdge}
            >
              {paymentLogRecId !== 0 && (
                <OpenEdge
                  paymentLogRecId={paymentLogRecId}
                  onComplete={afterPayment}
                  apiKey={paymentDetails?.openedgeApiKey}
                />
              )}
            </DmsModal>
          )}
          {repayModal && (
            <DmsModal
              isOpen={repayModal}
              closeFunction={() => {
                setRepayModal(false);
              }}
              title="Repay Payment"
              creditCardPaymentProvider={CardProcessorName.Repay}
            >
              <Repay
                iframe={iframe}
                paymentLogRecId={paymentLogRecId}
                onComplete={() => {
                  setRepayModal(false);
                  afterPayment();
                }}
              />
            </DmsModal>
          )}
          {confirmPostModalOpen && (
            <DmsModal
              title="Confirm Payment"
              message="Are you sure you want to post this payment?"
              onAcceptLabel="Yes"
              onDeclineLabel="No"
              isOpen={confirmPostModalOpen}
              closeFunction={() => {
                setConfirmPostModalOpen(false);
              }}
              onDeclineFunction={() => {
                setConfirmPostModalOpen(false);
              }}
              onAcceptFunction={() => {
                paymentService
                  .postPaymentSubmit(dmsPaymentPayload as any)
                  .then((res) => {
                    pollForReceiptOld(res.paymentLogRecId, afterPayment);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                setConfirmPostModalOpen(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
