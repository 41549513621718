import { FC } from 'react';
import { Outlet } from 'react-router-dom';
// mui
// import Grid from '@mui/material/Unstable_Grid2/Grid2';
// import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// components
import ViewHeader from './ViewHeader';
// style
import styles from './Accounts.module.scss';

// const kendoStyles: SxProps = {
//   fontFamily: 'Inter',
//   backgroundColor: '#fcfcfc',
//   p: '45px',
// };

const ViewLayout: FC = () => {
  return (
    <div className={styles.container}>
      {/* <Grid container direction="column" sx={{ ...kendoStyles }}> */}
      <ViewHeader />
      <Outlet />
      {/* </Grid> */}
    </div>
  );
};

export default ViewLayout;
