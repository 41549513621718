import { z } from "zod";
// validation
import { CollectJsResSchema, cliqResToMpd } from "./cliqResValidation";
import { PmtReq, PmtReqMisc } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/reqValidation";
import { ZMpd } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSchemas";

export type CliqPmtReq = PmtReq | ZMpd | { token: ZMpd["token"] };
export type CliqPmtReqMisc = PmtReqMisc | ZMpd | { token: ZMpd["token"] };

export const buildCliqReqBody = (
  pmtReqBody: z.SafeParseReturnType<z.input<typeof PmtReq>, z.output<typeof PmtReq>>,
  newCliqRes: CollectJsResSchema | null
): CliqPmtReq | null => {
  if (!pmtReqBody.data || !!pmtReqBody.error) {
    console.warn(
      "submitCliqPayment() -> buildCliqReqBody(): Request body validation failed:",
      pmtReqBody.error
    );
    console.warn({ newCliqRes });
    return null;
  }

  // This works for both saved and unsaved payments
  const cliqResValidated = CollectJsResSchema.safeParse(newCliqRes);
  const cliqMpd = cliqResToMpd.safeParse(cliqResValidated.data);
  if (
    !pmtReqBody.data.useSavedPaymentMethod &&
    (!cliqResValidated.data || !!cliqResValidated.error)
  ) {
    console.warn("submitCliqPayment() -> buildCliqReqBody(): Cliq body validation failed:");
    console.warn({ newCliqRes, cliqResValidated, cliqMpd });
    return null;
  }

  return {
    ...pmtReqBody.data,
    /** Null if `useSavedPaymentMethod === true` */
    token: cliqMpd?.data?.token ?? null,
    /** Null if `useSavedPaymentMethod === true` */
    mpd: cliqMpd?.data ?? null,
  } as CliqPmtReq;
};

export const buildCliqReqBodyMisc = (
  pmtReqBody: z.SafeParseReturnType<z.input<typeof PmtReqMisc>, z.output<typeof PmtReqMisc>>,
  newCliqRes: CollectJsResSchema | null
): CliqPmtReqMisc | null => {
  if (!pmtReqBody.data || !!pmtReqBody.error) {
    console.warn(
      "submitCliqPaymentMisc() -> buildCliqReqBodyMisc(): Request body validation failed:",
      pmtReqBody.error
    );
    console.warn({ newCliqRes });
    return null;
  }

  // This works for both saved and unsaved payments
  const cliqResValidated = CollectJsResSchema.safeParse(newCliqRes);
  const cliqMpd = cliqResToMpd.safeParse(cliqResValidated.data);

  return {
    ...pmtReqBody.data,
    /** Null if `useSavedPaymentMethod === true` */
    token: cliqMpd?.data?.token ?? null,
    /** Null if `useSavedPaymentMethod === true` */
    mpd: cliqMpd?.data ?? null,
  } as CliqPmtReqMisc;
};
