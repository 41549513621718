import { FC, PropsWithChildren } from 'react';
import { Document, DocumentProps, Page, PageProps } from '@react-pdf/renderer';
// utils
import { aspectRatioMap, dpi, pageSize } from '../default';

/** ### Wrapper that applies default settings for PDF table layouts
 * Used for generating a PDF to open and/or save to desktop
 */
const PdfDataTableView: FC<
  PropsWithChildren<DocumentProps> & { pageProps?: PropsWithChildren<PageProps> }
> = ({ children, style: docStyle, pageProps, ...props }) => {
  return (
    <Document
      style={{
        padding: '0px',
        margin: '0px',
        maxHeight: `${dpi * aspectRatioMap.A4!.hPx}px`,
        ...docStyle,
      }}
      {...props}
    >
      <Page
        dpi={dpi}
        size={pageSize}
        style={{
          padding: '32px',
          margin: '0px',
          maxHeight: `${dpi * aspectRatioMap.A4!.hPx}px`,
        }}
        wrap={false}
        {...pageProps}
      >
        {children}
      </Page>
    </Document>
  );
};

export default PdfDataTableView;
