import { formatPhoneNumber, sanitizePhoneInput } from '@/utils/helpers/general';
import { GridCustomCellProps } from '@progress/kendo-react-all';
import styles from './KendoCells.module.scss';

export const KendoPhoneNumberCell = (props: GridCustomCellProps) => {
  const sanitizedPhoneNumber = sanitizePhoneInput(props.children?.toString());
  const formattedPhoneNumber = formatPhoneNumber(sanitizedPhoneNumber);

  return (
    <td {...props.tdProps} className={styles.tableDataContainer}>
      {formattedPhoneNumber}
    </td>
  );
};
