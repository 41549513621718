import { z } from "zod";
// interfaces
import { PaymentMethod } from "@/enums/payment";
import { IFormFieldConfig } from "../cliqInterfaces";

export const CliqCcForm = z.object({
  /** Credit card number */
  ccnumber: z.boolean().default(false).describe("Credit Card Number"),
  /** Credit card expiration date */
  ccexp: z.boolean().default(false).describe("Expiration Date"),
  /** CVV */
  cvv: z.boolean().default(false).describe("CCV"),
});
export type CliqCcForm = z.infer<typeof CliqCcForm>;

export const CliqAchForm = z.object({
  /** Checking account name */
  checkname: z.boolean().default(false).describe("Checking Account Name"),
  /** Checking account number */
  checkaccount: z.boolean().default(false).describe("Checking Account Number"),
  /** Routing number */
  checkaba: z.boolean().default(false).describe("Routing Number"),
  /** Check transit */
  checktransit: z.boolean().default(false).describe("Check Transit"),
  /** Check institution */
  checkinstitution: z.boolean().default(false).describe("Check Institution"),
});
export type CliqAchForm = z.infer<typeof CliqAchForm>;

export class CliqFormConfig<T extends z.ZodRawShape> {
  constructor(public schema: z.ZodObject<T>) {}

  get fieldMap(): Map<keyof z.ZodObject<T>["shape"], IFormFieldConfig> {
    const keys = this.schema.keyof().options as (keyof z.ZodObject<T>["shape"])[];

    return new Map<keyof z.ZodObject<T>["shape"], IFormFieldConfig>(
      keys.map((key) => [
        key,
        {
          title: this.schema.shape[key]?.description ?? "",
          selector: `#${key as string}`,
          id: key as string,
        },
      ])
    );
  }
  get fieldLookup(): {
    [k in keyof z.ZodObject<T>["shape"]]: IFormFieldConfig;
  } {
    return Object.fromEntries(this.fieldMap) as {
      [k in keyof z.ZodObject<T>["shape"]]: IFormFieldConfig;
    };
  }
}

export const cliqFormConfig = {
  [PaymentMethod.enum["Credit Card"]]: new CliqFormConfig(CliqCcForm),
  [PaymentMethod.enum.ACH]: new CliqFormConfig(CliqAchForm),
};

export type CliqConfigKey = keyof typeof cliqFormConfig;
