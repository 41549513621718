// components
import PrinBalCell from '../tableCellComponents/PrinBalCell';
// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';
import { AccountTabRoutes } from '../AccountDetail/interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}/${AccountTabRoutes.PaymentOnly}`;

export const unpaidSalesColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  // @note `spStatus` and `saleType` are similar, but `spStatus` is used for this column in the alpha-implementation
  newColumnDef('spStatus', 'Type', 130),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('prinBal', 'Amount Due', 140, PrinBalCell),
  newColumnDef('code3', 'Lot', 80),
];
