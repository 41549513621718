import {
  Switch as KendoSwitch,
  SwitchProps as KendoSwitchProps,
  SwitchHandle,
} from '@progress/kendo-react-inputs';
import styles from './Switch.module.scss';
import { forwardRef } from 'react';

export interface SwitchProps extends KendoSwitchProps {
  label?: string;
  hidden?: boolean;
}

export const Switch = forwardRef<SwitchHandle, SwitchProps>((props, ref) => {
  const { label, ...restProps } = props;

  if (!label) {
    return <KendoSwitch {...restProps} />;
  }

  return (
    <div className={styles.container} hidden={props.hidden}>
      <div className={styles.label}>{label}</div>
      <KendoSwitch {...restProps} ref={ref} />
    </div>
  );
});
