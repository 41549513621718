import { CSSProperties, FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import MuiKendoButton from '@/mui/components/buttons/MuiKendoButton';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// style
import { LINK_BLUE } from '@/mui/theme/colors';

const linkStyle: CSSProperties = { color: LINK_BLUE, textDecoration: 'underline' };

/** ### Renders a table-cell with a link styled as a button */
const ButtonLinkCell: FC<
  GridCellProps & { navRouteBuilder: (dataItem: any) => string; children?: ReactNode } & {
    styleProp?: CSSProperties;
    buttonProps?: Parameters<typeof MuiKendoButton>[0];
  }
> = ({ dataItem, field, navRouteBuilder, children, styleProp, buttonProps }) => {
  const navigate = useNavigate();
  const route = navRouteBuilder(dataItem);
  const handleNavigate = () => navigate(route);

  const displayValue: string = dataItem[field!]!;
  // @note Temporary error handling - need to better accommodate for row-values that are intentionally 'nullish'
  if (displayValue === undefined) {
    throw Error(
      `<ButtonLinkCell /> - Invalid display-value field: "${displayValue}" - dataItem: ${JSON.stringify(
        dataItem
      )}`
    );
  }

  return (
    <td className="k-table-td" style={linkStyle}>
      <MuiKendoButton
        onClick={handleNavigate}
        variant="outlined"
        style={styleProp}
        {...buttonProps}
      >
        {children || displayValue}
      </MuiKendoButton>
    </td>
  );
};

export default ButtonLinkCell;
