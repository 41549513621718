import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateRangeDropdown from './DateRangeDropdown';
import DatePicker from '@/mui/components/form/MuiKendoDatePicker';
// state
import { useDashboardViewCtx } from '../../DashboardViewProvider';
// utils
import { DateRangeOptionKey, dateRangeOptions, formatDateTz } from '@/general/dates';

dayjs.extend(utc);

// Set initial date range
const initDateRangeKey: DateRangeOptionKey = 'This Month-to-date';
const { beginDate, endDate } = dateRangeOptions[initDateRangeKey];

/** ### Two fields: 'date from' and 'date to'
 * @note Date range should always be set
 */
const DateFilter: FC = () => {
  const dateRangeFilter = useDashboardViewCtx((s) => s.dateRangeFilter);
  const updateDateRangeFilter = useDashboardViewCtx((s) => s.updateDateRangeFilter);
  const initDateRangeFilter = useDashboardViewCtx((s) => s.initDateRangeFilter);
  const isDateFilterError = useDashboardViewCtx((s) => s.isDateFilterError);

  const fromDate = dateRangeFilter?.from;
  const toDate = dateRangeFilter?.to;

  // Event handlers
  const handleDateFieldChange = (formType: 'from' | 'to') => (e: dayjs.Dayjs | null) => {
    updateDateRangeFilter(e || dayjs(), formType);
  };

  useEffect(() => {
    initDateRangeFilter();
  }, []);

  const dateFromValue = formatDateTz(fromDate ? fromDate : beginDate, true);
  const dateToValue = formatDateTz(toDate ? toDate : endDate, true);

  return (
    <Grid container direction="row" alignItems="center" columnGap={1} wrap="nowrap">
      <DatePicker
        label="Date From"
        value={dateFromValue}
        onChange={handleDateFieldChange('from')}
        closeOnSelect
        error={isDateFilterError}
      />
      <DatePicker
        label="Date To"
        value={dateToValue}
        onChange={handleDateFieldChange('to')}
        closeOnSelect
        error={isDateFilterError}
      />
      <DateRangeDropdown />
    </Grid>
  );
};

export default DateFilter;
