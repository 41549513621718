import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { cloneDeep } from "lodash-es";
// kendo
import { Icons } from "@/components/icons";
// state
import { useAppDispatch } from "@/store/store";
import { useAuthSelector } from "@/features/auth/authSlice";
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { getSaleData } from "@/features/Sales/salesActionCreator";
// utils
import { salesService } from "@/services/salesService";
import { DateFormat, formatAddress, formatDate, getAddressFromGoogleMaps } from "@/utils/helpers/general";
// interfaces
import { Column } from "@/components/table/TableInterface";
import { CreditReport, CreditReportForm, CreditReportPayload, CreditReportSettings, UpdateAccountFromCreditReport } from "@/interfaces";
import { CreditReportBureau, CreditReportType, JointBuyerType } from "@/features/Sales/enums";
// style
import CreditScoreCell from "./CreditScoreCell";
import CustomDateCell from "./CustomDateCell";

/** @deprecated convert to context */
export const useCreditReports = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const { compId, userName } = useAuthSelector((state) => state);
  const { saleData } = useSalesSelector((state) => state);
  const [previousCreditReports, setPreviousCreditReports] = useState<CreditReport[]>([]);
  const [previousCreditReportsLoading, setPreviousCreditReportsLoading] = useState(false);
  const [updatingAccount, setUpdatingAccount] = useState(false);
  const [runningReport, setRunningReport] = useState(false);
  const [creditReportSettings, setCreditReportSettings] = useState<CreditReportSettings>();
  const [creditReportSettingsLoading, setCreditReportSettingsLoading] = useState(true);
  const [creditInquiryAuthPrinting, setCreditInquiryAuthPrinting] = useState(false);
  const [viewReportIframeUrl, setViewReportIframeUrl] = useState("");
  const [resettingData, setResettingData] = useState(false);

  const isDisabled = saleData.sale?.salestatus?.toLowerCase() !== "pending";

  // @todo move object/array/function out of component body
  const creditReportsColumns: Column[] = [
    { field: "timestampLocal", title: "Date of Report", cell: CustomDateCell, cells: { data: CustomDateCell } },
    { field: "custName", title: "Report For" },
    { field: "product", title: "Product" },
    { field: "btuScore", title: "B-TU", cells: { data: CreditScoreCell } },
    { field: "bXpnScore", title: "B-EXP", cells: { data: CreditScoreCell } },
    { field: "bEfxScore", title: "B-EFX", cells: { data: CreditScoreCell } },
    { field: "cTuScore", title: "C-TU", cells: { data: CreditScoreCell } },
    { field: "cXpnScore", title: "C-EXP", cells: { data: CreditScoreCell } },
    { field: "cEfxScore", title: "C-EFX", cells: { data: CreditScoreCell } },
    {
      field: "viewPrint",
      title: "View/Print",
      // @todo move component to file
      cell: (props) => {
        const { iFrameUrl, iFrameExpiresUtc } = props.dataItem;
        return (
          <td className="k-table-td">
            {!iFrameUrl ? (
              ""
            ) : new Date(iFrameExpiresUtc) < new Date() ? (
              <span>Expired</span>
            ) : (
              <Icons.Print onClick={() => setViewReportIframeUrl(iFrameUrl)} />
            )}
          </td>
        );
      },
    },
  ];

  const creditReportTypeOptions: CreditReportType[] = [];
  if (creditReportSettings?.creditEnabled) {
    creditReportTypeOptions.push(CreditReportType.Credit);
  }
  if (creditReportSettings?.prescreenEnabled) {
    creditReportTypeOptions.push(CreditReportType.Prescreen);
  }

  const creditBureauOptions: CreditReportBureau[] = [];
  if (creditReportSettings?.creditEfxEnabled) {
    creditBureauOptions.push(CreditReportBureau.Equifax);
  }
  if (creditReportSettings?.creditXpnEnabled) {
    creditBureauOptions.push(CreditReportBureau.Experian);
  }
  if (creditReportSettings?.creditTuEnabled) {
    creditBureauOptions.push(CreditReportBureau.Transunion);
  }

  const accountForm = useForm<UpdateAccountFromCreditReport>();
  const creditReportForm = useForm<CreditReportForm>();

  const buyer = saleData.buyer;
  const cobuyer = saleData.coBuyer;
  const fullBuyerAddress = formatAddress(buyer?.b_ADDR, buyer?.b_CITY, buyer?.b_STATE, buyer?.b_ZIP);
  const fullCobuyerAddress = formatAddress(cobuyer?.b_ADDR, cobuyer?.b_CITY, cobuyer?.b_STATE, cobuyer?.b_ZIP);

  const handleBuyerPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    const setValue = accountForm.setValue;

    setValue("buyerAddress", fullAddress.address, { shouldDirty: true });
    setValue("buyerCity", fullAddress.city, { shouldDirty: true });
    setValue("buyerState", fullAddress.state, { shouldDirty: true });
    setValue("buyerZip", fullAddress.zip, { shouldDirty: true });
  };

  const handleCobuyerPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    const setValue = accountForm.setValue;

    setValue("cobuyerAddress", fullAddress.address, { shouldDirty: true });
    setValue("cobuyerCity", fullAddress.city, { shouldDirty: true });
    setValue("cobuyerState", fullAddress.state, { shouldDirty: true });
    setValue("cobuyerZip", fullAddress.zip, { shouldDirty: true });
  };

  const resetAccountForm = async (reloadData = false) => {
    try {
      if (reloadData) {
        setResettingData(true);
        await dispatch(getSaleData(appRecId, false));
      }
      accountForm.reset({
        buyerFirstName: buyer?.b_FIRST ?? "",
        buyerMiddleName: buyer?.b_MID ?? "",
        buyerLastName: buyer?.b_LAST ?? "",
        buyerSsn: buyer?.b_SSN ?? "",
        buyerAddress: buyer?.b_ADDR ?? "",
        buyerCity: buyer?.b_CITY ?? "",
        buyerState: buyer?.b_STATE ?? "",
        buyerZip: buyer?.b_ZIP ?? "",
        buyerSignatureVerifiedBy: buyer?.b_VERSIGNBY ?? "",
        buyerCreditAuthSigned: formatDate(buyer?.b_SIGNED, { pattern: DateFormat.DateInput }) ?? "",
        buyerSecurityPin: buyer?.securityPIN ?? "",

        cobuyerFirstName: cobuyer?.b_FIRST ?? "",
        cobuyerMiddleName: cobuyer?.b_MID ?? "",
        cobuyerLastName: cobuyer?.b_LAST ?? "",
        cobuyerSsn: cobuyer?.b_SSN ?? "",
        cobuyerAddress: cobuyer?.b_ADDR ?? "",
        cobuyerCity: cobuyer?.b_CITY ?? "",
        cobuyerState: cobuyer?.b_STATE ?? "",
        cobuyerZip: cobuyer?.b_ZIP ?? "",
        cobuyerSignatureVerifiedBy: cobuyer?.b_VERSIGNBY ?? "",
        cobuyerCreditAuthSigned: formatDate(cobuyer?.b_SIGNED, { pattern: DateFormat.DateInput }) ?? "",
        cobuyerSecurityPin: cobuyer?.securityPIN ?? "",
      });
    } finally {
      setResettingData(false);
    }
  };

  const printCreditInquiryAuth = async () => {
    setCreditInquiryAuthPrinting(true);
    await salesService.printCreditInquiryAuth(buyer!.appRecID!);
    setCreditInquiryAuthPrinting(false);
  };

  const onAccountFormSubmit = async (data: UpdateAccountFromCreditReport) => {
    try {
      setUpdatingAccount(true);
      const payload = cloneDeep(data);
      if (creditReportForm.watch("reportType") === CreditReportType.Prescreen) {
        // Possible these values were set before they switched the report type.
        // Since these values are not visible at time of pre-screen report submit, they may not be valid anymore,
        // and they aren't needed for pre-screen reports, so just unset them.
        payload.buyerSignatureVerifiedBy = undefined;
        payload.buyerCreditAuthSigned = undefined;
        payload.cobuyerSignatureVerifiedBy = undefined;
        payload.cobuyerCreditAuthSigned = undefined;
      } else {
        // If the date fields are set, they need to be converted from the date input type to a full datetime string
        if (payload.buyerCreditAuthSigned) {
          payload.buyerCreditAuthSigned = new Date(payload.buyerCreditAuthSigned).toISOString();
        } else {
          payload.buyerCreditAuthSigned = undefined;
        }
        if (payload.cobuyerCreditAuthSigned) {
          payload.cobuyerCreditAuthSigned = new Date(payload.cobuyerCreditAuthSigned).toISOString();
        } else {
          payload.cobuyerCreditAuthSigned = undefined;
        }
      }
      payload.buyerRecId = buyer?.rec_Id;
      payload.cobuyerRecId = cobuyer?.rec_Id;
      await salesService.updateAccountFromCreditReport(payload);
      await dispatch(getSaleData(appRecId, false));
      accountForm.reset(data); // Reset the form so it is no longer dirty
      await dispatch(getSaleData(appRecId));
      toast.success("Application updated");
    } catch {
      toast.error("There was an error updating the application");
    } finally {
      setUpdatingAccount(false);
    }
  };

  const runReport = async (data: CreditReportForm) => {
    setRunningReport(true);
    try {
      // Validate the account data before attempting to run the report
      const isAccountFormValid = await accountForm.trigger();
      if (!isAccountFormValid) return;

      const isCreditReport = data.reportType === CreditReportType.Credit;

      if (isCreditReport && !accountForm.getValues().buyerCreditAuthSigned) {
        toast.error("Credit authorization must be obtained before running a credit report");
        return;
      }

      // If it's a prescreen report the bureaus are irrelevant,
      // but at least one has to be true due to the way the API was written
      const payload: CreditReportPayload = {
        appRecId: buyer!.appRecID!,
        product: isCreditReport ? "CREDIT" : "PRESCREEN",
        reportFor: data.reportFor === JointBuyerType.Buyer ? "BUYER" : data.reportFor === JointBuyerType.CoBuyer ? "COBUYER" : "JOINT",
        transUnionIsActive: data.reportType === CreditReportType.Prescreen ? true : data.bureaus.includes(CreditReportBureau.Transunion),
        experianIsActive: data.bureaus.includes(CreditReportBureau.Experian),
        equifaxIsActive: data.bureaus.includes(CreditReportBureau.Equifax),
      };
      const iframeUrl = await salesService.runCreditReport(payload);
      setViewReportIframeUrl(iframeUrl);
      getPreviousCreditReports();
    } finally {
      setRunningReport(false);
    }
  };

  const getPreviousCreditReports = () => {
    setPreviousCreditReportsLoading(true);
    // @todo use async/await
    salesService
      .getPreviousCreditReports(buyer!.appRecID!)
      .then((res) => {
        setPreviousCreditReports(res);
      })
      .finally(() => {
        setPreviousCreditReportsLoading(false);
      });
  };

  useEffect(() => {
    if (!creditReportSettings) return;
    creditReportForm.reset({
      reportType: creditReportSettings.creditEnabled
        ? CreditReportType.Credit
        : creditReportSettings.prescreenEnabled
        ? CreditReportType.Prescreen
        : undefined,
      reportFor: JointBuyerType.Buyer,
      bureaus: [],
    });
  }, [creditReportSettings]);

  useEffect(() => {
    setCreditReportSettingsLoading(true);
    // @todo use async/await
    salesService
      .getCreditReportSettings(compId!)
      .then((res) => {
        setCreditReportSettings(res);
      })
      .finally(() => {
        setCreditReportSettingsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (accountForm.watch("buyerCreditAuthSigned")) {
      accountForm.setValue("buyerSignatureVerifiedBy", userName);
    } else {
      accountForm.setValue("buyerSignatureVerifiedBy", "");
    }
  }, [accountForm.watch("buyerCreditAuthSigned")]);

  useEffect(() => {
    if (accountForm.watch("cobuyerCreditAuthSigned")) {
      accountForm.setValue("cobuyerSignatureVerifiedBy", userName);
    } else {
      accountForm.setValue("cobuyerSignatureVerifiedBy", "");
    }
  }, [accountForm.watch("cobuyerCreditAuthSigned")]);

  useEffect(() => {
    resetAccountForm();
  }, [creditReportForm.watch("reportFor")]);

  useEffect(() => {
    // Can't do joint prescreen reports
    if (
      creditReportForm.watch("reportType") === CreditReportType.Prescreen &&
      creditReportForm.watch("reportFor") === JointBuyerType.Joint
    ) {
      creditReportForm.setValue("reportFor", JointBuyerType.Buyer);
    }
  }, [creditReportForm.watch("reportType")]);

  // @todo duplicate useEffect
  useEffect(() => {
    getPreviousCreditReports();
  }, []);

  return {
    accountForm,
    creditBureauOptions,
    creditInquiryAuthPrinting,
    creditReportForm,
    creditReportTypeOptions,
    creditReportsColumns,
    creditReportSettings,
    creditReportSettingsLoading,
    previousCreditReportsLoading,
    onAccountFormSubmit,
    previousCreditReports,
    printCreditInquiryAuth,
    resetAccountForm,
    runningReport,
    runReport,
    setViewReportIframeUrl,
    updatingAccount,
    viewReportIframeUrl,
    resettingData,
    fullBuyerAddress,
    fullCobuyerAddress,
    handleBuyerPlaceChange,
    handleCobuyerPlaceChange,
    isDisabled,
  };
};
