import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@/mui/components/buttons/MuiKendoButton';
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// state
import { useTableCtx } from './tableState';
import { useAccountsViewCtx } from './accountsViewState';
// utils
import { SubviewInfo, viewInfo } from './viewInfo';
import { routeBase } from '.';

const fontStyle = { fontSize: '14px', fontWeight: 400 };
const menuStyle = { ...fontStyle };
const optionStyle: SxProps = { ...fontStyle, p: '7px', lineHeight: '20px', minWidth: 180 };

/** ### Dropdown menu to change subviews within the `Accounts` view
 * @note Change default/init sort based on the category of subview
 * */
const ViewSelector: FC = () => {
  const navigate = useNavigate();
  const page = useTableCtx((s) => s.page);
  const setPage = useTableCtx((s) => s.setPage);
  const setTextSearchFilter = useTableCtx((s) => s.setTextSearchFilter);
  const setFilter = useTableCtx((s) => s.setFilter);
  const setSort = useTableCtx((s) => s.setSort);
  const route = useAccountsViewCtx((s) => s.subviewInfo.route);

  // Dropdown state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Event handlers
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelect = (o: SubviewInfo) => () => {
    // Change route
    navigate(`/${routeBase}/${o.route}`);

    // Reset table if route is a list-view route
    if (o.route && o.route !== route) {
      // Reset table state
      setPage({ ...page, skip: 0 });
      setTextSearchFilter('');
      setFilter([]);
      setSort([]);
    }

    // Close dropdown
    handleClose();
  };

  // Build options
  const dropdownOptions = viewInfo.map((o) => {
    return (
      <MenuItem onClick={handleSelect(o)} sx={optionStyle} key={o.text}>
        {o.text}
      </MenuItem>
    );
  });

  return (
    <Grid container direction="column" width={200}>
      <Button onClick={handleButtonClick} fullWidth>
        Select List
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button', sx: menuStyle }}
        sx={{ left: 19 }}
        elevation={5}
      >
        {dropdownOptions}
      </Menu>
    </Grid>
  );
};

export default ViewSelector;
