import { ChangeEvent, FC } from 'react';
// mui
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// kendo
import { GridColumnMenuProps } from '@progress/kendo-react-all';
// state
import { useTableCtx } from './TableProvider';
// interfaces
import { SaleTypeKey } from '../../interfaces';
import { SaleType } from '@/enums';

/** ### Menu options */
const saleTypeOptions: { label?: string; value: SaleTypeKey }[] = [
  { value: 'All' },
  { value: SaleType.Finance },
  { value: SaleType.Lease },
  { value: SaleType.Cash },
];

/** ###  */
const SaleTypeColumnMenu: FC<GridColumnMenuProps> = () => {
  const activeSaleType = useTableCtx((s) => s.activeSaleType);
  const setActiveSaleType = useTableCtx((s) => s.setActiveSaleType);

  // Build menu options
  const radioButtonElems = saleTypeOptions.map(({ label, value }, idx) => (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label || value}
      key={`radio${idx}`}
    />
  ));

  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveSaleType(e.target.value as SaleTypeKey);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="sale-type-radio-buttons"
        defaultValue="All"
        name="radio-buttons-group"
        value={activeSaleType}
        onChange={handleChangeRadio}
      >
        {radioButtonElems}
      </RadioGroup>
    </FormControl>
  );
};
export default SaleTypeColumnMenu;
