import { Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const kendoMuiTypography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  fontFamily: 'inter',
  fontSize: 14,
  fontWeightRegular: 400,
  // custom props
  tableCell: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-0.28px',
    lineHeight: '20px',
    color: 'rgb(115, 115, 115)',
  },
};
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableCell: true;
  }

  interface TypographyClasses {
    tableCell: string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyPropsVariantOverrides {
    tableCell: true;
  }

  interface TypographyClasses {
    tableCell: string;
  }

  interface TypographyVariantsOptions {
    tableCell?: React.CSSProperties;
  }
}
