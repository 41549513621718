import { FC, PropsWithChildren } from "react";
// style
import styles from "./MultipleInputRow.module.scss";

export const MultipleInputRowRadioButton: FC<PropsWithChildren & { label: string }> = ({
  children,
  label,
}) => (
  <div className={styles.inputRow}>
    <div className={styles.inputLabel}>{label}</div>
    <div className={styles.radioButton}>{children}</div>
  </div>
);
