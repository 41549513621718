import styles from './FunctionLink.module.scss';

interface FunctionLinkProps {
  onClick: () => void;
  label: string;
  style?: React.CSSProperties;
}

export const FunctionLink = (props: FunctionLinkProps) => {
  return (
    <span onClick={props.onClick} className={styles.link} style={props.style ?? {}}>
      {props.label}
    </span>
  );
};
