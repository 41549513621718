import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import SubmitButton from "./SubmitButton";
import TitleGroup from "./TitleGroup";

/** ### Header layout for "AutoLend IQ" tab-view */
const Header: FC = () => (
  <Grid container direction="row" justifyContent="space-between" alignItems="center">
    <TitleGroup />

    <SubmitButton />
  </Grid>
);

export default Header;
