import { FC, useCallback, useEffect } from 'react';
import { debounce } from 'lodash-es';
// kendo
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  FilterOperator,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { Loader } from '@progress/kendo-react-all';
// components
import { PageState } from '@/components/table/TableInterface';
// utils
import { columnMappings } from './tableColumns';
import { TableFilters, useInventoryViewCtx } from '../state';
import { InventoryListRow } from '@/interfaces';
import { inventoryService } from '@/services/inventoryService';
import InventoryTableLayout from './InventoryTableLayout';
import {
  FilterValue,
  FilterValueFmt,
  GetInventoryPayload,
  IModalFilterParams,
  ServerSideFiltering,
  // SortValue,
} from './interfaces';
import { Sort } from '@/interfaces/requests';

const filterFieldNames: (keyof InventoryListRow)[] = [
  'stockNum',
  'vin',
  'year',
  'make',
  'model',
  'color',
  'code3',
  'shortName',
  'invType',
];

// needs to be updated for each table
const sortTranslationDict = {
  stockNum: 'StockNum',
  buyers: 'Buyers',
  vehicle: 'Vehicle',
  saleType: 'SaleType',
  dateSold: 'DateSold',
  amtDue: 'AmtDue',
  daysLate: 'DaysLate',
  sc: 'SC',
  shortName: 'ShortName',
};

const buildFilters: FilterDescriptor[] = filterFieldNames.map((n) => ({
  field: n,
  operator: FilterOperator.Contains,
  value: '',
  ignoreCase: true,
}));

// Convert filter string to type compatible with <Grid />
const filter: CompositeFilterDescriptor = { logic: 'or', filters: buildFilters };

/** ### Table component for "Inventory" view */
const InventoryTable: FC = () => {
  const searchFilter = useInventoryViewCtx((s) => s.searchFilter);
  const isLoading = useInventoryViewCtx((s) => s.isLoading);
  const setIsLoading = useInventoryViewCtx((s) => s.setIsLoading);
  const setListLength = useInventoryViewCtx((s) => s.setListLength);
  const tableDataMap = useInventoryViewCtx((s) => s.tableDataMap);
  const setTableDataMap = useInventoryViewCtx((s) => s.setTableDataMap);
  const tableFilters = useInventoryViewCtx((s) => s.tableFilters);
  const page = useInventoryViewCtx((s) => s.page);
  const sort = useInventoryViewCtx((s) => s.sort);
  const filterText = useInventoryViewCtx((s) => s.filterText);
  const modalFilterParams = useInventoryViewCtx((s) => s.modalFilterParams);
  const subview = useInventoryViewCtx((s) => s.subview);

  // Fetch inventory data on load, one route for all lists with different payload values
  const fetchData = async (
    sort: SortDescriptor[] | null,
    page: PageState,
    filter: TableFilters,
    search: string,
    modalFilterParams: IModalFilterParams | null
  ) => {
    setIsLoading(true);
    const pg = page.skip! / page.take! + 1;
    const filterArray: FilterValue[] = [];
    const filterArrayFmt: FilterValueFmt[] = [];
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        const value = filter[key as keyof TableFilters];
        if (value) {
          const newFilter = new FilterValue(key, value);
          if (value.length > 0) {
            filterArray.push(newFilter);
            filterArrayFmt.push({ ...newFilter, value: newFilter.valueFmt });
          }
        }
      }
    }
    const apiTableParams = ServerSideFiltering.fromTableFilter(
      pg,
      page.take,
      sort === null
        ? null
        : sort.map((sort) => ({
            field: sortTranslationDict[sort.field as keyof typeof sortTranslationDict],
            direction: sort.dir!.toUpperCase() as Sort['direction'],
          })),
      filterArray
    );

    if (subview === 'sold' && !modalFilterParams?.beginDate && !modalFilterParams?.endDate) {
      throw new Error('Begin Date and End Date not set!');
    }
    const beginDate = subview === 'sold' ? modalFilterParams!.beginDate : null;
    const endDate = subview === 'sold' ? modalFilterParams!.endDate : null;

    const payload: GetInventoryPayload = {
      page: apiTableParams.page,
      perPage: apiTableParams.perPage,
      sorts: apiTableParams.sorts === null ? null : apiTableParams.sorts,
      filters: filterArrayFmt.length > 0 ? filterArrayFmt : null,
      listType: subview,
      search: search,
      soldDateBegin: beginDate,
      soldDateEnd: endDate,
    };

    try {
      const res = await inventoryService.getInventoryList(payload);
      // @note need to add strong typing to subveiw title
      setTableDataMap({ ...tableDataMap, [subview]: res.data });
      setListLength(res.total);
    } catch (err) {
      // @todo properly handle error
      console.error(err);
    }

    setIsLoading(false);
  };

  const handleFilterChange = useCallback(
    debounce(
      (
        sort: SortDescriptor[] | null,
        page: PageState,
        tableFilters: TableFilters,
        newSearch: string,
        modalFilterParams: IModalFilterParams | null
      ) => fetchData(sort, page, tableFilters, newSearch, modalFilterParams),
      400
    ),
    [subview]
  );

  // Effects
  useEffect(() => {
    fetchData(sort, page, tableFilters, filterText, modalFilterParams);
  }, [sort, page, tableFilters, modalFilterParams]);

  useEffect(() => {
    handleFilterChange(sort, page, tableFilters, filterText, modalFilterParams);
  }, [filterText]);

  return (
    <InventoryTableLayout
      data={tableDataMap[subview]}
      columns={columnMappings[subview]}
      pageable={true}
      sortable={true}
      filter={
        {
          ...filter,
          filters: filter.filters.map((f) => ({
            ...f,
            value: searchFilter,
          })),
        } as CompositeFilterDescriptor
      }
      initPageSize={25}
      noResultsComponent={isLoading ? <Loader size="large" /> : <div>No records found</div>}
    />
  );
};

export default InventoryTable;
