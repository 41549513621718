import { CSSProperties, DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
// kendo
import { Icons } from '@/components/icons';
import { Button } from '@/components/old/button/Button';
import { DmsCheckbox } from '@/components/old/dmsCheckbox/DmsCheckbox';
// style
import styles from './DmsTextInput.module.scss';

export const DmsTextInput: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    inputLabel?: string;
    inputStyles?: CSSProperties;
    editButton?: boolean;
    editButtonFunction?: () => void;
    inputContainerStyle?: CSSProperties;
    errorMessage?: string;
    checkedInput?: boolean;
    setChecked?: (checked: boolean) => void;
  }
> = ({
  inputLabel,
  inputStyles = {},
  editButton,
  editButtonFunction,
  inputContainerStyle = {},
  errorMessage,
  checkedInput,
  setChecked,
  ...restInputProps
}) => {
  const localInputStyles = errorMessage ? { ...inputStyles, borderColor: '#bcd5757' } : inputStyles;

  return (
    <div className={styles.container}>
      {inputLabel && (
        <div className={styles.labelContainer} style={inputContainerStyle}>
          {checkedInput !== undefined && (
            <div className={styles.checkboxContainer}>
              <DmsCheckbox
                type="checkbox"
                checked={checkedInput}
                onChange={() => setChecked!(!checkedInput)}
                containerStyle={{ height: '100%' }}
              />
            </div>
          )}

          <div className={styles.label}>
            <div>
              {inputLabel}
              {restInputProps.required && (
                <Icons.Star color="#bc5757" width="10px" height="10px" className={styles.icon} />
              )}
            </div>
            {inputLabel && errorMessage ? (
              <div className={styles.errorMessage}>{errorMessage}</div>
            ) : null}
          </div>
        </div>
      )}
      <div className={styles.inputContainer}>
        <input className={styles.inputBox} {...restInputProps} style={localInputStyles} />
        {editButton && (
          <Button
            buttonLabel="Edit"
            buttonStyles={{
              width: '50px',
              height: '27.5px',
              padding: '4.5px 9.75px',
            }}
            onClick={editButtonFunction}
          />
        )}
      </div>
    </div>
  );
};
