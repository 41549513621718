import { FC, useState } from "react";
// mui
import { DataGrid, GridPaginationModel, gridClasses } from "@mui/x-data-grid";
// components
import DataDisplay from "../DataDisplay";
// state
import { useDashboardViewCtx } from "../../DashboardViewProvider";
import { useAuthSelector } from "@/features/auth/authSlice";
// utils
import { CustomGridParams, useServerGrid } from "@/hooks/useServerGrid/useServerGrid";
import { dashboardService } from "@/services/dashboardService";
import { useDebounceEffect } from "@/hooks/useDebounce/useDebounce";
import { convertSortToTable, handleChangeSort } from "@/mui/components/dataTable/utils";
import { tableColumns } from "./tableColumns";
import { rowHeightPx } from "../default";
// interfaces
import { ServerGridOptions } from "@/interfaces/ServerGrids";
import { TerminationRowData } from "../../interfaces";

/** ###  */
const TerminationsTable: FC = () => {
  const startDate = useDashboardViewCtx((s) => s.dateRangeFilter?.from);
  const endDate = useDashboardViewCtx((s) => s.dateRangeFilter?.to);
  const appliedStartDate = startDate.isValid() ? startDate.toISOString() : null;
  const appliedEndDate = endDate.isValid() ? endDate.toISOString() : null;
  const isDateFilterError = useDashboardViewCtx((s) => s.isDateFilterError);
  const compId = useDashboardViewCtx((s) => s.selectedCompany?.recId);
  const orgId = useAuthSelector((s) => s.orgId);

  const [pageModel, setPageModel] = useState<GridPaginationModel>({ page: 0, pageSize: 25 });

  const fetchTerminations = async (options: ServerGridOptions, customParams?: CustomGridParams) => {
    if (
      !customParams?.orgId ||
      !customParams?.startDate ||
      !customParams?.endDate ||
      isDateFilterError
    )
      return;
    return await dashboardService.getTerminations(options, customParams);
  };

  const tableModel = useServerGrid<TerminationRowData>({
    fetchFunction: fetchTerminations,
    initialSortOptions: [{ field: "chargeOffDate", dir: "desc" }],
  });

  const isLoading = tableModel.loading;
  const data = tableModel.data;
  const rowCount = tableModel.total;
  const sort = tableModel.sort;
  const onSortChange = tableModel.onSortChange;
  const setCustomParams = tableModel.setCustomParams;

  useDebounceEffect(
    () => setCustomParams({ startDate: appliedStartDate, endDate: appliedEndDate, compId, orgId }),
    [startDate, endDate, compId],
    300
  );

  const titleTotal = isLoading ? "-" : rowCount;
  const title = `Terminations (${titleTotal})`;
  const secondary = `Total for ${
    compId === null ? "All Locations" : "Current Location"
  } as of Date`;

  return (
    <DataDisplay title={title} secondary={secondary} maxWidth="100%" wrap="nowrap">
      <DataGrid
        loading={isLoading}
        rows={data}
        columns={tableColumns}
        getRowId={(r) => r.recId}
        paginationModel={pageModel}
        sortModel={convertSortToTable(sort)}
        sortingMode="server"
        onPaginationModelChange={setPageModel}
        onSortModelChange={handleChangeSort(onSortChange)}
        rowHeight={rowHeightPx}
        sx={{
          maxHeight: rowHeightPx * 7,
          minHeight: rowHeightPx * 7,
          maxWidth: "100%",
          [`& .${gridClasses.columnHeader}, .MuiDataGrid-columnHeaders`]: {
            backgroundColor: "#f9f9f9",
          },
        }}
      />
    </DataDisplay>
  );
};

export default TerminationsTable;
