import { FC, useState } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
// kendo
import { Button } from "@/components/button/Button";
import { TextInput } from "@/components/inputs/text/TextInput";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// components
import TooltipLayout from "./TooltipLayout";
// state
import { useSidenoteCtx } from "./SidenoteProvider";
// validation
import { SidenoteForm } from "./forms";

type AddLabelKey = `addLab${number}`;
const CustomAddOnField: FC<{ addOnNumber: number }> = ({ addOnNumber }) => {
  const addOnKey = `addOn${addOnNumber}` as keyof SidenoteForm;
  const addLabelKey = `addLab${addOnNumber}` as AddLabelKey;

  const isNew = useSidenoteCtx((s) => s.isNew);
  const formState = useSidenoteCtx((s) => s.formState);
  const fieldProps = formState.getFieldProps(addOnKey);

  const addOnLabel = useSidenoteCtx((s) => s.addOnLabels[addLabelKey]);
  if (!addOnLabel) return <></>;

  return (
    <CurrencyInput
      {...fieldProps}
      value={fieldProps.value as number}
      label={addOnLabel}
      style={{ marginTop: "1px", marginBottom: "1px", height: "38px" }}
      disabled={!isNew}
    />
  );
};

const textStyle = { marginTop: "1px", marginBottom: "1px", height: "38px" };

/** ###  */
const LeftContainer: FC = () => {
  const formState = useSidenoteCtx((s) => s.formState);
  const vendorsList = useSidenoteCtx((s) => s.vendorsList);
  const isNew = useSidenoteCtx((s) => s.isNew);
  const sidenoteTypeOptions = useSidenoteCtx((s) => s.sidenoteTypeOptions);
  const setSidenoteTypeOptions = useSidenoteCtx((s) => s.setSidenoteTypeOptions);

  const [isAddingNewType, setIsAddingNewType] = useState(false);
  const [newSidenoteType, setNewSidenoteType] = useState("");

  const handleAddNewType = () => {
    const updatedSidenoteOptions = [...sidenoteTypeOptions, newSidenoteType];
    setSidenoteTypeOptions(updatedSidenoteOptions);
    formState.setField("snType", newSidenoteType);

    // Clear the new sidenote type field and close the "add new" option
    setNewSidenoteType("");
    setIsAddingNewType(false);
  };

  const addOnFieldElems = [...Array(10).keys()].map((i) => (
    <CustomAddOnField addOnNumber={i + 1} key={i} />
  ));

  return (
    <SectionColumn p={0}>
      <TextInput
        {...formState.getFieldProps("snNumber")}
        label="SN Number"
        style={textStyle}
        required
        readOnly={!isNew}
      />
      <MuiKendoDateField
        {...formState.getFieldProps("snDate")}
        label="Date"
        required
        disabled={!isNew}
      />
      <DropdownInput
        label="Type"
        {...formState.getFieldProps("snType")}
        onChange={(e) => {
          if (e.target.value === "Add New +") setIsAddingNewType(true);
          else formState.getFieldProps("snType").onChange(e);
        }}
        errors={!!formState.getFieldProps("snType").errors}
        required
        data={["Add New +", ...sidenoteTypeOptions]}
        disabled={!isNew}
      />
      {isAddingNewType && (
        <div>
          <TextInput
            label="New Sidenote Type"
            value={newSidenoteType}
            onChange={(e) => setNewSidenoteType(e.target.value)}
            required
          />
          <Grid container direction="row" flex={1} justifyContent="flex-end" gap={1}>
            <Button
              label="Add New"
              type="button"
              onClick={handleAddNewType}
              buttonStyle={{ width: "90px", marginTop: "10px" }}
            />
            <Button
              label="Cancel"
              type="button"
              onClick={() => {
                setNewSidenoteType("");
                setIsAddingNewType(false);
              }}
              buttonStyle={{ width: "90px", marginTop: "10px" }}
            />
          </Grid>
        </div>
      )}
      <TooltipLayout>
        <Grid>
          <DropdownInput
            {...formState.getFieldProps("vendor")}
            onChange={(e) => {
              const appliedValue =
                typeof e.value === "number" ? e.value : e.value?.recId ? e.value?.recId : null;
              formState.setField("vendor", appliedValue);
            }}
            value={vendorsList.find((c) => c.recId === formState.form.vendor) ?? null}
            label="Vendor"
            data={vendorsList}
            textField="vendorName"
            dataItemKey="recId"
            disabled={!isNew}
          />
        </Grid>
      </TooltipLayout>

      {addOnFieldElems}
    </SectionColumn>
  );
};

export default LeftContainer;
