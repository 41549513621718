import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerVehicleExpense } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardExpenses: FC<{ vehicleExpenses?: LedgerVehicleExpense[] }> = ({
  vehicleExpenses,
}) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Vehicle Expenses</div>
    <table style={ledgerStyles.ledgerTable}>
      <thead>
        <tr style={{ ...ledgerStyles.verticalTableRow, ...ledgerStyles.labelRow }}>
          <td>Expense Date</td>
          <td>Paid To</td>
          <td>Expense For</td>
          <td>Amount</td>
        </tr>
      </thead>
      <tbody>
        {vehicleExpenses
          ? vehicleExpenses.map((ve, idx) => {
              return (
                <tr key={idx}>
                  <td>{formatDate(ve.date)}</td>
                  <td>{ve.paidTo}</td>
                  <td>{ve.paidFor}</td>
                  <td style={ledgerStyles.pullRight}>
                    {formatCurrency(ve.amount || 0)}
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  </>
);

export default LedgerCardExpenses;
