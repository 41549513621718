import { FC } from "react";
import { Blocker, useBlocker } from "react-router-dom";
import { Controller } from "react-hook-form";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import LgLoader from "@/components/loader/LgLoader";
import { SectionHeader } from "@components/sectionHeader/SectionHeader";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { MaskedTextInput } from "@/components/inputs/maskedTextInput/MaskedTextInput";
import { NumberInput } from "@/components/inputs/numberInput/NumberInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { MultipleInputRow } from "@/features/Sales/components/multipleInputRow/MultipleInputRow";
// components
import NavBlock from "@/utils/forms/NavBlock";
import VehicleTable from "./VehicleTable";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useVehicleForm } from "./useVehicleForm";
import { useWholesaleViewCtx } from "../WholesaleViewProvider";
// utils
import { scheduleData } from "@/utils/helpers/general";
// style
import salesTabStyles from "../WholesaleTab.module.scss";

const VehicleTab: FC = () => {
  const availableVehiclesLoading = useSalesSelector((s) => s.availableVehiclesLoading);
  const setWsFormField = useWholesaleViewCtx((s) => s.setWsFormField);
  const wsFormVehicle = useWholesaleViewCtx((s) => s.wsFormVehicle);
  const wsForm = useWholesaleViewCtx((s) => s.wsFormDeprec);
  // @todo convert to context-state
  const vehicleFormState = useVehicleForm();
  const { control, watch, bodyTypeData, updateVehicle, updateVehicleLoading, handleSubmit } =
    vehicleFormState;
  const isDirty = vehicleFormState.formState.isDirty;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  if (availableVehiclesLoading) return <LgLoader />;

  /** @deprecated move to separate file */
  const VehicleFormLayout: FC<{ blocker: Blocker }> = ({ blocker }) => (
    <main className={salesTabStyles.body} style={{ overflowX: "auto", overflowY: "scroll" }}>
      <form onSubmit={handleSubmit(updateVehicle)}>
        <section style={{ marginBottom: "25px" }}>
          <SectionHeader title="General Information" />
          <div className={salesTabStyles.bodyContainer}>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="stockNum"
                control={control}
                render={({ field }) => <TextInput label="Stock Number" readOnly {...field} />}
              />
              {wsForm?.saleStatus.toLowerCase() === "pending" && (
                <MultipleInputRow label="" htmlFor="">
                  <a
                    className={salesTabStyles.anchorText}
                    onClick={() => setWsFormField("vehRecId", null)}
                  >
                    + Select Different Vehicle from List
                  </a>
                </MultipleInputRow>
              )}
              <Controller
                name="vin"
                control={control}
                render={({ field }) => <TextInput label="VIN" readOnly {...field} />}
              />
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <MaskedTextInput
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    placeholder="0000"
                    label="Year"
                    readOnly
                    {...field}
                  />
                )}
              />
              <Controller
                name="make"
                control={control}
                render={({ field }) => <TextInput label="Make" readOnly {...field} />}
              />
              <Controller
                name="model"
                control={control}
                render={({ field }) => <TextInput label="Model" readOnly {...field} />}
              />
              <Controller
                name="color"
                control={control}
                render={({ field }) => <TextInput label="Color" readOnly {...field} />}
              />
              <Controller
                name="bodyType"
                control={control}
                render={({ field }) => (
                  <DropdownInput data={bodyTypeData} label="Body Type" disabled {...field} />
                )}
              />
              <Controller
                name="vehicleType"
                control={control}
                render={({ field }) => <DropdownInput label="Vehicle Type" disabled {...field} />}
              />
              <Controller
                name="drivetrain"
                control={control}
                render={({ field }) => <TextInput label="Drivetrain" readOnly {...field} />}
              />
              <Controller
                name="transmission"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    data={["Automatic", "Manual"]}
                    label="Transmission"
                    disabled
                    {...field}
                  />
                )}
              />
              <Controller
                name="milesOut"
                control={control}
                render={({ field }) => <NumberInput label="Miles Out" {...field} />}
              />
              <Grid container sx={{ flexWrap: "wrap", alignItems: "center", gap: "25px" }}>
                <Controller
                  name="notActual"
                  control={control}
                  render={({ field }) => <Checkbox label="Not Actual" {...field} />}
                />
                <Controller
                  name="exceedsLimits"
                  control={control}
                  render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
                />
                <Controller
                  name="printExempt"
                  control={control}
                  render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
                />
              </Grid>
            </div>
            <div className={salesTabStyles.bodyChild}>
              <div className={salesTabStyles.bodyChild} style={{ gap: "25px" }}>
                <Grid component="h3" sx={{ fontSize: 16, fontWeight: 700, m: 0 }}>
                  Alerts
                </Grid>
                <Controller
                  name="tmu"
                  control={control}
                  render={({ field }) => (
                    <Checkbox disabled label="TMU (True Miles Unknown)" {...field} />
                  )}
                />
                <Controller
                  name="salvageTitle"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Salvage Title" {...field} />}
                />
                <Controller
                  name="frameDamage"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Frame Damage" {...field} />}
                />
                <Controller
                  name="unibody"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Unibody" {...field} />}
                />
                <Controller
                  name="airbag"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Airbag" {...field} />}
                />
                <div>
                  <Controller
                    name="other"
                    control={control}
                    render={({ field }) => <Checkbox disabled label="Other" {...field} />}
                  />
                  {watch("other") && (
                    <Controller
                      name="otherAlert"
                      control={control}
                      render={({ field }) => <TextInput disabled {...field} />}
                    />
                  )}
                </div>
                <div className={salesTabStyles.bodyChild}>
                  <Grid component="h3" sx={{ fontSize: 16, fontWeight: 700, m: 0 }}>
                    Temporary Plate
                  </Grid>
                  <Controller
                    name="plateNumber"
                    control={control}
                    render={({ field }) => <TextInput label="Plate Number" {...field} />}
                  />
                  <Controller
                    name="dateIssued"
                    control={control}
                    render={({ field }) => <DateInput label="Date Issued" {...field} />}
                  />
                  <Controller
                    name="dateExpires"
                    control={control}
                    render={({ field }) => <DateInput label="Expiration Date" {...field} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ marginBottom: "25px" }}>
          <SectionHeader title="Suggested Sales Info" />
          <div className={salesTabStyles.bodyContainer}>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => <CurrencyInput label="Price" readOnly {...field} />}
              />
              <Controller
                name="downPayment"
                control={control}
                render={({ field }) => <CurrencyInput label="Down Payment" readOnly {...field} />}
              />
              <Controller
                name="downPaymentPercentage"
                control={control}
                render={({ field }) => (
                  <NumberInput readOnly format="p2" label="Down Payment %" {...field} />
                )}
              />
            </div>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="schedule"
                control={control}
                render={({ field }) => (
                  <DropdownInput disabled data={scheduleData} label="Schedule" {...field} />
                )}
              />
              <Controller
                name="payment"
                control={control}
                render={({ field }) => <CurrencyInput readOnly label="Payment" {...field} />}
              />
              <Controller
                name="months"
                control={control}
                render={({ field }) => <NumberInput label="Months" readOnly {...field} />}
              />
            </div>
          </div>
        </section>
        <div className={salesTabStyles.buttonContainer}>
          <Button label="Submit" buttonStyle={{ width: "134px" }} loading={updateVehicleLoading} />
        </div>
      </form>
      <NavBlock blocker={blocker} />
    </main>
  );

  return (
    <Grid
      container
      direction="column"
      sx={{ overflowX: "auto", overflowY: "hidden", p: "0 16px", gap: "16px" }}
    >
      {wsFormVehicle === null ? (
        <VehicleTable {...vehicleFormState} />
      ) : (
        <VehicleFormLayout blocker={blocker} />
      )}
    </Grid>
  );
};

export default VehicleTab;
