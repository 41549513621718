import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Button from '@/mui/components/buttons/MuiKendoButton';
import { DCS_BLUE } from '@/mui/theme/colors';
// kendo
import { Grid as KendoGrid, GridColumn, GridNoRecords } from '@progress/kendo-react-all';
import TextCell from '@/components/kendoCells/TextCell';
// state
import { useWholesaleViewCtx } from '../../WholesaleViewProvider';
import { useWholesaleCompanyFormCtx } from './WholesaleCompanyFormProvider';
// utils
import { handlePageChange } from '@/interfaces/tableState';
// interfaces
import { IKendoPaginationState } from '@/interfaces/kendo';
import { WsCompany } from '../../interfaces';
import { KendoColumnDef } from '@/utils/tableLayout/utils';
// style
import styles from '../vehicleSelectStyles.module.scss';

const buttonProps = {
  sx: { backgroundColor: undefined, borderColor: DCS_BLUE },
  style: { color: DCS_BLUE },
};
const headerClassName = styles.headerStyle;
const initPageState = { skip: 0, take: 10 };
const paginationSettings = { buttonCount: 4, pageSizes: [initPageState.take] };

const paginateData = (data: any[], skip: number, take: number) => data.slice(skip, skip + take);

/** ###  */
const WsCompanyModalListView: FC = () => {
  // Parent view state
  const wsCompanyList = useWholesaleViewCtx((s) => s.formAddlData?.companies || []);
  const setIsWsCompanyModalOpen = useWholesaleViewCtx((s) => s.setIsWsCompanyModalOpen);
  const setWsFormField = useWholesaleViewCtx((s) => s.setWsFormField);
  // Modal-form state
  const setWsCompanyResFromArr = useWholesaleCompanyFormCtx((s) => s.setWsCompanyResFromArr);
  // Local state
  const [page, setPage] = useState<IKendoPaginationState>({ ...initPageState });
  const [paginatedData, setPaginatedData] = useState(
    paginateData(wsCompanyList, page.skip, page.take)
  );
  const dbCt = wsCompanyList.length;

  // Event handlers
  const handleCloseModal = () => setIsWsCompanyModalOpen(false);
  const handleSelect = (wsCompany: WsCompany) => {
    setWsFormField('wsBuyer', wsCompany.recId || null);
    handleCloseModal();
  };
  const handleEdit = (wsCompany: WsCompany) =>
    setWsCompanyResFromArr(wsCompany.recId, wsCompanyList);

  // Effects
  useEffect(() => {
    setPaginatedData(paginateData(wsCompanyList, page.skip, page.take));
  }, [wsCompanyList.length, page.skip, page.take]);

  // Build table-column config
  const tableColumns: KendoColumnDef<keyof (WsCompany & { select: any })>[] = [
    {
      field: 'companyName',
      title: 'Wholesale Company Name',
      width: 150,
      cell: TextCell,
      headerClassName,
    },
    { field: 'state', title: 'State', width: 100, cell: TextCell, headerClassName },
    { field: 'city', title: 'City', width: 100, cell: TextCell, headerClassName },
    { field: 'address', title: 'Address', width: 100, cell: TextCell, headerClassName },
    { field: 'phone', title: 'Phone', width: 100, cell: TextCell, headerClassName },
    { field: 'contactEmail', title: 'Contact Email', width: 180, cell: TextCell, headerClassName },
    {
      field: 'select',
      title: ' ',
      cell: (props: any) => {
        return (
          <td className="k-table-td">
            <Grid container direction="row" wrap="nowrap" gap={1} flex={1} justifyContent="center">
              <Button
                onClick={() => handleSelect(props.dataItem)}
                variant="outlined"
                {...buttonProps}
              >
                Select
              </Button>
              <Button
                onClick={() => handleEdit(props.dataItem)}
                variant="outlined"
                {...buttonProps}
              >
                Edit
              </Button>
            </Grid>
          </td>
        );
      },
      width: 180,
      headerClassName,
    },
  ];

  // Build table columns
  const tableColumnElems = tableColumns.map((c) => (
    <GridColumn
      field={c.field}
      title={c.title}
      width={c.width}
      cell={c.cell}
      headerClassName={c.headerClassName}
      className={c.headerClassName}
      key={c.field}
    />
  ));

  return (
    // @todo add sort
    <KendoGrid
      data={paginatedData}
      total={dbCt}
      // Page state
      skip={page.skip}
      take={page.take}
      pageable={{ ...paginationSettings, pageSizeValue: initPageState.take }}
      onPageChange={handlePageChange(() => {}, setPage)}
      scrollable="scrollable"
      style={{ overflow: 'hidden', borderRadius: '10px 2px 10px 2px' }}
      className={styles.gridStyle}
    >
      <GridNoRecords>'No wholesale companies found'</GridNoRecords>
      {tableColumnElems}
    </KendoGrid>
  );
};

export default WsCompanyModalListView;
