import { z } from "zod";
// utils
import { zDayjs, zNumeric } from "@/utils/forms/fieldSchemas";
// interfaces
import { type EmployeeField } from "@/interfaces/System";
import { type WsForm } from "./formInterfaces";

export interface IWsFormRes extends Partial<IWsFormDb> {
  salespeople: IWsSalesperson[];
}

export class IWsFormDb {
  constructor(
    public appRecId: number | undefined,
    public bNames: string | null,
    public code3: string,
    public codBal: number,
    public saleDate: Date,
    public salesMan: number | null,
    public salesPrice: string,
    public saleStatus: string | null,
    public stockNum: string | null, // @note should be referenced from inventory/vehicle - this is a duplicate column on the backend
    public vehRecId: number | null,
    public wsBuyer: number | null,
    // Inventory/Vehicle fields
    public exceedsLim: boolean,
    public exempt: boolean,
    public make: string | null,
    public milesIn: string | null, // @note mapped from string
    public milesOut: string | null, // @note mapped from string
    public model: string | null,
    public notActual: boolean,
    public vin: string | null,
    public year: string | null,
    // Wholesale company fields
    public address: string | null,
    public city: string | null,
    public companyName: string | null,
    public contactEmail: string | null,
    public fax: string | null,
    public phone: string | null,
    public state: string | null,
    public taxId: string | null,
    public zip: string | null
  ) {}
}

export const WsFormRes = z.object({
  appRecId: z.number().nullish(),
  bNames: z.string().nullable().default(null),
  code3: z.string(),
  codBal: z.number().catch(0),
  saleDate: zDayjs,
  salesMan: z.number().nullable().default(null),
  salesPrice: zNumeric,
  saleStatus: z.string().nullable().default(null),
  stockNum: z.string().nullable().default(null), // @note should be referenced from inventory/vehicle - this is a duplicate column on the backend
  vehRecId: z.number().nullable().default(null),
  wsBuyer: z.number().nullable().default(null),
  // Inventory/Vehicle fields
  exceedsLim: z.boolean().catch(false),
  exempt: z.boolean().catch(false),
  make: z.string().nullable().default(null),
  milesIn: z.string().nullable().default(null), // @note mapped from string
  milesOut: z.string().nullable().default(null), // @note mapped from string
  model: z.string().nullable().default(null),
  notActual: z.boolean().catch(false),
  vin: z.string().nullable().default(null),
  year: z.string().nullable().default(null),
  // Wholesale company fields
  address: z.string().nullable().default(null),
  city: z.string().nullable().default(null),
  companyName: z.string().nullable().default(null),
  contactEmail: z.string().nullable().default(null),
  fax: z.string().nullable().default(null),
  phone: z.string().nullable().default(null),
  state: z.string().nullable().default(null),
  taxId: z.string().nullable().default(null),
  zip: z.string().nullable().default(null),
});
export type WsFormRes = z.infer<typeof WsFormRes>;

/** @deprecated duplicate */
export type IWsSalespersonDb = Partial<Omit<EmployeeField, "recId">> & Pick<EmployeeField, "recId">;
/** @deprecated duplicate */
export type IWsSalesperson = EmployeeField;

export class WsFormAddlData {
  public salespeople: IWsSalesperson[];
  constructor(
    public companies: WsCompany[] = [],
    public vehicles: WsFormVehicle[] = [],
    salespeople: IWsSalespersonDb[] = []
  ) {
    this.salespeople = salespeople.map((s) => ({
      recId: s.recId,
      shortName: s.shortName || "",
      userId: s.userId || "",
    }));
  }
}

export interface IWsFormAddlDataRes {
  companies?: WsCompany[];
  vehicles?: WsFormVehicle[];
}
/** @deprecated convert to  */
export class WsCompany {
  constructor(
    public address?: string | null,
    public city?: string | null,
    public companyName?: string | null,
    public contactEmail?: string | null,
    public contactName?: string | null,
    public contactPhone?: string | null,
    public county?: string | null,
    public fax?: string | null,
    public phone?: string | null,
    public recId?: number,
    public state?: string | null,
    public taxId?: string | null,
    public zip?: string | null
  ) {}

  static new(newWsCompany?: WsCompany): WsCompany {
    return new WsCompany(
      newWsCompany?.address,
      newWsCompany?.city,
      newWsCompany?.companyName,
      newWsCompany?.contactEmail,
      newWsCompany?.contactName,
      newWsCompany?.contactPhone,
      newWsCompany?.county,
      newWsCompany?.fax,
      newWsCompany?.phone,
      newWsCompany?.recId,
      newWsCompany?.state,
      newWsCompany?.taxId,
      newWsCompany?.zip
    );
  }
  /** @deprecated remove/refactor */
  static fromWsFormState(wsForm?: WsForm | null, wsCompanyList?: WsCompany[]): WsCompany {
    const wsCompany = wsCompanyList?.find((v) => v.recId === wsForm?.wsBuyer);

    if (!wsForm || !wsCompany) return new WsCompany();

    return WsCompany.new(wsCompany);
  }
}

export class WsFormVehicle {
  constructor(
    public alerT1?: boolean,
    public alerT2?: boolean,
    public alerT3?: boolean,
    public alerT4?: boolean,
    public alerT5?: boolean,
    public alerT6?: boolean,
    public alerT5N?: string,
    public bodyStyle?: string,
    public color?: string,
    public compId?: number,
    public exceedsLim?: boolean,
    public exempt?: boolean,
    public locId?: number,
    public make?: string | null,
    public milesIn?: string | null,
    public milesOut?: string | null,
    public model?: string | null,
    public notActual?: boolean,
    public orgId?: number,
    public recId?: number,
    public scOpen?: boolean,
    public stockNum?: string | null,
    public sugDown?: number,
    public sugPercent?: number,
    public sugPmt?: number,
    public sugPrice?: number,
    public sugTerm?: number,
    public sugTerms?: string,
    public tempTagExp?: string | Date | null,
    public tempTagIss?: string | Date | null,
    public tempTagNum?: string,
    public vin?: string | null,
    public year?: string | null
  ) {}

  get ymm(): string {
    const yearFmt = this.year ? `${this.year} ` : "";
    const makeFmt = this.make ? `${this.make} ` : "";
    const modelFmt = this.model ? `${this.model}` : "";
    return `${yearFmt}${makeFmt}${modelFmt}`;
  }

  static new(newVehicle: Omit<WsFormVehicle, "ymm">): WsFormVehicle {
    return new WsFormVehicle(
      newVehicle.alerT1,
      newVehicle.alerT2,
      newVehicle.alerT3,
      newVehicle.alerT4,
      newVehicle.alerT5,
      newVehicle.alerT6,
      newVehicle.alerT5N,
      newVehicle.bodyStyle,
      newVehicle.color,
      newVehicle.compId,
      newVehicle.exceedsLim,
      newVehicle.exempt,
      newVehicle.locId,
      newVehicle.make,
      newVehicle.milesIn,
      newVehicle.milesOut,
      newVehicle.model,
      newVehicle.notActual,
      newVehicle.orgId,
      newVehicle.recId,
      newVehicle.scOpen,
      newVehicle.stockNum,
      newVehicle.sugDown,
      newVehicle.sugPercent,
      newVehicle.sugPmt,
      newVehicle.sugPrice,
      newVehicle.sugTerm,
      newVehicle.sugTerms,
      newVehicle.tempTagExp,
      newVehicle.tempTagIss,
      newVehicle.tempTagNum,
      newVehicle.vin,
      newVehicle.year
    );
  }

  static fromWsFormState(
    wsForm?: WsForm | null,
    wsVehicleList?: WsFormVehicle[]
  ): WsFormVehicle | null {
    const wsVehicle = wsVehicleList?.find((v) => v.recId === wsForm?.vehRecId);

    if (!wsForm || !wsVehicle) return null;

    return WsFormVehicle.new(wsVehicle);
  }
}
