// mui
import { MuiColumnDef } from "@/mui/components/dataTable/interfaces";
// components
import ButtonGroupCell from "./ButtonGroupCell";
import BoolCell from "./BoolCell";
import ExpandCell from "./ExpandCell";
// utils
import { enumIdxCellFmt, percentCellFmt } from "@/mui/components/dataTable/cellFormatUtils";
// interfaces
import { type CollectionQueue } from "@/services/collectionsService";
import { QueueType } from "../../QueueDetailForm/interfaces";

export const tableColumns: MuiColumnDef<CollectionQueue>[] = [
  {
    // @ts-ignore
    field: "expand",
    headerName: "",
    // @ts-ignore
    width: "0",
    renderCell: ExpandCell,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  { field: "queueName", headerName: "Queue", flex: 1, minWidth: 120 },
  { field: "queueType", headerName: "Type", flex: 1, valueFormatter: enumIdxCellFmt(QueueType) },
  { field: "total", headerName: "Total", type: "number" },
  {
    field: "percentage",
    headerName: "Portfolio %",
    valueFormatter: percentCellFmt,
    type: "number",
  },
  { field: "autoTTP", headerName: "TTP Enabled", width: 120, renderCell: BoolCell },
  {
    // @ts-ignore
    field: "",
    headerName: "Actions",
    flex: 0,
    minWidth: 160,
    renderCell: (p) => <ButtonGroupCell {...p} />,
    type: "actions",
  },
];
