import { z } from "zod";
// validation
import { CliqFormConfig } from "./CliqForm/cliqFieldValidation";
// utils
import { SetState } from "@/interfaces/utilityTypes";
import { removeDuplicateFields } from "./cliqUtils";
import { cliqBaseConfig, creditCardFieldSchema, scriptElemId, scriptElemSelector } from "./config";
// interfaces
import { CollectJsConfigCallbackProps, IFormFieldConfig } from "./cliqInterfaces";

export const loadCliqConfigScript = (
  apiKey: string,
  appliedConfig: CliqFormConfig<z.ZodRawShape>
) => {
  // Remove existing DOM elems
  const existingScriptElem = document.querySelector(scriptElemSelector);
  existingScriptElem && existingScriptElem?.remove();

  // Build and attach new script tag to DOM
  const script = document.createElement("script");

  script.src = "https://secure.cardflexonline.com/token/Collect.js"; // "https://secure.safewebservices.com/token/Collect.js"
  script.async = true;
  script.id = scriptElemId;
  script.setAttribute("data-tokenization-key", apiKey);

  const parsedFields = appliedConfig.fieldLookup;

  script.onload = () => {
    removeDuplicateFields(appliedConfig.fieldMap);
    const frames = document.querySelectorAll<HTMLInputElement>(
      ".input-field iframe.CollectJSInlineIframe"
    );
    const fieldCt = parsedFields ? Object.keys(parsedFields).length : 0;
    //
    if (frames.length >= fieldCt) return;

    window.CollectJS.configure({
      ...cliqBaseConfig,
      // ...cliqConfig,
      fields: parsedFields,
    });
  };

  document.body.appendChild(script);
  return script;
};

export const loadCliqConfigScriptDeprec = (
  apiKey: string,
  cliqConfig: Partial<CollectJsConfigCallbackProps>,
  fieldMap: Map<string | number, IFormFieldConfig>,
  setIsLoading: SetState<boolean>
) => {
  setIsLoading(true);
  // Remove existing DOM elems
  const existingScriptElem = document.querySelector(scriptElemSelector);
  existingScriptElem && existingScriptElem?.remove();

  // Build and attach new script tag to DOM
  const script = document.createElement("script");

  script.src = "https://secure.cardflexonline.com/token/Collect.js"; // "https://secure.safewebservices.com/token/Collect.js"

  script.async = true;
  script.id = scriptElemId;
  script.setAttribute("data-tokenization-key", apiKey);
  const validatedFields = creditCardFieldSchema.safeParse(cliqConfig.fields);
  const parsedFields = validatedFields.data ?? cliqConfig.fields;

  script.onload = () => {
    removeDuplicateFields(fieldMap);
    const frames = document.querySelectorAll<HTMLInputElement>(
      ".input-field iframe.CollectJSInlineIframe"
    );
    const fieldCt = parsedFields ? Object.keys(parsedFields).length : 0;
    if (frames.length >= fieldCt) return setIsLoading(false);
    window.CollectJS.configure({
      ...cliqBaseConfig,
      ...cliqConfig,
      fields: parsedFields,
    });
  };

  document.body.appendChild(script);
  return script;
};
