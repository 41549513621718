import { useParams } from 'react-router-dom';
// interfaces
import { AccountDetailRouteParams } from './interfaces';

/** ### React-Router `useParams` typed for 'Accounts' detail-view subroutes
 * Returns an object of key/value pairs of the dynamic params from the current
 * URL that were matched by the route path.
 *
 * @see https://reactrouter.com/hooks/use-params
 */
export const useAccountDetailParams = useParams<AccountDetailRouteParams>;
