import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import PaymentMethodFields from "./PaymentMethodFields";
import BillingInfoFields from "./BillingInfoFields";
import OpenEdgeMpdFields from "./OpenEdgeMpdFields";
import CcBillingFields from "./CcBillingFields";
import AchAcctTypeField from "./AchAcctTypeField";
import AuthAndNotesSection from "./AuthAndNotesSection";
import SubmitButtonGroup from "./SubmitButtonGroup";

/** ###  */
const FormRightColumn: FC = () => (
  <Grid container direction="column" flex={1} sx={{ width: "500px", gap: "10px" }}>
    <PaymentMethodFields />
    <BillingInfoFields />
    <CcBillingFields />
    <OpenEdgeMpdFields />
    <AchAcctTypeField />

    <AuthAndNotesSection />
    <SubmitButtonGroup />
  </Grid>
);

export default FormRightColumn;
