import { GridCustomCellProps } from '@progress/kendo-react-all';
import styles from './KendoCells.module.scss';

export const KendoNumberCell = (props: GridCustomCellProps) => {
  const { ...restProps } = props;
  const value = Number(restProps.children?.toString());

  return (
    <td {...restProps.tdProps} className={styles.tableNumberContainer}>
      {!Number.isNaN(value) && value.toString()}
    </td>
  );
};
