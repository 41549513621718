import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import dayjs from "dayjs";
// mui
// kendo
import { formatNumber } from "@progress/kendo-intl";
import { GridCustomCellProps } from "@progress/kendo-react-all";
import { useAppDispatch } from "@/store/store";
import { collectionsActions } from "../collectionsSlice";
// interfaces
import { CollectionRow } from "./interfaces";
// style
import styles from "./QueueRunList.module.scss";

/** @deprecated duplicate component */
export const CurrencyCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td className="k-table-td">
    <div className={styles.currencyCell}>{formatNumber(dataItem[field!], "c")}</div>
  </td>
);
/** @deprecated duplicate component */
export const DateCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td className={styles.currencyCell}>{dayjs(dataItem[field!]).format("MM/DD/YYYY")}</td>
);
/** @deprecated duplicate component */
export const DaysLateCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td className="k-table-td">
    <div className={dataItem[field!] < 0 ? styles.daysLateNegative : styles.daysLatePositive}>
      {dataItem[field!]}
    </div>
  </td>
);
/** @deprecated duplicate component */
export const BoolCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>
    <div className={styles.boolCell}>
      {dataItem[field!] ? (
        <div>
          <FaCheckCircle className={styles.icon} />
        </div>
      ) : (
        <div>--</div>
      )}
    </div>
  </td>
);
/** @deprecated duplicate component */
export const StatusCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>
    <div className={styles.boolCell}>
      {dataItem[field!] == "green" ? (
        <div>
          <FaCircle className={styles.iconGreen} />
        </div>
      ) : null}
      {dataItem[field!] == "yellow" ? (
        <div>
          <FaCircle className={styles.iconYellow} />
        </div>
      ) : null}
      {dataItem[field!] == "red" ? (
        <div>
          <FaCircle className={styles.iconRed} />
        </div>
      ) : null}
    </div>
  </td>
);
/** @deprecated needs refactor */
export const StockNumCell: FC<
  Omit<GridCustomCellProps, "dataItem"> & { dataItem: CollectionRow }
> = ({ dataItem }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const runAccount = (collection: CollectionRow) => {
    dispatch(collectionsActions.setQueueAccounts([collection]));
    navigate(`run/${collection.recId}`, { state: { collection } });
  };

  return (
    <td className="k-table-td">
      <a onClick={() => runAccount(dataItem)} className={styles.link}>
        {dataItem.stockNum}
      </a>
    </td>
  );
};
