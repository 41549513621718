import {  PhoneInput, TextInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import tabStyles from '../../components/salesTabs/SalesTab.module.scss';

const UserInfo: FC<any> = ({ control, errors }) => {
  return (
    <div className={tabStyles.userInfoBody}>
      <div className={tabStyles.userInfoBar}>
        <div className={tabStyles.userData}>
          <Controller
            name="fullName"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <TextInput required label="Full Name" errors={errors.fullName?.message} {...field} />
            )}
          />
        </div>
        <div className={tabStyles.userData}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <PhoneInput
                required
                label="Phone Number"
                errors={errors.phoneNumber?.message}
                {...field}
              />
            )}
          />
        </div>
        <div className={tabStyles.userData}>
          <Controller
            name="dateOfQuote"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <DateInput
                readOnly
                required
                label="Date of Quote"
                errors={errors.dateOfQuote?.message}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
