import { Button, Checkbox, DropdownInput, TextInput } from '@/components';
import { CbrResponse, CrCode, legalService } from '@/services/legalService';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './CreditReporting.module.scss';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
import { useAppDispatch } from '@/store/store';
import { CoRepoType } from '@/interfaces';

type FormProps = {
  specialCondition: string;
  addressIndicator: string;
  doNotReport: boolean;
  doNotReportReason: string;
  closeAccount: boolean;
  type: string;
  specialComments: string;
  complianceConditionCode: string;
};

export const CreditReporting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const dispatch = useAppDispatch();
  const [cbrResponse, setCbrResponse] = useState<CbrResponse>({} as CbrResponse);
  const [specialConditionOptions, setSpecialConditionOptions] = useState<CrCode[]>([]);
  const [addressIndicatorOptions, setAddressIndicatorOptions] = useState<CrCode[]>([]);
  const [typeOptions, setTypeOptions] = useState<CoRepoType[]>([]);
  const [specialCommentsOptions, setSpecialCommentsOptions] = useState<CrCode[]>([]);
  const [complianceConditionOptions, setComplianceConditionOptions] = useState<CrCode[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { control, handleSubmit, watch, reset } = useForm<FormProps>({
    defaultValues: {
      specialCondition: '',
      addressIndicator: '',
      doNotReport: false,
      doNotReportReason: '',
      closeAccount: false,
      type: '',
      specialComments: '',
      complianceConditionCode: '',
    },
  });

  useEffect(() => {
    setLoading(true);
    legalService
      .getCBR(colRecId)
      .then((res) => {
        reset({
          specialCondition:
            res.crCodes.find(
              (value) => value.codeId === res.cbr.specialCondition && value.codeId !== ''
            )?.description || '',
          addressIndicator:
            res.crCodes.find(
              (value) => value.codeId === res.cbr.addressIndicator && value.codeId !== ''
            )?.description || '',
          doNotReport: res.cbr.doNotReport,
          doNotReportReason: res.cbr.doNotReportReason || '',
          closeAccount: res.cbr.closeAccount,
          type: res.cbr.type || '',
          specialComments:
            res.crCodes.find(
              (value) => value.codeId === res.cbr.specialComments && value.codeId !== ''
            )?.description || '',
          complianceConditionCode:
            res.crCodes.find(
              (value) => value.codeId === res.cbr.complianceConditionCode && value.codeId !== ''
            )?.description || '',
        });
        setCbrResponse(res);
        setSpecialConditionOptions(res.crCodes.filter((code) => code.type === 'B'));
        setAddressIndicatorOptions(res.crCodes.filter((code) => code.type === 'X'));
        setTypeOptions(res.coRepoTypes);
        setSpecialCommentsOptions(res.crCodes.filter((code) => code.type === 'S'));
        setComplianceConditionOptions(res.crCodes.filter((code) => code.type === 'C'));
      })
      .catch(() => toast.error('There was an error retrieving the CBR information'))
      .finally(() => setLoading(false));
  }, []);

  const submitCbr = (data: FormProps) => {
    setSubmitLoading(true);
    const specialCondition = specialConditionOptions.find(
      (value) => value.description === data.specialCondition
    );
    const addressIndicator = addressIndicatorOptions.find(
      (value) => value.description === data.addressIndicator
    );
    const specialComments = specialCommentsOptions.find(
      (value) => value.description === data.specialComments
    );
    const complianceCondition = complianceConditionOptions.find(
      (value) => value.description === data.complianceConditionCode
    );

    legalService
      .updateCBR({
        recId: cbrResponse.cbr.recId!,
        colRecId: colRecId,
        buyer: cbrResponse?.cbr?.buyer,
        address: cbrResponse?.cbr?.address,
        specialCondition: specialCondition ? specialCondition.codeId : null,
        addressIndicator: addressIndicator ? addressIndicator.codeId : null,
        type: data.type,
        specialComments: specialComments ? specialComments.codeId : null,
        complianceConditionCode: complianceCondition ? complianceCondition.codeId : null,
        doNotReport: data.doNotReport,
        closeAccount: data.closeAccount,
        doNotReportReason: watch('doNotReport') ? data.doNotReportReason : '',
      })
      .then(() => {
        toast.success('CBR Updated');
        dispatch(getAccountInformation(colRecId));
        navigate('..');
      })
      .catch(() => toast.error('There was an error updating CBR'))
      .finally(() => setSubmitLoading(false));
  };

  return (
    <AccountsMainPanel navBarTitle="Report To Credit" loading={loading}>
      <form className={styles.container} onSubmit={handleSubmit(submitCbr)}>
        <h2>Customer Information</h2>
        <div>
          <b>Buyer:</b> {cbrResponse?.cbr?.buyer}
        </div>
        <div>
          <b>Address:</b> {cbrResponse?.cbr?.address}
        </div>
        <Controller
          name="specialCondition"
          control={control}
          render={({ field }) => (
            <DropdownInput
              {...field}
              label="Special Condition"
              data={specialConditionOptions.map((value) => value.description).sort()}
              suggest
            />
          )}
        />
        <Controller
          name="addressIndicator"
          control={control}
          render={({ field }) => (
            <DropdownInput
              label="Address Indicator"
              data={addressIndicatorOptions.map((value) => value.description).sort()}
              suggest
              {...field}
            />
          )}
        />
        <h2>Reporting Information</h2>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <DropdownInput
              label="Type"
              suggest
              data={typeOptions.map((value) => value.type).sort()}
              {...field}
            />
          )}
        />
        <div className={styles.row}>
          <Controller
            name="doNotReport"
            control={control}
            render={({ field }) => (
              <Checkbox label="Do Not Report" labelStyles={{ fontSize: '14px' }} {...field} />
            )}
          />
          <Controller
            name="closeAccount"
            control={control}
            render={({ field }) => (
              <Checkbox label="Close Account" labelStyles={{ fontSize: '14px' }} {...field} />
            )}
          />
        </div>
        {watch('doNotReport') && (
          <Controller
            name="doNotReportReason"
            control={control}
            render={({ field }) => <TextInput label="Do Not Report Reason" {...field} />}
          />
        )}
        <Controller
          name="specialComments"
          control={control}
          render={({ field }) => (
            <DropdownInput
              label="Special Comments"
              data={specialCommentsOptions.map((value) => value.description).sort()}
              suggest
              {...field}
            />
          )}
        />
        <Controller
          name="complianceConditionCode"
          control={control}
          render={({ field }) => (
            <DropdownInput
              label="Compliance Condition Code"
              data={complianceConditionOptions.map((value) => value.description).sort()}
              suggest
              {...field}
            />
          )}
        />
        <Button
          type="submit"
          label="Submit"
          loading={submitLoading}
          buttonStyle={{ width: '50%' }}
        />
      </form>
    </AccountsMainPanel>
  );
};
