import React, { FC, useEffect, useState } from 'react';
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
  Input,
  Loader,
} from '@progress/kendo-react-all';
import { useInventoryDetailCtx } from '../../../InventoryDetailProvider';
import { Button } from '@/components';
import { DeleteCell, VerifyCell } from './cellTypes';
import styles from './CheckInGrid.module.scss';
import { OptionsItem } from '@/interfaces';
import { inventoryService } from '@/services/inventoryService';
import InspectorSelector from './InspectorSelector';
import stylesContainer from '../CheckInOptions.module.scss';

const OptionGrid: FC = () => {
  const isLoading = useInventoryDetailCtx((s) => s.isLoading);
  const optionsList = useInventoryDetailCtx((s) => s.optionsList);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);
  const setOptionsList = useInventoryDetailCtx((s) => s.setOptionsList);
  const setOptionsState = useInventoryDetailCtx((s) => s.setOptionsList);
  const [newItemValue, setNewItemValue] = useState('');
  const [maxHeight, setMaxHeight] = useState('');

  const addRow = async () => {
    const newSorder = Math.max(...optionsList.map((item: any) => item.sOrder)) + 1;
    const rowToAdd = new OptionsItem(
      undefined,
      inventoryVehicle.orgId,
      undefined,
      undefined,
      undefined,
      undefined,
      inventoryVehicle.recId,
      undefined,
      undefined,
      undefined,
      newSorder,
      newItemValue,
      false,
      false,
      inventoryVehicle.vin,
      undefined,
      undefined
    );
    const res = await inventoryService.insertVehicleOptionItem(rowToAdd);
    setOptionsList(res);
    setOptionsState(res);
    setNewItemValue(''); // Clear newItemValue after adding the row
  };

  const handleDelete = async (recIdToDelete: number) => {
    const res = await inventoryService.deleteSingleVehicleOption(
      Number(recIdToDelete),
      Number(inventoryVehicle.recId)
    );
    setOptionsState(res);
    setOptionsList(res);
  };

  const handleRefresh = async () => {
    const compId =
      optionsList.length === 0 ? 0 : Math.max(...optionsList.map((item: any) => item.compId));
    const res = await inventoryService.repopulateVehicleOptions(
      Number(inventoryVehicle.recId),
      Number(inventoryVehicle.orgId),
      inventoryVehicle.vin!,
      Number(inventoryVehicle.locId),
      Number(compId)
    );
    setOptionsState(res);
    setOptionsList(res);
  };

  const handleDataChange = (event: any) => {
    // Handle data changes here, such as updating the state
    setOptionsList(event.data);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;

      if (screenHeight <= 500) {
        setMaxHeight('30vh');
      } else if (screenHeight > 500 && screenHeight <= 650) {
        setMaxHeight('45vh');
      } else {
        setMaxHeight('55vh');
      }
    };

    // Call handleResize once to set initial maxHeight
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={stylesContainer.vehicleDetailsCard}>
      <div className={stylesContainer.formWrapper}>
        <Grid
          style={{ maxHeight: maxHeight }}
          data={optionsList}
          editField="inEdit"
          onItemChange={handleDataChange}
        >
          <GridColumn field="option" title="Option" editable={false} />
          <GridColumn
            field="verified"
            title="Verified"
            editable={true}
            cell={VerifyCell}
            headerClassName={styles.centeredHeader}
            width="100px"
          />
          <GridColumn
            field="delete"
            title="Delete"
            editable={true}
            cell={(props) => <DeleteCell {...props} onDelete={handleDelete} />}
            headerClassName={styles.centeredHeader}
            width="100px"
          />
          <GridNoRecords>
            {isLoading ? <Loader size="large" /> : 'No Vehicle Options Found'}
          </GridNoRecords>
          <GridToolbar>
            <InspectorSelector />
            <div style={{ flex: 1 }}></div>
            <Input
              value={newItemValue}
              onChange={(e) => setNewItemValue(e.target.value as string)}
              placeholder="Enter item value"
            />
            <Button
              secondary
              label="Add Row"
              themeColor="dark"
              onClick={addRow}
              disabled={!newItemValue.trim()}
            />
            <Button secondary label="Repopulate" themeColor="dark" onClick={handleRefresh} />
          </GridToolbar>
        </Grid>
      </div>
    </div>
  );
};

export default OptionGrid;
