import { ExcludeMethods } from "@/interfaces/utilityTypes";
import { RuntimeEnvKey, RuntimeEnvConfigValidation } from "./interfaces";

export class RuntimeEnvConfig {
  key: RuntimeEnvKey;
  level: number;
  label: string;
  isStandalone: boolean;

  constructor(input: ExcludeMethods<RuntimeEnvConfig>) {
    RuntimeEnvConfigValidation.parse(input);

    this.key = input.key;
    this.level = input.level;
    this.label = input.label;
    this.isStandalone = input.isStandalone;
  }

  isAllowedEnv(maxAllowedEnv: RuntimeEnvKey, customCondition: boolean = true): boolean {
    // Check if environment is set properly
    const maxAllowedEnvVal = RuntimeEnvKey.safeParse(maxAllowedEnv);
    if (!maxAllowedEnvVal.success) {
      console.error(maxAllowedEnvVal.error);
      return false;
    }
    const maxEnvLevelConfig = envConfigMap.get(maxAllowedEnvVal.data);
    if (!maxEnvLevelConfig) {
      console.error(
        "Invalid config:",
        maxAllowedEnvVal.data,
        "for environment key:",
        maxAllowedEnv
      );
      return false;
    }

    const shouldRender = this.level <= maxEnvLevelConfig.level && customCondition;
    return shouldRender;
  }
}

/** @deprecated use utility function for limiting component/function use */
export const isProd = import.meta.env.MODE.includes("production");
/** @deprecated use utility function for limiting component/function use */
export const isStaging = import.meta.env.MODE.includes("staging");
/** @deprecated use utility function for limiting component/function use */
export const isQA = import.meta.env.MODE.includes("qa");
/** @deprecated use utility function for limiting component/function use */
export const isDev = import.meta.env.MODE.includes("development");

/** @deprecated remove once off Alpha */
export const isStandalone = import.meta.env.MODE.includes("standalone");

export const envConfigMap = new Map<RuntimeEnvKey, RuntimeEnvConfig>([
  [
    "development",
    new RuntimeEnvConfig({ key: "development", label: "Development", level: 0, isStandalone }),
  ],
  ["qa", new RuntimeEnvConfig({ key: "qa", label: "QA", level: 1, isStandalone })],
  ["staging", new RuntimeEnvConfig({ key: "staging", label: "Staging", level: 2, isStandalone })],
  [
    "production",
    new RuntimeEnvConfig({ key: "production", label: "Development", level: 3, isStandalone }),
  ],
]);

const envMode = import.meta.env.MODE;

// @note this is split by a single hyphen - `development-standalone`
const envModeSplit = envMode.split("-");
const currentRuntimeEnvStr = RuntimeEnvKey.parse(envModeSplit[0]);
// const isStandalone = envModeSplit.length > 1 && envModeSplit[1] === 'standalone';

/** @todo add environment variables to global state context */
export const currentEnv = envConfigMap.get(currentRuntimeEnvStr)!;
