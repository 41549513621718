import { KendoColumnDef } from "@/utils/tableLayout/utils";
import { InsuranceClaim } from "./interfaces";

export const tableColumns: KendoColumnDef<keyof InsuranceClaim>[] = [
  { field: "claimStatus", title: "Claim Status" },
  { field: "claimDate", title: "Claim Date" },
  { field: "insCName", title: "Ins. Company" },
  { field: "custIns", title: "Cust. Ins" },
  { field: "cpiClaim", title: "CPI Claim" },
  { field: "custDed", title: "Deduct" },
  { field: "setAmt", title: "Settle Amt." },
  { field: "setDate", title: "Settle Date" },
];
