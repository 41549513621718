import React, { FC } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";

// Import existing components
import CompanyDetails from "./CompanyDetails";
import CompanyDefaults from "./CompanyDefaults";
import CompanyMgmtProvider from "./CompanyMgmtProvider";
import AddOnsSalesTaxRouter from "./addOnsSalesTax/AddOnsSalesTaxRouter";
import RepoCompanyRouter from "./RepoCompanies";

// Define all tabs
const tabs = [
  { value: "company-details", label: "Details" },
  { value: "company-defaults", label: "Defaults" },
  { value: "leasing-rates", label: "Leasing Rates" },
  { value: "checklist-items", label: "Checklist Items" },
  { value: "tax-settings", label: "Tax Settings" },
  { value: "repo-companies", label: "Repo Companies" },
  { value: "lien-holders", label: "Lien Holders" },
  { value: "addons-salestax", label: "Addons/Sales Tax" },
];

const CompanyManagementRouter: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Determine the currently active tab
  const currentTab =
    tabs.find((tab) => location.pathname.includes(tab.value))?.value || tabs[0]?.value;

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`./${newValue}`);
  };

  return (
    <CompanyMgmtProvider>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "30px",
          boxShadow: 3,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: `4px solid rgb(230, 230, 230)`,
            backgroundColor: "#eff0f4",
            padding: "0px 18px",
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="company-management-tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              ".MuiTab-root": {
                textTransform: "none",
                fontFamily: "Inter, sans-serif",
                fontSize: 16,
                fontWeight: 700,
                color: "rgb(17, 24, 39)",
                borderBottom: "4px solid transparent",
                padding: "4px 18px 0px 18px",
              },
              ".MuiTab-root.Mui-selected": {
                color: `rgb(41, 70, 149)`,
                borderBottom: `4px solid rgb(41, 70, 149)`,
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                aria-controls={`tabpanel-${tab.value}`}
              />
            ))}
          </Tabs>
        </Box>

        {/* Content Section */}
        <Box sx={{ padding: "20px" }}>
          <Routes>
            <Route index element={<Navigate to="company-details" replace />} />
            <Route path="company-details" element={<CompanyDetails />} />
            <Route path="company-defaults" element={<CompanyDefaults />} />

            {/* Other routes */}
            <Route path="leasing-rates" element={<div>Leasing Rates Placeholder</div>} />
            <Route path="checklist-items" element={<div>Checklist Items Placeholder</div>} />
            <Route path="tax-settings" element={<div>Tax Settings Placeholder</div>} />
            <Route path="repo-companies/*" element={<RepoCompanyRouter />} />
            <Route path="lien-holders" element={<div>Lien Holders Placeholder</div>} />

            {/* AddOns Sales Tax Router */}
            <Route path="addons-salestax/*" element={<AddOnsSalesTaxRouter />} />

            {/* Remove or restrict the fallback route */}
            <Route path="*" element={<Navigate to="company-details" replace />} />
          </Routes>
        </Box>
      </Box>
    </CompanyMgmtProvider>
  );
};

export default CompanyManagementRouter;
