import dayjs, { Dayjs } from "dayjs";
// interfaces
import { ICompanyLookup } from "@/interfaces/Company";
import { UpdateRoleReq } from "../AuthorizationMgmt/AuthRoleDetail/interfaces";

export class RoleDb {
  // defCompId: number;
  dms_Accounts: boolean;
  dms_Collections: boolean;
  dms_Inventory: boolean;
  dms_Sales: boolean;
  lastUpdated: Dayjs | null;
  orgId: number;
  recId: number;
  templateName: string;

  constructor(newRole: RoleDb) {
    const lastUpdated = dayjs(newRole.lastUpdated).isValid() ? dayjs(newRole.lastUpdated) : null;

    // @todo add validation for fields
    // this.defCompId = newRole.defCompId;
    this.orgId = newRole.orgId;
    this.dms_Accounts = newRole.dms_Accounts;
    this.dms_Collections = newRole.dms_Collections;
    this.dms_Inventory = newRole.dms_Inventory;
    this.dms_Sales = newRole.dms_Sales;
    this.lastUpdated = lastUpdated; // Default to current date if invalid
    this.recId = newRole.recId;
    this.templateName = newRole.templateName;
  }

  static fromReqBody(req: UpdateRoleReq, row: RoleRow): RoleDb {
    return new RoleDb({ ...row, ...req });
  }
}

export class RoleRow extends RoleDb {
  company: ICompanyLookup | null;

  constructor(role: RoleDb, companies: ICompanyLookup[]) {
    super(role);

    this.company = companies.find((comp) => comp.orgId === role.orgId) || null;
  }
}
