import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  Loader,
  GridCustomCellProps,
} from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Column } from '@/components/table/TableInterface';
import { Icons } from '@/components/icons';
import { Modal } from '@/components/modals/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { TextInput } from '@/components/inputs/text/TextInput';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
// utils
import { salesService } from '@/services/salesService';
import { formatDate } from '@/utils/helpers/general';
// interfaces
import { ScannedDocument } from '@/interfaces/Sales';
// style
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import scannedStyles from './ScannedDocuments.module.scss';
import styles from '../inventoryExpenses/expensesGrid/expensesGrid.module.scss';

/** @deprecated When implemented, pull scanned-documents state from `useScannedDocumentsCtx` - see components/hooks [PrintDocuments, Photos, ScannedDocuments, useSalesDocuments, useDocumentsForm] */
const ScannedDocuments: FC = () => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);
  const locId = useAuthSelector((s) => s.locId);

  const vehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);

  const [scannedDocsLoading, setScannedDocsLoading] = useState(false);
  const [scannedDocModalOpen, setScannedDocModalOpen] = useState(false);
  const [scannedDocsList, setScannedDocsList] = useState<ScannedDocument[]>([]);
  const [scannedFileDesc, setScannedFileDesc] = useState('');
  const [scannedFile, setScannedFile] = useState<File | null>(null);
  const [uploadingScannedFile, setUploadingScannedFile] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);

  const loadScannedDocs = async () => {
    setScannedDocsLoading(true);
    try {
      if (Number(vehicle?.recId) < 1) {
        return;
      }
      const res = await salesService.getScannedDocsByVehicle(orgId!, Number(vehicle?.recId));
      setScannedDocsList(res ?? []);
    } finally {
      setScannedDocsLoading(false);
    }
  };

  const uploadScannedFile = async () => {
    let hasErrors = false;

    if (!scannedFile) {
      setFileInputError(true);
      hasErrors = true;
    }
    if (!scannedFileDesc) {
      setFileDescriptionError(true);
      hasErrors = true;
    }

    if (hasErrors) return;

    const payload = {
      file: scannedFile!,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: accountInformation?.appRecId ?? 0,
      vehRecId: vehicle?.recId ?? 0,
      fileDesc: scannedFileDesc,
    };

    setUploadingScannedFile(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadScannedDocs(); // Refresh the list after upload
      setScannedFile(null);
      setScannedFileDesc('');
      setScannedDocModalOpen(false);
    } finally {
      setUploadingScannedFile(false);
    }
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setScannedFileDesc(e.target.value);
  };

  const handleScannedDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileInputError(false);
      setScannedFile(file);
    }
  };

  /** @deprecated move out of component body */
  const ScannedDocPrintButton: FC<GridCustomCellProps> = (props) => {
    const [printing, setPrinting] = useState(false);
    const value = props.dataItem;

    const handlePrint = async () => {
      setPrinting(true);
      try {
        await salesService.printScannedDoc(orgId!, value.fileNameCloud);
      } finally {
        setPrinting(false);
      }
    };

    return (
      <td className="k-table-td">
        {printing ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handlePrint} className={styles.tableActionIconContainer}>
            <Icons.Print className={styles.print} />
          </div>
        )}
      </td>
    );
  };

  /** @deprecated move out of component body */
  const DeleteButton: FC<GridCustomCellProps> = (props) => {
    const [deleting, setDeleting] = useState(false);
    const value = props.dataItem;

    const handleDelete = async () => {
      setDeleting(true);
      try {
        await salesService.deleteScannedDoc(orgId!, value.recId);
        await loadScannedDocs(); // Refresh the list after deletion
      } finally {
        setDeleting(false);
      }
    };

    return (
      <td className="k-table-td">
        {deleting ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handleDelete} className={styles.tableActionIconContainer}>
            <Icons.X style={{ color: 'red' }} />
          </div>
        )}
      </td>
    );
  };

  const DateCell: FC<GridCustomCellProps> = (props) => {
    const value = props.dataItem[props.field!];
    return <td>{formatDate(value as string)}</td>;
  };

  const scannedDocumentsColumns: Column[] = [
    { field: 'fileDesc', title: 'Document Description' },
    { field: 'fileUploadedBy', title: 'Uploaded By' },
    { field: 'fileUploadedDT', title: 'Upload Date', cells: { data: DateCell } },
    { field: 'print', title: ' ', cells: { data: ScannedDocPrintButton }, width: 60 },
    { field: 'delete', title: ' ', cells: { data: DeleteButton }, width: 60 },
  ];

  const scannedDocColumnElems = scannedDocumentsColumns.map((column, index) => (
    <GridColumn
      key={index}
      field={column.field}
      title={column.title}
      cells={column.cells}
      width={column.width}
    />
  ));

  useEffect(() => {
    loadScannedDocs();
  }, [orgId, vehicle?.recId]);

  return (
    <section className={inventoryPageStyles.multipleSectionsChild}>
      <Grid container className={scannedStyles.container}>
        <Grid container className={scannedStyles.body}>
          <section className={scannedStyles.section}>
            <SectionHeader showBar title="Scanned Documents" />
            <div className={scannedStyles.buttonContainer}>
              <Button label="Upload New" onClick={() => setScannedDocModalOpen(true)} />
            </div>
            <Spacer size={25} />
            <div className={styles.grid}>
              <KendoGrid data={scannedDocsList}>{scannedDocColumnElems}</KendoGrid>
            </div>
          </section>
        </Grid>
        {scannedDocModalOpen && (
          <Modal
            isOpen={true}
            onCloseButtonClick={() => setScannedDocModalOpen(false)}
            closeButton
            centerModal
            title="Upload Scanned Document"
            panelStyle={{ width: 600 }}
          >
            <div>
              <Spacer size={5} />
              <FileInput
                onChange={handleScannedDocInputChange}
                fileName={scannedFile?.name}
                errors={fileInputError}
              />
              <Spacer size={20} />
              <TextInput
                required
                label="File Description"
                value={scannedFileDesc}
                horizontalLabel={false}
                onChange={handleFileDescriptionChange}
                errors={fileDescriptionError}
              />
              <Spacer size={20} />
              <div style={{ width: 100, marginLeft: 'auto' }}>
                <Button label="Submit" loading={uploadingScannedFile} onClick={uploadScannedFile} />
              </div>
            </div>
          </Modal>
        )}
      </Grid>
    </section>
  );
};

export default ScannedDocuments;
