// interfaces
import { OrderForRepoRowData } from '@/features/Dashboard/interfaces';
import { DtoPage, DtoSort, PfsReqDto } from '@/interfaces/requests';

export class OutForRepoReq extends PfsReqDto {
  constructor(public appRecId: number, page: DtoPage, sorts: DtoSort[]) {
    super(page, sorts);
  }

  /** ### Constructor method that takes an object rather than individual params */
  static new_(newOutForRepoReq: OutForRepoReq): OutForRepoReq {
    return new OutForRepoReq(
      newOutForRepoReq.appRecId,
      newOutForRepoReq.page,
      newOutForRepoReq.sorts
    );
  }
}

export interface OrderForRepoFormData extends Omit<OrderForRepoRowData, "openByName"> {
  closeIt: boolean; //closeit
  vehicleRecovered?: boolean; //FCComplete
}
