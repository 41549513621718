import { FC } from "react";
import { NavLinkProps } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
// mui
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { alpha } from "@mui/system/colorManipulator";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { ButtonProps, buttonClasses } from "@mui/material/Button";
// local
import LinkButton from "./LinkButton";
import { DCS_BLUE } from "../../theme/colors";

const defaultSx: SxProps = {
  "&:hover": { background: alpha(DCS_BLUE, 0.05) },
  background: "inherit",
  px: 0,
  pr: 1,
};

type BackLinkProps = Omit<ButtonProps, "children"> & Partial<Omit<NavLinkProps, "children">>;

/** ### Styled back-button commonly used at the top of a view
 *
 * - Wrapper for `LinkButton`
 * - Default navigation is: 'up-one-level' (i.e. `../` - `/sales/postedSales/761843` -> `/sales/postedSales`)
 * - Default title is: "< Back to list"
 */
const BackLink: FC<
  BackLinkProps & {
    title?: string;
    disableChevron?: boolean;
    gridProps?: Grid2Props;
  }
> = ({ title = "Back to list", disableChevron = false, gridProps, sx, ...buttonProps }) => (
  <Grid container direction="row" {...gridProps}>
    <LinkButton
      sx={{ ...defaultSx, [`& .${buttonClasses.startIcon}`]: { ml: 0, mr: -0.25 }, ...sx }}
      disableElevation
      color="backLink"
      to="../"
      startIcon={!disableChevron && <FaChevronLeft style={{ height: 10 }} />}
      {...buttonProps}
    >
      {title}
    </LinkButton>
  </Grid>
);

export default BackLink;
