// mui
import { MuiColumnDef } from "@/mui/components/dataTable/interfaces";
// components
// utils
import { currencyCellFmt, dateCellFmt } from "@/mui/components/dataTable/cellFormatUtils";
// interfaces
import { SidenoteDetail } from "@/interfaces";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { FC } from "react";
import dayjs from "dayjs";

const LinkCell: FC<GridRenderCellParams<SidenoteDetail>> = ({ row }) => {
  return (
    <NavLink to={`./${row.recId}/`} relative="path">
      {row.snNumber}
    </NavLink>
  );
};

export const tableColumns: readonly MuiColumnDef<SidenoteDetail>[] = [
  { field: "snNumber", headerName: "SN Number", renderCell: LinkCell },
  {
    field: "snDate",
    headerName: "Date",
    flex: 1,
    minWidth: 120,
    type: "dateTime",
    valueFormatter: dateCellFmt,
    valueGetter: (p) => dayjs(p?.row.snDate).toDate(),
  },
  { field: "snTotal", headerName: "Total", flex: 1, minWidth: 120, valueFormatter: currencyCellFmt, type: "number" },
  { field: "snDown", headerName: "Down", flex: 1, minWidth: 120, valueFormatter: currencyCellFmt, type: "number" },
  { field: "snAmtFin", headerName: "Financed", flex: 1, minWidth: 120, valueFormatter: currencyCellFmt, type: "number" },
  { field: "prinBal", headerName: "Prin. Balance", flex: 1, minWidth: 120, valueFormatter: currencyCellFmt, type: "number" },
  { field: "status", headerName: "Status", flex: 1, minWidth: 120 },
  { field: "snDetail", headerName: "Detail", flex: 1, minWidth: 120 },
];
