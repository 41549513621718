import { FC, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// kendo
import { Button, DmsCheckbox, PhoneNumberInput, DmsModal } from '@components/old';
import { Spacer } from "@/components/spacer/Spacer";
// utils
import { GetPaymentData, paymentService } from '@/services/paymentService';
import { sanitizePhoneInput, displayErrors } from '@/utils/helpers/general';
// style
import styles from './TextToPay.module.scss';

/** ###  */
const TextToPay: FC<{
  paymentDetails: GetPaymentData;
  setPaymentDetails: Dispatch<SetStateAction<GetPaymentData | undefined>>;
}> = ({ paymentDetails, setPaymentDetails }) => {
  const { colRecId, appRecId, gpiCustStatmentEnabled } = paymentDetails;
  const [enabledLoading, setEnabledLoading] = useState<boolean>(false);

  const toggleTextToPay = () => {
    setEnabledLoading(true);
    if (!colRecId || !appRecId) {
      displayErrors('Unable to update opt out status');
      return;
    }
    paymentService
      .postToggleTextToPay({
        colRecId,
        enabled: !gpiCustStatmentEnabled,
      })
      .then(() => {
        setPaymentDetails((pd) => ({
          ...(pd as GetPaymentData),
          gpiCustStatmentEnabled: !gpiCustStatmentEnabled,
        }));
      })
      .finally(() => {
        setEnabledLoading(false);
      });
  };

  return (
    <div className={styles.textToPay}>
      <div className={styles.textToPayHeader}>Text to pay</div>
      <div className={styles.container}>
        {enabledLoading ? (
          <div className={styles.changeLoading}>
            <CircleSpinner color={'#3299df'} size={25} />
          </div>
        ) : (
          <DmsCheckbox
            checkboxLabel="Enable"
            onChange={toggleTextToPay}
            checked={gpiCustStatmentEnabled}
          />
        )}
        <Spacer size={8} />
        {gpiCustStatmentEnabled && (
          <div className={styles.textToPayItemContainer}>
            <div>
              <TextToPayItem
                phoneNumber={paymentDetails.buyerCPhone}
                appRecId={paymentDetails.appRecId}
                colRecId={paymentDetails.colRecId}
                email={paymentDetails.buyerEmail}
                buyerCoBuyerRecId={paymentDetails.buyerRecId}
                isBuyer={true}
              />
            </div>
            {paymentDetails.hasCobuyer && (
              <>
                <div>
                  <TextToPayItem
                    phoneNumber={paymentDetails.cobuyerCPhone}
                    appRecId={paymentDetails.appRecId}
                    colRecId={paymentDetails.colRecId}
                    email={paymentDetails.cobuyerEmail}
                    buyerCoBuyerRecId={paymentDetails.coBuyerRecId ?? undefined}
                    isBuyer={false}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type TextToPayItemProps = {
  phoneNumber: string;
  appRecId?: number;
  colRecId?: number;
  email: string;
  buyerCoBuyerRecId?: number;
  isBuyer: boolean;
};

const TextToPayItem = (props: TextToPayItemProps) => {
  const { phoneNumber, appRecId, colRecId, email, buyerCoBuyerRecId, isBuyer } = props;

  const [editNumberModalOpen, setEditNumberModalOpen] = useState<boolean>(false);
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);

  useEffect(() => {
    if (phoneNumber) {
      setTempPhoneNumber(phoneNumber);
    }
  }, [phoneNumber]);

  const sendTextToPay = () => {
    paymentService.sendTextToPay(appRecId!, colRecId!, email!, tempPhoneNumber).finally(() => {
      setLoading(false);
    });
  };

  const onDecline = () => {
    setTempPhoneNumber(phoneNumber);
    setEditNumberModalOpen(false);
  };

  const updatePhoneNumber = () => {
    setChangeLoading(true);
    if (!colRecId || !appRecId || !buyerCoBuyerRecId) {
      displayErrors('Unable to update number');
      return;
    }
    paymentService
      .updateTextToPayNumber({
        colRecId,
        appRecId,
        isBuyer,
        buyerCoBuyerRecId: buyerCoBuyerRecId,
        cellPhone: parseInt(sanitizePhoneInput(tempPhoneNumber)),
      })
      .catch(() => {
        setTempPhoneNumber(phoneNumber);
      })
      .finally(() => {
        setEditNumberModalOpen(false);
        setChangeLoading(false);
      });
  };

  return (
    <>
      <div>
        <div style={{ fontSize: '14px' }}>{isBuyer ? 'Buyer' : 'Co-Buyer'}</div>
        <div className={styles.inputContainer}>
          <PhoneNumberInput
            value={tempPhoneNumber}
            format="(###) ### ####"
            disabled={true}
            editButton={true}
            editButtonFunction={() => setEditNumberModalOpen(true)}
            inputContainerStyle={{ width: '100%' }}
            inputStyles={{ width: '100%' }}
            containerStyle={{ width: '100%' }}
          />
        </div>
        <div className={styles.bottomContainer}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <CircleSpinner color={'#FFF'} size={15} />
            </div>
          ) : (
            <Button
              buttonLabel="Send"
              disabled={loading}
              onClick={sendTextToPay}
              buttonStyles={{ height: '27.5px', width: '100%' }}
            />
          )}
        </div>
      </div>
      <DmsModal
        isOpen={editNumberModalOpen}
        title="Edit Phone Number?"
        closeFunction={() => setEditNumberModalOpen(false)}
        onAcceptLabel="Submit"
        onDeclineLabel="Cancel"
        onAcceptFunction={updatePhoneNumber}
        onDeclineFunction={onDecline}
      >
        {changeLoading ? (
          <div className={styles.changeLoading}>
            <CircleSpinner color={'#3299df'} size={50} />
          </div>
        ) : (
          <div className={styles.editPhoneNumber}>
            <div style={{ alignSelf: 'flex-start' }}>Phone Number</div>
            <PhoneNumberInput
              format="(###) ### ####"
              inputStyles={{ width: '250px' }}
              phoneNumber={tempPhoneNumber}
              onChange={(e) => setTempPhoneNumber(e.target.value)}
              minLength={10}
            />
          </div>
        )}
      </DmsModal>
    </>
  );
};

export default TextToPay;
