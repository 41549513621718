import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
// state
// utils

/** ### Displays title */
const DealsQuotesDetailHeader: FC = () => {
  return (
    <Grid>
      <Typography
        variant="h5"
        fontWeight={700}
        px={2.5}
        py={2}
        fontSize={24}
        textOverflow="ellipsis"
        noWrap
      >
        Deals and Quotes
      </Typography>
    </Grid>
  );
};

export default DealsQuotesDetailHeader;
