import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// kendo
import { Loader } from "@progress/kendo-react-all";

const sx: SxProps = { alignItems: "center", justifyContent: "center", flex: 1 };

/** ### This must be placed within a flex container */
const LgLoader: FC = () => (
  <Grid container sx={sx}>
    <Loader size="large" />
  </Grid>
);

export default LgLoader;
