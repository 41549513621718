import { DependencyList, useCallback, useEffect } from 'react';

export const useDebounceEffect = (effect: unknown, deps: DependencyList, delay = 250) => {
  const callback = useCallback(effect as never, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};
