import { FC } from "react";
// kendo
import { RadioGroupInput } from "@/components/inputs/radioGroupInput/RadioGroupInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// interfaces
import { TransactionType } from "@/enums/payment";

const options = TransactionType.options.map((type) => ({ label: type, value: type }));

/** ###  */
const TxnTypeField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("transactionType"));
  return (
    <RadioGroupInput
      label="Transaction Type"
      layout="horizontal"
      data={options}
      style={{ height: 29 }}
      {...fieldProps} // @todo fix component
    />
  );
};

export default TxnTypeField;
