import { PageSize, StandardPageSize } from '@react-pdf/types';
import { AspectRatio } from './interfaces';

export const dpi = 72;
export const pageSize: PageSize = 'A4';

/** https://en.wikipedia.org/wiki/ISO_216 */
export const aspectRatioMap: Partial<{
  [k in StandardPageSize]: { w: number; h: number; ratio: number; wPx: number; hPx: number };
}> = {
  A4: new AspectRatio(8.3, 11.7),
  // Add more as needed
};
