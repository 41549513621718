import { z } from 'zod';

export const InAtOption = z.enum(['before', 'at', 'after']);
export type InAtOption = z.infer<typeof InAtOption>;

export interface QuickActionsCommonForm {
  buyerEmail: string;
  documentsToBring: string;
  note: string;
  sendBuyerEmail: boolean;
  speakingTo: string;
}
export interface QuickActionsCommonReq {
  buyerEmail: string;
  coBuyerEmail: string;
  colRecId: number;
  dateTime: string | Date;
  documents: string;
  inAt: InAtOption;
  note?: string;
  sendBuyerEmail: boolean;
  sendCoBuyerEmail: boolean;
  speakingTo: string;
  /** @note This name is misleading: is represented in state as `appBuyerRecId`
   * @todo rename back to appBuyerRecId to avoid ambiguity
   * - this also requires updating the endpoint
   */
  userRecId: number | null;
}
