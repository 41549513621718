import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography, { TypographyProps } from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
// interfaces
import { ValidationError } from "../interfaces";

const Tg: FC<TypographyProps> = ({ children, sx, ...props }) => (
  <Typography
    component="span"
    variant="body2"
    sx={{ color: "text.secondary", display: "inline", ...sx }}
    {...props}
  >
    {children}
  </Typography>
);

const Rg: FC<TypographyProps> = ({ children, sx, ...props }) => (
  <Typography
    component="span"
    variant="body2"
    color="red"
    display="inline"
    sx={{ ...sx }}
    {...props}
  >
    {children}
  </Typography>
);

const Msg: FC<{ errorInfo: ValidationError }> = ({ errorInfo }) => (
  <ListItem alignItems="flex-start" component="span">
    <ListItemText
      primary={`Invalid '${errorInfo.label}'`}
      secondary={
        <Grid container direction="column" component="span">
          <Rg>{` — ${errorInfo.error}`}</Rg>

          {errorInfo.msg && errorInfo.msg !== "" && <Grid container direction="row" component="span" gap={1}>
            <Tg>{" — See: "}</Tg>
            <Tg>{errorInfo.msg}</Tg>
          </Grid>}
        </Grid>
      }
    />
  </ListItem>
);

const TooltipTextDisplay: FC = () => {
  const submitEvalValidationErrors = useAiUnderwritingCtx((s) => s.submitEvalValidationErrors);

  // @todo reenable in separate ticket
  // const scienapticValidation = useAiUnderwritingCtx((s) => s.scienapticValidation);
  // const errors = scienapticValidation.value?.map((item) => ValidationItem.fromRes(item));
  // const messageElems = errors?.map(errorInfo => <Msg errorInfo={errorInfo} key={errorInfo.pageLocation} />);
  // return <List component="span">{messageElems}</List>;

  const messages = submitEvalValidationErrors?.map((errorInfo) => (
    <Msg errorInfo={errorInfo} key={errorInfo.key} />
  ));

  return <List component="span">{messages}</List>;
};

/** ###  */
const TooltipLayout: FC<Omit<TooltipProps, "title">> = ({ children, sx, ...props }) => {
  const submitEvalValidationErrors = useAiUnderwritingCtx((s) => s.submitEvalValidationErrors);
  if (submitEvalValidationErrors === null) return children;

  return (
    <Tooltip
      title={<TooltipTextDisplay />}
      sx={sx}
      slots={{ tooltip: Paper }}
      slotProps={{
        tooltip: {
          sx: { mx: 0.5, my: 2, px: 2, py: 1, boxShadow: "0px 2px 5px 2px rgba(0,0,0,0.1)" },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipLayout;
