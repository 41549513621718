import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DateFormat, FormatDateOptions, formatDate } from '@/utils/helpers/general';

dayjs.extend(utc);

export const formatFormDateOpt = { pattern: DateFormat.YearMonthDayDash, utc: false };

/** ### Format the inputted date according to the standard used on the backend for forms */
export const formatFormDate = (
  date: Date,
  dateFormatOptions: FormatDateOptions = formatFormDateOpt
): string => {
  return formatDate(date.toLocaleString(), dateFormatOptions);
};

export const formatDateTz = <
  T extends Date | dayjs.Dayjs | string | null,
  U extends boolean,
  V extends U extends true ? dayjs.Dayjs : string
>(
  date: T,
  toDayjs = false as U
): V => {
  const { tzOffset, dateFormatStr } = getLocalDateFormat();

  if (toDayjs === true) {
    return dayjs(date).utcOffset(tzOffset) as V;
  }

  return dayjs(date).utcOffset(tzOffset).format(dateFormatStr) as V;
};

export const getLocalDateFormat = () => {
  const tzOffset = new Date().getTimezoneOffset();
  const tzOffsetHr = Number(tzOffset / 60);
  const tzOffsetStr = tzOffsetHr < 10 ? `0${tzOffsetHr}` : `${tzOffsetHr}`;
  const tzOffsetSign = tzOffsetHr > 0 ? '-' : '+';
  const tzOffsetFmt = `${tzOffsetSign}${tzOffsetStr}`;
  const dateFormatStr = `YYYY-MM-DDT[00:00:00${tzOffsetFmt}:00]`;
  const dateDisplayFormatStr = `M/D/YY h:mm A`;

  return { tzOffset, tzOffsetHr, tzOffsetStr, tzOffsetSign, tzOffsetFmt, dateFormatStr, dateDisplayFormatStr };
};

const { dateFormatStr } = getLocalDateFormat();

const initTodayDt_ = dayjs(new Date(dayjs(new Date()).format(dateFormatStr))).toDate();
const todayObj = {
  year: initTodayDt_.getFullYear(),
  month: initTodayDt_.getMonth(),
  day: initTodayDt_.getDate(),
};
export const initTodayDt = new Date(todayObj.year, todayObj.month, todayObj.day, 0, 0, 0);
export const newDt = (
  year: number = todayObj.year,
  month: number = todayObj.month,
  date: number = todayObj.day
) => {
  return new Date(formatDateTz(new Date(year, month, date), false) as string);
};
const getQuarterRange = (month: number) => {
  const quarterIdx = Math.floor(month / 3);
  // adjust for 0-based month values
  const quarterStart = quarterIdx * 3;
  return { start: quarterStart, end: quarterStart + 3 };
};

// days
export const todayDt = newDt(todayObj.year, todayObj.month, todayObj.day);
const yesterdayDt = newDt(todayObj.year, todayObj.month, todayObj.day - 1);
const tomorrowDt = newDt(todayObj.year, todayObj.month, todayObj.day + 1);
// weeks
const thisWeekFirstDay = todayObj.day - todayDt.getDay();
const thisWeekFirstDayDt = newDt(todayObj.year, todayObj.month, thisWeekFirstDay);
const thisWeekLastDay = thisWeekFirstDay + 6;
const thisWeekLastDayDt = newDt(todayObj.year, todayObj.month, thisWeekLastDay);
const lastWeekFirstDay = thisWeekFirstDay - 7;
const lastWeekFirstDayDt = newDt(todayObj.year, todayObj.month, lastWeekFirstDay);
const lastWeekLastDay = lastWeekFirstDay + 6;
const lastWeekLastDayDt = newDt(todayObj.year, todayObj.month, lastWeekLastDay);
// const twoWeeksAgoDt = newDt(todayObj.year, todayObj.month, first - 7);
// months
const thisMonthStartDt = newDt(todayObj.year, todayObj.month, 1);
const thisMonthEndDt = newDt(todayObj.year, todayObj.month + 1, 0);
const lastMonthStartDt = newDt(todayObj.year, todayObj.month - 1, 1);
const lastMonthEndDt = newDt(todayObj.year, todayObj.month, 0);
const thisYearStartDt = newDt(todayObj.year, 0, 1);
const thisYearEndDt = newDt(todayObj.year + 1, 0, 0);
const lastYearStartDt = newDt(todayObj.year - 1, 0, 1);
const lastYearEndDt = newDt(todayObj.year, 0, 0);
// Quarters
const { start: thisQuarterStartMonth, end: thisQuarterEndMonth } = getQuarterRange(todayObj.month);
const { start: lastQuarterStartMonth, end: lastQuarterEndMonth } = getQuarterRange(
  thisQuarterStartMonth - 1
);
const thisQuarterStartDt = newDt(todayObj.year, thisQuarterStartMonth, 1);
const thisQuarterEndDt = newDt(todayObj.year, thisQuarterEndMonth, 0);
const lastQuarterStartDt = newDt(todayObj.year, lastQuarterStartMonth, 1);
const lastQuarterEndDt = newDt(todayObj.year, lastQuarterEndMonth, 0);

export const dateRangeOptions = {
  Today: {
    beginDate: todayDt,
    endDate: todayDt,
  },
  Yesterday: {
    beginDate: yesterdayDt,
    endDate: yesterdayDt,
  },
  Tomorrow: {
    beginDate: tomorrowDt,
    endDate: tomorrowDt,
  },
  'This Week': {
    beginDate: thisWeekFirstDayDt,
    endDate: thisWeekLastDayDt,
  },
  'This Week-to-date': {
    beginDate: thisWeekFirstDayDt,
    endDate: todayDt,
  },
  'Last Week': {
    beginDate: lastWeekFirstDayDt,
    endDate: lastWeekLastDayDt,
  },
  'This Month': {
    beginDate: thisMonthStartDt,
    endDate: thisMonthEndDt,
  },
  'This Month-to-date': {
    beginDate: thisMonthStartDt,
    endDate: todayDt,
  },
  'Last Month': {
    beginDate: lastMonthStartDt,
    endDate: lastMonthEndDt,
  },
  'This Year': {
    beginDate: thisYearStartDt,
    endDate: thisYearEndDt,
  },
  'This Year-to-date': {
    beginDate: thisYearStartDt,
    endDate: todayDt,
  },
  'Last Year': {
    beginDate: lastYearStartDt,
    endDate: lastYearEndDt,
  },
  'This Quarter': {
    beginDate: thisQuarterStartDt,
    endDate: thisQuarterEndDt,
  },
  'This Quarter-to-date': {
    beginDate: thisQuarterStartDt,
    endDate: todayDt,
  },
  'Last Quarter': {
    beginDate: lastQuarterStartDt,
    endDate: lastQuarterEndDt,
  },
  'All History': {
    beginDate: newDt(1970, 0, 1),
    endDate: todayDt,
  },
  Custom: null,
};
export type DateRangeOptionKey = keyof typeof dateRangeOptions;
// Order not guaranteed
export const dateRangeOptionsArr = Object.entries(dateRangeOptions).map(([label, range]) => {
  return { label, range };
});
// Doing it this way to guarantee order is (at least) consistent with dateRangeOptionsArr
export const dateRangeOptionLabels = dateRangeOptionsArr.map(({ label }) => label);
