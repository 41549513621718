import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import MuiCheckboxField from "./MuiCheckboxField";
// components
import CliqField from "./CliqField";
// state
import { useCliqCtx } from "../CliqProvider";
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";

/** ### Cliq form layout */
const CliqForm: FC = () => {
  const { formState: pmtFormState, isAch } = usePmtFormCtx((s) => s);
  const cliqFormState = useCliqCtx((s) => s.cliqFormState);
  const fieldMap = useCliqCtx((s) => s.appliedConfig.fieldMap);

  const formFieldElems: JSX.Element[] = [];
  fieldMap.forEach((v, k) => {
    formFieldElems.push(
      <CliqField
        label={v?.title}
        fieldId={k}
        error={!cliqFormState.getFieldProps(k).value}
        key={k}
      />
    );
  });

  return (
    <Grid container direction="column" gap={2}>
      {formFieldElems}

      {!pmtFormState.config.external.isMiscPmt && <MuiCheckboxField
        label={`Save ${isAch ? "Account" : "Card"}`}
        {...pmtFormState.getFieldProps("saveCard")}
      />}
    </Grid>
  );
};

export default CliqForm;
