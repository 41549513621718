import { FC } from 'react';
import { useBlocker, useParams } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import ConfirmationModal from '@/mui/components/ConfirmationModal';
// components
import GeneralInfoSection from './GeneralInfoSection';
import FinancialInfoSection from './FinancialInfoSection';
import ReversalInfoSection from './ReversalInfoSection';
import FormSubmitButtonGroup from './SubmitButtonGroup';
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';
import { usePmtReversalCtx } from '../../PmtReversalProvider';
// utils
import { paymentService } from '@/services/paymentService';
import NavBlock from '@/utils/forms/NavBlock';
import { useNavUp } from '@/utils/routing/useNavUpEvent';
// interfaces
import {
  ReversiblePayment,
  ReversiblePaymentForm,
  ReversiblePaymentPostReq,
} from '../../interfaces';

/** ###  */
const PmtReversalForm: FC = () => {
  const navUp = useNavUp();
  const colRecId = Number(useParams().colRecId);
  const pmtRecId = Number(useParams().pmtRecId);
  const selectedPmt = usePmtReversalDetailCtx((s) => s.selectedPmt);
  const setRows = usePmtReversalCtx((s) => s.setRows);
  const isConfirmOpen = usePmtReversalDetailCtx((s) => s.isConfirmOpen);
  const setIsConfirmOpen = usePmtReversalDetailCtx((s) => s.setIsConfirmOpen);

  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = usePmtReversalDetailCtx((s) => s.pmtForm);
  const isSystemOnly = usePmtReversalDetailCtx((s) => s.pmtForm.watch().systemOnly);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });

  // Event handlers
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);
  const handleFormSubmit = async (pmtFormBody: ReversiblePaymentForm) => {
    // Validate
    const payload = new ReversiblePaymentPostReq(pmtFormBody, selectedPmt, { colRecId, pmtRecId });

    try {
      const pmtReversalRes = await paymentService.submitPaymentReversal(payload);
      setRows(pmtReversalRes.map((pmt) => new ReversiblePayment(pmt)));
      navUp();

      // Error is handled in request method
    } catch {}
    handleCloseConfirmationModal();
  };

  const refundMsg = "The FULL AMOUNT will be refunded to the customer's card.";
  const systemOnlyMsg =
    selectedPmt?.paidBy === 'Credit Card'
      ? "Money will NOT be put back onto the customer's card."
      : '';
  const modalMsg = isSystemOnly ? systemOnlyMsg : refundMsg;

  return (
    <Grid
      container
      direction="column"
      onSubmit={handleSubmit(handleFormSubmit)}
      gap={3}
      flex={1}
      component="form"
    >
      <Grid container direction="column" gap={4} flex={1}>
        <GeneralInfoSection />
        <FinancialInfoSection />
        <ReversalInfoSection />
      </Grid>

      <FormSubmitButtonGroup />

      <NavBlock blocker={blocker} />
      <ConfirmationModal
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        title="Confirm Payment Reversal"
        message={modalMsg}
        onConfirm={handleSubmit(handleFormSubmit)}
        isSubmitting={isSubmitting}
      />
    </Grid>
  );
};

export default PmtReversalForm;
