// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { FC } from "react";
// kendo
// style
import { usePmtFormCtx } from "../../accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
import PaidInField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/PaidInField";
import ProviderField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/ProviderField";
import PaidByField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/PaidByField";
import TotalReceivedField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/TotalReceivedField";
import WaiveConvFeeField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/WaiveConvFeeField";
import TotalPmtField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/TotalPmtField";
import MiscCategoryField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/MiscCategoryField";
import PayToFromField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormLeftColumn/PayToFromField";
//mui

const ChangeDueField: FC = () => {
  const changeDue = usePmtFormCtx((s) => s.formState.config.calculated.changeDue);
  return <CurrencyInput label="Change Due" readOnly value={changeDue} />;
};

/** ###  */
const FormLeftColumn: FC = () => (
  <Grid container direction="column" sx={{ width: "500px", gap: "10px" }}>
    <ProviderField />
    <PaidInField />
    <TotalPmtField />
    <PaidByField />
    <WaiveConvFeeField />
    <TotalReceivedField />
    <ChangeDueField />
    <PayToFromField />
    <MiscCategoryField />
  </Grid>
);

export default FormLeftColumn;
