import { AxiosResponse } from 'axios';
import { AxiosService } from './axiosService';
import { GetReportExportResponse } from '@/interfaces';
import { IReport } from '@/interfaces/Reports';
import {
  ExportFileType,
  IModalFilterParams,
  ReportsGetPgRes,
  newReportExportReqBody,
} from '@/features/Reports/interfaces';
import { displayErrors } from '@/utils/helpers/general';
import {
  ERSDb,
  ERSPostReq,
  ERSPostRes,
  ERSPutReq,
  ERSPutRes,
  ERSDeleteRes,
  ERSFetchAllRes,
} from '@/features/Reports/ERSDetailView/interfaces';
import { IPaginatedReq } from '@/interfaces/requests';

class ReportsService extends AxiosService {
  public constructor() {
    super();
  }

  /** ### Fetch list of reports - paginated
   * Makes a request at endpoint: `/Reports/GetReportsSubviewList`.
   */
  async GetReportsSubviewList(req: IPaginatedReq): Promise<ReportsGetPgRes> {
    try {
      const { data: response } = await this.axios.post<AxiosResponse<ReportsGetPgRes>>(
        '/Reports/GetReportsSubviewList',
        req
      );
      return response.data;
    } catch (err: any) {
      throw displayErrors(err.message);
    }
  }
  /** ### Request a report export in the form of a file (pdf/xlsx/csv)
   *
   * - Transform `filterParams` input (from modal field) into valid body that backend accepts.
   * - If `filterParams` is undefined, return full report.
   * - Returns the URL of the exported file
   */
  async fetchExport(
    report: IReport,
    fileType: ExportFileType,
    filterParams: IModalFilterParams | null = null
  ): Promise<string> {
    // Nullify unused fields
    const { useAmt, useInt, useDateRange } = report;

    let beginDatePayload: string | null = null;
    let endDatePayload: string | null = null;
    if (useDateRange && filterParams !== null) {
      beginDatePayload = filterParams && filterParams.beginDate;
      endDatePayload = filterParams && filterParams.endDate;
    }
    let amtPayload: number | null = null;
    if (useAmt && filterParams !== null) {
      amtPayload = filterParams.amt;
    }
    let intPayload: number | null = null;
    if (useInt && filterParams !== null) {
      intPayload = filterParams.int;
    }
    // const amt
    const reqBody = newReportExportReqBody({
      reportRecId: report.recId,
      outputType: fileType,
      startDateParam: beginDatePayload,
      endDateParam: endDatePayload,
      amtParam: amtPayload,
      intParam: intPayload,
    });

    try {
      const {
        data: { data: exportUrl },
      } = await this.axios.post<GetReportExportResponse>('/Reports/PrintPaginatedReport', reqBody);
      return exportUrl;
    } catch (err: any) {
      throw displayErrors(err.message);
    }
  }
  /** ### Fetch list of email report schedules */
  async fetchEmailReportSchedules(): Promise<ERSFetchAllRes> {
    try {
      const {
        data: {
          data: { companies, emailReportSchedules },
        },
      } = await this.axios.get<ERSFetchAllRes>('/Reports/EmailReportSchedules');
      const data: ERSFetchAllRes = {
        total: emailReportSchedules.length,
        data: { companies, emailReportSchedules },
      };

      return data;
    } catch (err: any) {
      throw displayErrors(err.message);
    }
  }
  async updateEmailReportSchedule(
    ersRecId: ERSDb['recId'],
    reqPayload: ERSPutReq
  ): Promise<AxiosResponse<ERSPutRes>> {
    try {
      const res = await this.axios.put<any, AxiosResponse<ERSPutRes>, ERSPutReq>(
        `/Reports/EmailReportSchedules/${ersRecId}`,
        reqPayload
      );

      return res;
    } catch (err: any) {
      // @todo handle error properly
      console.error(err);
      throw displayErrors(err.message);
    }
  }
  async createEmailReportSchedule(reqPayload: ERSPostReq): Promise<AxiosResponse<ERSPostRes>> {
    try {
      const res = await this.axios.post<any, AxiosResponse<ERSPostRes>, ERSPostReq>(
        '/Reports/EmailReportSchedules',
        reqPayload
      );

      return res;
    } catch (err: any) {
      throw displayErrors(err.message);
    }
  }
  /** @deprecated not implemented */
  async deleteEmailReportSchedule(ersRecId: ERSDb['recId']): Promise<AxiosResponse<ERSDeleteRes>> {
    try {
      const res = await this.axios.delete<any, AxiosResponse<ERSDeleteRes>, ERSDb['recId']>(
        `/Reports/EmailReportSchedules/${ersRecId}`
      );

      return res;
    } catch (err: any) {
      throw displayErrors(err.message);
    }
  }
}

export const reportsService = new ReportsService();
