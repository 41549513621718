import { Button } from "@/components";
import { FC, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { salesService } from "@/services/salesService";
import { useSidenoteCtx } from "./SidenoteProvider";

interface PrintButtonProps {
  isDisabled: boolean;
}

export const PrintButton: FC<PrintButtonProps> = ({ isDisabled }) => {
  const activeSidenote = useSidenoteCtx((s) => s.activeSidenote);
  const [printLoading, setPrintLoading] = useState<boolean>(false);

  const handlePrint = async (appRecId: number) => {
    setPrintLoading(true);
    let formIds: number;
    formIds = activeSidenote?.form_Sn_Id ? activeSidenote.form_Sn_Id : 172;
    const sidenoteDoc = await salesService.printSalesDoc({
      formIds: [formIds],
      appRecId,
    });
    window.open(sidenoteDoc);
    setPrintLoading(false);
  };

  return (
    <Grid2 container direction="row" flex={1} justifyContent="flex-start" pr="32px">
      <Button
        label="Print Sidenote"
        type="button"
        secondary
        disabled={isDisabled}
        onClick={() => handlePrint(activeSidenote?.appRecId!)}
        buttonStyle={{ width: "134px", margin: "0px 0px 10px 0px" }}
        loading={printLoading}
      />
    </Grid2>
  );
};
