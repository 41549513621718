import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { WsCompany } from '../../interfaces';

export interface IWholesaleCompanyFormCtx {
  wsCompanyRes: WsCompany | null;
  setWsCompanyRes: (wsCompanyRes: WsCompany | null) => void;
  setWsCompanyResFromArr: (
    wsCompanyRecId: number | null | undefined,
    wsCompanyList: WsCompany[]
  ) => void;
  wsCompanyForm: WsCompany | null;
  setWsCompanyForm: (wsCompany: WsCompany | null) => void;
  setWsCompanyFormField<T extends keyof WsCompany = keyof WsCompany>(
    field: T,
    value: WsCompany[T]
  ): void;
  isFormLoaded: boolean;
  isFormEdit: boolean;
  isFormDirty: boolean;
}

const WholesaleCompanyFormCtx = createContext<IWholesaleCompanyFormCtx | null>(null);

const WholesaleCompanyFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [wsCompanyRes, setWsCompanyRes_] = useState<WsCompany | null>(null);
  const [wsCompanyForm, setWsCompanyForm] = useState<WsCompany | null>(null);

  const setWsCompanyResFromArr = (
    wsCompanyRecId: number | null | undefined,
    wsCompanyList: WsCompany[]
  ) => {
    const wsCompany = wsCompanyList.find((c) => c.recId === wsCompanyRecId) || null;
    if (!wsCompany) throw new Error(`No Wholesale Company found for recId: ${wsCompanyRecId}`);
    setWsCompanyRes(wsCompany);
  };

  const setWsCompanyRes = (wsCompanyRes: WsCompany | null) => {
    setWsCompanyRes_(wsCompanyRes);
    setWsCompanyForm(wsCompanyRes || new WsCompany());
  };

  const setWsCompanyFormField = <T extends keyof WsCompany>(field: T, value: WsCompany[T]) => {
    const updatedWsCompanyForm = { ...wsCompanyForm, [field]: value };
    setWsCompanyForm(updatedWsCompanyForm);
  };

  return (
    <WholesaleCompanyFormCtx.Provider
      value={{
        wsCompanyRes,
        setWsCompanyRes,
        setWsCompanyResFromArr,
        wsCompanyForm,
        setWsCompanyForm,
        setWsCompanyFormField,
        get isFormLoaded(): boolean {
          return wsCompanyForm !== null;
        },
        get isFormEdit(): boolean {
          return wsCompanyRes !== null && !isNaN(Number(wsCompanyRes.recId));
        },
        get isFormDirty(): boolean {
          if (wsCompanyRes === null && wsCompanyForm !== null) {
            let isFieldDirty = false;
            for (const key in wsCompanyForm) {
              if (Object.prototype.hasOwnProperty.call(wsCompanyForm, key)) {
                const val = wsCompanyForm[key as keyof WsCompany];
                isFieldDirty = val !== null && val !== undefined && val !== '';
                if (isFieldDirty === true) break;
              }
            }
            return isFieldDirty === true;
          }

          return (
            wsCompanyRes?.address !== wsCompanyForm?.address ||
            wsCompanyRes?.city !== wsCompanyForm?.city ||
            wsCompanyRes?.companyName !== wsCompanyForm?.companyName ||
            wsCompanyRes?.contactEmail !== wsCompanyForm?.contactEmail ||
            wsCompanyRes?.contactName !== wsCompanyForm?.contactName ||
            wsCompanyRes?.contactPhone !== wsCompanyForm?.contactPhone ||
            wsCompanyRes?.county !== wsCompanyForm?.county ||
            wsCompanyRes?.fax !== wsCompanyForm?.fax ||
            wsCompanyRes?.phone !== wsCompanyForm?.phone ||
            wsCompanyRes?.recId !== wsCompanyForm?.recId ||
            wsCompanyRes?.state !== wsCompanyForm?.state ||
            wsCompanyRes?.taxId !== wsCompanyForm?.taxId ||
            wsCompanyRes?.zip !== wsCompanyForm?.zip
          );
        },
      }}
    >
      {children}
    </WholesaleCompanyFormCtx.Provider>
  );
};

export default WholesaleCompanyFormProvider;

export const useWholesaleCompanyFormCtx = <T,>(
  selector: (state: IWholesaleCompanyFormCtx) => T
): T => {
  const ctx = useContext(WholesaleCompanyFormCtx);
  if (!ctx) {
    throw new Error('useWholesaleCompanyFormCtx must be used within WholesaleCompanyFormProvider');
  }
  return selector(ctx);
};
