import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
import { IReport } from '@/interfaces/Reports';
import { IFormErrors, IModalFilterParams, ReportCategoryKey } from '../interfaces';

export interface IReportsContext {
  // Table variables
  reports: IReport[];
  setReports: (reports: IReport[]) => void;
  reportsMap: Map<IReport['recId'], IReport['reportNameOrg']>;
  // Modal variables
  selectedReport: IReport | null;
  setSelectedReport: (selectedReport: IReport | null) => void;
  modalFilterParams: IModalFilterParams | null;
  setModalFilterParams: (modalFilterParams: IModalFilterParams | null) => void;
  doesReportHaveFilters: (report: IReport) => boolean;
  formErrors: IFormErrors;
  setFormErrors: (field: keyof IFormErrors, newValue: boolean) => void;
}

const doesReportHaveFilters = (report: IReport): boolean => {
  return report.useDateRange || report.useAmt || report.useInt;
};
const ReportsContext = createContext<IReportsContext | null>(null);

export const ReportsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Table variables
  const [reports, setReports] = useState<IReport[]>([]);
  // Modal variables
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [modalFilterParams, setModalFilterParams] = useState<IModalFilterParams | null>(null);
  const [formErrors, setFormErrorsInit] = useState<IFormErrors>({
    date: false,
    int: false,
    amt: false,
  });

  const setFormErrors = (field: keyof IFormErrors, newValue: boolean) => {
    const updatedFormErrors = { ...formErrors, [field]: newValue };
    setFormErrorsInit(updatedFormErrors);
  };

  // actions
  return (
    <ReportsContext.Provider
      value={{
        // Table variables
        reports,
        setReports,
        get reportsMap(): Map<IReport['recId'], IReport['reportNameOrg']> {
          return new Map<IReport['recId'], IReport['reportNameOrg']>(
            reports.map((c) => [c.recId, c.reportNameOrg])
          );
        },
        // Modal variables
        selectedReport,
        setSelectedReport,
        modalFilterParams,
        setModalFilterParams,
        doesReportHaveFilters,
        formErrors,
        setFormErrors,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsCtx = <T,>(selector: (state: IReportsContext) => T): T => {
  const ctx = useContext(ReportsContext);
  if (!ctx) throw new Error('useReportsTableContext must be used within TableProvider');

  return selector(ctx);
};