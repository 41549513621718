import { FC } from "react";
// mui
import Skeleton from "@mui/material/Skeleton";
import TextField, { TextFieldProps } from "@mui/material/TextField";
// state
import { useCliqCtx } from "../CliqProvider";

const LoadingOverlay: FC = () => {
  const isLoading = useCliqCtx((s) => s.isLoading);

  if (!isLoading) return <></>;

  return (
    <Skeleton
      animation="wave"
      sx={{
        position: "absolute",
        transform: "none",
        transformOrigin: "unset",
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        borderRadius: "inherit",
      }}
    />
  );
};

/** ###  */
const CliqField: FC<TextFieldProps & { label?: string; fieldId: string }> = ({
  label,
  fieldId,
  InputLabelProps,
  InputProps,
  sx,
  ...props
}) => (
  <TextField
    label={label}
    required
    InputLabelProps={{ shrink: true, sx: { fontSize: 14 }, ...InputLabelProps }}
    InputProps={{
      id: fieldId,
      name: fieldId,
      className: "input-field",
      type: "tel",
      inputMode: "numeric",
      inputComponent: "small",
      sx: {
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: "300",
      },
      endAdornment: <LoadingOverlay />,
      ...InputProps,
    }}
    sx={{ flex: 1, ...sx }}
    {...props}
  />
);

export default CliqField;
