import { ExpensesGrid } from './expensesGrid/expensesGrid';
import { useParams } from 'react-router-dom';
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import { PageHeader } from '@/components/pageHeader/PageHeader';
import { useCallback, useEffect, useState } from 'react';
import { inventoryService } from '@/services/inventoryService';

export const InventoryExpenses = () => {
  const { invRecId } = useParams();
  const [saleStatus, setSaleStatus] = useState<string>('');
  const [stockNum, setStockNum] = useState<string>('');

  const loadSaleStatus = useCallback(async () => {
    if (!invRecId) {
      return;
    }

    const response = await inventoryService.getVehicleDetails(invRecId);
    setSaleStatus(response?.saleStatus ?? '');
    setStockNum(response?.stockNum ?? '');
  }, [invRecId]);

  useEffect(() => {
    loadSaleStatus();
  }, [loadSaleStatus]);

  if (!invRecId) {
    return <></>;
  }

  return (
    <div className={inventoryPageStyles.container}>
      <div className={inventoryPageStyles.header}>
        <PageHeader headerLabel="Expenses" />
      </div>
      <div className={inventoryPageStyles.body}>
        <div className={inventoryPageStyles.bodyContainer}>
          <div className={inventoryPageStyles.bodyChildMultipleSections}>
            <ExpensesGrid
              recId={invRecId}
              type="Current"
              saleStatus={saleStatus}
              vehRecId={invRecId}
              stockNum={stockNum}
            />
            <ExpensesGrid
              recId={invRecId}
              type="Historical"
              saleStatus={saleStatus}
              vehRecId={invRecId}
              stockNum={stockNum}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
