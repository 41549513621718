import { createRef } from 'react';
import dayjs from 'dayjs';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from '@/components';
import { Collection } from '@/services/collectionsService';
import { GridColumnProps } from '../../QueueRunList/interfaces';

interface CollectionsExportProps {
  collections: Collection[];
  columns: GridColumnProps[];
  title: string;
}

const CollectionsExport = (props: CollectionsExportProps) => {
  const { collections, columns, title } = props;
  const _exporter = createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      const options = _exporter.current.workbookOptions();

      if (!options.sheets) return;

      const rows = options.sheets[0]!.rows;

      const userNameRow = {
        cells: [
          {
            value: title.toUpperCase(),
            colSpan: 4,
            background: '#7a7a7a',
            color: '#fff',
            bold: true,
            verticalAlign: 'center',
          } as any,
        ],
        height: 30,
      };

      if (!rows) return;

      rows.unshift(userNameRow);

      _exporter.current.save(options);
    }
  };

  const data = collections.map((collection) => {
    return {
      ...collection,
      buyers: collection.buyers.map((buyer) => buyer).join('; '),
    };
  });

  const date = dayjs().format('YYYY-MM-DD');

  const filename = `Collections${date}.xlsx`;

  return (
    <>
      <Button label="Export" onClick={excelExport} />

      <ExcelExport data={data} fileName={filename} ref={_exporter}>
        {columns
          .filter((x) => x.display)
          .filter((x) => x.field !== 'selected')
          .map((column) => {
            return (
              <ExcelExportColumn field={column.field} title={column.title} key={column.field} />
            );
          })}
      </ExcelExport>
    </>
  );
};

export default CollectionsExport;
