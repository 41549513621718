import { FC } from 'react';
// components
import InventorySelector from './InventorySelector';
import AddNewInventoryButton from './AddNewInventoryButton';
import AddNewInventoryForm from './AddNewInventoryForm';
// utils
import { useInventoryViewCtx } from '../state';
import { inventoryTypesArr } from '../inventoryDetails/default';
// style
import styles from '../ListStyles.module.scss';

const HeaderTitle: FC = () => {
  const subview = useInventoryViewCtx((s) => s.subview);
  const listLength = useInventoryViewCtx((s) => s.listLength);

  if (!inventoryTypesArr) return <></>;
  const view = inventoryTypesArr.find((it) => it.id === subview);
  if (!view) return <></>;
  const viewTitle = view!.text;

  return (
    <h1 className={styles.header}>
      {`Inventory - ${viewTitle}: `}
      <span className={styles.listLength}>{`(${listLength})`}</span>
    </h1>
  );
};

/** ### Header component for "Inventory" view
 *
 * Contains:
 * - Title
 * - Add New Inventory Button
 * - Inventory-type selector
 */
const Header: FC = () => {
  const subview = useInventoryViewCtx((s) => s.subview);
  const mappedCompaniesCt = useInventoryViewCtx((s) => s.mappedCompaniesCt);
  const isModalOpen = useInventoryViewCtx((s) => s.isModalOpen);

  return (
    <>
      <div className={styles.topBar}>
        <HeaderTitle />
        <div className={styles.buttonGroup}>
          {subview === 'unsold' && mappedCompaniesCt === 1 && <AddNewInventoryButton />}
          <InventorySelector />
        </div>
      </div>
      {isModalOpen && <AddNewInventoryForm />}
    </>
  );
};

export default Header;
