import { FC, MutableRefObject } from "react";
import { NavLink, NavLinkProps, useLocation, useMatch } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// style
import { DCS_BLUE, HOVER_TRANSITION_SPEED, TEXT_PRIMARY1 } from "@/mui/theme/colors";
import { accountActions, useAccountSelector } from "@/features/Accounts/accountSlice";
import { useAppDispatch } from "@/store/store";
import { AccountTabRoutes } from "../../interfaces";

const routes = [
  AccountTabRoutes.PaymentHistory,
  AccountTabRoutes.LeaseAmendment,
  AccountTabRoutes.Gps,
  AccountTabRoutes.OdometerTracking,
  AccountTabRoutes.CpiRefund,
  AccountTabRoutes.PrincipalPayment,
  AccountTabRoutes.SavedPaymentMethods,
  AccountTabRoutes.Cbr,
  AccountTabRoutes.LegalAccount,
  AccountTabRoutes.ChargeOff,
  AccountTabRoutes.ReactivateChargeOff,
  AccountTabRoutes.PaymentReversal,
  AccountTabRoutes.CustomFields,
  AccountTabRoutes.OutForRepo,
];

const borderSizePx = "4px";
const sxInit: SxProps = {
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: `${borderSizePx} 18px 0px 18px`,
  margin: "0 5px",
  borderBottom: `${borderSizePx} solid transparent`,
  // @ts-ignore
  userSelect: "none !important",
  textDecoration: "none",
  transitionProperty: "border-bottom-color, color",
  transitionDuration: HOVER_TRANSITION_SPEED,
  "&:hover": { color: DCS_BLUE },
};

const ManageTab: FC<{ name: string; anchorRef?: MutableRefObject<null> }> = ({
  name,
  anchorRef,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isOpen = useAccountSelector((s) => s.customerAdminListOpen);
  const isActive = isOpen || routes.find((path) => pathname.endsWith(path));

  const sx: SxProps = {
    ...sxInit,
    borderBottomColor: isActive ? DCS_BLUE : "transparent",
    color: isActive ? DCS_BLUE : TEXT_PRIMARY1,
    cursor: "pointer",
  };
  const handleClick = () => dispatch(accountActions.setCustomerAdminListOpen(!isOpen));

  return (
    <Grid container sx={sx} onClick={handleClick} ref={anchorRef}>
      {name}
    </Grid>
  );
};

/** ###  */
const NavTab: FC<
  Omit<NavLinkProps, "to"> & { name: string; to?: string; anchorRef?: MutableRefObject<null> }
> = ({ name, anchorRef, ...navLinkProps }) => {
  const activeTabMatch = useMatch({ path: "/accounts/:recId/" });
  const isAcctActiveSubview = navLinkProps.to === "" && activeTabMatch !== null;
  const subview = useMatch({ path: "/accounts/:recId/:subview/*" })?.params.subview;

  const isActive = isAcctActiveSubview || navLinkProps.to === subview;

  const sx: SxProps = {
    ...sxInit,
    borderBottomColor: isActive ? DCS_BLUE : "transparent",
    color: isActive ? DCS_BLUE : TEXT_PRIMARY1,
  };

  return navLinkProps.to !== undefined ? (
    <Grid container component={NavLink} {...navLinkProps} to={navLinkProps.to} sx={sx}>
      {name}
    </Grid>
  ) : (
    <ManageTab name={name} anchorRef={anchorRef} />
  );
};

export default NavTab;
