import { FC } from 'react';
// kendo
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
  Input,
  Loader,
  GridPageChangeEvent,
  GridSortChangeEvent,
  PagerTargetEvent,
  DropDownList,
} from '@progress/kendo-react-all';
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
} from '@progress/kendo-data-query';
import { SortKendo, IKendoPaginationState } from '@/interfaces/kendo';
import { Button } from '@/components';
// utils
import { useReportsViewCtx } from './state/view';
import { useTableCtx } from './state/table';


const paginationSettings = {
  buttonCount: 4,
  pageSizes: [25, 50, 100],
};

// eslint-disable-next-line
const DropdownFilterCell: FC = (props: any) => {
  const { column, onChange, value } = props;

  if (!column.filter) {
    return null;
  }

  // custom logic
  return (
    <DropDownList
      data={column.filter.options}
      onChange={(e) => onChange({ value: e.target.value })}
      value={value}
    />
  );
};

export const handlePageChange =
  (setPageSizeValue: (p: number) => void, setPage: (p: IKendoPaginationState) => void) =>
  (e: GridPageChangeEvent) => {
    const targetEvent = e.targetEvent as PagerTargetEvent;
    const take = e.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({ ...e.page, take });
  };
/** ### Update sort on click - triggers pagination request */
export const handleSortChange = 
<SortKey,>(setSort: (s: SortKendo<SortKey>[]) => void) => (e: GridSortChangeEvent) => {
  let updatedSort = [...e.sort];

  if (updatedSort.length > 2) {
    updatedSort.shift();
  } else {
    updatedSort = updatedSort.filter((s) => s.dir !== undefined);
  }

  setSort(updatedSort as SortKendo<SortKey>[]);
};

/** ### Data-table/grid which displays data from the `Reports` database table
 * @note This component should **only** be used for any subview that fetches data from the `Reports` table.
 * */
const ReportsViewTable: FC<{
  // eslint-disable-next-line
  rows: any[];
  searchFilterPlaceholder?: string;
  disableSearchFilter?: boolean;
  filterComponent?: JSX.Element;
  buttonComponent?: JSX.Element;
  disableClientSidePaginationSortFiltering?: boolean;
  disableClientSidePagination?: boolean;
  disableClientSideSorting?: boolean;
  disableClientSideFiltering?: boolean;
}> = ({
  rows = [],
  searchFilterPlaceholder = 'Search filter',
  disableSearchFilter = false,
  filterComponent,
  buttonComponent,
  disableClientSidePaginationSortFiltering = false,
}) => {
  const isLoading = useReportsViewCtx((s) => s.isLoading);
  const dbCt = useReportsViewCtx((s) => s.dbCt);
  const tableColumns = useReportsViewCtx((s) => s.viewInfo.columns);
  const setDbCt = useReportsViewCtx((s) => s.setDbCt);
  // Table state
  const {
    filters,
    categoryFilters,
    sort,
    setSort,
    sortClientSide,
    setSortClientSide,
    page,
    setPage,
    pageSizeValue,
    setPageSizeValue,
    textSearch,
    setTextSearch,
    textFilter,
    setTextFilter
  } = useTableCtx((s) => s);

  // Add text filters
  const textFilterArr = tableColumns
    .filter((c) => c.filter === 'text')
    .map((c) => {
      return { field: c.field, value: textSearch, operator: 'contains', ignoreCase: true };
    });
  const textFilters: CompositeFilterDescriptor = { logic: 'or', filters: textFilterArr };
  //const reportCategoryFilters: CompositeFilterDescriptor = { logic: 'or', filters: categoryFilters };

  const tableFilters = {
    ...filters,
    filters: disableClientSidePaginationSortFiltering ? 
              [...filters.filters ] :
              [...filters.filters, textFilters]
  };

  // @note for client-side rendering - remove when switching to serverside
  // Manipulate rows
  const filteredData = rows.length > 0 ? filterBy(rows, tableFilters) : rows;
  setDbCt(disableClientSidePaginationSortFiltering ? dbCt : filteredData.length );
  const dataPage =
    filteredData.length > 0 && !disableClientSidePaginationSortFiltering ? filteredData.slice(page.skip, page.take! + page.skip!) : filteredData;
  const orderedData = dataPage.length > 0 && !disableClientSidePaginationSortFiltering ? orderBy(dataPage, sortClientSide) : dataPage;

  // Build table columns
  const tableColumnElems = tableColumns.map((column, idx) => {
    const filterConfig = column.filter ? { filter: column.filter } : {};

    return (
      <GridColumn
        field={column.field}
        title={column.title}
        width={column.width}
        cell={column.cell}
        // @note this does not work
        // @ts-ignore
        cells={(props) => <DropdownFilterCell {...props} column={column} />} // Use custom filter cell
        filter={column.filter}
        columnMenu={column.columnMenu}
        {...filterConfig}
        key={`tcol-${idx}]${column.field}`}
      />
    );
  });

  return (
    <Grid
      data={orderedData}
      sortable={{ mode: 'single' }}
      sort={disableClientSidePaginationSortFiltering ? sort : sortClientSide}
      onSortChange={handleSortChange(disableClientSidePaginationSortFiltering ? setSort : setSortClientSide)}
      pageable={{ ...paginationSettings, pageSizeValue }}
      onPageChange={handlePageChange(setPageSizeValue, setPage)}
      skip={page.skip}
      take={page.take}
      total={dbCt}
      // @note for client-side rendering - remove when switching to serverside
    >
      <GridToolbar>
        <Input
          style={{ minWidth: '200px' }}
          onChange={(e) => disableClientSidePaginationSortFiltering ? setTextFilter(e.value): setTextSearch(e.value)}
          placeholder={searchFilterPlaceholder}
          disabled={disableSearchFilter}
          value={disableClientSidePaginationSortFiltering ? textFilter : textSearch }
        />
        {filterComponent || <></>}
        {/* @note temporary - spacer to push the button to other side since the toolbar styling isn't easily accessible. */}
        <div style={{ flex: 1 }}></div>
        {/* @note temporary - kendo header adjusts height when button is not rendered- inconsistent height when switching to non-report tables */}
        {buttonComponent || <Button label="-" style={{ opacity: 0 }} disabled />}
      </GridToolbar>
      <GridNoRecords>{isLoading ? <Loader size="large" /> : 'No reports found'}</GridNoRecords>

      {tableColumnElems}
    </Grid>
  );
};

export default ReportsViewTable;
