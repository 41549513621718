import { SalesSubview } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  OutsideFundingFilterKey,
  OutsideFundingSortKey,
  outsideFundingFilterFields,
  outsideFundingSortFields,
} from './dataModelConfig';
import { OutsideFundingDb } from './interfaces';
import { outsideFundingTableColumns } from './tableColumns';

export const outsideFundingSubviewConfig: SubviewInfo<
  OutsideFundingSortKey,
  OutsideFundingFilterKey
> = {
  text: 'Outside Funding',
  route: SalesSubview.enum.outsideFunding,
  columns: outsideFundingTableColumns,
  dateFilterField: { field: 'saleDate', label: 'Date Sold' },
  initSortField: 'saleDate',
  sortableFields: outsideFundingSortFields,
  filterableFields: outsideFundingFilterFields,
  dbRowValidationFxn: (row: OutsideFundingDb) => OutsideFundingDb.new(row),
};
