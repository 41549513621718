// components
import NameCell from './NameCell';
// utils
import { Column } from '@/components/table/TableInterface';
import { ERSFmt } from '../ERSDetailView/interfaces';

export const fmtRunTimeLocal = (runTimeLocal: ERSFmt['runTimeLocal']) => {
  if (runTimeLocal === null) return null;
  const hrForm = runTimeLocal.getHours();
  const minForm = runTimeLocal.getMinutes();

  const hr = hrForm === 0 ? 12 : hrForm > 12 ? hrForm % 12 : hrForm;
  const amPm = hrForm > 12 ? 'pm' : 'am';
  const min = minForm < 10 ? `0${minForm}` : minForm;

  return `${hr}:${min} ${amPm}`;
};

export const ersTableColumns: (Column & { field: keyof ERSFmt })[] = [
  {
    field: 'reportName',
    title: 'Report Name',
    width: 'auto',
    cell: (e) => <NameCell emailReportSchedule={e.dataItem as ERSFmt} />,
    filter: 'text',
  },
  {
    field: 'emailTo',
    title: 'Email to',
    width: 'auto',
    filter: 'text',
  },
  {
    field: 'fileType',
    title: 'File Type',
    width: '90px',
    filter: 'text',
  },
  {
    field: 'scheduleType',
    title: 'Schedule',
    width: '90px',
    filter: 'text',
  },
  {
    field: 'runTimeLocal',
    title: 'Time',
    width: '90px',
    cell: (e) => (
      <td
        className="k-table-td"
        colSpan={1}
        aria-selected={false}
        role="gridcell"
        aria-colindex={5}
        data-grid-col-index={4}
        style={{ left: '0px', right: '0px' }}
      >
        {fmtRunTimeLocal((e.dataItem as ERSFmt).runTimeLocal)}
      </td>
    ),
  },
  {
    field: 'runDays',
    title: 'Days',
    width: '150px',
  },
  {
    field: 'runDate',
    title: 'Date',
    width: '60px',
  },
  {
    field: 'dateRangeType',
    title: 'Date range',
    width: '150px',
    filter: 'text',
  },
  {
    field: 'xDaysPrior',
    title: 'Days prior',
    width: '100px',
  },
];
