import { FC } from 'react';
// mui
import MuiViewHeader from '@/mui/components/layout/ViewHeader';
// Components
import ViewSelector from './ViewSelector';
// state
import { useSalesViewCtx } from '../SalesViewProvider';
import { useSalesListCtx } from './SalesListProvider';

/** ###  */
const ViewHeader: FC = () => {
  const isLoading = useSalesViewCtx((s) => s.isLoading);
  const dbCt = useSalesListCtx((s) => s.dbCt);
  const { text } = useSalesListCtx((s) => s.subviewInfo);

  return (
    <MuiViewHeader
      primaryText="Sales"
      secondaryText={text}
      isLoading={isLoading}
      dbCt={dbCt}
      viewSelector={<ViewSelector />}
    />
  );
};

export default ViewHeader;
