// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const allColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('status', 'Status', 120),
  newColumnDef('code3', 'Lot', 80),
];
