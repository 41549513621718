import { z } from "zod";
import { isDayjs } from "dayjs";
// kendo
import { type RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
// utils
import { zAddRulesIssue } from "../zod";
// interfaces
import {
  type AppliedFieldSchema,
  type AppliedField,
  type AppliedSchemaShape,
  type OnChangeEventUnionNew,
} from "./interfaces";
import { type FormConfigCbReturnInferred } from "./fieldConfig/callbacks";
import { type AnyFormCfgObj } from "./fieldConfig/returnTypes";
import { type FormOut, type ZObj } from "./fieldConfig/interfaces";
import { type FieldConfig } from "./fieldConfig/input";

const getFieldDefaultValue = <TVal, TField extends z.ZodType<TVal>, TInput>(
  zField: TInput extends z.ZodDefault<TField> ? z.ZodDefault<TField> : TField
): TField extends z.ZodDefault<infer U> ? U : null =>
  zField instanceof z.ZodDefault ? zField._def.defaultValue() : null;

/**
 * - If field already has a catch, early exit
 * - If field has a default, add `catch(defaultValue)`
 * - If field has no default (`getFieldDefaultValue` returns `null`), add null-catch
 */
const getAppliedFieldSchema = <
  TField extends z.ZodType<T>,
  T,
  // Inferred output types
  TOut extends AppliedField<TField>
>(
  zField: TField
) => {
  if (zField instanceof z.ZodCatch) return zField as TOut;
  if (zField instanceof z.ZodDefault) return zField.catch(getFieldDefaultValue(zField)) as TOut;
  return zField.nullable().catch(null) as TOut;
};

/** Inspired by this [StackOverflow answer](https://stackoverflow.com/a/77720528) */
export const buildDefaultSchema = <
  T extends z.ZodRawShape,
  TSchema extends z.ZodObject<T>,
  TField extends keyof AppliedSchemaShape<TSchema>
>(
  schema: TSchema
): AppliedFieldSchema<TSchema> => {
  const schemaFields = Object.entries(schema.shape) as [TField, TSchema["shape"][TField]][];

  const newProps = schemaFields.reduce((acc, [key, field]) => {
    acc[key] = getAppliedFieldSchema(field);
    return acc;
  }, {} as AppliedSchemaShape<TSchema>);

  return z.object(newProps);
};

export const getValueFromEvent = (e: OnChangeEventUnionNew, args?: (boolean | unknown)[]) => {
  // @note Handle event-type from `MuiDatePicker.onChange()`, or 'nullish' events. If `undefined`, convert to `null`.
  if (e === null || e === undefined || isDayjs(e)) return e ?? null;

  // @note Handle event-type from `MuiCheckBox.onChange()`.
  if (args && args.length > 0 && typeof args[0] === "boolean") return args[0];

  // @note Handle event-type from `KendoRadioGroup.onChange()`.
  if (e.value !== undefined && e.target?.value === undefined) {
    return (e as RadioGroupChangeEvent).value ?? null;
  }

  return e.target?.value ?? null;
};

/** Extract the refinement/transform from a `zod` schema.
 *
 * Transforms [`.transform()`, `.superRefine()`, `z.preprocess`]
 * all return instances of 'transform-effects' (`instanceof z.ZodTransformer`).
 *
 * The 3 types are: z.RefinementEffect<T> | z.TransformEffect<T> | z.PreprocessEffect<T>
 *
 * @note Not used yet, may use in the future
 */
export const extractEffect = <TSchema extends z.ZodEffects<z.ZodTypeAny>>(
  schemaWithEffect: TSchema
) => {
  if (
    !(schemaWithEffect instanceof z.ZodEffects) ||
    !(schemaWithEffect instanceof z.ZodTransformer)
  ) {
    throw new Error(
      "Schema must have an effect (`.superRefine()`, `.transform()`, `z.preprocess()`)"
    );
  }

  type Out = z.output<TSchema>;
  const effect = schemaWithEffect._def.effect as z.Effect<Out>;
  if (effect.type === "refinement") {
    return (effect as z.RefinementEffect<Out>).refinement;
  } else if (effect.type === "transform") {
    return (effect as z.TransformEffect<Out>).transform;
  } else if (effect.type === "preprocess") {
    return (effect as z.PreprocessEffect<Out>).transform;
  }

  // @note Function only supports
  throw new Error(
    "Schema must have an effect (`.superRefine()`, `.transform()`, `z.preprocess()`)"
  );
};

export const buildRefinedSchema = <
  TBase extends ZObj,
  TConfig extends AnyFormCfgObj<FormOut<TBase>>
>(
  baseSchema: TBase,
  config: TConfig | undefined | null,
  configValues: FormConfigCbReturnInferred<TConfig>
) => {
  // Exit if no config provided
  if (!config || !config.fields) return baseSchema;
  const configFieldsArr = Object.entries(config.fields) as [FieldKey, FieldCfg][];
  if (configFieldsArr.length < 1) return baseSchema;

  type FormCfgFields = TConfig["fields"];
  type FieldKey = keyof FormCfgFields;
  type FieldCfg = FieldConfig<any, any, any, FieldKey>;

  // Get config-fields that have `.registerOn()` and `.rules()` defined
  const configFieldsFiltered = configFieldsArr.filter(
    ([_fieldKey, fieldCfg]) => !!fieldCfg.registerOn || !!fieldCfg.rules
  );

  // memo
  const cfgRuleArr = configFieldsFiltered.map(([fieldKey, fieldCfg]) => {
    // IF `.registerOn()` IS DEFINED: Run `.registerOn()`, otherwise `registered = true`
    const isRegistered = fieldCfg.registerOn ? fieldCfg.registerOn({ ...configValues }) : true;

    return (form: z.output<TBase>, ctx: z.RefinementCtx) => {
      // IF (FIELD IS REGISTERED) & (FIELD IS NULL): THROW VALIDATION ERROR
      if (isRegistered) {
        if (!!fieldCfg.registerOn && form[fieldKey] === null) {
          zAddRulesIssue([ctx, fieldKey]);
        } else {
          // IF `isRegistered === true` & `.rules()` IS DEFINED: Run rules
          !!fieldCfg.rules && fieldCfg.rules({ ...configValues, form }, ctx, fieldKey);
        }
      }
    };
  });
  const injected = (form: z.output<TBase>, ctx: z.RefinementCtx) => {
    for (let idx = 0; idx < cfgRuleArr.length; idx++) {
      const appliedFieldRefinement = cfgRuleArr[idx];
      appliedFieldRefinement && appliedFieldRefinement(form, ctx);
    }
  };

  return baseSchema.superRefine((f, ctx) => {
    injected(f, ctx);
  });
};
