import { getFinanceTaxRate } from '@/features/Sales/salesActionCreator';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
import { FinanceTaxRates } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { useAppDispatch } from '@/store/store';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useSalesTax = () => {
  const { financeTaxRates, financeTaxRatesLoading } = useSalesSelector((s) => s);
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const taxOnData = ['Sales Price', 'Sales Price less Trade'];
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateTaxSettingsLoading, setUpdateTaxSettingsLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm({ defaultValues: financeTaxRates });

  const onResetClick = () => {
    setShowConfirmationModal(true);
  };

  const onResetYesClick = () => {
    reset(financeTaxRates);
    setShowConfirmationModal(false);
  };

  const getFinanceTaxRates = useCallback(async () => {
    const financeTaxRates = await dispatch(getFinanceTaxRate(appRecId));
    reset(financeTaxRates);
  }, [appRecId]);

  useEffect(() => {
    getFinanceTaxRates();
  }, []);

  const updateTaxSettings = async (data: FinanceTaxRates) => {
    setUpdateTaxSettingsLoading(true);
    const payload: FinanceTaxRates = {
      ...data,
      appRecId: appRecId,
      stateRate: (data.stateRate || 0) * 100,
      cityRate: (data.cityRate || 0) * 100,
      countyRate: (data.countyRate || 0) * 100,
      locationRate: (data.locationRate || 0) * 100,
    };
    try {
      const newSaleData = await salesService.updateFinanceTaxRates(payload);
      dispatch(salesActions.setSaleData(newSaleData));
      getFinanceTaxRates();
      toast.success('Tax Settings Updated');
    } finally {
      setUpdateTaxSettingsLoading(false);
    }
  };

  return {
    control,
    handleSubmit,
    taxOnData,
    financeTaxRates,
    financeTaxRatesLoading,
    showConfirmationModal,
    setShowConfirmationModal,
    onResetClick,
    onResetYesClick,
    updateTaxSettings,
    updateTaxSettingsLoading,
  };
};
