import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const TotalReceivedField: FC = () => {
  const isCcOrAch = usePmtFormCtx((s) => s.isCcOrAch);
  const isMiscPmt = usePmtFormCtx((s) => s.formState.config.external.isMiscPmt);
  const totalPayment = usePmtFormCtx((s) => s.formState.form.totalPayment);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("totalReceived"));
  
  const isNegativeMisc = isMiscPmt && totalPayment < 0;

  return (
    <CurrencyInput
      label="Amount Tendered"
      required={!isCcOrAch}
      readOnly={isCcOrAch || isNegativeMisc}
      {...fieldProps}
    />
  );
};

export default TotalReceivedField;
