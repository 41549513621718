import { FC } from 'react';
// kendo
import { Grid as KendoGrid, GridColumn } from '@progress/kendo-react-all';
import { Modal } from '@/components/modals/Modal';
// state
import { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// utils
import { tableColumns } from './tableColumns';

/** ###  */
const ClaimsModal: FC = () => {
  const setIsClaimsModalOpen = useAcctInsuranceCtx((s) => s.setIsClaimsModalOpen);
  const insuranceClaims = useAcctInsuranceCtx((s) => s.insuranceClaims);

  const isClaimsModalOpen = useAcctInsuranceCtx((s) => s.isClaimsModalOpen);
  if (!isClaimsModalOpen) return <></>;

  const columnElems = tableColumns.map((col) => {
    return <GridColumn field={col.field} title={col.title} key={col.field} />;
  });

  // Event handlers
  const handleClose = () => setIsClaimsModalOpen(false);

  return (
    <Modal
      isOpen={true}
      closeButton
      title="Claims"
      centerModal={true}
      onCloseButtonClick={handleClose}
      onDimmerClick={handleClose}
    >
      <KendoGrid data={insuranceClaims} sortable={false} filterable={false}>
        {columnElems}
      </KendoGrid>
    </Modal>
  );
};

export default ClaimsModal;
