import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Note } from '@/services/customerService';
import { DateFormat, extractTextFromHtmlString, formatDate } from '@/utils/helpers/general';
import { Icons } from '@/components/icons';

import styles from '../activityPanel.module.scss';
import { DCS_BLUE } from '@/mui/theme/colors';


/** ###  */
const NoteActivityCard: FC<{activity:Note, updatePinnedNote:Function}> = ({activity, updatePinnedNote}) => {

  return (
    <Grid container direction="column">
 <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Note />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.row}>
                <div className={styles.messageHeader}>Note created by {activity.createdByString}</div>
                <div className={styles.pinRow}>
                  {activity.pinned && (
                    <div className={styles.disclaimer}>
                      {activity.pinnedByString} - {formatDate(activity.pinnedOnUtc)}
                    </div>
                  )}
                  <div
                    className={styles.pin}
                    onClick={() => updatePinnedNote(activity)}
                    style={{ fill: activity.pinned ? DCS_BLUE : '#b1b1b1' }}
                  >
                    <Icons.Pin />
                  </div>
                </div>
              </div>
              <div>{activity.note}</div>
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(activity.updatedUtc, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    </Grid>
  );
};

export default NoteActivityCard;
