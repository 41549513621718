import { Button } from '@/components';
import { BuyerType } from '@/enums';
import { ApplicationBuyer } from '../buyer/ApplicationBuyer';
import { useApplicationBuyer } from '../buyer/useApplicationBuyer';

export const ApplicationCoBuyer = () => {
  const { hasCobuyer, handleAddOrRemoveCobuyer, updateHasCobuyerLoading, isDisabled } =
    useApplicationBuyer(BuyerType.CoBuyer);
  return (
    <>
      <Button
        label={`${!hasCobuyer ? 'Add' : 'Remove'} Co-Buyer`}
        buttonStyle={{ width: '150px' }}
        loading={updateHasCobuyerLoading}
        disabled={isDisabled}
        onClick={() => handleAddOrRemoveCobuyer(!hasCobuyer)}
      />
      {hasCobuyer && <ApplicationBuyer buyerOrCobuyer={BuyerType.CoBuyer} />}
    </>
  );
};
