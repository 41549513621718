import { FC } from "react";
import { useMatch } from "react-router-dom";
// components
import DetailViewLayout from "@/mui/components/layout/DetailViewLayout";
import DetailViewHeader from "@/mui/components/layout/DetailViewLayout/DetailViewHeader";
import NavTabs from "./layout/Header/NavTabs";
import LeftContainer from "./layout/LeftContainer";
import RightContainer from "./layout/RightContainer";
import QuickActionsModal from "./components/quickActionsModal/QuickActionsModal";
import DetailViewBody from "@/mui/components/layout/DetailViewLayout/DetailViewBody";
// state
import { useAcctDetailsCtx } from "./AcctDetailProvider";

/** ### Layout for Account detail view */
const AccountDetailLayout: FC = () => {
  const isAcctInfoLoading = useAcctDetailsCtx((s) => s.acctInfoReq.isLoading);
  const collectionsViewMatch = useMatch({ path: `/collections/:queueRecId/run/:colRecId/*` })?.pathname;
  const appliedNavPath = collectionsViewMatch ? false : "/accounts";

  return (
    <DetailViewLayout backLink={appliedNavPath}>
      <DetailViewHeader title="Account Overview">
        <NavTabs hasTabs />
      </DetailViewHeader>

      <DetailViewBody direction="row" gap={2.5} p="10px" isLoading={isAcctInfoLoading}>
        <LeftContainer />
        <RightContainer />
      </DetailViewBody>

      <QuickActionsModal />
    </DetailViewLayout>
  );
};

export default AccountDetailLayout;
