import { FC } from 'react';
// components
import ReportsViewTable from '../ReportsViewTable';
import ReportCategoryFilter from './ReportCategoryFilter';
// utils
import { useReportsCtx } from './defaultState';

/** ### Table component for "Reports" view */
const ReportsList: FC = () => {
  const reports = useReportsCtx(s => s.reports);

  return (
    <ReportsViewTable
      rows={reports}
      filterComponent={<ReportCategoryFilter />}
      disableClientSidePaginationSortFiltering={true}
    />
  );
};

export default ReportsList;
