import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Label } from '@progress/kendo-react-all';

const TitleHeader: FC<{ label: string }> = ({ label }) => {
  return (
    <Grid container direction="row" flex={1} alignItems="center" marginTop={3}>
      <Grid
        sx={{
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '20px',
          letterSpacing: '-0.02em',
          textAlign: 'left',
          color: '#294695',
        }}
      >
        <Label>{label}</Label>
      </Grid>
      <Grid
        flex={1}
        sx={{
          height: '3px',
          marginLeft: '26px',
          backgroundColor: '#E6E6E6',
        }}
      ></Grid>
    </Grid>
  );
};

export default TitleHeader;
