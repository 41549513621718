export interface VendorListItem {
  recId: number;
  vendorName: string;
  accountNumber: string;
}

/**
 * @todo convert to class
 * @todo break-out into response, form (done), and request-body classes
 */
export interface IVendor {
  recId?: number;
  orgId?: number;
  locId?: number;
  compId?: number;
  active?: boolean;
  vendorName: string;
  accountNumber?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  vFax?: string;
  contact?: string;
  emailAddress?: string;
  webAddress?: string;
  notes?: string;
}

/** @todo convert to class */
export interface VendorForm extends IVendor {}

export class VendorReq implements VendorForm {
  recId?: number;
  orgId?: number;
  locId?: number;
  compId?: number;
  active?: boolean;
  vendorName: string;
  accountNumber?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  vFax?: string;
  contact?: string;
  emailAddress?: string;
  webAddress?: string;
  notes?: string;

  constructor(form: VendorForm) {
    this.recId = form.recId;
    this.orgId = form.orgId;
    this.locId = form.locId;
    this.compId = form.compId;
    this.active = form.active;
    this.vendorName = form.vendorName || '';
    this.accountNumber = form.accountNumber;
    this.address = form.address;
    this.address2 = form.address2;
    this.city = form.city;
    this.state = form.state;
    this.zip = form.zip;
    this.phone = form.phone;
    this.vFax = form.vFax;
    this.contact = form.contact;
    this.emailAddress = form.emailAddress;
    this.webAddress = form.webAddress;
    this.notes = form.notes;
  }
}

/**
 * @deprecated use `VendorRes`, `VendorForm`, and `VendorReq` interfaces
 * @todo convert to class
 */
export type Vendor = IVendor;
