import { GridRowId, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
// kendo
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-all';

/** @note Used for data from routes configured for use with kendo tables. See existing use-cases of this function for reference. */
export const convertSortToTable = (kendoSort: SortDescriptor[]): GridSortModel => {
  return kendoSort.map(({ field, dir }) => ({ field, sort: dir }));
};

/** @note Used for data from routes configured for use with kendo tables. See existing use-cases of this function for reference. */
export const convertSortToReq = (muiSort: GridSortModel): Pick<GridSortChangeEvent, 'sort'> => {
  return { sort: muiSort.map(({ field, sort }) => ({ field, dir: sort || undefined })) };
};

/** ### 
 * 
 * #### Usage
 * ```tsx
 * <DataGrid
    rows={data}
    columns={tableColumns}
    // ...
    onSortModelChange={handleChangeSort(onSortChange)}
    // ...
  />
 * ```
 * @note Used for data from routes configured for use with kendo tables. See existing use-cases of this function for reference.
 */
export const handleChangeSort =
  (onSortChange: (_: GridSortChangeEvent) => void) => (newSortModel: GridSortModel) =>
    onSortChange(convertSortToReq(newSortModel) as GridSortChangeEvent);

/** ### Convenience callback for setting row ID for MUI DataGrid
 * #### Usage:
 * ```tsx
 * <DataGrid
 *   rows={data}
 *   columns={tableColumns}
 *   getRowId={getRowId('recId')}
 *   // Identical to:
 *   // getRowId={row => row.recId}
 *   // ...
 * />
 * ```
 */
export const getRowId =
  <R extends GridValidRowModel = GridValidRowModel>(col: keyof R) =>
  (row: R): GridRowId =>
    row[col];

/** ### Convenience callback for setting row ID for MUI DataGrid ***FOR ROWS WITH `recId` FIELDNAME ONLY***
 * #### Usage:
 * ```tsx
 * <DataGrid
 *   rows={data}
 *   columns={tableColumns}
 *   getRowId={getRowRecId}
 *   // Identical to:
 *   // getRowId={row => row.recId}
 *   // ...
 * />
 * ```
 */
export const getRowRecId = <
  R extends GridValidRowModel & { recId: number } = GridValidRowModel & { recId: number }
>(
  row: R
): GridRowId => row.recId;
