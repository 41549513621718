import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';

/** ###  */
const ColTypeCell: FC<GridCellProps> = ({ dataItem: { colType } }) => {
  return (
    <td className="k-table-td">
      <span>{colType === 'MI' ? 'IN' : colType === 'MO' ? 'OUT' : ''}</span>
    </td>
  );
};

export default ColTypeCell;
