import { ApiResponse, CoRepoType } from '@/interfaces';
import { toast } from 'react-toastify';
import { AxiosService } from './axiosService';

class LegalService extends AxiosService {
  public constructor() {
    super();
  }

  async getLegal(colRecId: number, compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<LegalResponse>>('/CustomerAdmin/GetLegal', {
        params: { compId, colRecId },
      });
      return data.data!;
    } catch (e) {
      toast.error("There was an error retrieving the customer's legal account data");
      throw e;
    }
  }

  async updateLegal(UpdateLegalRequest: UpdateLegalRequest) {
    try {
      const { data } = await this.axios.post('/CustomerAdmin/UpdateLegal', UpdateLegalRequest);
      toast.success('Legal account successfully updated');
      return data;
    } catch (e) {
      toast.error("There was an error updating the customer's legal account data");
      throw e;
    }
  }

  async getCBR(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CbrResponse>>('/CustomerAdmin/GetCBR', {
        params: { colRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateCBR(cbrPayload: CbrPayload) {
    try {
      const { data } = await this.axios.post('/CustomerAdmin/UpdateCBR', cbrPayload);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export const legalService = new LegalService();

export interface LegalResponse {
  legal: Legal;
  categories: Category[];
}

interface Category {
  recId: number;
  field: string;
  item: string;
  view: boolean;
  editable: boolean;
}

interface Legal {
  appRecId: number;
  isLegal: boolean;
  legalType?: any;
  category?: any;
  status?: any;
  bankruptcy: {
    fileDate?: any;
    pocFileDate?: any;
    caseNumber?: any;
    filingState?: any;
    filingDistrict?: any;
    filingDivision?: any;
    code?: any;
  };
}

export interface UpdateLegalRequest {
  appRecId: number;
  colRecId: number;
  compId: number;
  isLegal: boolean;
  legalType?: string;
  category?: number;
  status?: string;
  note?: string | null;
  bankruptcy?: {
    fileDate: string;
    pocFileDate: string;
    caseNumber: string;
    filingState: string;
    filingDistrict: string;
    filingDivision: string;
    code: string;
  };
}

export interface CbrResponse {
  crCodes: CrCode[];
  coRepoTypes: CoRepoType[];
  cbr: Cbr;
}

interface Cbr {
  recId: number;
  buyer: string;
  address: string;
  specialCondition?: any;
  addressIndicator?: any;
  type: string;
  specialComments?: any;
  complianceConditionCode?: any;
  doNotReport: boolean;
  closeAccount: boolean;
  doNotReportReason?: any;
}

export interface CrCode {
  recId: number;
  codeId: string;
  type: string;
  description: string;
}

export interface CbrPayload {
  recId: number;
  colRecId: number;
  buyer?: string;
  address?: string;
  specialCondition: string | null;
  addressIndicator: string | null;
  type: string;
  specialComments: string | null;
  complianceConditionCode: string | null;
  doNotReport: boolean;
  closeAccount: boolean;
  doNotReportReason?: string;
}
