import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@/mui/components/CommonModal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// components
import HistorySidebar from "./HistorySidebar";
import EvalResultsSection from "..";
import AlertsSection from "../../AlertsSection";
import DealSummarySection from "../../DealSummarySection";
import PrintPdfButton from "../../pdfUtils/PrintPdfButton";
// state
import { useAiUnderwritingCtx } from "../../AiUnderwritingProvider";

/** ###  */
const PreviousEvals: FC = () => {
  const reports = useAiUnderwritingCtx((s) => s.reportsHistory.value);
  const isModalOpen =
    useAiUnderwritingCtx((s) => s.isModalOpen) && reports !== null && reports.length > 1;
  const setIsModalOpen = useAiUnderwritingCtx((s) => s.setIsModalOpen);

  // Event handlers
  const handleCloseModal = () => setIsModalOpen(false);


  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      containerProps={{ p: 0, width: "80%", minWidth: "800px" }}
    >
      <Grid
        container
        direction="column"
        flex={1}
        pt="40px"
        px={0}
        rowGap={5}
        wrap="nowrap"
        component={Paper}
        sx={{ overflowX: "scroll", overflowY: "hidden" }}
      >
        {/* Header */}
        <Grid container direction="row" justifyContent="space-between" alignItems="center" px={5}>
          <Grid component={Typography} variant="h3" fontSize="31px" fontWeight={700}>
            Previous Evaluations
          </Grid>
          <PrintPdfButton isInModal={true} isDeclinedStatus = {false}/>
        </Grid>

        {/* Body */}
        <Grid
          container
          direction="row"
          flex={1}
          wrap="nowrap"
          component={Paper}
          sx={{ overflowX: "auto", overflowY: "hidden" }}
        >
          <HistorySidebar />
          <Grid
            container
            direction="column"
            flex={1}
            wrap="nowrap"
            sx={{ overflowX: "auto", overflowY: "scroll" }}
          >
            <EvalResultsSection isInModal />
            <AlertsSection isInModal />
            <DealSummarySection />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PreviousEvals;
