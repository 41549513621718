import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
// kendo
import { Icons } from '@/components/icons';
// style
import styles from './DmsRadioButtonGroup.module.scss';

export const DmsRadioButtonGroup: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    inputLabel?: string;
    buttons: string[];
    radioButtonGroupName: string;
    labelContainerStyle?: React.CSSProperties;
  }
> = ({ inputLabel, buttons, radioButtonGroupName, labelContainerStyle = {}, ...restProps }) => (
  <div className={styles.container}>
    <div className={styles.labelContainer} style={labelContainerStyle}>
      <div className={styles.label}>{inputLabel}</div>
      {restProps.required && (
        <Icons.Star color="#bc5757" width="10px" height="10px" className={styles.icon} />
      )}
    </div>
    <div className={styles.buttonContainer}>
      {buttons.map((button, idx) => {
        return (
          <div className={styles.button} key={idx}>
            <input
              id={button}
              type="radio"
              value={button}
              name={radioButtonGroupName}
              defaultChecked={idx === 0}
              {...restProps}
            />
            <label className={styles.buttonLabel}>{button}</label>
          </div>
        );
      })}
    </div>
  </div>
);
