import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
// state
import { ILhPhDetailCtx } from './LhPhDetailProvider';
// utils

/** ### Displays title */
const LhPhDetailHeader: FC<{ lhPhState?: ILhPhDetailCtx }> = ({ lhPhState }) => {
  const lhPhRes = lhPhState?.lhPhRes;
  const lotHeaderText = lhPhRes?.code3 ? `${lhPhRes.code3} ` : '';
  const stockNumHeaderText = lhPhRes?.stockNum ? `${lhPhRes.stockNum} ` : '';
  const saleStatusHeaderText = 'LHPH';
  const ymmHeaderText = lhPhRes?.vehYmm ? ` ${lhPhRes.vehYmm} ` : '';
  const vinHeaderText = lhPhRes?.vin ? ` - VIN: ${lhPhRes.vin}` : '';

  const headerTitle = `${lotHeaderText}${stockNumHeaderText}- ${saleStatusHeaderText} -${ymmHeaderText}${vinHeaderText}`;

  return (
    <Grid>
      <Typography
        variant="h5"
        fontWeight={700}
        px={2.5}
        py={2}
        fontSize={24}
        textOverflow="ellipsis"
        noWrap
      >
        {headerTitle}
      </Typography>
    </Grid>
  );
};

export default LhPhDetailHeader;
