import { CSSProperties, FC, PropsWithChildren } from "react";
import styles from "./MultipleInputRow.module.scss";

export const MultipleInputRow: FC<
  PropsWithChildren & {
    htmlFor?: string;
    label?: string;
    containerStyles?: CSSProperties;
    labelStyles?: CSSProperties;
    inputStyle?: CSSProperties;
  }
> = ({ children, htmlFor, label, containerStyles, labelStyles, inputStyle }) => (
  <div className={styles.inputRow} style={containerStyles}>
    <label className={styles.inputLabel} style={labelStyles} htmlFor={htmlFor}>
      {label}
    </label>
    <div className={styles.inputRowChild} style={inputStyle}>
      {children}
    </div>
  </div>
);
