import React, { useEffect, useState } from 'react';
import styles from './EmailReceipt.module.scss';
import { DmsTextInput, DmsCheckbox, DmsModal, EditEmail } from '@components/old';
import { Spacer } from "@/components/spacer/Spacer";
import { paymentService } from '@services/paymentService';
import { displayErrors } from '@/utils/helpers/general';
import { CircleSpinner } from 'react-spinners-kit';

/**
 Creates email receipt component to show and update current email and opt-in/opt-out status.
 Component includes two modals for updating email and opt-in/opt-out status
*/

type Props = {
  /** the current email of the buyer */
  buyerEmail: string;
  /** the current email of the cobuyer */
  coBuyerEmail: string;
  /** callback to update buyer/cobuyer include*/
  buyerCheckboxFunction: (val: boolean) => void;
  cobuyerCheckboxFunction: (val: boolean) => void;
  /** the current status of the buyers opt-in/opt-out status*/
  noEmailState?: boolean;
  coBuyerNoEmailState?: boolean;
  containerStyles?: React.CSSProperties;
  /** requirements to update opt-out status and email */
  colRecId?: number;
  appRecId?: number;
  buyerRecId?: number;
  cobuyerRecId?: number | null;
  /** function that is called when the email modal is closed with "Save" */
  emailCallback: (val: string) => void;
  cobuyerEmailCallback: (val: string) => void;
  /**  function that is called when the optOut modal is closed with "Yes" */
  optOutCallback: (val: boolean) => void;
  cobuyerOptOutCallback: (val: boolean) => void;
  hasCobuyer?: boolean;
};

export const EmailReceipt: React.FC<Props> = (props) => {
  const {
    buyerEmail,
    coBuyerEmail,
    buyerCheckboxFunction,
    cobuyerCheckboxFunction,
    noEmailState,
    coBuyerNoEmailState,
    containerStyles = {},
    colRecId,
    appRecId,
    buyerRecId,
    cobuyerRecId,
    emailCallback,
    cobuyerEmailCallback,
    optOutCallback,
    cobuyerOptOutCallback,
    hasCobuyer,
  } = props;

  const [editEmailModal, setEditEmailModal] = useState<boolean>(false);
  const [email, setBuyerEmail] = useState<string>('');
  const [tempCoEmail, setTempCoEmail] = useState<string>('');
  const [editCobuyerEmail, setEditCobuyerEmail] = useState<boolean>(false);
  const [optOutModal, setOptOutModal] = useState<boolean>(false);
  const [editCobuyerOptOut, setEditCobuyerOptOut] = useState<boolean>(false);
  const [buyerNoEmail, setBuyerNoEmail] = useState<boolean>();
  const [cobuyerNoEmail, setCobuyerNoEmail] = useState<boolean>();
  const [buyerChecked, setBuyerChecked] = useState<boolean>();
  const [cobuyerChecked, setCobuyerChecked] = useState<boolean>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [updatingEmail, setUpdatingEmail] = useState(false);

  useEffect(() => {
    setBuyerEmail(buyerEmail);
    setTempCoEmail(coBuyerEmail);
    setBuyerNoEmail(noEmailState);
    setCobuyerNoEmail(coBuyerNoEmailState);
    //default buyer email to checked
    if (buyerEmail && !noEmailState) {
      setBuyerChecked(true);
      buyerCheckboxFunction(true);
    }
    if (coBuyerEmail && !coBuyerNoEmailState) {
      setCobuyerChecked(true);
      cobuyerCheckboxFunction(true);
    }
  }, []);

  const saveAndUpdateEmail = (isBuyer = true) => {
    if (!colRecId || !appRecId || !buyerRecId) {
      displayErrors('unable to edit email');
      return;
    }
    setUpdatingEmail(true);
    paymentService
      .postUpdatedEmail({
        colRecId: colRecId,
        appRecId: appRecId,
        buyerCoBuyerRecId: isBuyer ? buyerRecId : cobuyerRecId!,
        isBuyer: isBuyer,
        email: isBuyer ? email : tempCoEmail,
      })
      .then(() => {
        setEditEmailModal(false);
        setEditCobuyerEmail(false);
        if (isBuyer) {
          emailCallback(email);
        } else {
          cobuyerEmailCallback(tempCoEmail);
        }
        setUpdatingEmail(false);
      })
      .catch((err: any) => {
        displayErrors(err.response.data.message);
      })
      .finally(() => {
        setUpdatingEmail(false);
      });
  };

  const updateOptOutStatus = () => {
    setRefreshing && setRefreshing(true);
    colRecId &&
      appRecId &&
      paymentService
        .postUpdatedOptOutStatus({
          optOut: editCobuyerOptOut ? !cobuyerNoEmail : !buyerNoEmail,
          colRecId: colRecId,
          appRecId: appRecId,
          isBuyer: editCobuyerOptOut ? false : true,
        })
        .then(() => {
          setBuyerChecked(false);
          if (editCobuyerOptOut) {
            // cobuyer does not default to checked when opt out set to false
            if (cobuyerNoEmail === false) {
              handleCobuyerInclude();
            }
            cobuyerOptOutCallback && cobuyerOptOutCallback(!cobuyerNoEmail);
            setCobuyerNoEmail(!cobuyerNoEmail);
          } else {
            optOutCallback && optOutCallback(!buyerNoEmail);
            setBuyerNoEmail(!buyerNoEmail);
            handleBuyerInclude();
          }

          setOptOutModal(false);
          setRefreshing && setRefreshing(true);
        })
        .catch(() => displayErrors('Unable to update opt-out status'))
        .finally(() => {
          setEditCobuyerOptOut(false);
          setRefreshing && setRefreshing(false);
        });
    setOptOutModal(false);
  };

  const openEmailModal = (isCobuyer = false) => {
    setEditCobuyerEmail(isCobuyer);
    setEditEmailModal(!editEmailModal);
  };

  const openOptOutModal = (isCobuyer = false) => {
    setEditCobuyerOptOut(isCobuyer);
    setOptOutModal(!optOutModal);
  };

  const closeOptOutModal = () => {
    if (editCobuyerOptOut) {
      setEditCobuyerOptOut(false);
    }
    setOptOutModal(false);
  };

  const handleBuyerInclude = () => {
    buyerCheckboxFunction(!buyerChecked);
    setBuyerChecked(!buyerChecked);
  };

  const handleCobuyerInclude = () => {
    setCobuyerChecked(!cobuyerChecked);
    cobuyerCheckboxFunction(!cobuyerChecked);
  };

  if (refreshing) {
    return (
      <div className={styles.loadingContainer} style={containerStyles}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }
  return (
    <div className={styles.container} style={containerStyles}>
      <Spacer size={8} />
      <div className={styles.title}>Email Receipt</div>
      <Spacer size={8} />
      <div className={styles.subContainer}>
        <div className={styles.bottomContainer}>
          {!buyerNoEmail && (
            <DmsCheckbox
              checkboxLabel="Buyer"
              onChange={handleBuyerInclude}
              checked={buyerChecked || false}
            />
          )}
        </div>
        <div className={styles.inputContainer}>
          <DmsTextInput
            value={buyerEmail}
            disabled={true}
            editButton={true}
            editButtonFunction={() => setEditEmailModal(!editEmailModal)}
            inputStyles={{ width: '100%' }}
          />
        </div>
        <div className={styles.optOutContainer}>
          <DmsCheckbox
            checkboxLabel="Email Opt-Out"
            onChange={() => openOptOutModal()}
            checked={buyerNoEmail || false}
          />
        </div>
      </div>
      {hasCobuyer && (
        <div className={styles.subContainer}>
          <div className={styles.bottomContainer}>
            {!cobuyerNoEmail && (
              <DmsCheckbox
                checkboxLabel="Co-Buyer"
                onChange={handleCobuyerInclude}
                checked={cobuyerChecked || false}
              />
            )}
          </div>
          <div className={styles.inputContainer}>
            <DmsTextInput
              value={coBuyerEmail}
              disabled={true}
              editButton={true}
              editButtonFunction={() => openEmailModal(true)}
              inputStyles={{ width: '100%' }}
            />
          </div>
          <div className={styles.optOutContainer}>
            <DmsCheckbox
              checkboxLabel="Email Opt-Out"
              onChange={() => openOptOutModal(true)}
              checked={cobuyerNoEmail || false}
            />
          </div>
        </div>
      )}

      {editEmailModal && (
        <DmsModal
          title="Edit Email"
          children={
            <EditEmail
              email={editCobuyerEmail ? tempCoEmail : email}
              setEmail={editCobuyerEmail ? setTempCoEmail : setBuyerEmail}
              saveAndUpdateEmail={() => saveAndUpdateEmail(editCobuyerEmail ? false : true)}
              header={`Note: this will permanently update ${
                editCobuyerEmail ? "Cobuyer's" : "Buyer's"
              } record `}
              updating={updatingEmail}
            />
          }
          isOpen={editEmailModal}
          closeFunction={() => {
            setEditCobuyerEmail(false);
            setTempCoEmail(coBuyerEmail);
            setBuyerEmail(buyerEmail);
            setEditEmailModal(false);
          }}
        />
      )}
      {optOutModal && (
        <DmsModal
          title={`Change Email Opt In/Out Status for ${editCobuyerOptOut ? 'Cobuyer' : 'Buyer'}`}
          message="Are you sure?"
          isOpen={optOutModal}
          onAcceptLabel="Yes"
          onDeclineLabel="No"
          closeFunction={closeOptOutModal}
          onDeclineFunction={closeOptOutModal}
          onAcceptFunction={() => {
            updateOptOutStatus();
          }}
        />
      )}
    </div>
  );
};
