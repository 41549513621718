import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
// kendo
import { Button } from '@/components/button/Button';
import { Modal } from "@/components/modals/Modal";
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { TextArea } from '@/components/inputs/textarea/TextArea';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import {
  emailRegex,
  genericRequired,
  optionalPhoneValidation,
} from '@/utils/helpers/formValidation';
import { getAddressFromGoogleMaps } from '@/utils/helpers/general';
import { inventoryService } from '@/services/inventoryService';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { IVendor, VendorForm, VendorReq } from '@/interfaces/Vendors';
import { SetState } from '@/interfaces/utilityTypes';
// style
import styles from './addVendorModal.module.scss';

const defaultVendorValues: VendorForm = {
  recId: undefined,
  active: true,
  vendorName: '',
  accountNumber: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  vFax: '',
  contact: '',
  emailAddress: '',
  webAddress: '',
  notes: '',
};

const buildFullAddressForVendor = (
  vendor: Pick<VendorForm, 'address' | 'city' | 'state' | 'zip'> | undefined
): string | null => {
  if (!vendor?.address) return null;

  return `${vendor?.address}, ${vendor?.city}, ${vendor?.state} ${vendor?.zip}`;
};

/** @deprecated needs refactor */
const AddVendorModal: FC<{
  opened: boolean;
  setOpened: SetState<boolean>;
  vendor: IVendor | null;
}> = ({ opened, setOpened, vendor }) => {
  const orgId = useAuthSelector((s) => s.orgId);
  const locId = useAuthSelector((s) => s.locId);
  const compId = useAuthSelector((s) => s.compId);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<VendorForm>({
    defaultValues: defaultVendorValues,
  });

  const address = watch('address');
  const city = watch('city');
  const state = watch('state');
  const zip = watch('zip');

  const isModalOpenAndVendorSelected = opened && vendor;
  const fullAddress =
    buildFullAddressForVendor(
      isModalOpenAndVendorSelected ? vendor : { address, city, state, zip }
    ) ?? '';

  const closeModal = (resetForm = true) => {
    if (resetForm) {
      reset(defaultVendorValues, { keepDirty: false });
    }

    setOpened(false);
  };

  const submitVendor = async (formBody: VendorForm) => {
    if (!formBody) return;

    try {
      // set defaults for orgId, locId, and compId
      const reqBody = new VendorReq({ ...formBody, orgId, locId, compId });

      if (formBody.recId) {
        await inventoryService.updateVendor(reqBody);
      } else {
        await inventoryService.createVendor(reqBody);
      }

      closeModal(true);
    } catch {}
  };

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const parsedAddress = getAddressFromGoogleMaps(place);

    setValue('address', parsedAddress.address, { shouldDirty: true });
    setValue('address2', parsedAddress.address2, { shouldDirty: true });
    setValue('city', parsedAddress.city, { shouldDirty: true });
    setValue('state', parsedAddress.state, { shouldDirty: true });
    setValue('zip', parsedAddress.zip, { shouldDirty: true });
  };

  // Reset form after successful submission
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(watch(), { keepDirty: false });
    }
    // @todo deps arr must use stable ref
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (isModalOpenAndVendorSelected) {
      reset(vendor, { keepDirty: false });
    }
    // @todo deps arr must use stable ref
  }, [vendor, opened]);

  // @todo move nested components to separate files
  return (
    <Modal
      isOpen={opened}
      centerModal
      closeButton
      onCloseButtonClick={closeModal}
      onDimmerClick={closeModal}
      panelStyle={{ maxWidth: '80vw', maxHeight: '95vh', zIndex: 10 }}
    >
      <div className={styles.header}>Add Vendor</div>
      <form onSubmit={handleSubmit(submitVendor)} style={{ marginTop: '20px' }}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <Controller
              name="vendorName"
              control={control}
              rules={{ ...genericRequired }}
              render={({ field }) => (
                <TextInput
                  required
                  label="Vendor Name"
                  errors={errors.vendorName?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="accountNumber"
              control={control}
              render={({ field }) => (
                <TextInput
                  label="Account Number"
                  errors={errors.accountNumber?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="contact"
              control={control}
              render={({ field }) => (
                <TextInput label="Contact" errors={errors.contact?.message} {...field} />
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{ ...optionalPhoneValidation }}
              render={({ field }) => (
                <PhoneInput label="Phone" errors={errors.phone?.message} {...field} />
              )}
            />
            <Controller
              name="vFax"
              control={control}
              render={({ field }) => (
                <PhoneInput label="Fax" errors={errors.vFax?.message} {...field} />
              )}
            />
            <Controller
              name="emailAddress"
              control={control}
              rules={{
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email',
                },
              }}
              render={({ field }) => (
                <TextInput label="Email Address" errors={errors.emailAddress?.message} {...field} />
              )}
            />
            <Controller
              name="webAddress"
              control={control}
              render={({ field }) => (
                <TextInput label="Company Website" errors={errors.webAddress?.message} {...field} />
              )}
            />
          </div>
          <div className={styles.column}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AddressAutocomplete
                  label="Address"
                  onPlaceSelected={handlePlaceSelected}
                  fullAddress={fullAddress}
                  errors={errors.address?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="address2"
              control={control}
              render={({ field }) => (
                <TextInput label="Address Line 2" errors={errors.address2?.message} {...field} />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextInput label="City" errors={errors.city?.message} {...field} />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  suggest
                  id="state"
                  label="State"
                  data={usaStateCodes}
                  errors={errors.state?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="zip"
              control={control}
              render={({ field }) => (
                <TextInput label="Zip Code" errors={errors.zip?.message} {...field} />
              )}
            />
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextArea label="Notes" errors={errors.notes?.message} rows={4} {...field} />
              )}
            />
            <div className={styles.buttonRow}>
              <Button label="Cancel" secondary disabled={!isDirty} onClick={() => reset()} />
              <Button
                label="Submit"
                secondary={!isDirty}
                disabled={!isDirty}
                loading={isSubmitting}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddVendorModal;
