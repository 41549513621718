import { CSSProperties, FC } from 'react';
// mui
// components
import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
// state
// utils
// interfaces
import Section from '@/mui/components/form/Section';
import SectionHeader from '@/mui/components/form/Section/SectionHeader';
import { OutForRepoGridPerApp } from './OutForRepoGrid';
import OutForRepoProvider from './OutForRepoProvider';
import OutForRepoForm from './OutForRepoForm';

const OutForRepoDetail: FC<{
  showTitle?: boolean;
  containerStyle?: CSSProperties;
}> = ({ containerStyle = {} }) => {
  return (
    <AccountsMainPanel navBarTitle="" containerStyle={containerStyle}>
      <OutForRepoForm />
      <Section title="Historical">
        <SectionHeader label="Historical" color="black" />
        <OutForRepoGridPerApp />
      </Section>
    </AccountsMainPanel>
  );
};

export default () => (
  <OutForRepoProvider>
    <OutForRepoDetail />
  </OutForRepoProvider>
);
