import { FC } from "react";
// components
import OpenEdgeModal from "./OpenEdgeModal";
import RepayModal from "./RepayModal";
import CliqPaymentModal from "./CliqPaymentModal";

/** ### Requires `usePmtFormCtx` */
const PaymentModalGroup: FC = () => (
  <>
    <CliqPaymentModal />
    <RepayModal />
    <OpenEdgeModal />
  </>
);

export default PaymentModalGroup;
