import { FC, useEffect, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { FaPlusSquare } from 'react-icons/fa';
import dayjs from 'dayjs';
// kendo
import { Button, DmsRadioButtonGroup, DmsModal, DmsTextInput } from '@components/old';
// utils
import { samTasksService } from '@/services/samTasksService';
// interfaces
import { SamTask, NewSamTask } from '@/interfaces/sam';
import { SamStatus } from '@/enums/sam';
// style
import styles from './SamTasks.module.scss';

const SamTasks: FC<{ appRecId?: number; colType?: string }> = ({ appRecId, colType }) => {
  const [statusFilter, setStatusFilter] = useState<SamStatus>(SamStatus.Active);
  const [samTasks, setSamTasks] = useState<SamTask[]>();
  const [currentTask, setCurrentTask] = useState<SamTask>();
  const [newSamTask, setNewSamTask] = useState<NewSamTask | null>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [modalRefreshing, setModalRefreshing] = useState<boolean>(false);
  const [samTaskError, setSamTaskError] = useState(false);

  const dateString = dayjs(currentTask?.postOn).format('ddd, MMM D, YYYY [at] h:mm A');

  const enteredBy = `Entered by ${currentTask?.postByName} on ${dateString}`;

  const canEdit = currentTask?.canDismiss && currentTask.canComplete;
  const button = {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 10,
  };
  const buttonStyle = canEdit
    ? button
    : { ...button, cursor: 'pointer', backgroundColor: 'rgb(65, 125, 175)' };
  const inputStyle = { width: '80%' };
  const textArea = { width: ' 80%' };
  const textAreaStyle = canEdit ? textArea : { ...textArea, backgroundColor: '#eeeeee' };

  useEffect(() => {
    getSamTasks();
  }, [appRecId, statusFilter]);

  const getSamTasks = () => {
    if (!appRecId) {
      return;
    }
    setRefreshing(true);
    samTasksService
      .getSamTasks(appRecId, 'Account', statusFilter)
      .then((data) => {
        setSamTasks(data);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const updateSamTask = (curSamTask: SamTask) => {
    if (!curSamTask.hotRecId) {
      return;
    }
    setModalRefreshing(true);
    samTasksService
      .updateSamTask(curSamTask)
      .then(() => {
        getSamTasks();
        setEditModalOpen(false);
        setCurrentTask(undefined);
      })
      .finally(() => {
        setModalRefreshing(false);
      });
  };

  const changeStatusAndUpdate = (status: string) => {
    setCurrentTask({ ...currentTask!, status });
    updateSamTask({ ...currentTask!, status });
  };

  const createNewSamTask = () => {
    if (!newSamTask?.item || !newSamTask?.hlNote || !appRecId || !colType) {
      setSamTaskError(true);
      return;
    }

    setSamTaskError(false);
    setModalRefreshing(true);
    samTasksService
      .createSamTask({ ...newSamTask, colType, appRecId })
      .then(() => {
        getSamTasks();
        setNewSamTask(undefined);
      })
      .finally(() => {
        setCreateModalOpen(false);
        setModalRefreshing(false);
      });
  };

  const RenderSamTasks = () => {
    if (!samTasks || samTasks.length === 0) {
      return <div></div>;
    }
    return (
      <>
        {samTasks?.map((task) => {
          if (task.status === statusFilter) {
            return (
              <div
                className={styles.task}
                key={`${task.hotRecId}-hlTask`}
                onClick={() => {
                  setEditModalOpen(true);
                  setCurrentTask(task);
                }}
              >
                <FaPlusSquare className={styles.icon} />
                <div>{task.item}</div>
              </div>
            );
          }
          return;
        })}
      </>
    );
  };

  const editBody = (
    <div className={styles.modalContainer}>
      <div className={styles.statusLabel}>{statusFilter}</div>
      <div className={styles.textLabel}>For</div>
      <div>
        <DmsTextInput value={currentTask?.samFor || ''} readOnly inputStyles={inputStyle} />
      </div>
      <div className={styles.enteredBy}>{enteredBy}</div>
      <div className={styles.textLabel}>Sam Item</div>
      <div>
        <DmsTextInput
          value={currentTask?.item || ''}
          onChange={(e) => setCurrentTask({ ...currentTask!, item: e.target.value })}
          readOnly={!canEdit}
          inputStyles={inputStyle}
        />
      </div>
      <div className={styles.textLabel}>Notes</div>
      <div className={styles.textArea}>
        <textarea
          value={currentTask?.hlNote || ''}
          rows={4}
          readOnly={!canEdit}
          style={textAreaStyle}
          onChange={(e) => setCurrentTask({ ...currentTask!, hlNote: e.target.value })}
        />
      </div>
      <div className={styles.modalButtonContainer}>
        <Button
          disabled={!canEdit}
          buttonLabel="Complete"
          buttonStyles={buttonStyle}
          onClick={() => changeStatusAndUpdate(SamStatus.Completed)}
        />
        <Button
          disabled={!canEdit}
          buttonLabel="Dismiss"
          buttonStyles={buttonStyle}
          onClick={() => changeStatusAndUpdate(SamStatus.Dismissed)}
        />
        <Button
          buttonLabel="Cancel"
          buttonStyles={button}
          onClick={() => setEditModalOpen(false)}
        />
        <Button
          disabled={!canEdit}
          buttonLabel="Save"
          buttonStyles={buttonStyle}
          onClick={() => updateSamTask(currentTask!)}
        />
      </div>
    </div>
  );

  const createBody = (
    <div className={styles.modalContainer}>
      <div className={styles.createTextLabel}>SAM Item</div>
      <div>
        <DmsTextInput
          value={newSamTask?.item || ''}
          onChange={(e) => setNewSamTask({ ...newSamTask!, item: e.target.value })}
          inputStyles={inputStyle}
        />
      </div>
      <div className={styles.textLabel}>Notes</div>
      <div>
        <textarea
          style={textArea}
          value={newSamTask?.hlNote || ''}
          rows={4}
          onChange={(e) => setNewSamTask({ ...newSamTask!, hlNote: e.target.value })}
        />
      </div>
      {samTaskError && (
        <div className={styles.samTaskError}>
          Please make sure the SAM Item and Notes field are not empty
        </div>
      )}
      <div className={styles.modalButtonContainer}>
        <Button
          buttonLabel="Cancel"
          buttonStyles={buttonStyle}
          onClick={() => {
            setSamTaskError(false);
            setCreateModalOpen(false);
          }}
        />
        <Button buttonLabel="Save" buttonStyles={buttonStyle} onClick={createNewSamTask} />
      </div>
    </div>
  );

  const spinner = (
    <div className={styles.loading}>
      <CircleSpinner color={'#3299df'} size={50} />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <DmsRadioButtonGroup
          inputLabel="SAM:"
          labelContainerStyle={{ minWidth: 50 }}
          buttons={Object.values(SamStatus)}
          radioButtonGroupName="SAM"
          onChange={(e) => setStatusFilter(e.target.value as SamStatus)}
        />
        <Button
          buttonLabel="Add New"
          buttonStyles={{ marginLeft: 20 }}
          onClick={() => setCreateModalOpen(true)}
        />
      </div>

      <div className={styles.taskContainer}>{refreshing ? spinner : <RenderSamTasks />}</div>

      <DmsModal
        isOpen={editModalOpen}
        closeFunction={() => setEditModalOpen(false)}
        title="Edit SAM Item"
        children={modalRefreshing ? spinner : editBody}
      />
      <DmsModal
        isOpen={createModalOpen}
        closeFunction={() => setCreateModalOpen(false)}
        title="Add SAM Item"
        children={modalRefreshing ? spinner : createBody}
      />
    </div>
  );
};

export default SamTasks;
