import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// mui
import NestedDetailLayout from "@/mui/components/layout/NestedDetailLayout";
// components
import AppTabHeader from "./AppTabHeader";
import { ApplicationBuyer } from "./subviews/buyer/ApplicationBuyer";
import { ApplicationCoBuyer } from "./subviews/coBuyer/ApplicationCoBuyer";
import SalesInsurance from "./subviews/insurance/SalesInsurance";
import AiUnderwritingTab from "./subviews/AiUnderwritingTab";
import { ApplicationUnderwriting } from "./subviews/underwriting/ApplicationUnderwriting";
import { ApplicationReferences } from "./subviews/references/ApplicationReferences";
// state
import AppTabViewProvider, { useAppTabViewCtx } from "./AppTabViewProvider";
// utils
import { tabInfoMap } from "./default";
import { BuyerType } from "@/enums";

/** ### Application-tab view layout
 * @todo use react-router implementation
 * @deprecated
 */
const ApplicationTabView: FC = () => {
  const currentTab = useAppTabViewCtx((s) => s.currentTab);
  const ActiveTabView = tabInfoMap.get(currentTab)?.component || (
    <div>Application Tab not Found</div>
  );

  return (
    <NestedDetailLayout sx={{ flex: 1 }} header={<AppTabHeader />}>
      {ActiveTabView}
    </NestedDetailLayout>
  );
};

/** ### Application-tab view layout
 * @todo convert to react-router
 * @deprecated
 */
const ApplicationTab: FC = () => (
  <AppTabViewProvider>
    <ApplicationTabView />
  </AppTabViewProvider>
);

/** ### Application-tab view layout
 * @todo Previous TODO stated to replace with above, seems to already be replaced. Keeping code for back and postarity.
 * @deprecated
 */
const ApplicationTabRoutes: FC = () => (
  <AppTabViewProvider>
    <Routes>
      <Route path="buyer" element={<ApplicationBuyer buyerOrCobuyer={BuyerType.Buyer} />} />
      <Route path="cobuyer" element={<ApplicationCoBuyer />} />
      <Route path="insurance" element={<SalesInsurance />} />
      <Route path="references" element={<ApplicationReferences />} />
      <Route path="underwriting" element={<ApplicationUnderwriting />} />
      <Route path="ai-underwriting" element={<AiUnderwritingTab />} />

      {/* Fallback */}
      <Route path="*" element={<Navigate to="./buyer" relative="path" replace={true} />} />
    </Routes>
  </AppTabViewProvider>
);

export default ApplicationTab;
