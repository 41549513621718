import { FC, PropsWithChildren, createContext } from "react";
import { useParams } from "react-router-dom";
// state
import { useSettingsViewCtx } from "../../AuthSettingsViewProvider";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useValidatedForm from "@/utils/forms/useValidatedForm";
import validateReqBody from "@/utils/forms/validateReqBody";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { AddUserAuthReq, UpdateUserAuthReq, UserAuthForm } from "./form";

const useCtxState = () => {
  const userRecIdParam = useParams().userRecId;
  const userRecId = getRouteParamNum(userRecIdParam);
  const isNew = userRecIdParam === "new";

  const activeUser = useSettingsViewCtx((s) => s.activeUser);

  const formState = useValidatedForm(UserAuthForm, {
    ...activeUser,
    defCompId: activeUser?.company?.recId,
    roleRecId: activeUser?.role?.recId,
  });
  const merged = AddUserAuthReq.merge(UpdateUserAuthReq);
  const reqBody = validateReqBody((isNew ? AddUserAuthReq : UpdateUserAuthReq) as typeof merged, {
    ...formState.form,
    userRecId,
  });

  return {
    isNew,
    formState,
    reqBody,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export const useUserAuthDetailCtx = useCtxFactory(Ctx);
const UserAuthDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default UserAuthDetailProvider;
