import { Route, Routes } from "react-router-dom";
import RepoCompanyTable from "./RepoCompanyTable";
import RepoCompanyProvider from "./RepoCompanyProvider";
import RepoCompanyDetail from "./RepoCompanyDetail";

const RepoCompanyRouter = () => {
  return (
    <RepoCompanyProvider>
      <Routes>
        <Route index element={<RepoCompanyTable />} />
        <Route path=":id" element={<RepoCompanyDetail />} />
      </Routes>
    </RepoCompanyProvider>
  );
}

export default RepoCompanyRouter;