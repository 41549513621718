import { toast } from "react-toastify";
// utils
import { systemService } from "@/services/systemService";
import { accountsService } from "@/services/accountsService";
import { inventoryService } from "@/services/inventoryService";
// interfaces
import { SetState } from "@/interfaces/utilityTypes";
import { GpsDevice, GpsInformation, PasstimeGetLastLocateRes } from "./interfaces";
import { IGpsCtx } from "./GpsProvider";

export const loadGpsLastLocate = async (
  vehRecId: number | undefined | null,
  gpsInformation: GpsInformation,
  setGpsLastLocateLoading: SetState<boolean>,
  setGpsLastLocation: SetState<IGpsCtx["gpsLastLocation"]>
) => {
  if (!vehRecId) {
    console.warn(`loadGpsLastLocate(): null/undefined 'vehRecId'`);
    return;
  }
  if (gpsInformation.device !== GpsDevice.enum.PassTime) {
    console.warn(`Unable to get GPS location for provider: '${gpsInformation.device}'`);
    return;
  }

  try {
    setGpsLastLocateLoading(true);
    const response = await systemService.getPasstimeLastLocate(vehRecId);
    if (!response) {
      toast.error("No data returned from PassTime: 'Last Locate'");
      return;
    }
    const newLastLocate = new PasstimeGetLastLocateRes(response);

    setGpsLastLocation(newLastLocate);
  } catch {
  } finally {
    setGpsLastLocateLoading(false);
  }
};

export const getGpsInfo = async (
  recId: number,
  recIdType: "colRecId" | "vehRecId",
  setGpsLoading: SetState<boolean>,
  setGpsInformation: SetState<IGpsCtx["gpsInformation"]>
) => {
  try {
    setGpsLoading(true);
    if (recIdType !== "colRecId" && recIdType !== "vehRecId") {
      throw new Error("RecIdType must be colRecId or vehRecId");
    }

    const response =
      recIdType === "colRecId"
        ? await accountsService.getGpsInfoFromColRecId(recId)
        : await inventoryService.getGpsInfoFromInvRecId(recId);

    setGpsInformation(response);
  } finally {
    setGpsLoading(false);
  }
};

export const forceUpdateGpsLocaton = async (invRecId: number, gpsInformation: GpsInformation) => {
  if (gpsInformation.device !== GpsDevice.enum.PassTime) {
    toast.error(`Unable to force update GPS location for provider ${gpsInformation.device}`);
    return;
  }
  try {
    await systemService.updatePasstimeMap(invRecId);
    toast.info(
      "Request sent to GPS provider. This can take about a minute, please check back then",
      { autoClose: false }
    );
  } catch {
    toast.error("Unable to force update GPS location");
  }
};
