export class LhPhFundingDb {
  constructor(
    public appRecId: number,
    public appStatus: string | null,
    public bNames: string | null,
    public buyerRecId: number,
    public cobuyRecId: number,
    public cod: number,
    public code3: string | null,
    public companyName: string | null,
    public compAppId: number,
    public compId: number,
    public fundAmt: number,
    public fundDate: Date,
    public fundDone: string | null,
    public fundDueDate: Date,
    public funded: boolean,
    public fundedOn: Date,
    public fundNum: number,
    public fundPmt: number,
    public fundPO: boolean,
    public fundPOAmt: number,
    public fundPODate: Date,
    public fundRate: number,
    public fundRepoSwap: boolean,
    public fundRequested: boolean,
    public invDays: number,
    public isCobuyer: boolean,
    public locId: number,
    public manLocation: number,
    public orgId: number,
    public realDown: number,
    public region: string | null,
    public ror: number,
    public saleDate: Date,
    public saleStatus: string | null,
    public saleType: string | null,
    public shortName: string | null,
    public stockNum: string | null,
    public term: number,
    public totalDown: number,
    public vehRecId: number,
    public vehYmm: string | null,
    public vin: string | null,
    public webYmm: string | null
  ) {}

  static new(newLhPhFundingRow: LhPhFundingDb): LhPhFundingDb {
    return new LhPhFundingDb(
      newLhPhFundingRow.appRecId,
      newLhPhFundingRow.appStatus,
      newLhPhFundingRow.bNames,
      newLhPhFundingRow.buyerRecId,
      newLhPhFundingRow.cobuyRecId,
      newLhPhFundingRow.cod,
      newLhPhFundingRow.code3,
      newLhPhFundingRow.companyName,
      newLhPhFundingRow.compAppId,
      newLhPhFundingRow.compId,
      newLhPhFundingRow.fundAmt,
      newLhPhFundingRow.fundDate,
      newLhPhFundingRow.fundDone,
      newLhPhFundingRow.fundDueDate,
      newLhPhFundingRow.funded,
      newLhPhFundingRow.fundedOn,
      newLhPhFundingRow.fundNum,
      newLhPhFundingRow.fundPmt,
      newLhPhFundingRow.fundPO,
      newLhPhFundingRow.fundPOAmt,
      newLhPhFundingRow.fundPODate,
      newLhPhFundingRow.fundRate,
      newLhPhFundingRow.fundRepoSwap,
      newLhPhFundingRow.fundRequested,
      newLhPhFundingRow.invDays,
      newLhPhFundingRow.isCobuyer,
      newLhPhFundingRow.locId,
      newLhPhFundingRow.manLocation,
      newLhPhFundingRow.orgId,
      newLhPhFundingRow.realDown,
      newLhPhFundingRow.region,
      newLhPhFundingRow.ror,
      newLhPhFundingRow.saleDate,
      newLhPhFundingRow.saleStatus,
      newLhPhFundingRow.saleType,
      newLhPhFundingRow.shortName,
      newLhPhFundingRow.stockNum,
      newLhPhFundingRow.term,
      newLhPhFundingRow.totalDown,
      newLhPhFundingRow.vehRecId,
      newLhPhFundingRow.vehYmm,
      newLhPhFundingRow.vin,
      newLhPhFundingRow.webYmm
    );
  }
}

export type ILhPhFundingDb = LhPhFundingDb;

export class LhPhFundingDetailDb {
  constructor(
    public purprice: number,
    public totexp: number,
    public fundExpAllow: number,
    public lhphIntRate: number,
    public fundRequested: boolean,
    public fundDone: string | null,
    public fundRepoSwap: boolean,
    public fundDate: string,
    public fundAmt: number,
    public fundNum: number,
    public fundPmt: number,
    public fundDueDate: string,
    public funded: boolean,
    public fundedon: string,
    public fundPO: boolean,
    public fundPODate: string,
    public fundPOAmt: number,
    public appRecId: number,
    public recId: number,
    public saledate: string,
    public salestatus: string | null,
    public stocknum: string | null,
    public legalName: string | null,
    public leinholder: number,
    public amtFin: number,
    public lhphFundDay: number,
    public lhphCutOffDay: number,
    public lhphMaxTerm: number,
    public lhphMaxRecon: number,
    public lhphFundRate: number,
    public term: number,
    public code3: string | null,
    public locid: number,
    public orgid: number
  ) {}

  static new(newLhPhFundingDetail: LhPhFundingDetailDb): LhPhFundingDetailDb {
    return new LhPhFundingDetailDb(
      newLhPhFundingDetail.purprice,
      newLhPhFundingDetail.totexp,
      newLhPhFundingDetail.fundExpAllow,
      newLhPhFundingDetail.lhphIntRate,
      newLhPhFundingDetail.fundRequested,
      newLhPhFundingDetail.fundDone,
      newLhPhFundingDetail.fundRepoSwap,
      newLhPhFundingDetail.fundDate,
      newLhPhFundingDetail.fundAmt,
      newLhPhFundingDetail.fundNum,
      newLhPhFundingDetail.fundPmt,
      newLhPhFundingDetail.fundDueDate,
      newLhPhFundingDetail.funded,
      newLhPhFundingDetail.fundedon,
      newLhPhFundingDetail.fundPO,
      newLhPhFundingDetail.fundPODate,
      newLhPhFundingDetail.fundPOAmt,
      newLhPhFundingDetail.appRecId,
      newLhPhFundingDetail.recId,
      newLhPhFundingDetail.saledate,
      newLhPhFundingDetail.salestatus,
      newLhPhFundingDetail.stocknum,
      newLhPhFundingDetail.legalName,
      newLhPhFundingDetail.leinholder,
      newLhPhFundingDetail.amtFin,
      newLhPhFundingDetail.lhphFundDay,
      newLhPhFundingDetail.lhphCutOffDay,
      newLhPhFundingDetail.lhphMaxTerm,
      newLhPhFundingDetail.lhphMaxRecon,
      newLhPhFundingDetail.lhphFundRate,
      newLhPhFundingDetail.term,
      newLhPhFundingDetail.code3,
      newLhPhFundingDetail.locid,
      newLhPhFundingDetail.orgid,
    );
  }
}

export type ILhPhFundingDetail = LhPhFundingDetailDb;
