import { zNumeric } from "../forms/fieldSchemas";
import { isNumber } from "../helpers/general";

/** If value is not numeric, return null
 * @deprecated remove - replace with `getNumber()`
 */
export const getRouteParamNum = (routeParam: string | number | undefined | null): number | null => {
  // @todo replace with `getNumber(routeParam)`
  return zNumeric.safeParse(routeParam).data ?? null;
};
/** Typesafe route param for views that can be either a number or 'new' */
export const getFormParam = (
  routeParam: string | number | undefined | null
): number | "new" | null => {
  const isInvalid = typeof routeParam !== "number" && typeof routeParam !== "string";
  if (isInvalid) return null;

  if (routeParam === "new") return routeParam;
  return isNumber(routeParam) ? Number(routeParam) : null;
};

/** Get typed route parameters, non-numeric */
// export const getRouteParams = <TOutput extends object>(
//   params: Readonly<{ [key in keyof TOutput]?: string | undefined }>
// ): Nullable<TOutput> => {
//   const rtn: TOutput = {} as TOutput;
//   const entries = Object.entries(params) as [keyof TOutput, string][];

//   entries.forEach(([key, value]) => {
//     rtn[key] = value || null;
//   });
//   return isNaN(Number(routeParam)) ? null : Number(routeParam);
// };
