import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// kendo
import {  Button, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
// utils
import useFetchAndNavAccountDetail from '../useFetchAndNav';
import { customerService } from '@/services/customerService';
import { formatPhoneE164 } from '@/utils/helpers/general';
import { getRouteParamNum } from '@/utils/routing/formatting';

/**
 * @note `accountInformation !== null` is validated in parent component: `Quick-Actions-Modal`
 *
 * @deprecated needs refactor
 */
const SendTextToOptIn: FC = () => {
  const params = useParams();
  const loc = useLocation();
  const dispatch = useAppDispatch();
  const colRecId = getRouteParamNum(params.colRecId);
  const fetchAndNav = useFetchAndNavAccountDetail();
  // Redux state
  const buyerCellPhone = useAccountSelector((s) => s.accountInformation!.buyer.cellPhone);
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const isLegalStatus = useAccountSelector((s) => s.accountInformation!.legalStatus);
  const shouldNotTextBuyer = useAccountSelector((s) => s.contactInformation.buyer.noText);
  const sendTextToPayResponse = useAccountSelector((s) => s.sendTextToPayResponse);

  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);

  // Event handlers
  const onCloseButtonClick = () => dispatch(accountActions.setQuickActionsModalOpened(false));

  if (shouldNotTextBuyer) {
    return <div>Buyer has no text preference on</div>;
  } else if (isLegalStatus) {
    return <div>Account in legal status, no contact can be made</div>;
  } else if (sendTextToPayResponse) {
    return <div>{sendTextToPayResponse}</div>;
  } else {
    return (
      <div>
        <div style={{ wordWrap: 'break-word', width: '700px' }}>
          The cell phone {buyerCellPhone || ''} has not Opted In to receive text messages. You must
          send the Opt In text and have the customer accept or you will not be able to text the
          customer with our system. Would you like to send the Opt In message now?
        </div>
        <Spacer size={20} />
        <Button
          label="Yes"
          onClick={() =>
            customerService
              .beginOptIn(formatPhoneE164(buyerCellPhone), Number(appRecId))
              .then(() => {
                fetchHotlist(appRecId, 'Account', 'Active');
                // Fetch info, update state and navigate to acct-detail base-route
                const browserPath = loc.pathname.replace(params['*'] || ' ', '');
                colRecId && fetchAndNav(browserPath, colRecId);
              })
              .catch((err) => {
                console.error('Error beginning opt in process', err);
                throw err;
              })
          }
        />
        <Spacer size={4} />
        <Button label="No" fillMode="outline" onClick={onCloseButtonClick} />
      </div>
    );
  }
};

export default SendTextToOptIn;
