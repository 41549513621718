import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from '@/mui/components/form/MuiKendoDatePicker';
// components
import DateRangeDropdown from './DateRangeDropdown';
// state
import { useTableCtx } from '../../TableProvider';
// utils
import { DateRangeOptionKey, dateRangeOptions, formatDateTz } from '@/general/dates';

dayjs.extend(utc);

// Set initial date range
const initDateRangeKey: DateRangeOptionKey = 'This Month-to-date';
const { beginDate, endDate } = dateRangeOptions[initDateRangeKey];

/** ### Two fields: 'date from' and 'date to'
 * @note Date range should always be set
 */
const DateFilter: FC<{ label: string }> = ({ label }) => {
  const [inputValue, setInputValue] = useState<DateRangeOptionKey>(initDateRangeKey);
  const dateRangeFilter = useTableCtx((s) => s.dateRangeFilter);
  const updateDateRangeFilter = useTableCtx((s) => s.updateDateRangeFilter);
  const setDateRangeFilter = useTableCtx((s) => s.setDateRangeFilter);
  const initDateRangeFilter = useTableCtx((s) => s.initDateRangeFilter);
  const isDateFilterError = useTableCtx((s) => s.isDateFilterError);

  const fromDate = dateRangeFilter?.from;
  const toDate = dateRangeFilter?.to;

  // Event handlers
  const handleDateFieldChange = (formType: 'from' | 'to') => (e: dayjs.Dayjs | null) => {
    updateDateRangeFilter(e || dayjs(), formType);
  };

  useEffect(() => {
    initDateRangeFilter();
  }, []);

  const dateFromValue = formatDateTz(fromDate ? fromDate : beginDate, true);
  const dateToValue = formatDateTz(toDate ? toDate : endDate, true);

  return (
    <Grid container direction="row" alignItems="center" columnGap={1} wrap="nowrap">
      <DatePicker
        label={`'${label}' From`}
        value={dateFromValue}
        onChange={handleDateFieldChange('from')}
        error={isDateFilterError}
      />
      <DatePicker
        label={`'${label}' To`}
        value={dateToValue}
        onChange={handleDateFieldChange('to')}
        error={isDateFilterError}
      />
      <DateRangeDropdown
        inputValue={inputValue}
        setInputValue={setInputValue}
        setDateRangeFilter={setDateRangeFilter}
      />
    </Grid>
  );
};

export default DateFilter;
