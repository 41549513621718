import { Context, useContext } from "react";

/**
 * ### Usage:
 * ```tsx
 * const useCtxState = () => {
 *   const [isLoading, setIsLoading] = useState(false);
 *
 *   return {
 *     isLoading,
 *     setIsLoading,
 *   };
 * };
 *
 * type IMyCtx = ReturnType<typeof useCtxState>;
 * const Ctx = createContext<IMyCtx | null>(null);
 * export const useMyCtx = useCtxFactory(Ctx);
 *
 * const MyProvider: FC<PropsWithChildren> = ({ children }) => (
 *   <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
 * );
 * export default MyProvider;
 * ```
 */
const useCtxFactory =
  <TCtx,>(Ctx: Context<TCtx | null>) =>
  <TField,>(selector: (state: TCtx) => TField): TField => {
    const ctx = useContext(Ctx);

    if (!ctx) {
      const selectorName = `use${Ctx.displayName}`;
      const providerName = Ctx.displayName?.replace("Ctx", "Provider");
      throw new Error(`${selectorName} must be used within ${providerName}`);
    }

    return selector(ctx);
  };

export default useCtxFactory;
