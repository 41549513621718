// interfaces
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import { CollectionActivityRowData } from '../../interfaces';

export const tableColumns: MuiColumnDef<CollectionActivityRowData>[] = [
  { field: 'companyName', headerName: 'Location', flex: 1, minWidth: 120 },
  { field: 'numCalls', headerName: 'Phone Calls', minWidth: 100 },
  { field: 'numTexts', headerName: 'Text Messages', minWidth: 125 },
  { field: 'numRoboCalls', headerName: 'Robo Calls' },
  { field: 'numLetters', headerName: 'Collection Letters', minWidth: 145 },
  { field: 'numEmails', headerName: 'Collection Emails', minWidth: 140 },
  { field: 'numFC', headerName: '# FC', width: 70 },
  { field: 'numOR', headerName: '# OR' },
  { field: 'numIOP', headerName: '# IOP' },
  { field: 'numPA', headerName: '# PA' },
  { field: 'numAppointments', headerName: '# Appts' },
];
