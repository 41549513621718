import { FC, ReactNode, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import NestedDetailHeaderLayout from "@/mui/components/layout/NestedDetailHeaderLayout";
// kendo
import { Icons } from "@/components/icons";
import { Loader } from "@/components/loader/Loader";
// components
import AppPdfLayout from "./AppPdfLayout";
import { CashBuyer } from "./subviews/buyer/CashBuyer"; 
import { CashCoBuyer } from "./subviews/coBuyer/CashCoBuyer"; 
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useAppTabViewCtx } from "./AppTabViewProvider";
import { SaleType } from "@/enums/general";
// utils
import { salesService } from "@/services/salesService";
import { scienapticService } from "@/services/scienapticService";
import { tabInfoList } from "./default";
// interfaces
import { ApplicationDb } from "@/features/Sales/interfaces/application";
import { ApplicationTab } from "./interfaces";
import { BuyerType } from "@/enums/general";
// style
import { COLOR_INACTIVE, DCS_BLUE, HOVER_TRANSITION_SPEED } from "@/mui/theme/colors";
import useReq from "@/utils/useReq";
import { useParams } from "react-router-dom";

const handlePrintApplication =
  (applicationData: ApplicationDb, setIsPrinting: (v: boolean) => void) => async (_: any) => {
    try {
      setIsPrinting(true);
      const htmlMarkup = ReactDOMServer.renderToStaticMarkup(
        <AppPdfLayout applicationData={applicationData} />
      );
      const pdfData = await salesService.printApplicationPdf(htmlMarkup);
      const fileURL = URL.createObjectURL(pdfData);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } finally {
      setIsPrinting(false);
    }
  };

/** @deprecated convert to React Router + MUI */
const NavTab: FC<{ tabInfo: { title: ApplicationTab; component: ReactNode } }> = ({
  tabInfo: { title },
}) => {
  const currentTab = useAppTabViewCtx((s) => s.currentTab);
  const setCurrentTab = useAppTabViewCtx((s) => s.setCurrentTab);

  const isActive = currentTab === title;

  return (
    <Grid
      fontSize={16}
      sx={{
        color: isActive ? DCS_BLUE : COLOR_INACTIVE,
        fontWeight: isActive ? 700 : 500,
        borderTop: "2px solid transparent",
        borderBottom: isActive ? `2px solid ${DCS_BLUE}` : "2px solid transparent",
        transitionProperty: "border-bottom-color, font-weight",
        transitionDuration: HOVER_TRANSITION_SPEED,
        "&:hover": { borderBottom: `2px solid ${DCS_BLUE}`, cursor: "pointer" },
      }}
      fontWeight={500}
      onClick={() => setCurrentTab(title)}
    >
      {title}
    </Grid>
  );
};

/** ###
 * @deprecated convert to React Router + MUI
 */
const AppTabHeader: FC = () => {
  const applicationData = useSalesSelector((s) => s.applicationData);
  const [isPrinting, setIsPrinting] = useState(false);
  const [showAutoLendIQ, setShowAutoLendIQ] = useState(false);

  const appRecID = useParams().id;

  const scienapticValidation = useReq(async () => {
    if (!appRecID) return;

    const anyActiveCred = await scienapticService.getScienapticCredentialsByOrgId();
    setShowAutoLendIQ(anyActiveCred ?? false);
    return;
  });

  useEffect(() => {
    scienapticValidation.load();
  }, []);

  const saleData = useSalesSelector((s) => s.saleData);
  const isCashSale = saleData.sale?.saletype === "Cash" ;

  // Build tab elems, sale type switch
  const navTabElems = tabInfoList.map((tabInfo) => {
    if((isCashSale && tabInfo.title === 'Buyer')){
      tabInfo.component = <CashBuyer buyerOrCobuyer={BuyerType.Buyer} />
      return <NavTab tabInfo={tabInfo} key={tabInfo.title} />;
    }
    if((isCashSale && tabInfo.title === 'Co-Buyer')){
      tabInfo.component = <CashCoBuyer />
      return <NavTab tabInfo={tabInfo} key={tabInfo.title} />;
    }
    if(isCashSale){
      //this return prevents other navigation from displaying.
      return
    }
    if ((tabInfo.title == "AutoLend IQ" && showAutoLendIQ) || tabInfo.title !== "AutoLend IQ") {
      return <NavTab tabInfo={tabInfo} key={tabInfo.title} />;
    }
    return;
  });

  return (
    <NestedDetailHeaderLayout
      headerTitle={
        <Grid fontSize={20} fontWeight={700}>
          {isCashSale ? 'Buyer Information' : 'Application'}
        </Grid>
      }
    >
      <Grid
        container
        direction="row"
        flex={1}
        justifyContent="end"
        gap={3}
        wrap="nowrap"
        sx={{ overflowX: "scroll" }}
      >
        {navTabElems}
      </Grid>
      <IconButton onClick={handlePrintApplication(applicationData, setIsPrinting)}>
        {isPrinting ? <Loader /> : <Icons.Print />}
      </IconButton>
    </NestedDetailHeaderLayout>
  );
};

export default AppTabHeader;
