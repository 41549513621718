import { FC, ReactNode } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import AccordionDetails from '@mui/material/AccordionDetails';
import SectionColumn from './SectionColumn';

const SplitColumnLayout: FC<
  Grid2Props & {
    leftColElems: ReactNode | ReactNode[];
    rightColElems: ReactNode | ReactNode[];
    colProps?: Grid2Props;
  }
> = ({ leftColElems, rightColElems, colProps, sx, ...gridProps }) => {
  return (
    <Grid container direction="row" flex={1} gap={2} p={2} pt={0} sx={{ ...sx }} {...gridProps}>
      <SectionColumn {...colProps}>{leftColElems}</SectionColumn>
      <SectionColumn {...colProps}>{rightColElems}</SectionColumn>
    </Grid>
  );
};

/** ###  */
const SectionBody: FC<
  Grid2Props & {
    leftColElems?: ReactNode | ReactNode[];
    rightColElems?: ReactNode | ReactNode[];
    colProps?: Grid2Props;
  }
> = ({ leftColElems = [], rightColElems = [], children, colProps, ...props }) => {
  const isSplit = children === undefined && leftColElems && rightColElems;

  return (
    <AccordionDetails sx={{ mr: 0, ...props.sx }}>
      {isSplit ? (
        <SplitColumnLayout
          leftColElems={leftColElems}
          rightColElems={rightColElems}
          colProps={colProps}
          {...props}
        />
      ) : (
        <SectionColumn gap={2} p={2} {...props}>
          {children}
        </SectionColumn>
      )}
    </AccordionDetails>
  );
};

export default SectionBody;
