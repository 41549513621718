export const supportModalDefaultValues = {
  summary: '',
  description: '',
  issueType: 'Technical Support',
  priority: { label: 'Low', id: 4 },
  contactEmail: false,
  contactPhone: false,
  email: '',
  phoneNumber: '',
  extension: '',
};
