/** ### @note moved for readability - this should just be implemented normally. */
export const appPdfCss = `
  
  table {
      border-collapse: collapse;
      width: 100%;
      font-family: Arial, sans-serif;
         font-size: 10px;
      border="0"    
    }
  
  .tB{
    border-top:1px solid #000000;
  }
  .rB{
    border-right:1px solid #000000;
  }
  .bB{
    border-bottom:1px solid #000000;
  }
  .lB{
    border-left:1px solid #000000;
  }
  .noBorderB {
   border-bottom:none
    }
  .noBorderT {
   border-top:none
    }
  body {
    -webkit-print-color-adjust: exact;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  th {
      border-top:1px solid #000000;
        line-height: 10px;
        font-weight: bold;
        padding: 4px 0px 0px 0px;
        text-align: left;
        color: #686868;
  }
  td {
  
        line-height: 10px;
        font-size: 11px;
        padding: 0px 0px 4px 0px;
        text-align: left; 
  }
  .headerSection {
      width: 100%;
      background-color: #DBEAF7; 
      color: #000000; 
      text-align: center; 	
      font-weight: bold;
      padding: 20px 0px 20px 0px;
       height="80"
  }
  .headerSection2 {
      width: 100%;
      background-color: #DBEAF7; 
      color: #000000; 
      text-align: center; 	
      font-weight: bold;
      padding: 10px 0px 10px 0px;
       height="40"
  }
  
  .sec2 {
       line-height: 1.6;
  }
   
  `;
