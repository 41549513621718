import { ChangeEvent, FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import { GridToolbarQuickFilter } from "@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter";
// utils
import { SetState } from "@/interfaces/utilityTypes";

const { notchedOutline, input, focused } = outlinedInputClasses;

type ITextSearchFilterState = {
  textSearchFilter: string | null;
  setTextSearchFilter: SetState<string | null>;
};

/** ###  */
const TableToolbar: FC<{
  placeholder: string;
  disabled: boolean;
  ButtonGroup?: FC;
  /** Optionally provide external state. */
  textSearchFilterState?: ITextSearchFilterState;
}> = ({ placeholder, disabled, ButtonGroup, textSearchFilterState }) => {
  // Event handlers
  const handleSearch = textSearchFilterState
    ? (e: ChangeEvent<HTMLInputElement>) =>
        textSearchFilterState.setTextSearchFilter(e.target.value)
    : undefined;

  return (
    <Grid component={GridToolbarContainer} container direction="row" justifyContent="space-between">
      <GridToolbarQuickFilter
        variant="outlined"
        placeholder={placeholder}
        size="small"
        disabled={disabled}
        // @todo move these props and styles to default theme - componentDefaults.tsx
        InputProps={{
          startAdornment: undefined,
          endAdornment: undefined,
          sx: {
            fontSize: 14,
            m: 0,
            background: "rgb(255, 255, 255)",
            borderRadius: 2,
            width: "200px",

            [`& .${input}`]: { p: 1 },
            [`& .${notchedOutline}`]: { borderRadius: "8px" },

            [`&:hover .${notchedOutline}`]: { borderColor: "rgba(0, 0, 0, 0.35)" },
            [`&.${focused} .${notchedOutline}`]: { borderColor: "rgb(204, 204, 204)" },
          },
        }}
        value={textSearchFilterState?.textSearchFilter}
        onChangeCapture={handleSearch}
      />
      {ButtonGroup && <ButtonGroup />}
    </Grid>
  );
};

export default TableToolbar;
