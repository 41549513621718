import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
import { OdometerTrackingList } from './OdometerTrackingList';

export const OdometerTracking = (props: { isInventory?: boolean }) => {
  const { isInventory = false } = props;

  return (
    <AccountsMainPanel navBarTitle="Odometer Tracking">
      <OdometerTrackingList isInventory={isInventory} />
    </AccountsMainPanel>
  );
};
