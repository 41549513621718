import { UseFormSetValue } from 'react-hook-form';
import { toast } from 'react-toastify';
// utils
import { companyService } from '@/services/companyService';
// interfaces
import { CreateSupportPostForm } from './interfaces';

export const initSupport = async (
  setValue: UseFormSetValue<CreateSupportPostForm>,
  isLoggedIn: boolean
) => {
  if (!isLoggedIn) return;

  try {
    const userData = await companyService.getSupportUserData();
    if (userData) {
      setValue('email', userData.userId);
      setValue('phoneNumber', userData.phoneCell);
    } else throw new Error();
  } catch (error) {
    console.error(error);
    toast.error('Error fetching support user data');
  }
};
