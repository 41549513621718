import { FC } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
// components
import PdfSection from './PdfSection';
import PdfTable from './PdfTable';
// interfaces
import { PdfColumnMap, PdfColumnInfo } from './interfaces';

const styles = StyleSheet.create({
  headerContainer: {
    borderBottom: '1px solid #ababab',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: 700,
  },
  table: {
    width: '100%',
    display: 'flex',
  },
});

/** ### Displays a default-formatting for a PDF section containing a table */
const PdfTableSection: FC<{
  header?: string;
  columnLabelMap?: PdfColumnMap;
  rows: any[];
}> = ({ header, columnLabelMap, rows }) => {
  if (rows.length < 1 && !columnLabelMap) return <></>;

  // If no map is provided, use row keys as column headers
  const appliedColumnLabelMap =
    columnLabelMap ??
    new Map<string, PdfColumnInfo>(Object.keys(rows[0]).map((c) => [c, { label: c }]));

  return (
    <PdfSection
      style={styles.table}
      header={header}
      headerProps={{
        style: { ...styles.headerContainer },
        textProps: { style: styles.headerText },
      }}
    >
      <PdfTable columnLabelMap={appliedColumnLabelMap} rows={rows} />
    </PdfSection>
  );
};

export default PdfTableSection;
