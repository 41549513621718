import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerBuyer } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** @deprecated Needs refactor */
const LedgerCardBuyerInfo: FC<{ buyer: LedgerBuyer; type: 'buyer' | 'cobuyer' }> = ({
  buyer,
  type,
}) => (
  <>
    <div style={ledgerStyles.sectionHeader}>
      {type === 'buyer' ? 'Buyer' : 'Co-Buyer'} Information
    </div>
    <table style={ledgerStyles.ledgerTable}>
      <tbody>
        <tr style={{ ...ledgerStyles.labelRow, ...ledgerStyles.topLine }}>
          <td style={ledgerStyles.labelRowTd}>
            {type === 'buyer' ? 'Primary Buyers' : 'Co-Buyers'} Legal Name
          </td>
          <td style={ledgerStyles.labelRowTd}>Nickname</td>
          <td style={ledgerStyles.labelRowTd}>Date of Birth</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            Age
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>
            {buyer.firstName} {buyer.lastName}
          </td>
          <td>{buyer.nickName}</td>
          <td>{formatDate(buyer.dateOfBirth)}</td>
          <td colSpan={2}>{buyer.age}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Social Security No</td>
          <td style={ledgerStyles.labelRowTd}>Drivers License No</td>
          <td style={ledgerStyles.labelRowTd}>State</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            Expiration Date
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>***-**-{buyer.socialSecurityLast4}</td>
          <td>{buyer.driversLicenseNumber}</td>
          <td>{buyer.driversLicenseState}</td>
          <td colSpan={2}>{formatDate(buyer.driversLicenseExpiration)}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Present Address</td>
          <td colSpan={4} style={ledgerStyles.labelRowTd}>
            County
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>{buyer.address}</td>
          <td colSpan={4}>{buyer.county}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td colSpan={5} style={ledgerStyles.labelRowTd}>
            Mailing Address
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td colSpan={5}>{buyer.mailingAddress}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Home Phone</td>
          <td style={ledgerStyles.labelRowTd}>Cell Phone</td>
          <td style={ledgerStyles.labelRowTd}>Other Phone</td>
          <td style={ledgerStyles.labelRowTd}>Type</td>
          <td style={ledgerStyles.labelRowTd}>Email</td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>{buyer.homePhone}</td>
          <td>{buyer.cellPhone}</td>
          <td>{buyer.otherPhone}</td>
          <td>{buyer.type}</td>
          <td>{buyer.email}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Residence Type</td>
          <td style={ledgerStyles.labelRowTd}>Mortgage Holder</td>
          <td style={ledgerStyles.labelRowTd}>Phone</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            Fax
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>{buyer.residenceType}</td>
          <td>{buyer.mortgageHolder}</td>
          <td>{buyer.mortgageHolderPhone}</td>
          <td colSpan={2}>{buyer.mortgageHolderFax}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Primary Employer</td>
          <td style={ledgerStyles.labelRowTd}>Address</td>
          <td style={ledgerStyles.labelRowTd}>Work Phone</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            Supervisor's Name
          </td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>{buyer.employer}</td>
          <td>{buyer.employerAddress}</td>
          <td>{buyer.workPhone}</td>
          <td colSpan={2}>{buyer.supervisorsName}</td>
        </tr>
        <tr style={{ ...ledgerStyles.labelRow }} className={'top-line'}>
          <td style={ledgerStyles.labelRowTd}>Occupation</td>
          <td style={ledgerStyles.labelRowTd}>Employed For</td>
          <td style={ledgerStyles.labelRowTd}>Type of Employment</td>
          <td style={ledgerStyles.labelRowTd}>Wage or Salary</td>
          <td style={ledgerStyles.labelRowTd}>Day Paid</td>
        </tr>
        <tr style={ledgerStyles.minHeight}>
          <td>{buyer.occupation}</td>
          <td>{buyer.employedFor}</td>
          <td>{buyer.typeOfEmployment}</td>
          <td>
            {formatCurrency(parseFloat(buyer.wageOrSalary.toString()) || 0)}
          </td>
          <td>{buyer.dayPaid}</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default LedgerCardBuyerInfo;
