import { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Icons } from '@/components/icons';
// components
import DealsQuotesDetailHeader from './DealsQuotesDetailHeader';
import LeaseTab from '../../components/desking/deskingTabs/quickQuote/leaseTab/LeaseTab';
import FinanceTab from '../../components/desking/deskingTabs/quickQuote/financeTab/FinanceTab';
import { ComparisonTool } from '../../components/desking/deskingTabs/comparisonTool/ComparisonTool';
import NavTabs from '../../components/desking/NavTabs';
// state
import { useSalesDetailCtx } from '../SalesDetailProvider';
import DealsQuotesDetailProvider, {
  useDealsQuotesDetailCtx,
  useHandlePrint,
} from './DealsQuotesDetailProvider';
// styles
import styles from '../DealsQuotesDetail/DealsQuotes.module.scss';

/** ###  */
const DealsQuotesDetailLayout: FC = () => {
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);
  const printTargetRef = useDealsQuotesDetailCtx((s) => s.printTargetRef);

  const handlePrint = useHandlePrint(printTargetRef);

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    setHeaderTitle(<DealsQuotesDetailHeader />);
  }, []);

  return (
    <Grid
      container
      direction="column"
      flex={1}
      wrap="nowrap"
      sx={{ overflowX: 'hidden', overflowY: 'hidden' }}
    >
      <Grid
        container
        direction="row"
        flex={1}
        justifyContent="space-between"
        gap={1}
        py={2}
        px={4}
        my={2}
        sx={{ backgroundColor: 'rgb(239, 240, 244)' }}
        borderRadius={3}
        alignItems="center"
      >
        <NavTabs />
        <Icons.Print className={styles.printIcon} onClick={handlePrint} />
      </Grid>
      <Outlet />
    </Grid>
  );
};

const DealsQuotesDetail: FC = () => {
  return (
    <DealsQuotesDetailProvider>
      <Routes>
        <Route element={<DealsQuotesDetailLayout />}>
          <Route path={`finance`} element={<FinanceTab />} />
          <Route path={`lease`} element={<LeaseTab />} />
          <Route path={`comparison`} element={<ComparisonTool />} />
          <Route path="/" element={<Navigate to={`finance`} replace={true} />} />
          <Route path="*" element={<Navigate to={`finance`} replace={true} />} />
        </Route>
      </Routes>
    </DealsQuotesDetailProvider>
  );
};
export default DealsQuotesDetail;
