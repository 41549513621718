import { FC } from 'react';
// kendo
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

/** Apply global state managed text search filtering for a given table */
const TextSearchFilter: FC<{
  tableState: {
    textSearchFilter: string | null;
    setTextSearchFilter: (textSearchFilter: string | null) => void;
  };
  searchFilterPlaceholder?: string;
  disableSearchFilter?: boolean;
}> = ({ tableState, searchFilterPlaceholder = 'Search Filter', disableSearchFilter = false }) => {
  const { textSearchFilter, setTextSearchFilter } = tableState;
  const handleTextSearchFilterChange = (e: InputChangeEvent) => setTextSearchFilter(e.value);
  return (
    <Input
      style={{ minWidth: '200px' }}
      onChange={handleTextSearchFilterChange}
      placeholder={searchFilterPlaceholder}
      disabled={disableSearchFilter}
      value={textSearchFilter || ''}
    />
  );
};

export default TextSearchFilter;
