import { FC, useRef } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// kendo
import { ListView, Popup } from "@progress/kendo-react-all";
import { ClickOutside } from "@components";
// components
import CustomerAdminItemView from "../CustomerAdminItemView";
import NavTab from "./NavTab";
// state
import { useAppDispatch } from "@/store/store";
import { accountActions, useAccountSelector } from "@/features/Accounts/accountSlice";
import useAccountDetails from "../../useAccountDetails";
// interfaces
import { AccountTabRoutes } from "../../interfaces";
// style
import { DCS_BLUE } from "@/mui/theme/colors";

/** ###
 * @todo convert to mui <Tabs> component
 */
const NavTabs: FC<{ hasTabs: boolean }> = ({ hasTabs }) => {
  /** @deprecated remove - ref is not necessary here */
  const manageAnchorRef = useRef(null);

  const dispatch = useAppDispatch();

  const { customerAdminList } = useAccountDetails();
  // local state
  const customerAdminListOpen = useAccountSelector((s) => s.customerAdminListOpen);

  if (!hasTabs) return <></>;
  return (
    <>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        sx={{ height: "100%", fontSize: 16, letterSpacing: -0.32 }}
      >
        {/* @todo convert to mui <Tabs> component */}
        <NavTab end to={AccountTabRoutes.Activity} name="Activity" />
        <NavTab to={AccountTabRoutes.Contact} name="Contact" />
        <NavTab to={AccountTabRoutes.Employer} name="Employer" />
        <NavTab to={AccountTabRoutes.Insurance} name="Insurance" />
        <NavTab to={AccountTabRoutes.References} name="References" />
        <NavTab to={AccountTabRoutes.AccountInfo} name="Account Info" />
        <NavTab name="Manage" anchorRef={manageAnchorRef} />
      </Grid>

      <Popup
        anchor={manageAnchorRef.current}
        show={customerAdminListOpen}
        popupAlign={{ vertical: "top", horizontal: "right" }}
        anchorAlign={{ vertical: "bottom", horizontal: "right" }}
      >
        <ClickOutside
          onClick={() => dispatch(accountActions.setCustomerAdminListOpen(false))}
          exceptionRef={manageAnchorRef}
        >
          <Grid
            sx={{
              background: "#fff",
              border: `1px solid ${DCS_BLUE}`,
              borderRadius: "0.75em",
              p: "0.7em 0",
            }}
          >
            <ListView data={customerAdminList} item={CustomerAdminItemView} />
          </Grid>
        </ClickOutside>
      </Popup>
    </>
  );
};

export default NavTabs;
