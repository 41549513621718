import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { AccountDb } from '../interfaces';
// style
import { COLOR_DANGER } from '@/mui/theme/colors';

// @todo constrain types
const ResidualDueCell: FC<GridCellProps> = ({ dataItem }) => {
  const { residual, daysLate } = dataItem as AccountDb;
  const isNull = residual === null;
  const color = daysLate > 0 && !isNull ? COLOR_DANGER : undefined;
  const fontWeight = daysLate > 0 ? 700 : undefined;

  const residualFmt = isNull ? '-' : formatCurrency(residual);
  return (
    <td className="k-table-td" style={{ textAlign: 'end' }}>
      <span style={{ fontWeight, color }}>{residualFmt}</span>
    </td>
  );
};

export default ResidualDueCell;
