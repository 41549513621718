import { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AddOnsSalesTaxTable from "./AddOnsSalesTaxTable";
import { useAddOnsSalesTaxCtx } from "./AddOnsSalesTaxProvider";
import { Loader } from "@progress/kendo-react-all";

const AddOnsSalesTaxList = () => {
  const profiles = useAddOnsSalesTaxCtx((s) => s.profiles);
  const handleRowClick = useAddOnsSalesTaxCtx((s) => s.handleRowClick);
  const [isLoading, setIsLoading] = useState(false);

  const headerText = `Tax/Addon Profiles (${profiles?.length})`;

  return (
    <Grid2 container direction="column" flex={1} boxSizing={'border-box'}>
      <Grid2 direction='row' flex={1} fontSize='18px' fontWeight='700'>{headerText}</Grid2>
      {isLoading ? (
        <Loader size="large" />
      ) : profiles ? (
        <AddOnsSalesTaxTable rows={profiles} handleRowClick={handleRowClick} />
      ) : (
        <p>No data available</p>
      )}
    </Grid2>
  );
};

export default AddOnsSalesTaxList;
