import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// components
import { Modal } from "@/components/modals/Modal";
import FormLayout from './FormLayout';
import ModalSubmitButtons from './ModalSubmitButtons';
// hooks
import { useAuthSelector } from '@/features/auth/authSlice';
import { useReportsViewCtx } from '../state/view';
import { useERSContext } from './state';
// utils
import { reportsService } from '@/services/reportsService';
// interfaces
import { ERSFmt, ERSForm } from './interfaces';
import {
  ERSFiletype,
  ERSFiletypeKey,
  ReportDateRange,
  ReportDateRangeKey,
  ReportFrequency,
  ReportFrequencyKey,
} from './default';
import { ReportsSubviews } from '../viewInfo';

/** ### Shows fields to customize reports
 *
 * These fields render based on certain conditions from the `report` payload.
 * - Modifying these fields updates the "Reports" view state:
 *    - These report-modifier values are used in the request.
 * - Button is used to submit request to generate report.
 */
const ERSDetailView: FC = () => {
  const orgId = useAuthSelector((s) => s.orgId);
  const subview = useReportsViewCtx((s) => s.subview);
  const isModalOpen = useReportsViewCtx((s) => s.isModalOpen);
  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const { addERS, updateERS, selectedERS, setSelectedERS, httpVerb, setHttpVerb } = useERSContext();

  // Initialize form
  const formMgmt = useForm<ERSForm>({
    defaultValues: {
      reportRecId: selectedERS?.reportRecId,
      compId: selectedERS?.compId,
      emailTo: selectedERS?.emailTo || '',
      fileType: selectedERS?.fileType || (ERSFiletype.XLSX as ERSFiletypeKey),
      scheduleType: selectedERS?.scheduleType || (ReportFrequency[1] as ReportFrequencyKey),
      runTimeLocal:
        selectedERS?.runTimeLocal || new Date(new Date(new Date().setHours(9)).setMinutes(0)),
      runDays: selectedERS?.runDays || [],
      runDate: selectedERS?.runDate || 1,
      runLastDayOfMonth: selectedERS?.runLastDayOfMonth || false,
      dateRangeType: selectedERS?.dateRangeType || (ReportDateRange[1] as ReportDateRangeKey),
      xDaysPrior: selectedERS?.xDaysPrior || 0,
    },
    mode: 'all',
  });

  // Event handlers
  const handleCloseExportModal = () => {
    setIsModalOpen(false);
    // Clear report schedule in state
    setSelectedERS(null);
    // Clear form
    formMgmt.reset();
  };

  const handleSubmitRequest = async (ersForm: ERSForm) => {
    if (httpVerb === 'POST') {
      const postPayload = ERSForm.toPostPayload(ersForm);
      try {
        const res = await reportsService.createEmailReportSchedule(postPayload);
        const {
          data: { data: createdERS },
        } = res;
        if (res.status !== 200) {
          toast.error(`Incorrect response: ${res.status} ${res.statusText}`);
          console.error('POST request payload:', postPayload);
          throw new Error(JSON.stringify(res));
        }

        addERS(createdERS);
        toast.success('Schedule created');
        setIsModalOpen(false);
        setSelectedERS(null);
        formMgmt.reset();
      } catch (error) {
        console.error(error);
      }
    } else if (httpVerb === 'PUT') {
      const putPayload = ERSForm.toPutPayload(ersForm);

      try {
        const res = await reportsService.updateEmailReportSchedule(selectedERS!.recId, putPayload);

        if (res.status !== 204) {
          toast.error(`Incorrect response: ${res.status} ${res.statusText}`);
          console.error('PUT request payload:', putPayload);
          throw new Error(JSON.stringify(res));
        }

        // Update ERS in state
        updateERS(ERSFmt.fromPutPayload(selectedERS!, putPayload, orgId!));
        toast.success('Schedule updated');
        setIsModalOpen(false);
        setSelectedERS(null);
        formMgmt.reset();
      } catch (error) {
        console.error(error);
      }
    }
    setHttpVerb();
  };

  return isModalOpen && subview === ReportsSubviews.ers ? (
    <FormProvider {...formMgmt}>
      <Modal
        title={selectedERS?.reportName || 'New Email Report Schedule'}
        centerModal
        closeButton
        onCloseButtonClick={handleCloseExportModal}
        onDimmerClick={handleCloseExportModal}
        isOpen={isModalOpen}
      >
        <form onSubmit={formMgmt.handleSubmit(handleSubmitRequest)}>
          <FormLayout formMgmt={formMgmt} />
          <ModalSubmitButtons />
        </form>
        {/* Spacer - modal doesn't provide access to style */}
        <div style={{ minWidth: '700px' }}></div>
      </Modal>
    </FormProvider>
  ) : (
    <div />
  );
};

export default ERSDetailView;
