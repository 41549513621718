const filterFields = [
  'appRecId',
  'purPrice',
  'saleDate',
  'soldPrice',
  'stockNum',
  'totExp',
] as const;

export type CashSalesFilterKey = (typeof filterFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const cashSalesFilterFields: CashSalesFilterKey[] = [...filterFields];

const sortFields = ['appRecId', 'purPrice', 'saleDate', 'soldPrice', 'stockNum', 'totExp'] as const;

export type CashSalesSortKey = (typeof sortFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const cashSalesSortFields: CashSalesSortKey[] = [...sortFields];
