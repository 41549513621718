import { NumberInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { useParams } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import {  DropdownInput, TextArea, TextInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { BasicTable } from '@/components/table/Table';
import { Column } from '@/components/table/TableInterface';
import { formatCurrency } from '@/utils/helpers/general';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './WarrantyRequest.module.scss';
import {
  getWarrantyInformation,
  getWarrantyLogs,
  postWarrantyRequest,
  updateWarrantyRequest,
} from '../../../../accountActionCreators';
import { useAppDispatch } from '@/store/store';
import { WarrantyInformation, WarrantyLog } from '@/interfaces';
import { accountsService } from '@/services/accountsService';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { Loader } from '@progress/kendo-react-all';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';

type FormProps = {
  status: string;
  currentMiles: number;
  warrantyIssue: string;
  estimatedAmount: number;
  customerIsPaying: number;
};

export const WarrantyRequest = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const colRecId = Number(params.colRecId);
  const statusOptions = ['Pending', 'Approved', 'Denied'];
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isNewRequestIssue, setIsNewRequestIssue] = useState(false);
  const [warrantyLogsTableData, setWarrantyLogsTableData] = useState<WarrantyRequestItem[]>([]);
  const [warrantyInformation, setWarrantyInformation] = useState<WarrantyInformation>(
    {} as WarrantyInformation
  );
  const [warrantyLog, setWarrantyLog] = useState<WarrantyLog>({} as WarrantyLog);
  const [refreshTable, setRefreshTable] = useState(false);
  const [formRefreshing, setFormRefreshing] = useState(false);
  const { getWarrantyLogsLoading, postWarrantyRequestLoading } = useAccountSelector((s) => s);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      status: 'Pending',
      currentMiles: undefined,
      warrantyIssue: '',
      estimatedAmount: undefined,
      customerIsPaying: undefined,
    },
  });

  useEffect(() => {
    dispatch(getWarrantyLogs(colRecId)).then((res) =>
      setWarrantyLogsTableData(
        res.map((value) => {
          return {
            recId: value.recId,
            date: dayjs(value.date).utc().format('MM/DD/YYYY'),
            status: value.status,
            estimate: formatCurrency(value.estimatedAmount),
            custPay: formatCurrency(value.customerAmount),
            item: value.item,
          } as WarrantyRequestItem;
        })
      )
    );
  }, [dispatch, refreshTable]);

  useEffect(() => {
    dispatch(getWarrantyInformation(colRecId)).then((res) => setWarrantyInformation(res));
  }, [dispatch]);

  const onSubmit = (data: FormProps) => {
    if (isNewRequestIssue) {
      dispatch(
        postWarrantyRequest({
          colRecId: colRecId,
          status: data.status,
          requestDate: dayjs().toISOString(),
          currentMiles: Number(data.currentMiles),
          issue: data.warrantyIssue,
          estimatedAmount: data.estimatedAmount,
          customerAmount: data.customerIsPaying,
          submittedBy: warrantyInformation.buyers,
        })
      ).then(() => {
        setShowForm(false);
        setRefreshTable((prev) => !prev);
      });
    } else {
      dispatch(
        updateWarrantyRequest({
          recId: warrantyLog.recId,
          colRecId: colRecId,
          status: data.status,
          requestDate: warrantyLog.date,
          currentMiles: Number(data.currentMiles),
          issue: data.warrantyIssue,
          estimatedAmount: data.estimatedAmount,
          customerAmount: data.customerIsPaying,
          submittedBy: warrantyInformation.buyers,
        })
      ).then(() => {
        setShowForm(false);
        setRefreshTable((prev) => !prev);
      });
    }
  };

  const WarrantyRequestColumns: Column[] = [
    {
      field: 'date',
      title: 'Date',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'estimate',
      title: 'Estimate',
    },
    {
      field: 'custPay',
      title: 'Cust. Pay',
    },
    {
      field: 'item',
      title: 'Item',
    },
  ];

  interface WarrantyRequestItem {
    recId: number;
    date: string;
    status: string;
    estimate: string;
    custPay: string;
    item: string;
  }

  const InformationModal = () => {
    return (
      <Modal
        isOpen={showInformationModal}
        closeButton={true}
        title="Customer Information"
        onCloseButtonClick={() => setShowInformationModal(false)}
        onDimmerClick={() => setShowInformationModal(false)}
      >
        <div className={styles.informationContainer}>
          <div>
            <b>Account:</b> {warrantyInformation.accountNumber}
          </div>
          <div>
            <b>Buyers:</b> {warrantyInformation.buyers}
          </div>
          <div>
            <b>Date Sold:</b> {dayjs(warrantyInformation.dateSold).utc().format('MM/DD/YYYY')}
          </div>
          <div>
            <b>Sold Miles:</b> {warrantyInformation.milesOut}
          </div>
        </div>
      </Modal>
    );
  };

  const WarrantyRequestTable = () => (
    <AccountsMainPanel navBarTitle="Warranty Items" loading={getWarrantyLogsLoading}>
      <>
        <div>
          <BasicTable
            data={warrantyLogsTableData}
            columns={WarrantyRequestColumns}
            pageable={true}
            sortable={true}
            onRowClick={(e) => {
              setFormRefreshing(true);
              accountsService
                .getWarrantyLog(e.dataItem.recId)
                .then((res) => {
                  setWarrantyLog(res);
                  reset({
                    status: res.status,
                    currentMiles: res.currentMiles,
                    warrantyIssue: res.item,
                    estimatedAmount: res.estimatedAmount,
                    customerIsPaying: res.customerAmount,
                  });
                })
                .finally(() => setFormRefreshing(false));
              setIsNewRequestIssue(false);
              setShowForm(true);
            }}
          />
        </div>
        <div className={styles.addNewButtonContainer}>
          <Button
            label="Add New"
            onClick={() => {
              setFormRefreshing(true);
              setWarrantyLog({} as WarrantyLog);
              reset({
                status: 'Pending',
                currentMiles: undefined,
                warrantyIssue: '',
                estimatedAmount: undefined,
                customerIsPaying: undefined,
              });
              setIsNewRequestIssue(true);
              setShowForm(true);
              setFormRefreshing(false);
            }}
          />
        </div>
      </>
    </AccountsMainPanel>
  );

  const WarrantyRequestForm = () => (
    <>
      <AccountsMainPanel navBarTitle="Warranty Request">
        {formRefreshing ? (
          <div className={styles.loading}>
            <Loader size="large" />
          </div>
        ) : (
          <div className={styles.warrantyRequestContainer}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <div className={styles.backToTable}>
                <Button
                  label="<- Back to Table"
                  fillMode="flat"
                  onClick={() => setShowForm(false)}
                  type="button"
                />
              </div>
              <Button
                label="View Customer Information"
                onClick={() => setShowInformationModal(true)}
                type="button"
                buttonStyle={{ width: '250px' }}
              />
              <TextInput
                label="Date Submitted"
                value={
                  warrantyLog.date
                    ? dayjs(warrantyLog.date).utc().format('MM/DD/YYYY')
                    : dayjs().format('MM/DD/YYYY')
                }
                readOnly
              />
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DropdownInput
                    label="Status"
                    errors={errors.status ? 'This field is required' : undefined}
                    data={statusOptions}
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name="currentMiles"
                control={control}
                rules={{ required: true, validate: (value) => value >= 0 }}
                render={({ field }) => (
                  <NumberInput
                    errors={
                      errors.currentMiles
                        ? errors.currentMiles.type === 'validate'
                          ? 'This field cannot be negative'
                          : 'This field is required'
                        : undefined
                    }
                    label="Current Miles"
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name="warrantyIssue"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextArea
                    errors={errors.warrantyIssue ? 'This field is required' : undefined}
                    label="Warranty Issue"
                    rows={3}
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name="estimatedAmount"
                control={control}
                rules={{ required: true, validate: (value) => value >= 0 }}
                render={({ field }) => (
                  <CurrencyInput
                    errors={
                      errors.estimatedAmount
                        ? errors.estimatedAmount.type === 'validate'
                          ? 'This field cannot be negative'
                          : 'This field is required'
                        : undefined
                    }
                    label="Estimated Amount"
                    required
                    {...field}
                  />
                )}
              />
              <Controller
                name="customerIsPaying"
                control={control}
                rules={{ required: true, validate: (value) => value >= 0 }}
                render={({ field }) => (
                  <CurrencyInput
                    errors={
                      errors.customerIsPaying
                        ? errors.customerIsPaying.type === 'validate'
                          ? 'This field cannot be negative'
                          : 'This field is required'
                        : undefined
                    }
                    label="Customer is Paying"
                    required
                    {...field}
                  />
                )}
              />
              <Button
                type="submit"
                label={
                  isNewRequestIssue
                    ? 'Submit Warranty Request Issue'
                    : 'Update Warranty Request Issue'
                }
                loading={postWarrantyRequestLoading}
                buttonStyle={{ width: '250px' }}
              />
            </form>
          </div>
        )}
      </AccountsMainPanel>
      <InformationModal />
    </>
  );

  return showForm ? WarrantyRequestForm() : WarrantyRequestTable();
};
