import { FC, useState } from 'react';
// mui
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// kendo
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
// state
import { useTableCtx } from '../tableState';
// interfaces
import { Filter } from '@/interfaces/requests';
import {
  allowedCategoryFiltersMap as allowedAcctCategoryFiltersMap,
  AllowedCategoryFilterKey as AllowedAcctCategoryFilterKey,
  allowedFilterFields as allowedAcctFilterFields,
} from '../accountsSubviews/default';
// import {
//   AllowedFilterKey as AllowedPmtFilterKey,
//   allowedCategoryFiltersMap as allowedPmtCategoryFilterFields,
// } from '../pmtSubviews/default';
import { useAccountsViewCtx } from '../accountsViewState';

/**
 * @note All category filters MUST use `IN` operator.
 *
 * @todo make field type generic
 * @todo move to general location
 * @todo need to fix logic on how filter values are stored, updated, accessed:
 * - On change:
 *    - If category exists (i.e. in the Map kept in state):
 *       - If 'adding option': append option to filter.value;
 *       - If 'removing option' && filter.options.length === 1: remove filter from Map;
 *       - If 'removing option' && filter.options.length > 1: remove option from filter.value;
 *    - Else:
 *       If 'adding option': create filter from Map;
 * @todo AllowedPmtCategoryFilterKey
 */
const CategoryFilterOption: FC<{
  field: AllowedAcctCategoryFilterKey;
  label: string;
  value: string;
}> = ({ field, label, value }) => {
  const filter = useTableCtx((s) => s.filter);
  const addFilter = useTableCtx((s) => s.addFilter);
  const removeFilter = useTableCtx((s) => s.removeFilter);

  // @note the options list must be re-initialized from context when dropdown is opened again.
  const values = filter.map((f) => f.value);
  const [isChecked, setIsChecked] = useState(values.includes(value));

  const filterValue: Filter = { field, operator: 'IN', value };

  // Event handlers
  const handleCheck = () => {
    // Remove filter if checked, else add
    isChecked ? removeFilter(filterValue) : addFilter(filterValue);

    setIsChecked(!isChecked);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleCheck} role={undefined} dense>
        <ListItemIcon>
          <Checkbox checked={isChecked} edge="start" disableRipple sx={{ py: 0 }} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            letterSpacing: '-0.20px',
            lineHeight: '20px',
            fontSize: '14px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

// @todo move to general location
// @todo when requested, add payment filters
// @todo clean up type logic, making these types generic would help
// @todo add AllowedPmtCategoryFilterKey
const CategoryFilter: FC<{ field: AllowedAcctCategoryFilterKey }> = ({ field }) => {
  const categoryFilterOptions = useAccountsViewCtx((s) => s.subviewInfo.categoryFilterOptions);
  const [isOpen, setIsOpen] = useState(false);
  /**
   * 1. Get the category mapping, which includes:
   *    1. the label for the dropdown element
   *    2. the mapping for the option labels (for that category/field)
   */
  let label = '';
  let optionsMap: Map<string, { value: string; label: string }> = new Map();

  if (allowedAcctFilterFields.includes(field as AllowedAcctCategoryFilterKey)) {
    const { label: acctLabel, optionsMap: acctOptionsMap } = allowedAcctCategoryFiltersMap.get(
      field as AllowedAcctCategoryFilterKey
    )!;
    label = acctLabel;
    optionsMap = acctOptionsMap;
  }
  // @note payment subview category filters not yet supported, but keeping logic here will save time
  // else if (allowedPmtFilterFields.includes(field as AllowedPmtFilterKey)) {
  //   const { label: pmtLabel, optionsMap: pmtOptionsMap } = allowedPmtCategoryFiltersMap.get(
  //     field as AllowedPmtFilterKey
  //   )!;
  //   label = pmtLabel;
  //   optionsMap = pmtOptionsMap;
  // }

  if (categoryFilterOptions === undefined || categoryFilterOptions.length === 0) {
    return <></>;
  }

  const filterOptionElems = categoryFilterOptions.map((o, idx) => {
    const { value, label } = optionsMap.get(o)!;
    return <CategoryFilterOption field={field} value={value} label={label} key={`c-f-o${idx}`} />;
  });

  return (
    <DropDownButton
      label={`${label} Filter`}
      open={isOpen}
      setOpen={setIsOpen}
      buttonProps={{ secondary: true }}
    >
      <List component={Paper}>{filterOptionElems}</List>
    </DropDownButton>
  );
};

export default CategoryFilter;
