import styles from './formLinkWrapper.module.scss';

export const FormLinkWrapper = (props: { children: React.ReactNode }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.spacer} />
      <div className={styles.linkContainer}>{props.children}</div>
    </div>
  );
};
