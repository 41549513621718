import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Call } from '@/services/customerService';
import { DateFormat, extractTextFromHtmlString, formatDate } from '@/utils/helpers/general';
import { Icons } from '@/components/icons';

import styles from '../activityPanel.module.scss';


/** ###  */
const LogCallActivityCard: FC<{activity:Call, callerPhoneNumber:string}> = ({activity, callerPhoneNumber}) => {
  const phone = activity.phoneNumber || "Unknown Phone Number";

  return (
    <Grid container direction="column">
       <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div className={styles.type}>
                  {`Call from ${activity.employeeName} to ${activity.toName}${phone}`}
                </div>
              </div>
              <div>{extractTextFromHtmlString(activity.body)}</div>
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    </Grid>
  );
};

export default LogCallActivityCard;
