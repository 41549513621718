import styles from "./VehicleAndTempTag.module.scss";
import salesTabStyles from "../SalesTab.module.scss";
import { BasicTable } from "@/components/table/Table";
import { useVehicleAndTempTag } from "./useVehicleAndTempTag";
import { SectionHeader } from "@components/sectionHeader/SectionHeader";
import { Controller } from "react-hook-form";
import {
  Button,
  Checkbox,
  DropdownInput,
  MaskedTextInput,
  NumberInput,
  TextInput,
} from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { MultipleInputRow } from "../../multipleInputRow/MultipleInputRow";
import { scheduleData } from "@/utils/helpers/general";
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { Loader } from "@progress/kendo-react-all";
import { AppStatus } from "@/features/Sales/enums";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
import { FC } from "react";

export const VehicleAndTempTag: FC = () => {
  const {
    vehicleColumns,
    showVehicleForm,
    setShowVehicleForm,
    control,
    watch,
    bodyTypeData,
    handleSubmit,
    updateVehicle,
    updateVehicleLoading,
    listData,
    handleSearchFilter,
  } = useVehicleAndTempTag();
  const { availableVehiclesLoading, saleData } = useSalesSelector((s) => s);
  const isPosted = saleData.sale?.salestatus === AppStatus.Posted;

  if (availableVehiclesLoading) {
    return (
      <div className={salesTabStyles.loader}>
        <Loader size="large" />
      </div>
    );
  }

  const VehicleTable = () => {
    return (
      <div>
        <BasicTable data={listData} columns={vehicleColumns} pageable sortable />
      </div>
    );
  };

  const VehicleForm = () => {
    return (
      <form onSubmit={handleSubmit(updateVehicle)}>
        <section className={salesTabStyles.section}>
          <SectionHeader title="General Information" />
          <div className={salesTabStyles.bodyContainer}>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="stockNum"
                control={control}
                render={({ field }) => <TextInput label="Stock Number" readOnly {...field} />}
              />
              {!isPosted && (
                <MultipleInputRow label="" htmlFor="">
                  <a
                    className={salesTabStyles.anchorText}
                    onClick={() => setShowVehicleForm(false)}
                  >
                    + Select Different Vehicle from List
                  </a>
                </MultipleInputRow>
              )}
              <Controller
                name="vin"
                control={control}
                render={({ field }) => <TextInput label="VIN" readOnly {...field} />}
              />
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <MaskedTextInput
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    placeholder="0000"
                    label="Year"
                    readOnly
                    {...field}
                  />
                )}
              />
              <Controller
                name="make"
                control={control}
                render={({ field }) => <TextInput label="Make" readOnly {...field} />}
              />
              <Controller
                name="model"
                control={control}
                render={({ field }) => <TextInput label="Model" readOnly {...field} />}
              />
              <Controller
                name="color"
                control={control}
                render={({ field }) => <TextInput label="Color" readOnly {...field} />}
              />
              <Controller
                name="bodyType"
                control={control}
                render={({ field }) => (
                  <DropdownInput data={bodyTypeData} label="Body Type" disabled {...field} />
                )}
              />
              <Controller
                name="vehicleType"
                control={control}
                render={({ field }) => <DropdownInput label="Vehicle Type" disabled {...field} />}
              />
              <Controller
                name="drivetrain"
                control={control}
                render={({ field }) => <TextInput label="Drivetrain" readOnly {...field} />}
              />
              <Controller
                name="transmission"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    data={["Automatic", "Manual"]}
                    label="Transmission"
                    disabled
                    {...field}
                  />
                )}
              />
              <Controller
                name="milesOut"
                control={control}
                render={({ field }) => <NumberInput label="Miles Out" {...field} />}
              />
              <div className={styles.checkboxRow}>
                <Controller
                  name="notActual"
                  control={control}
                  render={({ field }) => <Checkbox label="Not Actual" {...field} />}
                />
                <Controller
                  name="exceedsLimits"
                  control={control}
                  render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
                />
                <Controller
                  name="printExempt"
                  control={control}
                  render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
                />
              </div>
            </div>
            <div className={salesTabStyles.bodyChild}>
              <div className={salesTabStyles.bodyChild} style={{ gap: "25px" }}>
                <h3 className={styles.subHeading}>Alerts</h3>
                <Controller
                  name="tmu"
                  control={control}
                  render={({ field }) => (
                    <Checkbox disabled label="TMU (True Miles Unknown)" {...field} />
                  )}
                />
                <Controller
                  name="salvageTitle"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Salvage Title" {...field} />}
                />
                <Controller
                  name="frameDamage"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Frame Damage" {...field} />}
                />
                <Controller
                  name="unibody"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Unibody" {...field} />}
                />
                <Controller
                  name="airbag"
                  control={control}
                  render={({ field }) => <Checkbox disabled label="Airbag" {...field} />}
                />
                <div>
                  <Controller
                    name="other"
                    control={control}
                    render={({ field }) => <Checkbox disabled label="Other" {...field} />}
                  />
                  {watch("other") && (
                    <Controller
                      name="otherAlert"
                      control={control}
                      render={({ field }) => <TextInput disabled {...field} />}
                    />
                  )}
                </div>
                <div className={salesTabStyles.bodyChild}>
                  <h3 className={styles.subHeading}>Temporary Plate</h3>
                  <Controller
                    name="plateNumber"
                    control={control}
                    render={({ field }) => <TextInput label="Plate Number" {...field} />}
                  />
                  <Controller
                    name="dateIssued"
                    control={control}
                    render={({ field }) => <MuiKendoDateField label="Date Issued" {...field} />}
                  />

                  <Controller
                    name="dateExpires"
                    control={control}
                    render={({ field }) => <MuiKendoDateField label="Expiration Date" {...field} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={salesTabStyles.section}>
          <SectionHeader title="Suggested Sales Info" />
          <div className={salesTabStyles.bodyContainer}>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => <CurrencyInput label="Price" readOnly {...field} />}
              />
              <Controller
                name="downPayment"
                control={control}
                render={({ field }) => <CurrencyInput label="Down Payment" readOnly {...field} />}
              />
              <Controller
                name="downPaymentPercentage"
                control={control}
                render={({ field }) => (
                  <NumberInput readOnly format="p2" label="Down Payment %" {...field} />
                )}
              />
            </div>
            <div className={salesTabStyles.bodyChild}>
              <Controller
                name="schedule"
                control={control}
                render={({ field }) => (
                  <DropdownInput disabled data={scheduleData} label="Schedule" {...field} />
                )}
              />
              <Controller
                name="payment"
                control={control}
                render={({ field }) => <CurrencyInput readOnly label="Payment" {...field} />}
              />
              <Controller
                name="months"
                control={control}
                render={({ field }) => <NumberInput label="Months" readOnly {...field} />}
              />
            </div>
          </div>
        </section>
        <div className={salesTabStyles.buttonContainer}>
          <Button label="Submit" buttonStyle={{ width: "134px" }} loading={updateVehicleLoading} />
        </div>
      </form>
    );
  };

  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>Vehicle</h2>
        {!showVehicleForm && (
          <TextInput
            name="search"
            placeholder="Search"
            className={salesTabStyles.searchBox}
            onChange={(e) => handleSearchFilter(e.target.value)}
          />
        )}
      </header>
      <main className={salesTabStyles.body}>
        {showVehicleForm ? <VehicleForm /> : <VehicleTable />}
      </main>
    </div>
  );
};
