import { ReportCategory } from '@/enums';
//interfaces
import { IReport } from '@/interfaces/Reports';
import { IPaginatedReq } from '@/interfaces/requests';

export const exportFileTypes = [
  'xlsx', // 'xlsx'
  'csv',
  'pdf',
] as const;
/** Report file-type lookup keys */
export type ExportFileType = (typeof exportFileTypes)[number];
export interface ExportFileTypeMap {
  label: string;
}
export const fileTypeMap: { [key in ExportFileType]: ExportFileTypeMap } = {
  xlsx: { label: 'Excel' },
  csv: { label: 'CSV' },
  pdf: { label: 'PDF' },
};

export interface IFormErrors {
  date: boolean;
  int: boolean;
  amt: boolean;
}

export interface IModalFilterParams {
  beginDate: string | null;
  endDate: string | null;
  amt: number | null;
  int: number | null;
}
class ModalFilterParams {
  constructor(
    public beginDate: string | null = null,
    public endDate: string | null = null,
    public amt: number | null = null,
    public int: number | null = null
  ) {}
}
export const newModalFilterParams = (
  filterParams: Partial<ModalFilterParams> | null
): IModalFilterParams => {
  return new ModalFilterParams(
    filterParams?.beginDate || null,
    filterParams?.endDate || null,
    filterParams?.amt || null,
    filterParams?.int || null
  );
};

/** @todo test with camelcase - the C# model is implemented
 * with pascalcase (shorthand for public properties)
 * but may be represented differently at runtime.
 * */
export interface IReportExportReqBody {
  reportRecId: number;
  outputType: 'pdf' | 'xlsx' | 'csv';
  startDateParam: string | null;
  endDateParam: string | null;
  amtParam: number | null;
  intParam: number | null;
}
class ReportExportReqBody implements IReportExportReqBody {
  constructor(
    public reportRecId: number,
    public outputType: 'pdf' | 'xlsx' | 'csv',
    public startDateParam: string | null = null,
    public endDateParam: string | null = null,
    public amtParam: number | null = null,
    public intParam: number | null = null
  ) {}
}
export const newReportExportReqBody = (body: ReportExportReqBody): IReportExportReqBody => {
  // Validation: dates
  if (body.startDateParam && !body.endDateParam) {
    throw new Error(
      `newReportExportReqBody(): Invalid date range: beginDate = ${body.startDateParam}  endDate = ${body.endDateParam}`
    );
  }
  // @ts-ignore
  if (body.beginDate && isNaN(new Date(body.beginDate!))) {
    throw new Error(`newReportExportReqBody(): Invalid beginDate: ${body.startDateParam}`);
  }
  // @ts-ignore
  if (body.endDate && isNaN(new Date(body.endDate!))) {
    throw new Error(`newReportExportReqBody(): Invalid endDate: ${body.endDateParam}`);
  }

  // Validation: filetype
  if (!exportFileTypes.includes(body.outputType)) {
    throw new Error(
      `newReportExportReqBody(): Incorrect filetype for ReportExportReqBody: ${body.outputType}`
    );
  }
  return new ReportExportReqBody(
    body.reportRecId,
    body.outputType,
    body.startDateParam,
    body.endDateParam,
    body.amtParam,
    body.intParam
  );
};

/** ## Reports fetch all request - paginated */
export interface ReportsGetPgReq extends IPaginatedReq {
  search: string | null;
}

/** ## Accounts fetch all response - paginated */
export interface ReportsGetPgRes {
  total: number;
  page: number;
  perPage: number;
  data: IReport[];
}

export type ReportCategoryKey = ReportCategory | 'All';
