import { FC } from 'react';
// kendo
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
// components
import { NumberInput } from '@/components';
// utils
import { useReportsCtx } from '../ReportsList/defaultState';
import { IModalFilterParams, newModalFilterParams } from '../interfaces';

/** ### Integer form for export filter.
 * Since component only renders if:
 * - Selected report is set; AND
 * - Modal filter params is set; AND
 * - The filter field is set;
 * Form is always controlled and no placeholder or validation is needed.
 *
 * @note Int fields may have custom labels at property `intLabel`.
 */
const IntForm: FC = () => {
  const modalFilterParams = useReportsCtx(s => s.modalFilterParams);
  const setModalFilterParams = useReportsCtx(s => s.setModalFilterParams);
  const selectedReport = useReportsCtx(s => s.selectedReport);

  // Event handlers
  const handleChangeIntField = (e: NumericTextBoxChangeEvent) => {
    const updatedModalFilterParams: IModalFilterParams = {
      ...(modalFilterParams || newModalFilterParams({})),
      int: e.target.value,
    };
    setModalFilterParams(updatedModalFilterParams);
  };

  // @note The property on the database is currently `intLable`, it may be temporarily mapped to `intLabel` on the backend in the future.
  // @ts-ignore
  const intLabel = selectedReport!.intLabel || selectedReport!.intLable || 'Integer';

  return (
    <NumberInput
      onChange={handleChangeIntField}
      label={intLabel}
      horizontalLabel
      value={modalFilterParams!.int!}
    />
  );
};

export default IntForm;
