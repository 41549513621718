import { Components } from "@mui/material/styles/components";
import { Theme } from "@mui/material/styles/createTheme";
import { LabComponents } from "@mui/lab/themeAugmentation";

const MuiButton: Components<Omit<Theme, "components">>["MuiButton"] = {
  defaultProps: {
    variant: "contained",
  },

  styleOverrides: {
    root: {
      textTransform: "none",
      color: "primary",
      fontSize: 14,
      fontWeight: 600,
      borderRadius: 16,
    },
  },
};

const MuiLoadingButton: LabComponents["MuiLoadingButton"] = {
  defaultProps: {
    ...MuiButton.defaultProps,
  },
  styleOverrides: {
    ...MuiButton.styleOverrides,
  },
};

const buttonTheme = { MuiButton, MuiLoadingButton };

export default buttonTheme;
