import { IInventoryTypeInfo } from './interfaces';

export const inventoryTypesArr: IInventoryTypeInfo[] = [
  { id: 'unsold', text: 'Unsold' },
  { id: 'sold', text: 'Sold' },
  { id: 'iop', text: 'In Our Possession' },
  { id: 'all', text: 'All Vehicles' },
  { id: 'alllotsretail', text: 'All Lots Retail' },
  { id: 'notReceived', text: 'Not Received' },
  { id: 'titlelogs', text: 'Title Logs' },
  { id: 'disposal', text: 'Disposal' },
];
