import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import InventoryVehicleView from "./components/inventoryVehicle/InventoryVehicleView";
import SuggestedAndSoldInfo from "./components/suggestedAndSoldInfo/SuggestedAndSoldInfo";
import { TitleAndTagInformation } from "./components/titleAndTagInformation/TitleAndTagInformation";
import { InventoryExpenses } from "./components/inventoryExpenses/inventoryExpenses";
import GpsAndOdometer from "./components/gpsAndOdometer/GpsAndOdometer";
import CheckIn from "./components/checkIn/CheckIn";
import VehicleOptions from "./components/checkIn/VehicleOptions";
import { InventoryDocuments } from "./components/documents/Documents";
import BuyersGuide from "./components/buyersGuide/BuyersGuide";

/** ###  */
const InventoryDetailsRouter: FC = () => (
  <Routes>
    <Route path="vehicle" element={<InventoryVehicleView />} />
    <Route path="expenses" element={<InventoryExpenses />} />
    <Route path="vehicleCheckIn" element={<CheckIn />} />
    <Route path="vehicleOptions" element={<VehicleOptions />} />
    <Route path="titleTag" element={<TitleAndTagInformation />} />
    <Route path="suggestedSold" element={<SuggestedAndSoldInfo />} />
    <Route path="gpsOdometer" element={<GpsAndOdometer />} />
    <Route path="documents" element={<InventoryDocuments />} />
    <Route path="buyersGuide" element={<BuyersGuide />} />

    <Route path="" element={<Navigate to="vehicle" replace={true} />} />
  </Routes>
);

export default InventoryDetailsRouter;
