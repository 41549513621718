import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import AuthAndNotesSection from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/AuthAndNotesSection";
import BillingInfoFields from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/BillingInfoFields";
import CcBillingFields from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/CcBillingFields";
import AchAcctTypeField from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/AchAcctTypeField";
import OpenEdgeMpdFields from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/OpenEdgeMpdFields";
import SubmitButtonGroup from "../../accountsSubviews/AccountDetail/components/PaymentForm/FormRightColumn/SubmitButtonGroup";

/** @deprecated remove and replace with single-instance payment form. `registerOn` prop should control which fields render */
const FormRightColumn: FC = () => (
  <Grid
    container
    direction="column"
    wrap="nowrap"
    sx={{ width: 380, maxWidth: 500, justifyContent: "space-between", gap: 3, flex: 1 }}
  >
    <Grid container direction="column" gap={1.5}>
      <BillingInfoFields />
      <CcBillingFields />
      <OpenEdgeMpdFields />
      <AchAcctTypeField />
    </Grid>

    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ flex: 1, gap: 2, justifyContent: "end", justifySelf: "end" }}
    >
      <AuthAndNotesSection />
      <SubmitButtonGroup />
    </Grid>
  </Grid>
);

export default FormRightColumn;
