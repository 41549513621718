import { KendoDateCell } from '@/components/kendoCells/KendoDateCell';
import { Column } from '@/components/table/TableInterface';
import { SecureClosePresentation } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { GridCustomCellProps } from '@progress/kendo-react-all';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useSecureClose = () => {
  const params = useParams();
  const appRecId = Number(params.id);
  const [secureClosePresentations, setSecureClosePresentations] = useState<
    SecureClosePresentation[]
  >([]);

  const DateCell = (props: GridCustomCellProps) => {
    return <KendoDateCell dateFormat="MM/DD/YYYY" {...props} />;
  };

  const secureCloseColumns: Column[] = [
    {
      field: 'secureClosePresentationId',
      title: 'Presentation ID',
    },
    {
      field: 'documentInclude',
      title: 'Documents Include',
    },
    {
      field: 'presentationCreationDate',
      title: 'Presentation Creation Date',
      cells: { data: DateCell },
    },
  ];

  const getSecureClosePresentations = useCallback(async () => {
    const response = await salesService.getSecureClosePresentations(appRecId);
    setSecureClosePresentations(response);
  }, []);

  useEffect(() => {
    getSecureClosePresentations();
  }, []);

  return { secureCloseColumns, secureClosePresentations, appRecId };
};
