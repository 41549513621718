import { FC } from 'react';
// kendo
import { Button as KendoButton, ButtonProps, Loader } from '@progress/kendo-react-all';
// style
import styles from './Button.module.scss';

export type CustomButtonProps = ButtonProps & {
  label?: string | JSX.Element;
  loading?: boolean;
  secondary?: boolean;
  buttonStyle?: React.CSSProperties;
  fullWidth?: boolean;
};

export const Button: FC<CustomButtonProps> = (props) => {
  const {
    label = '',
    loading = false,
    secondary = false,
    buttonStyle = {},
    disabled = false,
    fullWidth = true,
    themeColor = 'primary',
    ...restProps
  } = props;

  return (
    <div>
      <KendoButton
        className={fullWidth ? styles.wideButton : styles.button}
        style={buttonStyle}
        themeColor={themeColor}
        disabled={loading || disabled}
        fillMode={secondary ? 'outline' : 'solid'}
        {...restProps}
      >
        {loading ? <Loader themeColor={secondary ? 'primary' : 'light'} /> : label}
      </KendoButton>
    </div>
  );
};
