import { FC, PropsWithChildren } from 'react';
import { StyleSheet, Text, TextProps, View, ViewProps } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: '2px',
    borderTop: 'none',
    borderBottom: '1px solid #ababab',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: 800,
  },
});

/** ###  */
const PdfSectionHeader: FC<PropsWithChildren<ViewProps> & { textProps?: TextProps }> = ({
  textProps,
  children,
  ...props
}) => {
  return (
    <View {...props} style={{ ...styles.headerContainer, ...props.style }}>
      <Text {...textProps} style={{ ...styles.headerText, ...textProps?.style }}>
        {children}
      </Text>
    </View>
  );
};

export default PdfSectionHeader;
