import React from 'react';
import styles from '@components/text/Text.module.scss';

export interface TextProps {
  type: 'body' | 'p' | 'label' | 'h1' | 'h2' | 'h3' | 'h4';
  children: string | null;
  className?: any;
}

export const Text = ({ type, children }: TextProps) => {
  switch (type) {
    case 'h1':
      return <h1 className={styles.h1}>{children}</h1>;
    case 'h2':
      return <h2 className={styles.h2}>{children}</h2>;
    case 'h3':
      return <h3 className={styles.h3}>{children}</h3>;
    case 'h4':
      return <h4 className={styles.h4}>{children}</h4>;
    case 'p':
      return <p className={styles.p}>{children}</p>;
    case 'label':
      return <label className={styles.label}>{children}</label>;
    case 'body':
    default:
      return <>{children}</>;
  }
};
