import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
// kendo
import { Checkbox } from '@/components/checkbox/Checkbox';
import { Spacer } from '@/components/spacer/Spacer';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modals/Modal';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { RadioGroupInput } from '@/components/inputs/radioGroupInput/RadioGroupInput';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import DateInput from "@/mui/components/form/MuiKendoDateField";
// components
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import { FunctionLink } from '@/components/functionLink/FunctionLink';
import { FormLinkWrapper } from '@/components/formLinkWrapper/formLinkWrapper';
import { SelectVendorModal } from '@/components/selectVendorModal/selectVendorModal';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useInventoryViewCtx } from '../state';
// utils
import { inventoryService } from '@/services/inventoryService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import {
  vinRegex,
  genericRequired,
  notGreaterThanTodayValidation,
} from '@/utils/helpers/formValidation';
// interfaces
import { AddNewInventoryPayload, AssignableCompany, ColorListItem } from '@/interfaces/Inventory';
//
import { ApiErrorMessage } from '@/interfaces/Api';
import { IVendor } from '@/interfaces/Vendors';
// style
import styles from './AddNewInventory.module.scss';

/** ###  */
const AddNewInventoryForm: FC = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    control,
    trigger,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<AddNewInventoryPayload>({ mode: 'onChange' });

  const orgId = useAuthSelector((s) => s.orgId);
  const locId = useAuthSelector((s) => s.locId);
  const compId = useAuthSelector((s) => s.compId);

  const isModalOpen = useInventoryViewCtx((s) => s.isModalOpen);
  const setIsModalOpen = useInventoryViewCtx((s) => s.setIsModalOpen);
  const tableDataMap = useInventoryViewCtx((s) => s.tableDataMap);
  const setTableDataMap = useInventoryViewCtx((s) => s.setTableDataMap);
  const isSubmitting = useInventoryViewCtx((s) => s.isSubmitting);
  const setIsSubmitting = useInventoryViewCtx((s) => s.setIsSubmitting);
  const setIsLoading = useInventoryViewCtx((s) => s.setIsLoading);

  const [assignableCompanies, setAssignableCompanies] = useState<AssignableCompany[]>([]);
  const [vendorListModalOpened, setVendorListModalOpened] = useState(false);
  const [colors, setColors] = useState<ColorListItem[]>([]);

  const initData = async () => {
    try {
      setValue(
        'purchaseDate',
        formatDate(dayjs().format(), { pattern: DateFormat.DateInput, utc: false })
      );
      setValue('carTruck', 0);
      setColors(await inventoryService.getColors());
      setAssignableCompanies(await inventoryService.getAssignableCompanies());
    } catch {
      toast.error('Unable to initialize data');
    } finally {
      setIsLoading(false);
    }
  };

  const getVinAuditData = useCallback(async () => {
    const validVin = await trigger('vin');
    if (!validVin) return;
    const vin = watch('vin');
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');
    inventoryService.vinAuditDecode(vin).then((res) => {
      // The API doesn't throw an error if decoding is unsuccessful, so we'll just have to determine it based on whether most values are null
      if (!res.year && !res.make && !res.model) {
        toast.error('Unable to decode VIN');
      }
      if (res?.year) setValue('year', res.year, { shouldValidate: true });
      if (res?.make) setValue('make', res.make, { shouldValidate: true });
      if (res?.model) setValue('model', res.model, { shouldValidate: true });
    });
  }, [trigger, watch, setValue]);

  const submit = async (data: AddNewInventoryPayload) => {
    data.assignedTo = (data.assignedTo as unknown as AssignableCompany).recId;
    data.orgId = orgId!;
    data.compId = compId!;
    data.locId = locId!;
    setIsSubmitting(true);
    try {
      const { invRecId } = await inventoryService.addNewInventory(data);
      toast.success('Vehicle added to inventory');
      navigate(`/inventory/${invRecId}/vehicle`);

      const res = await inventoryService.getUnsoldList();
      setTableDataMap({ ...tableDataMap, unsold: res });
      setIsModalOpen(false);
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (responseData?.message === 'Duplicate VIN not allowed') {
        setError('vin', { type: 'custom', message: responseData?.message });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!assignableCompanies || !assignableCompanies.length) return;
    const defaultCompany = assignableCompanies.find((ac) => ac.recId === compId);
    if (!defaultCompany) {
      toast.error('Unable to assign default company');
      return;
    }
    setValue('assignedTo', defaultCompany);
  }, [assignableCompanies]);

  useEffect(() => {
    // need to reset the form fields *before* opening the modal
    reset(Object.fromEntries(Object.keys(getValues()).map((key) => [key, undefined])));
    initData();
  }, [isModalOpen]);

  if (!isModalOpen) return <></>;

  const clearYearMakeModelFields = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue('vin', event.target.value, { shouldDirty: true });

    const validVin = await trigger('vin');
    if (!validVin) return;

    setValue('year', '', { shouldValidate: true });
    setValue('make', '', { shouldValidate: true });
    setValue('model', '', { shouldValidate: true });
  };

  const selectVendor = (vendor: IVendor) => {
    setValue('purchaseVendorId', vendor.recId!, { shouldDirty: true });
    setValue('purchaseVendorName', vendor.vendorName, { shouldDirty: true });
  };

  return (
    <>
      <Modal
        centerModal
        title="Add New Inventory"
        isOpen={isModalOpen}
        closeButton
        panelChildrenStyle={{ overflow: 'hidden' }}
        onCloseButtonClick={() => setIsModalOpen(false)}
        onDimmerClick={() => setIsModalOpen(false)}
      >
        <Spacer size={2} />
        <form className={styles.formContainer} onSubmit={handleSubmit(submit)}>
          <div className={styles.formColumn}>
            <Controller
              name="buyersName"
              control={control}
              render={({ field }) => <TextInput label="Buyer's Name" {...field} />}
            />
            <Controller
              name="vin"
              control={control}
              rules={{
                validate: (v: string) =>
                  !vinRegex.test(v) ? 'Please enter a valid VIN' : undefined,
                ...genericRequired,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  label="VIN"
                  errors={errors.vin?.message}
                  {...field}
                  onChange={(e) => clearYearMakeModelFields(e)}
                />
              )}
            />
            <FunctionLink onClick={getVinAuditData} label="Decode Vin" />
            <Controller
              name="year"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextInput required label="Year" errors={errors.year?.message} {...field} />
              )}
            />
            <Controller
              name="make"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextInput required label="Make" errors={errors.make?.message} {...field} />
              )}
            />
            <Controller
              name="model"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <TextInput required label="Model" errors={errors.model?.message} {...field} />
              )}
            />
            <Controller
              name="exteriorColor"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Exterior Color" data={colors.map((c) => c.item)} {...field} />
              )}
            />
            <Controller
              name="interiorColor"
              control={control}
              render={({ field }) => (
                <DropdownInput label="Interior Color" data={colors.map((c) => c.item)} {...field} />
              )}
            />
            <Controller
              name="carTruck"
              control={control}
              render={({ field: { onChange, ...restField } }) => (
                <RadioGroupInput
                  label="Car/Truck"
                  data={[
                    { label: 'Car', value: 0 },
                    { label: 'Truck', value: 1 },
                  ]}
                  onChange={(e) => onChange(e.value)}
                  layout="horizontal"
                  {...restField}
                />
              )}
            />
            <Controller
              name="gpsInstalled"
              control={control}
              render={({ field }) => <Checkbox label="GPS Installed" boldLabel {...field} />}
            />
          </div>
          <div className={styles.formColumn}>
            <Controller
              name="purchaseDate"
              control={control}
              rules={{ ...genericRequired, ...notGreaterThanTodayValidation }}
              render={({ field }) => (
                <DateInput
                  required
                  label="Purchase Date"
                  errors={errors.purchaseDate?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="purchaseVendorName"
              control={control}
              rules={{
                required: 'Purchased From is required',
              }}
              render={({ field }) => (
                <TextInput
                  label="Purchased From"
                  errors={errors.purchaseVendorName?.message}
                  disabled
                  required
                  {...field}
                />
              )}
            />
            <FormLinkWrapper>
              <FunctionLink onClick={() => setVendorListModalOpened(true)} label="Select Vendor" />
            </FormLinkWrapper>
            <Controller
              name="dateReceived"
              control={control}
              rules={{
                validate: (d: string) => {
                  const today = dayjs();
                  const received = dayjs(d);
                  const purchased = watch('purchaseDate')
                    ? dayjs(watch('purchaseDate'))
                    : undefined;
                  if (received > today) return "Cannot be greater than today's date";
                  if (purchased && received < purchased)
                    return 'Cannot be less than date purchased';
                  return undefined;
                },
              }}
              render={({ field }) => (
                <DateInput label="Date Received" errors={errors.dateReceived?.message} {...field} />
              )}
            />
            <Controller
              name="assignedTo"
              control={control}
              rules={genericRequired}
              render={({ field }) => (
                <DropdownInput
                  label="Assigned To"
                  required
                  data={assignableCompanies}
                  textField="shortName"
                  dataItemKey="recId"
                  errors={errors.assignedTo?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="purchasePrice"
              control={control}
              render={({ field }) => (
                <CurrencyInput
                  label="Purchase Price"
                  errors={errors.purchasePrice?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="transport"
              control={control}
              render={({ field }) => (
                <CurrencyInput label="Transport" errors={errors.transport?.message} {...field} />
              )}
            />
            <MultipleInputRow label="P.S.I. Cost/Days" htmlFor="psiCostDays">
              <Controller
                name="psiCost"
                control={control}
                render={({ field }) => (
                  <CurrencyInput id="psiCostDays" errors={errors.psiCost?.message} {...field} />
                )}
              />
              <Controller
                name="psiDays"
                control={control}
                render={({ field }) => (
                  <NumberInput id="psiCostDays" errors={errors.psiDays?.message} {...field} />
                )}
              />
            </MultipleInputRow>
            <Controller
              name="milesIn"
              control={control}
              rules={{
                required: 'Miles In is required',
                min: { value: 0, message: 'Miles In cannot be less than 0' },
              }}
              render={({ field }) => (
                <NumberInput
                  label="Miles In"
                  required
                  errors={errors.milesIn?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="notActual"
              control={control}
              render={({ field }) => <Checkbox label="Not Actual" alignWithTextInputs {...field} />}
            />
            <Controller
              name="exceedsLimit"
              control={control}
              render={({ field }) => (
                <Checkbox label="Exceeds Limit" alignWithTextInputs {...field} />
              )}
            />
            <Controller
              name="printExempt"
              control={control}
              render={({ field }) => (
                <Checkbox label="Print Exempt" alignWithTextInputs {...field} />
              )}
            />
          </div>
          <div className={styles.formColumn}>
            <span style={{ fontWeight: 700 }}>Alerts</span>
            <Spacer size={11} />
            <Controller
              name="trueMilesUnknown"
              control={control}
              render={({ field }) => <Checkbox label="TMU (True Miles Unknown)" {...field} />}
            />
            <Controller
              name="salvageTitle"
              control={control}
              render={({ field }) => <Checkbox label="Salvage Title" {...field} />}
            />
            <Controller
              name="frameDamage"
              control={control}
              render={({ field }) => <Checkbox label="Frame Damage" {...field} />}
            />
            <Controller
              name="unibody"
              control={control}
              render={({ field }) => <Checkbox label="Unibody" {...field} />}
            />
            <Controller
              name="airBagDisclosure"
              control={control}
              render={({ field }) => <Checkbox label="Airbag Disclosure" {...field} />}
            />
            <div style={{ display: 'flex' }}>
              <Controller
                name="customAlert"
                control={control}
                render={({ field }) => <Checkbox label="" {...field} />}
              />
              <Controller
                name="customAlertReason"
                control={control}
                render={({ field }) => <TextInput disabled={!watch('customAlert')} {...field} />}
              />
            </div>
            <Spacer expand />
            <Button
              fullWidth={false}
              type="submit"
              label="Submit"
              loading={isSubmitting}
              disabled={!(isDirty && isValid)}
            />
          </div>
        </form>
      </Modal>

      <SelectVendorModal
        opened={vendorListModalOpened}
        setOpened={setVendorListModalOpened}
        selectVendor={selectVendor}
      />
    </>
  );
};

export default AddNewInventoryForm;
