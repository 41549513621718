import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
import { PmtSubviewDb, newPmtSubviewItem } from './interfaces';

export interface IPmtSubviewCtx {
  pmtSubviewList: PmtSubviewDb[];
  setPmtSubviewList: (pmtSubviewList: PmtSubviewDb[]) => void;
  pmtSubviewMap: Map<PmtSubviewDb['recId'], PmtSubviewDb>;
  getPmtById: (lookupKey: PmtSubviewDb['recId']) => PmtSubviewDb;
}

const PmtSubviewCtx = createContext<IPmtSubviewCtx | null>(null);

const PmtSubviewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [pmtSubviewList, setPmtSubviewList_] = useState<PmtSubviewDb[]>([]);

  return (
    <PmtSubviewCtx.Provider
      value={{
        pmtSubviewList,
        setPmtSubviewList: (pmtSubviewDbArr: PmtSubviewDb[]) => {
          const pmtSubviewList = pmtSubviewDbArr.map((a) => newPmtSubviewItem(a));

          setPmtSubviewList_(pmtSubviewList);
        },
        get pmtSubviewMap(): Map<PmtSubviewDb['recId'], PmtSubviewDb> {
          return new Map<PmtSubviewDb['recId'], PmtSubviewDb>(
            pmtSubviewList.map((c) => [c.recId, c])
          );
        },
        getPmtById(lookupKey: PmtSubviewDb['recId']) {
          const acct = this.pmtSubviewMap.get(lookupKey);
          if (!acct) throw new Error(`No Payment found for recId: ${lookupKey}`);
          return acct;
        },
      }}
    >
      {children}
    </PmtSubviewCtx.Provider>
  );
};

export const usePmtSubviewCtx = <T,>(selector: (state: IPmtSubviewCtx) => T): T => {
  const ctx = useContext(PmtSubviewCtx);
  if (!ctx) {
    throw new Error('usePmtSubviewCtx must be used within PmtSubviewProvider');
  }
  return selector(ctx);
};

export default PmtSubviewProvider;
