import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Loader, TextInput, DropdownInput, NumberInput, TextArea, Checkbox, Button } from '@/components';
import { PageHeader } from '@/components/pageHeader/PageHeader';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { inventoryService } from '@/services/inventoryService';
import { useNavigationConfirm } from '@/hooks';
import { optionalNotGreaterThanTodayValidation } from '@/utils/helpers/formValidation';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { TitleAndTagInformation as TitleAndTagInformationDto } from '@/interfaces';
import { DateString } from '@/utils/types';
// style
import styles from './TitleAndTagInformation.module.scss';
import inventoryPageStyles from '../InventoryPageStyles.module.scss';

type TitleHeaderMessage =
  | 'Title Has Not Been Received'
  | 'Title is In'
  | `Title is Out ${string | undefined}`;

export const deliveredMethodData = ['In Person', 'Via Certified Mail', 'Other'];

export const TitleAndTagInformation: FC = () => {
  const [getTitleAndTagInformationLoading, setGetTitleAndTagInformationLoading] = useState(false);
  const [updateTitleAndTagInformationLoading, setUpdateTitleAndTagInformationLoading] =
    useState(false);
  const [titleHeaderMessage, setTitleHeaderMessage] = useState<TitleHeaderMessage | undefined>(
    undefined
  );
  const { userName } = useAuthSelector((s) => s);
  const params = useParams();
  const invRecId = Number(params.invRecId);

  const getTitleAndTagInformation = async (invRecId: number) => {
    setGetTitleAndTagInformationLoading(true);
    try {
      const res = await inventoryService.getTitleAndTagInformation(invRecId);
      return res;
    } finally {
      setGetTitleAndTagInformationLoading(false);
    }
  };

  const updateTitleAndTagInformation = async (data: TitleAndTagInformationDto) => {
    setUpdateTitleAndTagInformationLoading(true);
    try {
      await inventoryService.updateTitleAndTagInformation(data);
      toast.success('Title & Tag Information Updated');
      reset(watch());
    } finally {
      setUpdateTitleAndTagInformationLoading(false);
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm<TitleAndTagInformationDto>({ mode: 'onChange' });
  const { saleStatus, titleInD, titleOutD, titleOutTo } = watch();

  const initForm = async () => {
    const resDto = await getTitleAndTagInformation(invRecId);
    reset(resDto);
  };

  useEffect(() => {
    initForm();
  }, []);

  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  const configureTitleHeaderMessage = (
    titleIn?: DateString,
    titleOut?: DateString,
    titleOutTo?: string
  ) => {
    let message: TitleHeaderMessage;
    if (!titleIn) {
      message = 'Title Has Not Been Received';
    } else if (!titleOut) {
      message = 'Title is In';
    } else {
      message = `Title is Out ${titleOutTo}`;
    }

    return message;
  };

  const toggleVerifiedBy = (isVerified: boolean) => {
    const newTitleVByName: string = isVerified
      ? `${userName} on ${dayjs().format('MM/DD/YY hh:mm a')}`
      : '';
    setValue('titleVByName', newTitleVByName);
  };

  useEffect(() => {
    setTitleHeaderMessage(configureTitleHeaderMessage(titleInD, titleOutD, titleOutTo));
    // @todo deps arr must use stable ref
  }, [titleInD, titleOutD, titleOutTo]);

  // @todo move nested components to separate files
  return (
    <>
      <div className={inventoryPageStyles.container}>
        <div className={inventoryPageStyles.header}>
          <PageHeader headerLabel="Title & Tag Information">
            <div
              className={
                titleHeaderMessage === 'Title Has Not Been Received'
                  ? styles.titleHeaderBad
                  : styles.titleHeaderGood
              }
            >
              {!getTitleAndTagInformationLoading && titleHeaderMessage}
            </div>
          </PageHeader>
        </div>
        <form
          className={inventoryPageStyles.body}
          onSubmit={handleSubmit(updateTitleAndTagInformation)}
        >
          {getTitleAndTagInformationLoading ? (
            <Loader size="large" />
          ) : (
            <>
              <div className={inventoryPageStyles.bodyContainer}>
                <div className={inventoryPageStyles.bodyChildMultipleSections}>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <Controller
                      name="titleNum"
                      control={control}
                      render={({ field }) => <TextInput label="Title Number" {...field} />}
                    />
                    <Controller
                      name="titleSt"
                      control={control}
                      render={({ field }) => (
                        <DropdownInput
                          suggest
                          label="Title State"
                          data={usaStateCodes}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="altsVehTitleIssueDate"
                      control={control}
                      rules={{ ...optionalNotGreaterThanTodayValidation }}
                      render={({ field }) => (
                        <DateInput
                          label="Title Issue Date"
                          errors={errors.altsVehTitleIssueDate?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="titleOAss"
                      control={control}
                      rules={{ min: { value: 0, message: 'Value cannot be less than 0' } }}
                      render={({ field }) => (
                        <NumberInput
                          format="n0"
                          label="# Open Assignments"
                          errors={errors.titleOAss?.message}
                          {...field}
                        />
                      )}
                    />
                  </section>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <Controller
                      name="titleInD"
                      control={control}
                      rules={{ ...optionalNotGreaterThanTodayValidation }}
                      render={({ field }) => (
                        <DateInput
                          label="Title In Date"
                          errors={errors.titleInD?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="titleOutD"
                      control={control}
                      rules={{ ...optionalNotGreaterThanTodayValidation }}
                      render={({ field }) => (
                        <DateInput
                          label="Title Out Date"
                          errors={errors.titleOutD?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="titleDateComplete"
                      control={control}
                      render={({ field }) => <DateInput label="Title Complete" {...field} />}
                    />
                  </section>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <Controller
                      name="titleOutTo"
                      control={control}
                      render={({ field }) => (
                        <TextArea label="Title Out (Where/Why)" rows={3} {...field} />
                      )}
                    />
                    <Controller
                      name="titleNotes"
                      control={control}
                      render={({ field }) => <TextArea label="Title Notes" rows={3} {...field} />}
                    />
                  </section>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <Controller
                      name="salvageTitle"
                      control={control}
                      render={({ field }) => <Checkbox label="Salvage Title" {...field} />}
                    />
                    <Controller
                      name="titlePCopy"
                      control={control}
                      render={({ field }) => <Checkbox label="Photo Copy On-File" {...field} />}
                    />
                    <Controller
                      name="atCustodian"
                      control={control}
                      render={({ field }) => <Checkbox label="At Custodian" {...field} />}
                    />
                    <Controller
                      name="titleVer"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <Checkbox
                          label="I have personally inspected this title"
                          {...restProps}
                          onChange={(e) => {
                            onChange(e.value);
                            toggleVerifiedBy(e.value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="titleVByName"
                      control={control}
                      render={({ field }) => <TextInput label="Verified By" readOnly {...field} />}
                    />
                  </section>
                </div>
                <div className={inventoryPageStyles.bodyChildMultipleSections}>
                  {saleStatus?.toLowerCase() === 's' && (
                    <section className={inventoryPageStyles.multipleSectionsChild}>
                      <Controller
                        name="tDelivered"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            labelPlacement="before"
                            label="Title/Tag Delivered"
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="tDelDate"
                        control={control}
                        render={({ field }) => <DateInput label="Date Delivered" {...field} />}
                      />
                      <Controller
                        name="tDelTo"
                        control={control}
                        render={({ field }) => <TextInput label="Delivered To" {...field} />}
                      />
                      <Controller
                        name="tDelMethod"
                        control={control}
                        render={({ field }) => (
                          <DropdownInput label="Method" data={deliveredMethodData} {...field} />
                        )}
                      />
                    </section>
                  )}
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <SectionHeader title="Temporary Plate" showBar={false} />
                    <Controller
                      name="tempTagNum"
                      control={control}
                      render={({ field }) => <TextInput label="Plate Number" {...field} />}
                    />
                    <Controller
                      name="tempTagIss"
                      control={control}
                      rules={{ ...optionalNotGreaterThanTodayValidation }}
                      render={({ field }) => (
                        <DateInput
                          label="Date Issued"
                          errors={errors.tempTagIss?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="tempTagExp"
                      control={control}
                      rules={{
                        validate: (date?: DateString) => {
                          if (!date) return undefined;
                          const oneYearFromNow = dayjs().add(1, 'year').format('YYYY-MM-DD');
                          const today = dayjs().format('YYYY-MM-DD');
                          if (date < today) return 'Date cannot be in the past';
                          if (date > oneYearFromNow)
                            return 'Date cannot be greater than 1 year from now';
                          return undefined;
                        },
                      }}
                      render={({ field }) => (
                        <DateInput
                          label="Expiration Date"
                          errors={errors.tempTagExp?.message}
                          {...field}
                        />
                      )}
                    />
                  </section>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <SectionHeader title="Vehicle Tag" showBar={false} />
                    <Controller
                      name="tagNum"
                      control={control}
                      render={({ field }) => <TextInput label="Tag Number" {...field} />}
                    />
                    <Controller
                      name="tagState"
                      control={control}
                      render={({ field }) => (
                        <DropdownInput suggest label="Tag State" data={usaStateCodes} {...field} />
                      )}
                    />
                    <Controller
                      name="tagExp"
                      control={control}
                      rules={{
                        validate: (date?: DateString) => {
                          if (!date) return undefined;
                          const oneYearFromNow = dayjs().add(3, 'year').format('YYYY-MM-DD');
                          const today = dayjs().format('YYYY-MM-DD');
                          if (date < today) return 'Date cannot be in the past';
                          if (date > oneYearFromNow)
                            return 'Date cannot be greater than 3 years from now';
                          return undefined;
                        },
                      }}
                      render={({ field }) => (
                        <DateInput
                          label="Expiration Date"
                          errors={errors.tagExp?.message}
                          {...field}
                        />
                      )}
                    />
                  </section>
                  <section className={inventoryPageStyles.multipleSectionsChild}>
                    <SectionHeader title="Motor Vehicle Inspection" showBar={false} />
                    <Controller
                      name="inspNum"
                      control={control}
                      render={({ field }) => <TextInput label="Inspection Number" {...field} />}
                    />
                    <Controller
                      name="inspDate"
                      control={control}
                      rules={{ ...optionalNotGreaterThanTodayValidation }}
                      render={({ field }) => (
                        <DateInput
                          label="Date of Inspection"
                          errors={errors.inspDate?.message}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="inspExp"
                      control={control}
                      rules={{
                        validate: (date?: DateString) => {
                          if (!date) return undefined;
                          const oneYearFromNow = dayjs().add(1, 'year').format('YYYY-MM-DD');
                          const today = dayjs().format('YYYY-MM-DD');
                          if (date < today) return 'Date cannot be in the past';
                          if (date > oneYearFromNow)
                            return 'Date cannot be greater than 1 year from now';
                          return undefined;
                        },
                      }}
                      render={({ field }) => (
                        <DateInput
                          label="Expiration Date"
                          errors={errors.inspExp?.message}
                          {...field}
                        />
                      )}
                    />
                  </section>
                </div>
              </div>
              <div className={inventoryPageStyles.submitButtonContainer}>
                <Button label="Submit" loading={updateTitleAndTagInformationLoading} />
              </div>
            </>
          )}
        </form>
      </div>
      {NavigationConfirm}
    </>
  );
};
