import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
// kendo
import { IKendoPaginationState, SortKendo } from '@/interfaces/kendo';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { collectionsService } from '@/services/collectionsService';
import { paymentService } from '@/services/paymentService';
// interfaces
import { RepoCompany } from '@/services/paymentService';
import { EmployeeField } from '@/interfaces/System';
import { OrderForRepoFormData, OutForRepoReq } from './interfaces';
import { OrderForRepoRowData } from '@/features/Dashboard/interfaces';

const initSort: SortKendo[] = [new SortKendo('recId', 'desc')];
export const initPage: IKendoPaginationState = { skip: 1, take: 25 };

export interface IOutForRepoCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  isSubmitSuccessful: boolean;
  setIsSubmitSuccessful: (_: boolean) => void;
  isExpanded: boolean;
  setIsExpanded: (_: boolean) => void;
  repoRows: OrderForRepoRowData[];
  setRepoRows: (_rows: IOutForRepoCtx['repoRows']) => void;
  activeRepoRow: OrderForRepoRowData | null;
  activeRowId: number | null;
  setActiveRowId: (_activeId: IOutForRepoCtx['activeRowId']) => void;
  repoTotal: number;
  setRepoTotal: (_repoTotal: IOutForRepoCtx['repoTotal']) => void;
  page: IKendoPaginationState;
  setPage: (page: IOutForRepoCtx['page']) => void;
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: IOutForRepoCtx['pageSizeValue']) => void;
  queryPageNumber: number;
  sorts: SortKendo<string>[];
  setSorts: (sorts: IOutForRepoCtx['sorts']) => void;
  employeeList: EmployeeField[];
  setEmployeeList: (_employeeList: IOutForRepoCtx['employeeList']) => void;
  repoCompanyList: RepoCompany[];
  setRepoCompanyList: (_repoCompanyList: IOutForRepoCtx['repoCompanyList']) => void;
  outForRepoForm: UseFormReturn<OrderForRepoFormData, any, undefined>;
}

const OutForRepoCtx = createContext<IOutForRepoCtx | null>(null);

const OutForRepoProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const compId = useAccountSelector((s) => s.accountInformation!.compId);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [repoRows, setRepoRows] = useState<IOutForRepoCtx['repoRows']>([]);
  const [activeRowId, setActiveRowId] = useState<IOutForRepoCtx['activeRowId'] | null>(null);
  const [repoTotal, setRepoTotal] = useState<IOutForRepoCtx['repoTotal']>(0);
  const [page, setPage] = useState<IOutForRepoCtx['page']>(initPage);
  const [sorts, setSorts_] = useState<IOutForRepoCtx['sorts']>(initSort);
  const [employeeList, setEmployeeList] = useState<IOutForRepoCtx['employeeList']>([]);
  const [repoCompanyList, setRepoCompanyList] = useState<RepoCompany[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const activeRepoRow = repoRows.find((r) => activeRowId === r.recId) || null;
  const defaultValues: Partial<OrderForRepoFormData> = {
    recId: 0,
    orgId: 0,
    locId: 0,
    compId: 0,
    colRecId: 0,
    appRecId: 0,
    openTime: '',
    closeTime: '',
    fcStatus: 'Open',
    assgnTo: 0,
    assgnToName: '',
    sText: true,
    fcRes: false,
    fcWork: false,
    fcOther: false,
    fcWhere: '',
    spIns: '',
    closedReson: '',
    repoCompID: 0,
    colType: '',
    closeIt: false,
    vehicleRecovered: false,
  };

  const outForRepoForm = useForm<OrderForRepoFormData>({
    defaultValues: defaultValues,
  });

  const { reset, setValue } = outForRepoForm;

  useEffect(() => {
    if (activeRepoRow && activeRepoRow !== null) {
      setValue('assgnToName', '');
      reset(activeRepoRow);
      setIsExpanded(true); // Expand the form if an open row is found
    } else {
      reset(defaultValues); // Reset to default values if no open row is found
      setIsExpanded(false); // Ensure the form is not expanded
    }
  }, [activeRowId]);

  const setSorts = (sortsToSet: (Omit<SortKendo, 'dir'> & { dir?: SortKendo['dir'] })[]) => {
    const newSortsArr = sortsToSet.map((s) => new SortKendo(s.field, s.dir));
    setSorts_(newSortsArr);
  };

  const loadRepoHistory = async () => {
    if (isNaN(Number(appRecId))) return;
    try {
      const payload = OutForRepoReq.new_({ appRecId: Number(appRecId), page, sorts });
      const repoList = await collectionsService.getOutForReposPerApp(payload);
      const openRowId = repoList.data.find((row) => row.fcStatus === 'Open')?.recId;
      setRepoRows(repoList.data);
      setActiveRowId(openRowId || null);
      setRepoTotal(repoList.total);
    } catch (_) {}
  };

  const loadEmployeeList = async () => {
    if (isNaN(Number(compId))) return;
    try {
      const employeeList = await paymentService.getUsersByCompanyId(compId!);
      setEmployeeList(employeeList);
    } catch (_) {}
  };

  const loadRepoCompanyList = async () => {
    if (isNaN(Number(compId))) return;
    try {
      const repoCompanyList = await paymentService.getRepoCompaniesByCompanyId(compId!);
      setRepoCompanyList(repoCompanyList || []);
    } catch (_) {}
  };

  useEffect(() => {
    setIsSubmitSuccessful(false);
    loadRepoHistory();
    loadEmployeeList();
    loadRepoCompanyList();
  }, [appRecId, compId, isSubmitSuccessful]);

  return (
    <OutForRepoCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        isSubmitSuccessful,
        setIsSubmitSuccessful,
        isExpanded,
        setIsExpanded,
        repoRows,
        setRepoRows,
        activeRepoRow,
        activeRowId,
        setActiveRowId,
        repoTotal,
        setRepoTotal,
        outForRepoForm,
        sorts,
        setSorts,
        page,
        setPage,
        employeeList,
        setEmployeeList,
        repoCompanyList,
        setRepoCompanyList,

        get pageSizeValue(): number {
          return this.page.take!;
        },
        setPageSizeValue(take: number) {
          setPage({ ...page, take });
        },
        // @todo remove once backend calc for `LIMIT` clause (the 'per-page' value) is removed
        get queryPageNumber(): number {
          return Math.round(this.page.skip / this.page.take) + 1;
        },
      }}
    >
      {children}
    </OutForRepoCtx.Provider>
  );
};

export default OutForRepoProvider;

export const useOutForRepoCtx = <T,>(selector: (state: IOutForRepoCtx) => T): T => {
  const ctx = useContext(OutForRepoCtx);
  if (!ctx) {
    throw new Error('useOutForRepoCtx must be used within OutForRepoProvider');
  }
  return selector(ctx);
};
