import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
// mui
import MuiProvider from "@/mui/MuiProvider";
// components
import Router from "./Router";
// state
import { store } from "@/store/store";
// style
import "@/styles/index.scss";

// Override toast.error to make it sticky by default
const originalError = toast.error; // Keep a reference to the original method
toast.error = (message, options = {}) => {
  return originalError(message, {
    ...options,
    autoClose: false, // Sticky by default for error toasts
    progress: 1
  });
};

/** ###  */
const App: FC<PropsWithChildren> = ({ children }) => (
  <Provider store={store}>
    <MuiProvider>{children || <Router />}</MuiProvider>
    <ToastContainer hideProgressBar={false}/>
    {/* @note add `stacked` prop after upgrading `react-toastify` to v10 - <ToastContainer stacked /> */}
  </Provider>
);

export default App;
