import { FC, SyntheticEvent } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// state
import { useDashboardViewCtx } from '../../DashboardViewProvider';
// utils
import { DateRangeOptionKey, dateRangeOptionLabels } from '@/general/dates';
// interfaces
import { DateRangeFilter } from '@/interfaces/tableState';

dayjs.extend(utc);

const autocompleteStyle = { minWidth: '200px' };

/** ###  */
const DateRangeDropdown: FC = () => {
  const dateRangeFilter = useDashboardViewCtx((s) => s.dateRangeFilter);
  const inputValue = useDashboardViewCtx((s) => s.dateRangeFilter?.dateRange);
  const setDateRangeFilter = useDashboardViewCtx((s) => s.setDateRangeFilter);

  const handleChangeDate = (_: SyntheticEvent<Element, Event>, dateRangeLabel: string) => {
    const dateRangeOption = dateRangeLabel as DateRangeOptionKey;
    const newDateRangeFilter =
      dateRangeOption === 'Custom'
        ? new DateRangeFilter({ ...dateRangeFilter, dateRange: dateRangeOption })
        : DateRangeFilter.fromDateRange(dateRangeOption as DateRangeOptionKey);

    setDateRangeFilter(newDateRangeFilter);
  };

  return (
    <Autocomplete
      sx={autocompleteStyle}
      options={dateRangeOptionLabels}
      disableClearable
      value={inputValue}
      onChange={handleChangeDate}
      renderInput={(p) => (
        <TextField
          {...p}
          label="Date Range"
          InputProps={{ ...p.InputProps, sx: { fontSize: '14px' } }}
          InputLabelProps={{ sx: { fontSize: '14px' } }}
        />
      )}
    />
  );
};

export default DateRangeDropdown;
