import dayjs from 'dayjs';


export interface ICashTermQuoteBase {
  salesPrice: number;
  salesTax: number;
  license: number;
  title: number;
  registration: number;
  customField1: number;
  customField2: number;
  customField3: number;
  customField4: number;
  customField5: number;
  customField6: number;
  serviceContract: number;
  totalSalePrice: number;
  tradeAllowance: number;
  tradePayoff: number;
  depositDown: number;
  cashOnDelivery: number;
}
export interface ICashTerm extends ICashTermQuoteBase {
  dateOfSale: string; // dayjs().format('YYYY-MM-DD')
}
export const cashTermFormDefaults = {
  dateOfSale: dayjs().format('YYYY-MM-DD'),
  salesTax: 0,
  salesPrice: 0,
  license: 0,
  title: 0,
  registration: 0,
  customField1: 0,
  customField2: 0,
  customField3: 0,
  customField4: 0,
  customField5: 0,
  customField6: 0,
  serviceContract: 0,
  totalSalePrice: 0,
  tradeAllowance: 0,
  tradePayoff: 0,
  depositDown: 0,
  cashOnDelivery: 0,
};

export type CashTermData = typeof cashTermFormDefaults;

export enum TermsLockType {
  Num = 'Num',
  Amt = 'Amt',
}
