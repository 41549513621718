import { FC, useState } from 'react';
import { toast } from 'react-toastify';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
import { Button } from '@/components';
// utils
import { paymentService } from '@/services/paymentService';

/** ###  */
const ActionsCell: FC<GridCellProps> = ({ dataItem: { recId } }) => {
  const [printing, setPrinting] = useState(false);

  const handlePrint = (recId: number) => async () => {
    setPrinting(true);

    try {
      const receiptUrl = await paymentService.getReceiptUrl(recId);
      if (!receiptUrl) throw new Error();
      window.open(receiptUrl);
    } catch (e) {
      toast.error('Unable to reprint receipt');
    } finally {
      setPrinting(false);
    }
  };

  return (
    <td className="k-table-td">
      <Button label="Print" onClick={handlePrint(recId)} loading={printing} secondary />
    </td>
  );
};

export default ActionsCell;
