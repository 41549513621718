import { FC } from 'react';
// components
import ExportButton from '../ReportsList/ExportButton';
// utils
import { useReportsCtx } from '../ReportsList/defaultState';
import { IFormErrors } from '../interfaces';

/** ### Shows fields to customize report-exports
 *
 * These fields render based on certain conditions from the `report` payload.
 * - Modifying these fields updates the "Reports" view state:
 *    - These report-modifier values are used in the request.
 * - Button is used to submit request to generate report.
 *
 * @note double nesting in div due to parent component access to style
 */
const ModalExportButtons: FC = () => {
  const selectedReport = useReportsCtx(s => s.selectedReport);
  const formErrors = useReportsCtx(s => s.formErrors);

  let isButtonDisabled = false;
  for (const iterator in formErrors) {
    const isError = formErrors[iterator as keyof IFormErrors];
    if (isError === true) {
      isButtonDisabled = isError;
      break;
    }
  }

  return (
    <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          gap: '40px',
          padding: '10px',
          borderRadius: '25px',
          background: '#eff0f4',
        }}
      >
        <ExportButton
          report={selectedReport!}
          fileType="xlsx"
          isInModal
          isDisabled={isButtonDisabled}
        />
        <ExportButton
          report={selectedReport!}
          fileType="csv"
          isInModal
          isDisabled={isButtonDisabled}
        />
        <ExportButton
          report={selectedReport!}
          fileType="pdf"
          isInModal
          isDisabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default ModalExportButtons;
