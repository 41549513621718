import { FC, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@/mui/components/buttons/MuiKendoButton';
// kendo
import { Modal } from "@/components/modals/Modal";
// components
import WsCompanyModalListView from './WsCompanyModalListView';
import WsCompanyModalDetailView from './WsCompanyModalDetailView';
// state
import { useWholesaleViewCtx } from '../../WholesaleViewProvider';
import { useWholesaleCompanyFormCtx } from './WholesaleCompanyFormProvider';

const WsCompanyModal: FC = () => {
  // Parent view state
  const setIsWsCompanyModalOpen = useWholesaleViewCtx((s) => s.setIsWsCompanyModalOpen);
  const isWsCompanyModalOpen = useWholesaleViewCtx((s) => s.isWsCompanyModalOpen);
  const isModalOpen = useWholesaleViewCtx((s) => s.isModalOpen);
  // Modal-form state
  const setWsCompanyRes = useWholesaleCompanyFormCtx((s) => s.setWsCompanyRes);
  const isFormLoaded = useWholesaleCompanyFormCtx((s) => s.isFormLoaded);

  // Event handlers
  const handleCloseModal = () => setIsWsCompanyModalOpen(false);

  if (isModalOpen || !isWsCompanyModalOpen) return <></>;

  let ViewComponent: ReactNode = <WsCompanyModalListView />;
  if (isFormLoaded) ViewComponent = <WsCompanyModalDetailView />;

  return (
    <Modal
      isOpen={isWsCompanyModalOpen}
      closeButton
      centerModal
      onCloseButtonClick={handleCloseModal}
      panelChildrenStyle={{ overflow: 'hidden', display: 'flex' }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid container direction="row" justifyContent="space-between" alignContent="center" py={1}>
          <Grid container>
            <Typography sx={{ fontWeight: 700, alignSelf: 'center', fontSize: '16px' }}>
              {!isFormLoaded ? 'Select Wholesale Company' : 'Add Wholesale Company'}
            </Typography>
          </Grid>
          {/* @todo not necessary for PR - add text-search and move this to that div */}
          {!isFormLoaded && <Button onClick={() => setWsCompanyRes(null)}>Add new</Button>}
        </Grid>
        {ViewComponent}
      </Grid>
    </Modal>
  );
};

export default WsCompanyModal;
