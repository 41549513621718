import { useSelector } from 'react-redux';
// interfaces
import { AppState } from '@/store/appState';

/** ### Utility hook which provides type-checking for Redux's `useSelector`
 * @todo - move Redux state to React context
 */
export function useAppStateSelector<TSelected>(
  selector: (state: AppState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return useSelector<AppState, TSelected>(selector, equalityFn);
}

/** ### Utility hook which selects a nested group of states
 * @todo - move Redux state to React context
 */
export function buildSubStateSelector<TSubState>(
  baseSubStateSelector: (state: AppState) => TSubState
) {
  return function <TSelected>(
    subSelector: (state: TSubState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ) {
    const selector = (state: AppState) => subSelector(baseSubStateSelector(state));
    return useSelector<AppState, TSelected>(selector, equalityFn);
  };
}
