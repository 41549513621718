import { FC } from "react";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
import { Icons } from "../icons";

/** ###  */
const ScOpenCell: FC<GridCellProps> = ({ ...props }) => (
  <td className="k-table-td">
    <span>
      {props.dataItem.scOpen ? <Icons.Service style={{ width: 14, height: 14 }} /> : "--"}
    </span>
  </td>
);

export default ScOpenCell;
