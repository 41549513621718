import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';
import { Collection } from '@/services/collectionsService';

const initialState = {
  queueAccounts: null as unknown as Collection[],
};

export type CollectionsAppState = typeof initialState;

export const slice = createSlice<
  CollectionsAppState,
  {
    setQueueAccounts: CaseReducer<CollectionsAppState, PayloadAction<Collection[]>>;
    reset: CaseReducer<CollectionsAppState>;
  }
>({
  name: 'collections',
  initialState,
  reducers: {
    setQueueAccounts: (state, { payload }) => {
      state.queueAccounts = payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const useCollectionsSelector = buildSubStateSelector<CollectionsAppState>(
  (state) => state.collections
);
export const collectionsActions = slice.actions;
export const collectionsReducer = slice.reducer;
