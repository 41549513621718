import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const CpiPaidField: FC = () => {
  const cpiDueDate = usePmtFormCtx((s) => s.cpiDueDate);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("cpiPaid"));

  const title = `CPI Due${cpiDueDate ? ` on ${cpiDueDate}` : ""}`;

  return <CurrencyInput label={title} required {...fieldProps} />;
};

export default CpiPaidField;
