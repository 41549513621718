// components
import TotalAppliedCell from '../TotalAppliedCell';
import ActionsCell from './ActionsCell';
import ColTypeCell from './ColTypeCell';
// utils
import { PmtSubviewDb } from '../interfaces';
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';

export const miscPaymentTableColumns: KendoColumnDef<keyof PmtSubviewDb>[] = [
  newColumnDef('pmtDate', 'Payment Date', 140, 'date'),
  newColumnDef('mPayToFrom', 'Customer Name'),
  newColumnDef('totalApplied', 'Amount', 125, TotalAppliedCell),
  newColumnDef('colType', 'In/Out', 90, ColTypeCell),
  newColumnDef('mPayCat', 'Category', 200),
  newColumnDef('mPayNote', 'Note', 'auto'),
  newColumnDef('code3', 'Lot', 80),
  newColumnDef('recId', 'Actions', 130, ActionsCell, false),
];
