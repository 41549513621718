import { FC } from 'react';
import BuyersGuideProvider, { useBuyersGuideCtx } from './BuyersGuideProvider';
import BuyersGuideForm from './BuyersGuideForm';
import { PageHeader } from '@/components/pageHeader/PageHeader';
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import { Loader } from '@/components';

const BuyersGuide: FC = () => {
  const isLoading = useBuyersGuideCtx((s) => s.isLoading);
  const setIsLoading = useBuyersGuideCtx((s) => s.setIsLoading);

  return (
    <>
      <div className={inventoryPageStyles.container}>
        <div className={inventoryPageStyles.header}>
          <PageHeader headerLabel="Buyer's Guide"></PageHeader>
        </div>
        <section>
          {isLoading ? (
            <Loader size="large" />
          ) : (
            <div className={inventoryPageStyles.bodyContainer}>
              <div className={inventoryPageStyles.bodyChild}>
                <BuyersGuideForm />
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default () => (
  <BuyersGuideProvider>
    <BuyersGuide />
  </BuyersGuideProvider>
);
