import { KendoFieldOption } from '@/interfaces/kendo';

export const buildOptionsFromEnum = (enumParam: { [key: number]: string }): KendoFieldOption[] => {
  const optionsArr: KendoFieldOption[] = [];

  for (const key in enumParam) {
    if (Object.prototype.hasOwnProperty.call(enumParam, key)) {
      typeof enumParam[key] === 'string' &&
        optionsArr.push({ dataItemKey: key, textField: enumParam[key]! });
    }
  }

  return optionsArr;
};

/** DB=Form: Mon=Mon, Tue=Tue, Wed=Wed, Thu=Thu, Fri=Fri, Sat=Sat, Sun=Sun */
export enum DayOfWeek {
  'Mon' = 'Mon',
  'Tue' = 'Tue',
  'Wed' = 'Wed',
  'Thu' = 'Thu',
  'Fri' = 'Fri',
  'Sat' = 'Sat',
  'Sun' = 'Sun',
}
export type DayOfWeekKey = keyof typeof DayOfWeek;
export const dayOfWeekOptions = buildOptionsFromEnum(DayOfWeek);
export const daysOfWeek = buildOptionsFromEnum(DayOfWeek).map((o) => o.dataItemKey) as string[];

/** DB=Form: 1=YearToDate, 2=MonthToDate, 3=XDaysPrior, 4=CurrentDay, 5=Yesterday, 6=CurrentMonth, 7=PreviousMonth */
export enum ReportDateRange {
  'Year-to-Date' = 1,
  'Month-to-date' = 2,
  'X Days Prior' = 3,
  'Current Day' = 4,
  'Yesterday' = 5,
  'Current Month' = 6,
  'Previous Month' = 7,
}
export type ReportDateRangeKey = keyof typeof ReportDateRange;
export const reportDateRangeOptions = buildOptionsFromEnum(ReportDateRange);

/** DB=Form: 1=Monthly, 2=Weekly, 3=Daily */
export enum ReportFrequency {
  'Monthly' = 1,
  'Weekly' = 2,
  'Daily' = 3,
}
export type ReportFrequencyKey = keyof typeof ReportFrequency;
export const reportFrequencyOptions = buildOptionsFromEnum(ReportFrequency);

/** DB=Form: PDF=PDF, CSV=CSV, XLS=XLSX */
export enum ERSFiletype {
  'PDF' = 'PDF',
  'CSV' = 'CSV',
  'XLSX' = 'XLS',
}
export type ERSFiletypeKey = keyof typeof ERSFiletype;
export type ERSFiletypeDbKey = `${ERSFiletype}`;
export const ersFiletypeOptions = buildOptionsFromEnum(ERSFiletype);
