import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { CircleSpinner } from "react-spinners-kit";
// kendo
import { Icons } from "@/components/icons";
import { TextInput } from "@components/inputs/text/TextInput";
import { Button } from "@components/button/Button";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useAcctDetailsCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/AcctDetailProvider";
// utils
import { Messages, customerService } from "@/services/customerService";
import { accountsService } from "@/services/accountsService";
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { formatPhone } from "@/utils/helpers/formatting";
// interfaces
import { Events } from "@/enums/general";
import { ActivityType, CustomerActivity } from "./activityPanelInterfaces";
// style
import styles from "./activityPanel.module.scss";

interface ChatBoxProps {
  activityData: CustomerActivity[];
  appBuyerRecId: number;
  initData: () => Promise<void>;
  height?: number;
  loading: boolean;
  isLegal: boolean;
  canTextBuyer: boolean;
}

export const ChatBox: FC<ChatBoxProps> = (props) => {
  const { userName, compId } = useAuthSelector((s) => s);
  const signalRConnection = useAuthCtx((s) => s.signalRConnection);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [newMessageSending, setNewMessageSending] = useState(false);
  const optStatus = useAcctDetailsCtx((s) => s.optStatus);
  const optInAuthorization = useAcctDetailsCtx((s) => s.optInAuthorization);

  const { control, handleSubmit, watch, reset } = useForm<{ newMessage: string }>({
    defaultValues: {
      newMessage: "",
    },
  });

  const newMessage = watch("newMessage");

  const init = () => {
    if (!props.activityData) return;
    const tempMessages = props.activityData
      .filter((ad) => ad.activityType === ActivityType.enum.Message)
      .map((ad) => ad.activity as Messages);

    tempMessages.sort((a, b) => (b.timestamp! > b.timestamp! ? -1 : 1));

    setMessages(tempMessages);
  };

  useEffect(() => {
    init();
  }, [props.activityData]);

  useEffect(() => {
    window.addEventListener(Events.ReceivedSMS, props.initData);
    return () => window.removeEventListener(Events.ReceivedSMS, props.initData);
  }, []);

  const addNewMessage = async () => {
    setNewMessageSending(true);
    try {
      await accountsService.sendTextMessege(props.appBuyerRecId, newMessage);
      reset();
      // Fetching the messages from the history is slow so mock it in there first
      const insertedMessage: Messages = {
        timestamp: new Date().toString(),
        body: newMessage,
        sentBy: userName || "",
        sentByNumber: null,
        sentTo: null,
        sentToNumber: null,
        received: false,
        ecomRecId: Number(Math.random().toString().slice(2)),
        seen: false,
        isDirectConsentError: null,
        status: null,
        detailRaw: null,
        errorCode: null,
        errorMessage: null,
      };
      setMessages([insertedMessage, ...messages]);
      props.initData();
    } finally {
      setNewMessageSending(false);
    }
  };

  const changeReadStatus = async (changedMessage: Messages) => {
    try {
      if (changedMessage.seen) {
        await customerService.markEcomMessageAsUnread(changedMessage.ecomRecId);
      } else {
        await customerService.markEcomMessageAsRead(changedMessage.ecomRecId);
      }

      if (signalRConnection && compId) {
        await signalRConnection.send("UpdateMessageCountForConnections", compId, "");
      }

      setMessages(
        messages.map((m) => {
          if (m.ecomRecId === changedMessage.ecomRecId) {
            m.seen = !m.seen;
          }
          return m;
        })
      );
    } catch (err) {
      toast.error("Unable to change read status");
    }
  };

  const placeholderMessageSwitch = () => {
    if (optInAuthorization && optStatus !== "accepted") {
      return "Customer has not consented to texts. Messaging is disabled.";
    }
    if (props.isLegal) {
      return "Cannot contact customer with legal status";
    }
    if (!props.canTextBuyer) {
      return "Buyer has no text preference on";
    }
    return "Type a new message...";
  };

  const messagingInputDisabledSwitch = ()=>{
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (props.isLegal || !props.canTextBuyer) {
      return true;
    }
    return false;
  }

  const messagingButtonDisabledSwitch = ()=>{
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (props.isLegal||!newMessage || newMessageSending ||!props.canTextBuyer) {
      return true;
    }
    return false;
  }

  return (
    <>
      <form onSubmit={handleSubmit(addNewMessage)} className={styles.smsInputContainer}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <Controller
            name="newMessage"
            control={control}
            render={({ field }) => (
              <TextInput
                containerStyles={{ display: "flex", flex: "1" }}
                placeholder={
                  placeholderMessageSwitch()
                }
                disabled={messagingInputDisabledSwitch()}
                {...field}
              />
            )}
          />
        </div>
        <div>
          <Button
            label="Send"
            disabled={messagingButtonDisabledSwitch()}
            loading={newMessageSending}
          />
        </div>
      </form>
      <div className={styles.chatBoxContainer}>
        {props.loading ? (
          <div className={styles.loading}>
            <CircleSpinner color={"#3299df"} size={50} />
          </div>
        ) : (
          messages.map((message) => {
            const wasOutboundSMS = !message.received;
            const headerContents = [
              message.sentBy,
              formatDate(message.timestamp, { pattern: DateFormat.DateTime, utc: false }),
            ];
            // Date may be missing, if so don't show the dash
            const headerText = headerContents.filter((el) => !!el).join(" - ");
            return (
              <div
                className={wasOutboundSMS ? styles.smsOutbound : styles.smsInbound}
                key={message.ecomRecId}
              >
                <div className={styles.smsHeader}>{headerText}</div>
                <div className={styles.smsBodyWrapper}>
                  <div className={styles.readIcon} onClick={() => changeReadStatus(message)}>
                    {!wasOutboundSMS && message.seen && <Icons.Eye color="#129A38" />}
                    {!wasOutboundSMS && !message.seen && <Icons.EyeX color="#C60000" />}
                    {(message.isDirectConsentError ||
                      message.status === "failed" ||
                      message.status === "undelivered") && <Icons.RoundX color="#C60000" />}
                  </div>
                  <div className={styles.smsBody}>
                    {message.isDirectConsentError && (
                      <div className={styles.smsDirectConsentFailure}>
                        Failed to send, Customer opted out of text communication with phone number:{" "}
                        {formatPhone(message.sentToNumber ?? "")}
                      </div>
                    )}
                    {(message.status === "failed" || message.status === "undelivered") && (
                      <div className={styles.smsDirectConsentFailure}>
                        Message failed with error: {message.errorMessage ?? message.errorCode}
                      </div>
                    )}
                    <div>{message.body}</div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
