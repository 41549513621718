import CreditEducation from '@/components/creditEducation/CreditEducation';
import salesTabStyles from '../SalesTab.module.scss';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import NavTabs from './NavTabs';

type Props = {
  isCobuyer: boolean;
};

const CreditEducationDetail: FC<Props> = ({ isCobuyer }) => {
  const params = useParams();
  const appRecId = Number(params.id);
  const { saleData } = useSalesSelector((s) => s);
  const hasCobuyer = !!(saleData?.coBuyer?.appRecID && saleData?.coBuyer?.b_FIRST);

  return (
    <>
      <div className={salesTabStyles.container}>
        <header className={salesTabStyles.headerContainer}>
          <h2 className={salesTabStyles.header}>Credit Education</h2>
          {hasCobuyer && <NavTabs />}
        </header>
        <main className={salesTabStyles.body}>
            <CreditEducation appRecId={appRecId} isCobuyer={isCobuyer} />
          </main>
      </div>
    </>
  );
};

export default CreditEducationDetail;
