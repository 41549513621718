import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// kendo
import { Button } from '@/components/button/Button';
// state
import { useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { salesService } from '@/services/salesService';
import {
  DateFormat,
  formatAddress,
  formatDate,
  formatPhoneNumber,
  formatSSN,
  getAddressFromGoogleMaps,
} from '@/utils/helpers/general';
// interfaces
import { Employer, PreviousAddress } from '@/interfaces/Accounts';
import { Column } from '@/components/table/TableInterface';
import { BuyerType, CurrentPrevious, EmploymentType } from '@/enums/general';
import { PaymentInterval } from '@/enums/payment';
import { SaleBuyer } from '@/interfaces';
// @todo move nested components to separate files
/** @deprecated @todo use context to manage state */
export const useApplicationBuyer = (buyerOrCobuyer: BuyerType) => {
  const { saleData, hasCobuyer } = useSalesSelector((s) => s);
  const { compId, locId, orgId } = useAuthSelector((s) => s);
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const buyerRecId = saleData.buyer?.rec_Id;
  const coBuyerRecId = saleData.coBuyer?.rec_Id;
  const [prevEmployerData, setPrevEmployerData] = useState<Employer[]>([] as Employer[]);
  const [prevEmployersLoading, setPrevEmployersLoading] = useState(false);
  const [prevResidenceData, setPrevResidenceData] = useState<PreviousAddress[]>(
    [] as PreviousAddress[]
  );
  const [prevResidenceLoading, setPrevResidenceLoading] = useState(false);
  const [activeResidenceData, setActiveResidenceData] = useState<PreviousAddress | undefined>(
    undefined
  );
  const [editPrevResidenceModalOpen, setEditPrevResidenceModalOpen] = useState(false);
  const [updateBuyerApplicationLoading, setUpdateBuyerApplicationLoading] = useState(false);
  const [updateHasCobuyerLoading, setUpdateHasCobuyerLoading] = useState(false);
  const [editEmploymentHistoryModalOpen, setEditEmploymentHistoryModalOpen] = useState(false);
  const [activeEmploymentHistoryData, setActiveEmploymentHistoryData] = useState<
    Employer | undefined
  >(undefined);
  const [addEmploymentHistoryLoading, setAddEmploymentHistoryLoading] = useState(false);
  const [deleteEmploymentHistoryLoading, setDeleteEmploymentHistoryLoading] = useState(false);
  const [addResidenceHistoryLoading, setAddResidenceHistoryLoading] = useState(false);
  const [deleteResidenceHistoryLoading, setDeleteResidenceHistoryLoading] = useState(false);
  const [showMailingAddress, setShowMailingAddress] = useState(!!saleData.buyer?.b_MADDR);
  const [activeResidenceHistoryAddress, setActiveResidenceHistoryAddress] = useState<
    string | undefined
  >(undefined);
  const [activeEmploymentHistoryAddress, setActiveEmploymentHistoryAddress] = useState<
    string | undefined
  >(undefined);

  // @todo remove unnecessary optimization
  const fullAddress = useMemo(() => {
    const isCobuyer = buyerOrCobuyer === BuyerType.CoBuyer;
    let formattedAddress: string | undefined;

    if (isCobuyer) {
      formattedAddress = formatAddress(
        saleData.coBuyer?.b_ADDR,
        saleData.coBuyer?.b_CITY,
        saleData.coBuyer?.b_STATE,
        saleData.coBuyer?.b_ZIP
      );
    } else {
      // If buyer has address, set address, else it is undefined
      formattedAddress = formattedAddress = formatAddress(
        saleData.buyer?.b_ADDR,
        saleData.buyer?.b_CITY,
        saleData.buyer?.b_STATE,
        saleData.buyer?.b_ZIP
      );
    }

    return formattedAddress;
    // @todo deps arr must use stable ref
  }, [saleData]);

  // @todo remove unnecessary optimization
  const fullMailingAddress = useMemo(() => {
    const isCobuyer = buyerOrCobuyer === BuyerType.CoBuyer;
    let formattedAddress: string | undefined;

    if (isCobuyer) {
      formattedAddress = formatAddress(
        saleData.coBuyer?.b_MADDR,
        saleData.coBuyer?.b_MCITY,
        saleData.coBuyer?.b_MSTATE,
        saleData.coBuyer?.b_MZIP
      );
    } else {
      // If buyer has address, set address, else it is undefined
      formattedAddress = formattedAddress = formatAddress(
        saleData.buyer?.b_MADDR,
        saleData.buyer?.b_MCITY,
        saleData.buyer?.b_MSTATE,
        saleData.buyer?.b_MZIP
      );
    }

    return formattedAddress;
  }, [saleData]);

  // @todo remove unnecessary optimization
  const fullEmployerAddress = useMemo(() => {
    const isCobuyer = buyerOrCobuyer === BuyerType.CoBuyer;
    let formattedAddress: string | undefined;

    if (isCobuyer) {
      formattedAddress = formatAddress(
        saleData.coBuyerEmployer?.address,
        saleData.coBuyerEmployer?.city,
        saleData.coBuyerEmployer?.state,
        saleData.coBuyerEmployer?.zip
      );
    } else {
      // If buyer employer has address, set address, else it is undefined
      formattedAddress = formatAddress(
        saleData.buyerEmployer?.address,
        saleData.buyerEmployer?.city,
        saleData.buyerEmployer?.state,
        saleData.buyerEmployer?.zip
      );
    }

    return formattedAddress;
  }, [saleData]);

  // @todo move object/array/function out of component body
  const typeOfResidenceData = [
    'House',
    'Apartment',
    'Townhome',
    'Duplex',
    'Quadplex',
    'Trailer',
    'Other',
  ];

  // @todo remove unnecessary optimization
  const isDisabled = useMemo(
    () => saleData.sale?.salestatus?.toLowerCase() !== 'pending',
    [saleData]
  );

  // @todo move object/array/function out of component body
  const tdStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' };

  // @todo use class constructor function w/ validation
  const buyerValues = {
    firstName: saleData.buyer?.b_FIRST,
    middleName: saleData.buyer?.b_MID,
    lastName: saleData.buyer?.b_LAST,
    nickname: saleData.buyer?.b_NICK,
    socialSecurityNumber: formatSSN(saleData.buyer?.b_SSN) ?? '',
    dob: formatDate(saleData.buyer?.b_DOB, { pattern: DateFormat.DateInput }),
    numberOfDependents: saleData.buyer?.b_NUMDEP,
    driversLicenseNumber: saleData.buyer?.b_DL,
    expirationDate: formatDate(saleData.buyer?.b_DLEXP, { pattern: DateFormat.DateInput }),
    state: saleData.buyer?.b_DLSTATE,
    zip: saleData.buyer?.dlZip ?? '',
    cellPhoneNumber: formatPhoneNumber(saleData.buyer?.b_CPHONE) ?? '',
    homePhoneNumber: formatPhoneNumber(saleData.buyer?.b_HPHONE) ?? '',
    otherPhoneNumber: formatPhoneNumber(saleData.buyer?.b_OPHONE) ?? '',
    email: saleData.buyer?.b_EMAIL,
    address: saleData.buyer?.b_ADDR,
    aptOrSuite: saleData.buyer?.b_addr2,
    city: saleData.buyer?.b_CITY,
    addressState: saleData.buyer?.b_STATE,
    addressZip: saleData.buyer?.b_ZIP ?? '',
    county: saleData.buyer?.b_COUNTY,
    rentOrOwn: saleData.buyer?.b_RENTOWN,
    rentOrOwnYears: saleData.buyer?.b_RYEARS,
    rentOrOwnMonths: saleData.buyer?.b_RMONTHS,
    typeOfResidence: saleData.buyer?.b_HOMETYPE,
    landlordsName: saleData.buyer?.b_LANDMORG,
    monthlyPayment: saleData.sale?.billRent,
    mortgageBalance: saleData.buyer?.b_MORGBAL,
    currentOrPastDue: saleData.buyer?.b_CURPAST,
    landlordsPhoneNumber: formatPhoneNumber(saleData.buyer?.b_LLPHONE) ?? '',
    signatureVerifiedBy: saleData.buyer?.b_VERSIGNBY,
    creditDateSigned: formatDate(saleData.buyer?.b_SIGNED, { pattern: DateFormat.DateInput }),
    totalMonthlyNetIncome: saleData.sale?.netPay,
    primaryEmployer: saleData.buyerEmployer?.employer,
    occupation: saleData.buyerEmployer?.jobTitle,
    supervisor: saleData.buyerEmployer?.supervisor,
    employmentYears: saleData.buyerEmployer?.years,
    employmentMonths: saleData.buyerEmployer?.months,
    employmentType: saleData.buyerEmployer?.employmentType,
    payPeriod: saleData.buyerEmployer?.payFrequency,
    takeHomePay: saleData.buyerEmployer?.salary,
    nextPayDay: formatDate(saleData.buyerEmployer?.nextPayDay, { pattern: DateFormat.DateInput }),
    paidOn: saleData.buyerEmployer?.paidOn,
    hoursOrShiftWorked: saleData.buyerEmployer?.hours,
    employerPhoneNumber: formatPhoneNumber(saleData.buyerEmployer?.phoneNumber) ?? '',
    employerAddress: saleData.buyerEmployer?.address,
    employerCity: saleData.buyerEmployer?.city,
    employerState: saleData.buyerEmployer?.state,
    employerZip: saleData.buyerEmployer?.zip ?? '',
    otherIncomeAmount: saleData.buyerEmployer?.otherIncomeAmount,
    otherIncomeSchedule: saleData.buyerEmployer?.otherIncomeSchedule,
    otherIncomeSource: saleData.buyerEmployer?.otherIncomeSource,
    currentOrPrevious: saleData.buyer?.b_CURPREV,
    lotLocation: saleData.buyer?.b_LOTLOC,
    numberOfPurchases: saleData.buyer?.b_NUMPUR,
    mailingAddress: saleData.buyer?.b_MADDR,
    mailingCity: saleData.buyer?.b_MCITY,
    mailingState: saleData.buyer?.b_MSTATE,
    mailingZip: saleData.buyer?.b_MZIP,
    appRecId: saleData.buyer?.appRecID,
  };

  // @todo use class constructor function w/ validation
  const coBuyerValues = {
    firstName: saleData.coBuyer?.b_FIRST,
    middleName: saleData.coBuyer?.b_MID,
    lastName: saleData.coBuyer?.b_LAST,
    nickname: saleData.coBuyer?.b_NICK,
    socialSecurityNumber: formatSSN(saleData.coBuyer?.b_SSN) ?? '',
    dob: formatDate(saleData.coBuyer?.b_DOB, { pattern: DateFormat.DateInput }),
    numberOfDependents: saleData.coBuyer?.b_NUMDEP,
    driversLicenseNumber: saleData.coBuyer?.b_DL,
    expirationDate: formatDate(saleData.coBuyer?.b_DLEXP, { pattern: DateFormat.DateInput }),
    state: saleData.coBuyer?.b_DLSTATE,
    zip: saleData.coBuyer?.dlZip ?? '',
    cellPhoneNumber: formatPhoneNumber(saleData.coBuyer?.b_CPHONE) ?? '',
    homePhoneNumber: formatPhoneNumber(saleData.coBuyer?.b_HPHONE) ?? '',
    otherPhoneNumber: formatPhoneNumber(saleData.coBuyer?.b_OPHONE) ?? '',
    email: saleData.coBuyer?.b_EMAIL,
    address: saleData.coBuyer?.b_ADDR,
    aptOrSuite: saleData.coBuyer?.b_addr2,
    city: saleData.coBuyer?.b_CITY,
    addressState: saleData.coBuyer?.b_STATE,
    addressZip: saleData.coBuyer?.b_ZIP ?? '',
    county: saleData.coBuyer?.b_COUNTY,
    rentOrOwn: saleData.coBuyer?.b_RENTOWN,
    rentOrOwnYears: saleData.coBuyer?.b_RYEARS,
    rentOrOwnMonths: saleData.coBuyer?.b_RMONTHS,
    typeOfResidence: saleData.coBuyer?.b_HOMETYPE,
    landlordsName: saleData.coBuyer?.b_LANDMORG,
    monthlyPayment: saleData.sale?.billRent1,
    mortgageBalance: saleData.coBuyer?.b_MORGBAL,
    currentOrPastDue: saleData.coBuyer?.b_CURPAST,
    landlordsPhoneNumber: formatPhoneNumber(saleData.coBuyer?.b_LLPHONE) ?? '',
    signatureVerifiedBy: saleData.coBuyer?.b_VERSIGNBY,
    creditDateSigned: formatDate(saleData.coBuyer?.b_SIGNED, { pattern: DateFormat.DateInput }),
    totalMonthlyNetIncome: saleData.sale?.netPay1,
    primaryEmployer: saleData.coBuyerEmployer?.employer,
    occupation: saleData.coBuyerEmployer?.jobTitle,
    supervisor: saleData.coBuyerEmployer?.supervisor,
    employmentYears: saleData.coBuyerEmployer?.years,
    employmentMonths: saleData.coBuyerEmployer?.months,
    employmentType: saleData.coBuyerEmployer?.employmentType,
    payPeriod: saleData.coBuyerEmployer?.payFrequency,
    takeHomePay: saleData.coBuyerEmployer?.salary,
    nextPayDay: formatDate(saleData.coBuyerEmployer?.nextPayDay, { pattern: DateFormat.DateInput }),
    paidOn: saleData.coBuyerEmployer?.paidOn,
    hoursOrShiftWorked: saleData.coBuyerEmployer?.hours,
    employerPhoneNumber: formatPhoneNumber(saleData.coBuyerEmployer?.phoneNumber) ?? '',
    employerAddress: saleData.coBuyerEmployer?.address,
    employerCity: saleData.coBuyerEmployer?.city,
    employerState: saleData.coBuyerEmployer?.state,
    employerZip: saleData.coBuyerEmployer?.zip ?? '',
    otherIncomeAmount: saleData.coBuyerEmployer?.otherIncomeAmount,
    otherIncomeSchedule: saleData.coBuyerEmployer?.otherIncomeSchedule,
    otherIncomeSource: saleData.coBuyerEmployer?.otherIncomeSource,
    currentOrPrevious: saleData.coBuyer?.b_CURPREV,
    lotLocation: saleData.coBuyer?.b_LOTLOC,
    numberOfPurchases: saleData.coBuyer?.b_NUMPUR,
    mailingAddress: saleData.coBuyer?.b_MADDR,
    mailingCity: saleData.coBuyer?.b_MCITY,
    mailingState: saleData.coBuyer?.b_MSTATE,
    mailingZip: saleData.coBuyer?.b_MZIP,
  };

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(
    buyerOrCobuyer === 'Buyer'
      ? {
          defaultValues: buyerValues,
        }
      : {
          defaultValues: coBuyerValues,
        }
  );

  const addEmploymentHistoryForm = useForm({
    defaultValues: {
      employer: '',
      employmentType: EmploymentType.FullTime,
      jobTitle: '',
      years: 0,
      months: 0,
      salary: undefined as unknown as number,
      payFrequency: PaymentInterval.enum.Weekly as PaymentInterval,
      address: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
      superVisor: '',
      nextPayDay: '',
      paidOn: '',
      hours: '',
      currentPrevious: CurrentPrevious.Previous,
      buyerOrCobuyer: buyerOrCobuyer,
    },
  });

  const addResidenceHistoryForm = useForm({
    defaultValues: {
      address: '',
      city: '',
      state: '',
      zip: '',
      county: '',
      years: 0,
      months: 0,
      rentOwn: '',
      homeType: '',
      rentMortgage: 0,
      mortgageBalance: 0,
      currentPast: '',
    },
  });

  // @todo move component to file
  const lengthOfEmploymentColumn: FC = (props: any) => {
    const value = props.dataItem;
    return (
      <td>
        {value.years} Years {value.months} months
      </td>
    );
  };

  // @todo move component to file
  const viewEmploymentColumn: FC = (props: any) => {
    const value: Employer = props.dataItem;
    return (
      <td style={tdStyles}>
        <Button
          buttonStyle={{ width: '141px' }}
          label="View"
          onClick={() => {
            addEmploymentHistoryForm.reset({
              employer: value.employer ?? '',
              employmentType: value.employmentType !== null ? value.employmentType : undefined,
              jobTitle: value.jobTitle ?? '',
              years: value.years ?? undefined,
              months: value.months ?? undefined,
              salary: value.salary ?? undefined,
              payFrequency: value.payFrequency !== null ? value.payFrequency : undefined,
              address: value.address ?? '',
              city: value.city ?? '',
              state: value.state ?? '',
              zip: value.zip ?? '',
              phoneNumber: value.phoneNumber ?? '',
              superVisor: value.supervisor ?? '',
              nextPayDay: dayjs(value.nextPayDay).utc().format('YYYY-MM-DD'),
              paidOn: value.paidOn ?? '',
              hours: value.hours ?? '',
              currentPrevious: value.currentPrevious ?? undefined,
              buyerOrCobuyer: value.buyerOrCoBuyer !== null ? value.buyerOrCoBuyer : undefined,
            });
            setActiveEmploymentHistoryData(value);
            setActiveEmploymentHistoryAddress(
              formatAddress(value.address, value.city, value.state, value.zip)
            );
            setEditEmploymentHistoryModalOpen(true);
          }}
        />
      </td>
    );
  };

  // @todo move component to file
  const residenceAddressColumn: FC = (props: any) => {
    const value: PreviousAddress = props.dataItem;
    return (
      <td>
        {value.address}
        {value.city ? `, ${value.city}` : ''}
        {value.state ? `, ${value.state}` : ''} {value.zip}
      </td>
    );
  };

  // @todo move component to file
  const viewResidenceColumn: FC = (props: any) => {
    const value: PreviousAddress = props.dataItem;
    return (
      <td style={tdStyles}>
        <Button
          buttonStyle={{ width: '141px' }}
          label="View"
          onClick={() => {
            addResidenceHistoryForm.reset({
              address: value.address,
              city: value.city,
              state: value.state,
              zip: value.zip,
              county: value.county,
              years: value.years,
              months: value.months,
              rentOwn: value.rentOwn,
              homeType: value.homeType,
              rentMortgage: value.rentMortgage,
              mortgageBalance: value.mortgageBalance,
              currentPast: value.currentPast,
            });
            setActiveResidenceData(value);
            setActiveResidenceHistoryAddress(
              formatAddress(value.address, value.city, value.state, value.zip)
            );
            setEditPrevResidenceModalOpen(true);
          }}
        />
      </td>
    );
  };

  // @todo move object/array/function out of component body
  const prevEmployerColumns: Column[] = [
    {
      field: 'employer',
      title: 'Employer',
    },
    {
      field: 'currentPrevious',
      title: 'Current/Previous',
    },
    {
      field: 'years',
      title: 'Length of Employment',
      cells: { data: lengthOfEmploymentColumn },
    },
    {
      field: 'viewEmploymentButton',
      title: ' ',
      cells: { data: viewEmploymentColumn },
    },
  ];

  // @todo move object/array/function out of component body
  const prevResidenceColumns: Column[] = [
    {
      field: 'address',
      title: 'Address',
      cells: { data: residenceAddressColumn },
    },
    {
      field: 'years',
      title: 'Length of Residence',
      cells: { data: lengthOfEmploymentColumn },
    },
    {
      field: 'viewResidenceHistory',
      title: ' ',
      cells: { data: viewResidenceColumn },
    },
  ];

  type FormData = typeof buyerValues;

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('addressState', fullAddress.state);
    setValue('addressZip', fullAddress.zip);
    setValue('county', fullAddress.county);
  };

  const handleMailingPlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('mailingAddress', fullAddress.address);
    setValue('mailingCity', fullAddress.city);
    setValue('mailingState', fullAddress.state);
    setValue('mailingZip', fullAddress.zip);
  };

  const handleEmployerPlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('employerAddress', fullAddress.address);
    setValue('employerCity', fullAddress.city);
    setValue('employerState', fullAddress.state);
    setValue('employerZip', fullAddress.zip);
  };

  const handleResidenceHistorySelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    const setValue = addResidenceHistoryForm.setValue;

    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('state', fullAddress.state);
    setValue('zip', fullAddress.zip);
    setValue('county', fullAddress.county);
  };

  const handleEmploymentHistorySelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    const setValue = addEmploymentHistoryForm.setValue;

    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('state', fullAddress.state);
    setValue('zip', fullAddress.zip);
  };

  const handleApplicationUpdate = async (data: FormData, isCobuyer: boolean, recId: number) => {
    setUpdateBuyerApplicationLoading(true);
    try {
      const newSaleData = await salesService.updateBuyerApplication({
        appRecId: appRecId,
        compId: compId,
        locId: locId,
        orgId: orgId,
        buyerRecId: recId,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        nickName: data.nickname,
        socialSecurityNumber: data.socialSecurityNumber,
        dateOfBirth: data.dob && data.dob !== 'Invalid Date' ? data.dob : null,
        numberOfDependents: data.numberOfDependents,
        driversLicenseNumber: data.driversLicenseNumber,
        driversLicenseExpirationDate: data.expirationDate && data.expirationDate !== 'Invalid Date' ? data.expirationDate : null,
        driversLicenseState: data.state,
        driversLicenseZip: data.addressZip, // Updated from `data.zip` to `data.addressZip`
        cellPhoneNumber: data.cellPhoneNumber,
        homePhoneNumber: data.homePhoneNumber,
        otherPhoneNumber: data.otherPhoneNumber,
        email: data.email,
        address: data.address,
        address2: data.aptOrSuite,
        city: data.city,
        state: data.addressState,
        zip: data.addressZip,
        county: data.county,
        rentOrOwn: data.rentOrOwn,
        rentOrOwnYears: data.rentOrOwnYears,
        rentOrOwnMonths: data.rentOrOwnMonths,
        typeOfResidence: data.typeOfResidence,
        landlordMortgageHolderName: data.landlordsName,
        homeMonthlyPayment: data.monthlyPayment,
        mortgageBalance: data.mortgageBalance,
        currentOrPastDue: data.currentOrPastDue,
        landlordMortgageHolderPhoneNumber: data.landlordsPhoneNumber,
        creditSignatureVerifiedBy: data.signatureVerifiedBy,
        creditDateSigned: data.creditDateSigned && data.creditDateSigned !== 'Invalid Date' ? data.creditDateSigned : null,
        totalMonthlyNetIncomeBuyer: isCobuyer ? undefined : data.totalMonthlyNetIncome,
        totalMonthlyNetIncomeCoBuyer: isCobuyer ? data.totalMonthlyNetIncome : undefined,
        primaryEmployer: data.primaryEmployer,
        occupation: data.occupation,
        supervisor: data.supervisor,
        employmentYears: data.employmentYears,
        employmentMonths: data.employmentMonths,
        employmentType: data.employmentType,
        payPeriod: data.payPeriod,
        salary: data.takeHomePay,
        nextPayDay: data.nextPayDay && data.nextPayDay !== 'Invalid Date' ? data.nextPayDay : null,
        paidOn: data.paidOn,
        hoursOrShiftWorked: data.hoursOrShiftWorked,
        employerPhoneNumber: data.employerPhoneNumber,
        employerAddress: data.employerAddress,
        employerCity: data.employerCity,
        employerState: data.employerState,
        employerZip: data.employerZip,
        otherIncomeAmount: data.otherIncomeAmount,
        otherIncomeSchedule: data.otherIncomeSchedule,
        otherIncomeSource: data.otherIncomeSource,
        isCobuyer,
        lotLocation: data.lotLocation,
        currentPrevious: data.currentOrPrevious,
        numberOfPurchases: data.numberOfPurchases,
        mailingAddress: showMailingAddress ? data.mailingAddress : null,
        mailingCity: showMailingAddress ? data.mailingCity : null,
        mailingState: showMailingAddress ? data.mailingState : null,
        mailingZip: showMailingAddress ? data.mailingZip : null,
      });
  
      dispatch(salesActions.setSaleData(newSaleData));
      toast.success(`${isCobuyer ? 'Co-Buyer' : 'Buyer'} Application Updated`);
    } catch (error) {
      console.error('Error updating application:', error); // Log error for debugging
      toast.error('There was an error updating the application');
    } finally {
      setUpdateBuyerApplicationLoading(false);
    }
  };
  
  const handleBuyerApplicationUpdate = (data: FormData) => {
    handleApplicationUpdate(data, false, Number(buyerRecId));
  };
  
  const handleCoBuyerApplicationUpdate = (data: FormData) => {
    handleApplicationUpdate(data, true, Number(coBuyerRecId));
  };
  


  const handleCloseEditEmploymentHistoryModal = () => {
    addEmploymentHistoryForm.reset();
    setActiveEmploymentHistoryData(undefined);
    setActiveEmploymentHistoryAddress(undefined);
    setEditEmploymentHistoryModalOpen(false);
  };

  const handleCloseEditResidenceHistoryModal = () => {
    addResidenceHistoryForm.reset();
    setActiveResidenceData(undefined);
    setActiveResidenceHistoryAddress(undefined);
    setEditPrevResidenceModalOpen(false);
  };

  // @todo move interface out of component body
  type AddEmploymentFormProps = {
    employer: string;
    employmentType: EmploymentType;
    jobTitle: string;
    years: number;
    months: number;
    salary: number;
    payFrequency: PaymentInterval;
    address: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    superVisor: string;
    nextPayDay: string;
    paidOn: string;
    hours: string;
    currentPrevious: CurrentPrevious;
    buyerOrCobuyer: string;
  };

  const handleAddEmploymentHistory = (data: AddEmploymentFormProps) => {
    setAddEmploymentHistoryLoading(true);
    // @todo use async/await
    accountsService
      .createHistoryEmployer({
        appRecId: appRecId,
        buyerRecId: buyerOrCobuyer === 'Buyer' ? buyerRecId! : coBuyerRecId!,
        employer: data.employer,
        employmentType: data.employmentType,
        jobTitle: data.jobTitle,
        years: data.years,
        months: data.months,
        salary: data.salary,
        payFrequency: data.payFrequency,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        doNotCall: false,
        doNotEmail: false,
        doNotText: false,
        supervisor: data.superVisor,
        nextPayDay: data.nextPayDay && data.nextPayDay !== 'Invalid Date' ? data.nextPayDay : null,
        paidOn: data.paidOn,
        hours: data.hours,
        fax: '',
        extension: '',
        currentPrevious: data.currentPrevious,
        buyerOrCoBuyer: buyerOrCobuyer,
      })
      .then(() => {
        getPrevEmployersList();
        addEmploymentHistoryForm.reset();
        setActiveEmploymentHistoryData(undefined);
        setEditEmploymentHistoryModalOpen(false);
      })
      .finally(() => setAddEmploymentHistoryLoading(false));
  };

  const handleUpdateEmploymentHistory = (data: AddEmploymentFormProps) => {
    setAddEmploymentHistoryLoading(true);
    // @todo use async/await
    accountsService
      .updateHistoryEmployer({
        recId: Number(activeEmploymentHistoryData?.recId),
        employer: data.employer,
        employmentType: data.employmentType,
        jobTitle: data.jobTitle,
        years: data.years,
        months: data.months,
        salary: data.salary,
        payFrequency: data.payFrequency,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        doNotCall: false,
        doNotEmail: false,
        doNotText: false,
        supervisor: data.superVisor,
        nextPayDay: data.nextPayDay && data.nextPayDay !== 'Invalid Date' ? data.nextPayDay : null,
        paidOn: data.paidOn,
        hours: data.hours,
        fax: '',
        extension: '',
        currentPrevious: data.currentPrevious,
        buyerOrCoBuyer: buyerOrCobuyer,
      })
      .then(() => {
        getPrevEmployersList();
        addEmploymentHistoryForm.reset();
        setActiveEmploymentHistoryData(undefined);
        setEditEmploymentHistoryModalOpen(false);
      })
      .finally(() => setAddEmploymentHistoryLoading(false));
  };

  const handleDeleteEmploymentHistory = () => {
    setDeleteEmploymentHistoryLoading(true);
    // @todo use async/await
    accountsService
      .deleteHistoryEmployer(activeEmploymentHistoryData!.recId!)
      .then(() => {
        getPrevEmployersList();
        addEmploymentHistoryForm.reset();
        setActiveEmploymentHistoryData(undefined);
        setEditEmploymentHistoryModalOpen(false);
        toast.success('Employer Deleted');
      })
      .catch(() => toast.error('Unable to delete employer'))
      .finally(() => setDeleteEmploymentHistoryLoading(false));
  };

  // @todo move interface out of component body
  type AddResidenceHistoryFormProps = {
    address: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    years: number;
    months: number;
    rentOwn: string;
    homeType: string;
    rentMortgage: number;
    mortgageBalance: number;
    currentPast: string;
  };

  const handleAddResidenceHistory = (data: AddResidenceHistoryFormProps) => {
    setAddResidenceHistoryLoading(true);
    // @todo use async/await
    salesService
      .createResidenceHistory({
        orgId: orgId,
        locId: locId,
        compId: compId,
        appRecId: appRecId,
        buyerRecId: buyerOrCobuyer === 'Buyer' ? buyerRecId : coBuyerRecId,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        county: data.county,
        years: data.years,
        months: data.months,
        rentOwn: data.rentOwn,
        homeType: data.homeType,
        rentMortgage: data.rentMortgage,
        mortgageBalance: data.mortgageBalance,
        currentPast: data.currentPast,
      })
      .then(() => {
        getPrevResidenceList();
        setActiveResidenceData(undefined);
        addResidenceHistoryForm.reset();
        setEditPrevResidenceModalOpen(false);
        toast.success('Residence Added');
      })
      .catch(() => toast.error('Unable to add residence'))
      .finally(() => setAddResidenceHistoryLoading(false));
  };

  const handleUpdateResidenceHistory = (data: AddResidenceHistoryFormProps) => {
    setAddResidenceHistoryLoading(true);
    // @todo use async/await
    salesService
      .updateResidenceHistory({
        recId: activeResidenceData?.recId,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        county: data.county,
        years: data.years,
        months: data.months,
        rentOwn: data.rentOwn,
        homeType: data.homeType,
        rentMortgage: data.rentMortgage,
        mortgageBalance: data.mortgageBalance,
        currentPast: data.currentPast,
      })
      .then(() => {
        getPrevResidenceList();
        setActiveResidenceData(undefined);
        addResidenceHistoryForm.reset();
        setEditPrevResidenceModalOpen(false);
        toast.success('Residence Updated');
      })
      .catch(() => toast.error('Unable to update residence'))
      .finally(() => setAddResidenceHistoryLoading(false));
  };

  const handleDeleteResidenceHistory = () => {
    setDeleteResidenceHistoryLoading(true);
    // @todo use async/await
    salesService
      .deleteResidenceHistory(activeResidenceData!.recId!)
      .then(() => {
        getPrevResidenceList();
        setActiveResidenceData(undefined);
        addResidenceHistoryForm.reset();
        setEditPrevResidenceModalOpen(false);
        toast.success('Residence Deleted');
      })
      .catch(() => toast.error('Unable to delete residence'))
      .finally(() => setDeleteResidenceHistoryLoading(false));
  };

  const handleAddOrRemoveCobuyer = (hasCobuyer: boolean) => {
    setUpdateHasCobuyerLoading(true);
    // @todo use async/await
    salesService
      .updateHasCobuyer({
        compId: compId!,
        locId: locId!,
        orgId: orgId!,
        cobuyerRecId: coBuyerRecId!,
        appRecId: appRecId,
        hasCobuyer: hasCobuyer,
      })
      .then((res) => dispatch(salesActions.setSaleData(res)))
      .catch(() => toast.error('There was an error adding a Co-buyer'))
      .finally(() => setUpdateHasCobuyerLoading(false));
  };

  const getPrevEmployersList = async () => {
    setPrevEmployersLoading(true);
    // @todo use async/await
    await salesService
      .getEmployerHistoryByBuyerRecId(buyerOrCobuyer === 'Buyer' ? buyerRecId! : coBuyerRecId!)
      .then((res) => setPrevEmployerData(res))
      .catch(() => toast.error('Unable to retrieve previous employers'))
      .finally(() => setPrevEmployersLoading(false));
  };

  const getPrevResidenceList = async () => {
    setPrevResidenceLoading(true);
    // @todo use async/await
    await salesService
      .getResidenceHistory(buyerOrCobuyer === 'Buyer' ? buyerRecId! : coBuyerRecId!)
      .then((res) => setPrevResidenceData(res))
      .catch(() => toast.error('Unable to retrieve previous residence'))
      .finally(() => setPrevResidenceLoading(false));
  };

  // @todo deps arr must use stable ref
  useEffect(() => {
    dispatch(salesActions.setHasCobuyer(!!saleData.sale?.isCobuyer));
    buyerOrCobuyer === 'Buyer' ? reset(buyerValues) : reset(coBuyerValues);
    getPrevEmployersList();
    getPrevResidenceList();
  }, [saleData]);

  return {
    control,
    typeOfResidenceData,
    watch,
    hasCobuyer,
    prevEmployerColumns,
    updateBuyerApplicationLoading,
    handleBuyerApplicationUpdate,
    handleCoBuyerApplicationUpdate,
    handleAddOrRemoveCobuyer,
    handleCloseEditEmploymentHistoryModal,
    updateHasCobuyerLoading,
    handleSubmit,
    editEmploymentHistoryModalOpen,
    handleAddEmploymentHistory,
    setActiveEmploymentHistoryData,
    setEditEmploymentHistoryModalOpen,
    addEmploymentHistoryForm,
    activeEmploymentHistoryData,
    addEmploymentHistoryLoading,
    deleteEmploymentHistoryLoading,
    handleDeleteEmploymentHistory,
    prevEmployerData,
    prevEmployersLoading,
    handleUpdateEmploymentHistory,
    prevResidenceColumns,
    prevResidenceData,
    prevResidenceLoading,
    activeResidenceData,
    setActiveResidenceData,
    editPrevResidenceModalOpen,
    setEditPrevResidenceModalOpen,
    addResidenceHistoryForm,
    handleCloseEditResidenceHistoryModal,
    handleAddResidenceHistory,
    handleUpdateResidenceHistory,
    handleDeleteResidenceHistory,
    addResidenceHistoryLoading,
    deleteResidenceHistoryLoading,
    showMailingAddress,
    setShowMailingAddress,
    errors,
    handlePlaceSelected,
    handleEmployerPlaceSelected,
    handleMailingPlaceSelected,
    handleResidenceHistorySelected,
    handleEmploymentHistorySelected,
    fullAddress,
    fullEmployerAddress,
    fullMailingAddress,
    activeResidenceHistoryAddress,
    activeEmploymentHistoryAddress,
    isDisabled,
  };
};
