import { FC, ReactNode, createContext, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
// kendo
import { SortDescriptor } from '@progress/kendo-data-query';
// utils
import { columnPool } from './Tables/tableColumns';
// interfaces
import { PageState } from '@/components/table/TableInterface';
import { InventoryListRow } from '@/interfaces/Inventory';
import { IFormErrors, IModalFilterParams } from '../inventory/Tables/interfaces';

export const inventoryTypeKeys = [
  'unsold',
  'sold',
  'iop',
  'all',
  'alllotsretail',
  'notReceived',
  'titlelogs',
  'disposal',
] as const;
/** Inventory type lookup keys */
export type InventorySubView = (typeof inventoryTypeKeys)[number];

type ICtx = IInventoryContext;
export interface IInventoryContext {
  subview: InventorySubView;
  // Table variables
  searchFilter: string;
  setSearchFilter: (searchFilterValue: string) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  listLength: number;
  setListLength: (listLength: number) => void;
  tableDataMap: TableDataMap;
  setTableDataMap: (tableDataMap: TableDataMap) => void;
  tableFilters: TableFilters;
  setTableFilters: (tableFilters: TableFilters) => void;
  sort: SortDescriptor[] | null;
  setSort: (sort: SortDescriptor[] | null) => void;
  page: PageState;
  setPage: (page: PageState) => void;
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: number) => void;
  filterText: string;
  setFilterText: (filterText: string) => void;
  modalFilterParams: IModalFilterParams | null;
  setModalFilterParams: (modalFilterParams: IModalFilterParams | null) => void;
  formErrors: IFormErrors;
  setFormErrors: (field: keyof IFormErrors, newValue: boolean) => void;
  mappedCompaniesCt: number;
  setMappedCompaniesCt: (mappedCompaniesCt: number) => void;
}

export type TableFilters = { [key in keyof InventoryListRow]: string[] };

// @ts-ignore
const initTableFilters: TableFilters = {};
columnPool.forEach((c) => {
  initTableFilters[c.field] = [];
});

export const Ctx = createContext<ICtx | null>(null);
export type TableDataMap = Record<InventorySubView, InventoryListRow[]>;

const InventoryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const routeParams = useParams();
  const [searchFilter, setSearchFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listLength, setListLength] = useState(0);
  const [mappedCompaniesCt, setMappedCompaniesCt] = useState(0);
  const [tableDataMap, setTableDataMap] = useState<TableDataMap>({
    unsold: [],
    sold: [],
    iop: [],
    all: [],
    alllotsretail: [],
    notReceived: [],
    titlelogs: [],
    disposal: [],
  });
  const [tableFilters, setTableFilters] = useState<TableFilters>(initTableFilters);
  const [sort, setSort] = useState<SortDescriptor[] | null>([{ field: 'ProductName', dir: 'asc' }]);
  const [page, setPage] = useState<PageState>({
    skip: 0,
    take: 25,
  });
  const [pageSizeValue, setPageSizeValue] = useState<number>(25);
  const [filterText, setFilterText] = useState('');
  const [modalFilterParams, setModalFilterParams] = useState<IModalFilterParams | null>(null);
  const [formErrors, setFormErrorsInit] = useState<IFormErrors>({
    date: false,
    int: false,
    amt: false,
  });

  const setFormErrors = (field: keyof IFormErrors, newValue: boolean) => {
    const updatedFormErrors = { ...formErrors, [field]: newValue };
    setFormErrorsInit(updatedFormErrors);
  };

  return (
    <Ctx.Provider
      value={{
        subview: routeParams['*'] as InventorySubView,
        // Table variables
        searchFilter,
        setSearchFilter,
        isLoading,
        setIsLoading,
        isModalOpen,
        setIsModalOpen,
        isSubmitting,
        setIsSubmitting,
        listLength,
        setListLength,
        tableDataMap,
        setTableDataMap,
        tableFilters,
        setTableFilters,
        sort,
        setSort,
        page,
        setPage,
        pageSizeValue,
        setPageSizeValue,
        filterText,
        setFilterText,
        modalFilterParams,
        setModalFilterParams,
        formErrors,
        setFormErrors,
        mappedCompaniesCt,
        setMappedCompaniesCt,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export default InventoryProvider;

export const useInventoryViewCtx = <T,>(selector: (state: ICtx) => T): T => {
  const ctx = useContext(Ctx);
  if (!ctx) {
    throw new Error('useInventoryViewCtx must be used within InventoryViewProvider');
  }
  return selector(ctx);
};
