import React from 'react';
import styles from './Button.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  buttonLabel?: string;
  buttonStyles?: React.CSSProperties;
  fontSize?: string;
}

export const Button: React.FC<Props> = (props) => {
  const { buttonLabel, buttonStyles = {}, ...restProps } = props;
  return (
    <button className={styles.button} style={buttonStyles} {...restProps}>
      {buttonLabel}
    </button>
  );
};
