import { SalesScoring, SalesScoringAnswers } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { useAppDispatch } from '@/store/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { salesActions, useSalesSelector } from '../../salesSlice';

export interface SalesScoringAnswersSelected extends SalesScoringAnswers {
  selected: boolean;
}

export interface SalesScoringSelected extends SalesScoring {
  answers: SalesScoringAnswersSelected[];
  canBeSelected: boolean;
}

export enum UnderwritingGrades {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
}

export interface GradeRanges {
  grade: UnderwritingGrades;
  minScore: number;
  maxScore: number;
}

export const useUnderwritingScore = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const { underwritingScore, saleData } = useSalesSelector((s) => s);
  const [maxScore, setMaxScore] = useState(0);
  const [getScoringQuestionsLoading, setGetScoringQuestionsLoading] = useState(false);
  const [switchOn, setSwitchOn] = useState(!!saleData.sale?.manualUnderwritingSelection);
  const [gradeRanges, setGradeRanges] = useState<GradeRanges[]>([]);

  const isDisabled = useMemo(
    () => saleData.sale?.salestatus?.toLowerCase() !== 'pending',
    [saleData]
  );

  const getScoringQuestions = useCallback(
    async (manualUnderwritingSelection: boolean) => {
      setGetScoringQuestionsLoading(true);
      try {
        if (!manualUnderwritingSelection) {
          await salesService.dynamicScoringUpdate({ appRecId: appRecId });
        }
        const scoringQuestionsResponse = await salesService.getScoringQuestions();
        const applicationScoring = await salesService.getApplicationScoring(appRecId);
        const questionIdCanDynamicallyUpdate = scoringQuestionsResponse
          .filter((question) => question.canDynamicallyUpdate)
          .map((question) => question.id);

        const initialUnderwritingScore = scoringQuestionsResponse?.map((question) => {
          return {
            ...question,
            canBeSelected:
              !isDisabled &&
              (!!manualUnderwritingSelection ||
                !questionIdCanDynamicallyUpdate.includes(question.id)),
            answers: question.answers.map((answerValue) => {
              const selected = !!applicationScoring.find(
                (applicationScore) =>
                  applicationScore.answerId === answerValue.id &&
                  applicationScore.questionId === question.id
              );

              return {
                ...answerValue,
                selected: selected,
              };
            }),
          };
        });

        dispatch(salesActions.setUnderwritingScore(initialUnderwritingScore));
        let max = 0;
        scoringQuestionsResponse?.forEach((value) => {
          value.answers.forEach((answer) => {
            if (answer.level === 5) max += answer.score;
          });
        });
        setMaxScore(max);

        const gradeRange: GradeRanges[] = [
          { grade: UnderwritingGrades.A, minScore: Math.floor(0.8 * max), maxScore: 1 * max },
          {
            grade: UnderwritingGrades.B,
            minScore: Math.floor(0.6 * max),
            maxScore: Math.floor(0.8 * max) - 1,
          },
          {
            grade: UnderwritingGrades.C,
            minScore: Math.floor(0.4 * max),
            maxScore: Math.floor(0.6 * max) - 1,
          },
          {
            grade: UnderwritingGrades.D,
            minScore: Math.floor(0.2 * max),
            maxScore: Math.floor(0.4 * max) - 1,
          },
          {
            grade: UnderwritingGrades.F,
            minScore: Math.floor(0 * max),
            maxScore: Math.floor(0.2 * max) - 1,
          },
        ];
        setGradeRanges(gradeRange);
      } finally {
        setGetScoringQuestionsLoading(false);
      }
    },
    [saleData]
  );

  const toggleManualEntryOn = async () => {
    try {
      setSwitchOn(true);
      await salesService.updateManualUnderwritingSelection({
        appRecId: appRecId,
        manualUnderwritingSelection: true,
      });
      const newUnderwritingScore = underwritingScore.map((question) => {
        return { ...question, canBeSelected: true };
      });

      dispatch(
        salesActions.setSaleData({
          ...saleData,
          sale: { ...saleData.sale, manualUnderwritingSelection: true },
        })
      );
      dispatch(salesActions.setUnderwritingScore(newUnderwritingScore));
    } catch {
      toast.error('Unable to toggle manual selection');
      setSwitchOn(false);
    }
  };

  const toggleManualEntryOff = async () => {
    try {
      setSwitchOn(false);
      await salesService.updateManualUnderwritingSelection({
        appRecId: appRecId,
        manualUnderwritingSelection: false,
      });

      dispatch(
        salesActions.setSaleData({
          ...saleData,
          sale: { ...saleData.sale, manualUnderwritingSelection: false },
        })
      );

      await getScoringQuestions(false);
    } catch {
      toast.error('Unable to toggle manual selection');
      setSwitchOn(true);
    }
  };

  const totalScore = useMemo(() => {
    let score = 0;
    underwritingScore?.forEach((value) => {
      value.answers.forEach((answer) => {
        if (answer.selected) score += answer.score;
      });
    });
    return score;
  }, [underwritingScore]);

  const grade = useMemo(() => {
    const scorePercentage = totalScore / maxScore;
    let grade;

    switch (true) {
      case scorePercentage < 0.2:
        grade = UnderwritingGrades.F;
        break;
      case scorePercentage < 0.4:
        grade = UnderwritingGrades.D;
        break;
      case scorePercentage < 0.6:
        grade = UnderwritingGrades.C;
        break;
      case scorePercentage < 0.8:
        grade = UnderwritingGrades.B;
        break;
      default:
        grade = UnderwritingGrades.A;
        break;
    }

    return grade;
  }, [totalScore, maxScore]);

  useEffect(() => {
    getScoringQuestions(!!saleData.sale?.manualUnderwritingSelection);
  }, [saleData]);

  return {
    underwritingScore,
    totalScore,
    maxScore,
    getScoringQuestionsLoading,
    toggleManualEntryOff,
    toggleManualEntryOn,
    switchOn,
    grade,
    gradeRanges,
  };
};
