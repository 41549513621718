import { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { debounce } from 'lodash-es';
// components
import Header from './Header';
import ExportFormModal from './ExportFormModal';
import EmailReportSchedulesTable from './EmailReportSchedulesTable';
import ERSDetailView from './ERSDetailView';
import ReportsList from './ReportsList';
// utils
import { useReportsCtx, ReportsProvider, IReportsContext } from './ReportsList/defaultState';
import { useReportsViewCtx, ReportsViewProvider, IReportsViewContext } from './state/view';
import { EmailReportScheduleProvider } from './ERSDetailView/state';
import { ITableContext, TableProvider, useTableCtx } from './state/table';
import { reportsService } from '@/services/reportsService';
import { ReportsSubviews } from './viewInfo';
// style
import styles from './Reports.module.scss';
import { IPaginatedReq, Sort } from '@/interfaces/requests';
import { SortKendo } from '@/interfaces/kendo';
import { ReportsGetPgReq } from './interfaces';

const TableSelector: FC = () => {
  const subview = useReportsViewCtx((s) => s.subview);

  // return subview === ReportsSubviews.ers ? <EmailReportSchedulesTable /> : <ReportsTable />;
  return subview === ReportsSubviews.ers ? <EmailReportSchedulesTable /> : <ReportsList />;
};

/** ### Reports view-component
 * @note sub-view switching should be handled by react router rather than state
 */
const Reports: FC = () => {
  const tableState = useTableCtx((s) => s);
  const reportsViewContext = useReportsViewCtx((s) => s);
  const reportsContext = useReportsCtx((s) => s);


  const fetchReports = async (
    tableState: ITableContext<string>,
    reportsViewContext: IReportsViewContext,
    reportsContext: IReportsContext
  ) => {
    const { categoryFilters, sort, page, pageSizeValue, queryPageNumber, textFilter} = tableState;
    const { setDbCt, setIsLoading } = reportsViewContext;
    const { setReports }  = reportsContext;

    setIsLoading(true);

    try {
      const payload: ReportsGetPgReq = {
        page: queryPageNumber, 
        perPage: page.take,
        search: textFilter,
        sorts: sort.map((s) => {
          return {
            field: s.field as SortKendo['field'],
            direction: s.dir as Sort['direction'],
          };
        }),
        filters: categoryFilters
      };

      const resReports = await reportsService.GetReportsSubviewList(payload);

      if (resReports.data.length === 0) {
        toast.info('No reports found');
        return;
      }

      const reportsWithMappedReportName = resReports.data.map((r) => ({
        ...r,
        reportNameOrg: `${r.reportNameOrg} -${r.recId}`,
      }));
      setReports(reportsWithMappedReportName);
      setDbCt(resReports.total);
    } catch (err) {
      console.error(err);
      toast.error(`Error fetching reports: ${err}`);
    }

    setIsLoading(false);
  };

  // Effects
  useEffect(() => {
    fetchReports(tableState, reportsViewContext, reportsContext);
  }, []);

  useEffect(() => {
    fetchReports(tableState, reportsViewContext, reportsContext);
  }, [
    tableState.categoryFilters.map((f) => `${f.field}-${f.operator}-${f.value}`).join(''),
    tableState.sort.map((s) => `${s.field}-${s.dir}`).join(''), 
    tableState.page.skip, 
    tableState.page.take, 
    tableState.pageSizeValue
  ]);

  // Debounce the request filtered by 'text search filter'
  const debouncedFetch = useCallback(
    debounce(
      (
        tableState : ITableContext<string>, 
        reportsViewContext: IReportsViewContext,
        reportsContext: IReportsContext
      ) => fetchReports(tableState, reportsViewContext, reportsContext),
      600
    ),
    []
  );
  useEffect(() => {
    debouncedFetch(tableState, reportsViewContext, reportsContext);
  }, [tableState.textFilter]);

  return (
    <div className={styles.container}>
      <Header />
      <TableSelector />
      <ExportFormModal />
      <ERSDetailView />
    </div>
  );
};

export default () => (
  <ReportsViewProvider>
    <TableProvider>
      <ReportsProvider>
        <EmailReportScheduleProvider>
          <Reports />
        </EmailReportScheduleProvider>
      </ReportsProvider>
    </TableProvider>
  </ReportsViewProvider>
);
