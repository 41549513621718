import { CSSProperties, FC, ReactNode } from 'react';
// kendo
import { Button } from '@/components/old/button/Button';
import { Icons } from '@/components/icons';
// interfaces
import { CardProcessorName } from '@/enums/payment';
// style
import styles from './DmsModal.module.scss';

export const DmsModal: FC<{
  title?: string;
  message?: string;
  isOpen: boolean;
  onAcceptLabel?: string;
  onDeclineLabel?: string;
  onAcceptFunction?: () => void;
  onDeclineFunction?: () => void;
  onAcceptStyles?: CSSProperties;
  onDeclineStyles?: CSSProperties;
  closeFunction: () => void;
  containerStyles?: CSSProperties;
  children?: ReactNode;
  creditCardPaymentProvider?: string;
}> = ({
  title,
  message,
  isOpen,
  onAcceptLabel,
  onDeclineLabel,
  onAcceptFunction,
  onDeclineFunction,
  onAcceptStyles,
  onDeclineStyles,
  closeFunction,
  containerStyles = {},
  children,
  creditCardPaymentProvider,
}) => {
  const backgroundStyle = isOpen ? styles.background : styles.hidden;

  return (
    <div className={backgroundStyle}>
      <div className={styles.dimmer} />
      <div
        className={
          children
            ? creditCardPaymentProvider === CardProcessorName.Repay
              ? styles.largeContainer
              : styles.openEdgeContainer
            : styles.container
        }
        style={containerStyles}
      >
        <div className={styles.header}>
          <div>{title}</div>
          <Icons.X onClick={closeFunction} style={{ cursor: 'pointer' }} />
        </div>
        <div className={styles.body}>
          {children ? (
            creditCardPaymentProvider === CardProcessorName.OpenEdge ? (
              <div className={styles.message}>{children}</div>
            ) : (
              children
            )
          ) : (
            <div className={styles.message}>{message}</div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          {onAcceptFunction && (
            <Button
              onClick={onAcceptFunction}
              buttonLabel={onAcceptLabel}
              style={{ ...onAcceptStyles, width: '48%', height: '27px', margin: '4px 0' }}
            />
          )}
          {onDeclineFunction && (
            <Button
              onClick={onDeclineFunction}
              buttonLabel={onDeclineLabel}
              style={{ ...onDeclineStyles, width: '48%', height: '27px', margin: '4px 0' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
