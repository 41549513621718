// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';
import GPSCell from '../tableCellComponents/GPSCell';
import AmtDueCell from '../tableCellComponents/AmtDueCell';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const outForRepoTableColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('gpsCode', 'GPS', 80, GPSCell),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('amtDue', 'Amount Due', 140, AmtDueCell),
  newColumnDef('daysLate', 'Days Late', 120, 'number'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('status', 'Status', 120),
  newColumnDef('code3', 'Lot', 80),
];