import { useEffect, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import styles from '@features/old/payment/Payment.module.scss';
import { paymentService, GetPaymentData, Employee, getDefaultPostPaymentPayload } from '@services/paymentService';
import { IPaymentProviderConfigRes } from '@/interfaces/payment';
import {
  Button,
  DmsDropDown,
  DmsRadioButtonGroup,
  EmailReceipt,
  DmsTextInput,
  DmsNumberInput,
  DmsModal,
  Repay,
  SavedCreditCard,
  SavedACH,
  OpenEdge,
} from '@components/old';
import { Spacer } from "@/components/spacer/Spacer";
import { Nullable } from '@utils/types';
import { SavedPmtMethodRes } from '@/interfaces/CreditCard';
import { CardProcessorName, PaymentType, PmtPaidIn } from '@/enums/payment';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getCanWaiveFeeDeprec,
  getConvenienceFee,
  getDdDueStatus,
  getIsNewPayment,
  getMpdToken,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  getSavePayment,
  handleCpiDueChange,
} from '@/utils/helpers/payment';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials, sanitizePhoneInput } from '@/utils/helpers/general';
import levelUpImage from '@assets/level_up.png';
import { useOldPaymentSelector } from '../oldPaymentSlice';
import { PostPaymentPayloadOld } from '../interfaces';

const navigateToList = () => window.PmtReturn?.panelSetActive('MASTER', true);

const afterPayment = () => {
  setTimeout(() => {
    window.PmtReturn?.runAction('RefreshData');
    window.PmtReturn?.refreshListData('Payments');
  }, 1000);
  navigateToList();
};

export const WholesalePayment = () => {
  const { colRecId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [accepted, setAccepted] = useState<PmtPaidIn>(PmtPaidIn.enum['In-Person']);
  const [paymentDetails, setPaymentDetails] = useState<GetPaymentData>();
  const [amountTendered, setAmountTendered] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [carPayment, setCarPayment] = useState(0);
  const [refreshing, setRefreshing] = useState(true);
  const [cpiDue, setCpiDue] = useState(0);
  const [ddDue, setDdDue] = useState(0);
  const [lateCharge, setLateCharge] = useState(0);
  const [lateChargeWaived, setLateChargeWaived] = useState(0);
  const [lateChargeOwed, setLateChargeOwed] = useState(0);
  const [nsfDue, setNsfDue] = useState(0);
  const [nsfOwed, setNsfOwed] = useState(0);
  const [paymentType, setPaymentType] = useState('Cash');
  const [changeDue, setChangeDue] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [employeeArray, setEmployeeArray] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [confirmPostModalOpen, setConfirmPostModalOpen] = useState(false);
  const [repayModal, setRepayModal] = useState<boolean>(false);
  const [iframe, setIframe] = useState<string>('');
  const [paymentPayload, setPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [formErrors, setFormErrors] = useState(false);
  const [buyerEmail, setBuyerEmail] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [includeBuyerEmail, setIncludeBuyerEmail] = useState<boolean>(false);
  const [includeCobuyerEmail, setIncludeCobuyerEmail] = useState<boolean>(false);
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState('');
  const [takenByPassword, setTakenByPassword] = useState<string>('');
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  const [processor, setProcessor] = useState<CardProcessorName | undefined>();
  const [processors, setProcessors] = useState<CardProcessorName[]>([]);
  const [providerData, setProviderData] = useState<IPaymentProviderConfigRes>({} as IPaymentProviderConfigRes);

  // All payment methods
  const [paymentMethods, setPaymentMethods] = useState<SavedPmtMethodRes[]>();

  // New Card
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [waiveFee, setWaiveFee] = useState(false);
  const [newCard, setNewCard] = useState(true);

  const [saveCard, setSaveCard] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('AL');

  // New ACH
  const [saveAccount, setSaveAccount] = useState(false);
  const [newAccount, setNewAccount] = useState(true);
  const [accountType, setAccountType] = useState('Checking');

  // Existing Payment Method
  const [mpdId, setMpdId] = useState('');
  const [achMpdId, setAchMpdId] = useState('');
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [openEdgeAchModal, setOpenEdgeAchModal] = useState(false);

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  const showChangeDue = paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach;

  const paymentTypes = getAllowedPaymentTypes(providerData);

  const initData = async () => {
    setRefreshing(true);
    try {
      const pmtDetails = await paymentService.getPaymentDetails(colRecId);

      paymentService.getPaymentProviders(pmtDetails.colRecId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        setProcessors(enabledProviders);
        setProcessor(preferredProviderName);
      });

      setPaymentDetails(pmtDetails);
      setTotalPayment(pmtDetails.prinBal!);
      setAmountTendered(pmtDetails.prinBal!);
      setCarPayment(pmtDetails.prinBal!);
      setCpiDue(pmtDetails.cpiDueNow);
      setDdDue(pmtDetails.ddDueNow);
      setLateCharge(pmtDetails.lcDue);
      setNsfDue(pmtDetails.nsfDue);
      setBuyerEmail(pmtDetails.buyerEmail);
      setFirstName(pmtDetails.buyerFirstName);
      setLastName(pmtDetails.buyerLastName);
      setZip(pmtDetails.buyerZip);
      setAddress(pmtDetails.buyerAddr);
      setCity(pmtDetails.buyerCity);
      setState(pmtDetails.buyerState);
      setIncludeBuyerEmail(pmtDetails.buyerNoEmail);
      setIncludeCobuyerEmail(false);
      setBuyerPhoneNumber(sanitizePhoneInput(pmtDetails.buyerCPhone));

      await getSavedPayments(pmtDetails.appRecId!);

      const users = await paymentService.getUsersByCompanyId(pmtDetails.compId!);
      setEmployeeArray(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (defaultEmployee) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      setPaymentPayload(getDefaultPostPaymentPayload(pmtDetails));
    } catch (e) {
      console.error(e);
    }
    setRefreshing(false);
  };

  const getSavedPayments = async (ari: number) => {
    await paymentService.getSavedPaymentMethods(ari).then((res) => {
      setPaymentMethods(res);
    });
  };

  const submit = () => {
    const tempDmsPayload = paymentPayload;

    const paymentToSend = amountTendered;

    const isAch = paymentType === PaymentType.Ach;
    const isCC = paymentType === PaymentType.CreditCard;

    tempDmsPayload!.AchAcctType = getAchAcctType(paymentType, accountType);
    tempDmsPayload!.AchConvFee = isAch ? convenienceFee : 0;
    tempDmsPayload!.BillAddress = address;
    tempDmsPayload!.BillCity = city;
    tempDmsPayload!.BillEmail = buyerEmail;
    tempDmsPayload!.BillFirstName = firstName;
    tempDmsPayload!.BillLastName = lastName;
    tempDmsPayload!.BillState = state;
    tempDmsPayload!.BillZip = zip;
    tempDmsPayload!.CarPmt = paymentToSend;
    tempDmsPayload!.CcConvFee = isCC ? convenienceFee : 0;
    tempDmsPayload!.Change = changeDue;
    tempDmsPayload!.ColRecId = colRecId!;
    tempDmsPayload!.ColType = paymentDetails!.colType;
    tempDmsPayload!.ConvFee = convenienceFee;
    tempDmsPayload!.CpiPaid = cpiDue;
    tempDmsPayload!.DdPmt = ddDue;
    tempDmsPayload!.EmailB = paymentDetails!.buyerEmail ?? '';
    tempDmsPayload!.EmailC = paymentDetails!.cobuyerEmail ?? '';
    tempDmsPayload!.IsAch = isAch;
    tempDmsPayload!.IsNewCard = getIsNewPayment(paymentType, newCard, newAccount);
    tempDmsPayload!.LcOwed = lateChargeOwed;
    tempDmsPayload!.LcPaid = lateCharge;
    tempDmsPayload!.LcWaived = lateChargeWaived;
    tempDmsPayload!.Mpd.AccountNumber = accountNumber;
    tempDmsPayload!.Mpd.RoutingNumber = routingNumber;
    tempDmsPayload!.Mpd.Token = getMpdToken(paymentType, mpdId, achMpdId);
    tempDmsPayload!.NsfPaid = nsfDue;
    tempDmsPayload!.PaidBy = paymentType;
    tempDmsPayload!.PaidIn = accepted;
    tempDmsPayload!.PaidRef = referenceNumber;
    tempDmsPayload!.PayNote = paymentNote;
    tempDmsPayload!.PmtContext = 'WHOLESALE';
    tempDmsPayload!.PmtType = paymentType;
    tempDmsPayload!.ProcessorType = getProcessorIntByName(processor!);
    tempDmsPayload!.SaveCard = getSavePayment(paymentType, saveCard, saveAccount);
    tempDmsPayload!.SendB = includeBuyerEmail;
    tempDmsPayload!.SendC = includeCobuyerEmail;
    tempDmsPayload!.TakenBy = getInitials(employee!.shortName);
    tempDmsPayload!.TakenByPassword = takenByPassword;
    tempDmsPayload!.TotalReceived = paymentToSend;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;
    tempDmsPayload!.WaiveAchConvFee = isAch && waiveFee;
    tempDmsPayload!.WaiveCCConvFee = isCC && waiveFee;
    tempDmsPayload!.WaiveConvFee = waiveFee;

    setPaymentPayload(tempDmsPayload);

    if (!formErrors && takenByPassword) {
      if (
        (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) ||
        paymentPayload?.Mpd.Token
      ) {
        // TODO: When adding error handling, add logic here to only open modal if there are no form errors
        setConfirmPostModalOpen(true);
      } else if (processor === CardProcessorName.Repay && paymentPayload) {
        // Get iframe url
        paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setIframe(res.iFrameUrl);
          setRepayModal(true);
        });
      } else if (
        processor === CardProcessorName.OpenEdge &&
        paymentType === PaymentType.CreditCard
      ) {
        paymentService
          .postPaymentSubmit(paymentPayload as any)
          .then((res) => {
            setPaymentLogRecId(res.paymentLogRecId);
            setOpenEdgeModal(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        setOpenEdgeAchModal(true);
      }
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const validateNumber = (value: number | undefined) => {
    value !== undefined
      ? value >= 0
        ? setFormErrors(false)
        : setFormErrors(true)
      : setFormErrors(true);
  };

  const errorMessage = (value: Nullable<number>) => {
    if (totalPayment > carPayment) {
      return 'Payment must be less than balance due';
    }

    if (Number.isNaN(value) || value === null) {
      return 'Value cannot be empty';
    } else if (value < 0) {
      return 'Value cannot be negative';
    } else {
      return;
    }
  };

  const handleTotalPaymentBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    validateNumber(parseFloat(e.target.value));
    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setAmountTendered(parseFloat(e.target.value) + convenienceFee);
    }
    setAmountTendered(parseFloat(e.target.value));
  };

  const handleAmountTenderedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    validateNumber(parseFloat(e.target.value));
    if (parseFloat(e.target.value) < totalPayment) {
      setAmountTendered(totalPayment);
    }
  };

  const handleChangeDue = (amountTendered: number, totalPayment: number) => {
    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setChangeDue(0);
    } else if (amountTendered > totalPayment) {
      setChangeDue(amountTendered - totalPayment);
    } else {
      setChangeDue(0);
    }
  };

  const onLateChargeBlur = (value: string) => {
    const maxLateCharge = paymentDetails?.lcDue ? paymentDetails?.lcDue : 0;
    let tempLateCharge = parseFloat(value);

    validateNumber(tempLateCharge);
    let tempLateChargeWaived = lateChargeWaived;
    let tempLateChargeOwed = lateChargeOwed;

    if (tempLateCharge > maxLateCharge) {
      tempLateCharge = maxLateCharge;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = 0;
    } else if (tempLateCharge < 0) {
      tempLateCharge = 0;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = maxLateCharge;
    } else if (tempLateCharge + tempLateChargeWaived > maxLateCharge) {
      tempLateChargeWaived = maxLateCharge - tempLateCharge;
      tempLateChargeOwed = 0;
    }

    tempLateChargeOwed = maxLateCharge - tempLateCharge - tempLateChargeWaived;

    setLateCharge(tempLateCharge);
    setLateChargeWaived(tempLateChargeWaived);
    setLateChargeOwed(tempLateChargeOwed);
  };

  const onLateChargeWaivedBlur = (value: string) => {
    const maxLateCharge = paymentDetails?.lcDue ? paymentDetails?.lcDue : 0;
    let tempLateChargeWaived = parseFloat(value);

    validateNumber(tempLateChargeWaived);
    let tempLateCharge = lateCharge;
    let tempLateChargeOwed = lateChargeOwed;

    if (tempLateChargeWaived > maxLateCharge) {
      tempLateCharge = 0;
      tempLateChargeWaived = maxLateCharge;
      tempLateChargeOwed = 0;
    } else if (tempLateChargeWaived < 0) {
      tempLateCharge = maxLateCharge;
      tempLateChargeWaived = 0;
      tempLateChargeOwed = 0;
    } else if (tempLateCharge + tempLateChargeWaived > maxLateCharge) {
      tempLateCharge = maxLateCharge - tempLateChargeWaived;
      tempLateChargeOwed = 0;
    }

    tempLateChargeOwed = maxLateCharge - tempLateCharge - tempLateChargeWaived;

    setLateCharge(tempLateCharge);
    setLateChargeWaived(tempLateChargeWaived);
    setLateChargeOwed(tempLateChargeOwed);
  };

  useEffect(() => {
    handleChangeDue(amountTendered, totalPayment);
  }, [amountTendered, totalPayment, paymentType]);

  // useEffect to dynamically update total payments and the amount tendered
  useEffect(() => {
    let convFee = 0;
    if (paymentType === PaymentType.CreditCard && !waiveFee) {
      convFee = paymentDetails!.convenienceFee;
    } else if (paymentType === PaymentType.Ach && !waiveFee) {
      convFee = paymentDetails!.achConvenienceFee;
    }

    if (paymentType === PaymentType.CreditCard || (paymentType === PaymentType.Ach && !waiveFee)) {
      setAmountTendered(totalPayment + convFee);
    } else {
      setAmountTendered(totalPayment);
    }
  }, [
    cpiDue,
    ddDue,
    lateCharge,
    nsfDue,
    carPayment,
    convenienceFee,
    waiveFee,
    processor,
    paymentType,
  ]);

  useEffect(() => {
    if (!paymentDetails || !processor|| !paymentDetails) return;
    setConvenienceFee(getConvenienceFee(paymentDetails, processor, paymentType, waiveFee));
  }, [paymentType, processor, waiveFee]);

  // useEffect to update nsfOwed whenever nsfDue is updated
  useEffect(() => {
    if (paymentDetails?.nsfDue) {
      setNsfOwed(paymentDetails.nsfDue - nsfDue);
    }
  }, [paymentDetails?.nsfDue, nsfDue]);

  useEffect(() => {
    initData();
  }, []);

  if (refreshing) {
    return (
      <div className={styles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  const canWaiveFee = getCanWaiveFeeDeprec(processor, providerData);

  return (
    <div>
      {postPaymentLoading && (
        <div className={styles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={styles.header}>
        <img src={levelUpImage} onClick={navigateToList} />
        Wholesale Payment
      </div>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div>
            <form onSubmit={onSubmit} className={styles.paymentDetails}>
              {processors && processors.length > 1 && (
                <DmsDropDown
                  name="processor"
                  label="Processor"
                  values={processors}
                  value={processor}
                  inputContainerStyle={{ width: '155px' }}
                  onChange={(e) => setProcessor(e.target.value as CardProcessorName)}
                />
              )}
              <DmsRadioButtonGroup
                name="paymentType"
                inputLabel="Accepted In"
                buttons={PmtPaidIn.options}
                radioButtonGroupName="AcceptedIn"
                required
                onChange={(e) => setAccepted(e.target.value as PmtPaidIn)}
              />
              <div className={styles.paymentInputContainer}>
                <div className={styles.paymentInputTitle}>Paid</div>
                <div className={styles.paymentInputTitle}>Waived</div>
                <div className={styles.paymentInputTitle}>Owed</div>
              </div>
              <DmsNumberInput
                name="carPayment"
                inputLabel="Balance Due"
                inputStyles={{ width: '100%' }}
                readOnly
                errors={errorMessage(carPayment)}
                value={carPayment}
                fixedDecimalScale
                decimalScale={2}
                onChange={(e) => {
                  setCarPayment(parseFloat(e.target.value));
                }}
              />
              <DmsNumberInput
                name="totalPayment"
                inputLabel="Total Paid"
                inputStyles={{ width: '100%' }}
                value={totalPayment}
                fixedDecimalScale
                decimalScale={2}
                errors={errorMessage(totalPayment)}
                onBlur={(e) => {
                  handleTotalPaymentBlur(e);
                }}
                onChange={(e) => {
                  setTotalPayment(parseFloat(e.target.value));
                }}
              />
              {paymentDetails?.onCpi ? (
                <div className={styles.cpi}>
                  <DmsNumberInput
                    name="cpiDue"
                    inputLabel="CPI Due"
                    inputStyles={{ width: '100%' }}
                    value={cpiDue}
                    fixedDecimalScale
                    decimalScale={2}
                    errors={errorMessage(cpiDue)}
                    onBlur={(e) => {
                      validateNumber(parseFloat(e.target.value));
                      handleCpiDueChange(
                        parseFloat(e.target.value),
                        paymentDetails,
                        setPaymentDetails
                      );
                    }}
                    onChange={(e) => {
                      setCpiDue(parseFloat(e.target.value));
                    }}
                  />

                  <div className={styles.cpiStatus}>{paymentDetails?.cpiStatus}</div>
                </div>
              ) : null}
              {paymentDetails?.defDownBal ? (
                <div className={styles.cpi}>
                  <DmsNumberInput
                    name="ddDue"
                    inputStyles={{ width: '70px' }}
                    value={ddDue}
                    inputLabel="DD Due"
                    fixedDecimalScale
                    decimalScale={2}
                    errors={errorMessage(ddDue)}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setDdDue(parseFloat(e.target.value));
                    }}
                  />
                  <div className={styles.cpiStatus}>{getDdDueStatus(paymentDetails)}</div>
                </div>
              ) : null}
              {paymentDetails?.lcDue ? (
                <div className={styles.lateChargeContainer}>
                  <DmsNumberInput
                    name="lateCharge"
                    inputStyles={{ width: '70px' }}
                    value={lateCharge}
                    inputLabel="Late Fee"
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => onLateChargeBlur(e.target.value)}
                    onChange={(e) => {
                      setLateCharge(parseFloat(e.target.value));
                    }}
                  />
                  <DmsNumberInput
                    name="lateChargeWaived"
                    inputStyles={{ width: '70px' }}
                    value={lateChargeWaived}
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => onLateChargeWaivedBlur(e.target.value)}
                  />
                  <DmsNumberInput
                    name="lateChargeOwed"
                    inputStyles={{ width: '70px' }}
                    value={lateChargeOwed}
                    readOnly
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setLateChargeOwed(parseFloat(e.target.value));
                    }}
                  />
                </div>
              ) : null}
              {paymentDetails?.nsfDue ? (
                <div className={styles.nsfContainer}>
                  <DmsNumberInput
                    name="nsfDue"
                    inputStyles={{ width: '70px' }}
                    value={nsfDue}
                    inputLabel="NSF Due"
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setNsfDue(parseFloat(e.target.value));
                    }}
                  />
                  <DmsNumberInput
                    name="nsfOwed"
                    inputStyles={{ width: '70px' }}
                    value={nsfOwed}
                    readOnly
                    fixedDecimalScale
                    decimalScale={2}
                    onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                    onChange={(e) => {
                      setNsfOwed(parseFloat(e.target.value));
                    }}
                  />
                </div>
              ) : null}
              <DmsDropDown
                name="paymentType"
                label="Payment Type"
                values={paymentTypes}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setPaymentType(e.target.value)}
                value={paymentType}
              />
              <DmsTextInput
                name="referenceNumber"
                inputLabel="Reference #"
                inputStyles={{ width: '100%' }}
                onChange={(e) => setReferenceNumber(e.target.value)}
                value={referenceNumber}
              />
              <DmsNumberInput
                name="amountTendered"
                inputLabel="Amount Tendered"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%' }}
                fixedDecimalScale
                readOnly={paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach}
                decimalScale={2}
                errors={errorMessage(amountTendered)}
                onBlur={(e) => {
                  handleAmountTenderedBlur(e);
                }}
                onChange={(e) => setAmountTendered(parseFloat(e.target.value))}
                value={amountTendered}
              />

              <DmsNumberInput
                name="changeDue"
                inputLabel="Change Due"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%', color: showChangeDue ? '#000' : 'transparent' }}
                value={changeDue}
                readOnly
                fixedDecimalScale
                errors={errorMessage(changeDue)}
                decimalScale={2}
              />
              <DmsTextInput
                inputLabel="Password"
                type="password"
                inputStyles={{ width: '100%' }}
                required
                value={takenByPassword}
                onChange={(e) => setTakenByPassword(e.target.value)}
              />
              <DmsDropDown
                name="employee"
                label="Employee"
                values={employeeArray.map((employee) => employee.shortName)}
                value={employee!.shortName}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setEmployee(employeeArray[e.target.options.selectedIndex])}
              />
              <DmsTextInput
                name="paymentNote"
                inputLabel="Payment Note"
                inputStyles={{ width: '100%' }}
                value={paymentNote}
                onChange={(e) => setPaymentNote(e.target.value)}
              />
              <DmsModal
                title="Confirm Payment"
                message="Are you sure you want to post this payment?"
                onAcceptLabel="Yes"
                onDeclineLabel="No"
                isOpen={confirmPostModalOpen}
                closeFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onDeclineFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onAcceptFunction={() => {
                  paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
                    paymentService
                      .getReceiptUrl(res.paymentRecId)
                      .then((res) => {
                        window.open(res);
                        afterPayment();
                      })
                      .catch((err) => console.error(err));
                  });
                  setConfirmPostModalOpen(false);
                }}
              />
              <Button
                buttonLabel="Post Payment"
                onClick={() => {
                  submit();
                }}
                style={{
                  padding: '4px 0',
                  width: '100%',
                  height: '36px',
                  fontSize: '18px',
                }}
              />
            </form>
            <EmailReceipt
              buyerEmail={paymentDetails?.buyerEmail || ''}
              coBuyerEmail={paymentDetails?.cobuyerEmail || ''}
              containerStyles={{ marginTop: '16px' }}
              noEmailState={paymentDetails?.buyerNoEmail}
              coBuyerNoEmailState={paymentDetails?.cobuyerNoEmail}
              colRecId={colRecId}
              appRecId={paymentDetails?.appRecId}
              buyerRecId={paymentDetails?.buyerRecId}
              cobuyerRecId={paymentDetails?.coBuyerRecId}
              emailCallback={(val) =>
                paymentDetails && setPaymentDetails({ ...paymentDetails, buyerEmail: val })
              }
              cobuyerEmailCallback={(val) =>
                paymentDetails && setPaymentDetails({ ...paymentDetails, cobuyerEmail: val })
              }
              optOutCallback={(val) =>
                paymentDetails && setPaymentDetails({ ...paymentDetails, buyerNoEmail: val })
              }
              cobuyerOptOutCallback={(val) =>
                paymentDetails && setPaymentDetails({ ...paymentDetails, cobuyerNoEmail: val })
              }
              buyerCheckboxFunction={(val) => setIncludeBuyerEmail(val)}
              cobuyerCheckboxFunction={(val) => setIncludeCobuyerEmail(val)}
              hasCobuyer={paymentDetails?.hasCobuyer ?? false}
            />
            {paymentType === PaymentType.CreditCard && (
              <SavedCreditCard
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveCard={saveCard}
                onSaveCard={setSaveCard}
                newCard={newCard}
                onNewCard={setNewCard}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                zip={zip}
                onZip={setZip}
                address={address}
                onAddress={setAddress}
                city={city}
                onCity={setCity}
                state={state}
                onState={setState}
                mpdId={mpdId}
                onMpdId={setMpdId}
                onSetFormErrors={setFormErrors}
                useCardOnFile={true}
                savedPaymentMethods={paymentMethods!}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
            {paymentType === PaymentType.Ach && (
              <SavedACH
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveAccount={saveAccount}
                onSaveAccount={setSaveAccount}
                newAccount={newAccount}
                onNewAccount={setNewAccount}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                onAccountType={setAccountType}
                mpdId={achMpdId}
                onMpdId={setAchMpdId}
                onSetFormErrors={setFormErrors}
                useAchOnFile={true}
                savedPaymentMethods={paymentMethods!}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
          </div>
        </div>
        <DmsModal
          isOpen={openEdgeAchModal}
          closeFunction={() => setOpenEdgeAchModal(false)}
          title="OpenEdge Payment"
          creditCardPaymentProvider="OpenEdge"
        >
          <div className={styles.achContainer}>
            <div className={styles.achInputLabel}>Routing Number</div>
            <DmsNumberInput
              name="routingNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setRoutingNumber(e.target.value)}
              maxLength={9}
            />
            <Spacer size={10} />
            <div className={styles.achInputLabel}>Account Number</div>
            <DmsNumberInput
              name="accountNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setAccountNumber(e.target.value)}
              maxLength={17}
            />
            <Spacer size={10} />
            <Button
              buttonStyles={{ width: '300px', height: '30px' }}
              buttonLabel="Submit"
              onClick={() =>
                paymentService
                  .postPaymentSubmit(paymentPayload as any)
                  .then((res) => {
                    setPaymentLogRecId(res.paymentLogRecId);
                    setOpenEdgeAchModal(false);
                    afterPayment();
                  })
                  .catch((e) => {
                    console.error(e);
                  })
              }
            />
          </div>
        </DmsModal>
        {openEdgeModal && (
          <DmsModal
            isOpen={openEdgeModal}
            closeFunction={() => {
              setOpenEdgeModal(false);
            }}
            title="OpenEdge Payment"
            creditCardPaymentProvider={CardProcessorName.OpenEdge}
          >
            <OpenEdge
              paymentLogRecId={paymentLogRecId}
              apiKey={paymentDetails?.openedgeApiKey}
              onComplete={afterPayment}
            />
          </DmsModal>
        )}
        {repayModal && (
          <DmsModal
            isOpen={repayModal}
            closeFunction={() => {
              setRepayModal(false);
            }}
            title="Repay Payment"
            creditCardPaymentProvider={CardProcessorName.Repay}
          >
            <Repay iframe={iframe} paymentLogRecId={paymentLogRecId} onComplete={afterPayment} />
          </DmsModal>
        )}
      </div>
    </div>
  );
};
