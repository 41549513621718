import { useCallback, useEffect } from 'react';
import { pollForReceiptOld } from '@/utils/helpers/payment';
import { displayErrors } from '@/utils/helpers/general';
import styles from './Repay.module.scss';

export interface RepayProps {
  iframe: string;
  paymentLogRecId: number | null;
  onComplete?: () => void;
}

export const Repay: React.FunctionComponent<RepayProps> = (props) => {
  const { iframe, paymentLogRecId, onComplete = () => null } = props;

  const handleMessage = useCallback(
    (e: any) => {
      if (!e.origin || !e.origin.includes('repay') || !paymentLogRecId) return;

      if (e.data === 'PAYMENT_ERROR') {
        displayErrors('There was an error processing your payment');
      }

      if (e.data === 'PAYMENT_SUCCESS') {
        pollForReceiptOld(paymentLogRecId, onComplete);
      }
    },
    [paymentLogRecId]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <iframe className={styles.container} src={iframe} title="Repay Credit Card Payment"></iframe>
  );
};
