import { FC } from 'react';
// kendo
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
// components
import { NumberInput } from '@/components';
// utils
import { useReportsCtx } from '../ReportsList/defaultState';
import { IModalFilterParams, newModalFilterParams } from '../interfaces';

/** ### Amount form for export filter.
 * Since component only renders if:
 * - Selected report is set; AND
 * - Modal filter params is set; AND
 * - The filter field is set;
 * Form is always controlled and no placeholder or validation is needed.
 */
const AmtForm: FC = () => {
  const modalFilterParams = useReportsCtx(s => s.modalFilterParams);
  const setModalFilterParams = useReportsCtx(s => s.setModalFilterParams);


  // Event handlers
  const handleChangeAmtField = (e: NumericTextBoxChangeEvent) => {
    const updatedModalFilterParams: IModalFilterParams = {
      ...(modalFilterParams || newModalFilterParams({})),
      amt: e.target.value,
    };
    setModalFilterParams(updatedModalFilterParams);
  };

  return (
    <NumberInput
      onChange={handleChangeAmtField}
      label="Amount"
      horizontalLabel
      value={modalFilterParams!.amt!}
    />
  );
};

export default AmtForm;
