import { useMemo, useState, forwardRef, FC } from 'react';
import { Label } from '@progress/kendo-react-all';
import { Control, useController } from 'react-hook-form';
import styles from './CheckboxGroup.module.scss';
import textStyles from '@components/text/Text.module.scss';

interface CheckboxGroupProps {
  label?: string;
  errors?: string | boolean;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  horizontalOptions?: boolean;
  required?: boolean;
  options: string[];
  control: Control<any>;
  name: string;
  disabled?: boolean;
  hidden?:boolean;
}

// Can only be used in react-hook-form due to the interaction with the controller
const CheckboxGroup: FC<CheckboxGroupProps> = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  (
    {
      options,
      control,
      name,
      label,
      boldLabel = true,
      horizontalLabel = true,
      horizontalOptions,
      errors,
      required,
      disabled,
      hidden,
    },
    ref
  ) => {
    const { field } = useController({
      control,
      name,
    });
    const [value, setValue] = useState(field.value || []);
    const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

    const Checkboxes = (
      <div className={horizontalOptions ? styles.horizontalCheckboxes : styles.checkboxes} hidden={hidden}>
        {options.map((option, idx) => (
          <div className={styles.checkbox} key={`checkboxGroup-${idx}`}>
            <div className={styles.checkboxLabel}>{option}</div>
            <input
              className={styles.checkboxInput}
              onChange={(e) => {
                let valueCopy = [...value];

                const currentlyHasValue = valueCopy.find((v) => v === e.target.value);

                if (e.target.checked && !currentlyHasValue) {
                  valueCopy.push(e.target.value);
                }

                if (!e.target.checked && currentlyHasValue) {
                  valueCopy = valueCopy.filter((v) => v !== e.target.value);
                }

                // send data to react hook form
                field.onChange(valueCopy);

                // update local state
                setValue(valueCopy);
              }}
              key={option}
              type="checkbox"
              checked={value.includes(option)}
              value={option}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    );

    return (
      <div className={styles.inputContainer} hidden={hidden} ref={ref}>
        {label ? (
          horizontalLabel ? (
            <div className={textStyles.row}>
              <Label
                editorId={editorId}
                className={textStyles.label}
                style={{ fontWeight: boldLabel ? 700 : 400 }}
              >{`${label} ${required ? '*' : ''}`}</Label>
              {Checkboxes}
            </div>
          ) : (
            <Label
              editorId={editorId}
              className={textStyles.label}
              style={{ fontWeight: boldLabel ? 700 : 400 }}
            >
              {`${label} ${required ? '*' : ''}`}
              {Checkboxes}
            </Label>
          )
        ) : (
          <>{Checkboxes}</>
        )}
        {!!errors && typeof errors === 'string' && (
          <div className={styles.errorMessage}>{errors}</div>
        )}
      </div>
    );
  }
);

export default CheckboxGroup;
