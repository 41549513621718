import dayjs from "dayjs";
import { ZodEnum } from "zod";
// mui
import { GridColDef } from "@mui/x-data-grid";
// utils
import { formatPercent } from "@/utils/helpers/formatting";
import { DateFormat, formatCurrency, formatDate } from "@/utils/helpers/general";
import { usaStatesMap } from "@/general/regions";

/** Format a cell with currency input. Used in `GridColDef.valueFormatter` */
export const currencyCellFmt: GridColDef["valueFormatter"] = ({ value }) =>
  isNaN(Number(value)) ? "-" : formatCurrency(Number(value));

/** Format a cell with date input. Used in `GridColDef.valueFormatter` */
export const dateCellFmt: GridColDef["valueFormatter"] = ({ value }) =>
  formatDate(value || "-", { pattern: DateFormat.CondensedDate });

/** Format a cell with date input. Used in `GridColDef.valueFormatter` */
export const dateCellDefaultFmt: GridColDef["valueFormatter"] = ({ value }) => 
  formatDate(value || "-", { pattern: DateFormat.Default, placeholder: "-" });

/** Format a cell with datetime input. Used in `GridColDef.valueFormatter` */
export const datetimeCellFmt: GridColDef["valueFormatter"] = ({ value }) =>
  !value ? "-" : dayjs(value).format(DateFormat.DateTime);

/** Format a cell with state input. Used in `GridColDef.valueFormatter` */
export const stateCellFmt: GridColDef["valueFormatter"] = ({ value }) =>
  !value ? "-" : (usaStatesMap.has(value) ? usaStatesMap.get(value) : value);

/** Format a cell with datetime input. Used in `GridColDef.valueGetter` */
export const datetimeCellGetter: GridColDef["valueGetter"] = ({ value }): any =>
  !value ? null : dayjs(value).toDate();

/** Format a cell with '0 to 1' range percent input. Used in `GridColDef.valueFormatter` */
export const percentCellFmt: GridColDef["valueFormatter"] = ({ value }) => formatPercent(value);

/** Format a cell with '0 to 100' range percent input. Used in `GridColDef.valueFormatter` */
export const percentCellScaledFmt: GridColDef["valueFormatter"] = ({ value }) =>
  formatPercent(value, { is0to100: true });

/** Format a cell with enum label input. Used in `GridColDef.valueFormatter` */
export const enumCellFmt: <T extends [string, ...string[]]>(
  enumMap: Map<ZodEnum<T>, string>,
  placeholder?: string
) => GridColDef["valueFormatter"] =
  (enumMap, placeholder) =>
  ({ value }) => {
    const fallback = placeholder || value;
    const displayValue = enumMap.get(value) || fallback;

    return displayValue;
  };

/** Format a cell with numeric enum input. Used in `GridColDef.valueFormatter` */
export const enumIdxCellFmt: <T extends [string, ...string[]]>(
  enum_: ZodEnum<T>,
  placeholder?: string
) => GridColDef["valueFormatter"] =
  (enum_, placeholder) =>
  ({ value }) => {
    const fallback = placeholder || value;
    const displayValue = enum_.options[value] || fallback;

    return displayValue;
  };
