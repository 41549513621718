import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
// components
// state
import { useAppDispatch } from '@/store/store';
import { useNavigationConfirm } from '@/hooks';
import { getQuoteData } from '@/features/Sales/salesActionCreator';
import { useAuthSelector } from '@/features/auth/authSlice';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { systemService } from '@/services/systemService';
import { salesService } from '@/services/salesService';
import {
  checkIfNullOrUndefined,
  roundToTwoDecimals,
} from '@/utils/helpers/general';
import { getsalestax, reCalcCashTerms } from '@/utils/helpers/cashTerms';
// interfaces
import { QuoteData } from '@/interfaces';
import { TermsLockType, cashTermFormDefaults } from './cashInterfaces';
// style
import styles from '../../../desking/deskingTabs/quickQuote/financeTab/FinanceTab.module.scss';
import tabStyles from '../../SalesTab.module.scss';

/** @deprecated needs refactor */
const CashTerm: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const appRecId = Number(params.id);
  const dispatch = useAppDispatch();

  const compId = useAuthSelector((s) => s.compId);
  const orgId = useAuthSelector((s) => s.orgId);
  const disableDate = orgId !== 2;
  const quoteData = useSalesSelector((s) => s.quoteData);
  const saleData = useSalesSelector((s) => s.saleData);
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);
  const quoteDataLoading = useSalesSelector((s) => s.quoteDataLoading);

  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [customField6Name, setCustomField6Name] = useState('');
  const [updateCashLoading, setUpdateCashLoading] = useState(false);
  const [currentLock, setCurrentLock] = useState<TermsLockType>(TermsLockType.Amt);
  const [quotePrinting, setQuotePrinting] = useState(false);
  const [cashQuoteData, setCashQuoteData] = useState<QuoteData | undefined>(undefined);
  const [pageError, setPageError] = useState('');
  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({ defaultValues: cashTermFormDefaults });

  const cashSaleType = 'Retail Sale';
  const tradeAllowance = watch('tradeAllowance');
  const tradePayoff = watch('tradePayoff');
  const depositDown = watch('depositDown');
  const cashOnDelivery = watch('cashOnDelivery');


  const resetForm = () => {
    reset(
      {
        dateOfSale: dayjs().format('YYYY-MM-DD'),
        salesPrice: !checkIfNullOrUndefined(saleData.sale?.salesPrice)
          ? saleData.sale?.salesPrice
          : cashQuoteData?.deF_SP,
        salesTax: saleData.sale?.salesTax || 0,
        license: !checkIfNullOrUndefined(saleData.sale?.license)
          ? saleData.sale?.license
          : cashQuoteData?.pricE1,
        title: !checkIfNullOrUndefined(saleData.sale?.title)
          ? saleData.sale?.title
          : cashQuoteData?.pricE2,
        registration: !checkIfNullOrUndefined(saleData.sale?.registration)
          ? saleData.sale?.registration
          : cashQuoteData?.pricE3,
        customField1: !checkIfNullOrUndefined(saleData.sale?.addon4)
          ? saleData.sale?.addon4
          : cashQuoteData?.pricE4,
        customField2: !checkIfNullOrUndefined(saleData.sale?.addon5)
          ? saleData.sale?.addon5
          : cashQuoteData?.pricE5,
        customField3: !checkIfNullOrUndefined(saleData.sale?.addon6)
          ? saleData.sale?.addon6
          : cashQuoteData?.pricE6,
        customField4: !checkIfNullOrUndefined(saleData.sale?.addon7)
          ? saleData.sale?.addon7
          : cashQuoteData?.pricE7,
        customField5: !checkIfNullOrUndefined(saleData.sale?.addon8)
          ? saleData.sale?.addon8
          : cashQuoteData?.pricE8,
        customField6: !checkIfNullOrUndefined(saleData.sale?.addon9)
          ? saleData.sale?.addon9
          : cashQuoteData?.pricE9,
        serviceContract: !checkIfNullOrUndefined(saleData.sale?.servCont)
          ? saleData.sale?.servCont
          : cashQuoteData?.pricE10,
        totalSalePrice: saleData.sale?.totalSP || 0,
        tradeAllowance: saleData.sale?.tradeAllow || 0,
        tradePayoff: saleData.sale?.tradePayoff || 0,
        cashOnDelivery: !checkIfNullOrUndefined(saleData.sale?.cod)
          ? saleData.sale?.cod
          : cashQuoteData?.def_Down,
        depositDown: !checkIfNullOrUndefined(saleData.sale?.depositDown)
          ? saleData.sale?.depositDown
          : 0,

      },
      { keepDirty: false }
    );
    onFieldBlur();

  };

  const initData = () => {
    try {
      setCustomField1Name(cashQuoteData?.adD4 || '');
      setCustomField2Name(cashQuoteData?.adD5 || '');
      setCustomField3Name(cashQuoteData?.adD6 || '');
      setCustomField4Name(cashQuoteData?.adD7 || '');
      setCustomField5Name(cashQuoteData?.adD8 || '');
      setCustomField6Name(cashQuoteData?.adD9 || '');
    } catch (err) {
      setPageError('Unable to load quote data');
    }
  };

  const { NavigationConfirm } = useNavigationConfirm(
    isDirty //TODO: also check addSpecialPaymentPlan.formState.isDirty
  );

  const onFieldBlur = (
    newLock?: TermsLockType
  ) => {
    const formValues = getValues();

    const lock = newLock ? newLock : currentLock;
    if (newLock) setCurrentLock(newLock);

    const addon1 = Math.max(0, formValues.license);
    const addon2 = Math.max(0, formValues.title);
    const addon3 = Math.max(0, formValues.registration);
    const addon4 = Math.max(0, formValues.customField1);
    const addon5 = Math.max(0, formValues.customField2);
    const addon6 = Math.max(0, formValues.customField3);
    const addon7 = Math.max(0, formValues.customField4);
    const addon8 = Math.max(0, formValues.customField5);
    const addon9 = Math.max(0, formValues.customField6);
    const addon10 = Math.max(0, formValues.serviceContract);
    const stax1 = saleData.sale!.staX1!;
    const stax2 = saleData.sale!.staX2!;
    const stax3 = saleData.sale!.staX3!;
    const stax4 = saleData.sale!.staX4!;
    const stax5 = saleData.sale!.staX5!;
    const stax6 = saleData.sale!.staX6!;
    const stax7 = saleData.sale!.staX7!;
    const stax8 = saleData.sale!.staX8!;
    const stax9 = saleData.sale!.staX9!;
    const stax10 = saleData.sale!.staX10!;
    const ctax1 = saleData.sale!.citytaX1!;
    const ctax2 = saleData.sale!.citytaX2!;
    const ctax3 = saleData.sale!.citytaX3!;
    const ctax4 = saleData.sale!.citytaX4!;
    const ctax5 = saleData.sale!.citytaX5!;
    const ctax6 = saleData.sale!.citytaX6!;
    const ctax7 = saleData.sale!.citytaX7!;
    const ctax8 = saleData.sale!.citytaX8!;
    const ctax9 = saleData.sale!.citytaX9!;
    const ctax10 = saleData.sale!.citytaX10!;
    const ltax1 = saleData.sale!.loctaX1!;
    const ltax2 = saleData.sale!.loctaX2!;
    const ltax3 = saleData.sale!.loctaX3!;
    const ltax4 = saleData.sale!.loctaX4!;
    const ltax5 = saleData.sale!.loctaX5!;
    const ltax6 = saleData.sale!.loctaX6!;
    const ltax7 = saleData.sale!.loctaX7!;
    const ltax8 = saleData.sale!.loctaX8!;
    const ltax9 = saleData.sale!.loctaX9!;
    const ltax10 = saleData.sale!.loctaX10!;
    const otax1 = saleData.sale!.countytaX1!;
    const otax2 = saleData.sale!.countytaX2!;
    const otax3 = saleData.sale!.countytaX3!;
    const otax4 = saleData.sale!.countytaX4!;
    const otax5 = saleData.sale!.countytaX5!;
    const otax6 = saleData.sale!.countytaX6!;
    const otax7 = saleData.sale!.countytaX7!;
    const otax8 = saleData.sale!.countytaX8!;
    const otax9 = saleData.sale!.countytaX9!;
    const otax10 = saleData.sale!.countytaX10!;

    const statesalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.staterate!,
      saleData.sale!.statemax!,
      saleData.sale!.statemin!,
      saleData.sale!.stateon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      stax1,
      stax2,
      stax3,
      stax4,
      stax5,
      stax6,
      stax7,
      stax8,
      stax9,
      stax10
    );
    const countysalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.countyrate!,
      saleData.sale!.countymax!,
      saleData.sale!.countymin!,
      saleData.sale!.countyon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      otax1,
      otax2,
      otax3,
      otax4,
      otax5,
      otax6,
      otax7,
      otax8,
      otax9,
      otax10
    );
    const citysalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.cityrate!,
      saleData.sale!.citymax!,
      saleData.sale!.citymin!,
      saleData.sale!.cityon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ctax1,
      ctax2,
      ctax3,
      ctax4,
      ctax5,
      ctax6,
      ctax7,
      ctax8,
      ctax9,
      ctax10
    );
    const locsalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.locrate!,
      saleData.sale!.locmax!,
      saleData.sale!.locmin!,
      saleData.sale!.locon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ltax1,
      ltax2,
      ltax3,
      ltax4,
      ltax5,
      ltax6,
      ltax7,
      ltax8,
      ltax9,
      ltax10
    );
    const newSalesTax = statesalestax + countysalestax + citysalestax + locsalestax;
    // const salesTax = 0;
    const newTotalSalePrice =
      formValues.salesPrice +
      formValues.license +
      formValues.title +
      formValues.registration +
      formValues.customField1 +
      formValues.customField2 +
      formValues.customField3 +
      formValues.customField4 +
      formValues.customField5 +
      formValues.customField6 +
      formValues.serviceContract +
      newSalesTax ;

    const newCashOnDelivery = 
    newTotalSalePrice - 
    formValues.depositDown- 
    formValues.tradeAllowance + 
    formValues.tradePayoff;

    const newData = reCalcCashTerms( //have to change this at the source first
      {
        dateOfSale: formValues.dateOfSale,
        salesPrice: formValues.salesPrice,
        salesTax: newSalesTax,
        license: formValues.license,
        title: formValues.title,
        registration: formValues.registration,
        customField1: formValues.customField1,
        customField2: formValues.customField2,
        customField3: formValues.customField3,
        customField4: formValues.customField4,
        customField5: formValues.customField5,
        customField6: formValues.customField6,
        serviceContract: formValues.serviceContract,
        totalSalePrice: newTotalSalePrice,
        tradeAllowance: tradeAllowance,
        tradePayoff: formValues.tradePayoff,
        depositDown: formValues.depositDown,
        cashOnDelivery: newCashOnDelivery,

      },
      saleData,
      cashQuoteData!,
      lock
    );

    setValue('salesPrice', newData!.salesPrice);
    setValue('salesTax', newData!.salesTax);
    setValue('license', newData!.license);
    setValue('title', newData!.title);
    setValue('registration', newData!.registration);
    setValue('customField1', newData!.addon4); // DOC FEE May change in back end
    setValue('customField2', newData!.addon5);
    setValue('customField3', newData!.addon6);
    setValue('customField4', newData!.addon7);
    setValue('customField5', newData!.addon8);
    setValue('customField6', newData!.addon9);
    setValue('serviceContract', newData!.serviceContract);
    setValue('totalSalePrice', newData!.totalSp);
    setValue('cashOnDelivery', newData!.cod);
  };

  const printQuote = async () => {
    setQuotePrinting(true);
    try {
      if (isDirty) {
        toast.error('Form has updates that must be submitted before printing quote');
        return;
      }
      const validForm = await trigger();
      if (!validForm) {
        toast.error('Quote has errors, please review');
        return;
      }
      onFieldBlur();
      await updateCashTerm(getValues());
      const formIds = await systemService.getFormIds(saleData.sale!.compid!);
      const quoteUrl = await salesService.printSalesDoc({
        formIds: [formIds.company.formQuoteFSId!],
        appRecId,
      });
      window.open(quoteUrl);
      await salesService.setHasQuote(appRecId);
      dispatch(salesActions.setHasQuote(true));
    } catch (err) {
      toast.error('Unable to print quote');
    } finally {
      setQuotePrinting(false);
    }
  };

  useEffect(() => {
    dispatch(getQuoteData(compId!));
  }, []);

  useEffect(() => {
    if (!quoteData || !quoteData.length) return;
    setCashQuoteData(quoteData.find((value) => value.stype === cashSaleType));
  }, [quoteData]);

  useEffect(() => {
  }, [cashOnDelivery, tradeAllowance, tradePayoff, depositDown]);


  useEffect(() => {
    if (!cashQuoteData) return;
    initData();
  }, [cashQuoteData]);

  const updateCashTerm = async (data: typeof cashTermFormDefaults) => {
    setUpdateCashLoading(true);
    try {
      const res = await salesService.updateCashTerms({
        appRecId: appRecId,
        saleDate: data.dateOfSale !== 'Invalid Date' ? data.dateOfSale : null,
        salePrice: roundToTwoDecimals(data.salesPrice),
        saleTax: roundToTwoDecimals(data.salesTax),
        license: roundToTwoDecimals(data.license),
        title: roundToTwoDecimals(data.title),
        registration: roundToTwoDecimals(data.registration),
        customField1: roundToTwoDecimals(data.customField1),//DOC FEE May change in back end
        customField2: roundToTwoDecimals(data.customField2),
        customField3: roundToTwoDecimals(data.customField3),
        customField4: roundToTwoDecimals(data.customField4),
        customField5: roundToTwoDecimals(data.customField5),
        customField6: roundToTwoDecimals(data.customField6),
        serviceContract: roundToTwoDecimals(data.serviceContract),
        totalSalePrice: roundToTwoDecimals(data.totalSalePrice),
        tradeAllowance: roundToTwoDecimals(data.tradeAllowance),
        tradePayoff: roundToTwoDecimals(data.tradePayoff),
        depositDown: roundToTwoDecimals(data.depositDown),
        cashOnDelivery: roundToTwoDecimals(data.cashOnDelivery),
      });
      dispatch(salesActions.setSaleData(res));
      toast.success('Cash Terms Updated');
    } catch (err) {
      toast.error('Unable to update cash terms');
    } finally {
      setUpdateCashLoading(false);
    }
  };

  useEffect(() => {
    if (saleData && cashQuoteData) {
      resetForm();
    }
  }, [saleData, cashQuoteData]);

  if (saleDataLoading || quoteDataLoading)
    return (
      <div className={tabStyles.loader}>
        <Loader size="large" />
      </div>
    );
  if (pageError) return <div>{pageError}</div>;

  return (
    <Grid container direction="column">
      <header className={tabStyles.headerContainer}>
        <h2 className={tabStyles.header}>{saleData.sale?.saletype || ''} Terms</h2>
        <div className={tabStyles.headerButtonContainer}>
          <Button label="Sales Tax" onClick={() => navigate('sales-tax')} />
          <Button label="Trade In" onClick={() => navigate('trade-in')} />
          <Button label="Print Quote" onClick={printQuote} loading={quotePrinting} />
        </div>
      </header>
      <main className={tabStyles.body}>
        <form onSubmit={handleSubmit(updateCashTerm)}>
          <div className={tabStyles.bodyContainer}>
            <div className={tabStyles.bodyChild}>
              <Controller
                name="dateOfSale"
                control={control}
                render={({ field }) => <DateInput label="Date of Sale" readOnly={disableDate} {...field} />}
              />
              <Controller
                name="salesPrice"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Sales Price"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />

              <Controller
                name="salesTax"
                control={control}
                render={({ field }) => (
                  <CurrencyInput label="Sales Tax" readOnly rightAlignInput {...field} />
                )}
              />
              <Controller
                name="license"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="License"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Title"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="registration"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Registration"
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    rightAlignInput
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField1" //CHANGE THIS TO DOC FEE, FIND IT May change in back end
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField1Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                  />
                )}
              />
              <Controller
                name="customField2"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField2Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField3"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField3Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              
            </div>
            <div className={tabStyles.bodyChild}>
            <Controller
                name="customField4"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField4Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField5"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label={customField5Name}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField6"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label={customField6Name}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="serviceContract"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Service Contract"
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="tradeAllowance"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Trade Allowance" readOnly {...field} />
                )}
              />
              <Controller
                name="tradePayoff"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Trade Payoff" readOnly {...field} />
                )}
              />
              <Controller
                name="depositDown"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Deposit Down" readOnly {...field} />
                )}
              />
              <Controller
                name="cashOnDelivery"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Cash On Delivery"
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="totalSalePrice"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Total Sale Price" readOnly {...field} />
                )}
              />
              <small className={tabStyles.disclaimer} style={{ alignSelf: 'flex-end' }}>
                Includes sales tax and other fees
              </small>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.rightButtonGroup}>
              <Button
                label="Submit"
                disabled={saleData.sale?.salestatus == 'Posted'}
                buttonStyle={{ width: 102 }}
                loading={updateCashLoading}
              />
            </div>
          </div>
        </form>
      </main>
      {NavigationConfirm}
    </Grid>
  );
};

export default CashTerm;
