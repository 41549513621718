import { useNavigate } from 'react-router-dom';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions } from '@/features/Accounts/accountSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { accountsService } from '@/services/accountsService';

/** ### Fetch account information after sending an unassociated request, and navigate to a given route
 * @note This iteration involves one hook for a given set of routes:
 * - In this case, this hook can only be used for navigating within the "Account Detail" view.
 * @todo add type-safety and validation to `browserLocation`
 * @todo adapt for general fetch + state use
 */
const useFetchAndNavAccountDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // return async (routeBase: string, colRecId: number) => {
  return async (browserPath: string, colRecId: number) => {
    // Fetch info, update state
    await dispatch(getAccountInformation(colRecId));
    const res = await accountsService.getContactInformation(colRecId);
    dispatch(accountActions.setContactInformation(res));

    // Build route from params and navigate
    navigate(browserPath);
  };
};

export default useFetchAndNavAccountDetail;
