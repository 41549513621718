import { FC } from "react";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";

const CurrencyFieldDisplay: FC<{ label: string; value?: number | null; isFlex1?: boolean }> = ({
  label,
  value,
  isFlex1 = false,
}) => (
  <CurrencyInput
    label={label}
    value={value}
    placeholder="-"
    readOnly
    containerStyle={isFlex1 ? { flex: 1 } : {}}
  />
);

export default CurrencyFieldDisplay;
