import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerOriginalContractInformation } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardLeaseContractInformation: FC<{
  originalContractInformation: LedgerOriginalContractInformation;
}> = ({ originalContractInformation }) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Original Contract Information</div>
    <table style={ledgerStyles.ledgerTable}>
      <tbody>
        <tr>
          <td style={ledgerStyles.label}>Sales Price</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.salesPrice || 0)}
          </td>
          <td style={ledgerStyles.tableSpacer}></td>
          <td style={ledgerStyles.label}>Date Leased</td>
          <td></td>
          <td>{formatDate(originalContractInformation.dateSold)}</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>{originalContractInformation.addOn5Label}</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.addOn5 || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Stock #</td>
          <td></td>
          <td>{originalContractInformation.stockNumber}</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>{originalContractInformation.addOn6Label}</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.addOn6 || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Vehicle</td>
          <td></td>
          <td colSpan={4}>{originalContractInformation.vehicle}</td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>{originalContractInformation.addOn7Label}</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.addOn7 || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>V.I.N.</td>
          <td></td>
          <td colSpan={4}>{originalContractInformation.vin}</td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>{originalContractInformation.addOn8Label}</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.addOn8 || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Miles</td>
          <td></td>
          <td>{originalContractInformation.miles}</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>{originalContractInformation.addOn9Label}</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.addOn9 || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Trade Allowance</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.tradeAllowance || 0)}
          </td>
          <td style={ledgerStyles.tableSpacer}></td>
          <td style={ledgerStyles.label}>Total Down</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.totalDown || 0)}
          </td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td style={ledgerStyles.label}>Trade Payoff</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.tradePayoff || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Rate</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>{originalContractInformation.apr ?? 'n/a'}</td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Service Contract</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.serviceContract || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Net Trade</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.netTrade || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Depreciation Total</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.depreciationTotal || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Credit Life</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.creditInsurance || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Security Deposit</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.securityDeposit || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Rent Total</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.financeCharge || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Credit Disability</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.creditDisability || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Tax on Down</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.downTax || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Sales Tax</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.salesTax || 0)}
          </td>
        </tr>
        <tr>
          <td style={ledgerStyles.label}>Total Sale Price</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.totalSalePrice || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>License</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.license || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Total Lease Payments</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.totalPayments || 0)}
          </td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td style={ledgerStyles.label}>Title</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.title || 0)}
          </td>
          <td></td>
          <td style={ledgerStyles.label}>Residual</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.residual || 0)}
          </td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td style={ledgerStyles.label}>Registration</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.registration || 0)}
          </td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td style={ledgerStyles.label}>Deferred Down</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.deferredDown || 0)}
          </td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td style={ledgerStyles.label}>Due at Signing</td>
          <td></td>
          <td style={ledgerStyles.pullRight}>
            {formatCurrency(originalContractInformation.cashOnDelivery || 0)}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default LedgerCardLeaseContractInformation;
