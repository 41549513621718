import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@/mui/components/buttons/MuiKendoButton';
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';
// utils
import { useNavUp } from '@/utils/routing/useNavUpEvent';

const CancelButton: FC = () => {
  const navUp = useNavUp();
  const resetFormState = usePmtReversalDetailCtx((s) => s.resetFormState);

  // Event handlers
  const handleClickCancel = () => {
    resetFormState();
    navUp();
  };

  return (
    // @note this will replace below: <Button onClick={handleClickCancel} sx={{ backgroundColor: 'rgb(245,20,20)' }} color="error">
    <Button onClick={handleClickCancel}>Cancel</Button>
  );
};
const SubmitButton: FC = () => {
  // @note `isDirty` is not updating - need to look into that
  const { isValid, isSubmitting } = usePmtReversalDetailCtx((s) => s.pmtForm.formState);
  const setIsConfirmOpen = usePmtReversalDetailCtx((s) => s.setIsConfirmOpen);

  // Event handlers
  const handleOpenConfirmationModal = () => setIsConfirmOpen(true);

  return (
    <Button onClick={handleOpenConfirmationModal} disabled={!isValid || isSubmitting}>
      Submit
    </Button>
  );
};

/** ###  */
const FormSubmitButtonGroup: FC = () => {
  return (
    <Grid container direction="row" justifyContent="end" gap={1}>
      <SubmitButton />
      <CancelButton />
    </Grid>
  );
};

export default FormSubmitButtonGroup;
