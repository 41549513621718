import { InventoryExpense } from '@/interfaces';
import { inventoryService } from '@/services/inventoryService';
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridNoRecords,
  Loader,
} from '@progress/kendo-react-all';
import { FC, useCallback, useEffect, useState } from 'react';
import { useServerGrid } from '@/hooks/useServerGrid/useServerGrid';
import { ServerGridOptions } from '@/interfaces/ServerGrids';
import { KendoDateCell } from '@/components/kendoCells/KendoDateCell';
import KendoCurrencyCell from '@/components/kendoCells/KendoCurrencyCell';
import { KendoPdfCell } from '@/components/kendoCells/KendoPdfCell';
import { KendoNumberCell } from '@/components/kendoCells/KendoNumberCell';
import { systemService } from '@/services/systemService';
import { toast } from 'react-toastify';
import { GroupDescriptor, GroupResult, groupBy } from '@progress/kendo-data-query';
import { Button } from '@/components';
import inventoryPageStyles from '../../InventoryPageStyles.module.scss';
import styles from './expensesGrid.module.scss';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { formatCurrency } from '@/utils/helpers/general';
import { EditExpenseModal } from '../editExpenseModal/editExpenseModal';

const DateCell = (props: GridCustomCellProps) => {
  return <KendoDateCell dateFormat="MM/DD/YYYY" {...props} />;
};

const PdfCell = (props: GridCustomCellProps) => {
  const hasDoc = props.dataItem.docFlag as boolean;
  const orgId = props.dataItem.orgId;
  const blobName = props.dataItem.docName;

  return <KendoPdfCell onClick={() => downloadFile(orgId, blobName)} hasDoc={hasDoc} {...props} />;
};

interface ActionCellProps extends GridCustomCellProps {
  onEdit: () => void;
  historicalExpense: boolean;
}

const ExpenseActionCell: FC<ActionCellProps> = ({ onEdit, historicalExpense, ...rest }) => {
  return (
    <td {...rest.tdProps}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Button label={historicalExpense ? 'View' : 'Edit'} secondary onClick={onEdit} />
      </div>
    </td>
  );
};

const downloadFile = async (orgId: number, blobName: string) => {
  try {
    const fileUrl = await systemService.getBlobReadUrl(orgId, blobName);
    window.open(fileUrl);
  } catch (e) {
    toast.error('Unable to open expense file');
    console.error(e);
    throw e;
  }
};

interface ExpensesGridProps {
  recId: string | undefined;
  type: 'Current' | 'Historical';
  saleStatus: string;
  vehRecId: string;
  stockNum: string;
}

export const ExpensesGrid: FC<ExpensesGridProps> = ({
  recId,
  type,
  saleStatus,
  vehRecId,
  stockNum,
}) => {
  const historicalExpenses = type === 'Historical';
  const initialTitle = historicalExpenses ? 'Expense History' : 'Current Expenses';

  const [title, setTitle] = useState<string>(initialTitle);
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const [selectedExpense, setSelectedExpense] = useState<InventoryExpense>();

  const loadCurrentExpenses = useCallback(
    async (options: ServerGridOptions) =>
      await inventoryService.getCurrentExpenses(recId!, options),
    [recId]
  );

  const loadHistoricalExpenses = useCallback(
    async (options: ServerGridOptions) =>
      await inventoryService.getHistoricalExpenses(recId!, options),
    [recId]
  );

  const { loading, data, total, sort, onSortChange, reload } = useServerGrid<InventoryExpense>({
    fetchFunction: historicalExpenses ? loadHistoricalExpenses : loadCurrentExpenses,
    initialSortOptions: [{ field: 'expDate', dir: 'desc' }],
  });

  const addNewExpense = () => {
    setSelectedExpense(undefined);
    setEditModalOpened(true);
  };

  const editExpense = (expense: InventoryExpense) => {
    setSelectedExpense(expense);
    setEditModalOpened(true);
  };

  const groupDescriptor: GroupDescriptor[] = [{ field: 'stockNum' }];

  useEffect(() => {
    if (data.length === 0) {
      setTitle(initialTitle + ` - No Expenses`);
      return;
    }

    const total = formatCurrency(data.reduce((total, next) => total + next.expAmt, 0));
    if (data.length === 1) {
      setTitle(initialTitle + ` - 1 Expense for ${total}`);
    } else {
      setTitle(initialTitle + ` - ${data.length} Expenses for ${total}`);
    }
  }, [data]);

  const ActionCell = (props: GridCustomCellProps) => {
    const { dataItem } = props;

    if (dataItem.internal == null) {
      return <></>;
    }

    return (
      <ExpenseActionCell
        onEdit={() => editExpense(dataItem)}
        historicalExpense={historicalExpenses}
        {...props}
      />
    );
  };

  return (
    <>
      <section className={inventoryPageStyles.multipleSectionsChild}>
        <SectionHeader title={title} showBar={true}>
          {!historicalExpenses && <Button label="Add New +" onClick={addNewExpense} />}
        </SectionHeader>
        <Grid
          data={
            historicalExpenses
              ? groupBy(data, groupDescriptor).map((x) => {
                  if ((x as GroupResult).value) {
                    (x as GroupResult).value = `Stock # ${(x as GroupResult).value}`;
                  }
                  return x;
                })
              : data
          }
          total={total}
          scrollable="none"
          sortable={true}
          sort={sort}
          onSortChange={onSortChange}
          groupable={historicalExpenses ? true : false}
          group={historicalExpenses ? groupDescriptor : []}
          lockGroups={true}
          className={styles.removeGroupingHeader}
        >
          <GridNoRecords>{loading ? <Loader size="large" /> : 'No records found'}</GridNoRecords>
          <GridColumn field="expDate" title="Date" key="expDate" cells={{ data: DateCell }} />
          <GridColumn
            field="expAmt"
            title="Amount"
            key="expAmt"
            cells={{ data: KendoCurrencyCell }}
          />
          <GridColumn field="poNum" title="Invoice" key="poNum" cells={{ data: KendoNumberCell }} />
          <GridColumn field="paidTo" title="Paid To" key="paidTo" />
          <GridColumn field="expFor" title="Expense Detail" key="expFor" />
          <GridColumn field="docFlag" title="Doc" key="docFlag" cells={{ data: PdfCell }} />
          <GridColumn field="invCosPol" title="Code" key="invCosPol" />
          <GridColumn cell={ActionCell} width="100" />
        </Grid>
      </section>
      <EditExpenseModal
        expense={selectedExpense}
        historicalExpense={historicalExpenses}
        opened={editModalOpened}
        setOpened={setEditModalOpened}
        saleStatus={saleStatus}
        vehRecId={vehRecId}
        stockNum={stockNum}
        reloadGrid={reload}
      />
    </>
  );
};
