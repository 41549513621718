// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import ButtonLinkCell from '@/mui/components/dataTable/cells/ButtonLinkCell';
// utils
import { dateCellDefaultFmt, datetimeCellGetter, stateCellFmt } from '@/mui/components/dataTable/cellFormatUtils';
import { RepoCompany } from '@/interfaces/RepoCompany';
// interfaces

export const tableColumns: MuiColumnDef<RepoCompany>[] = [
  {
    field: 'company',
    headerName: 'Repo Company',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1,
    minWidth: 120,
    valueFormatter: stateCellFmt,
  },
  {
    field: 'officePhone',
    headerName: 'Phone',
    minWidth: 150,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'fax',
    headerName: 'Fax',
    minWidth: 150,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'insuranceExpired',
    headerName: 'Ins. Expires',
    minWidth: 170,
    type: 'datetime',
    valueFormatter: dateCellDefaultFmt,
    valueGetter: datetimeCellGetter,
    align: 'left',
  },
  {
    field: 'bondExpired',
    headerName: 'Bond Expires',
    minWidth: 170,
    type: 'datetime',
    valueFormatter: dateCellDefaultFmt,
    valueGetter: datetimeCellGetter,
    align: 'left',
  },
  {
    field: 'licenseExpired',
    headerName: 'Lic. Expires',
    minWidth: 170,
    type: 'datetime',
    valueFormatter: dateCellDefaultFmt,
    valueGetter: datetimeCellGetter,
    align: 'left',
  },
  {
    field: 'recId',
    headerName: 'Actions',
    renderCell: ButtonLinkCell,
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
  },
];
