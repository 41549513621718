import React from 'react';
import styles from './DmsCheckbox.module.scss';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checkboxLabel?: string;
  containerStyle?: React.CSSProperties;
}

export const DmsCheckbox: React.FC<Props> = (props) => {
  const { checkboxLabel, containerStyle = {}, ...restProps } = props;
  return (
    <div className={styles.container} style={containerStyle}>
      <input className={styles.checkbox} type="checkbox" {...restProps} />
      <label className={styles.label}>{checkboxLabel}</label>
    </div>
  );
};
