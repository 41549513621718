import { CSSProperties } from "react";
import { z } from "zod";

export const AlertType = z.enum(["recommendation", "error", "warning", "notice", "other"]);
export type AlertType = z.infer<typeof AlertType>;

export class Alert {
  constructor(public type: AlertType, public message: string) {}
}

export class AlertConfig {
  color: CSSProperties["backgroundColor"];
  /** @deprecated - use Mui.alpha(color, 0.1) */
  pdfColor: CSSProperties["backgroundColor"];
  label: string;

  constructor(info: AlertConfig) {
    // this.type = info.type;
    this.color = info.color;
    this.pdfColor = info.pdfColor;
    this.label = info.label;
  }
}
