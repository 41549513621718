import { UseFormReturn } from 'react-hook-form';
import { TimePickerChangeEvent } from '@progress/kendo-react-all';
// utils
import {
  ReportFrequency,
  ReportFrequencyKey,
  ERSFiletypeKey,
  ReportDateRangeKey,
  ReportDateRange,
  DayOfWeek,
  DayOfWeekKey,
  ERSFiletypeDbKey,
  ERSFiletype,
} from './default';
import { ICompanyERS } from './state';

// eslint-disable-next-line
export type ERSHookForm = UseFormReturn<ERSForm, any, ERSForm>;

class ERSBase<
  TempRunDays extends string | DayOfWeekKey[] = string,
  TempScheduleType extends number | ReportFrequencyKey = number,
  TempDateRangeType extends number | ReportDateRangeKey = number,
  TempRunTimeLocal extends string | Date = string,
  TempFiletype extends ERSFiletypeDbKey | ERSFiletypeKey = ERSFiletypeDbKey
> {
  constructor(
    /** Record ID for report - foreign key. */
    readonly reportRecId: number,
    /** Company ID - foreign key.
     * @note orig `compid`
     */
    public compId: number,
    /** List of recipient email addresses for report schedule.
     * @note map to array of email-strings on frontend
     * @todo convert to array of email-strings
     */
    public emailTo: string,
    /** File type a report should be sent as. */
    public fileType: TempFiletype,
    /** Frequency of scheduled email - daily, weekly or monthly, represented as enum.
     * 1=Monthly, 2=Weekly, 3=Daily
     * @note map number to ReportFrequency
     * @todo inaccurate name - should be `emailFrequency`.
     */
    public scheduleType: TempScheduleType,
    /** Time of day when email automation runs, in local time - NOT UTC.
     * Format: "21:30:00"
     * @note convert to { hr: number; min: number } i.e. { hr: 23, min: 54 }
     * @note orig `runTime` - '08:30 am',
     */
    public runTimeLocal: TempRunTimeLocal | null,
    /** Days of the week which a weekly report should run.
     * Currently a string in the form of comma-separated emails.\
     * Format: "Mon,Wed,Thu,Sat"
     * @todo convert to `DayOfWeekKey[]`
     */
    public runDays: TempRunDays,
    /** Day of the month which a monthly report should run (1-28). */
    public runDate: number,
    /** If `true`, override `runDate` (used on backend). */
    public runLastDayOfMonth: boolean,
    /** Date-range a report should draw from.
     * @note map to ReportDateRange on frontend
     * @note orig dateRangeType_List - ReportDateRange
     */
    public dateRangeType: TempDateRangeType,
    /** Number (X) of days offset from start of date range.
     * @todo convert to number
     */
    public xDaysPrior: number,
    /** Is this schedule visible */
    public isVisible: boolean,
    /** Unsure how this is used */
    public filenamePrefix: string
  ) {}
}
/** ## Email report schedule parameters, as they are represented in the database currently
 * @todo fix data types as they are stored in the database. Several `boolean` and `number` types that are in `string` form.
 */
export class ERSDb extends ERSBase {
  /** Record ID for email in table. */
  readonly recId: number;
  /** Organization ID - foreign key.
   * @note orig `orgid`
   */
  readonly orgId: number;
  /** Template name of report - from f.k. `reportRecId`. */
  public reportName: string;
  /** Name of report set by org - from f.k. `reportRecId`.
   * @note used for display, this should be converted to `${reportNameOrg} -${recId}`.
   */
  public reportNameOrg: string;
  /** Can/should report use a 'date-range'.
   * @note orig `useDateRange`
   * @deprecated not used for this form
   */
  public reportUseDateRange?: boolean;

  constructor(
    ersBase: ERSBase & {
      recId: number;
      orgId: number;
      reportName: string;
      reportNameOrg: string;
      reportUseDateRange?: boolean;
    }
  ) {
    super(
      ersBase.reportRecId,
      ersBase.compId,
      ersBase.emailTo,
      ersBase.fileType,
      ersBase.scheduleType,
      ersBase.runTimeLocal,
      ersBase.runDays,
      ersBase.runDate,
      ersBase.runLastDayOfMonth,
      ersBase.dateRangeType,
      ersBase.xDaysPrior,
      ersBase.isVisible,
      ersBase.filenamePrefix
    );
    this.recId = ersBase.recId;
    this.orgId = ersBase.orgId;
    this.reportName = ersBase.reportName;
    this.reportNameOrg = ersBase.reportNameOrg;
    this.reportUseDateRange = ersBase.reportUseDateRange;
  }

  /** ### Create an instance of ERS-Db from ERS-Fmt */
  static fromERSFmt(ersFmt: ERSFmt, orgId: ERSDb['orgId']): ERSDb {
    const ersBase = ERSForm.revertToBaseTypes(ersFmt);

    const ersDbType: ERSDb = {
      ...ersFmt,
      ...ersBase,
      orgId,
    };

    return new ERSDb(ersDbType);
  }
}

/** ## Email report schedule parameters, as they are represented in the frontend
 * @note This is a temporary class for converting properties to their correct types.
 *
 * Fields are added as types are converted and used on the frontend.\
 * Fields are removed as types are corrected on the backend.
 * @todo convert `emailTo` to array of email-strings
 */
export class ERSForm extends ERSBase<
  DayOfWeekKey[],
  ReportFrequencyKey,
  ReportDateRangeKey,
  Date,
  ERSFiletypeKey
> {
  constructor(ersBase: ERSBase) {
    const { fileType, scheduleType, runDays, runTimeLocal, dateRangeType } =
      ERSForm.convertToFormTypes(ersBase);

    super(
      ersBase.reportRecId,
      ersBase.compId,
      ersBase.emailTo,
      fileType,
      scheduleType,
      runTimeLocal,
      runDays,
      ersBase.runDate,
      ersBase.runLastDayOfMonth,
      dateRangeType,
      ersBase.xDaysPrior,
      ersBase.isVisible,
      ersBase.filenamePrefix
    );
  }

  /** ### To convert to ERS-Fmt, remove the report name properties */
  static fromFmt(ersFmt: ERSFmt): ERSForm {
    const { reportName, reportNameOrg, ...ersForm } = ersFmt;

    return ersForm;
  }

  static convertFiletype(filetypeDb: ERSBase['fileType']): ERSForm['fileType'] {
    const match = Object.entries(ERSFiletype).find(([_, v]) => v === filetypeDb);
    if (!match) {
      console.error(`ERSForm: Invalid filetype:`, filetypeDb);
    }
    const key = match ? match[0] : '';

    return ERSFiletype[key as ERSFiletypeKey] as ERSFiletypeKey;
  }
  static revertFiletype(filetypeForm: ERSFiletypeKey): ERSFiletypeDbKey {
    return ERSFiletype[filetypeForm];
  }

  static convertScheduleType(scheduleTypeDb: ERSBase['scheduleType']): ERSForm['scheduleType'] {
    if (scheduleTypeDb !== 0 && scheduleTypeDb !== null && !ReportFrequency[scheduleTypeDb]) {
      throw new Error(`scheduleTypeDb is outside of enum range: ${scheduleTypeDb}`);
    }
    return ReportFrequency[scheduleTypeDb] as ReportFrequencyKey;
  }
  static revertScheduleType(scheduleTypeDb: ERSForm['scheduleType']): ERSBase['scheduleType'] {
    return ReportFrequency[scheduleTypeDb];
  }

  /** ### Convert run time local (i.e. "08:30:00") */
  static convertRunTimeLocal(runTimeLocalDb: ERSBase['runTimeLocal']): ERSForm['runTimeLocal'] {
    if (runTimeLocalDb === null) return null;

    if (runTimeLocalDb.split(':').length !== 3) {
      throw new Error(`Invalid runTimeLocalDb: ${runTimeLocalDb}`);
    }
    // Extract 'hours' and 'minutes', ignore 'seconds'
    const [hrStr, minStr] = runTimeLocalDb.split(':') as [string, string, string];

    // Format: yy,mm,dd,h,m,s,ms
    const dt = new Date();

    const time = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      Number(hrStr),
      Number(minStr)
    );

    if (!time) {
      throw new Error(`'convertRunTimeLocal()' invalid time: ${time}`);
    }

    return time;
  }
  static revertRunTimeLocal(runTimeLocalForm: ERSForm['runTimeLocal']): ERSBase['runTimeLocal'] {
    if (runTimeLocalForm === null) return null;

    const hr = runTimeLocalForm.getHours();
    const min = runTimeLocalForm.getMinutes();

    return `${hr < 10 ? `0${hr}` : hr}:${min < 10 ? `0${min}` : min}:00`;
  }

  /** ### Take in comma-separated string of selected run-day-options, convert to array. */
  static convertRunDays(runDaysDb: ERSBase['runDays'] | null): ERSForm['runDays'] {
    if (runDaysDb === null || runDaysDb === '') {
      return [];
    } else {
      // convert to arr
      const runDaysArr = runDaysDb.split(',') as DayOfWeekKey[];
      // validate
      for (let idx = 0; idx < runDaysArr.length; idx++) {
        const dayOfWeek = runDaysArr[idx]!;
        if (!DayOfWeek[dayOfWeek]) {
          throw new Error(`Invalid day of week. Input: ${dayOfWeek}  Arr: ${runDaysArr}`);
        }
      }

      return runDaysArr;
    }
  }
  static revertRunDays(runDaysForm: ERSForm['runDays']): ERSBase['runDays'] {
    return runDaysForm.join();
  }

  static convertDateRangeType(dateRangeTypeDb: ERSBase['dateRangeType']): ERSForm['dateRangeType'] {
    // Convert date-range type
    if (dateRangeTypeDb !== 0 && dateRangeTypeDb !== null && !ReportDateRange[dateRangeTypeDb]) {
      throw new Error(`dateRangeTypeDb is outside of enum range: ${dateRangeTypeDb}`);
    }
    return ReportDateRange[dateRangeTypeDb] as ReportDateRangeKey;
  }
  static revertDateRangeType(dateRangeType: ERSForm['dateRangeType']): ERSBase['dateRangeType'] {
    return ReportDateRange[dateRangeType];
  }

  /** Convert time-of-day from state-format to form field */
  // static convertRunTimeLocal(timeOfDay: Date | null): Date | null {
  //   if (timeOfDay === null) return null;

  //   // Format: year,month,day,hours,minutes,seconds,ms
  //   const dt = new Date();

  //   const time = new Date(
  //     dt.getFullYear(),
  //     dt.getMonth(),
  //     dt.getDate(),
  //     timeOfDay.hr,
  //     timeOfDay.min
  //   );

  //   if (!time) {
  //     throw new Error(`'convertTimeOfDayToDate()' invalid time: ${timeOfDay}`);
  //   }

  //   return time;
  // }

  // /** Convert time-of-day from form-field-format to state */
  static convertRunTimeLocalKendo(runTimeLocalKendo: TimePickerChangeEvent): Date | null {
    return runTimeLocalKendo.value;
  }

  static convertToFormTypes(
    ersBase: ERSBase
  ): Pick<ERSForm, 'fileType' | 'scheduleType' | 'runDays' | 'runTimeLocal' | 'dateRangeType'> {
    return {
      fileType: ERSForm.convertFiletype(ersBase.fileType),
      scheduleType: ERSForm.convertScheduleType(ersBase.scheduleType),
      runDays: ERSForm.convertRunDays(ersBase.runDays),
      runTimeLocal: ERSForm.convertRunTimeLocal(ersBase.runTimeLocal),
      dateRangeType: ERSForm.convertDateRangeType(ersBase.dateRangeType),
    };
  }
  /** Convert all property types mapped for use in forms, back to their original type (as represented on the db) */
  static revertToBaseTypes(ersForm: ERSForm): ERSBase {
    return {
      ...ersForm,
      fileType: ERSForm.revertFiletype(ersForm.fileType),
      scheduleType: ERSForm.revertScheduleType(ersForm.scheduleType),
      runTimeLocal: ERSForm.revertRunTimeLocal(ersForm.runTimeLocal),
      runDays: ERSForm.revertRunDays(ersForm.runDays),
      dateRangeType: ERSForm.revertDateRangeType(ersForm.dateRangeType),
      isVisible: typeof ersForm.isVisible !== 'boolean' ? true : ersForm.isVisible,
      filenamePrefix: typeof ersForm.filenamePrefix !== 'string' ? '' : ersForm.filenamePrefix,
    };
  }

  static toPutPayload(ersForm: ERSForm): ERSPutReq {
    // Remove fields not accepted by the endpoint
    const { compId, reportRecId, ...ersFormPut } = ERSForm.revertToBaseTypes(ersForm);

    return ersFormPut;
  }

  static toPostPayload(ersForm: ERSForm): ERSPostReq {
    return ERSForm.revertToBaseTypes(ersForm);
  }
}

/** ## Email Report Schedule as its used for display purposes. */
export class ERSFmt extends ERSForm {
  /** Template name of report - from f.k. `reportRecId`. */
  readonly recId: number;
  /** Template name of report - from f.k. `reportRecId`. */
  readonly reportName: string;
  /** Name of report set by org - from f.k. `reportRecId`.
   * @note used for display, this should be converted to `${reportNameOrg} -${recId}`.
   */
  readonly reportNameOrg: string;

  constructor(ersDb: ERSDb) {
    super(ersDb);

    this.recId = ersDb.recId;
    this.reportName = ersDb.reportName;
    this.reportNameOrg = ersDb.reportNameOrg;
  }

  /** ### Create ERSFmt from ERSDb */
  static fromERSDb(ersDb: ERSDb): ERSFmt {
    return new ERSFmt(ersDb);
  }

  /** ### Create ERSFmt from selected-ers and the payload of the put request
   * - Convert payload values to 'form-compatible' values (from 'db' formƒ)
   */
  static fromPutPayload(ersFmt: ERSFmt, ersForm: ERSPutReq, orgId: ERSDb['orgId']): ERSFmt {
    // Convert back to db form type, for use in ERS-Fmt constructor
    return ERSFmt.fromERSDb({ ...ersFmt, ...ersForm, orgId });
  }
}

export interface ERSFetchAllRes {
  data: { companies: ICompanyERS[]; emailReportSchedules: ERSDb[] };
  total: number;
}

export type ERSPostReq = Omit<
  ERSDb,
  'reportName' | 'reportNameOrg' | 'reportUseDateRange' | 'orgId' | 'recId'
>;
export type ERSPostRes = { data: ERSDb };
export type ERSPutReq = Omit<ERSPostReq, 'reportRecId' | 'compId'>;
export type ERSPutRes = { data: string };
export type ERSDeleteRes = { data: string };
