// components
import AmtDueCell from '../tableCellComponents/AmtDueCell';
import GPSCell from '../tableCellComponents/GPSCell';
// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const iopColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('gpsCode', 'GPS', 80, GPSCell),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('iopDate', 'IOP Date', 120, 'date'),
  newColumnDef('iopSaleDate', 'IOP Sale Date', 120, 'date'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('amtDue', 'Amount Due', 140, AmtDueCell),
  newColumnDef('daysLate', 'Days Late', 120, 'number'),
  newColumnDef('code3', 'Lot', 80),
];
