import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
// data
import { SubviewInfo, SubviewKey, viewInfoMap } from './viewInfo';

export interface IAccountsViewCtx {
  // Route variables
  subview: SubviewKey;
  subviewInfo: SubviewInfo;
  // Request variables
  dbCt: number;
  setDbCt: (dbCt: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  // Modal variables
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  mappedCompaniesCt: number;
  setMappedCompaniesCt: (mappedCompaniesCt: number) => void;
}

const AccountsViewCtx = createContext<IAccountsViewCtx | null>(null);

export const AccountsViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Route variables
  const routeParams = useParams();
  // Request variables
  const [isLoading, setIsLoading] = useState(false);
  const [dbCt, setDbCt] = useState(0);
  const [mappedCompaniesCt, setMappedCompaniesCt] = useState(0);
  // Modal variables
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <AccountsViewCtx.Provider
      value={{
        // Route variables
        subview: routeParams['*']! as SubviewKey,
        get subviewInfo(): SubviewInfo {
          if (!this.subview) throw new Error(`Invalid subview: '${this.subview}'`);
          const viewInfoItem = viewInfoMap.get(this.subview);
          if (!viewInfoItem) throw new Error(`Invalid viewInfoItem- subview: '${this.subview}'`);

          return viewInfoItem;
        },
        // Request variables
        isLoading,
        setIsLoading,
        dbCt,
        setDbCt,
        // Modal variables
        isModalOpen,
        setIsModalOpen,
        mappedCompaniesCt,
        setMappedCompaniesCt,
      }}
    >
      {children}
    </AccountsViewCtx.Provider>
  );
};

export const useAccountsViewCtx = <T,>(selector: (state: IAccountsViewCtx) => T): T => {
  const ctx = useContext(AccountsViewCtx);
  if (!ctx) {
    throw new Error('useAccountsViewCtx must be used within AccountsViewProvider');
  }
  return selector(ctx);
};
