import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

const LcPaidField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("lcPaid"));
  return <CurrencyInput label="Paid" required horizontalLabel={false} {...fieldProps} />;
};
const LcWaivedField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("lcWaived"));
  return (
    <CurrencyInput
      label="Waived"
      required
      horizontalLabel={false}
      {...fieldProps}
      containerStyle={{ minWidth: undefined, width: undefined }}
    />
  );
};
/** ###  */
const LateChargeFields: FC = () => {
  const lcOwed = usePmtFormCtx((s) => s.formState.config.calculated.lcOwed);
  const isHidden = usePmtFormCtx((s) => s.formState.getFieldProps("lcWaived")).hidden; // @note can use any field that has `registerOn` for lc-fields

  if (isHidden) return <></>;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Grid container component="span" sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>
        Late Charges
      </Grid>

      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{ flex: 1.25, gap: "10px", alignItems: "center" }}
      >
        <LcPaidField />
        <LcWaivedField />

        <CurrencyInput label="Owed" readOnly horizontalLabel={false} value={lcOwed} />
      </Grid>
    </Grid>
  );
};

export default LateChargeFields;
