import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inventoryService } from '@/services/inventoryService';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
import OptionGrid from './checkInGrid/optionsGrid';
import styles from './CheckInOptions.module.scss';
import { OptionsItem } from '@/interfaces';

export const loadCheckInViewData = async (
  invRecId: number | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setOptionsState: (optionsState: OptionsItem[]) => void,
  setOptionsList: (optionsList: OptionsItem[]) => void
) => {
  if (!invRecId) {
    return;
  }

  try {
    setIsLoading(true);
    const initVehicleOptions = await inventoryService.getVehicleOptionsList(invRecId);

    const validatedOptionsList = initVehicleOptions.map(
      (v) =>
        new OptionsItem(
          v.orgId,
          v.locIdAfc,
          v.recId,
          v.compIdAfc,
          v.compIdSolutions,
          v.locIdSolutions,
          v.vehRecId,
          v.invId,
          v.timeUploaded,
          v.userRecId,
          v.sOrder,
          v.option,
          v.verified,
          v.display,
          v.vin,
          v.compId,
          v.locId
        )
    );

    setOptionsState(validatedOptionsList);
    setOptionsList(validatedOptionsList);

    setIsLoading(false);
  } catch (error) {
    console.error(error);
  }
};

const VehicleOptions = () => {
  const { invRecId } = useParams();
  const setIsLoading = useInventoryDetailCtx((s) => s.setIsLoading);
  const setOptionsState = useInventoryDetailCtx((s) => s.setOptionsState);
  const setOptionsList = useInventoryDetailCtx((s) => s.setOptionsList);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);

  useEffect(() => {
    loadCheckInViewData(Number(invRecId), setIsLoading, setOptionsState, setOptionsList);
  }, [inventoryVehicle.vin]);

  return (
    <div className={styles.container}>
      <div className={styles.vehicleHeaderCard}>
        <span className={styles.subtitle}>Vehicle Options</span>
      </div>
      <OptionGrid />
    </div>
  );
};

export default VehicleOptions;
