import { ReactNode } from "react";
import { toast } from "react-toastify";
import axiosStatic from "axios";
// state
import { store } from "@/store/store";
// utils
import { config } from "@/config";
import { currentEnv } from "@/env";

export const axios = axiosStatic.create({ baseURL: config.apiUrl });

const getToken = () => localStorage.getItem("dmsToken");

axios.interceptors.request.use(async (requestConfig) => {
  try {
    const state = store.getState();
    const paymentCompId = state.oldPayment?.compId; // once removed from alpha, this will be unnecessary
    // @todo remove post-alpha: `selectedCompId` should be managed with state
    requestConfig.headers["dms-selected-comp-id"] =
      localStorage.getItem("selectedCompId") ?? paymentCompId ?? "";
    // @note orig - keeping for reference: requestConfig.headers['dms-selected-comp-id'] = compId ?? paymentCompId ?? '';

    // required for authorizing requests with identity server
    requestConfig.headers["X-CSRF"] = "1";
    if (currentEnv.isStandalone) {
      requestConfig.withCredentials = true;
    } else {
      const authToken = getToken();
      requestConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }
  } catch (err: any) {
    console.error("Error in request interceptor", err);
    if (currentEnv.isStandalone && err?.response?.status === 401) {
      window.location.replace(`${config.apiUrl}/bff/login?returnUrl=${window.location.href}`);
    }
  }
  return requestConfig;
});

axios.interceptors.response.use(async (response) => {
  return response;
});

export class AxiosService {
  private static staticAxios = axios;

  protected get axios() {
    return AxiosService.staticAxios;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  displayErrors = (msg: ReactNode, err: Error) => {
    console.error(err);
    // @note is this correct? seems like it should check if standalone
    if (currentEnv.key !== "development") {
      window.toastr.error(msg, "Error");
    } else {
      toast.error(msg);
    }
  };
}
