export class PostedSalesDb {
  constructor(
    public appRecId: number,
    public manLocation: number,
    public defDownBal: number | null,
    public defDownDaysLate: number | null,
    public hasSpecialPmts: number | null,
    public cod: number | null,
    public totalDown: number | null,
    public isCobuyer: boolean,
    public orgId: number,
    public locId: number,
    public term: string | null,
    public compAppId: number,
    public compId: number,
    public stockNum: string | null,
    public saleStatus: string | null,
    public saleType: string | null,
    public bNames: string | null,
    public saleDate: Date,
    public invDays: number,
    public vehRecId: number,
    public buyerRecId: number,
    public cobuyRecId: number,
    public vehYmm: string | null,
    public shortName: string | null,
    public code3: string | null,
    public region: string | null,
    public companyName: string | null,
    public soldPrice: number | null,
    public ror: number,
    public totalCost: number,
    public realDown: number,
    public vin: string | null,
    public webYmm: string | null
  ) {}

  static new(newPostedSalesRow: PostedSalesDb): PostedSalesDb {
    return new PostedSalesDb(
      newPostedSalesRow.appRecId,
      newPostedSalesRow.manLocation,
      newPostedSalesRow.defDownBal,
      newPostedSalesRow.defDownDaysLate,
      newPostedSalesRow.hasSpecialPmts,
      newPostedSalesRow.cod,
      newPostedSalesRow.totalDown,
      newPostedSalesRow.isCobuyer,
      newPostedSalesRow.orgId,
      newPostedSalesRow.locId,
      newPostedSalesRow.term,
      newPostedSalesRow.compAppId,
      newPostedSalesRow.compId,
      newPostedSalesRow.stockNum,
      newPostedSalesRow.saleStatus,
      newPostedSalesRow.saleType,
      newPostedSalesRow.bNames,
      newPostedSalesRow.saleDate,
      newPostedSalesRow.invDays,
      newPostedSalesRow.vehRecId,
      newPostedSalesRow.buyerRecId,
      newPostedSalesRow.cobuyRecId,
      newPostedSalesRow.vehYmm,
      newPostedSalesRow.shortName,
      newPostedSalesRow.code3,
      newPostedSalesRow.region,
      newPostedSalesRow.companyName,
      newPostedSalesRow.soldPrice,
      newPostedSalesRow.ror,
      newPostedSalesRow.totalCost,
      newPostedSalesRow.realDown,
      newPostedSalesRow.vin,
      newPostedSalesRow.webYmm
    );
  }
}

export type IPostedSalesDb = PostedSalesDb;
