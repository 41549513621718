import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// kendo
import { GridCustomCellProps } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Icons } from '@/components/icons';
import { Column } from '@/components/table/TableInterface';
// state
import { useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import {
  getAvailableVehicles,
  getSaleData,
  getSaleVehicle,
} from '@/features/Sales/salesActionCreator';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { salesService } from '@/services/salesService';
// interfaces
import { Vehicle } from '@/interfaces/Sales';
import { IDefaultFormValues } from "@/features/Sales/detailView/WholesaleView/VehicleTab/default";

const bodyTypeData = ['2 door', '4 door', 'Coupe', 'Hatch Back', 'Sedan', 'Truck'];
const defaultFormValues = {
  stockNum: '',
  vin: '',
  year: '',
  make: '',
  model: '',
  color: '',
  bodyType: '',
  vehicleType: '',
  drivetrain: '',
  transmission: 'Automatic',
  milesOut: 0,
  notActual: false,
  exceedsLimits: false,
  printExempt: false,
  tmu: false,
  salvageTitle: false,
  frameDamage: false,
  unibody: false,
  airbag: false,
  other: false,
  otherAlert: '',
  plateNumber: '',
  dateIssued: '',
  dateExpires: '',
  price: 0,
  downPayment: 0,
  downPaymentPercentage: 0,
  schedule: 'Weekly',
  payment: 0,
  months: 0,
};

/** @deprecated convert to context */
export const useVehicleAndTempTag = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);

  const compId = useAuthSelector((s) => s.compId);
  const saleData = useSalesSelector((s) => s.saleData);
  const availableVehicles = useSalesSelector((s) => s.availableVehicles);
  const selectedVehicleId = useSalesSelector((s) => s.selectedVehicleId);
  const saleVehicle = useSalesSelector((s) => s.saleVehicle);

  const [showVehicleForm, setShowVehicleForm] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IDefaultFormValues | undefined>(undefined);
  const [selectVehicleLoading, setSelectVehicleLoading] = useState(false);
  const [updateVehicleLoading, setUpdateVehicleLoading] = useState(false);
  const [listData, setListData] = useState<IDefaultFormValues[]>(availableVehicles);

  const { control, watch, reset, handleSubmit } = useForm<IDefaultFormValues>({
    defaultValues: defaultFormValues,
  });

  const resetVehicleFields = (vehicle: IDefaultFormValues) => {
    reset({
      stockNum: vehicle.stockNum,
      vin: vehicle.vin,
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      color: vehicle.color,
      bodyType: vehicle.bodyStyle,
      transmission: 'Automatic',
      price: vehicle.sugPrice,
      downPayment: vehicle.sugDown,
      downPaymentPercentage: vehicle.sugPercent,
      schedule: vehicle.sugTerms,
      payment: vehicle.sugPmt,
      months: vehicle.sugTerm,
      tmu: vehicle.alerT1,
      salvageTitle: vehicle.alerT2,
      frameDamage: vehicle.alerT3,
      unibody: vehicle.alerT4,
      airbag: vehicle.alerT6,
      other: vehicle.alerT5,
      otherAlert: vehicle.alerT5N,
      plateNumber: vehicle.tempTagNum,
      dateExpires: dayjs(vehicle.tempTagExp).format('YYYY-MM-DD'),
      dateIssued: dayjs(vehicle.tempTagIss).format('YYYY-MM-DD'),
      milesOut: Number(vehicle.milesOut),
    });
  };

  const SelectCell: FC<GridCustomCellProps> = ({ dataItem }) => {
    const vehicle: IDefaultFormValues = dataItem;

    return (
      <td>
        <Button
          secondary
          label="Select"
          fillMode="outline"
          loading={selectVehicleLoading}
          onClick={async () => {
            setSelectVehicleLoading(true);
            try {
              setSelectedVehicle(vehicle);
              resetVehicleFields(vehicle);
              await salesService
                .postSelectVehicle({ appRecId: appRecId, vehRecId: vehicle!.recId! })
                .then((res) => {
                  dispatch(salesActions.setSaleData(res));
                  dispatch(salesActions.setSaleVehicle(vehicle));
                  dispatch(salesActions.setSelectedVehicleId(vehicle.recId));
                });
              dispatch(getAvailableVehicles(compId!));
              setShowVehicleForm(true);
            } catch {
              toast.error('Unable to select vehicle');
            } finally {
              setSelectVehicleLoading(false);
            }
          }}
        />
      </td>
    );
  };

  const updateVehicle = async (data: IDefaultFormValues) => {
    setUpdateVehicleLoading(true);
    try {
      await salesService.updateVehicle({
        recId: selectedVehicle!.recId,
        sugPrice: data.price,
        stockNum: data.stockNum,
        sugTerm: data.months,
        sugTerms: data.schedule,
        sugPmt: data.payment,
        sugDown: data.downPayment,
        sugPercent: data.downPaymentPercentage,
        alerT1: data.tmu,
        alerT2: data.salvageTitle,
        alerT3: data.frameDamage,
        alerT4: data.unibody,
        alerT6: data.airbag,
        alerT5: data.other,
        alerT5N: data.otherAlert,
        tempTagExp:
          data.dateExpires && data.dateExpires !== 'Invalid Date' ? data.dateExpires : null,
        tempTagIss: data.dateIssued && data.dateIssued !== 'Invalid Date' ? data.dateIssued : null,
        tempTagNum: data.plateNumber,
        milesOut: data.milesOut ? data.milesOut.toFixed(0) : Number(0).toFixed(),
      });
      dispatch(getAvailableVehicles(compId!));
      dispatch(getSaleData(appRecId));
      toast.success('Vehicle Updated');
    } catch {
      toast.error('There was an error submitting this vehicle');
    } finally {
      setUpdateVehicleLoading(false);
    }
  };

  const handleSearchFilter = (searchValue: string) => {
    const filterColumns: (keyof Vehicle)[] = ['stockNum', 'vin', 'year', 'make', 'model', 'color'];
    if (searchValue) {
      const lcSearchValue = searchValue.toLowerCase();
      setListData(
        availableVehicles.filter((row) => {
          return filterColumns.find((fc) =>
            row[fc]?.toString()?.toLowerCase()?.includes(lcSearchValue)
          );
        })
      );
    } else {
      setListData(availableVehicles);
    }
  };

  const vehicleColumns: Column[] = [
    {
      field: 'stockNum',
      title: 'Stock #',
    },
    {
      field: 'scOpen',
      title: 'Sc',
      cell: (props: any) => {
        const { scOpen } = props.dataItem;
        return (
          <td className="k-table-td">
            <span>{scOpen ? <Icons.Service style={{ width: 14, height: 14 }} /> : '--'}</span>
          </td>
        );
      },
    },
    {
      field: 'year',
      title: 'Year',
    },
    {
      field: 'make',
      title: 'Make',
    },
    {
      field: 'model',
      title: 'Model',
    },
    {
      field: 'color',
      title: 'Color',
    },
    {
      field: 'vin',
      title: 'Vin',
    },
    {
      field: 'selectButton',
      title: ' ',
      cells: { data: SelectCell },
    },
  ];

  useEffect(() => {
    const vehicle = availableVehicles.find((value) => value.stockNum === saleData.sale!.stocknum!);
    if (vehicle) {
      setSelectedVehicle(vehicle);
      reset({
        stockNum: vehicle.stockNum,
        vin: vehicle.vin,
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        color: vehicle.color,
        bodyType: vehicle.bodyStyle,
        transmission: 'Automatic',
        price: vehicle.sugPrice,
        downPayment: vehicle.sugDown,
        downPaymentPercentage: vehicle.sugPercent,
        schedule: vehicle.sugTerms,
        payment: vehicle.sugPmt,
        months: vehicle.sugTerm,
        tmu: vehicle.alerT1,
        salvageTitle: vehicle.alerT2,
        frameDamage: vehicle.alerT3,
        unibody: vehicle.alerT4,
        airbag: vehicle.alerT6,
        other: vehicle.alerT5,
        otherAlert: vehicle.alerT5N,
        plateNumber: vehicle.tempTagNum,
        dateExpires: dayjs(vehicle.tempTagExp).format('YYYY-MM-DD'),
        dateIssued: dayjs(vehicle.tempTagIss).format('YYYY-MM-DD'),
        milesOut: Number(vehicle.milesOut),
      });
      setShowVehicleForm(true);
      salesActions.setSelectedVehicleId(vehicle.recId);
    }
  }, []);

  useEffect(() => {
    if (selectedVehicleId) {
      dispatch(getSaleVehicle(selectedVehicleId));
    } else {
      salesActions.setSaleVehicle(undefined);
    }
  }, [setSelectedVehicle, setShowVehicleForm, selectedVehicleId, availableVehicles]);

  useEffect(() => {
    if (saleVehicle) {
      setShowVehicleForm(true);
      setSelectedVehicle(saleVehicle);
      resetVehicleFields(saleVehicle);
    } else {
      setShowVehicleForm(false);
    }
  }, [JSON.stringify(saleVehicle)]);

  return {
    vehicleColumns,
    showVehicleForm,
    setShowVehicleForm,
    control,
    watch,
    selectedVehicle,
    bodyTypeData,
    handleSubmit,
    updateVehicle,
    selectVehicleLoading,
    updateVehicleLoading,
    listData,
    handleSearchFilter,
  };
};
