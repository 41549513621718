import { z } from "zod";
// validation
import { BasePmtFormSchema } from "./formValidation";
import { PaymentReqType, PmtContext, ZCardProcessorName } from "@/enums/payment";
import { paymentFormCfg } from "./formConfig";
import { PmtExternalValues } from "./formCalculations/interfaces";
import { PmtReq } from "./reqValidation";
// utils
import { getCardProcessorInt, getPaymentProviders } from "@/utils/helpers/payment";
import { FormOut } from "@/utils/forms/useValidatedForm/fieldConfig/interfaces";
import { calcCcAch, calcChangeDue, calcLcOwed } from "./formCalculations/readonlyValues";
import { getInitials } from "@/utils/helpers/general";
import { type Nullish } from "@/interfaces/utilityTypes";
// interfaces
import { type IPaymentProviderConfigRes, type PaymentDataRes } from "@/interfaces/payment";
import { type SavedPmtMethod } from "@/interfaces/CreditCard";
import { type EmployeeField } from "@/interfaces/System";
import { type AuthAppState } from "@/features/auth/authSlice";
import { type FormConfigOutput } from "@/utils/forms/useValidatedForm/fieldConfig/callbacks";

export const getEnabledPmtMethods = (
  pmtProcessorCfg: IPaymentProviderConfigRes | null,
  validPmtMethods: Map<"REPAY" | "OpenEdge" | "BlytzPay" | "Cliq", SavedPmtMethod[]> | null,
  form: Pick<FormOut<typeof BasePmtFormSchema>, "provider" | "paidBy">
): SavedPmtMethod[] | null => {
  if (!pmtProcessorCfg || !form.provider || !validPmtMethods) return null;

  const enabledProviders = getPaymentProviders(pmtProcessorCfg);
  const allEnabledPmtMethodsArr = validPmtMethods.get(form.provider) ?? null;

  if (!enabledProviders || !enabledProviders.includes(form.provider) || !allEnabledPmtMethodsArr)
    return null;

  const enabledPmtMethodsArr = allEnabledPmtMethodsArr.filter(
    (pm) =>
      // @todo apply correct enum to mpdType
      (pm.mpdType === "CreditCard" && form.paidBy === "Credit Card") ||
      // @todo apply correct enum to mpdType and use the `ACH` option
      (pm.mpdType !== "CreditCard" && form.paidBy === "ACH")
  );

  return enabledPmtMethodsArr.length > 0 ? enabledPmtMethodsArr : null;
};
export const getEnabledPmtMethodsFormCalc = (
  enabledPmtProviders: ZCardProcessorName[] | undefined,
  validPmtMethods: Map<ZCardProcessorName, Pick<SavedPmtMethod, "mpdType">[]> | undefined | null,
  form: FormOut<typeof BasePmtFormSchema>
) => {
  if (!enabledPmtProviders || !form.provider || !validPmtMethods) return null;

  const allEnabledPmtMethodsArr = validPmtMethods.get(form.provider) ?? null;

  if (
    !enabledPmtProviders ||
    !enabledPmtProviders.includes(form.provider) ||
    !allEnabledPmtMethodsArr
  )
    return null;

  const enabledPmtMethodsArr = allEnabledPmtMethodsArr.filter(
    (pm) =>
      // @todo apply correct enum to mpdType
      (pm.mpdType === "CreditCard" && form.paidBy === "Credit Card") ||
      // @todo apply correct enum to mpdType and use the `ACH` option
      (pm.mpdType !== "CreditCard" && form.paidBy === "ACH")
  );

  return enabledPmtMethodsArr.length > 0 ? enabledPmtMethodsArr : null;
};

// @todo convert to generic inferred type
type SafeParsePmtForm = z.SafeParseReturnType<any, BasePmtFormSchema>;
type PmtFormCfgValues = FormConfigOutput<typeof paymentFormCfg>;

export const formToReqBody = (
  {
    validatedForm,
    external,
    calculated,
  }: {
    // @todo clean up types
    validatedForm: SafeParsePmtForm;
    external: PmtFormCfgValues["external"];
    calculated: PmtFormCfgValues["calculated"];
  },
  selectedPmtMethod: SavedPmtMethod | null,
  employee: EmployeeField | null | undefined,
  paymentData: PaymentDataRes | null
) => {
  const form = validatedForm.data;
  if (!form || !employee || !paymentData) return null;

  const { employeeRecId, paidBy, provider } = form;
  const { isCc, isAch, isCcOrAch, convFee, changeDue } = calculated;
  const { isPrincipalOnly, lcDue, isDownPmt, isWsPmt } = external;

  const billFirstName = isWsPmt ? "x" : form.billFirstName?.trim() ?? null; // @todo fix on backend - either 1. Allow `billFirstName = null` if wholesale pmt (i.e. `colType = "WS"`); 2. Add new field for wholesaleAccountName
  const billLastName = form.billLastName?.trim() ?? null;
  const payToFromFmt =
    billFirstName && billLastName ? `${billFirstName} ${billLastName}`.trim() : null; // @todo remove field on frontend payload, calculate on backend
  const payToFrom = isWsPmt ? billLastName : payToFromFmt; // @todo remove field on frontend payload, calculate on backend
  const pmtContext: PmtContext = isPrincipalOnly
    ? "NEW_UI_MISC"
    : isDownPmt
    ? "SALE_CASHDOWN"
    : "NEW_UI";

  return {
    ...form,
    processorType: getCardProcessorInt(provider),
    userRecId: employeeRecId,
    pmtType: paidBy,
    isAch,
    achConvFee: isAch ? convFee : null,
    ccConvFee: isCc ? convFee : null,
    change: changeDue,
    isNewCard: isCcOrAch ? !form.useSavedPaymentMethod : false,
    lcOwed: calcLcOwed({ ...form, lcDue }),
    pmtContext,
    useSavedPaymentMethod: isCcOrAch ? form.useSavedPaymentMethod : false,
    waiveAchConvFee: !!form.waiveConvFee,
    waiveCcConvFee: !!form.waiveConvFee,
    convFee,
    saveCard: isCcOrAch && !form.useSavedPaymentMethod ? form.saveCard : false,

    billAddress: form.billAddress ?? (selectedPmtMethod?.address || null),
    billCity: form.billCity ?? (selectedPmtMethod?.city || null),
    billState: form.billState ?? (selectedPmtMethod?.state || null),
    billZip: form.billZip ?? (selectedPmtMethod?.zip || null),
    billEmail: form.billEmail ?? selectedPmtMethod?.email,
    billFirstName,
    billLastName,
    payToFrom,

    appRecId: paymentData.appRecId,
    colRecId: paymentData.colRecId,
    colType: paymentData.colType,
    compId: paymentData.compId,
    emailB: paymentData.buyerEmail,
    emailC: paymentData.cobuyerEmail,
    locId: paymentData.locId,
    orgId: paymentData.orgId,
    stockNum: paymentData.stockNum,
    token: form.mpd?.token ?? null,
    paymentType: isPrincipalOnly ? PaymentReqType.enum.PrinOnly : "",
    isPrincipalOnly,

    // User fields
    takenBy: getInitials(employee?.shortName ?? ""),
    userEmail: employee?.userId,
    userShortName: employee?.shortName,
  };
};

export const formToReqBodyMisc: (input: {
  formValues: FormOut<typeof BasePmtFormSchema> | undefined;
  externalValues: PmtExternalValues;
  employee: EmployeeField | null | undefined;
  selectedPmtMethod: any | null;
  authState: AuthAppState;
  convFee: number | null;
}) => Nullish<z.input<typeof PmtReq>> | null = (values) => {
  if (!values.employee || !values.formValues || !values.externalValues) return null;
  const { formValues, externalValues, employee, authState, selectedPmtMethod } = values;

  const { isCc, isAch, isCcOrAch } = calcCcAch(formValues);
  const { lcDue } = externalValues;

  return {
    ...formValues,
    isAch,
    processorType: getCardProcessorInt(values.formValues.provider),
    userRecId: formValues.employeeRecId,
    pmtType: formValues.paidBy,
    achConvFee: isAch ? values.convFee : null,
    ccConvFee: isCc ? values.convFee : null,
    change: calcChangeDue(formValues, { isPrincipalOnly: false }),
    isNewCard: isCcOrAch ? !formValues.useSavedPaymentMethod : false,
    lcOwed: calcLcOwed({ ...formValues, lcDue }),
    pmtContext: "NEW_UI_MISC",
    useSavedPaymentMethod: isCcOrAch ? formValues.useSavedPaymentMethod : false,
    waiveAchConvFee: !!formValues.waiveConvFee,
    waiveCcConvFee: !!formValues.waiveConvFee,
    convFee: values.convFee,
    saveCard: isCcOrAch && !formValues.useSavedPaymentMethod ? formValues.saveCard : false,
    billEmail: formValues.billEmail ?? selectedPmtMethod?.email,
    billAddress: formValues.billAddress ?? selectedPmtMethod?.address,
    billCity: formValues.billCity ?? selectedPmtMethod?.city,
    billState: formValues.billState ?? selectedPmtMethod?.state,
    billZip: formValues.billZip ?? selectedPmtMethod?.zip,

    colType: formValues.carPmt >= 0 ? "MI" : "MO",
    compId: authState.compId,
    locId: authState.locId,
    orgId: authState.orgId,
    // emailB: formValues.billEmail ?? selectedPmtMethod?.email,
    token: formValues.mpd?.token ?? null,
    paymentType: "",

    // User fields
    takenBy: getInitials(employee?.shortName ?? ""),
    userEmail: employee?.userId,
    userShortName: employee?.shortName,
  };
};
