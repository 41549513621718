const filterFields = [
  'appRecId',
  'bNames',
  'buyerRecId',
  'cobuyRecId',
  'code3',
  'companyName',
  'compAppId',
  'compId',
  'invDays',
  'isCobuyer',
  'locId',
  'manLocation',
  'orgId',
  'purPrice',
  'region',
  'ror',
  'saleDate',
  'saleStatus',
  'saleType',
  'shortName',
  'soldPrice',
  'stockNum',
  'totExp',
  'vehRecId',
  'vehYmm',
  'vin',
] as const;

export type WholesalesFilterKey = (typeof filterFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const wholesalesFilterFields: WholesalesFilterKey[] = [...filterFields];

const sortFields = [
  'appRecId',
  'bNames',
  'buyerRecId',
  'cobuyRecId',
  'code3',
  'companyName',
  'compAppId',
  'compId',
  'invDays',
  'isCobuyer',
  'locId',
  'manLocation',
  'orgId',
  'purPrice',
  'region',
  'ror',
  'saleDate',
  'saleStatus',
  'saleType',
  'shortName',
  'soldPrice',
  'stockNum',
  'totExp',
  'vehRecId',
  'vehYmm',
  'vin',
] as const;

export type WholesalesSortKey = (typeof sortFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const wholesalesSortFields: WholesalesSortKey[] = [...sortFields];
