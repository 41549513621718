import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// kendo
import { GridCustomCellProps } from '@progress/kendo-react-all';
import { Icons } from '@/components/icons';
import { KendoDateCell } from '@/components/kendoCells/KendoDateCell';
import { KendoPhoneNumberCell } from '@/components/kendoCells/KendoPhoneNumberCell';
import { Column } from '@/components/table/TableInterface';
// utils
import { salesService } from '@/services/salesService';
// interfaces
import { CryptoSignSignature } from '@/interfaces';
// style
import styles from './CryptoSign.module.scss';

const PrintCryptoSign: FC<GridCustomCellProps> = (props) => (
  <td>
    <div className={styles.printContainer}>
      <Icons.Print
        className={styles.print}
        onClick={() => window.open(props.children?.toString())}
      />
    </div>
  </td>
);

const DateTimeCell: FC<GridCustomCellProps> = (props) => (
  <KendoDateCell dateFormat="MM/DD/YYYY hh:mm A" {...props} />
);

const cryptoSignColumns: Column[] = [
  {
    field: 'signatureRequestId',
    title: 'ID',
  },
  {
    field: 'signatureRequestCreatedUtc',
    title: 'Created',
    cells: { data: DateTimeCell },
  },
  {
    field: 'signatureRequestCompletedUtc',
    title: 'Completed',
    cells: { data: DateTimeCell },
  },
  {
    field: 'signatureRequestStatus',
    title: 'Status',
  },
  {
    field: 'order',
    title: 'Order',
    width: 65,
  },
  {
    field: 'signerStatus',
    title: 'Signer Status',
  },
  {
    field: 'dateSignedUtc',
    title: 'Date Signed',
    cells: { data: DateTimeCell },
  },
  {
    field: 'phone',
    title: 'Phone',
    cells: { data: KendoPhoneNumberCell },
    width: 130,
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'documentDownloadLink',
    title: 'Doc',
    cells: { data: PrintCryptoSign },
    width: 60,
  },
];

export const useCryptoSign = () => {
  const params = useParams();
  const appRecId = Number(params.id);
  const [getCryptoSignSignaturesLoading, setGetCryptoSignSignaturesLoading] = useState(false);
  const [cryptoSignSignatures, setCryptoSignSignatures] = useState<CryptoSignSignature[]>([]);

  const getCryptoSignSignatures = async () => {
    setGetCryptoSignSignaturesLoading(true);
    try {
      const response = await salesService.getCryptoSignSignatures(appRecId);
      setCryptoSignSignatures(response);
    } finally {
      setGetCryptoSignSignaturesLoading(false);
    }
  };

  useEffect(() => {
    getCryptoSignSignatures();
  }, []);

  return { appRecId, getCryptoSignSignaturesLoading, cryptoSignSignatures, cryptoSignColumns };
};
