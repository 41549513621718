import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import SectionColumn from '@/mui/components/form/Section/SectionColumn';
// kendo
import { Checkbox } from '@/components/checkbox/Checkbox';
import { TextInput } from '@/components/inputs/text/TextInput';
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// components
// state
import { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// interfaces
import { InsuranceCoverageOption } from '@/enums/general';

/** ###  */
const InsuranceLeftColumn: FC = () => {
  const insuranceInfo = useAcctInsuranceCtx((s) => s.insuranceInfo);

  const insuranceInfoForm = useAcctInsuranceCtx((s) => s.insuranceInfoForm);
  const watch = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch);
  const control = useAcctInsuranceCtx((s) => s.insuranceInfoForm.control);

  const insRequired = watch('insRequired');
  const insCancelled = watch('insCancelled');
  const insCancelledDate = watch('insCancelledDate');
  const insExpire = watch('insExpire');

  return (
    <SectionColumn p={0}>
      <Grid component="h3">Coverage</Grid>

      <Controller
        name="insRequired"
        control={control}
        render={({ field }) => <Checkbox {...field} label="Insurance Required" />}
      />

      <TextInput label="VIN" disabled value={insuranceInfo?.vin} />

      <Controller
        name="insPolicyNumber"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput {...field} label="Policy Number" errors={fieldState.error?.message} />
        )}
      />

      <Controller
        name="insCoverage"
        control={control}
        render={({ field, fieldState }) => (
          <DropdownInput
            {...field}
            label="Coverage"
            data={InsuranceCoverageOption.options}
            errors={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="insExpire"
        rules={{ required: insRequired && 'Required' }}
        render={({ field }) => (
          <DateInput
            {...field}
            label="Expires On"
            required={insRequired}
            onChange={(val) => field.onChange(val)}
            errors={insRequired === true && insExpire === null}
          />
        )}
      />

      <Controller
        name="insComprehensiveDeductible"
        control={control}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            label="Comprehensive Deductible"
            errors={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="insCollisionDeductible"
        control={control}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            label="Collision Deductible"
            errors={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="insFireTheftDeductible"
        control={control}
        render={({ field, fieldState }) => (
          <CurrencyInput
            {...field}
            label="Fire and Theft Deductible"
            errors={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="insCancelled"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            label="Insurance Cancelled?"
            defaultChecked={insuranceInfo?.insCancelled || false}
            onChange={(e) => {
              field.onChange(e.value);
              if (e.value === true && insCancelledDate === null) {
                insuranceInfoForm.setError('insCancelledDate', {
                  message: 'Please Enter a Valid Date ',
                });
              }
            }}
          />
        )}
      />

      {insCancelled && (
        <Controller
          name="insCancelledDate"
          control={control}
          rules={{ required: insCancelled ? 'Please Enter a Valid Date' : false }}
          shouldUnregister
          render={({ field }) => (
            <DateInput
              {...field}
              required
              label="Cancelled On"
              onChange={(val) => field.onChange(val)}
              errors={insCancelled === true && watch('insCancelledDate') === null}
            />
          )}
        />
      )}
    </SectionColumn>
  );
};

export default InsuranceLeftColumn;
