import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { CompanyAddOnsTax } from "@/interfaces/CtxAdd";

interface CustomTableProps {
  rows: CompanyAddOnsTax[];
  handleRowClick: (recId: number) => void;
}

const AddOnsSalesTaxTable: FC<CustomTableProps> = ({ rows, handleRowClick }) => {
  return (
    <Box sx={{ margin: "20px" }}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px", boxShadow: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#e9e9e9" }}>
              <TableCell
                sx={{
                  fontWeight: "700",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  color: "rgb(17, 24, 39)",
                }}
              >
                Company
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "700",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  color: "rgb(17, 24, 39)",
                }}
              >
                Profile
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.recId}
                hover
                sx={{
                  cursor: "pointer",
                  backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#fff",
                  "&:hover": { backgroundColor: "#f0f8ff" },
                }}
                onClick={() => handleRowClick(row.recId)}
              >
                <TableCell>{row.shortName ?? "N/A"}</TableCell>
                <TableCell>{row.saleType ?? "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddOnsSalesTaxTable;
