import { FC } from "react";
import { Dayjs } from "dayjs";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker/DatePicker.types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";

// @todo add separate component MuiKendoDatePickerForm - which is wrapped in a <FormControl> element
/** Wrapper for MUI's Date-Picker
 * Demos:
 *
 * - [DatePicker](https://mui.com/x/react-date-pickers/date-picker/)
 * - [Validation](https://mui.com/x/react-date-pickers/validation/)
 *
 * API:
 *
 * - [DatePicker API](https://mui.com/x/api/date-pickers/date-picker/)
 */
const MuiKendoDatePicker: FC<DatePickerProps<Dayjs> & { error?: boolean; errorMsg?: string }> = ({
  error = false,
  errorMsg,
  label,
  disabled,
  ...props
}) => {
  const usesErrorStr = typeof errorMsg === "string";

  return (
    <Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          {...props}
          slotProps={{
            textField: {
              label,
              fullWidth: true,
              variant: "outlined",
              error,
              helperText: usesErrorStr ? errorMsg || " " : "",
              InputProps: { sx: { fontSize: "14px" } },
              InputLabelProps: { sx: { fontSize: "14px" } },
            },
          }}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default MuiKendoDatePicker;
