import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// components
import PmtReversalTable from './PmtReversalTable';

const Header: FC = () => {
  return (
    <Grid container direction="row" justifyContent="start" alignItems="center">
      <Typography
        fontSize="20px"
        fontWeight={700}
        lineHeight="30px"
        mb="5px"
        mr="40px"
        letterSpacing="-0.4px"
        color="rgb(17,24,39)"
      >
        Payment Reversal
      </Typography>
    </Grid>
  );
};

/** ###  */
const PmtReversalList: FC = () => {
  return (
    <Grid container direction="column" gap={1} flex={1}>
      <Header />
      <PmtReversalTable />
    </Grid>
  );
};

export default PmtReversalList;
