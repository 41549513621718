import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Paper from '@mui/material/Paper';
import MuiKendoTextField from '@/mui/components/form/MuiKendoTextField';
// state
import { useWholesaleCompanyFormCtx } from './WholesaleCompanyFormProvider';
import { formFieldStyle } from '../default';
// style

/** ###  */
const WholesaleCompanyForm: FC = () => {
  const setWsCompanyFormField = useWholesaleCompanyFormCtx((s) => s.setWsCompanyFormField);
  const wsCompanyForm = useWholesaleCompanyFormCtx((s) => s.wsCompanyForm!);

  return (
    <Grid
      container
      direction="column"
      flex={1}
      wrap="nowrap"
      rowGap={1}
      p={1}
      py={2}
      sx={{ overflowX: 'auto', overflowY: 'scroll' }}
    >
      <Grid container direction="row" component={Paper} elevation={0} flex={1} gap={2}>
        <Grid container direction="column" component={Paper} elevation={0} flex={1} p={2}>
          <MuiKendoTextField
            label="Company Name"
            value={wsCompanyForm.companyName}
            onChange={(e) => setWsCompanyFormField('companyName', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Address"
            value={wsCompanyForm.address}
            onChange={(e) => setWsCompanyFormField('address', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Zip"
            value={wsCompanyForm.zip}
            onChange={(e) => {
              if (e.currentTarget.value.length <= 5 && !isNaN(Number(e.currentTarget.value))) {
                setWsCompanyFormField('zip', e.currentTarget.value);
              }
            }}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="City"
            value={wsCompanyForm.city}
            onChange={(e) => setWsCompanyFormField('city', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="County"
            value={wsCompanyForm.county}
            onChange={(e) => setWsCompanyFormField('county', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="State"
            value={wsCompanyForm.state}
            onChange={(e) => {
              if (e.currentTarget.value.length <= 2) {
                setWsCompanyFormField('state', e.currentTarget.value);
              }
            }}
            sx={formFieldStyle}
          />
        </Grid>
        <Grid container direction="column" component={Paper} elevation={0} flex={1} p={2}>
          <MuiKendoTextField
            label="Phone"
            value={wsCompanyForm.phone}
            onChange={(e) => setWsCompanyFormField('phone', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Fax"
            value={wsCompanyForm.fax}
            onChange={(e) => setWsCompanyFormField('fax', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Tax Id"
            value={wsCompanyForm.taxId}
            onChange={(e) => setWsCompanyFormField('taxId', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Contact Name"
            value={wsCompanyForm.contactName}
            onChange={(e) => setWsCompanyFormField('contactName', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Contact Email"
            value={wsCompanyForm.contactEmail}
            onChange={(e) => setWsCompanyFormField('contactEmail', e.currentTarget.value)}
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Contact Phone"
            value={wsCompanyForm.contactPhone}
            onChange={(e) => setWsCompanyFormField('contactPhone', e.currentTarget.value)}
            sx={formFieldStyle}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WholesaleCompanyForm;
