import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';

const initialState = {
  /** @deprecated */
  postPaymentLoading: false,
};

export type PaymentAppState = typeof initialState;

export const slice = createSlice<
  PaymentAppState,
  {
    /** @deprecated */
    setPostPaymentLoading: CaseReducer<PaymentAppState, PayloadAction<boolean>>;
  }
>({
  name: 'payment',
  initialState,
  reducers: {
    /** @deprecated */
    setPostPaymentLoading: (state, action) => {
      state.postPaymentLoading = action.payload;
    },
  },
});

export const usePaymentSelector = buildSubStateSelector<PaymentAppState>((state) => state.payment);
export const paymentActions = slice.actions;
export const paymentReducer = slice.reducer;
