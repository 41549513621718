import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Modal } from "@/components/modals/Modal";
import { Button } from "@/components/button/Button";
import { Spacer } from "@/components/spacer/Spacer";
// components
// state
import { usePmtFormCtx } from "./PmtFormProvider";
// validation
// utils
import { accountsService } from "@/services/accountsService";

/** ###  */
const PendingRewriteModal: FC = () => {
  const paymentData = usePmtFormCtx((s) => s.paymentData.value);
  const cancellingRewrite = usePmtFormCtx((s) => s.cancellingRewrite);
  const setCancellingRewrite = usePmtFormCtx((s) => s.setCancellingRewrite);
  const showPendingRewriteModal = usePmtFormCtx((s) => s.showPendingRewriteModal);
  const setShowPendingRewriteModal = usePmtFormCtx((s) => s.setShowPendingRewriteModal);
  const setContinueSubmitting = usePmtFormCtx((s) => s.setContinueSubmitting);
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);

  if (!showPendingRewriteModal) return <></>;

  const cancelRewriteAndProceed = async () => {
    if (!paymentData?.appRecId) {
      setCancellingRewrite(false);
      console.warn(`cancelRewriteAndProceed(): invalid appRecId:`, paymentData?.appRecId);
      return;
    }
    try {
      setCancellingRewrite(true);
      await accountsService.cancelPendingRewrite(paymentData.appRecId);
      setShowPendingRewriteModal(false);
      setContinueSubmitting(true);
    } finally {
      setCancellingRewrite(false);
    }
  };
  const handleCancel = () => {
    setShowPendingRewriteModal(false);
    setCancellingRewrite(false);
    setIsSubmitting(false);
  };

  return (
    <Modal centerModal isOpen={showPendingRewriteModal}>
      <div>There is a pending rewrite on this account.</div>
      <div>In order to proceed with the transaction, the rewrite must be canceled</div>

      <Spacer size={20} />

      {/* Button group */}
      <Grid container sx={{ justifyContent: "center", gap: "40px" }}>
        <Button label="Cancel Transaction" fullWidth={false} secondary onClick={handleCancel} />
        <Button
          label="Cancel Rewrite & Complete Payment"
          fullWidth={false}
          themeColor="warning"
          loading={cancellingRewrite}
          onClick={cancelRewriteAndProceed}
        />
      </Grid>
    </Modal>
  );
};

export default PendingRewriteModal;
