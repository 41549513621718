import { FC } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
// components
import ViewHeader from './ViewHeader';

/** ###  */
const ViewLayout: FC<
  Grid2Props & {
    /** If provided, props applied directly to  ViewHeader` component */
    headerProps?: Parameters<typeof ViewHeader>[0];
  }
> = ({ headerProps, children, sx, ...gridProps }) => (
  // Change padding to p={2}
  <Grid container direction="column" px="36px" gap={2} sx={{ ...sx }} {...gridProps}>
    {headerProps && <ViewHeader {...headerProps} />}

    {children}
  </Grid>
);

export default ViewLayout;
