import { FC, PropsWithChildren, ReactNode, createContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
// services
import { inventoryService } from "@/services/inventoryService";
import { salesService } from "@/services/salesService";
import { collectionsService } from "@/services/collectionsService";
// utils
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { SalesSubview } from "../enums";
import { getNumber } from "@/utils/helpers/general";
import { ColTypeCode, ColTypeLabel, colTypeMap } from "@/enums/general";
import { SaleData } from "@/interfaces";
import { paymentService } from "@/services/paymentService";

const useCtxState = () => {
  // @todo to be added
  const salesSubviewMatch = useMatch("/sales/:subview/:appRecId/*");
  /** Value from browser parameter `/sales/:subview` */
  const appRecId = getRouteParamNum(salesSubviewMatch?.params.appRecId);
  /** Value from browser parameter `/sales/:subview/:appRecId/` */
  const subview = SalesSubview.safeParse(salesSubviewMatch?.params.subview).data ?? null;

  /** @deprecated to be removed */
  const [headerTitle, setHeaderTitle] = useState<ReactNode>(null);

  const saleDataReq = useReq(async () => {
    if (!appRecId) return;
    return await salesService.getSale(appRecId);
  });
  const colRecIdReq = useReq(async (saleData?: SaleData) => {
    const origSaleType = saleData?.sale?.saletype ?? saleDataReq.value?.sale?.saletype;
    const saleType = ColTypeLabel.safeParse(origSaleType).data;
    const appliedColType = ColTypeCode.safeParse(colTypeMap[saleType!].code).data; // @note This is NOT unsafe, as a failed lookup will be `undefined`

    if (appliedColType && appRecId) {
      return await collectionsService.getColRecId(appRecId, appliedColType);
    } else {
      const errObj = { origSaleType, saleType, appRecId };
      console.error("collectionsService.getColRecId(): Invalid params", errObj);
      return null;
    }
  });
  const vehRecId = getNumber(saleDataReq.value?.sale?.vehRecID);
  const suggestedSalesInfo = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getSuggestedSalesInfo(vehRecId);
  });
  const vehicleCost = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getVehicleCostDetails(vehRecId.toString());
  });

  /** @note Load/fetch must be manually called (i.e. downPayments.load()), as this is only used in few components. */
  const downPayments = useReq(async () => {
    if (!appRecId) return;
    return await salesService.getDownPaymentList(appRecId);
  });
  const paymentDataReq = useReq(async (saleData?: SaleData) => {
    const origSaleType = saleData?.sale?.saletype ?? saleDataReq.value?.sale?.saletype;
    const saleType = ColTypeLabel.safeParse(origSaleType).data;
    const appliedColType = ColTypeCode.safeParse(saleType && colTypeMap[saleType].code).data;

    if (!appliedColType || !appRecId) {
      console.warn("paymentDataReq.load", { appRecId, origSaleType, saleData, appliedColType });
      return;
    }
    return await paymentService.getPaymentDataByAppRecId(appRecId, appliedColType);
  });

  useEffect(() => {
    saleDataReq.load();
  }, [appRecId]);
  useEffect(() => {
    suggestedSalesInfo.load();
    vehicleCost.load();
  }, [vehRecId]);

  return {
    /** @deprecated to be removed */
    headerTitle,
    /** @deprecated to be removed */
    setHeaderTitle,

    // Req. data states
    saleDataReq,
    suggestedSalesInfo,
    vehicleCost,
    downPayments,
    colRecIdReq,
    paymentDataReq,

    // Router
    appRecId,
    subview,
  };
};

type ISalesDetailCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ISalesDetailCtx | null>(null);
export const useSalesDetailCtx = useCtxFactory(Ctx);

const SalesDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SalesDetailProvider;
