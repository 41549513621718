import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// interfaces
import { IPayment } from '@/interfaces';
import { PmtSubviewKey } from '../viewInfo';
import { IPaginatedReq, IPaginatedRes } from '@/interfaces/requests';

dayjs.extend(utc);

/** ## A "Payment" item specific to the interface returned from a `subview` query, as its represented in the `Payments` table. */
export class PmtSubviewDb
  implements Omit<IPayment, 'prinPaid' | 'acctNum' | 'intPaid' | 'pmtTime'>
{
  constructor(
    public appRecId: number,
    public amountDue: number,
    public code3: string,
    public change: number,
    public colRecId: number,
    public colType: string,
    public compId: number,
    public companyNsfFee: number,
    public cpiPaid: number,
    public daysLate: number,
    public ddPaid: number,
    public entryType: string,
    public inDaysLate: number,
    public interestPaid: number,
    public isAch: boolean,
    public isCreditCard: boolean,
    public last4: string,
    public lcOwed: number,
    public lcPaid: number,
    public locId: number,
    public miscPaid: number,
    public nsfPaid: number,
    public orgId: number,
    public paidBy: string,
    public paidIn: string,
    public paidRef: string,
    public payNote: string,
    /** Date represented as a string */
    public pmtDate: string,
    public principalBalance: number,
    public principalPaid: number,
    public recId: number,
    public receiptNumber: string,
    public snPaid: number,
    public stockNumber: string,
    public takenBy: string,
    public taxPaid: number,
    public totalApplied: number,
    public totalPayment: number,
    public totalReceived: number,
    public mPayCat: string,
    // mapping for 'MI' | 'MO' `colType`s
    // public mPayInOut: string,
    public mPayNote: string,
    public mPayToFrom: string
  ) {}
}

export const newPmtSubviewItem = (payment: PmtSubviewDb): PmtSubviewDb => {
  // Convert date-strings to correct format
  const pmtDate = payment.pmtDate === null ? '' : dayjs(payment.pmtDate).utc().format('M/D/YYYY');

  return new PmtSubviewDb(
    payment.appRecId,
    payment.amountDue,
    payment.code3,
    payment.change,
    payment.colRecId,
    payment.colType,
    payment.compId,
    payment.companyNsfFee,
    payment.cpiPaid,
    payment.daysLate,
    payment.ddPaid,
    payment.entryType,
    payment.inDaysLate,
    payment.interestPaid,
    payment.isAch,
    payment.isCreditCard,
    payment.last4,
    payment.lcOwed,
    payment.lcPaid,
    payment.locId,
    payment.miscPaid,
    payment.nsfPaid,
    payment.orgId,
    payment.paidBy,
    payment.paidIn,
    payment.paidRef,
    payment.payNote,
    pmtDate,
    payment.principalBalance,
    payment.principalPaid,
    payment.recId,
    payment.receiptNumber,
    payment.snPaid,
    payment.stockNumber,
    payment.takenBy,
    payment.taxPaid,
    payment.totalApplied,
    payment.totalPayment,
    payment.totalReceived,
    payment.mPayCat,
    // payment.mPayInOut,
    payment.mPayNote,
    payment.mPayToFrom
  );
};

export interface IPmtSubviewReq extends IPaginatedReq {
  subview: PmtSubviewKey;
  search: string;
}

export type IPmtSubviewKeyRes = IPaginatedRes<PmtSubviewDb>;
