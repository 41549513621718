import { FC, useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
// utils
import NavBlock from "@/utils/forms/NavBlock";

/** ### Prevent navigation from current page is condition `shouldBlock` is true.
 *
 * Usage:
 * ```tsx
 * // Basic (always block navigation):
 * const NavBlock = useNavBlock();
 * // Custom condition:
 * const NavBlock = useNavBlock(isDirty || !isValid);
 *
 * return (
 *   // Basic:
 *   <NavBlockElem />
 *   // Customized:
 *   <NavBlockElem title="Example title" textBody="Example text body" blocker={blocker} modalProps={modalProps} containerProps={containerProps} />
 *   <NavBlockElem
 *      title={<Grid container direction='row'> ...elements... </Grid>}
 *      textBody={<Grid container direction='column'> ...elements... </Grid>}
 *      blocker={blocker}
 *      modalProps={modalProps}
 *      containerProps={containerProps}
 *   />
 * )
 * ```
 */
const useNavBlock = (shouldBlock: boolean = true) => {
  const [isOpen, setIsOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const shouldBlockNav = shouldBlock && currentLocation.pathname !== nextLocation.pathname;
    shouldBlockNav && setIsOpen(true);
    return shouldBlockNav;
  });

  useEffect(() => {
    blocker.state === "blocked" && setIsOpen(true);
  }, [blocker.state]);

  const NavBlockElem: FC<Omit<Parameters<typeof NavBlock>["0"], "blocker">> = (params) => (
    <NavBlock {...params} openState={{ isOpen, setIsOpen }} blocker={blocker} />
  );

  return { NavBlockElem, blocker };
};

export default useNavBlock;
