export class ApplicationsDb {
  constructor(
    public appId: number,
    public appDate: Date,
    public appDays: number,
    public appRecId: number,
    public appStatus: string,
    public appt: string,
    public atLot: number,
    public bhPhone: string,
    public bcPhone: string,
    public bNames: string,
    public buyerRecId: number,
    public callsIn: number,
    public callsOut: number,
    public ccPhone: string,
    public chPhone: string,
    public cobuyRecId: number,
    public code3: string,
    public companyName: string,
    public compAppId: number,
    public compId: number,
    public depositDown: number,
    public emailOut: number,
    public hasBureau: boolean,
    public hasQuote: boolean,
    public hasVehicle: boolean,
    public invDays: number,
    public isCobuyer: boolean,
    public lAppStatus: string,
    public lastContactTime: Date,
    public lastContactType: string,
    public locId: number,
    public manLocation: number,
    public nextContactTime: Date,
    public numTd: number,
    public orgId: number,
    public referredBy: string,
    public region: string,
    public saleDate: Date,
    public saleStatus: string,
    public saleType: string,
    public shortName: string,
    public smsIn: number,
    public smsOut: number,
    public stockNum: string,
    public upType: string,
    public vehRecId: number,
    public vehYmm: string,
    public vin?: string
  ) {}

  static new(newApplicationsRow: ApplicationsDb): ApplicationsDb {
    return new ApplicationsDb(
      newApplicationsRow.appId,
      newApplicationsRow.appDate,
      newApplicationsRow.appDays,
      newApplicationsRow.appRecId,
      newApplicationsRow.appStatus,
      newApplicationsRow.appt,
      newApplicationsRow.atLot,
      newApplicationsRow.bhPhone,
      newApplicationsRow.bcPhone,
      newApplicationsRow.bNames,
      newApplicationsRow.buyerRecId,
      newApplicationsRow.callsIn,
      newApplicationsRow.callsOut,
      newApplicationsRow.ccPhone,
      newApplicationsRow.chPhone,
      newApplicationsRow.cobuyRecId,
      newApplicationsRow.code3,
      newApplicationsRow.companyName,
      newApplicationsRow.compAppId,
      newApplicationsRow.compId,
      newApplicationsRow.depositDown,
      newApplicationsRow.emailOut,
      newApplicationsRow.hasBureau,
      newApplicationsRow.hasQuote,
      newApplicationsRow.hasVehicle,
      newApplicationsRow.invDays,
      newApplicationsRow.isCobuyer,
      newApplicationsRow.lAppStatus,
      newApplicationsRow.lastContactTime,
      newApplicationsRow.lastContactType,
      newApplicationsRow.locId,
      newApplicationsRow.manLocation,
      newApplicationsRow.nextContactTime,
      newApplicationsRow.numTd,
      newApplicationsRow.orgId,
      newApplicationsRow.referredBy,
      newApplicationsRow.region,
      newApplicationsRow.saleDate,
      newApplicationsRow.saleStatus,
      newApplicationsRow.saleType,
      newApplicationsRow.shortName,
      newApplicationsRow.smsIn,
      newApplicationsRow.smsOut,
      newApplicationsRow.stockNum,
      newApplicationsRow.upType,
      newApplicationsRow.vehRecId,
      newApplicationsRow.vehYmm,
      newApplicationsRow.vin
    );
  }
}

export type IApplicationsDb = ApplicationsDb;
