import { FC, ReactNode } from "react";
// mui
import { Grid2Props } from "@mui/material/Unstable_Grid2";
import { FormControlProps } from "@mui/material/FormControl";
import { FormLabelProps } from "@mui/material/FormLabel";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import FormHelperText, { FormHelperTextProps } from "@mui/material/FormHelperText";
// local
import MuiKendoFormControl from "./MuiKendoFormControl";

// @ts-ignore
export const MuiKendoHelperText: FC<
  FormHelperTextProps & {
    errorText?: string;
    helperText?: string;
    keepHelperTextSpacing?: boolean;
    helperComponent?: ReactNode;
  }
> = ({
  errorText,
  helperText,
  error,
  keepHelperTextSpacing = false,
  helperComponent,
  ...helperTextProps
}) => {
  const inputNode = errorText || helperText;

  return (
    helperComponent || (
      <FormHelperText
        {...helperTextProps}
        error={errorText ? true : error}
        sx={{
          alignSelf: "end",
          mr: 1,
          textOverflow: "ellipsis",
          textWrap: "nowrap",
          overflow: "hidden",
          width: "100%",
          textAlign: "end",
        }}
      >
        {/* An empty space keeps this rendered */}
        {keepHelperTextSpacing && !inputNode ? " " : inputNode}
      </FormHelperText>
    )
  );
};

export const MKTextInput: FC<OutlinedInputProps> = ({ sx: sxProps, ...props }) => (
  <OutlinedInput margin="none" sx={{ fontSize: 14, m: 1, ...sxProps }} {...props} />
);

/** ### Modified OutlinedInput, applies kendo styles
 *
 * Demos:
 *
 * - [Text Field](https://mui.com/material-ui/react-text-field/#components)
 *
 * API:
 *
 * - [OutlinedInput API](https://mui.com/material-ui/api/outlined-input/)
 * - inherits [InputBase API](https://mui.com/material-ui/api/input-base/)
 */
const MuiKendoTextField: FC<
  OutlinedInputProps & {
    errorText?: string;
    helperText?: string;
    helperTextProps?: FormHelperTextProps;
    keepHelperTextSpacing?: boolean;
    helperComponent?: ReactNode;
    containerProps?: Grid2Props;
    formControlProps?: FormControlProps;
    labelProps?: FormLabelProps;
  }
> = ({
  label,
  errorText,
  helperText,
  helperTextProps,
  keepHelperTextSpacing,
  helperComponent,
  containerProps,
  formControlProps,
  labelProps,
  ...inputProps
}) => {
  return (
    <>
      <MuiKendoFormControl
        {...formControlProps}
        label={label}
        disabled={inputProps.disabled}
        containerProps={containerProps}
        labelProps={{ ...labelProps, sx: { flex: 1, ...labelProps?.sx } }}
        error={!!(errorText || inputProps.error)}
      >
        <MKTextInput
          {...inputProps}
          disabled={inputProps.disabled}
          error={!!(errorText || inputProps.error)}
          sx={{ ...inputProps.sx, flex: 1.25 }}
        />
      </MuiKendoFormControl>
      <MuiKendoHelperText
        {...helperTextProps}
        errorText={errorText}
        helperText={helperText}
        helperComponent={helperComponent}
        keepHelperTextSpacing={keepHelperTextSpacing}
      />
    </>
  );
};

export default MuiKendoTextField;
