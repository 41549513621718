import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
// kendo
import { RadioGroup } from '@progress/kendo-react-inputs';
import { Spacer } from '@/components/spacer/Spacer';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// components
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { Modal } from '@/components/modals/Modal';
import { BasicTable } from '@/components/table/Table';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { shortZipRegex, optionalPhoneValidation } from '@/utils/helpers/formValidation';
import {
  DateFormat,
  formatAddress,
  formatDate,
  getAddressFromGoogleMaps,
} from '@/utils/helpers/general';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { AccountInformation, Employer, GetEmployersData } from '@/interfaces/Accounts';
import { Column } from '@/components/table/TableInterface';
import { PaymentInterval } from '@/enums/payment';
import { BuyerType, CurrentPrevious, EmploymentType } from '@/enums/general';
// style
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

const employerHistoryColumns: Column[] = [
  {
    field: 'employer',
    title: 'Employer',
  },
  {
    field: 'currentPrevious',
    title: 'Current/Previous',
  },
  {
    field: 'years',
    title: 'How Long',
  },
];

// @todo move component to file
/** @deprecated @todo use context to manage state */
export const Employers: FC = () => {
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const [buyerType, setBuyerType] = useState<BuyerType>(BuyerType.Buyer);
  const [employers, setEmployers] = useState<GetEmployersData>();
  const [historyEmployers, setHistoryEmployers] = useState<GetEmployersData>();
  const [employersLoading, setEmployersLoading] = useState(false);
  const [newEmployerHistoryModalOpen, setNewEmployerHistoryModalOpen] = useState(false);
  const [updateHistoryEmployer, setUpdateHistoryEmployer] = useState<Employer | undefined>();

  const { accountInformation, accountInformationLoading } = useAccountSelector((state) => state);

  const hasCoBuyer = !!(
    accountInformation?.coBuyer?.appBuyerRecId && accountInformation?.coBuyer?.firstName
  );

  const navBarItems = hasCoBuyer
    ? [
        {
          title: BuyerType.Buyer,
          isActive: buyerType === BuyerType.Buyer,
          onClick: () => setBuyerType(BuyerType.Buyer),
        },
        {
          title: BuyerType.CoBuyer,
          isActive: buyerType === BuyerType.CoBuyer,
          onClick: () => setBuyerType(BuyerType.CoBuyer),
        },
      ]
    : [];

  const loadEmployers = async () => {
    setEmployersLoading(true);
    try {
      const empRes = await accountsService.getEmployers(colRecId);
      setEmployers(empRes);
      const historyEmpRes = await accountsService.getHistoryEmployers(colRecId);
      setHistoryEmployers(historyEmpRes);
    } finally {
      setEmployersLoading(false);
    }
  };

  useEffect(() => {
    if (accountInformation) {
      loadEmployers();
    }
    // @todo deps arr must use stable ref
  }, [accountInformation]);

  const currentEmployer =
    buyerType === BuyerType.Buyer ? employers?.buyer[0] : employers?.coBuyer[0];

  const currentHistoryEmployers =
    buyerType === BuyerType.Buyer ? historyEmployers?.buyer : historyEmployers?.coBuyer;

  return (
    <AccountsMainPanel
      navBarTitle="Employer"
      navBarItems={navBarItems}
      loading={employersLoading || accountInformationLoading}
    >
      <>
        <EmployerForm employer={currentEmployer} isPrimaryEmployer />
        {!!currentHistoryEmployers?.length && (
          <>
            <Spacer size={20} />
            <div className={styles.tableContainer}>
              <h3>{buyerType}'s Employment History & Other Current Employers</h3>
              <BasicTable
                data={currentHistoryEmployers}
                columns={employerHistoryColumns}
                onRowClick={(e) => {
                  setUpdateHistoryEmployer(e.dataItem);
                }}
              />
            </div>
            <Spacer size={20} />
          </>
        )}
      </>
      {newEmployerHistoryModalOpen && (
        <Modal
          centerModal
          isOpen={newEmployerHistoryModalOpen}
          closeButton
          onCloseButtonClick={() => setNewEmployerHistoryModalOpen(false)}
          onDimmerClick={() => setNewEmployerHistoryModalOpen(false)}
        >
          <EmployerForm
            onSubmitSuccess={() => {
              loadEmployers();
              setNewEmployerHistoryModalOpen(false);
            }}
            hasCoBuyer={hasCoBuyer}
            isNewEmployer
            initialBuyerType={buyerType}
          />
        </Modal>
      )}
      {!!updateHistoryEmployer && (
        <Modal
          centerModal
          isOpen={!!updateHistoryEmployer}
          closeButton
          onCloseButtonClick={() => setUpdateHistoryEmployer(undefined)}
          onDimmerClick={() => setUpdateHistoryEmployer(undefined)}
        >
          <EmployerForm
            onSubmitSuccess={() => {
              loadEmployers();
              setUpdateHistoryEmployer(undefined);
            }}
            employer={updateHistoryEmployer}
          />
        </Modal>
      )}
      <div className={styles.stickyBottom}>
        <div className={styles.link} onClick={() => setNewEmployerHistoryModalOpen(true)}>
          + Add a non-primary employer
        </div>
      </div>
    </AccountsMainPanel>
  );
};

const getDefaultFormValues = (
  employer: Employer,
  accountInformation: AccountInformation,
  initialBuyerType: BuyerType
) => ({
  recId: employer.recId || 0,
  appRecId: employer.appRecId || accountInformation.appRecId!,
  buyerRecId:
    employer.buyerRecId || initialBuyerType == BuyerType.Buyer
      ? accountInformation.buyer.appBuyerRecId!
      : accountInformation.coBuyer.appBuyerRecId!,
  employer: employer.employer || '',
  employmentType: employer.employmentType || null,
  jobTitle: employer.jobTitle || '',
  years: employer.years || 0,
  months: employer.months || 0,
  salary: employer.salary || 0,
  payFrequency: employer.payFrequency || null,
  address: employer.address || '',
  city: employer.city || '',
  state: employer.state || '',
  zip: employer.zip || '',
  phoneNumber: employer.phoneNumber || '',
  doNotCall: employer.doNotCall || false,
  doNotText: employer.doNotText || false,
  doNotEmail: employer.doNotEmail || false,
  buyerOrCoBuyer: employer.buyerOrCoBuyer || initialBuyerType,
  supervisor: employer.supervisor || '',
  nextPayDay: formatDate(employer.nextPayDay || '', { pattern: DateFormat.DateInput }),
  paidOn: employer.paidOn || '',
  hours: employer.hours || '',
  fax: employer.fax || '',
  extension: employer.extension || '',
  currentPrevious: employer.currentPrevious || undefined,
});

// @todo move component to file
/** @deprecated @todo use context to manage state */
const EmployerForm: FC<{
  employer?: Employer;
  isNewEmployer?: boolean;
  hasCoBuyer?: boolean;
  isPrimaryEmployer?: boolean;
  initialBuyerType?: BuyerType;
  onSubmitStart?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitComplete?: () => void;
}> = (props) => {
  const employer = props.employer ?? ({} as Employer);
  const { onSubmitSuccess = () => null, initialBuyerType = BuyerType.Buyer } = props;
  const [employerSubmitting, setEmployerSubmitting] = useState(false);

  const accountInformation = useAccountSelector((s) => s.accountInformation);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Employer>({
    defaultValues: getDefaultFormValues(employer, accountInformation!, initialBuyerType),
  });

  // @todo remove unnecessary optimization
  const fullAddress = useMemo(() => {
    const formattedAddress = formatAddress(
      employer?.address,
      employer?.city,
      employer?.state,
      employer?.zip
    );

    return formattedAddress;
    // @todo deps arr must use stable ref
  }, [employer]);

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('address', fullAddress.address, { shouldDirty: true });
    setValue('city', fullAddress.city, { shouldDirty: true });
    setValue('state', fullAddress.state, { shouldDirty: true });
    setValue('zip', fullAddress.zip, { shouldDirty: true });
  };

  const buyerOrCoBuyer = watch('buyerOrCoBuyer');

  useEffect(() => {
    const appBuyerRecId =
      buyerOrCoBuyer === BuyerType.Buyer
        ? accountInformation?.buyer.appBuyerRecId!
        : accountInformation?.coBuyer.appBuyerRecId!;
    setValue('buyerRecId', appBuyerRecId);
  }, [buyerOrCoBuyer]);

  const onSubmit = (updatedEmployer: Employer) => {
    setEmployerSubmitting(true);
    const nextPayDay = updatedEmployer.nextPayDay ? updatedEmployer.nextPayDay : undefined;

    if (props.isNewEmployer) {
      // @todo use async/await
      accountsService
        .createHistoryEmployer({ ...updatedEmployer, nextPayDay: nextPayDay })
        .then(() => {
          onSubmitSuccess();
          reset();
        })
        .finally(() => {
          setEmployerSubmitting(false);
        });
    } else {
      if (props.isPrimaryEmployer) {
        // @todo use async/await
        accountsService
          .updateEmployer({ ...updatedEmployer, nextPayDay: nextPayDay })
          .then(() => {
            onSubmitSuccess();
          })
          .finally(() => {
            setEmployerSubmitting(false);
          });
      } else {
        // @todo use async/await
        accountsService
          .updateHistoryEmployer({ ...updatedEmployer, nextPayDay: nextPayDay })
          .then(() => {
            onSubmitSuccess();
            reset();
          })
          .finally(() => {
            setEmployerSubmitting(false);
          });
      }
    }
  };

  const onDelete = () => {
    setEmployerSubmitting(true);
    // @todo use async/await
    accountsService
      .deleteHistoryEmployer(employer!.recId!)
      .then(() => {
        onSubmitSuccess();
        reset();
      })
      .finally(() => setEmployerSubmitting(false));
  };

  useEffect(() => {
    reset(getDefaultFormValues(employer, accountInformation!, initialBuyerType));
    // @todo deps arr must use stable ref
  }, [employer?.recId, accountInformation]);

  const buyerOptions = Object.values(BuyerType).map((bo) => ({ label: bo, value: bo }));

  // @todo move nested components to separate files
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
      {props.isNewEmployer && props.hasCoBuyer && (
        <h3 style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          New employer for:
          <RadioGroup
            data={buyerOptions}
            layout="horizontal"
            value={watch('buyerOrCoBuyer')}
            onChange={(e) => setValue('buyerOrCoBuyer', e.value)}
          />
        </h3>
      )}
      <div className={styles.columns}>
        <div className={styles.column}>
          {!props.isPrimaryEmployer && (
            <Controller
              name="currentPrevious"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Current/Previous Employer"
                  data={Object.values(CurrentPrevious)}
                  {...field}
                />
              )}
            />
          )}
          <Controller
            name="employer"
            control={control}
            render={({ field }) => (
              <TextInput
                label={props.isPrimaryEmployer ? 'Primary Employer Name' : 'Employer Name'}
                {...field}
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <AddressAutocomplete
                label="Address"
                {...field}
                onPlaceSelected={handlePlaceSelected}
                fullAddress={fullAddress}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => <TextInput label="City" {...field} />}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => <DropdownInput label="State" data={usaStateCodes} {...field} />}
          />
          <Controller
            name="zip"
            control={control}
            rules={{
              pattern: {
                value: shortZipRegex,
                message: 'Zip must be 5 numbers',
              },
            }}
            render={({ field }) => (
              <TextInput label="Zip" errors={errors.zip?.message} {...field} />
            )}
          />
          <Controller
            name="supervisor"
            control={control}
            render={({ field }) => <TextInput label="Supervisor" {...field} />}
          />
          <div style={{ display: 'flex', gap: 5 }}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={optionalPhoneValidation}
              render={({ field }) => (
                <PhoneInput
                  label="Phone"
                  containerStyles={{ flex: 3 }}
                  errors={errors.phoneNumber?.message || !!errors.phoneNumber}
                  {...field}
                />
              )}
            />
            <Controller
              name="extension"
              control={control}
              rules={{ pattern: { value: /\d{1,}/g, message: 'Extension must be numeric' } }}
              render={({ field }) => (
                <TextInput
                  label="Ext"
                  errors={errors.extension?.message}
                  containerStyles={{ flex: 1 }}
                  {...field}
                />
              )}
            />
          </div>
          <div className={styles.optOutCheckboxes}>
            <div>
              <Controller
                name="doNotCall"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Call" {...field} />}
              />
            </div>
            <div>
              <Controller
                name="doNotText"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Text" {...field} />}
              />
            </div>
            <div>
              <Controller
                name="doNotEmail"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Email" {...field} />}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <Controller
            name="jobTitle"
            control={control}
            render={({ field }) => <TextInput label="Occupation" {...field} />}
          />
          <div className={styles.inlineInputContainer}>
            <span className={styles.inlineInputLabel}>Time at address</span>
            <div className={styles.inlineInputItems}>
              <Controller
                name="years"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Years" horizontalLabel={false} min={0} {...field} />
                )}
              />
              <Controller
                name="months"
                control={control}
                rules={{ max: { value: 12, message: 'Cannot be greater than 12' } }}
                render={({ field }) => (
                  <NumberInput
                    label="Months"
                    min={0}
                    horizontalLabel={false}
                    errors={errors.months?.message}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="employmentType"
            control={control}
            render={({ field }) => (
              <DropdownInput
                label="Employment Type"
                data={Object.values(EmploymentType)}
                {...field}
              />
            )}
          />
          <Controller
            name="payFrequency"
            control={control}
            render={({ field }) => (
              <DropdownInput label="Pay Period" data={PaymentInterval.options} {...field} />
            )}
          />
          <Controller
            name="salary"
            control={control}
            render={({ field }) => <CurrencyInput label="Take Home Pay" {...field} />}
          />
          <Controller
            name="nextPayDay"
            control={control}
            render={({ field }) => {
              return <DateInput label="Next Payday" {...field} />;
            }}
          />
          <Controller
            name="paidOn"
            control={control}
            render={({ field }) => <TextInput label="Paid On" {...field} />}
          />
          <Controller
            name="hours"
            control={control}
            render={({ field }) => <TextInput label="Hours/Shift Worked" {...field} />}
          />
          <Spacer size={10} />
          <div style={{ alignSelf: 'end', display: 'flex', gap: '5px' }}>
            {!props.isPrimaryEmployer && !props.isNewEmployer && (
              <>
                <Button
                  label="Delete"
                  themeColor="error"
                  loading={employerSubmitting}
                  disabled={employerSubmitting}
                  onClick={onDelete}
                />
                <Spacer horizontal size={5} />
              </>
            )}
            <Button
              label={props.isNewEmployer ? 'Submit' : 'Update'}
              loading={employerSubmitting}
              disabled={employerSubmitting || !isDirty}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
