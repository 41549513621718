import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { useAppDispatch } from '@/store/store';
import { getSaleData } from '@/features/Sales/salesActionCreator';
import { useAppTabViewCtx } from '../../AppTabViewProvider';
// utils
import { salesService } from '@/services/salesService';
// interfaces
import {
  DclUnderwriting,
  IncomeAssessment,
  InterviewQuestions,
  LegalQuestions,
  SalesScoringDto,
  SalesScoringGridDto,
} from '@/interfaces/Sales';
import { BuyerTypeBoth } from '@/features/Sales/enums';
import { PaymentInterval } from '@/enums/payment';

export enum InterviewQuestionCreditCoachValues {
  Yes = 'Yes, I am willing to work with a free credit coach today.',
  No = 'Thank you, but I am not interested in working with a free credit coach.',
  YesButCannot = 'I am willing to work with a free credit coach but not in a financial position to do so today.',
}

export enum InterviewQuestionCreditDifficultiesValues {
  Yes = 'Yes, my credit difficulties are behind me.',
  No = 'No, I am still going through a credit difficulty situation.',
}

export enum InterviewQuestionCoverageInsuranceValues {
  Yes = 'I may have difficulty securing full coverage insurance.',
  No = 'I should not have any difficulty securing full coverage insurance.',
}

const buyerCobuyerBothData = [BuyerTypeBoth.Buyer, BuyerTypeBoth.CoBuyer, BuyerTypeBoth.Both];

export const useApplicationUnderwriting = () => {
  const { saleData, companyUnderwriting, underwritingScore } = useSalesSelector((s) => s);
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const setCurrentTab = useAppTabViewCtx((s) => s.setCurrentTab);
  const hasCobuyer = !!saleData.sale?.isCobuyer;
  const [updateUnderwritingLoading, setUpdateUnderwritingLoading] = useState(false);
  const formDefaultValues = {
    buyerSsn: '',
    buyerDriversLicenseNumber: '',
    buyerDriversExpiration: '',
    interviewQuestionOne: saleData.sale?.interviewQuestionImproveCreditScore,
    interviewQuestionTwo: saleData.sale?.interviewQuestionCreditCoach,
    interviewQuestionThree: saleData.sale?.interviewQuestionEarlyRefinance,
    interviewQuestionFour:
      saleData.sale?.interviewQuestionCreditDifficulties === true
        ? InterviewQuestionCreditDifficultiesValues.Yes
        : saleData.sale?.interviewQuestionCreditDifficulties === false
        ? InterviewQuestionCreditDifficultiesValues.No
        : undefined,
    interviewQuestionFive:
      saleData.sale?.interviewQuestionCoverageInsurance === true
        ? InterviewQuestionCoverageInsuranceValues.Yes
        : saleData.sale?.interviewQuestionCoverageInsurance === false
        ? InterviewQuestionCoverageInsuranceValues.No
        : undefined,
    interviewQuestionSix: saleData.sale?.interviewQuestionEstimatedMileage,
    buyerMonthlyNetIncome: 0,
    coBuyerMonthlyNetIncome: 0,
    totalMonthlyNetIncome: 0,
    buyerRentOrMortgage: 0,
    coBuyerRentOrMortgage: 0,
    totalRentOrMortgage: 0,
    buyerOtherAutoPayment: 0,
    coBuyerOtherAutoPayment: 0,
    totalOtherAutoPayment: 0,
    buyerAutoInsurancePayment: 0,
    coBuyerAutoInsurancePayment: 0,
    totalAutoInsurancePayment: 0,
    buyerElectric: 0,
    coBuyerElectric: 0,
    totalElectric: 0,
    buyerUtilityGas: 0,
    coBuyerUtilityGas: 0,
    totalUtilityGas: 0,
    buyerCreditCards: 0,
    coBuyerCreditCards: 0,
    totalCreditCards: 0,
    buyerMiscellaneous: 0,
    coBuyerMiscellaneous: 0,
    totalMiscellaneous: 0,
    buyerStudentLoans: 0,
    coBuyerStudentLoans: 0,
    totalStudentLoans: 0,
    buyerAlimonyChildSupport: 0,
    coBuyerAlimonyChildSupport: 0,
    totalAlimonyChildSupport: 0,
    buyerTotalExpenses: 0,
    coBuyerTotalExpenses: 0,
    totalExpenses: 0,
    buyerDisposableIncome: 0,
    coBuyerDisposableIncome: 0,
    totalDisposableIncome: 0,
    maximumAllowedWeekly: 0,
    maximumAllowedMonthly: 0,
    maximumAllowedBiWeekly: 0,
    maximumAllowedSemiMonthly: 0,
    buyerEquifax: undefined as unknown as number,
    coBuyerEquifax: undefined as unknown as number,
    buyerTransUnion: undefined as unknown as number,
    coBuyerTransUnion: undefined as unknown as number,
    buyerExperian: undefined as unknown as number,
    coBuyerExperian: undefined as unknown as number,
    checkingAccountBoth: saleData.sale?.ckbcBoth,
    checkingAccountWith: saleData.sale?.ckWith,
    checkingAccountBalance: saleData.sale?.ckBal,
    savingsAccountBoth: saleData.sale?.svbcBoth,
    savingsAccountWith: saleData.sale?.svWith,
    savingsAccountBalance: saleData.sale?.svBal,
    garnishmentBoth: saleData.sale?.grbcBoth,
    garnishmentAmount: saleData.sale?.grAmount,
    garnishmentWith: saleData.sale?.grWith,
    oweIrsBoth: saleData.sale?.irbcBoth,
    oweIrsAmount: saleData.sale?.irAmount,
    bankruptcyBoth: saleData.sale?.bkbcBoth,
    bankruptcyDischargedYesNo: saleData.sale?.bkDisYesNo,
    bankruptcyDischargedDate: dayjs(saleData.sale?.bkDisDate).format('YYYY-MM-DD'),
    rideSharingBoth: saleData.sale?.rsbcBoth,
    ownCheckingAccount: saleData.sale?.ckYesNo,
    ownSavingsAccount: saleData.sale?.svYesNo,
    driveForRideSharing: saleData.sale?.rsYesNo,
    haveAnyGarnishments: saleData.sale?.grYesNo,
    oweIrs: saleData.sale?.irYesNo,
    everFiledForBankruptcy: saleData.sale?.bkYesNo,
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const isDisabled = useMemo(
    () => saleData.sale?.salestatus?.toLowerCase() !== 'pending',
    [saleData]
  );

  const calculateMaximumAllowedPayment = (
    schedule: PaymentInterval,
    totalMonthlyNetIncome: number,
    companyUnderwriting: DclUnderwriting
  ) => {
    const percentageUsed = companyUnderwriting.useLease_Underwriting
      ? companyUnderwriting.payPctOfNetReq || 0
      : companyUnderwriting.minDnPmtPct || 0;

    let maximumAllowedPayment = 0;
    let divideByNum = 1;

    switch (schedule) {
      case 'Weekly':
        divideByNum = 4.3;
        break;
      case 'Bi-Weekly':
        divideByNum = 2.15;
        break;
      case 'Semi-Monthly':
        divideByNum = 2;
        break;
      case 'Monthly':
        divideByNum = 1;
        break;
    }

    maximumAllowedPayment = (totalMonthlyNetIncome * (percentageUsed * 0.01)) / divideByNum;

    return maximumAllowedPayment;
  };

  const updateLegalQuestions = async (data: typeof formDefaultValues) => {
    const payload: LegalQuestions = {
      appRecId: appRecId,
      checkingAccountYesNo: data.ownCheckingAccount,
      checkingAccountWith: data.checkingAccountWith,
      checkingAccountBoth: hasCobuyer ? data.checkingAccountBoth : BuyerTypeBoth.Buyer,
      checkingAccountBalance: data.checkingAccountBalance,
      savingsAccountYesNo: data.ownSavingsAccount,
      savingsAccountWith: data.savingsAccountWith,
      savingsAccountBoth: hasCobuyer ? data.savingsAccountBoth : BuyerTypeBoth.Buyer,
      savingsAccountBalance: data.savingsAccountBalance,
      garnishmentYesNo: data.haveAnyGarnishments,
      garnishmentAmount: data.garnishmentAmount,
      garnishmentWith: data.garnishmentWith,
      garnishmentBoth: hasCobuyer ? data.garnishmentBoth : BuyerTypeBoth.Buyer,
      oweIrsYesNo: data.oweIrs,
      oweIrsAmount: data.oweIrsAmount,
      oweIrsBoth: hasCobuyer ? data.oweIrsBoth : BuyerTypeBoth.Buyer,
      bankruptcyBoth: hasCobuyer ? data.bankruptcyBoth : BuyerTypeBoth.Buyer,
      bankruptcyDischargedDate:
        data.bankruptcyDischargedDate && data.bankruptcyDischargedDate !== 'Invalid Date'
          ? data.bankruptcyDischargedDate
          : null,
      bankruptcyDischargedYesNo: data.bankruptcyDischargedYesNo,
      bankruptcyYesNo: data.everFiledForBankruptcy,
      rideSharingBoth: hasCobuyer ? data.rideSharingBoth : BuyerTypeBoth.Buyer,
      rideSharingYesNo: data.driveForRideSharing,
    };
    await salesService.updateLegalQuestions(payload);
  };

  const updateIncomeAssessment = async (data: typeof formDefaultValues) => {
    const payload: IncomeAssessment = {
      appRecId: appRecId,
      buyerTotalMonthlyNetIncome: data.buyerMonthlyNetIncome,
      buyerRentMortgage: data.buyerRentOrMortgage,
      buyerOtherAutoPayment: data.buyerOtherAutoPayment,
      buyerAutoInsurancePayment: data.buyerAutoInsurancePayment,
      buyerElectric: data.buyerElectric,
      buyerUtilityGas: data.buyerUtilityGas,
      buyerCreditCards: data.buyerCreditCards,
      buyerMisc: data.buyerMiscellaneous,
      buyerStudentLoans: data.buyerStudentLoans,
      buyerChildSupport: data.buyerAlimonyChildSupport,
      cobuyerTotalMonthlyNetIncome: hasCobuyer ? data.coBuyerMonthlyNetIncome : undefined,
      cobuyerRentMortgage: hasCobuyer ? data.coBuyerRentOrMortgage : undefined,
      cobuyerOtherAutoPayment: hasCobuyer ? data.coBuyerOtherAutoPayment : undefined,
      cobuyerAutoInsurancePayment: hasCobuyer ? data.coBuyerAutoInsurancePayment : undefined,
      cobuyerElectric: hasCobuyer ? data.coBuyerElectric : undefined,
      cobuyerUtilityGas: hasCobuyer ? data.coBuyerUtilityGas : undefined,
      cobuyerCreditCards: hasCobuyer ? data.coBuyerCreditCards : undefined,
      cobuyerMisc: hasCobuyer ? data.coBuyerMiscellaneous : undefined,
      cobuyerStudentLoans: hasCobuyer ? data.coBuyerStudentLoans : undefined,
      cobuyerChildSupport: hasCobuyer ? data.coBuyerAlimonyChildSupport : undefined,
      buyerCreditScoreEquifax: data.buyerEquifax,
      buyerCreditScoreTransUnion: data.buyerTransUnion,
      buyerCreditScoreExperian: data.buyerExperian,
      cobuyerCreditScoreEquifax: hasCobuyer ? data.coBuyerEquifax : undefined,
      cobuyerCreditScoreTransUnion: hasCobuyer ? data.coBuyerTransUnion : undefined,
      cobuyerCreditScoreExperian: hasCobuyer ? data.coBuyerExperian : undefined,
      buyerDisposableIncome: data.buyerDisposableIncome,
      cobuyerDisposableIncome: hasCobuyer ? data.coBuyerDisposableIncome : undefined,
    };

    await salesService.updateIncomeAssessment(payload);
  };

  const updateInterviewQuestions = async (data: typeof formDefaultValues) => {
    const payload: InterviewQuestions = {
      appRecId: appRecId,
      improveCreditScore: data.interviewQuestionOne,
      creditCoach: data.interviewQuestionTwo ? data.interviewQuestionTwo : undefined,
      earlyRefinance: data.interviewQuestionThree,
      creditDifficulties:
        data.interviewQuestionFour === InterviewQuestionCreditDifficultiesValues.Yes
          ? true
          : data.interviewQuestionFour === InterviewQuestionCreditDifficultiesValues.No
          ? false
          : undefined,
      coverageInsurance:
        data.interviewQuestionFive === InterviewQuestionCoverageInsuranceValues.Yes
          ? true
          : data.interviewQuestionFive === InterviewQuestionCoverageInsuranceValues.No
          ? false
          : undefined,
      estimatedMileage: data.interviewQuestionSix,
    };

    await salesService.updateInterviewQuestions(payload);
  };

  const upsertApplicationScoring = async () => {
    const salesScoringSelected: SalesScoringDto[] = underwritingScore
      ? underwritingScore
          ?.filter((value) => value.answers.find((answer) => answer.selected))
          .map((value) => {
            const answer = value.answers.find((answer) => answer.selected);
            return { questionId: value.id, answerId: answer!.id, score: answer!.score };
          })
      : [];

    const payload: SalesScoringGridDto = {
      appRecId: appRecId,
      salesScorings: salesScoringSelected,
    };

    await salesService.upsertApplicationScoring(payload);
  };

  const updateUnderwriting = async (data: typeof formDefaultValues) => {
    setUpdateUnderwritingLoading(true);
    try {
      await updateIncomeAssessment(data);
      await updateLegalQuestions(data);
      await updateInterviewQuestions(data);
      await upsertApplicationScoring();
      toast.success('Underwriting Updated');
      await dispatch(getSaleData(appRecId));
      setCurrentTab('Underwriting');
    } finally {
      setUpdateUnderwritingLoading(false);
    }
  };

  useEffect(() => {
    reset({
      buyerSsn: saleData.buyer?.b_SSN,
      buyerDriversLicenseNumber: saleData.buyer?.b_DL,
      buyerDriversExpiration: dayjs(saleData.buyer?.b_DLEXP ?? '').format('YYYY-MM-DD'),
      interviewQuestionOne: saleData.sale?.interviewQuestionImproveCreditScore,
      interviewQuestionTwo: saleData.sale?.interviewQuestionCreditCoach,
      interviewQuestionThree: saleData.sale?.interviewQuestionEarlyRefinance,
      interviewQuestionFour:
        saleData.sale?.interviewQuestionCreditDifficulties === true
          ? InterviewQuestionCreditDifficultiesValues.Yes
          : saleData.sale?.interviewQuestionCreditDifficulties === false
          ? InterviewQuestionCreditDifficultiesValues.No
          : undefined,
      interviewQuestionFive:
        saleData.sale?.interviewQuestionCoverageInsurance === true
          ? InterviewQuestionCoverageInsuranceValues.Yes
          : saleData.sale?.interviewQuestionCoverageInsurance === false
          ? InterviewQuestionCoverageInsuranceValues.No
          : undefined,
      interviewQuestionSix: saleData.sale?.interviewQuestionEstimatedMileage,
      buyerMonthlyNetIncome: saleData.sale?.netPay || 0,
      coBuyerMonthlyNetIncome: saleData.sale?.netPay1 || 0,
      totalMonthlyNetIncome: 0,
      buyerRentOrMortgage: saleData.sale?.billRent || 0,
      coBuyerRentOrMortgage: saleData.sale?.billRent1 || 0,
      totalRentOrMortgage: 0,
      buyerOtherAutoPayment: saleData.sale?.billAuto || 0,
      coBuyerOtherAutoPayment: saleData.sale?.billAuto1 || 0,
      totalOtherAutoPayment: 0,
      buyerAutoInsurancePayment: saleData.sale?.billIns || 0,
      coBuyerAutoInsurancePayment: saleData.sale?.billIns1 || 0,
      totalAutoInsurancePayment: 0,
      buyerElectric: saleData.sale?.billElect || 0,
      coBuyerElectric: saleData.sale?.billElect1 || 0,
      totalElectric: 0,
      buyerUtilityGas: saleData.sale?.billUtil || 0,
      coBuyerUtilityGas: saleData.sale?.billUtil1 || 0,
      totalUtilityGas: 0,
      buyerCreditCards: saleData.sale?.billCC || 0,
      coBuyerCreditCards: saleData.sale?.billCC1 || 0,
      totalCreditCards: 0,
      buyerMiscellaneous: saleData.sale?.billOther || 0,
      coBuyerMiscellaneous: saleData.sale?.billOther1 || 0,
      totalMiscellaneous: 0,
      buyerStudentLoans: saleData.sale?.billStudentLoans || 0,
      coBuyerStudentLoans: saleData.sale?.billStudentLoans1 || 0,
      totalStudentLoans: 0,
      buyerAlimonyChildSupport: saleData.sale?.billAlimony || 0,
      coBuyerAlimonyChildSupport: saleData.sale?.billAlimony1 || 0,
      totalAlimonyChildSupport: 0,
      buyerTotalExpenses: 0,
      coBuyerTotalExpenses: 0,
      totalExpenses: 0,
      buyerDisposableIncome: saleData.sale?.disposableIncome || 0,
      coBuyerDisposableIncome: saleData.sale?.disposableIncome1 || 0,
      totalDisposableIncome: 0,
      maximumAllowedWeekly: undefined,
      maximumAllowedMonthly: undefined,
      maximumAllowedBiWeekly: undefined,
      maximumAllowedSemiMonthly: undefined,
      buyerEquifax: saleData.sale?.b_EQUIFAX,
      coBuyerEquifax: saleData.sale?.cB_EQUIFAX,
      buyerTransUnion: saleData.sale?.b_TRANSUNION,
      coBuyerTransUnion: saleData.sale?.cB_TRANSUNION,
      buyerExperian: saleData.sale?.b_EXPERIAN,
      coBuyerExperian: saleData.sale?.cB_EXPERIAN,
      checkingAccountBoth: saleData.sale?.ckbcBoth,
      checkingAccountWith: saleData.sale?.ckWith,
      checkingAccountBalance: saleData.sale?.ckBal,
      savingsAccountBoth: saleData.sale?.svbcBoth,
      savingsAccountWith: saleData.sale?.svWith,
      savingsAccountBalance: saleData.sale?.svBal,
      garnishmentBoth: saleData.sale?.grbcBoth,
      garnishmentAmount: saleData.sale?.grAmount,
      garnishmentWith: saleData.sale?.grWith,
      oweIrsBoth: saleData.sale?.irbcBoth,
      oweIrsAmount: saleData.sale?.irAmount,
      bankruptcyBoth: saleData.sale?.bkbcBoth,
      bankruptcyDischargedYesNo: saleData.sale?.bkDisYesNo,
      bankruptcyDischargedDate: dayjs(saleData.sale?.bkDisDate).format('YYYY-MM-DD'),
      ownCheckingAccount: saleData.sale?.ckYesNo,
      ownSavingsAccount: saleData.sale?.svYesNo,
      driveForRideSharing: saleData.sale?.rsYesNo,
      rideSharingBoth: saleData.sale?.rsbcBoth,
      haveAnyGarnishments: saleData.sale?.grYesNo,
      oweIrs: saleData.sale?.irYesNo,
      everFiledForBankruptcy: saleData.sale?.bkYesNo,
    });
  }, []);

  useEffect(() => {
    setValue(
      'totalMonthlyNetIncome',
      watch('buyerMonthlyNetIncome') + watch('coBuyerMonthlyNetIncome')
    );
  }, [watch('buyerMonthlyNetIncome'), watch('coBuyerMonthlyNetIncome')]);

  useEffect(() => {
    setValue('totalRentOrMortgage', watch('buyerRentOrMortgage') + watch('coBuyerRentOrMortgage'));
  }, [watch('buyerRentOrMortgage'), watch('coBuyerRentOrMortgage')]);

  useEffect(() => {
    setValue(
      'totalOtherAutoPayment',
      watch('buyerOtherAutoPayment') + watch('coBuyerOtherAutoPayment')
    );
  }, [watch('buyerOtherAutoPayment'), watch('coBuyerOtherAutoPayment')]);

  useEffect(() => {
    setValue(
      'totalAutoInsurancePayment',
      watch('buyerAutoInsurancePayment') + watch('coBuyerAutoInsurancePayment')
    );
  }, [watch('buyerAutoInsurancePayment'), watch('coBuyerAutoInsurancePayment')]);

  useEffect(() => {
    setValue('totalElectric', watch('buyerElectric') + watch('coBuyerElectric'));
  }, [watch('buyerElectric'), watch('coBuyerElectric')]);

  useEffect(() => {
    setValue('totalUtilityGas', watch('buyerUtilityGas') + watch('coBuyerUtilityGas'));
  }, [watch('buyerUtilityGas'), watch('coBuyerUtilityGas')]);

  useEffect(() => {
    setValue('totalCreditCards', watch('buyerCreditCards') + watch('coBuyerCreditCards'));
  }, [watch('buyerCreditCards'), watch('coBuyerCreditCards')]);

  useEffect(() => {
    setValue('totalMiscellaneous', watch('buyerMiscellaneous') + watch('coBuyerMiscellaneous'));
  }, [watch('buyerMiscellaneous'), watch('coBuyerMiscellaneous')]);

  useEffect(() => {
    setValue('totalStudentLoans', watch('buyerStudentLoans') + watch('coBuyerStudentLoans'));
  }, [watch('buyerStudentLoans'), watch('coBuyerStudentLoans')]);

  useEffect(() => {
    setValue(
      'totalAlimonyChildSupport',
      watch('buyerAlimonyChildSupport') + watch('coBuyerAlimonyChildSupport')
    );
  }, [watch('buyerAlimonyChildSupport'), watch('coBuyerAlimonyChildSupport')]);

  useEffect(() => {
    setValue(
      'buyerTotalExpenses',
      watch('buyerRentOrMortgage') +
        watch('buyerOtherAutoPayment') +
        watch('buyerAutoInsurancePayment') +
        watch('buyerElectric') +
        watch('buyerUtilityGas') +
        watch('buyerCreditCards') +
        watch('buyerMiscellaneous') +
        watch('buyerStudentLoans') +
        watch('buyerAlimonyChildSupport')
    );
  }, [
    watch('buyerRentOrMortgage'),
    watch('buyerOtherAutoPayment'),
    watch('buyerAutoInsurancePayment'),
    watch('buyerElectric'),
    watch('buyerUtilityGas'),
    watch('buyerCreditCards'),
    watch('buyerMiscellaneous'),
    watch('buyerStudentLoans'),
    watch('buyerAlimonyChildSupport'),
  ]);

  useEffect(() => {
    setValue(
      'coBuyerTotalExpenses',
      watch('coBuyerRentOrMortgage') +
        watch('coBuyerOtherAutoPayment') +
        watch('coBuyerAutoInsurancePayment') +
        watch('coBuyerElectric') +
        watch('coBuyerUtilityGas') +
        watch('coBuyerCreditCards') +
        watch('coBuyerMiscellaneous') +
        watch('coBuyerStudentLoans') +
        watch('coBuyerAlimonyChildSupport')
    );
  }, [
    watch('coBuyerRentOrMortgage'),
    watch('coBuyerOtherAutoPayment'),
    watch('coBuyerAutoInsurancePayment'),
    watch('coBuyerElectric'),
    watch('coBuyerUtilityGas'),
    watch('coBuyerCreditCards'),
    watch('coBuyerMiscellaneous'),
    watch('coBuyerStudentLoans'),
    watch('coBuyerAlimonyChildSupport'),
  ]);

  useEffect(() => {
    setValue(
      'totalExpenses',
      watch('totalRentOrMortgage') +
        watch('totalOtherAutoPayment') +
        watch('totalAutoInsurancePayment') +
        watch('totalElectric') +
        watch('totalUtilityGas') +
        watch('totalCreditCards') +
        watch('totalMiscellaneous') +
        watch('totalStudentLoans') +
        watch('totalAlimonyChildSupport')
    );
  }, [
    watch('totalRentOrMortgage'),
    watch('totalOtherAutoPayment'),
    watch('totalAutoInsurancePayment'),
    watch('totalElectric'),
    watch('totalUtilityGas'),
    watch('totalCreditCards'),
    watch('totalMiscellaneous'),
    watch('totalStudentLoans'),
    watch('totalAlimonyChildSupport'),
  ]);

  useEffect(() => {
    setValue('buyerDisposableIncome', watch('buyerMonthlyNetIncome') - watch('buyerTotalExpenses'));
  }, [watch('buyerMonthlyNetIncome'), watch('buyerTotalExpenses')]);

  useEffect(() => {
    setValue(
      'coBuyerDisposableIncome',
      watch('coBuyerMonthlyNetIncome') - watch('coBuyerTotalExpenses')
    );
  }, [watch('coBuyerMonthlyNetIncome'), watch('coBuyerTotalExpenses')]);

  useEffect(() => {
    setValue('totalDisposableIncome', watch('totalMonthlyNetIncome') - watch('totalExpenses'));
  }, [watch('totalMonthlyNetIncome'), watch('totalExpenses')]);

  useEffect(() => {
    setValue(
      'maximumAllowedWeekly',
      calculateMaximumAllowedPayment('Weekly', watch('totalMonthlyNetIncome'), companyUnderwriting)
    );
    setValue(
      'maximumAllowedBiWeekly',
      calculateMaximumAllowedPayment(
        'Bi-Weekly',
        watch('totalMonthlyNetIncome'),
        companyUnderwriting
      )
    );
    setValue(
      'maximumAllowedSemiMonthly',
      calculateMaximumAllowedPayment(
        'Semi-Monthly',
        watch('totalMonthlyNetIncome'),
        companyUnderwriting
      )
    );
    setValue(
      'maximumAllowedMonthly',
      calculateMaximumAllowedPayment('Monthly', watch('totalMonthlyNetIncome'), companyUnderwriting)
    );
  }, [watch('totalMonthlyNetIncome')]);

  return {
    control,
    handleSubmit,
    updateUnderwriting,
    updateUnderwritingLoading,
    hasCobuyer,
    watch,
    buyerCobuyerBothData,
    saleData,
    errors,
    isDisabled,
  };
};
