import { GpsHistoryItemDeprec } from '@/interfaces';
import { accountsService } from '@/services/accountsService';
import { inventoryService } from '@/services/inventoryService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent,
  Loader,
} from '@progress/kendo-react-all';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const columns: GridColumnProps[] = [
  {
    field: 'lastPing',
    title: 'Ping Date',
    cell: (props) => {
      const { lastPing } = props.dataItem as GpsHistoryItemDeprec;
      return (
        <td className="k-table-td">{formatDate(lastPing, { pattern: DateFormat.DateTime })}</td>
      );
    },
  },
  {
    field: 'address',
    title: 'Address',
  },
  {
    field: 'latitude',
    title: 'Lat',
    width: 'auto',
  },
  {
    field: 'longitude',
    title: 'Long',
  },
  {
    field: 'odometer',
    title: 'Odometer',
  },
  {
    field: 'speed',
    title: 'Speed',
  },
];

export const GpsHistory = (props: { isInventory?: boolean }) => {
  const { isInventory = false } = props;
  const params = useParams();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(false);
  const [gpsHistory, setGpsHistory] = useState<GpsHistoryItemDeprec[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const recId = isInventory ? Number(params.invRecId) : Number(params.colRecId);

  const getGpsHistory = () => {
    const pg = skip / take + 1;

    if (isInventory) {
      const payload = {
        invRecId: recId,
        page: pg,
        perPage: take,
      };

      setLoading(true);
      inventoryService
        .getGpsHistory(payload)
        .then((res) => {
          setGpsHistory(res.data);
          setTotalItems(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const payload = {
        colRecId: recId,
        page: pg,
        perPage: take,
      };

      setLoading(true);
      accountsService
        .getGpsHistory(payload)
        .then((res) => {
          setGpsHistory(res.data);
          setTotalItems(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  useEffect(() => {
    getGpsHistory();
  }, [skip, take]);

  return (
    <Grid
      data={gpsHistory}
      skip={skip}
      take={take}
      total={totalItems}
      pageable={{
        buttonCount: 4,
        pageSizes: [10, 25, 50, 100],
      }}
      onPageChange={pageChange}
      style={{
        maxHeight: '100%',
      }}
    >
      <GridNoRecords>
        <div>{loading ? <Loader size="large" /> : 'No location history found'}</div>
      </GridNoRecords>
      {columns.map((column) => {
        return (
          <GridColumn
            field={column.field}
            title={column.title}
            key={column.field}
            width="auto"
            cell={column.cell}
          />
        );
      })}
    </Grid>
  );
};
