import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
// import RadioGroup from '@mui/material/RadioGroup';
// import Radio from '@mui/material/Radio';
import { MKTextInput } from '@/mui/components/form/MuiKendoTextField';

/** ###  */
const SamList: FC = () => {
  return (
    <Grid
      container
      direction="column"
      flex={1}
      alignItems="start"
      justifyContent="start"
      height="100%"
      p={2}
    >
      <FormControl>
        <FormLabel id="radio-buttons-group-label" sx={{ color: 'black', fontWeight: 600 }}>
          SAM
        </FormLabel>
        {/* @note keeping for reference 
        <RadioGroup row aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
          <FormControlLabel value="Active" control={<Radio />} label="Active" />
          <FormControlLabel value="Dismissed" control={<Radio />} label="Dismissed" />
          <FormControlLabel value="Complete" control={<Radio />} label="Complete" />
        </RadioGroup> */}
      </FormControl>
      <Grid container flex={1} width="100%">
        <MKTextInput multiline fullWidth sx={{ mx: 0, backgroundColor: 'white' }} disabled />
      </Grid>
    </Grid>
  );
};

export default SamList;
