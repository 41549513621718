import React, { useState } from 'react';
// interfaces
import { Icons } from '@/components/icons';
// style
import styles from './DmsDropDown.module.scss';

type CustomValue = { display: string; value: string };
interface DropDownProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  values?: string[];
  customValues?: CustomValue[];
  label: string;
  required?: boolean;
  labelContainerStyle?: React.CSSProperties;
  inputContainerStyle?: React.CSSProperties;
}

export const DmsDropDown: React.FC<DropDownProps> = (props: DropDownProps): JSX.Element => {
  const {
    values,
    customValues,
    label,
    required,
    labelContainerStyle = {},
    inputContainerStyle = {},
    ...restSelectProps
  } = props;

  const finalValues = customValues
    ? customValues
    : values
    ? values!.map((x) => ({ display: x, value: x }))
    : [];

  const [selectedValue, setSelectedValue] = useState<string>(finalValues[0]?.value ?? '');

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer} style={labelContainerStyle}>
        <div className={styles.label}>{label}</div>
        {required && <Icons.Star className={styles.icon} />}
      </div>
      <select
        className={styles.select}
        value={selectedValue}
        style={inputContainerStyle}
        onChange={(e) => {
          setSelectedValue(e.target.value);
        }}
        {...restSelectProps}
      >
        {finalValues.map((value, idx) => (
          <option value={value.value} key={idx}>
            {value.display}
          </option>
        ))}
      </select>
    </div>
  );
};
