import { FC } from "react";
// mui
import { TextTemplate } from "@/services/collectionsService";

/** ###  */
const TextTemplateToolTip: FC<{ data: TextTemplate }> = ({ data }) => (
  <div className="dontClose">
    <div>
      <strong>Body: </strong>
    </div>
    <pre>{data.body}</pre>
  </div>
);

export default TextTemplateToolTip;
