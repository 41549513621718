import { FC } from "react";
// components
import PaymentForm from "./PaymentForm";
// kendo
import { AccountsMainPanel } from "./accountsMainPanel/AccountsMainPanel";

/**
 * Form state is initialized with data from `getPaymentDetails()`
 * Most of the payment submit payload is contained in the form state
 * Some fields shown in the form are not directly part of the form state - the ones not wrapped in a <Controller />
 * - `totalPayment` which is not part of the submit payload but needs to display;
 * - `employee` which is an object that many of the submit payload fields are populated by;
 *
 * Currently this supports the following payment types:
 * 1. Regular payments ("Take A Payment" in old accounts, collection payments), as indicated by an empty `paymentType`
 * 2. Principal Only, as indicated by `paymentType` === 'PrinOnly'
 */
const AccountDetailPaymentForm: FC<{ isPrinOnly?: boolean }> = ({ isPrinOnly }) => (
  <AccountsMainPanel navBarTitle={isPrinOnly ? "Principal Only Payment" : "Take a Payment"}>
    <PaymentForm isPrinOnly={isPrinOnly} />
  </AccountsMainPanel>
);

export default AccountDetailPaymentForm;
