// interfaces
import { PmtFieldValues, PmtFormCb, PmtFormCbParams, PmtFormEffectCb } from "./interfaces";

type CarPmtEffectParams = Omit<Partial<PmtFormCbParams>, "form"> & {
  form: Pick<PmtFormCbParams["form"], "totalPayment" | "cpiPaid" | "lcPaid" | "ddPmt" | "nsfPaid">;
};
export const calcCarPmtEffect = ({
  form: { cpiPaid, ddPmt, lcPaid, nsfPaid, totalPayment },
}: CarPmtEffectParams): PmtFieldValues["carPmt"] =>
  totalPayment - (cpiPaid + lcPaid + ddPmt + nsfPaid);

export const calcTotalReceived: PmtFormCb = ({ form, calculated, external }) => {
  const { totalPayment, totalReceived, carPmt } = form;
  const { isPrincipalOnly, isMiscPmt } = external;

  if (isMiscPmt) {
    return totalPayment;
  }
  if (isPrincipalOnly) {
    if (calculated.isCcOrAch) return carPmt + (calculated.convFee ?? 0);

    return carPmt;
  }

  if (calculated.isCcOrAch) return totalPayment + (calculated.convFee ?? 0);
  if (totalPayment > totalReceived) return totalPayment;

  return totalReceived;
};
export const calcTotalReceivedOnTotalPaymentChange: PmtFormCb = ({
  form,
  calculated,
  external,
}) => {
  const newTotalReceived = calcTotalReceived({ form, calculated, external });
  if (!external.isMiscPmt && !external.isPrincipalOnly && !calculated.isCcOrAch) {
    return form.totalPayment;
  }

  return newTotalReceived;
};

const calcLcPaidInLcPaidEffect: PmtFormCb = ({ form, external }) => {
  if (external.isPrincipalOnly) return form.lcPaid;
  if (form.lcPaid < 0) return 0;

  const maxLateCharge = external.lcDue;
  if (form.lcPaid > maxLateCharge) return maxLateCharge;

  return form.lcPaid;
};
const calcLcWaivedInLcPaidEffect: PmtFormCb = ({ form, external }) => {
  if (external.isPrincipalOnly) return form.lcWaived;
  if (form.lcPaid < 0) return 0;

  const maxLateCharge = external.lcDue;

  if (form.lcPaid > maxLateCharge) return 0;
  else if (form.lcPaid + form.lcWaived > maxLateCharge) {
    return maxLateCharge - form.lcPaid;
  }

  return form.lcWaived;
};
export const lcPaidEffect: PmtFormEffectCb = (params) => {
  if (params.external.isPrincipalOnly) return {} as Partial<PmtFieldValues>;

  return {
    carPmt: calcCarPmtEffect(params),
    lcPaid: calcLcPaidInLcPaidEffect(params),
    lcWaived: calcLcWaivedInLcPaidEffect(params),
    totalReceived: calcTotalReceived(params),
  } as Partial<PmtFieldValues>;
};
export const lcWaivedEffect: PmtFormEffectCb = ({ form, external }) => {
  if (external.isPrincipalOnly) return {};

  const maxLateCharge = external.lcDue;

  if (form.lcWaived > maxLateCharge) {
    return { lcPaid: 0, lcWaived: maxLateCharge };
  } else if (form.lcWaived < 0) {
    return { lcPaid: maxLateCharge, lcWaived: 0 };
  } else if (form.lcPaid + form.lcWaived > maxLateCharge) {
    return { lcPaid: maxLateCharge - form.lcWaived };
  }
  return {};
};

export const transactionTypeEffect: PmtFormEffectCb = ({ form, external }) => {
  const { transactionType } = form;
  const { cpiDueNow, ddDueNow, dmsNextDueAmount, lcDue, maxPayment, nsfDue, payOff, pmtDue } =
    external;
  return {
    carPmt: transactionType === "Payoff" ? payOff : pmtDue,
    cpiPaid: cpiDueNow,
    lcPaid: lcDue,
    ddPmt: ddDueNow,
    nsfPaid: nsfDue,
    totalReceived: transactionType === "Payoff" ? maxPayment : dmsNextDueAmount,
    totalPayment: transactionType === "Payoff" ? maxPayment : dmsNextDueAmount,
  };

  return {};
};
