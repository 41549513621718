import { Button } from '@/components';
import { Column } from '@/components/table/TableInterface';
import { getSaleDataNoLoading } from '@/features/Sales/salesActionCreator';
import { TradeIn } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { inventoryService } from '@/services/inventoryService';
import { useAppDispatch } from '@/store/store';
import { formatCurrency } from '@/utils/helpers/general';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useTradeIn = () => {
  const params = useParams();
  const appRecId = Number(params.id);
  const dispatch = useAppDispatch();
  const [tradeInList, setTradeInList] = useState([] as TradeIn[]);
  const [activeTradeIn, setActiveTradeIn] = useState<TradeIn | undefined>(undefined);
  const [editTradeInModalOpen, setEditTradeInModalOpen] = useState(false);
  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalPayoff, setTotalPayoff] = useState(0);
  const [totalNetTrade, setTotalNetTrade] = useState(0);
  const [submitTradeInLoading, setSubmitTradeInLoading] = useState(false);
  const [deleteTradeInLoading, setDeleteTradeInLoading] = useState(false);
  const [getTradeInLoading, setGetTradeInLoading] = useState(false);
  const formDefaultValues = {
    allowance: null as unknown as number,
    acv: null as unknown as number,
    payoff: null as unknown as number,
    netTrade: null as unknown as number,
    vin: '',
    year: null as unknown as number,
    make: '',
    model: '',
    color: '',
    bodyType: '',
    vehicleType: '',
    miles: null as unknown as number,
    notActual: false,
    exceedsLimits: false,
    printExempt: false,
    nameOnTitle: '',
    owedTo: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    contact: '',
    phone: '',
    actualPayoff: null as unknown as number,
    goodUntil: '',
    perDiem: null as unknown as number,
  };
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const editTradeInForm = useForm({ defaultValues: formDefaultValues });

  const EditTradeInButton = (props: any) => {
    const value: TradeIn = props.dataItem;
    return (
      <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button label="Edit" onClick={() => openEditTradeInModal(value)} />
      </td>
    );
  };

  const CurrencyCell = (props: any) => {
    const value = props.dataItem[props.field];
    return <td {...props}>{formatCurrency(value)}</td>;
  };

  const tradeInColumns: Column[] = [
    {
      field: 'year',
      title: 'Year',
    },
    {
      field: 'make',
      title: 'Make',
    },
    {
      field: 'model',
      title: 'Model',
    },
    {
      field: 'vin',
      title: 'VIN',
    },
    {
      field: 'allowance',
      title: 'Allowance',
      cells: { data: CurrencyCell },
    },
    {
      field: 'payoff',
      title: 'Payoff',
      cells: { data: CurrencyCell },
    },
    {
      field: 'netTrade',
      title: 'Net-Trade',
      cells: { data: CurrencyCell },
    },
    {
      field: 'editTradeIn',
      title: ' ',
      cells: { data: EditTradeInButton },
    },
  ];

  const openEditTradeInModal = (tradeIn: TradeIn) => {
    setActiveTradeIn(tradeIn);
    editTradeInForm.reset({
      allowance: tradeIn.allowance,
      acv: tradeIn.acv,
      payoff: tradeIn.payoff,
      netTrade: tradeIn.netTrade,
      vin: tradeIn.vin,
      year: Number(tradeIn.year),
      make: tradeIn.make,
      model: tradeIn.model,
      color: tradeIn.color,
      bodyType: tradeIn.bodyType,
      vehicleType: tradeIn.vehicleType,
      miles: Number(tradeIn.miles),
      notActual: tradeIn.notActual,
      exceedsLimits: tradeIn.exceedsLimits,
      printExempt: tradeIn.printExempt,
      nameOnTitle: tradeIn.nameOnTitle,
      owedTo: tradeIn.owedTo,
      address: tradeIn.payoffAddress,
      city: tradeIn.payoffCity,
      state: tradeIn.payoffState,
      zip: tradeIn.payoffZip || '',
      contact: tradeIn.payoffContact,
      phone: tradeIn.payoffPhone || '',
      actualPayoff: tradeIn.actualPayoff,
      goodUntil: dayjs(tradeIn.goodUntil).utc().format('YYYY-MM-DD'),
      perDiem: tradeIn.payoffPerDiem,
    });
    setEditTradeInModalOpen(true);
  };

  const closeEditTradeInModal = () => {
    setActiveTradeIn(undefined);
    editTradeInForm.reset();
    setEditTradeInModalOpen(false);
  };

  const getTotalValues = () => {
    let totalAllowance = 0;
    let totalPayoff = 0;
    let totalNetTrade = 0;

    tradeInList.forEach((value) => {
      totalAllowance += value.allowance;

      if (value.payoff) {
        totalPayoff += value.payoff;
      }

      if (value.netTrade) {
        totalNetTrade += value.netTrade;
      }
    });

    setTotalAllowance(totalAllowance);
    setTotalPayoff(totalPayoff);
    setTotalNetTrade(totalNetTrade);
  };

  const getTradeInList = async () => {
    await salesService
      .getTradeInList(appRecId)
      .then((res) => {
        setTradeInList(res);
      })
      .catch(() => toast.error('There was an error receiving the trade-in list'));
  };

  const addTradeIn = (data: typeof formDefaultValues) => {
    setSubmitTradeInLoading(true);
    salesService
      .postTradeIn({
        appRecId: appRecId,
        allowance: data.allowance,
        acv: data.acv,
        payoff: data.payoff,
        netTrade: data.netTrade,
        vin: data.vin,
        year: data.year ? data.year.toString() : undefined,
        make: data.make,
        model: data.model,
        color: data.color,
        bodyType: data.bodyType,
        vehicleType: data.vehicleType,
        miles: data.miles ? data.miles.toString() : undefined,
        notActual: data.notActual,
        exceedsLimits: data.exceedsLimits,
        printExempt: data.printExempt,
        nameOnTitle: data.nameOnTitle,
        owedTo: data.owedTo,
        payoffAddress: data.address,
        payoffCity: data.city,
        payoffZip: data.zip,
        payoffState: data.state,
        payoffContact: data.contact,
        payoffPhone: data.phone,
        actualPayoff: data.actualPayoff,
        payoffPerDiem: data.perDiem,
      })
      .then(() => {
        getTradeInList();
        dispatch(getSaleDataNoLoading(appRecId));
        toast.success('Trade-In Vehicle Added');
      })
      .catch(() => toast.error('Unable to add trade-in vehicle'))
      .finally(() => setSubmitTradeInLoading(false));
  };

  useEffect(() => {
    if (!submitTradeInLoading && isSubmitSuccessful) {
      reset(formDefaultValues);
    }
  }, [submitTradeInLoading]);

  const updateTradeIn = (data: typeof formDefaultValues) => {
    setSubmitTradeInLoading(true);
    salesService
      .updateTradeIn({
        appRecId: appRecId,
        recId: activeTradeIn!.recId,
        allowance: data.allowance,
        acv: data.acv,
        payoff: data.payoff,
        netTrade: data.netTrade,
        vin: data.vin,
        year: data.year ? data.year.toString() : undefined,
        make: data.make,
        model: data.model,
        color: data.color,
        bodyType: data.bodyType,
        vehicleType: data.vehicleType,
        miles: data.miles ? data.miles.toString() : undefined,
        notActual: data.notActual,
        exceedsLimits: data.exceedsLimits,
        printExempt: data.printExempt,
        nameOnTitle: data.nameOnTitle,
        owedTo: data.owedTo,
        payoffAddress: data.address,
        payoffCity: data.city,
        payoffZip: data.zip,
        payoffState: data.state,
        payoffContact: data.contact,
        payoffPhone: data.phone,
        actualPayoff: data.actualPayoff,
        payoffPerDiem: data.perDiem,
      })
      .then(() => {
        setActiveTradeIn(undefined);
        setEditTradeInModalOpen(false);
        getTradeInList();
        dispatch(getSaleDataNoLoading(appRecId));
        toast.success('Updated Trade-In Vehicle');
      })
      .catch(() => toast.error('Unable to update trade-in vehicle'))
      .finally(() => setSubmitTradeInLoading(false));
  };

  const deleteTradeIn = async (recId: number) => {
    setDeleteTradeInLoading(true);
    await salesService
      .deleteTradeIn(recId, appRecId)
      .then(() => {
        setActiveTradeIn(undefined);
        setEditTradeInModalOpen(false);
        toast.success('Trade-In Vehicle Deleted');
        getTotalValues();
      })
      .catch(() => toast.error('Unable to delete trade-in vehicle'))
      .finally(() => setDeleteTradeInLoading(false));
    setGetTradeInLoading(true);
    await getTradeInList();
    setGetTradeInLoading(false);
    dispatch(getSaleDataNoLoading(appRecId));
  };

  const getVinAuditData = async () => {
    const validVin = await trigger('vin');
    if (!validVin) return;
    const vin = watch('vin');
    setValue('year', undefined as unknown as number);
    setValue('make', '');
    setValue('model', '');
    inventoryService.vinAuditDecode(vin).then((res) => {
      // The API doesn't throw an error if decoding is unsuccessful, so we'll just have to determine it based on whether most values are null
      if (!res.year && !res.make && !res.model) {
        toast.error('Unable to decode VIN');
      }
      if (res?.year) setValue('year', Number(res.year));
      if (res?.make) setValue('make', res.make);
      if (res?.model) setValue('model', res.model);
      if (res?.vehicleStyle) setValue('bodyType', res.vehicleStyle);
      if (res?.vehicleType) setValue('vehicleType', res.vehicleType);
    });
  };

  useEffect(() => {
    setGetTradeInLoading(true);
    getTradeInList();
    setGetTradeInLoading(false);
  }, [appRecId]);

  useEffect(() => {
    setValue('netTrade', (watch('allowance') || 0) - (watch('payoff') || 0));
  }, [watch('allowance'), watch('payoff')]);

  useEffect(() => {
    editTradeInForm.setValue(
      'netTrade',
      (editTradeInForm.watch('allowance') || 0) - (editTradeInForm.watch('payoff') || 0)
    );
  }, [editTradeInForm.watch('allowance'), editTradeInForm.watch('payoff')]);

  useEffect(() => {
    getTotalValues();
  }, [tradeInList]);

  return {
    control,
    watch,
    tradeInList,
    tradeInColumns,
    handleSubmit,
    addTradeIn,
    errors,
    updateTradeIn,
    deleteTradeIn,
    editTradeInModalOpen,
    editTradeInForm,
    closeEditTradeInModal,
    activeTradeIn,
    totalAllowance,
    totalPayoff,
    totalNetTrade,
    submitTradeInLoading,
    deleteTradeInLoading,
    getTradeInLoading,
    getVinAuditData,
  };
};
