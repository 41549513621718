// PhotoGallery.tsx
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Skeleton } from '@mui/material';

interface PhotoGalleryProps {
  photos: { url: string; title: string }[];
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos }) => {
  return (
    <ImageList sx={{ width: '100%', height: 'auto', justifyItems: 'center' }} cols={3} rowHeight={164}>
      {photos.reverse().map((photo, index) => (
        <ImageListItem key={index} sx={{
              width: 210,
              height: 118,
            }}>
          {photo ? (
            <img
              style={{
                width: 210,
                height: 118,
                alignSelf: 'center',
              }}
              src={photo.url}
              alt={photo.title}
              loading="lazy"
              onError={(e) => {
                (e.target as HTMLImageElement).src = 'path_to_placeholder_image.jpg'; // Provide a placeholder image URL
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )}
          <ImageListItemBar
            title={photo.title}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default PhotoGallery;
