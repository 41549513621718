import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// kendo
import { RadioGroupInput } from "@/components/inputs/radioGroupInput/RadioGroupInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { Checkbox } from "@/components/checkbox/Checkbox";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
import { SavedPmtMethod } from "@/interfaces";
import { ZMpd } from "../paymentSchemas";

const UseSavedPmtField: FC = () => {
  const { enabledPmtMethods, isAch } = usePmtFormCtx((s) => s);
  const setField = usePmtFormCtx((s) => s.formState.setField);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("useSavedPaymentMethod"));

  const acctCardTxt = isAch ? "Account" : "Card";
  const options = [
    { label: `New ${acctCardTxt}`, value: 0 },
    { label: `${acctCardTxt} on file`, value: 1 },
  ];

  return (
    <RadioGroupInput
      label="Payment Method"
      data={options}
      layout="horizontal"
      {...fieldProps}
      // @note HTML element requires a `number`
      value={Number(fieldProps.value)}
      // @note Form state requires a `boolean`
      onChange={(e) => setField("useSavedPaymentMethod", Boolean(e.value))}
      disabled={!enabledPmtMethods || enabledPmtMethods.length < 1}
      // @todo fix component
      style={{
        height: 29,
        rowGap: 0,
        flexWrap: "nowrap",
        fontSize: 13,
        columnGap: 4,
      }}
      inputStyles={{ rowGap: 0 }}
    />
  );
};

const SaveCardField: FC = () => {
  const isAch = usePmtFormCtx((s) => s.isAch);
  const isCliq = usePmtFormCtx((s) => s.formState.form.provider === "Cliq");
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("saveCard"));

  // The cliq condition is temporary while the form is being updated.
  // @todo move all instances of this component to openedge and repay modals
  if (isCliq) return <></>;

  const acctCardTxt = isAch ? "Account" : "Card";
  return <Checkbox label={`Save ${acctCardTxt}`} {...fieldProps} />;
};

const MpdRecIdField: FC = () => {
  const { enabledPmtMethods, isAch, selectedPmtMethod } = usePmtFormCtx((s) => s);
  const setField = usePmtFormCtx((s) => s.formState.setField);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("mpdRecId"));

  // @todo remove after calc is added to registerOn
  if (!enabledPmtMethods) return <></>;

  return (
    <Grid container direction="column" gap={1.5} hidden={fieldProps.hidden}>
      <DropdownInput
        label={isAch ? "Accounts" : "Cards"}
        required
        data={enabledPmtMethods}
        dataItemKey="recId"
        textField="last4"
        {...fieldProps}
        onChange={(e) => {
          const mpd: SavedPmtMethod | null = e.value;
          setField("mpdRecId", mpd?.recId ?? null);
          setField(
            "mpd",
            ZMpd.safeParse({ token: mpd?.mpdId, lastFour: mpd?.last4, brand: mpd?.cardType })
              .data ?? null
          );
        }}
      />

      <TextInput label="First Name" readOnly value={selectedPmtMethod?.fName ?? null} />
      <TextInput label="Last Name" readOnly value={selectedPmtMethod?.lName ?? null} />
    </Grid>
  );
};

const PaymentMethodFields: FC = () => [
  <UseSavedPmtField key="useSavedPaymentMethod" />,
  <MpdRecIdField key="mpdRecId" />,
  <SaveCardField key="savecard" />,
];
export default PaymentMethodFields;
