import { useEffect, useRef } from 'react';

interface ClickOutsideProps {
  children: React.ReactNode;
  onClick: () => void;
  exceptionRef?: React.MutableRefObject<any>;
  outsideClickClassExceptions?: string[];
}

export const ClickOutside = (props: ClickOutsideProps) => {
  const { children, onClick, exceptionRef, outsideClickClassExceptions } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener);

    return () => {
      document.removeEventListener('mousedown', handleClickListener);
    };
  }, []);

  const handleClickListener = (event: any) => {
    let clickedInside;
    let classExceptions = [];

    if (outsideClickClassExceptions) {
      classExceptions = outsideClickClassExceptions;
    } else {
      //Hacky fix for kendo dropdowns
      classExceptions = ['dontClose', 'k-list-item'];
    }

    if (exceptionRef) {
      clickedInside =
        (wrapperRef && wrapperRef.current!.contains(event.target)) ||
        exceptionRef.current === event.target ||
        exceptionRef.current.contains(event.target);
    } else {
      clickedInside = wrapperRef && wrapperRef.current!.contains(event.target);
    }

    classExceptions?.forEach((className) => {
      if (typeof event.target?.className === 'string' && event.target?.className?.includes(className)) {
        clickedInside = true;
      }
    });

    if (clickedInside) return;
    else onClick();
  };

  return <div ref={wrapperRef}>{children}</div>;
};
