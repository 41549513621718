import { useAuthSelector } from '@/features/auth/authSlice';

const useEmailFeatureFlag = () => {
  const userEmail = useAuthSelector((s) => s.userEmail?.toLowerCase());

  const isDcsEmail = userEmail?.endsWith('@dclteam.com') || false;
  const isAfsEmail = userEmail?.endsWith('@afsdealers.com') || false;

  return isDcsEmail || isAfsEmail;
};

export default useEmailFeatureFlag;
