import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import styles from './AccountInfo.module.scss';
import { GetPaymentData } from '@/services/paymentService';
dayjs.extend(utc);

interface Props {
  paymentDetails?: GetPaymentData;
}

const formatAddress = (addr?: string, city?: string, state?: string, zip?: string) => {
  return `${addr ? addr + ', ' : ''}${city ? city + ', ' : ''}${state ? state + ' ' : ''}${
    zip ?? ''
  }`;
};

export const AccountInfo: React.FC<Props> = (props) => {
  if (!props.paymentDetails) return null;

  const {
    buyerFirstName = '',
    buyerLastName = '',
    buyerDob = '',
    buyerSsn = '',
    buyerAddr = '',
    buyerCity = '',
    buyerState = '',
    buyerZip = '',
    buyerCPhone = '',
    buyerHPhone = '',
    buyerWPhone = '',
    buyerEmployer = '',

    coBuyerRecId,
    cobuyerFirstName = '',
    cobuyerLastName = '',
    cobuyerDob = '',
    cobuyerSsn = '',
    cobuyerAddr = '',
    cobuyerCity = '',
    cobuyerState = '',
    cobuyerZip = '',
    cobuyerCPhone = '',
    cobuyerHPhone = '',
    cobuyerWPhone = '',
    cobuyerEmployer = '',
  } = props.paymentDetails;

  const buyerName = `${buyerFirstName} ${buyerLastName}`;
  const buyerAddress = formatAddress(buyerAddr, buyerCity, buyerState, buyerZip);

  const cobuyerName = `${cobuyerFirstName} ${cobuyerLastName}`;
  const cobuyerAddress = formatAddress(cobuyerAddr, cobuyerCity, cobuyerState, cobuyerZip);

  return (
    <div className={styles.container}>
      <div className={styles.name}>{buyerName}</div>
      <ul className={styles.list}>
        <li>
          <strong>B-DOB:</strong> {buyerDob ? dayjs(buyerDob).utc().format('M/D/YYYY') : ''}
        </li>
        <li>
          <strong>B-SSN:</strong> {buyerSsn ?? ''}
        </li>
        <li>
          <strong>Address:</strong> {buyerAddress}
        </li>
        <li>
          <strong>Cell:</strong> {buyerCPhone}
        </li>
        <li>
          <strong>Home:</strong> {buyerHPhone}
        </li>
        <li>
          <strong>Work:</strong> {buyerWPhone}
        </li>
        <li>
          <strong>Employer:</strong> {buyerEmployer}
        </li>
      </ul>
      {!!coBuyerRecId && !!cobuyerFirstName && (
        <>
          <div className={styles.name}>{cobuyerName}</div>
          <ul className={styles.list}>
            <li>
              <strong>CB-DOB:</strong>{' '}
              {cobuyerDob ? dayjs(cobuyerDob).utc().format('M/D/YYYY') : ''}
            </li>
            <li>
              <strong>CB-SSN:</strong> {cobuyerSsn ?? ''}
            </li>
            <li>
              <strong>Address:</strong> {cobuyerAddress}
            </li>
            <li>
              <strong>Cell:</strong> {cobuyerCPhone}
            </li>
            <li>
              <strong>Home:</strong> {cobuyerHPhone}
            </li>
            <li>
              <strong>Work:</strong> {cobuyerWPhone}
            </li>
            <li>
              <strong>Employer:</strong> {cobuyerEmployer}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};
