import { FC, PropsWithChildren, createContext, useEffect } from "react";
import { useMatch } from "react-router-dom";
// utils
import { companyService } from "@/services/companyService";
import { userAuthService } from "@/services/userAuthService";
import { getRouteParamNum } from "@/utils/routing/formatting";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useReq from "@/utils/useReq";
// interfaces
import { RoleDb, RoleRow } from "./interfaces/role";
import { UserAuthRow } from "./interfaces/user";

const useCtxState = () => {
  const roles = useReq(async () => {
    const rolesRes = await userAuthService.getRoleTemplatesForOrg();
    return rolesRes?.map((r) => new RoleDb(r));
  });
  const users = useReq(async () => await userAuthService.getUsersByOrgId());
  const companies = useReq(async () => await companyService.getCompanies());

  useEffect(() => {
    companies.load();
    roles.load();
    users.load();
  }, []);

  // Getters
  const roleRows = roles.value?.map((role) => new RoleRow(role, companies.value ?? [])) ?? [];
  const userRows =
    users.value?.map((user) => new UserAuthRow(user, roleRows, companies.value ?? [])) ?? [];

  // @note `useMatch` is used instead of `useParams` due to the JSX-scope of this provider, relative to the router
  const activeRecId = getRouteParamNum(
    useMatch({ path: "/:root/:viewRoot/:currentView/:recId" })?.params.recId
  );
  const activeUser = userRows.find((user) => user.recId === activeRecId) || null;
  const activeTemplate = roleRows.find((role) => role.recId === activeRecId) || null;

  return {
    // Req states
    roles,
    users,
    companies,

    // Getters
    userRows,
    roleRows,
    activeTemplate,
    activeUser,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);
export const useSettingsViewCtx = useCtxFactory(Ctx);

const SettingsViewProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SettingsViewProvider;
