type PctInput = number | string | undefined | null;
type FmtPctOptions = { is0to100?: boolean; decimalPlaces?: number; placeholder?: string };

const defaultOptInput = { decimalPlaces: 2, is0to100: false, placeholder: "- %" };

export const formatPercent: (percentValue: PctInput, options?: FmtPctOptions) => string = (
  percentValue,
  { decimalPlaces = 2, is0to100 = false, placeholder = "- %" } = defaultOptInput
) => {
  // Handle non-numeric strings
  const isInvalidStr = typeof percentValue === "string" && isNaN(Number(percentValue));
  if (isInvalidStr) return placeholder;

  if (typeof percentValue === "number" || typeof percentValue === "string") {
    // Scale values between 0-1 to be 100-base
    const scale = is0to100 ? 1 : 100;

    // Set decimal precision
    const roundedPercentage = (Number(percentValue) * scale).toFixed(decimalPlaces);

    return roundedPercentage + "%";
  }

  return placeholder;
};

export function formatPhone(inputPhone: string) {
  const cleaned = ("" + inputPhone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
}

export const lowerCaseLetters = (s: string | undefined | null) =>
  !s ? "" : s.replace(/[^a-zA-Z]/g, "").toLowerCase();

/** Given two strings, compare alphabetic characters (case-insensitive).
 * @note Comparison ignores any non-alphabetic characters.
 */
export const compareLettersIgnoringCase = (
  str1: string | null | undefined,
  str2: string | null | undefined
) => lowerCaseLetters(str1) === lowerCaseLetters(str2);
