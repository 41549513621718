import { Button } from '@/components';
import { FC, useState } from 'react';
import { useBuyersGuideCtx } from './BuyersGuideProvider';
import { inventoryService } from '@/services/inventoryService';
import { useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export type PrintType = 'Internal' | 'External';

interface PrintButtonProps {
  isDisabled: boolean;
}

export const PrintButton: FC<PrintButtonProps> = ({ isDisabled }) => {
  const { invRecId } = useParams();
  const bgCurrent = useBuyersGuideCtx((s) => s.bgCurrent);
  const languageFilter = useBuyersGuideCtx((s) => s.languageFilter);
  const printLoading = useBuyersGuideCtx((s) => s.printLoading);
  const setPrintLoading = useBuyersGuideCtx((s) => s.setPrintLoading);
  // const [printLoading, setPrintLoading] = useState<boolean>(false);

  const handlePrint = async () => {
    setPrintLoading(true);
    const buyersGuideDoc = await inventoryService.printBuyersGuideDoc({
      invRecId: Number(invRecId),
      printSpanish: languageFilter == 'Spanish'
    });
    window.open(buyersGuideDoc);
    setPrintLoading(false);
  };

  return (
      <Button
        label="Print Buyer's Guide"
        type="button"
        secondary
        disabled={isDisabled}
        onClick={() => handlePrint()}
        buttonStyle={{ width: '134px' }}
        loading={printLoading}
      />
  );
};
