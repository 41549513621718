const filterFields = [
  'appRecId',
  'purPrice',
  'saleDate',
  'soldPrice',
  'stockNum',
  'totExp',
] as const;

export type LhPhFundingFilterKey = (typeof filterFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const lhPhFundingFilterFields: LhPhFundingFilterKey[] = [...filterFields];

const sortFields = ['appRecId', 'purPrice', 'saleDate', 'soldPrice', 'stockNum', 'totExp'] as const;

export type LhPhFundingSortKey = (typeof sortFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const lhPhFundingSortFields: LhPhFundingSortKey[] = [...sortFields];
