import { Button } from '@/components';
import { BasicTable } from '@/components/table/Table';
import { Link } from 'react-router-dom';
import { SectionHeader } from '@components/sectionHeader/SectionHeader';
import salesTabStyles from '../SalesTab.module.scss';
import { useSecureClose } from './useSecureClose';

export const SecureClose = () => {
  const { secureCloseColumns, secureClosePresentations } = useSecureClose();
  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>Secure Close</h2>
        <Link to="../" relative="path">
          <Button label="Documents" />
        </Link>
      </header>
      <main className={salesTabStyles.body}>
        <SectionHeader title="Secure Close Information" />
        <BasicTable data={secureClosePresentations} columns={secureCloseColumns} />
      </main>
    </div>
  );
};
