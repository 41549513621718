// interfaces
import { AlertConfig, AlertType } from "./interfaces";

export const alertConfigMap: { [key in AlertType]: AlertConfig } = {
  recommendation: new AlertConfig({
    color: "#2946952a",
    pdfColor: "#cfd3df",
    label: "Recommendations",
  }),
  error: new AlertConfig({
    color: "#ff00002a",
    pdfColor: "#f5c6c6",
    label: "Errors",
  }),
  warning: new AlertConfig({
    color: "#c7a3122a",
    pdfColor: "#e7e0c4",
    label: "Warnings",
  }),
  notice: new AlertConfig({
    color: "#0080002a",
    pdfColor: "#c1e2c1",
    label: "Notice",
  }),
  other: new AlertConfig({
    color: "#0000002a",
    pdfColor: "#c9c9c9",
    label: "Other",
  }),
};
