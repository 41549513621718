import { FC, ReactNode } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';

/** ###  */
const SectionColumn: FC<Omit<Grid2Props, 'children'> & { children?: ReactNode | ReactNode[] }> = ({
  children,
  sx,
  ...props
}) => (
  <Grid container direction="column" flex={1} gap={1} {...props} sx={sx}>
    {children}
  </Grid>
);

export default SectionColumn;
