import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import dayjs from 'dayjs';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
import { Icons } from '@/components/icons';
// utils
import { formatCurrency, formatPercentage } from '@/utils/helpers/general';
// interfaces
import { InventoryListRow } from '@/interfaces/Inventory';
// style
import styles from './Checkbox.module.scss';
import vinStyles from './VinCell.module.scss';

export const ServiceCell: FC<GridCellProps> = ({ dataItem }) => {
  const { scOpen } = dataItem;
  return (
    <td className="k-table-td">
      <span>{scOpen ? <Icons.Service style={{ width: 14, height: 14 }} /> : '--'}</span>
    </td>
  );
};

export const BoolCell: FC<GridCellProps & { fieldCheck: string }> = ({ fieldCheck, ...props }) => {
  const value = props.dataItem[fieldCheck];
  return (
    <td className="k-table-td">
      <div className={styles.boolCell}>
        <div>{value ? <FaCheckCircle className={styles.icon} /> : '--'}</div>
      </div>
    </td>
  );
};

export const CurrencyCell: FC<GridCellProps & { fieldCheck: string }> = ({
  fieldCheck,
  ...props
}) => {
  const value = props.dataItem[fieldCheck];
  return (
    <td className="k-table-td">
      <div style={{ color: '#737373', display: 'flex', justifyContent: 'right' }}>
        {formatCurrency(value)}
      </div>
    </td>
  );
};

export const PercentageCell: FC<GridCellProps & { fieldCheck: string }> = ({
  fieldCheck,
  ...props
}) => {
  const value = props.dataItem[fieldCheck];
  return (
    <td className="k-table-td-2">
      <div style={{ color: '#737373', display: 'flex', justifyContent: 'right' }}>
        {formatPercentage(value)}
      </div>
    </td>
  );
};

export const DateCell: FC<GridCellProps & { fieldCheck: string }> = ({ fieldCheck, ...props }) => {
  const value = props.dataItem[fieldCheck];
  return (
    <td className="k-table-td" style={{ color: '#737373' }}>
      {value ? dayjs(value).format('MM/DD/YYYY') : ''}
    </td>
  );
};

export const InvDaysCell: FC<GridCellProps & { fieldCheck: string }> = ({
  fieldCheck,
  ...props
}) => {
  const value = props.dataItem[fieldCheck];
  let days;
  if (value) {
    const date1 = dayjs(value);
    const date2 = dayjs();
    const hours = date2.diff(date1, 'hours');
    days = Math.floor(hours / 24).toString();
  } else {
    days = '';
  }
  return (
    <td className="k-table-td" style={{ color: '#737373' }}>
      {days}
    </td>
  );
};

export const LinkCell: FC<GridCellProps> = ({ dataItem }) => {
  const { recId, stockNum } = dataItem as InventoryListRow;
  const location = useLocation();
  const listPath = location.pathname.split('/').reverse()[0];

  return (
    <td className="k-table-td">
      <Link to={`/inventory/${recId}/vehicle?list=${listPath}`} className={styles.link}>
        {stockNum}
      </Link>
    </td>
  );
};

export const VinCell: FC<GridCellProps> = ({ dataItem }) => {
  const { invAlerts, vin } = dataItem as InventoryListRow;

  return (
    <td className="k-table-td">
      <div className={invAlerts ? vinStyles.hasAlerts : ''}>{vin}</div>
    </td>
  );
};
