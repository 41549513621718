import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { z } from 'zod';
// interfaces
import { IPaginatedReq } from '@/interfaces/requests';
import { AcctsSubviewKey } from '../viewInfo';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

/** ## An account as its represented in the database table `Accounts` */
export class AccountDb {
  constructor(
    public accountNum: number,
    public amtDue: number | null,
    public prinBal: number | null,
    public appRecId: number,
    public assignment: string,
    public buyerRecId: string,
    public buyers: string,
    /** Co-buyer */
    public coBuyRecId: string,
    /** Vehicle lot */
    public code3: string,
    public colType: string,
    public daysLate: number,
    public dueNow: number,
    public mainLocation: number,
    public recId: number,
    public residual: number,
    public saleType: SaleTypeKey,
    public shortName: string,
    public spStatus: SpStatusKey | null,
    public stockNum: string,
    public vehicle: string,
    public vehRecId: number,
    public vin: string,
    // OPTIONAL PARAMS
    /** Buyer cell-phone */
    public bcPhone?: string,
    /** Buyer home-phone */
    public bhPhone?: string,
    public buyerSsn?: string,
    /** Co-buyer cell-phone */
    public ccPhone?: string,
    public chgOffCat?: string,
    /** String, in date format */
    public chgOffDate?: string,
    /** Co-buyer home-phone */
    public chPhone?: string,
    public coBuySsn?: string,
    public compId?: number,
    public cpiStatus?: string,
    /** String, in date format */
    public dateSold?: string,
    /** String, in date format */
    public iopDate?: string,
    /** String, in date format */
    public iopSaleDate?: string,
    public gpsCode?: number,
    /** String, in date format */
    public lastPmtPaid?: string,
    public locId?: number,
    public mafc_Loc?: number,
    public manLocation?: number,
    public orgId?: number,
    public sc?: string,
    public status?: number
  ) {}
}

export const newAccountDb = (account: AccountDb) => {
  // @note should this use the `formatDateTz()` function to avoid unpredictable timezone issues?
  // Convert date-strings to correct format
  const dateSold =
    account.dateSold === null ? '' : dayjs(account.dateSold).utc().format('M/D/YYYY');
  const iopDate = account.iopDate === null ? '' : dayjs(account.iopDate).utc().format('M/D/YYYY');
  const iopSaleDate =
    account.iopSaleDate === null ? '' : dayjs(account.iopSaleDate).utc().format('M/D/YYYY');
  const lastPmtPaid =
    account.lastPmtPaid === null ? '' : dayjs(account.lastPmtPaid).utc().format('M/D/YYYY');
  const chgOffDate =
    account.chgOffDate === null ? '' : dayjs(account.chgOffDate).utc().format('M/D/YYYY');

  return new AccountDb(
    account.accountNum,
    account.amtDue,
    account.prinBal,
    account.appRecId,
    account.assignment,
    account.buyerRecId,
    account.buyers,
    account.coBuyRecId,
    account.code3,
    account.colType,
    account.daysLate,
    account.dueNow,
    account.mainLocation,
    account.recId,
    account.residual,
    account.saleType,
    account.shortName,
    account.spStatus,
    account.stockNum,
    account.vehicle,
    account.vehRecId,
    account.vin,
    // OPTIONAL PARAMS
    account.bcPhone,
    account.bhPhone,
    account.buyerSsn,
    account.ccPhone,
    account.chgOffCat,
    chgOffDate,
    account.chPhone,
    account.coBuySsn,
    account.compId,
    account.cpiStatus,
    dateSold,
    iopDate,
    iopSaleDate,
    account.gpsCode,
    lastPmtPaid,
    account.locId,
    account.mafc_Loc,
    account.manLocation,
    account.orgId,
    account.sc,
    account.status
  );
};

export enum SaleTypeEnum {
  'Wholesale' = 'Wholesale',
  'Sidenote' = 'Sidenote',
  'Lease' = 'Lease',
  'Finance' = 'Finance',
  'Down' = 'Deferred Down',
  'Deposit' = 'Deposit',
  'CashSale' = 'Cash Sale',
  'Cash' = 'Cash Down',
}
export type SaleTypeKey = keyof typeof SaleTypeEnum;
// @note temporary - need to find an efficient way to use the enum as a fxn/generic param
export const saleTypeMapping = new Map<SaleTypeKey, { value: SaleTypeKey; label: SaleTypeEnum }>([
  ['Wholesale', { value: 'Wholesale', label: SaleTypeEnum.Wholesale }],
  ['Sidenote', { value: 'Sidenote', label: SaleTypeEnum.Sidenote }],
  ['Lease', { value: 'Lease', label: SaleTypeEnum.Lease }],
  ['Finance', { value: 'Finance', label: SaleTypeEnum.Finance }],
  ['Down', { value: 'Down', label: SaleTypeEnum.Down }],
  ['Deposit', { value: 'Deposit', label: SaleTypeEnum.Deposit }],
  ['CashSale', { value: 'CashSale', label: SaleTypeEnum.CashSale }],
  ['Cash', { value: 'Cash', label: SaleTypeEnum.Cash }],
]);

// @todo implement enum and mapping
// @note `spStatus` column - no clue what the difference is between this and `saleType`
export enum SpStatusEnum {
  'Wholesale' = 'Wholesale',
  'Installment' = 'Installment',
  'Lease Down' = 'Lease Down',
  'Vehicle Deposit' = 'Vehicle Deposit',
  'Deferred Down' = 'Deferred Down',
  'Deposit' = 'Deposit',
  'CashSale' = 'Cash Sale',
  'Cash Down' = 'Cash Down',
}
export type SpStatusKey = keyof typeof SpStatusEnum;
// @note temporary - need to find an efficient way to use the enum as a fxn/generic param
export const spStatusMapping = new Map<SpStatusKey, { value: SpStatusKey; label: SpStatusEnum }>([
  ['Wholesale', { value: 'Wholesale', label: SpStatusEnum.Wholesale }],
  ['Installment', { value: 'Installment', label: SpStatusEnum.Installment }],
  ['Lease Down', { value: 'Lease Down', label: SpStatusEnum['Lease Down'] }],
  ['Vehicle Deposit', { value: 'Vehicle Deposit', label: SpStatusEnum['Vehicle Deposit'] }],
  ['Deferred Down', { value: 'Deferred Down', label: SpStatusEnum['Deferred Down'] }],
  ['Deposit', { value: 'Deposit', label: SpStatusEnum.Deposit }],
  ['CashSale', { value: 'CashSale', label: SpStatusEnum.CashSale }],
  ['Cash Down', { value: 'Cash Down', label: SpStatusEnum['Cash Down'] }],
]);

export enum LaStatusEnum {
  'Active' = 'Active',
  'ChargeOff' = 'Charge Off',
  'ResidualDue' = 'Residual Due',
}
export type LaStatusKey = keyof typeof LaStatusEnum;
// @note temporary - need to find an efficient way to use the enum as a fxn/generic param
export const laStatusMapping = new Map<LaStatusKey, { value: LaStatusKey; label: LaStatusEnum }>([
  ['Active', { value: 'Active', label: LaStatusEnum.Active }],
  ['ChargeOff', { value: 'ChargeOff', label: LaStatusEnum.ChargeOff }],
  ['ResidualDue', { value: 'ResidualDue', label: LaStatusEnum.ResidualDue }],
]);

// status @todo implement enum and mapping
// "Charged Off", "Inactive", "Active", "Residual Due", "In Active",

/** ## Accounts fetch all request - paginated */
export interface AccountsGetPgReq extends IPaginatedReq {
  subview: AcctsSubviewKey;
  search: string | null;
}
/** ## Accounts fetch all response - paginated */
export interface AccountsGetPgRes {
  total: number;
  page: number;
  perPage: number;
  data: AccountDb[];
}

export class OptInData {
  constructor(
    public recId: number | null = null,
    public phoneNumberE164: string | null = null,
    public orgId: number | null = null,
    public dcWorkflowBeginUtc: Date | null = null,
    public dcWorkflowEndUtc: Date | null = null,
    public dcWorkflowExpiresUtc: Date | null = null,
    public dcWorkflowRequestCount: number | null = null,
    public directConsentGranted: boolean | null = null,
    public optOutReminderSentUtc: Date | null = null
  ) {}
  static new(newOptInData: OptInData | null): OptInData {
    return new OptInData(
      newOptInData?.recId,
      newOptInData?.phoneNumberE164,
      newOptInData?.orgId,
      newOptInData?.dcWorkflowBeginUtc,
      newOptInData?.dcWorkflowEndUtc,
      newOptInData?.dcWorkflowExpiresUtc,
      newOptInData?.dcWorkflowRequestCount,
      newOptInData?.directConsentGranted,
      newOptInData?.optOutReminderSentUtc
    );
  }
}

export const OptStatus = z.enum(["accepted", "unsent", "denied", "pending", "error"]);
export type OptStatus = z.infer<typeof OptStatus>;
