import { FC } from 'react';
// utils
import { DateFormat, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerContactHistory } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardContactHistory: FC<{ contactHistory: LedgerContactHistory[] }> = ({
  contactHistory,
}) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Contact History</div>
    <table style={ledgerStyles.ledgerTable}>
      <tbody>
        {contactHistory.map((ch, idx) => {
          return (
            <tr key={idx} style={ledgerStyles.pmtHistoryRow}>
              <td>{formatDate(ch.contactOn, { pattern: DateFormat.DateFullTime })}</td>
              <td>{ch.transType}</td>
              <td>{ch.detailShort}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </>
);

export default LedgerCardContactHistory;
