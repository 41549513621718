import { FC } from "react";
import { Controller } from "react-hook-form";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { NumberInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";

const TaxName: FC = () => {
  return (
    <Grid container direction="column" flex={1}>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ visibility: "hidden", height: "30.5px", display: "flex", alignItems: "center" }}
      >
        {"Where"}
      </Grid>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ height: "38px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}
      >
        {"State"}
      </Grid>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ height: "38px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}
      >
        {"County"}
      </Grid>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ height: "38px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}
      >
        {"City"}
      </Grid>
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ height: "38px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}
      >
        {"Location"}
      </Grid>
    </Grid>
  );
};

const TaxRate: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Rate"}
      </Grid>
      <Controller
        name="stateRate"
        control={control}
        render={({ field }) => <NumberInput isPercentage isPercentageLarge {...field} />}
      />
      <Controller
        name="countyRate"
        control={control}
        render={({ field }) => <NumberInput isPercentage isPercentageLarge {...field} />}
      />
      <Controller
        name="cityRate"
        control={control}
        render={({ field }) => <NumberInput isPercentage isPercentageLarge {...field} />}
      />
      <Controller
        name="locRate"
        control={control}
        render={({ field }) => <NumberInput isPercentage isPercentageLarge {...field} />}
      />
    </Grid>
  );
};

const TaxOn: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"On"}
      </Grid>
      <Controller
        name="stateOn"
        control={control}
        render={({ field }) => (
          <DropdownInput
            {...field}
            value={field.value}
            // Set data to the keys of the recurringIntervalValues object
            data={["Sales Price", "Sales Price Less Trade"]}
            // containerStyle={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="countyOn"
        control={control}
        render={({ field }) => (
          <DropdownInput
            {...field}
            value={field.value}
            // Set data to the keys of the recurringIntervalValues object
            data={["Sales Price", "Sales Price Less Trade"]}
            // containerStyle={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="cityOn"
        control={control}
        render={({ field }) => (
          <DropdownInput
            {...field}
            value={field.value}
            // Set data to the keys of the recurringIntervalValues object
            data={["Sales Price", "Sales Price Less Trade"]}
            // containerStyle={{ width: "100%" }}
          />
        )}
      />
      <Controller
        name="locOn"
        control={control}
        render={({ field }) => (
          <DropdownInput
            {...field}
            value={field.value}
            // Set data to the keys of the recurringIntervalValues object
            data={["Sales Price", "Sales Price Less Trade"]}
            // containerStyle={{ width: "100%" }}
          />
        )}
      />
    </Grid>
  );
};

const TaxMax: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Max"}
      </Grid>
      <Controller
        name="stateMax"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="countyMax"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="cityMax"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="locMax"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
    </Grid>
  );
};

const TaxMin: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" flex={1}>
      <Grid
        flex={1}
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Min"}
      </Grid>
      <Controller
        name="stateMin"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="countyMin"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="cityMin"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
      <Controller
        name="locMin"
        control={control}
        render={({ field }) => (
          <CurrencyInput {...field} containerStyle={{ paddingTop: "1px", paddingBottom: "1px" }} />
        )}
      />
    </Grid>
  );
};

const TaxMaxTrade: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" flex={1} alignItems="stretch">
      <Grid
        fontSize="15px"
        fontWeight="700"
        sx={{ display: "flex", alignSelf: "center", paddingBottom: "8px" }}
      >
        {"Max Trade"}
      </Grid>

      <Grid sx={{ width: "100%" }}>
        <Controller
          name="maxTradeAllow"
          control={control}
          render={({ field }) => (
            <CurrencyInput
              {...field}
              containerStyle={{
                paddingTop: "1px",
                paddingBottom: "1px",
                width: "100%", // Ensures the input stretches
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

/** ###  */
const TaxFields: FC = () => [
  <TaxName key="nameTaxFields" />,
  <TaxRate key="rateTaxFields" />,
  <TaxOn key="onTaxFields" />,
  <TaxMax key="maxTaxFields" />,
  <TaxMin key="minTaxFields" />,
  <TaxMaxTrade key="maxTradeFields" />,
];

export default TaxFields;
