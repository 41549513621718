import { FC } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
// components
import ColumnHeader from './ColumnHeader';

/** ###  */
const ColumnLayout: FC<Grid2Props & { title: string }> = ({ title, children, ...props }) => {
  return (
    <Grid
      container
      direction="column"
      // minWidth={90}
      // width={90}
      alignItems="start"
      justifyContent="space-between"
      wrap="nowrap"
      gap={1}
      {...props}
    >
      <ColumnHeader>{title}</ColumnHeader>
      {children}
    </Grid>
  );
};

export default ColumnLayout;
