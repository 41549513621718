import { FC } from "react";
import { Link, useParams } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DCS_BLUE, BORDER_GREY } from "@/mui/theme/colors";
// state
import { useSalesSelector } from "../../salesSlice";
import { SaleType } from "@/enums/general";

const isNotNewSale = (newSale: boolean): boolean => !newSale;
const credEduCond = (newSale: boolean, saleType?: SaleType): boolean =>
  isNotNewSale(newSale) && saleType === "Lease";
const isNotCashSaleCond = (newSale: boolean, saleType?: SaleType): boolean =>
 isNotNewSale(newSale) && saleType !== "Cash";
const isCashSale = (newSale: boolean, saleType?: SaleType): boolean =>
 isNotNewSale(newSale) && saleType === "Cash";
const applicationLabel = (saleType?: SaleType): string =>
saleType === "Cash" ? "Buyer Info" : "Application";

const navOptions = [
  { value: "sale-management", label: "Sale Management", shouldDisplay: () => true },
  { value: "creditReports", label: "Credit Reports", shouldDisplay: isNotCashSaleCond }, // !newSale and !cashSale
  { value: "application", label: "Application", shouldDisplay: isNotCashSaleCond }, // !newSale and !cashSale
  { value: "buyer-info", label: "Buyer Info", shouldDisplay: isCashSale },
  { value: "verification", label: "Verification", shouldDisplay: isNotCashSaleCond }, // !newSale and !cashSale
  { value: "vehicleAndTempTag", label: "Vehicle & Temp Tag", shouldDisplay: isNotNewSale }, // !newSale
  { value: "terms", label: "Terms/Quotes", shouldDisplay: isNotNewSale }, // !newSale
  { value: "documents", label: "Documents", shouldDisplay: isNotNewSale }, // !newSale
  { value: "creditEducation", label: "Credit Education", shouldDisplay: credEduCond }, // !newSale && saleType === "Lease"
];

const useRouteMatch = (patterns: readonly string[]) => {
 const tabRoute = useParams()["*"];
 for (let idx = 0; idx < patterns.length; idx++) {
   const origRoute = patterns[idx];
   if (origRoute === tabRoute) return origRoute;
 }
 return null;
};

const NavTabs: FC = () => {
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);
  const saleData = useSalesSelector((s) => s.saleData);

  const newSale = useParams().id === "newSale";
  const saleType = saleData.sale?.saletype;

  const appliedNavOptions = navOptions.filter((o) => o.shouldDisplay(newSale, saleType));
  const currentTab =
    useRouteMatch(appliedNavOptions.map((t) => t.value)) || appliedNavOptions[0]?.value;

 // Build
  const tabElems = appliedNavOptions.map((t) => (
    <Tab
      label={t.label}
      value={t.value}
      role="navigation"
      component={Link}
      to={"./" + t.value}
      relative="path"
      sx={{
        // Container styles
        alignItems: "start",
        borderBottom: "1px solid rgba(0, 0, 0, 0.0)",
        padding: "20px 30px",
        // Text styles
        textTransform: "none",
        fontSize: 12,
        fontWeight: 700,
        // Selected tab styles
        "&.MuiTab-root.Mui-selected": {
          color: DCS_BLUE,
          backgroundColor: "rgba(41, 70, 149, 0.2)",
        },
      }}
      key={t.value}
    />
  ));

  return (
    <Tabs
      value={currentTab}
      orientation="vertical"
      variant="scrollable"
      aria-label="sidebar-nav-tabs"
    >
      {tabElems}
    </Tabs>
  );
};

/** ###  */
const SalesDetailNavTabs: FC = () => (
  <Grid
    container
    direction="column"
    // @todo remove all of these
    sx={{ minWidth: "10%", flexShrink: 0, width: "200px", borderRight: `1px solid ${BORDER_GREY}` }}
  >
    <Grid component="h3" sx={{ pl: 4, fontWeight: 700 }}>
      Sales
    </Grid>

    <NavTabs />
  </Grid>
);

export default SalesDetailNavTabs;
