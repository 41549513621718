import { FC, Fragment, useEffect } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioGroupInput } from '../inputs';
// components
import HotlistItem from './HotlistItem';
// state
import { useHotlistCtx } from './HotlistProvider';
// interfaces
import { StatusFilterKey, TransTypeKey, statusFilterKeys } from './types';
// style
import styles from './Hotlist.module.scss';

// @note this allows us to apply a unique display label, while still keeping the ease of access with a lookup key
const filterOptions = statusFilterKeys.map((filterName) => ({
  label: filterName,
  value: filterName,
}));

const HotlistLoading: FC = () => {
  const isLoading = useHotlistCtx((s) => s.isLoading);
  if (!isLoading) return <></>;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" p="40px 0px">
      <Loader themeColor="primary" />
    </Grid>
  );
};

const Hotlist: FC<{
  /** @note Currently only supports AppRecId and VehRecId */
  recId: number | null;
  transType: TransTypeKey;
  initialStatusFilter: StatusFilterKey;
  showStatusFilters?: boolean;
}> = ({ recId, transType, initialStatusFilter, showStatusFilters = false }) => {
  const statusFilter = useHotlistCtx((s) => s.statusFilter);
  const setStatusFilter = useHotlistCtx((s) => s.setStatusFilter);
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);
  const hotlistData = useHotlistCtx((s) => s.hotlistData);
  const isLoading = useHotlistCtx((s) => s.isLoading);

  // Fetch hotlist data with init-status-filter ONLY ON FIRST RENDER - addl fetches happen through external `fetchHotlist` usage OR changing the filter
  useEffect(() => {
    fetchHotlist(recId, transType, initialStatusFilter);
  }, []);
  useEffect(() => {
    showStatusFilters && fetchHotlist(recId, transType, statusFilter);
  }, [statusFilter]);

  // Apply init-filter on first render
  useEffect(() => {
    setStatusFilter(initialStatusFilter);
  }, []);

  // Build hotlist
  const hotlistElems = hotlistData.map((hotlistItem, idx) => (
    <Fragment key={`hli${hotlistItem.hotRecId}`}>
      <HotlistItem hotlistItem={hotlistItem} />
      {idx < hotlistData.length - 1 && <Divider />}
    </Fragment>
  ));

  return (
    <Grid container direction="column">
      {showStatusFilters && (
        <RadioGroupInput
          data={filterOptions}
          layout="horizontal"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.value)}
        />
      )}

      <div className={styles.hotlistContainer}>
        <HotlistLoading />
        {isLoading || <List>{hotlistElems}</List>}
      </div>
    </Grid>
  );
};

export default Hotlist;
