import { useEffect, useState, FocusEvent } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// kendo
import { Spacer } from '@/components/spacer/Spacer';
// components
import { Button } from '@/components/old/button/Button';
import { DmsDropDown } from '@/components/old/dmsDropDown/DmsDropDown';
import { DmsRadioButtonGroup } from '@/components/old/dmsRadioButtonGroup/DmsRadioButtonGroup';
import { EmailReceipt } from '@/components/old/emailReceipt/EmailReceipt';
import { DmsTextInput } from '@/components/old/dmsTextInput/DmsTextInput';
import { DmsNumberInput } from '@/components/old/dmsNumberInput/DmsNumberInput';
import { DmsModal } from '@/components/old/dmsModal/DmsModal';
import { Repay } from '@/components/old/repay/Repay';
import { SavedCreditCard } from '@/components/old/savedCreditCard/SavedCreditCard';
import { SavedACH } from '@/components/old/savedAch/SavedACH';
import { OpenEdge } from '@/components/old/openEdge/OpenEdge';
import { AccountInfo } from '@/components/old/accountInfo/AccountInfo';
import { DmsNotes } from '@/components/old/dmsNotes/DmsNotes';
import SamTasks from '@/components/old/samTasks/SamTasks';
import TextToPay from '@/components/old/textToPay/TextToPay';
// state
import { useOldPaymentSelector } from '../oldPaymentSlice';
// utils
import {
  paymentService,
  GetPaymentData,
  Employee,
  PostPaymentPayload,
  getDefaultPostPaymentPayload,
  PaymentProviders,
} from '@services/paymentService';
import {
  getAchAcctType,
  getAllowedPaymentTypes,
  getCanWaiveFeeDeprec,
  getConvenienceFee,
  getIsNewPayment,
  getMpdToken,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  getSavePayment,
  paymentInRange,
  pollForReceiptOld,
  validateTotalPayment,
} from '@/utils/helpers/payment';
import { UNSAFE_getAlphaWindowCurrentValues } from '@/utils/helpers/alpha';
import { getInitials } from '@/utils/helpers/general';
// interfaces
import { Nullable } from '@utils/types';
import { SavedPmtMethodRes } from '@/interfaces/CreditCard';
import { CardProcessorName, PaymentType, PmtPaidIn } from '@/enums/payment';
// style
import styles from '@features/old/payment/Payment.module.scss';
import levelUpImage from '@assets/level_up.png';
import { PostPaymentPayloadOld } from '../interfaces';

const navigateToList = () => window.ACManage?.panelSetActive('MASTER', true);

export const AcPrinPayment = () => {
  const { colRecId, postPaymentLoading } = useOldPaymentSelector((state) => state);
  const [paymentDetails, setPaymentDetails] = useState<GetPaymentData>();
  const [accepted, setAccepted] = useState<PmtPaidIn>(PmtPaidIn.enum['In-Person']);
  const [inPersonPaymentDetails, setInPersonPaymentDetails] = useState<GetPaymentData>();
  const [amountTendered, setAmountTendered] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [principalBalance, setPrincipalBalance] = useState(0);
  const [refreshing, setRefreshing] = useState(true);
  const [paymentType, setPaymentType] = useState('Cash');
  const [changeDue, setChangeDue] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [employeeArray, setEmployeeArray] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [confirmPostModalOpen, setConfirmPostModalOpen] = useState(false);
  const [repayModal, setRepayModal] = useState<boolean>(false);
  const [iframe, setIframe] = useState<string>('');
  const [paymentPayload, setPaymentPayload] = useState<PostPaymentPayloadOld>();
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [formErrors, setFormErrors] = useState(false);
  const [appRecId, setAppRecId] = useState(0);
  const [buyerEmail, setBuyerEmail] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [includeBuyerEmail, setIncludeBuyerEmail] = useState<boolean>(false);
  const [includeCobuyerEmail, setIncludeCobuyerEmail] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  /** @todo rename to `pmtProvider` - similar field-names: `['processor', ]` */
  const [processor, setProcessor] = useState<CardProcessorName | undefined>();
  const [processors, setProcessors] = useState<CardProcessorName[]>([]);
  const [providerData, setProviderData] = useState<PaymentProviders>({} as PaymentProviders);

  // All payment methods
  const [paymentMethods, setPaymentMethods] = useState<SavedPmtMethodRes[]>();

  // New Card
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [waiveFee, setWaiveFee] = useState(false);
  const [newCard, setNewCard] = useState(true);

  const [saveCard, setSaveCard] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('AL');

  // New ACH
  const [saveAccount, setSaveAccount] = useState(false);
  const [newAccount, setNewAccount] = useState(true);
  const [accountType, setAccountType] = useState('Checking');

  // Existing Payment Method
  const [mpdId, setMpdId] = useState('');
  const [achMpdId, setAchMpdId] = useState('');
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [openEdgeAchModal, setOpenEdgeAchModal] = useState(false);

  const showChangeDue = paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach;

  const paymentTypes = getAllowedPaymentTypes(providerData);

  const { SHORTNAME: UNSAFE_currentEmployeeShortName } = UNSAFE_getAlphaWindowCurrentValues();

  const initData = async () => {
    setRefreshing(true);
    try {
      const pmtDetails = await paymentService.getPaymentDetails(colRecId!);

      paymentService.getPaymentProviders(pmtDetails.colRecId!).then((paymentProviders) => {
        const enabledProviders = getPaymentProviderArray(paymentProviders);
        const preferredProviderName = getPreferredPaymentProviderName(
          paymentProviders.preferredPaymentProvider,
          enabledProviders
        );
        setProviderData(paymentProviders);
        setProcessors(enabledProviders);
        setProcessor(preferredProviderName);
      });

      setPaymentDetails(pmtDetails);
      setInPersonPaymentDetails(pmtDetails);
      setTotalPayment(pmtDetails.pmtDue);
      setAmountTendered(pmtDetails.pmtDue);
      setPrincipalBalance(pmtDetails.prinBal);
      setBuyerEmail(pmtDetails.buyerEmail);
      setFirstName(pmtDetails.buyerFirstName);
      setLastName(pmtDetails.buyerLastName);
      setZip(pmtDetails.buyerZip);
      setAddress(pmtDetails.buyerAddr);
      setCity(pmtDetails.buyerCity);
      setState(pmtDetails.buyerState);
      setAppRecId(pmtDetails.appRecId);
      setIncludeBuyerEmail(pmtDetails.buyerNoEmail);
      setIncludeCobuyerEmail(false);

      await getSavedPayments(pmtDetails.appRecId!);

      const users = await paymentService.getUsersByCompanyId(pmtDetails.compId);
      setEmployeeArray(users);
      const defaultEmployee = users.find(
        (user) => user.shortName === UNSAFE_currentEmployeeShortName
      );
      if (defaultEmployee) {
        setEmployee(defaultEmployee);
      } else {
        setEmployee(users[0]);
      }

      setPaymentPayload(getDefaultPostPaymentPayload(pmtDetails));
    } catch (e) {
      console.error(e);
    }
    setRefreshing(false);
  };

  const getSavedPayments = async (ari: number) => {
    await paymentService.getSavedPaymentMethods(ari).then((res) => {
      setPaymentMethods(res);
    });
  };

  const submit = () => {
    const tempDmsPayload = paymentPayload;

    const paymentToSend = amountTendered;

    const isAch = paymentType === PaymentType.Ach;
    const isCC = paymentType === PaymentType.CreditCard;

    tempDmsPayload!.AchAcctType = getAchAcctType(paymentType, accountType);
    tempDmsPayload!.AchConvFee = isAch ? convenienceFee : 0;
    tempDmsPayload!.BillAddress = address;
    tempDmsPayload!.BillCity = city;
    tempDmsPayload!.BillEmail = buyerEmail;
    tempDmsPayload!.BillFirstName = firstName;
    tempDmsPayload!.BillLastName = lastName;
    tempDmsPayload!.BillState = state;
    tempDmsPayload!.BillZip = zip;
    tempDmsPayload!.CarPmt = totalPayment;
    tempDmsPayload!.CcConvFee = isCC ? convenienceFee : 0;
    tempDmsPayload!.Change = changeDue;
    tempDmsPayload!.ColRecId = colRecId!;
    tempDmsPayload!.ColType = paymentDetails!.colType;
    tempDmsPayload!.ConvFee = convenienceFee;
    tempDmsPayload!.CpiPaid = 0;
    tempDmsPayload!.DdPmt = 0;
    tempDmsPayload!.EmailB = inPersonPaymentDetails!.buyerEmail || '';
    tempDmsPayload!.EmailC = inPersonPaymentDetails!.cobuyerEmail ?? '';
    tempDmsPayload!.IsAch = isAch;
    tempDmsPayload!.IsNewCard = getIsNewPayment(paymentType, newCard, newAccount);
    tempDmsPayload!.LcOwed = 0;
    tempDmsPayload!.LcPaid = 0;
    tempDmsPayload!.LcWaived = 0;
    tempDmsPayload!.Mpd.AccountNumber = accountNumber;
    tempDmsPayload!.Mpd.RoutingNumber = routingNumber;
    tempDmsPayload!.Mpd.Token = getMpdToken(paymentType, mpdId, achMpdId);
    tempDmsPayload!.NsfPaid = 0;
    tempDmsPayload!.PaidBy = paymentType;
    tempDmsPayload!.PaidIn = accepted;
    tempDmsPayload!.PaidRef = referenceNumber;
    tempDmsPayload!.PayNote = paymentNote;
    tempDmsPayload!.PaymentType = 'PrinOnly';
    tempDmsPayload!.PmtContext = 'AC_PRIN';
    tempDmsPayload!.PmtType = paymentType;
    tempDmsPayload!.ProcessorType = getProcessorIntByName(processor!);
    tempDmsPayload!.SaveCard = getSavePayment(paymentType, saveCard, saveAccount);
    tempDmsPayload!.SendB = includeBuyerEmail;
    tempDmsPayload!.SendC = includeCobuyerEmail;
    tempDmsPayload!.TakenBy = getInitials(employee!.shortName);
    tempDmsPayload!.TakenByPassword = password;
    tempDmsPayload!.TotalReceived = paymentToSend;
    tempDmsPayload!.UserEmail = employee!.userId;
    tempDmsPayload!.UserRecId = employee!.recId;
    tempDmsPayload!.WaiveAchConvFee = isAch && waiveFee;
    tempDmsPayload!.WaiveCCConvFee = isCC && waiveFee;
    tempDmsPayload!.WaiveConvFee = waiveFee;

    setPaymentPayload(tempDmsPayload);

    if (!formErrors && password) {
      if (
        (paymentType !== PaymentType.CreditCard && paymentType !== PaymentType.Ach) ||
        paymentPayload?.Mpd.Token
      ) {
        // TODO: When adding error handling, add logic here to only open modal if there are no form errors
        setConfirmPostModalOpen(true);
      } else if (processor === CardProcessorName.Repay && paymentPayload) {
        // Get iframe url
        paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setIframe(res.iFrameUrl);
          setRepayModal(true);
        });
      } else if (
        processor === CardProcessorName.OpenEdge &&
        paymentType === PaymentType.CreditCard
      ) {
        paymentService
          .postPaymentSubmit(paymentPayload as any)
          .then((res) => {
            setPaymentLogRecId(res.paymentLogRecId);
            setOpenEdgeModal(true);
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        setOpenEdgeAchModal(true);
      }
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const validateNumber = (value: number | undefined) => {
    value !== undefined
      ? value >= 0
        ? setFormErrors(false)
        : setFormErrors(true)
      : setFormErrors(true);
  };

  const errorMessage = (value: Nullable<number>, input?: string) => {
    if (input === 'totalPayment') {
      const rangeError = paymentInRange(value!, paymentDetails!, paymentType);

      if (rangeError.error) {
        return rangeError.message;
      }
    }

    if (Number.isNaN(value) || value === null) {
      return 'Value cannot be empty';
    } else if (value < 0) {
      return 'Value cannot be negative';
    } else {
      return;
    }
  };

  const handleTotalPaymentBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    validateTotalPayment(parseFloat(e.target.value), paymentDetails!, paymentType, setFormErrors);

    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setTotalPayment(parseFloat(e.target.value));
      setAmountTendered(parseFloat(e.target.value) + convenienceFee);
    } else {
      setAmountTendered(parseFloat(e.target.value));
    }
    if (parseFloat(e.target.value) > amountTendered) {
      setAmountTendered(parseFloat(e.target.value));
    }
  };

  const handleChangeDue = (amountTendered: number, totalPayment: number) => {
    if (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) {
      setChangeDue(0);
    } else if (amountTendered > totalPayment) {
      setChangeDue(amountTendered - totalPayment);
    } else {
      setChangeDue(0);
    }
  };

  useEffect(() => {
    handleChangeDue(amountTendered, totalPayment);
  }, [amountTendered, totalPayment, paymentType]);

  useEffect(() => {
    if (!paymentDetails || !processor) return;
    setConvenienceFee(getConvenienceFee(paymentDetails!, processor, paymentType, waiveFee));
  }, [paymentType, processor, waiveFee]);

  // useEffect to dynamically update totalPaytment and amountTendered
  useEffect(() => {
    if (inPersonPaymentDetails) {
      if (
        (paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach) &&
        !waiveFee
      ) {
        setAmountTendered(totalPayment + convenienceFee);
      } else {
        setAmountTendered(totalPayment);
      }
    }
  }, [paymentType, waiveFee, inPersonPaymentDetails?.nextDueAmount, convenienceFee, processor]);

  useEffect(() => {
    initData();
  }, []);

  if (refreshing) {
    return (
      <div className={styles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  }

  const canWaiveFee = getCanWaiveFeeDeprec(processor, providerData);

  return (
    <div>
      {postPaymentLoading && (
        <div className={styles.dimmer}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      )}
      <div className={styles.header}>
        <img src={levelUpImage} onClick={navigateToList} />
        Principal Only Payment
      </div>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div>
            <form onSubmit={onSubmit} className={styles.paymentDetails}>
              {processors && processors.length > 1 && (
                <DmsDropDown
                  name="processor"
                  label="Processor"
                  values={processors}
                  value={processor}
                  inputContainerStyle={{ width: '155px' }}
                  onChange={(e) => setProcessor(e.target.value as CardProcessorName)}
                />
              )}
              <DmsRadioButtonGroup
                name="paymentType"
                inputLabel="Accepted In"
                buttons={PmtPaidIn.options}
                radioButtonGroupName="AcceptedIn"
                required
                onChange={(e) => setAccepted(e.target.value as PmtPaidIn)}
              />
              <div className={styles.paymentInputContainer}>
                <div className={styles.paymentInputTitle}>Paid</div>
                <div className={styles.paymentInputTitle}>Waived</div>
                <div className={styles.paymentInputTitle}>Owed</div>
              </div>
              <DmsNumberInput
                name="carPayment"
                inputLabel="Principal Balance"
                inputStyles={{ width: '100%' }}
                readOnly
                errors={errorMessage(principalBalance)}
                value={principalBalance}
                fixedDecimalScale
                decimalScale={2}
                onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                onChange={(e) => {
                  setPrincipalBalance(parseFloat(e.target.value));
                }}
              />
              <DmsNumberInput
                name="totalPayment"
                inputLabel="Principal Payment"
                inputStyles={{ width: '100%' }}
                value={totalPayment}
                fixedDecimalScale
                decimalScale={2}
                errors={errorMessage(totalPayment, 'totalPayment')}
                onBlur={(e) => {
                  handleTotalPaymentBlur(e);
                }}
                onChange={(e) => {
                  setTotalPayment(parseFloat(e.target.value));
                }}
              />
              <DmsDropDown
                name="paymentType"
                label="Payment Type"
                values={paymentTypes}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setPaymentType(e.target.value)}
                value={paymentType}
              />
              <DmsTextInput
                name="referenceNumber"
                inputLabel="Reference #"
                inputStyles={{ width: '100%' }}
                onChange={(e) => setReferenceNumber(e.target.value)}
                value={referenceNumber}
              />
              <DmsNumberInput
                name="amountTendered"
                inputLabel="Amount Tendered"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%' }}
                fixedDecimalScale
                readOnly={paymentType === PaymentType.CreditCard || paymentType === PaymentType.Ach}
                decimalScale={2}
                errors={errorMessage(amountTendered)}
                onBlur={(e) => {
                  validateNumber(parseFloat(e.target.value));
                }}
                onChange={(e) => setAmountTendered(parseFloat(e.target.value))}
                value={amountTendered}
              />
              <DmsNumberInput
                name="changeDue"
                inputLabel="Change Due"
                inputContainerStyle={{ width: '100%' }}
                inputStyles={{ width: '100%', color: showChangeDue ? '#000' : 'transparent' }}
                value={changeDue}
                readOnly
                fixedDecimalScale
                errors={errorMessage(changeDue)}
                decimalScale={2}
                onBlur={(e) => validateNumber(parseFloat(e.target.value))}
                onChange={(e) => {
                  setChangeDue(parseFloat(e.target.value));
                }}
              />
              <DmsTextInput
                inputLabel="Password"
                type="password"
                inputStyles={{ width: '100%' }}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <DmsDropDown
                name="employee"
                label="Employee"
                values={employeeArray.map((employee) => employee.shortName)}
                value={employee!.shortName}
                inputContainerStyle={{ width: '100%' }}
                onChange={(e) => setEmployee(employeeArray[e.target.options.selectedIndex])}
              />
              <DmsTextInput
                name="paymentNote"
                inputLabel="Payment Note"
                inputStyles={{ width: '100%' }}
                value={paymentNote}
                onChange={(e) => setPaymentNote(e.target.value)}
              />
              <DmsModal
                title="Confirm Payment"
                message="Are you sure you want to post this payment?"
                onAcceptLabel="Yes"
                onDeclineLabel="No"
                isOpen={confirmPostModalOpen}
                closeFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onDeclineFunction={() => {
                  setConfirmPostModalOpen(false);
                }}
                onAcceptFunction={() => {
                  paymentService.postPaymentSubmit(paymentPayload as any).then((res) => {
                    paymentService
                      .getReceiptUrl(res.paymentRecId)
                      .then((res) => {
                        window.open(res);
                        navigateToList();
                      })
                      .catch((err) => console.error(err));
                  });
                  setConfirmPostModalOpen(false);
                  initData();
                }}
              />
              <Button
                buttonLabel="Post Payment"
                onClick={() => {
                  submit();
                }}
                style={{
                  padding: '4px 0',
                  width: '100%',
                  height: '36px',
                  fontSize: '18px',
                }}
              />
            </form>
            <EmailReceipt
              buyerEmail={inPersonPaymentDetails?.buyerEmail || ''}
              coBuyerEmail={inPersonPaymentDetails?.cobuyerEmail || ''}
              containerStyles={{ marginTop: '16px' }}
              noEmailState={inPersonPaymentDetails?.buyerNoEmail}
              coBuyerNoEmailState={inPersonPaymentDetails?.cobuyerNoEmail}
              colRecId={colRecId}
              appRecId={inPersonPaymentDetails?.appRecId}
              buyerRecId={inPersonPaymentDetails?.buyerRecId}
              cobuyerRecId={inPersonPaymentDetails?.coBuyerRecId}
              emailCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, buyerEmail: val })
              }
              cobuyerEmailCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, cobuyerEmail: val })
              }
              optOutCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, buyerNoEmail: val })
              }
              cobuyerOptOutCallback={(val) =>
                inPersonPaymentDetails &&
                setInPersonPaymentDetails({ ...inPersonPaymentDetails, cobuyerNoEmail: val })
              }
              buyerCheckboxFunction={(val) => setIncludeBuyerEmail(val)}
              cobuyerCheckboxFunction={(val) => setIncludeCobuyerEmail(val)}
              hasCobuyer={inPersonPaymentDetails?.hasCobuyer ?? false}
            />
            {!!(processors && processors.length) && (
              <TextToPay
                paymentDetails={inPersonPaymentDetails!}
                setPaymentDetails={setInPersonPaymentDetails}
              />
            )}
            {paymentType === PaymentType.CreditCard && (
              <SavedCreditCard
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveCard={saveCard}
                onSaveCard={setSaveCard}
                newCard={newCard}
                onNewCard={setNewCard}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                zip={zip}
                onZip={setZip}
                address={address}
                onAddress={setAddress}
                city={city}
                onCity={setCity}
                state={state}
                onState={setState}
                mpdId={mpdId}
                onMpdId={setMpdId}
                onSetFormErrors={setFormErrors}
                useCardOnFile={true}
                savedPaymentMethods={paymentMethods!}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
            {paymentType === PaymentType.Ach && (
              <SavedACH
                convenienceFee={convenienceFee}
                waiveFee={waiveFee}
                onWaiveFee={setWaiveFee}
                saveAccount={saveAccount}
                onSaveAccount={setSaveAccount}
                newAccount={newAccount}
                onNewAccount={setNewAccount}
                firstName={firstName}
                onFirstName={setFirstName}
                lastName={lastName}
                onLastName={setLastName}
                onAccountType={setAccountType}
                mpdId={achMpdId}
                onMpdId={setAchMpdId}
                onSetFormErrors={setFormErrors}
                useAchOnFile={true}
                savedPaymentMethods={paymentMethods!}
                paymentProvider={processor!}
                canWaiveFee={canWaiveFee}
              />
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <AccountInfo paymentDetails={inPersonPaymentDetails} />
          <SamTasks appRecId={appRecId} colType={inPersonPaymentDetails?.colType} />
          <DmsNotes colRecId={colRecId} />
        </div>
        <DmsModal
          isOpen={openEdgeAchModal}
          closeFunction={() => setOpenEdgeAchModal(false)}
          title="OpenEdge Payment"
          creditCardPaymentProvider="OpenEdge"
        >
          <div className={styles.achContainer}>
            <div className={styles.achInputLabel}>Routing Number</div>
            <DmsNumberInput
              name="routingNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setRoutingNumber(e.target.value)}
              maxLength={9}
            />
            <Spacer size={10} />
            <div className={styles.achInputLabel}>Account Number</div>
            <DmsNumberInput
              name="accountNumber"
              inputContainerStyle={{ width: '100%' }}
              inputStyles={{ width: '100%' }}
              onChange={(e) => setAccountNumber(e.target.value)}
              maxLength={17}
            />
            <Spacer size={10} />
            <Button
              buttonStyles={{ width: '300px', height: '30px' }}
              buttonLabel="Submit"
              onClick={() =>
                paymentService
                  .postPaymentSubmit(paymentPayload as any)
                  .then((res) => {
                    pollForReceiptOld(res.paymentLogRecId, () => {
                      setOpenEdgeAchModal(false);
                      navigateToList();
                    });
                  })
                  .catch((e) => {
                    console.error(e);
                  })
              }
            />
          </div>
        </DmsModal>
        {openEdgeModal && (
          <DmsModal
            isOpen={openEdgeModal}
            closeFunction={() => {
              setOpenEdgeModal(false);
            }}
            title="OpenEdge Payment"
            creditCardPaymentProvider={CardProcessorName.OpenEdge}
          >
            <OpenEdge
              paymentLogRecId={paymentLogRecId}
              apiKey={paymentDetails?.openedgeApiKey}
              onComplete={navigateToList}
            />
          </DmsModal>
        )}
        {repayModal && (
          <DmsModal
            isOpen={repayModal}
            closeFunction={() => {
              setRepayModal(false);
            }}
            title="Repay Payment"
            creditCardPaymentProvider={CardProcessorName.Repay}
          >
            <Repay iframe={iframe} paymentLogRecId={paymentLogRecId} onComplete={navigateToList} />
          </DmsModal>
        )}
      </div>
    </div>
  );
};
