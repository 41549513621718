import { FC } from 'react';
// mui
import Typography from '@mui/material/Typography';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';

/** ###  */
const TextCell: FC<GridCellProps> = ({ dataItem, field }) => {
  const cellValue: string = dataItem[field!];

  const textFmt = cellValue === '' || cellValue === null ? '-' : cellValue;

  return (
    <td className="k-table-td" style={{ textWrap: 'nowrap', textOverflow: 'ellipsis' }}>
      <Typography component="span" variant="tableCell">
        {textFmt}
      </Typography>
    </td>
  );
};

export default TextCell;
