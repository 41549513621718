export class OutsideFundingDb {
  constructor(
    public appRecId: number,
    public bNames: string | null,
    public buyerRecId: number,
    public cobuyRecId: number,
    public cod: number,
    public code3: string | null,
    public companyName: string | null,
    public compAppId: number,
    public compId: number,
    public discount: number,
    public discountRate: number,
    public funded: boolean,
    public fundedOn: Date,
    public fundPmt: number,
    public invDays: number,
    public isCobuyer: boolean,
    public lhPmtAmt: number,
    public locId: number,
    public manLocation: number,
    public name: string,
    public orgId: number,
    public region: string | null,
    public saleDate: Date,
    public saleStatus: string | null,
    public saleType: string | null,
    public stockNum: string | null,
    public term: number,
    public totalDown: number,
    public vehRecId: number,
    public vehYmm: string | null,
    public vin: string | null,
    public webYmm: string | null
  ) {}

  static new(newOutsideFundingRow: OutsideFundingDb): OutsideFundingDb {
    return new OutsideFundingDb(
      newOutsideFundingRow.appRecId,
      newOutsideFundingRow.bNames,
      newOutsideFundingRow.buyerRecId,
      newOutsideFundingRow.cobuyRecId,
      newOutsideFundingRow.cod,
      newOutsideFundingRow.code3,
      newOutsideFundingRow.companyName,
      newOutsideFundingRow.compAppId,
      newOutsideFundingRow.compId,
      newOutsideFundingRow.discount,
      newOutsideFundingRow.discountRate,
      newOutsideFundingRow.funded,
      newOutsideFundingRow.fundedOn,
      newOutsideFundingRow.fundPmt,
      newOutsideFundingRow.invDays,
      newOutsideFundingRow.isCobuyer,
      newOutsideFundingRow.lhPmtAmt,
      newOutsideFundingRow.locId,
      newOutsideFundingRow.manLocation,
      newOutsideFundingRow.name,
      newOutsideFundingRow.orgId,
      newOutsideFundingRow.region,
      newOutsideFundingRow.saleDate,
      newOutsideFundingRow.saleStatus,
      newOutsideFundingRow.saleType,
      newOutsideFundingRow.stockNum,
      newOutsideFundingRow.term,
      newOutsideFundingRow.totalDown,
      newOutsideFundingRow.vehRecId,
      newOutsideFundingRow.vehYmm,
      newOutsideFundingRow.vin,
      newOutsideFundingRow.webYmm
    );
  }
}

export type IOutsideFundingDb = OutsideFundingDb;
