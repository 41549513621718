import { FC, useEffect } from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router-dom';
// components
import Header from './Header';
import { InventoryDetailsContainer } from './inventoryDetails/InventoryDetailsContainer';
import InventoryTable from './Tables/InventoryTable';
// state
import { useAuthSelector } from '../auth/authSlice';
import InventoryProvider, { useInventoryViewCtx } from './state';
import InventoryDetailProvider from './inventoryDetails/InventoryDetailProvider';
// utils
import { systemService } from '@/services/systemService';
// style
import styles from './InventoryContainer.module.scss';

const ParentContainer: FC = () => {
  const compId = useAuthSelector((s) => s.compId);
  const setMappedCompaniesCt = useInventoryViewCtx((s) => s.setMappedCompaniesCt);
  const fetchMappedCompanies = async () => {
    try {
      const res = await systemService.mappedCompanies();
      setMappedCompaniesCt(res.length);
    } catch (err) {
      // @todo properly handle error
      console.error(err);
    }
  };

  // Effects
  useEffect(() => {
    fetchMappedCompanies();
  }, [compId]);

  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

const TableView: FC = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

const InventoryContainer: FC = () => {
  return (
    <Routes>
      <Route element={<ParentContainer />}>
        <Route element={<TableView />}>
          <Route path="/unsold" element={<InventoryTable />} />
          <Route path="/sold" element={<InventoryTable />} />
          <Route path="/iop" element={<InventoryTable />} />
          <Route path="/all" element={<InventoryTable />} />
          <Route path="/alllotsretail" element={<InventoryTable />} />
          <Route path="/notReceived" element={<InventoryTable />} />
          <Route path="/titlelogs" element={<InventoryTable />} />
          <Route path="/disposal" element={<InventoryTable />} />
        </Route>
        <Route path="/:invRecId/*" element={<InventoryDetailsContainer />} />
      </Route>
      <Route path="*" element={<Navigate to="/inventory/unsold" replace={true} />} />
    </Routes>
  );
};

export default () => (
  <InventoryProvider>
    <InventoryDetailProvider>
      <InventoryContainer />
    </InventoryDetailProvider>
  </InventoryProvider>
);
