import Big from "big.js";
import dayjs from "dayjs";

export function LhPhLoop(
  AmtFin: Big,
  NumPmts: number,
  LoanDate: dayjs.Dayjs,
  FirstDate: dayjs.Dayjs,
  IntRate: Big,
  PmtAmt: Big
): Big {
  let BegDate = LoanDate;
  let EndDate = FirstDate;
  let PrinBal = AmtFin;

  for (let myx = 1; myx <= NumPmts; myx++) {
    let days = new Big(EndDate.diff(BegDate, 'day'));
    let intdue = PrinBal.times(IntRate).times(days).div(new Big(36500)).round(2, Big.roundHalfUp);
    PrinBal = PrinBal.minus(PmtAmt).plus(intdue).round(2, Big.roundHalfUp);
    BegDate = EndDate;
    EndDate = dayjs(BegDate).add(1, 'month');
  }
  return PrinBal;
}

export function getLHPHPayment(
  AmtFin: number,
  NumPmts: number,
  LoanDate: dayjs.Dayjs,
  FirstDate: dayjs.Dayjs,
  IntRate: number
): number {
  if (NumPmts === 0) return 0;

  const payment = (amtfin: Big, rate: Big, numpmts: number): Big => {
    if (rate.eq(0)) return amtfin.div(numpmts);
    const r = rate.div(1200);
    return amtfin.times(r).div(new Big(1).minus(new Big(1).plus(r).pow(-numpmts)));
  };

  let PmtAmt1 = payment(new Big(AmtFin), new Big(IntRate), NumPmts).round(2, Big.roundHalfUp);

  let PrinBal = LhPhLoop(new Big(AmtFin), NumPmts, LoanDate, FirstDate, new Big(IntRate), PmtAmt1);

  let AdjPmt = PrinBal.div(NumPmts).round(2, Big.roundHalfUp);
  let PmtAmt = PmtAmt1.plus(AdjPmt).round(2, Big.roundHalfUp);

  for (let myx = 1; myx <= 4; myx++) {
    PrinBal = LhPhLoop(new Big(AmtFin), NumPmts, LoanDate, FirstDate, new Big(IntRate), PmtAmt);

    AdjPmt = PrinBal.div(NumPmts).round(2, Big.roundHalfUp);
    PmtAmt = PmtAmt.plus(AdjPmt).round(2, Big.roundHalfUp);
  }

  return PmtAmt.toNumber();
}

export function getFundDates(
  Orgid: number,
  FundDay: number,
  CutOffDay: number
): { FundDate: dayjs.Dayjs; FundDueDate: dayjs.Dayjs } {
  let DateSold = dayjs();
  let SDDay = DateSold.day() + 1;
  let FundDate: dayjs.Dayjs = dayjs();
  let FundDueDate: dayjs.Dayjs = dayjs();
  if (Orgid === 10026 || Orgid === 10053 || Orgid === 10036) {
    if (FundDay >= CutOffDay) {
      if (CutOffDay > SDDay) {
        FundDate = dayjs(DateSold).add(7 + FundDay - SDDay, 'day');
      } else {
        FundDate = dayjs(DateSold).add(FundDay - SDDay, 'day');
      }
    } else {
      if (CutOffDay > SDDay) {
        FundDate = dayjs(DateSold).add(14 + FundDay - SDDay, 'day');
      } else {
        FundDate = dayjs(DateSold).add(7 + FundDay - SDDay, 'day');
      }
    }
    if (FundDay > 15) {
      FundDueDate = FundDate.add(1, 'month').set('date', 0).add(25, 'day');
    } else {
      FundDueDate = FundDate.add(1, 'month').set('date', 0).add(10, 'day');
    }
  } else {
    if (SDDay === 1) {
      FundDate = dayjs(DateSold).add(2, 'day');
    } else if (SDDay === 2) {
      FundDate = dayjs(DateSold).add(1, 'day');
    } else if (SDDay === 3) {
      FundDate = dayjs(DateSold).add(7, 'day');
    } else if (SDDay === 4) {
      FundDate = dayjs(DateSold).add(6, 'day');
    } else if (SDDay === 5) {
      FundDate = dayjs(DateSold).add(5, 'day');
    } else if (SDDay === 6) {
      FundDate = dayjs(DateSold).add(4, 'day');
    } else if (SDDay === 7) {
      FundDate = dayjs(DateSold).add(3, 'day');
    }
    FundDueDate = FundDate.add(1, 'month').set('date', 0).add(25, 'day');
  }
  return { FundDate, FundDueDate };
}