import { z } from "zod";
// utils
import { Nullish } from "@/interfaces/utilityTypes";

/** ### Request body validation, based on `zod`.
 *
 * Build and validate a request body using form values and  request body validation.
 * @todo add
 * @todo handle intersection types (i.e. `isNew ? AddRoleReq : UpdateRoleReq`)
 * @todo add required validation to `<serviceName>.<requestMethod>()` methods
 * i.e. `paymentService.postPaymentExample(reqBody, reqSchema);
 */
const validateReqBody = <TSchema extends z.ZodEffects<z.ZodTypeAny> | z.ZodObject<z.ZodRawShape>>(
  reqSchema: TSchema, // | TSchema,
  /** @todo Support handling: 1. `z.SafeParseReturnType`; 2. (Considering the tradeoffs) A callback which builds the req body
   */
  reqBodyInput?: Nullish<z.input<TSchema>> | null
) => {
  const validation: z.SafeParseReturnType<
    z.input<TSchema>,
    z.output<TSchema>
  > = reqSchema.safeParse(reqBodyInput);

  const errors = validation.error?.formErrors.fieldErrors as
    | { [P in keyof z.output<TSchema>]?: string[] | undefined }
    | undefined;
  const errorsArr = validation.error?.issues;
  const isValid = validation.success;
  const reqBody: z.output<TSchema> | null = validation.data ?? null;

  return {
    validation,
    reqBody,
    errors,
    errorsArr,
    isValid,
  } as const;
};

export default validateReqBody;
