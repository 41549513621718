import { FC } from "react";
// mui
// kendo
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DropdownInput } from "@/components";

/** ###  */
const AuthAndNotesSection: FC = () => {
  const employees = usePmtFormCtx((s) => s.employees.value);
  const employee = usePmtFormCtx((s) => s.employee);
  const { form, getFieldProps, setField } = usePmtFormCtx((s) => s.formState);

  return (
    <Grid2 container direction="column" gap={1.5} flex={1}>
    <>
      <DropdownInput
        label="Employee"
        required
        data={employees || []}
        dataItemKey="recId"
        textField="shortName"
        onChange={(e) => {
          if (typeof e.value === "number") {
            setField("employeeRecId", e.value || null);
          } else if (e.value && e.value?.recId) {
            setField("employeeRecId", e.value.recId || null);
          }
        }}
        value={employee}
        errors={!form.employeeRecId}
      />

      <TextInput label="Password" type="password" required {...getFieldProps("takenByPassword")} />
    </>
    </Grid2>
  );
};

export default AuthAndNotesSection;
