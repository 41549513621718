import { FC, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
// components
import { Checkbox, DropdownInput, TextInput } from '@/components';
import { TimeInput } from '@/components/inputs/time/TimeInput';
import CheckboxGroup from '@/components/inputs/checkboxGroup/CheckboxGroup';
// utils
import { useReportsViewCtx } from '../state/view';
import { useReportsCtx } from '../ReportsList/defaultState';
import { useERSContext } from './state';
import { ERSForm, ERSHookForm } from './interfaces';
import {
  daysOfWeek,
  ersFiletypeOptions,
  reportDateRangeOptions,
  reportFrequencyOptions,
} from './default';
import { KendoFieldOption } from '@/interfaces/kendo';
// style
import styles from './EmailReportsSchedules.module.scss';

// Option-array build fxns
export const buildOptions = <T extends string, U extends string>(
  opts: { [key in T | U]: number | string }[],
  idKey: T,
  labelKey: U
) => {
  return opts.map((o) => ({
    dataItemKey: o[idKey],
    textField: o[labelKey],
  }));
};
export const buildOptionsStrs = (opts: string[]) => {
  return opts.map((o) => ({
    dataItemKey: o,
    textField: o,
  }));
};

export const buildOptionsFromMap = (map: Map<string | number, string>): KendoFieldOption[] => {
  const mappedArr: KendoFieldOption[] = [];

  map.forEach((v, k) => {
    mappedArr.push({ dataItemKey: k, textField: v });
  });

  return mappedArr;
};

/** ### Displays form fields in modal for a single Email Report Schedule. */
const FormLayout: FC<{ formMgmt: ERSHookForm }> = ({ formMgmt }) => {
  const { control } = formMgmt;
  const { scheduleType, runLastDayOfMonth, dateRangeType } = useWatch({ control });
  const isModalOpen = useReportsViewCtx((s) => s.isModalOpen);
  const reportsMap = useReportsCtx(s => s.reportsMap);
  const { companiesMap, isEdit, selectedERS } = useERSContext();
  const { trigger } = formMgmt;

  // const reportsOptions = buildOptions(reports, 'recId', 'reportNameOrg');
  const reportsOptions = buildOptionsFromMap(reportsMap);
  const companiesOptions = buildOptionsFromMap(companiesMap);

  useEffect(() => {
    if (selectedERS) {
      // Populate form with existing values
      formMgmt.setValue('reportRecId', selectedERS.reportRecId, { shouldTouch: true });
      formMgmt.setValue('compId', selectedERS.compId, { shouldTouch: true });
      formMgmt.setValue('emailTo', selectedERS.emailTo, { shouldTouch: true });
      formMgmt.setValue('fileType', selectedERS.fileType, { shouldTouch: true });
      formMgmt.setValue('scheduleType', selectedERS.scheduleType, { shouldTouch: true });
      formMgmt.setValue('runTimeLocal', selectedERS.runTimeLocal, { shouldTouch: true });
      formMgmt.setValue('runDays', selectedERS.runDays, { shouldTouch: true });
      formMgmt.setValue('runDate', selectedERS.runDate, { shouldTouch: true });
      formMgmt.setValue('runLastDayOfMonth', selectedERS.runLastDayOfMonth, { shouldTouch: true });
      formMgmt.setValue('dateRangeType', selectedERS.dateRangeType, { shouldTouch: true });
      formMgmt.setValue('xDaysPrior', selectedERS.xDaysPrior, { shouldTouch: true });
      trigger();
    } else {
      // Clear form
      formMgmt.reset();
    }
  }, [!selectedERS, isModalOpen]);

  return (
    <div className={styles.formContainer}>
      <Controller
        name="reportRecId"
        control={control}
        rules={{ required: !isEdit && 'This field is required', pattern: /\d+/ }}
        render={({ field }) => (
          <DropdownInput
            required
            label="Reports"
            data={reportsOptions}
            errors={formMgmt.formState.errors[field.name]?.message}
            placeholder={
              reportsOptions.find((r) => r.dataItemKey === field.value)?.textField as string
            }
            dataItemKey="dataItemKey"
            textField="textField"
            disabled={isEdit}
            {...field}
            onChange={(e) => {
              e.target.value ? field.onChange(e.target.value?.dataItemKey) : field.onChange('');
            }}
          />
        )}
      />

      <Controller
        name="compId"
        control={control}
        rules={{ required: !isEdit && 'This field is required', pattern: /\d+/ }}
        render={({ field }) => (
          <DropdownInput
            required
            label="Company"
            data={companiesOptions}
            errors={formMgmt.formState.errors[field.name]?.message}
            placeholder={companiesMap.get(field.value)}
            dataItemKey="dataItemKey"
            textField="textField"
            disabled={isEdit}
            {...field}
            onChange={(e) => {
              e.target.value ? field.onChange(e.target.value?.dataItemKey) : field.onChange('');
            }}
          />
        )}
      />

      <Controller
        name="emailTo"
        control={control}
        rules={{
          required: 'This field is required',
          pattern: {
            // @note linter is somehow throwing an issue with this
            // eslint-disable-next-line
            value: /^([\w\-\.]+@([\w-]+\.)+[\w-]{2,})(,([\w\-\.]+@([\w-]+\.)+[\w-]{2,}))*$/g,
            message: 'Must use comma-separated emails (i.e. <id>@<domain.com>,<id>@<domain.com>)',
          },
        }}
        render={({ field }) => (
          <TextInput
            label="Email to"
            required
            errors={formMgmt.formState.errors[field.name]?.message}
            {...field}
          />
        )}
      />

      <Controller
        name="fileType"
        control={control}
        rules={{ required: 'This field is required', pattern: /\w+/ }}
        render={({ field }) => {
          return (
            <DropdownInput
              required
              label="File type"
              data={ersFiletypeOptions}
              errors={formMgmt.formState.errors[field.name]?.message?.toString()}
              placeholder={field.value}
              dataItemKey="dataItemKey"
              textField="textField"
              disabled={false}
              {...field}
              onChange={(e) => field.onChange(e.target.value.dataItemKey)}
            />
          );
        }}
      />

      <Controller
        name="scheduleType"
        control={control}
        rules={{ required: 'This field is required', pattern: /\w+/ }}
        render={({ field }) => {
          return (
            <DropdownInput
              required
              label="Schedule"
              data={reportFrequencyOptions}
              errors={formMgmt.formState.errors[field.name]?.message?.toString()}
              placeholder={field.value}
              dataItemKey="dataItemKey"
              textField="textField"
              disabled={false}
              {...field}
              onChange={(e) => field.onChange(e.target.value.textField)}
            />
          );
        }}
      />

      <Controller
        name="runTimeLocal"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => {
          return (
            <TimeInput
              required
              label="Time"
              value={field.value!}
              onChange={(e) => {
                field.onChange(ERSForm.convertRunTimeLocalKendo(e));
              }}
              errors={formMgmt.formState.errors[field.name]?.message as string}
              disabled={false}
              steps={{ minute: 30 }}
              size="small"
            />
          );
        }}
      />

      {scheduleType === 'Weekly' ? (
        <Controller
          name="runDays"
          control={control}
          rules={{ required: 'This field is required', minLength: 1 }}
          render={({ field }) => {
            return (
              <CheckboxGroup
                required
                horizontalOptions
                label="Days"
                options={daysOfWeek}
                control={control}
                errors={formMgmt.formState.errors[field.name]?.message}
                {...field}
              />
            );
          }}
        />
      ) : (
        <div />
      )}

      {scheduleType === 'Monthly' ? (
        <Controller
          name="runDate"
          control={control}
          rules={{ required: 'This field is required', pattern: /\d+/ }}
          render={({ field }) => {
            return (
              <TextInput
                required
                disabled={runLastDayOfMonth === true}
                placeholder={field.value.toString() || '1'}
                label="Report run date (enter 1-28)"
                type="number"
                errors={formMgmt.formState.errors[field.name]?.message}
                {...field}
                value={field.value?.toString() || '1'}
              />
            );
          }}
        />
      ) : (
        <div />
      )}

      {scheduleType === 'Monthly' ? (
        <Controller
          name="runLastDayOfMonth"
          control={control}
          render={({ field }) => {
            return (
              <Checkbox labelPlacement="before" label="Run on last day of month?" {...field} />
            );
          }}
        />
      ) : (
        <div />
      )}

      <Controller
        name="dateRangeType"
        control={control}
        rules={{ required: 'This field is required', pattern: /\w+/ }}
        render={({ field }) => {
          return (
            <DropdownInput
              required
              label="Date range"
              data={reportDateRangeOptions}
              errors={formMgmt.formState.errors[field.name]?.message?.toString()}
              placeholder={field.value}
              dataItemKey="dataItemKey"
              textField="textField"
              disabled={false}
              {...field}
              onChange={(e) => field.onChange(e.target.value.textField)}
            />
          );
        }}
      />

      {dateRangeType === 'X Days Prior' ? (
        <Controller
          name="xDaysPrior"
          control={control}
          rules={{ required: 'This field is required', pattern: /\d+/ }}
          render={({ field }) => {
            return (
              <TextInput
                required
                label="Days prior"
                placeholder={field.value.toString() || '0'}
                errors={formMgmt.formState.errors[field.name]?.message}
                type="number"
                {...field}
                value={field.value?.toString() || '0'}
              />
            );
          }}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default FormLayout;
