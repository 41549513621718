import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
  Loader,
} from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
// components
import TextSearchFilter from '@/components/table/TextSearchFilter';
import TableFilters from './TableFilters';
// state
import { useTableCtx } from './TableProvider';
import { useSalesViewCtx } from '../../SalesViewProvider';
import { useSalesListCtx } from '../SalesListProvider';
import { useSalesListViewData } from './useSalesListViewData';
import { useAppDispatch } from '@/store/store';
import { salesActions } from '../../salesSlice';
// utils
import { useSalesParams } from '../../detailView/utils';
import { defaultPageState, handlePageChange, handleSortChange } from '@/interfaces/tableState';
import { routeBase } from '@/features/Sales/SalesListView/listViewConfig';
import { fetchMappedCompanies, fetchSalesList } from './requests';
// interfaces
import { SalesSubview } from '../../enums';
import { SortKendo } from '@/interfaces/kendo';

/** ###  */
const SalesListViewTable: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subview = useSalesParams().subview;
  // fetch
  useSalesListViewData();
  // context state
  const isLoading = useSalesViewCtx((s) => s.isLoading);
  const salesListRows = useSalesListCtx((s) => s.salesListRows);
  const tableColumns = useSalesListCtx((s) => s.subviewInfo.columns);
  const mappedCompaniesCt = useSalesListCtx((s) => s.mappedCompaniesCt);
  const dbCt = useSalesListCtx((s) => s.dbCt);
  const viewState = useSalesViewCtx((s) => s);
  const listViewState = useSalesListCtx((s) => s);
  const { initSortField, initSortDirection, route } = listViewState.subviewInfo;
  // table state
  const tableState = useTableCtx((s) => s);
  const { page, setPage, pageSizeValue, resetTableState, setPageSizeValue } = tableState;
  const { sorts, setSorts } = tableState;

  // Event handlers
  const handleAddNewClick = () => {
    dispatch(salesActions.setSaleData({}));
    dispatch(salesActions.setSaleVehicle({}));
    dispatch(salesActions.setSelectedVehicleId(-1));

    let navRoute = '';
    if (route === SalesSubview.enum.applications) {
      navRoute = `/${routeBase}/${route}/newSale`;
    } else if (route === SalesSubview.enum.wholesales) {
      navRoute = `/${routeBase}/${route}/new`;
    } else if (route === SalesSubview.enum.dealsQuotes) {
      navRoute = `/${routeBase}/${route}/new`;
    }

    navigate(navRoute);
  };

  // Reset table state whenever the subview changes
  useEffect(() => {
    resetTableState(initSortField, initSortDirection);
    fetchSalesList(subview!, viewState, tableState, listViewState);
    fetchMappedCompanies(listViewState.setMappedCompaniesCt);

    // Call resetTableState when unmount
    return () => {
      resetTableState(initSortField, initSortDirection);
    };
  }, [subview]);

  // Build table columns
  const tableColumnElems = tableColumns.map((c) => (
    <GridColumn
      field={c.field}
      title={c.title}
      width={c.width}
      cells={c.cells}
      columnMenu={c.columnMenu}
      key={c.field} />
  ));

  // @note Temporarily disable "Add New" button while they are in development
  const isAddNewEnabled = ['applications', 'wholesales', 'dealsQuotes'].includes(route);

  // @note Columns are applied on nav, while the data from the prev. req. remains in state until the appropriate req. is returned (i.e. state updating rows of the correct shape)
  const configColumnNames = tableColumns.map((c) => c.field!);
  const dataColumnNames = salesListRows.length > 0 ? Object.keys(salesListRows[0]!) : [];
  const isInvalid = configColumnNames.some((cfgCol) => !dataColumnNames.includes(cfgCol));

  return (
    <KendoGrid
      data={isLoading || isInvalid ? [] : salesListRows}
      total={dbCt}
      sortable={{ mode: 'single' }}
      sort={sorts as SortKendo[]}
      onSortChange={handleSortChange(setSorts)}
      skip={page.skip}
      take={page.take}
      pageable={{ ...defaultPageState, pageSizeValue }}
      onPageChange={handlePageChange(setPageSizeValue, setPage)}
      style={{ maxHeight: '80%', overflow: 'scroll' }}
    >
      <GridToolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          flex={1}
          wrap="nowrap"
        >
          <Grid container direction="row" gap={1} alignItems="center" wrap="nowrap">
            <TextSearchFilter tableState={tableState} />
            <TableFilters />
          </Grid>
          {/* @note temporary - kendo header adjusts height when button is not rendered- inconsistent height when switching to non-account tables */}
          <Button label="-" style={{ opacity: 0 }} disabled />
          {mappedCompaniesCt === 1 && isAddNewEnabled && (
            <Button
              label="Add New +"
              buttonStyle={{ width: '190px' }}
              onClick={handleAddNewClick}
            />
          )}
        </Grid>
      </GridToolbar>
      <GridNoRecords>{isLoading ? <Loader size="large" /> : 'No records found'}</GridNoRecords>
      {tableColumnElems}
    </KendoGrid>
  );
};

export default SalesListViewTable;
