import { SortDescriptor, State as DataQueryState } from '@progress/kendo-data-query';
import { Sort } from './requests';

export class SortKendo<SortKey = string> implements Omit<SortDescriptor, 'field' | 'compare'> {
  constructor(public field: SortKey, public dir: 'asc' | 'desc' = 'desc') {}

  /** Case does not matter in the SQL query these values are passed into */
  toSortApi(): Sort<SortKendo['field']> {
    return {
      field: this.field as SortKendo['field'],
      direction: this.dir as Sort['direction'],
    };
  }
}

export type IKendoPaginationState = Required<Pick<DataQueryState, 'skip' | 'take'>>;

/** Option-type for kendo dropdown */
export type KendoFieldOption = { dataItemKey: number | string; textField: string };
