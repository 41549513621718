import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
// kendo
import { Button } from '@/components';
// state
import { useAppDispatch } from '@/store/store';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { getSaleDataNoLoading } from '@/features/Sales/salesActionCreator';
// utils
import { accountsService } from '@/services/accountsService';
import { salesService } from '@/services/salesService';
import { formatAddress, getAddressFromGoogleMaps } from '@/utils/helpers/general';
// interfaces
import { Reference } from '@/interfaces';
import { Column } from '@/components/table/TableInterface';
import { BuyerType } from '@/enums';

type FormProps = {
  firstName: string;
  lastName: string;
  relationship: string;
  referenceFor: BuyerType;
  yearsKnown: number;
  monthsKnown: number;
  comments: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  email: string;
  phoneNumber: string;
  dealership: string;
  currentCustomer: string;
};

/** @deprecated @todo use context to manage state */
export const useApplicationReferences = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const appRecId = Number(params.id);
  const saleData = useSalesSelector((s) => s.saleData);
  const [referenceList, setReferenceList] = useState<Reference[]>([]);
  const [postReferenceLoading, setPostReferenceLoading] = useState(false);
  const [getReferencesLoading, setGetReferencesLoading] = useState(false);
  const [deleteReferenceLoading, setDeleteReferenceLoading] = useState(false);
  const [editReferenceOpen, setEditReferenceOpen] = useState(false);
  const [activeEditReference, setActiveEditReference] = useState<Reference | undefined>(undefined);
  const [fullReferenceAddress, setFullReferenceAddress] = useState<string | undefined>(undefined);

  // @todo move object/array/function out of component body
  const defaultForm: FormProps = {
    firstName: '',
    lastName: '',
    relationship: '',
    referenceFor: BuyerType.Buyer,
    yearsKnown: 0,
    monthsKnown: 0,
    comments: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    county: '',
    email: '',
    phoneNumber: '',
    dealership: '',
    currentCustomer: 'N/A',
  };
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormProps>({
    defaultValues: defaultForm,
  });

  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('state', fullAddress.state);
    setValue('zip', fullAddress.zip);
    setValue('county', fullAddress.county);
  };

  // @todo remove unnecessary optimization
  const isDisabled = useMemo(
    () => saleData.sale?.salestatus?.toLowerCase() !== 'pending',
    [saleData]
  );

  // @todo move component to file
  const ButtonRender: FC = (props: any) => {
    const splitName = (name: string) => {
      const [firstName, ...lastName] = name.split(' ').filter(Boolean);
      return {
        firstName: firstName,
        lastName: lastName.join(' '),
      };
    };
    const value: Reference = props.dataItem;

    return (
      <td>
        <Button
          label="Edit"
          onClick={() => {
            const nameObject = splitName(value.name);
            reset({
              firstName: nameObject.firstName,
              lastName: nameObject.lastName,
              relationship: value.relationship,
              referenceFor: value.refFor,
              yearsKnown: value.knownForYears,
              monthsKnown: value.knownForMonths,
              comments: value.comments,
              address: value.shortAddress,
              city: value.city,
              state: value.state,
              zip: value.zip,
              county: '',
              email: value.email,
              phoneNumber: value.cellPhone,
              dealership: value.currentCustomerLot,
              currentCustomer: value.currentCustomer,
            });
            setActiveEditReference(value);
            setFullReferenceAddress(
              formatAddress(value.address ?? undefined, value.city, value.state, value.zip)
            );
            setEditReferenceOpen(true);
          }}
        />
      </td>
    );
  };

  // @todo move object/array/function out of component body
  const columns: Column[] = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'relationship',
      title: 'Relationship',
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'knownForYears',
      title: 'Years',
    },
    {
      field: 'knownForMonths',
      title: 'Months',
    },
    {
      field: 'cellPhone',
      title: 'Phone',
    },
    {
      field: 'editReference',
      title: ' ',
      cells: { data: ButtonRender },
    },
  ];

  const getReferences = () => {
    setGetReferencesLoading(true);
    // @todo use async/await
    salesService
      .getReferences(appRecId)
      .then((res) => setReferenceList(res))
      .catch(() => toast.error('There was an error getting the references'))
      .finally(() => setGetReferencesLoading(false));
  };

  const handleAddNewReference = (data: FormProps) => {
    setPostReferenceLoading(true);
    // @todo use async/await
    salesService
      .postReference({
        appRecId: appRecId,
        refFor: data.referenceFor,
        name: `${data.firstName} ${data.lastName}`,
        shortAddress: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        knownForMonths: data.monthsKnown,
        knownForYears: data.yearsKnown,
        relationship: data.relationship,
        homePhone: '',
        cellPhone: data.phoneNumber,
        email: data.email,
        noTexts: false,
        noCalls: false,
        noEmails: false,
        currentCustomer: data.currentCustomer,
        currentCustomerLot: data.dealership,
        comments: data.comments,
      })
      .then(() => {
        toast.success('Reference Added');
        getReferences();
        dispatch(getSaleDataNoLoading(appRecId));
        reset(defaultForm);
      })
      .catch(() => toast.error('There was an error adding the reference'))
      .finally(() => setPostReferenceLoading(false));
  };

  const updateReference = (data: FormProps) => {
    setPostReferenceLoading(true);
    // @todo use async/await
    accountsService
      .updateReference({
        recId: activeEditReference?.recId,
        refFor: data.referenceFor,
        name: `${data.firstName} ${data.lastName}`,
        shortAddress: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        knownForMonths: data.monthsKnown,
        knownForYears: data.yearsKnown,
        relationship: data.relationship,
        homePhone: '',
        cellPhone: data.phoneNumber,
        email: data.email,
        noTexts: false,
        noCalls: false,
        noEmails: false,
        currentCustomer: data.currentCustomer,
        currentCustomerLot: data.dealership,
        comments: data.comments,
      })
      .then(() => {
        toast.success('Reference Updated');
        setActiveEditReference(undefined);
        setEditReferenceOpen(false);
        getReferences();
        reset(defaultForm);
      })
      .catch(() => toast.error('There was an error updating the reference'))
      .finally(() => setPostReferenceLoading(false));
  };

  const deleteReference = () => {
    setDeleteReferenceLoading(true);
    // @todo use async/await
    accountsService
      .deleteReference(activeEditReference!.recId!)
      .then(() => {
        toast.success('Reference Deleted');
        setActiveEditReference(undefined);
        setEditReferenceOpen(false);
        dispatch(getSaleDataNoLoading(appRecId));
        getReferences();
        reset(defaultForm);
      })
      .catch(() => toast.error('There was an error deleting the reference'))
      .finally(() => setDeleteReferenceLoading(false));
  };

  const onEditModalClose = () => {
    setEditReferenceOpen(false);
    setFullReferenceAddress(undefined);
    setActiveEditReference(undefined);
    reset(defaultForm);
  };

  useEffect(() => {
    getReferences();
  }, [appRecId]);

  return {
    control,
    columns,
    referenceList,
    handleAddNewReference,
    handleSubmit,
    postReferenceLoading,
    getReferencesLoading,
    editReferenceOpen,
    setEditReferenceOpen,
    deleteReferenceLoading,
    deleteReference,
    updateReference,
    onEditModalClose,
    watch,
    fullReferenceAddress,
    handlePlaceSelected,
    isDisabled,
  };
};
