import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// state
import { oldPaymentReducer } from '@/features/old/payment/oldPaymentSlice';
import { accountReducer } from '@/features/Accounts/accountSlice';
import { collectionsReducer } from '@/features/collections/collectionsSlice';
import { salesReducer } from '@/features/Sales/salesSlice';
import { paymentReducer } from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice';
import { authReducer } from '@/features/auth/authSlice';

export const store = configureStore({
  reducer: {
    oldPayment: oldPaymentReducer,
    account: accountReducer,
    collections: collectionsReducer,
    sales: salesReducer,
    payment: paymentReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;

/** @todo move Redux state to React context */
export const useAppDispatch: () => AppDispatch = useDispatch;
