import { CSSProperties, FC, ForwardedRef } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { TEXT_PRIMARY1 } from '@/mui/theme/colors';
// kendo
import { ComboBoxHandle, Label } from '@progress/kendo-react-all';
// components
import InternalDropdownInput from './InternalDropdownInput';
// interfaces
import { DropdownInputProps } from './interfaces';

const labelStyle: CSSProperties = {
  fontSize: '14px',
  color: TEXT_PRIMARY1,
  minWidth: 'fit-content',
  flex: 1,
};

/** ###  */
const LabelDropdown: FC<
  DropdownInputProps & {
    inputRef: ForwardedRef<ComboBoxHandle>;
    editorId: string;
    horizontalLabel?: boolean;
  }
> = ({
  // @ts-ignore
  display,
  editorId,
  label = '',
  required = false,
  horizontalLabel = true,
  boldLabel = true,
  inputRef,
  ...inputProps
}) => {
  const fontWeight = boldLabel ? 700 : 400;
  const requiredStr = required ? ' *' : '';
  const labelStr = label + requiredStr;

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      {horizontalLabel ? (
        <>
          <Label editorId={editorId} style={{ ...labelStyle, fontWeight }}>
            {labelStr}
          </Label>

          <InternalDropdownInput {...inputProps} editorId={editorId} inputRef={inputRef} />
        </>
      ) : (
        <Label editorId={editorId} style={{ ...labelStyle, fontWeight }}>
          {labelStr}

          <InternalDropdownInput {...inputProps} editorId={editorId} inputRef={inputRef} />
        </Label>
      )}
    </Grid>
  );
};

export default LabelDropdown;
