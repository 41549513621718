import { ChangeEvent, FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import {
  GridPagination,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';

const handlePageChange =
  (setPage: (page: number) => void) => (_: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };

const disabledActions = { actions: { nextButton: () => null, previousButton: () => null } };

/** ###  */
const CustomPagination: FC<TablePaginationProps> = ({ className }) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const page = useGridSelector(apiRef, gridPageSelector);

  const { setPage } = apiRef.current;

  return (
    <Grid container direction="row" flex={1} gap={0.5} wrap="nowrap" overflow="hidden">
      <Pagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        siblingCount={1}
        boundaryCount={2}
        onChange={handlePageChange(setPage)}
        sx={{ alignSelf: 'center' }}
      />

      <GridPagination
        rowsPerPageOptions={[10, 25, 50]}
        sx={{
          '& .MuiToolbar-root': { flexFlow: 'row-reverse', mr: 2, gap: 1 },
          '& .MuiTablePagination-toolbar, .MuiToolbar-root': { alignContent: 'center', padding: 0 },
          '& .MuiInputBase-root': { m: 0, background: 'rgb(239, 239, 239)' },
          '& .MuiTablePagination-spacer, .MuiTablePagination-displayedRows, .MuiTablePagination-actions, .MuiTablePagination-displayedRows':
            { display: 'none' },
        }}
        slots={disabledActions}
        slotProps={{ select: { variant: 'outlined', size: 'small' } }}
        labelRowsPerPage="items per page"
      />

      <GridPagination
        showFirstButton={false}
        showLastButton={false}
        sx={{ flex: 1 }}
        slots={disabledActions}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} – ${to} of ${count !== -1 ? count : `more than ${to}`} items`
        }
      />
    </Grid>
  );
};

export default CustomPagination;
