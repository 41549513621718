import { FC, PropsWithChildren } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';

/** ###  */
const ColumnHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      fontSize={14}
      fontWeight={700}
      minHeight={30}
      alignSelf="center"
      justifySelf='start'
    >
      {children}
    </Grid>
  );
};

export default ColumnHeader;
