import { FC } from 'react';
// kendo
import { GridCustomCellProps } from '@progress/kendo-react-all';
// style
import styles from './KendoCells.module.scss';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const KendoCurrencyCell: FC<GridCustomCellProps> = (props) => {
  const { className, ...restProps } = props;
  const decimalValue = Number(restProps.children?.toString());
  const displayValue = isNaN(decimalValue) ? 0 : decimalValue;

  return (
    <td {...restProps.tdProps} className={[styles.tableNumberContainer, className].join(' ')}>
      {formatter.format(displayValue)}
    </td>
  );
};

export default KendoCurrencyCell;
