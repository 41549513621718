import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';

export interface OldPaymentAppState {
  postPaymentLoading?: boolean;
  colRecId?: number;
  compId?: number;
  orgId?: number;
  locId?: number;
  appRecId?: number;
}

const initialState = {
  postPaymentLoading: false,
};

export const slice = createSlice<
  OldPaymentAppState,
  {
    setInitialState: CaseReducer<OldPaymentAppState, PayloadAction<OldPaymentAppState>>;
    setPostPaymentLoading: CaseReducer<OldPaymentAppState, PayloadAction<boolean>>;
  }
>({
  name: 'oldPayment',
  initialState,
  reducers: {
    setInitialState: (state, { payload }) => {
      return payload;
    },
    setPostPaymentLoading: (state, action) => {
      state.postPaymentLoading = action.payload;
    },
  },
});

export const useOldPaymentSelector = buildSubStateSelector<OldPaymentAppState>(
  (state) => state.oldPayment
);
export const oldPaymentActions = slice.actions;
export const oldPaymentReducer = slice.reducer;
