import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';

/** ###  */
const PaymentsCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const totalPayments = useDashboardViewCtx((s) => s.dashboardData?.totalPayments);

  return (
    <DashboardCardLayout
      header="Payments"
      primary={totalPayments ? formatCurrency(totalPayments) : '$ -'}
      secondary={totalPayments ? 'Total across all locations' : 'No payments for this range'}
      isLoading={isLoading}
    />
  );
};

export default PaymentsCard;
