import { FC, FormEventHandler } from "react";
// mui
import { alpha } from "@mui/system/colorManipulator";
import Grid from "@mui/material/Unstable_Grid2";
import MuiKendoButton from "@/mui/components/buttons/MuiKendoButton";
// components
import PaymentModal from "@/components/modals/PaymentModal";
import CliqForm from "./CliqForm";
// state
import CliqProvider, { useCliqCtx } from "./CliqProvider";
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
// utils
import { submitButtonId } from "./config";
import { cliqFormConfig } from "./CliqForm/cliqFieldValidation";
import useNavBlock from "@/utils/forms/useNavBlock";

const CliqFormLayout: FC<{ onComplete?: () => void }> = () => {
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);
  const setIsCliqModalOpen = usePmtFormCtx((s) => s.setIsCliqModalOpen);
  const apiKey = usePmtFormCtx((s) => s.paymentProviders.value?.cliqApiKey);
  // Cliq form state
  const isSubmitting = useCliqCtx((s) => s.isSubmitting);
  const setIsCliqSubmitting = useCliqCtx((s) => s.setIsCliqSubmitting);
  const isCliqFormValid = useCliqCtx((s) => s.cliqFormState.isValid);
  const isDirty = usePmtFormCtx((s) => s.formState.isDirty);
  const { NavBlockElem: Blocker } = useNavBlock(isDirty || isSubmitting);

  // Event handlers
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!apiKey) return;

    window.CollectJS.startPaymentRequest(e);
    setIsCliqSubmitting(true);
  };
  const handleClose = () => {
    setIsSubmitting(false);
    setIsCliqModalOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      component="form"
      gap={2}
      onSubmit={handleSubmit}
      method="post"
    >
      <CliqForm />
      <Blocker />

      <Grid container direction="row" justifyContent="end" gap={2}>
        <MuiKendoButton
          type="submit"
          disabled={!isCliqFormValid || isSubmitting}
          id={submitButtonId}
          onClick={() => setIsCliqSubmitting(true)}
        >
          Submit
        </MuiKendoButton>

        <MuiKendoButton
          disabled={isSubmitting}
          onClick={handleClose}
          sx={{ background: "unset", "&:hover": { background: alpha("#000", 0.1) } }}
          style={{ color: alpha("#000", 0.8) }}
        >
          Cancel
        </MuiKendoButton>
      </Grid>
    </Grid>
  );
};

/** ### Requires `usePmtFormCtx`
 * @deprecated needs refactor
 */
const CliqPaymentModal: FC = (props) => {
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);
  const isCliqModalOpen = usePmtFormCtx((s) => s.isCliqModalOpen);
  const setIsCliqModalOpen = usePmtFormCtx((s) => s.setIsCliqModalOpen);
  const pmtType = usePmtFormCtx((s) => s.formState.form.paidBy);

  const handleClose = () => {
    setIsSubmitting(false);
    setIsCliqModalOpen(false);
  };

  return (
    <PaymentModal open={isCliqModalOpen} onClose={handleClose} title="Payment">
      {!!pmtType && Object.keys(cliqFormConfig).includes(pmtType) && (
        <CliqProvider>
          <CliqFormLayout {...props} />
        </CliqProvider>
      )}
    </PaymentModal>
  );
};

export default CliqPaymentModal;
