import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Checkbox } from "@/components/checkbox/Checkbox";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const WaiveConvFeeFieldInput: FC = () => {
  const field = usePmtFormCtx((s) => s.formState.getFieldProps("waiveConvFee"));
  return <Checkbox label="Waive Fee" checked={field.value} {...field} />;
};

const WaiveConvFeeField: FC = () => {
  const convFee = usePmtFormCtx((s) => s.convFee ?? 0);

  if (!usePmtFormCtx((s) => s.isCcOrAch)) return <></>;
  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
      <Grid component="span" sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>
        Conv. Fee
      </Grid>
      <Grid container sx={{ flex: 1.25, gap: "10px", alignItems: "center" }}>
        <CurrencyInput readOnly value={convFee} />

        <WaiveConvFeeFieldInput />
      </Grid>
    </Grid>
  );
};

export default WaiveConvFeeField;
