import { FC } from "react";
// components
import PaymentFormLayout from "./FormLayout";
// state
import PmtFormProvider from "./PmtFormProvider";
// interfaces
import { type PmtFormProps } from "./interfaces";

/** Contains all logic and state necessary for implementing a payment form
 *
 * @deprecated Move folder to correct scope - `/src/components/PaymentForm`
 * @todo Determine if `isPrinOnly` should be derived from browser params
 * @todo Allow passing in request data (`useReq`-managed) externally for easier control:
 * - paymentProviders - `paymentService.getPaymentProvidersByCompany`/`paymentService.getPaymentProviders`
 * - paymentData - `paymentService.getPaymentDataByColRecId`
 * - validPmtMethods - `paymentService.getSavedPaymentMethods`
 * - (may not be necessary) miscCategories - `paymentService.getMiscCategories`
 * - (may not be necessary) colRecId - `collectionsService.getColRecId`
 */
const PaymentForm: FC<PmtFormProps> = (props) => (
  <PmtFormProvider {...props}>
    <PaymentFormLayout />
  </PmtFormProvider>
);

export default PaymentForm;
