import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
// kendo
import { SortDescriptor, State as DataQueryState } from '@progress/kendo-data-query';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import {
  Grid,
  GridColumn,
  GridColumnReorderEvent,
  GridColumnResizeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSortChangeEvent,
  GridColumnProps as KendoGridColumnProps,
  Loader,
  PagerTargetEvent,
  GridHeaderCellProps,
  GridHeaderCell,
} from '@progress/kendo-react-all';
import { Button, Checkbox, DropDownButton } from '@/components';
import { Icons } from '@/components/icons';
// components
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// utils
import { accountsService } from '@/services/accountsService';
// interfaces
import { IPayment, IAccountPaymentHistoryRes } from '@/interfaces';
import { Filter, Sort } from '@/interfaces/requests';
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// style
import styles from './PaymentHistory.module.scss';

enum CellType {
  Date = 'Date',
  Currency = 'Currency',
}

type PaymentHistoryItemKeys = keyof IPayment;

interface GridColumnProps extends KendoGridColumnProps {
  order?: number;
  field: PaymentHistoryItemKeys;
  display?: boolean;
  button?: boolean;
  cellType?: CellType;
}

interface CustomHeaderCellProps extends GridHeaderCellProps {
  details?: string;
}

const STORAGE_KEY = 'paymentHistoryColumns';
const HIDE_PAYMENT_STORAGE_KEY = 'paymentHistoryFilter';
const initialDataState: DataQueryState = { skip: 0, take: 25 };
const initialSort: Array<SortDescriptor> = [{ field: 'pmtDate', dir: 'desc' }];

export const PaymentHistory: FC = () => {
  const [paymentHistory, setPaymentHistory] = useState<IAccountPaymentHistoryRes | undefined>();
  const defaultColumnSettings = [
    {
      field: 'pmtDate',
      title: 'Date',
      display: true,
      cellType: CellType.Date,
    },
    {
      field: 'entryType',
      title: 'Type',
      display: true,
    },
    {
      field: 'totalApplied',
      title: `Payment`,
      display: true,
      cellType: CellType.Currency,
    },
    {
      field: 'totalReceived',
      title: 'Paid',
      display: true,
      cellType: CellType.Currency,
    },
    {
      field: 'cpiPaid',
      title: 'CPI',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'principalPaid',
      title: 'Principal',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'interestPaid',
      title: 'Interest',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'lcPaid',
      title: 'LC Paid',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'lcOwed',
      title: 'LC Owed',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'nsfPaid',
      title: 'NSF Paid',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'taxPaid',
      title: 'Tax Paid',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'ddPaid',
      title: 'DD Paid',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'snPaid',
      title: 'SN Paid',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'inDaysLate',
      title: 'Days Late',
      display: false,
    },
    {
      field: 'paidBy',
      title: 'Paid By',
      display: false,
    },
    {
      field: 'paidRef',
      title: 'Reference',
      display: false,
    },
    {
      field: 'paidIn',
      title: 'Paid In',
      display: false,
    },
    {
      field: 'principalBalance',
      title: 'Prin. Balance',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'change',
      title: 'Change',
      display: false,
      cellType: CellType.Currency,
    },
    {
      field: 'payNote',
      title: 'Notes',
      display: false,
    },
  ].map((col, idx) => ({ order: idx, ...col })) as unknown as GridColumnProps[];

  const headerValues = {
    totalApplied:
      (paymentHistory?.totals.cpiPaid || 0) +
      (paymentHistory?.totals.principalPaid || 0) +
      (paymentHistory?.totals.interestPaid || 0) +
      (paymentHistory?.totals.lcPaid || 0) +
      (paymentHistory?.totals.nsfPaid || 0) +
      (paymentHistory?.totals.taxPaid || 0) +
      (paymentHistory?.totals.ddPaid || 0) +
      (paymentHistory?.totals.snPaid || 0),
    cpiPaid: paymentHistory?.totals.cpiPaid,
    principalPaid: paymentHistory?.totals.principalPaid,
    interestPaid: paymentHistory?.totals.interestPaid,
    lcPaid: paymentHistory?.totals.lcPaid,
    nsfPaid: paymentHistory?.totals.nsfPaid,
    taxPaid: paymentHistory?.totals.taxPaid,
    ddPaid: paymentHistory?.totals.ddPaid,
    snPaid: paymentHistory?.totals.snPaid,
  };

  const getColumnSettings = () => {
    const clonedDefaults = cloneDeep(defaultColumnSettings);
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY)!) ?? clonedDefaults;
    } catch (err) {
      return clonedDefaults;
    }
  };

  const params = useParams();
  const colRecId = Number(params.colRecId);

  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = React.useState(initialSort);
  const [columnState, setColumnState] = useState<GridColumnProps[]>(getColumnSettings());
  const [page, setPage] = useState<DataQueryState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | undefined>(25);
  const [gridSeed, setGridSeed] = useState(Math.random());
  const [hideNewPaymentsFilter, setHideNewPaymentsFilter] = useState<Filter | undefined>(
    localStorage.getItem(HIDE_PAYMENT_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(HIDE_PAYMENT_STORAGE_KEY)!)
      : undefined
  );

  const getPaymentHistory = (sort: Array<SortDescriptor>, page: DataQueryState) => {
    setLoading(true);
    const pg = page.skip! / page.take! + 1;

    const filter = hideNewPaymentsFilter ? [hideNewPaymentsFilter] : undefined;

    const payload = {
      colRecId,
      page: pg,
      perPage: page.take!,
      sort: sort.map((sort) => ({
        field: sort.field,
        direction: sort.dir!.toUpperCase() as Sort['direction'],
      })),
      filter: filter,
    };

    accountsService
      .getPaymentHistory(payload)
      .then((res) => {
        res?.data.map(resItem => {
          resItem.inDaysLate = resItem.inDaysLate === null ? 0 : resItem.inDaysLate;
          resItem.daysLate = resItem.daysLate === null ? 0 : resItem.daysLate;
          return resItem;
        });
        setPaymentHistory(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const toggleHideNewPayments = (event: CheckboxChangeEvent) => {
    if (event.value) {
      const hideNewPayments: Filter = {
        field: 'entryType',
        operator: '<>',
        value: 'New Pmt Due',
      };
      setHideNewPaymentsFilter(hideNewPayments);
      localStorage.setItem(HIDE_PAYMENT_STORAGE_KEY, JSON.stringify(hideNewPayments));
    } else {
      setHideNewPaymentsFilter(undefined);
      localStorage.removeItem(HIDE_PAYMENT_STORAGE_KEY);
    }
  };

  const setColumns = (newColumns: GridColumnProps[]) => {
    setColumnState(newColumns);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newColumns));
  };

  const onColumnResize = (event: GridColumnResizeEvent) => {
    if (event.end !== true) {
      return;
    }
    const colIndex = event.index;
    const columnField = event.columns[colIndex]!.field;
    const newWidth = event.newWidth;

    const tempColumns = cloneDeep(columnState);
    const index = tempColumns.findIndex((c) => c.field === columnField);

    tempColumns[index]!.width = newWidth;

    setColumns(tempColumns);
  };

  const onColumnReorder = (event: GridColumnReorderEvent) => {
    const tempColumns = cloneDeep(columnState);
    event.columns.forEach((column) => {
      const index = tempColumns.findIndex((c) => c.field === column.field);
      tempColumns[index]!.order = column.orderIndex!;
    });
    setColumns(tempColumns);
    setGridSeed(Math.random());
  };

  const resetGrid = () => {
    localStorage.removeItem(STORAGE_KEY);
    setGridSeed(Math.random());
    setColumnState(cloneDeep(defaultColumnSettings));
  };

  const handleUserSettingsRowClick = (column: GridColumnProps) => {
    if (!column) return;
    const tempColumns = cloneDeep(columnState);
    const tempColumn = tempColumns.find((c) => c.field === column.field);

    if (!tempColumn) return;
    if (tempColumn.display) {
      tempColumn.display = false;
      tempColumn.width = undefined;
    } else {
      tempColumn.display = true;
      tempColumn.width = 100;
    }

    setColumns(tempColumns);
  };

  const CurrencyCell = (props: any) => {
    const value = props.dataItem[props.field];
    return (
      <td className="k-table-td">
        <div className={styles.currencyCell}>{formatCurrency(value)}</div>
      </td>
    );
  };

  const DateCell = (props: any) => {
    const value = props.dataItem[props.field];
    return <td className={styles.currencyCell}>{formatDate(value)}</td>;
  };

  const getColumnCell = (column: GridColumnProps) => {
    if (column.cellType === CellType.Currency) return { data: CurrencyCell };
    if (column.cellType === CellType.Date) return { data: DateCell };
    return;
  };

  useEffect(() => {
    getPaymentHistory(sort, page);
    setGridSeed(Math.random());
  }, [sort, page, pageSizeValue, hideNewPaymentsFilter]);

  const renderColumns = columnState.filter((x) => x.display).sort((a, b) => a.order! - b.order!);

  const CustomHeaderCell = (props: CustomHeaderCellProps) => {
    const SortComponent =
      props.columnMenuWrapperProps.sort &&
      props.columnMenuWrapperProps.sort[0]?.field === props.field ? (
        props.columnMenuWrapperProps.sort[0]?.dir == 'asc' ? (
          <div className={styles.kendoSortArrow}>
            <Icons.KendoSortArrow />
          </div>
        ) : (
          <div className={styles.kendoSortArrowReverse}>
            <Icons.KendoSortArrow />
          </div>
        )
      ) : null;

    return (
      <div className={styles.customHeaderCell} onClick={props.onClick}>
        <div className={styles.titleContainer}>
          <div>{props.title}</div>
          {SortComponent}
        </div>
        <div className={styles.detailContainer}>{props.details}</div>
      </div>
    );
  };

  return (
    <AccountsMainPanel
      navBarTitle="Payment History"
      headerRight={
        <div className={styles.header}>
          <div className={styles.userSettingsButtonContainer}>
            <Checkbox
              label="Hide New Payments Due"
              labelStyles={{ fontWeight: 700 }}
              defaultChecked={hideNewPaymentsFilter !== undefined}
              onChange={(e) => toggleHideNewPayments(e)}
            />
            <div className={styles.userSettingsButton}>
              <div className={styles.columnSettings}>
                <DropDownButton
                  label="User Settings"
                  open={userSettingsOpen}
                  setOpen={setUserSettingsOpen}
                >
                  <div className={styles.userSettingsContent}>
                    {columnState.map((c) => {
                      return (
                        <div
                          className={styles.userSettingsCheckList}
                          onClick={() => handleUserSettingsRowClick(c)}
                        >
                          <Checkbox checked={c.display} /> <div>{c.title}</div>
                        </div>
                      );
                    })}
                  </div>
                </DropDownButton>
              </div>
            </div>
            <div className={styles.userSettingsButton}>
              <Button label="Reset Settings" secondary onClick={resetGrid} />
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <Grid
          key={gridSeed}
          data={paymentHistory?.data || []}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
          }}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          resizable={true}
          onColumnResize={onColumnResize}
          reorderable={true}
          onColumnReorder={onColumnReorder}
          skip={page.skip}
          take={page.take}
          total={paymentHistory?.total ?? 0}
          pageable={{
            buttonCount: 4,
            pageSizes: [10, 25, 100],
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
        >
          <GridNoRecords>
            <div style={{ textAlign: 'left' }}>
              {loading ? <Loader size="large" /> : 'No payment history found'}
            </div>
          </GridNoRecords>
          {renderColumns.map((column) => {
            return (
              <GridColumn
                field={column.field}
                title={column.title}
                sortable={true}
                key={column.field}
                width={column.width}
                className={column.className}
                cells={getColumnCell(column)}
                headerCell={(e) =>
                  Object.hasOwn(headerValues, column.field) ? (
                    <CustomHeaderCell
                      details={formatCurrency(
                        headerValues[column.field as keyof typeof headerValues]
                      )}
                      {...e}
                    />
                  ) : (
                    <GridHeaderCell {...e} />
                  )
                }
              />
            );
          })}
        </Grid>
      </div>
    </AccountsMainPanel>
  );
};
