import { FC } from "react";
// kendo
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

const BillFirstNameField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billFirstName"));
  return <TextInput label="First Name" required {...fieldProps} />;
};
const BillLastNameField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billLastName"));
  const isWsPmt = usePmtFormCtx((s) => s.formState.config.external.isWsPmt);
  const appliedLabel = isWsPmt ? "Account Name" : "Last Name";
  return <TextInput label={appliedLabel} required {...fieldProps} />;
};
const BillEmailField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("billEmail"));
  return <TextInput label="Email" required {...fieldProps} />;
};

/** ###  */
const BillingInfoFields: FC = () => [
  <BillFirstNameField key="billFirstName" />,
  <BillLastNameField key="billLastName" />,
  <BillEmailField key="billEmail" />,
];
export default BillingInfoFields;
