import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';

// DashboardCardLayout
const AccountsReceivableCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const accountsReceivable = useDashboardViewCtx(
    (s) => s.dashboardData?.accountsReceivable?.accountsReceivable
  );
  const totalAccounts = useDashboardViewCtx(
    (s) => s.dashboardData?.accountsReceivable?.totalAccounts
  );

  const secondary = !totalAccounts
    ? 'Data unavailable'
    : `${totalAccounts} Account` + (totalAccounts > 1 ? 's' : '');

  return (
    <DashboardCardLayout
      header="A/R Principal"
      primary={accountsReceivable ? formatCurrency(accountsReceivable) : '$ -'}
      secondary={secondary}
      isLoading={isLoading}
    />
  );
};

export default AccountsReceivableCard;
