export interface AgingByCompanyRes {
  curPer?: number;
  curNum?: number;
  curPmt?: number;
  curPrin?: number;
  onePer?: number;
  oneDayNum?: number;
  oneDayPmt?: number;
  oneDayPrin?: number;
  thirtyPer?: number;
  thirtyDayNum?: number;
  thirtyDayPmt?: number;
  thirtyDayPrin?: number;
  sixtyPer?: number;
  sixtyDayNum?: number;
  sixtyDayPmt?: number;
  sixtyDayPrin?: number;
}

export interface ColumnData<T extends string | number = number> {
  percent: T;
  accountCount: T;
  payDelq: T;
  ar: T;
  title?: string;
}

export const scrollBarHeight = '3px';
