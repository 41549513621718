import { toast } from "react-toastify";
// utils
// interfaces
import { AxiosService } from "./axiosService";
import { ApiResponse } from "@/interfaces/Api";
import { UpdateUserAuthReq } from "@/features/Settings/AuthorizationMgmt/UserAuthDetail/form";
import { RoleDb } from "@/features/Settings/interfaces/role";
import { UserAuthRes } from "@/features/Settings/interfaces/user";
import {
  AddRoleReq,
  UpdateRoleReq,
} from "@/features/Settings/AuthorizationMgmt/AuthRoleDetail/interfaces";

class UserAuthService extends AxiosService {
  public constructor() {
    super();
  }

  async getUsersByOrgId() {
    try {
      const res = await this.axios.get<ApiResponse<UserAuthRes[]>>("/Users/GetUsersByOrgId");
      return res.data.data;
    } catch (e) {
      toast.error("Unable to fetch list of users");
      console.error("Unable to fetch list of users", e);
      throw e;
    }
  }
  async updateUserAuth(reqBody: UpdateUserAuthReq) {
    try {
      const res = await this.axios.put("/Users/UpdateUserAuth", reqBody);
      toast.success("User successfully updated");
      return res;
    } catch (e) {
      console.error(e);
      toast.error("Failed to update user");
      throw e;
    }
  }

  async getRoleTemplatesForOrg() {
    try {
      const res = await this.axios.get<ApiResponse<RoleDb[]>>("/AuthRole/GetRoleTemplatesByOrg");
      return res.data.data;
    } catch (e) {
      toast.error("Unable to get security templates for org");
      console.error("Unable to get security templates for org", e);
      throw e;
    }
  }
  /** @deprecated unused */
  async getRoleTemplate(roleRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<RoleDb>>("/AuthRole/GetRoleTemplate", {
        params: { roleRecId },
      });
      return res.data.data;
    } catch (e) {
      toast.error("Unable to fetch role template");
      console.error("Unable to fetch role template", e);
      throw e;
    }
  }
  async updateRoleTemplate(userData: UpdateRoleReq) {
    try {
      const res = await this.axios.put("/AuthRole/UpdateRoleTemplate", userData);
      toast.success("Role updated");
      return res;
    } catch (e) {
      toast.error("Failed to update role template");
      throw e;
    }
  }
  async addRoleTemplate(userData: AddRoleReq) {
    try {
      const res = await this.axios.post<ApiResponse<{ recId: number }>>(
        "/AuthRole/AddRoleTemplate",
        userData
      );
      toast.success("Role added");
      return res.data.data?.recId;
    } catch (e) {
      toast.error("Failed to add role template");
      throw e;
    }
  }
}

export const userAuthService = new UserAuthService();
