import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerInsuranceInformation } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardInsuranceInformation: FC<{
  insuranceInformation: LedgerInsuranceInformation;
}> = ({ insuranceInformation }) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Insurance Information</div>
    <table style={ledgerStyles.ledgerTable}>
      <tbody>
        <tr style={ledgerStyles.labelRow}>
          <td style={ledgerStyles.labelRowTd}>Company</td>
          <td style={ledgerStyles.labelRowTd}>Phone</td>
          <td colSpan={3} style={ledgerStyles.labelRowTd}>
            Fax
          </td>
        </tr>
        <tr className={'bot-line'}>
          <td>{insuranceInformation.company}</td>
          <td>{insuranceInformation.phone}</td>
          <td colSpan={3}>{insuranceInformation.fax}</td>
        </tr>
        <tr style={ledgerStyles.labelRow}>
          <td style={ledgerStyles.labelRowTd}>Address</td>
          <td style={ledgerStyles.labelRowTd}>Contact</td>
          <td style={ledgerStyles.labelRowTd}>Phone</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            Fax
          </td>
        </tr>
        <tr className={'bot-line'}>
          <td>{insuranceInformation.address}</td>
          <td>{insuranceInformation.contact}</td>
          <td>{insuranceInformation.contactPhone}</td>
          <td colSpan={2}>{insuranceInformation.contactFax}</td>
        </tr>
        <tr style={ledgerStyles.labelRow}>
          <td colSpan={5} style={ledgerStyles.labelRowTd}>
            Email
          </td>
        </tr>
        <tr className={'bot-line'}>
          <td colSpan={5}>{insuranceInformation.contactEmail}</td>
        </tr>
        <tr style={ledgerStyles.labelRow}>
          <td style={ledgerStyles.labelRowTd}>Policy #</td>
          <td style={ledgerStyles.labelRowTd}>Coverage</td>
          <td style={ledgerStyles.labelRowTd}>Expiration Date</td>
          <td style={ledgerStyles.labelRowTd}>Status</td>
          <td style={ledgerStyles.labelRowTd}>Date Cancelled</td>
        </tr>
        <tr className={'bot-line'}>
          <td>{insuranceInformation.policyNumber}</td>
          <td>{insuranceInformation.coverage}</td>
          <td>{formatDate(insuranceInformation.expirationDate)}</td>
          <td>{insuranceInformation.status}</td>
          <td>{formatDate(insuranceInformation.dateCancelled)}</td>
        </tr>
        <tr style={ledgerStyles.labelRow}>
          <td style={ledgerStyles.labelRowTd}>Comp. Deductible</td>
          <td style={ledgerStyles.labelRowTd}>Collision Deductible</td>
          <td style={ledgerStyles.labelRowTd}>Fire & Theft Deductible</td>
          <td colSpan={2} style={ledgerStyles.labelRowTd}>
            CPI Status
          </td>
        </tr>
        <tr className={'bot-line'}>
          <td>
            {formatCurrency(insuranceInformation.compDeductible || 0)}
          </td>
          <td>
            {formatCurrency(insuranceInformation.collisionDeductible || 0)}
          </td>
          <td>
            {formatCurrency(insuranceInformation.fireTheftDeductible || 0)}
          </td>
          <td colSpan={2}>{insuranceInformation.cpiStatus}</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default LedgerCardInsuranceInformation;
