import { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// components
import WsFormBodyLayout from "../layoutComponents/WsFormBodyLayout";
import WsFormSection from "../layoutComponents/WsFormSection";
import PaymentsSummary from "./PaymentsSummary";
import PaymentFormLayout from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/FormLayout";

/** ### Displays payment details about app and list of sent payments */
const WsPaymentsViewLayout: FC = () => (
  <WsFormBodyLayout>
    <WsFormSection>
      <Outlet />
    </WsFormSection>
  </WsFormBodyLayout>
);

const WsPaymentsView: FC = () => (
  <Routes>
    <Route element={<WsPaymentsViewLayout />}>
      <Route path="" element={<PaymentsSummary />} />

      <Route path="collect/*">
        <Route path="" element={<PaymentFormLayout />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Route>
    </Route>

    {/* Fallback */}
    <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
  </Routes>
);

export default WsPaymentsView;
