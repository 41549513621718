import { FC, useState } from "react";
// mui
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  DataGrid,
  GridSortModel,
  GridOverlayProps,
  GridOverlay,
  gridClasses,
} from "@mui/x-data-grid";
// kendo
import LgLoader from "@/components/loader/LgLoader";
// components
import ExpandableRow from "./ExpandableRow";
// state
import { useCollectionQueuesCtx } from "../../CollectionQueuesProvider";
import { useCollectionQueuesListCtx } from "../CollectionQueuesListProvider";
// utils
import { tableColumns } from "./tableColumns";

const initialSort: GridSortModel = [{ field: "queueName", sort: "desc" }];

const CustomOverlay: FC<GridOverlayProps> = () => {
  const theme = useTheme();

  return (
    <Grid
      component={GridOverlay}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        "& .no-rows-primary": { fill: theme.palette.mode === "light" ? "#AEB8C2" : "#3D4751" },
        "& .no-rows-secondary": { fill: theme.palette.mode === "light" ? "#E8EAED" : "#1D2126" },
      }}
    >
      <LgLoader />
    </Grid>
  );
};

/** ###  */
const CollectionQueuesTable: FC = () => {
  const isLoading = useCollectionQueuesCtx((s) => s.isLoading);
  const queues = useCollectionQueuesCtx((s) => s.queues);
  const pageModel = useCollectionQueuesListCtx((s) => s.pageModel);
  const setPageModel = useCollectionQueuesListCtx((s) => s.setPageModel);

  const [sortModel, setSortModel] = useState<GridSortModel>(initialSort);

  const isUpdating = isLoading && queues.length === 0;

  return (
    <DataGrid
      loading={isLoading}
      rows={queues}
      columns={tableColumns}
      getRowId={(r) => r.recid}
      paginationModel={pageModel}
      onPaginationModelChange={setPageModel}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      rowHeight={40}
      slots={{ row: ExpandableRow, loadingOverlay: CustomOverlay }}
      // @todo need to properly implement - required for expandable row
      sx={{ [`& *.${gridClasses.overlayWrapper}`]: { minHeight: isUpdating ? 300 : undefined } }}
    />
  );
};

export default CollectionQueuesTable;
