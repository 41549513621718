import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateFilter from './DateFilter/DateFilter';
import LocationDropdown from './LocationDropdown';

/** ### Set table filters here
 * - Renders filter(s) based on the types set in the column spec
 * - Handles conditional logic for determining if current subview allows filters
 * - String filters have a set amount column filter is a string filter
 */
const DashboardFilters: FC = () => (
  <Grid
    container
    direction="row"
    gap={1}
    alignItems="center"
    justifyContent="space-between"
    flex="1"
    p={1}
  >
    <DateFilter />
    <LocationDropdown />
  </Grid>
);

export default DashboardFilters;
