import { FC } from "react";
// kendo
import { RadioGroupInput } from "@/components/inputs/radioGroupInput/RadioGroupInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// validation
import { acctTypeOptions, AccountType } from "@/enums/payment";

/** ###  */
const AchAcctTypeField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("achAcctType"));
  const setField = usePmtFormCtx((s) => s.formState.setField);

  return (
    <RadioGroupInput
      label="Account Type"
      layout="horizontal"
      data={acctTypeOptions}
      {...fieldProps}
      value={fieldProps.value && AccountType.options.indexOf(fieldProps.value)}
      onChange={(e) => setField("achAcctType", AccountType.options[e.value])}
    />
  );
};

export default AchAcctTypeField;
