import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// kendo
import { Checkbox } from '@/components/checkbox/Checkbox';
import { Column } from '@/components/table/TableInterface';
// state
import { useAppDispatch } from '@/store/store';
import { useAuthSelector } from '@/features/auth/authSlice';
import {
  getSaleData,
  getSaleDataNoLoading,
  getVerificationList,
} from '@/features/Sales/salesActionCreator';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { salesService } from '@/services/salesService';
// interfaces
import { Reference } from '@/interfaces/Accounts';
import { UpdateAppVerificationCtois, VerificationItem, VerificationList } from '@/interfaces/Sales';
import { BuyerType, EmploymentType } from '@/enums/general';
import { PaymentInterval } from '@/enums/payment';

// @todo use context to manage state
export const useVerificationForm = (
  contactType: string,
  contactRecId: number,
  verificationListItem: VerificationList
) => {
  type YesNoNull = 'Yes' | 'No' | null;
  const { userName } = useAuthSelector((s) => s);
  const { saleData } = useSalesSelector((s) => s);
  const dispatch = useAppDispatch();
  const params = useParams();
  const appRecId = Number(params.id);
  const isCobuyer = verificationListItem.contactFor === 'Co-Buyer';
  const buyerRecId = isCobuyer ? saleData.coBuyer?.rec_Id : saleData.buyer?.rec_Id;
  const employerRecId = isCobuyer ? saleData.coBuyerEmployer?.recId : saleData.buyerEmployer?.recId;
  const [buyerInformationVerificationItems, setBuyerInformationVerificationItems] = useState<
    VerificationItem[]
  >([]);
  const [employerInformationVerificationItems, setEmployerInformationVerificationItems] = useState<
    VerificationItem[]
  >([]);
  const [personalContactVerificationItems, setPersonalContactVerificationItems] = useState<
    VerificationItem[]
  >([]);
  const [referenceData, setReferenceData] = useState<Reference | undefined>(undefined);
  const [updateVerificationLoading, setUpdateVerificationLoading] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState<YesNoNull>(
    verificationListItem.verifiedAddress === true
      ? 'Yes'
      : verificationListItem.verifiedAddress === false
      ? 'No'
      : null
  );
  const [verifiedEmployer, setVerifiedEmployer] = useState<YesNoNull>(
    verificationListItem.verifiedEmployer === true
      ? 'Yes'
      : verificationListItem.verifiedEmployer === false
      ? 'No'
      : null
  );
  const [verifiedContact, setVerifiedContact] = useState<YesNoNull>(
    verificationListItem.verifiedContact === true
      ? 'Yes'
      : verificationListItem.verifiedContact === false
      ? 'No'
      : null
  );
  const [verifiedPhone, setVerifiedPhone] = useState<YesNoNull>(
    verificationListItem.verifiedPhone === true
      ? 'Yes'
      : verificationListItem.verifiedPhone === false
      ? 'No'
      : null
  );
  const typeOfHomeData = [
    'House',
    'Apartment',
    'Townhome',
    'Duplex',
    'Quadplex',
    'Trailer',
    'Other',
  ];

  enum verificationGroups {
    BuyerInformation = "Buyer's Information",
    CoBuyerInformation = "Co-Buyer's Information",
    BuyerEmployer = "Buyer's Employer",
    CoBuyerEmployer = "Co-Buyer's Employer",
    PersonalContact = 'Personal Contact',
  }

  const formatLastContact = verificationListItem.lastCall
    ? dayjs(verificationListItem.lastCall).format('MM/DD/YYYY hh:mm A')
    : '';

  const referenceDefaultValues = {
    name: '',
    relationship: '',
    referenceFor: 'Buyer' as BuyerType,
    yearsKnown: 0,
    monthsKnown: 0,
    address: '',
    city: '',
    state: '',
    zip: '',
    currentCustomer: 'N/A',
    spokeTo: '',
    lastContact: '',
    contactStatus: '',
    email: '',
    phoneNumber: '',
    dealership: '',
    comments: '',
  };

  const referenceVerificationForm = useForm({
    defaultValues: referenceDefaultValues,
  });

  const buyerLandlordDefaultValues = {
    landlordsName: saleData.buyer?.b_LANDMORG,
    rentOrOwn: saleData.buyer?.b_RENTOWN,
    livedAtYears: saleData.buyer?.b_RYEARS,
    livedAtMonths: saleData.buyer?.b_RMONTHS,
    typeOfHome: saleData.buyer?.b_HOMETYPE,
    rentAmount: saleData.buyer?.b_RENTMORG,
    mortgageBalance: saleData.buyer?.b_MORGBAL,
    currentOrPastDue: saleData.buyer?.b_CURPAST,
    spokeTo: saleData.buyer?.b_SPOKETO,
    lastContact: formatLastContact,
    contactStatus: verificationListItem.callResults,
    landlordsPhoneNumber: saleData.buyer?.b_LLPHONE,
  };

  const coBuyerLandlordDefaultValues = {
    landlordsName: saleData.coBuyer?.b_LANDMORG,
    rentOrOwn: saleData.coBuyer?.b_RENTOWN,
    livedAtYears: saleData.coBuyer?.b_RYEARS,
    livedAtMonths: saleData.coBuyer?.b_RMONTHS,
    typeOfHome: saleData.coBuyer?.b_HOMETYPE,
    rentAmount: saleData.coBuyer?.b_RENTMORG,
    mortgageBalance: saleData.coBuyer?.b_MORGBAL,
    currentOrPastDue: saleData.coBuyer?.b_CURPAST,
    spokeTo: saleData.coBuyer?.b_SPOKETO,
    lastContact: formatLastContact,
    contactStatus: verificationListItem.callResults,
    landlordsPhoneNumber: saleData.coBuyer?.b_LLPHONE,
  };

  const landlordVerificationForm = useForm({
    defaultValues:
      verificationListItem.contactFor === 'Co-Buyer'
        ? coBuyerLandlordDefaultValues
        : buyerLandlordDefaultValues,
  });

  const buyerEmployerDefaultValues = {
    primaryEmployer: saleData.buyerEmployer?.employer,
    occupation: saleData.buyerEmployer?.jobTitle,
    supervisor: saleData.buyerEmployer?.supervisor,
    yearsWorked: saleData.buyerEmployer?.years,
    monthsWorked: saleData.buyerEmployer?.months,
    employmentType: saleData.buyerEmployer?.employmentType as EmploymentType,
    payPeriod: saleData.buyerEmployer?.payFrequency as PaymentInterval,
    takeHomePay: saleData.buyerEmployer?.salary,
    nextPayday: dayjs(saleData.buyerEmployer?.nextPayDay).utc().format('YYYY-MM-DD'),
    paidOn: saleData.buyerEmployer?.paidOn,
    hoursOrShift: saleData.buyerEmployer?.hours,
    spokeTo: saleData.buyerEmployer?.spokeTo,
    lastContact: formatLastContact,
    contactStatus: verificationListItem.callResults,
    employersAddress: saleData.buyerEmployer?.address,
    city: saleData.buyerEmployer?.city,
    state: saleData.buyerEmployer?.state,
    zip: saleData.buyerEmployer?.zip,
    employerPhoneNumber: saleData.buyerEmployer?.phoneNumber,
  };

  const coBuyerEmployerDefaultValues = {
    primaryEmployer: saleData.coBuyerEmployer?.employer,
    occupation: saleData.coBuyerEmployer?.jobTitle,
    supervisor: saleData.coBuyerEmployer?.supervisor,
    yearsWorked: saleData.coBuyerEmployer?.years,
    monthsWorked: saleData.coBuyerEmployer?.months,
    employmentType: saleData.coBuyerEmployer?.employmentType as EmploymentType,
    payPeriod: saleData.coBuyerEmployer?.payFrequency as PaymentInterval,
    takeHomePay: saleData.coBuyerEmployer?.salary,
    nextPayday: dayjs(saleData.coBuyerEmployer?.nextPayDay).utc().format('YYYY-MM-DD'),
    paidOn: saleData.coBuyerEmployer?.paidOn,
    hoursOrShift: saleData.coBuyerEmployer?.hours,
    spokeTo: saleData.coBuyerEmployer?.spokeTo,
    lastContact: formatLastContact,
    contactStatus: verificationListItem.callResults,
    employersAddress: saleData.coBuyerEmployer?.address,
    city: saleData.coBuyerEmployer?.city,
    state: saleData.coBuyerEmployer?.state,
    zip: saleData.coBuyerEmployer?.zip,
    employerPhoneNumber: saleData.coBuyerEmployer?.phoneNumber,
  };

  const employerVerificationForm = useForm({
    defaultValues:
      verificationListItem.contactFor === 'Co-Buyer'
        ? coBuyerEmployerDefaultValues
        : buyerEmployerDefaultValues,
  });

  const initData = async () => {
    try {
      if (contactType === 'Reference') {
        // @todo use async/await
        await salesService.getReferenceByRecId(contactRecId).then((res) => {
          referenceVerificationForm.reset({
            name: res.name,
            relationship: res.relationship,
            referenceFor: res.refFor,
            yearsKnown: res.knownForYears,
            monthsKnown: res.knownForMonths,
            address: res.shortAddress,
            city: res.city,
            state: res.state,
            zip: res.zip,
            currentCustomer: res.currentCustomer,
            spokeTo: res.spokeTo,
            lastContact: formatLastContact,
            contactStatus: verificationListItem.callResults,
            email: res.email,
            phoneNumber: res.cellPhone,
            dealership: res.currentCustomerLot,
            comments: res.comments,
          });
          setReferenceData(res);
        });
      }
      // @todo use async/await
      await salesService.getVerificationItems(contactType, contactRecId).then((res) => {
        setBuyerInformationVerificationItems(
          res.filter(
            (item) =>
              item.group === verificationGroups.BuyerInformation ||
              item.group === verificationGroups.CoBuyerInformation
          )
        );
        setEmployerInformationVerificationItems(
          res.filter(
            (item) =>
              item.group === verificationGroups.BuyerEmployer ||
              item.group === verificationGroups.CoBuyerEmployer
          )
        );
        setPersonalContactVerificationItems(
          res.filter((item) => item.group === verificationGroups.PersonalContact)
        );
      });
    } catch {
      toast.error('There was an error receiving the verification information');
    }
  };

  useEffect(() => {
    dispatch(salesActions.setVerificationItemLoading(true));
    // @todo use async/await
    initData().finally(() => dispatch(salesActions.setVerificationItemLoading(false)));
  }, []);

  // @todo move component to file
  const BuyerInformationCheckboxCell: FC = (props: any) => {
    const verificationItem: VerificationItem = props.dataItem;
    const verificationItemField = props.field;
    return (
      <td {...props}>
        <Checkbox
          onChange={() => {
            let newVerificationItems;
            let verifiedYesOrNoAddress: YesNoNull = null;
            let verifiedYesOrNoPhone: YesNoNull = null;
            if (verificationItemField === 'verifiedYes') {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedYes ? false : verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedYes && !verificationItem.verifiedNo
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };

              newVerificationItems = buyerInformationVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === 'B-Addr' || verificationItem.itemKey === 'C-Addr') {
                verifiedYesOrNoAddress = newVerificationItem.verifiedYes ? 'Yes' : null;
                setVerifiedAddress(verifiedYesOrNoAddress);
              }
              if (
                verificationItem.itemKey === 'B-Phone' ||
                verificationItem.itemKey === 'C-Phone'
              ) {
                verifiedYesOrNoPhone = newVerificationItem.verifiedYes ? 'Yes' : null;
                setVerifiedPhone(verifiedYesOrNoPhone);
              }
              setBuyerInformationVerificationItems(newVerificationItems);
            } else {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedNo ? false : verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedYes && !verificationItem.verifiedNo
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };
              newVerificationItems = buyerInformationVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === 'B-Addr' || verificationItem.itemKey === 'C-Addr') {
                verifiedYesOrNoAddress = newVerificationItem.verifiedNo ? 'No' : null;
                setVerifiedAddress(verifiedYesOrNoAddress);
              }
              if (
                verificationItem.itemKey === 'B-Phone' ||
                verificationItem.itemKey === 'C-Phone'
              ) {
                verifiedYesOrNoPhone = newVerificationItem.verifiedNo ? 'No' : null;
                setVerifiedPhone(verifiedYesOrNoPhone);
              }
              setBuyerInformationVerificationItems(newVerificationItems);
            }
            verifyItem(newVerificationItems, 'Buyer', {
              address:
                verificationItem.itemKey === 'B-Addr' || verificationItem.itemKey === 'C-Addr'
                  ? verifiedYesOrNoAddress
                  : verifiedAddress,
              employer: verifiedEmployer,
              contact: verifiedContact,
              phone:
                verificationItem.itemKey === 'B-Phone' || verificationItem.itemKey === 'C-Phone'
                  ? verifiedYesOrNoPhone
                  : verifiedPhone,
            });
          }}
          checked={
            verificationItemField == 'verifiedYes'
              ? verificationItem.verifiedYes
              : verificationItem.verifiedNo
          }
        />
      </td>
    );
  };

  // @todo move component to file
  const EmployerInformationCheckboxCell: FC = (props: any) => {
    const verificationItem: VerificationItem = props.dataItem;
    const verificationItemField = props.field;
    return (
      <td {...props}>
        <Checkbox
          onChange={() => {
            let newVerificationItems;
            let verifiedYesOrNo: YesNoNull = null;
            if (verificationItemField === 'verifiedYes') {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedYes ? false : verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedYes && !verificationItem.verifiedNo
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };
              newVerificationItems = employerInformationVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === 'B-Pemp' || verificationItem.itemKey === 'C-Pemp') {
                verifiedYesOrNo = newVerificationItem.verifiedYes ? 'Yes' : null;
                setVerifiedEmployer(verifiedYesOrNo);
              }
              setEmployerInformationVerificationItems(newVerificationItems);
            } else {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedNo ? false : verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedYes && !verificationItem.verifiedNo
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };
              newVerificationItems = employerInformationVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === 'B-Pemp' || verificationItem.itemKey === 'C-Pemp') {
                verifiedYesOrNo = newVerificationItem.verifiedNo ? 'No' : null;
                setVerifiedEmployer(verifiedYesOrNo);
              }
              setEmployerInformationVerificationItems(newVerificationItems);
            }
            verifyItem(newVerificationItems, 'Employer', {
              address: verifiedAddress,
              employer: verifiedYesOrNo,
              contact: verifiedContact,
              phone: verifiedPhone,
            });
          }}
          checked={
            verificationItemField == 'verifiedYes'
              ? verificationItem.verifiedYes
              : verificationItem.verifiedNo
          }
        />
      </td>
    );
  };

  // @todo move component to file
  const PersonalContactCheckboxCell: FC = (props: any) => {
    const verificationItem: VerificationItem = props.dataItem;
    const verificationItemField = props.field;
    const itemKeySuffix =
      contactType === 'Employer'
        ? isCobuyer
          ? '-CPe'
          : '-BPe'
        : contactType === 'Landlord'
        ? isCobuyer
          ? '-cLL'
          : '-LL'
        : verificationItem.contactId?.toString();
    return (
      <td {...props}>
        <Checkbox
          onChange={() => {
            let newVerificationItems;
            let verifiedYesOrNo: YesNoNull = null;
            if (verificationItemField === 'verifiedYes') {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedYes ? false : verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedYes && !verificationItem.verifiedNo
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };
              newVerificationItems = personalContactVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === `Call` + itemKeySuffix) {
                verifiedYesOrNo = newVerificationItem.verifiedYes ? 'Yes' : null;
                setVerifiedContact(verifiedYesOrNo);
              }
              setPersonalContactVerificationItems(newVerificationItems);
            } else {
              const newVerificationItem: VerificationItem = {
                ...verificationItem,
                verifiedYes: !verificationItem.verifiedNo ? false : verificationItem.verifiedYes,
                verifiedNo: !verificationItem.verifiedNo,
                verifiedBy:
                  !verificationItem.verifiedNo && !verificationItem.verifiedYes
                    ? `${userName} at ${dayjs().format('MM/DD/YY hh:mm A')}`
                    : '',
              };
              newVerificationItems = personalContactVerificationItems.map((item) => {
                if (item.itemKey === newVerificationItem.itemKey) {
                  return newVerificationItem;
                }
                return item;
              });
              if (verificationItem.itemKey === `Call` + itemKeySuffix) {
                verifiedYesOrNo = newVerificationItem.verifiedNo ? 'No' : null;
                setVerifiedContact(verifiedYesOrNo);
              }
              setPersonalContactVerificationItems(newVerificationItems);
            }
            verifyItem(newVerificationItems, 'Personal', {
              address: verifiedAddress,
              employer: verifiedEmployer,
              contact: verifiedYesOrNo,
              phone: verifiedPhone,
            });
          }}
          checked={
            verificationItemField == 'verifiedYes'
              ? verificationItem.verifiedYes
              : verificationItem.verifiedNo
          }
        />
      </td>
    );
  };

  // @todo move object/array out of body
  const addressColumn: Column[] = [
    {
      field: 'item',
      title: "Buyer's Information",
    },
    {
      field: 'info',
      title: 'Information To Verify',
    },
    {
      field: 'verifiedYes',
      title: 'Yes',
      cells: { data: BuyerInformationCheckboxCell },
    },
    {
      field: 'verifiedNo',
      title: 'No',
      cells: { data: BuyerInformationCheckboxCell },
    },
    {
      field: 'verifiedBy',
      title: 'Verified By',
    },
  ];

  // @todo move object/array out of body
  const employerColumn: Column[] = [
    {
      field: 'item',
      title: 'Employer Information',
    },
    {
      field: 'info',
      title: 'Information To Verify',
    },
    {
      field: 'verifiedYes',
      title: 'Yes',
      cells: { data: EmployerInformationCheckboxCell },
    },
    {
      field: 'verifiedNo',
      title: 'No',
      cells: { data: EmployerInformationCheckboxCell },
    },
    {
      field: 'verifiedBy',
      title: 'Verified By',
    },
  ];

  // @todo move object/array out of body
  const personalContactColumn: Column[] = [
    {
      field: 'item',
      title: 'Personal Contact',
    },
    {
      field: 'info',
      title: 'Information To Verify',
    },
    {
      field: 'verifiedYes',
      title: 'Yes',
      cells: { data: PersonalContactCheckboxCell },
    },
    {
      field: 'verifiedNo',
      title: 'No',
      cells: { data: PersonalContactCheckboxCell },
    },
    {
      field: 'verifiedBy',
      title: 'Verified By',
    },
  ];

  const updateEmployerVerification = async (data: typeof buyerEmployerDefaultValues) => {
    const callInFuture = personalContactVerificationItems.find(
      (item) =>
        item.itemKey === (verificationListItem.contactFor === 'Co-Buyer' ? 'Call-CPe' : 'Call-BPe')
    )?.verifiedNo;

    const verificationItems = buyerInformationVerificationItems.concat(
      employerInformationVerificationItems,
      personalContactVerificationItems
    );

    const verificationCtoi = verificationItems.map((item) => {
      return {
        recId: item.appVerctoiRecId,
        verifiedN: item.verifiedNo,
        verifiedY: item.verifiedYes,
        verifiedByOn: item.verifiedBy,
      } as UpdateAppVerificationCtois;
    });

    try {
      setUpdateVerificationLoading(true);

      await accountsService.updateEmployer({
        recId: employerRecId,
        buyerRecId: buyerRecId,
        employer: data.primaryEmployer!,
        employmentType: data.employmentType,
        jobTitle: data.occupation!,
        years: data.yearsWorked!,
        months: data.monthsWorked!,
        salary: data.takeHomePay!,
        payFrequency: data.payPeriod,
        address: data.employersAddress!,
        city: data.city!,
        state: data.state!,
        zip: data.zip!,
        phoneNumber: data.employerPhoneNumber!,
        doNotCall: !!callInFuture,
        doNotText: false,
        doNotEmail: false,
        buyerOrCoBuyer: isCobuyer ? BuyerType.CoBuyer : BuyerType.Buyer,
        supervisor: data.supervisor!,
        nextPayDay: data.nextPayday !== 'Invalid Date' && data.nextPayday ? data.nextPayday : null,
        paidOn: data.paidOn !== 'Invalid Date' && data.paidOn ? data.paidOn : undefined,
        hours: data.hoursOrShift!,
        spokeTo: data.spokeTo,
      });

      await salesService.updateVerification({
        updateAppVerificationContact: {
          isReference: false,
          appRecId: appRecId,
          recId: verificationListItem.recId,
          verifiedAddress: verifiedAddress,
          verifiedContact: verifiedContact,
          verifiedEmployer: verifiedEmployer,
          verifiedPhone: verifiedPhone,
          callResults: data.contactStatus,
          lastCall: new Date(data.lastContact),
          lastCallLong: data.lastContact
            ? dayjs(data.lastContact).format('ddd, MMM D YYYY, h:mm A')
            : '',
        },
        updateAppVerificationCtois: verificationCtoi,
      });

      await dispatch(getSaleDataNoLoading(appRecId));
      // @todo use async/await
      await dispatch(getVerificationList(appRecId)).then((res) => {
        const newVerificationListItem = res?.find(
          (item) => item.recId === verificationListItem.recId
        );
        dispatch(salesActions.setVerificationListItem(newVerificationListItem!));
      });
      await dispatch(getSaleData(appRecId));
      await initData();
      dispatch(salesActions.setShowVerificationForm(true));
    } catch {
      toast.error('Error updating employer verification');
    } finally {
      setUpdateVerificationLoading(false);
    }
  };

  const verifyItem = async (
    verificationItems: VerificationItem[],
    verificationType: 'Employer' | 'Buyer' | 'Personal',
    verified: {
      address: YesNoNull;
      contact: YesNoNull;
      employer: YesNoNull;
      phone: YesNoNull;
    }
  ) => {
    let newVerificationItems;

    if (verificationType === 'Buyer') {
      newVerificationItems = verificationItems.concat(
        employerInformationVerificationItems,
        personalContactVerificationItems
      );
    } else if (verificationType === 'Employer') {
      newVerificationItems = verificationItems.concat(
        buyerInformationVerificationItems,
        personalContactVerificationItems
      );
    } else {
      newVerificationItems = verificationItems.concat(
        buyerInformationVerificationItems,
        employerInformationVerificationItems
      );
    }

    const verificationCtoi = newVerificationItems.map((item) => {
      return {
        recId: item.appVerctoiRecId,
        verifiedN: item.verifiedNo,
        verifiedY: item.verifiedYes,
        verifiedByOn: item.verifiedBy,
      } as UpdateAppVerificationCtois;
    });

    try {
      if (contactType === 'Employer') {
        await salesService.updateVerification({
          updateAppVerificationContact: {
            isReference: false,
            appRecId: appRecId,
            recId: verificationListItem.recId,
            verifiedAddress: verified.address,
            verifiedContact: verified.contact,
            verifiedEmployer: verified.employer,
            verifiedPhone: verified.phone,
            callResults: employerVerificationForm.watch('contactStatus'),
            lastCall: new Date(employerVerificationForm.watch('lastContact')),
            lastCallLong: employerVerificationForm.watch('lastContact')
              ? dayjs(employerVerificationForm.watch('lastContact')).format(
                  'ddd, MMM D YYYY, h:mm A'
                )
              : '',
          },
          updateAppVerificationCtois: verificationCtoi,
        });
      } else if (contactType === 'Reference') {
        await salesService.updateVerification({
          updateAppVerificationContact: {
            isReference: true,
            appRecId: appRecId,
            recId: verificationListItem.recId,
            verifiedAddress: verified.address,
            verifiedContact: verified.contact,
            verifiedEmployer: verified.employer,
            verifiedPhone: verified.phone,
            callResults: referenceVerificationForm.watch('contactStatus'),
            lastCall: new Date(referenceVerificationForm.watch('lastContact')),
            lastCallLong: referenceVerificationForm.watch('lastContact')
              ? dayjs(referenceVerificationForm.watch('lastContact')).format(
                  'ddd, MMM D YYYY, h:mm A'
                )
              : '',
          },
          updateAppVerificationCtois: verificationCtoi,
        });
      } else {
        await salesService.updateVerification({
          updateAppVerificationContact: {
            isReference: false,
            appRecId: appRecId,
            recId: verificationListItem.recId,
            verifiedAddress: verified.address,
            verifiedContact: verified.contact,
            verifiedEmployer: verified.employer,
            verifiedPhone: verified.phone,
            callResults: landlordVerificationForm.watch('contactStatus'),
            lastCall: new Date(landlordVerificationForm.watch('lastContact')),
            lastCallLong: landlordVerificationForm.watch('lastContact')
              ? dayjs(landlordVerificationForm.watch('lastContact')).format(
                  'ddd, MMM D YYYY, h:mm A'
                )
              : '',
          },
          updateAppVerificationCtois: verificationCtoi,
        });
      }
      const newVerificationListItem = {
        ...verificationListItem,
        verifiedAddress: verified.address === 'Yes' ? true : false,
        verifiedEmployer: verified.employer === 'Yes' ? true : false,
        verifiedContact: verified.contact === 'Yes' ? true : false,
        verifiedPhone: verified.phone === 'Yes' ? true : false,
      };
      dispatch(salesActions.setVerificationListItem(newVerificationListItem));
    } catch {
      toast.error('Unable to verify information');
    }
  };

  const updateLandlordVerification = async (data: typeof buyerLandlordDefaultValues) => {
    const verificationItems = buyerInformationVerificationItems.concat(
      employerInformationVerificationItems,
      personalContactVerificationItems
    );

    const verificationCtoi = verificationItems.map((item) => {
      return {
        recId: item.appVerctoiRecId,
        verifiedN: item.verifiedNo,
        verifiedY: item.verifiedYes,
        verifiedByOn: item.verifiedBy,
      } as UpdateAppVerificationCtois;
    });

    try {
      setUpdateVerificationLoading(true);

      await salesService.updateLandlord({
        appRecId: appRecId,
        buyerRecId: buyerRecId,
        isCobuyer: isCobuyer,
        landlordsName: data.landlordsName,
        rentOrOwn: data.rentOrOwn,
        livedAtMonths: data.livedAtMonths,
        livedAtYears: data.livedAtYears,
        typeOfHome: data.typeOfHome,
        rentAmount: data.rentAmount,
        mortgageBalance: data.mortgageBalance,
        currentOrPastDue: data.currentOrPastDue,
        landlordsPhoneNumber: data.landlordsPhoneNumber,
        spokeTo: data.spokeTo,
      });

      await salesService.updateVerification({
        updateAppVerificationContact: {
          isReference: false,
          appRecId: appRecId,
          recId: verificationListItem.recId,
          verifiedAddress: verifiedAddress,
          verifiedContact: verifiedContact,
          verifiedEmployer: verifiedEmployer,
          verifiedPhone: verifiedPhone,
          callResults: data.contactStatus,
          lastCall: new Date(data.lastContact),
          lastCallLong: data.lastContact
            ? dayjs(data.lastContact).format('ddd, MMM D YYYY, h:mm A')
            : '',
        },
        updateAppVerificationCtois: verificationCtoi,
      });

      // @todo use async/await
      await dispatch(getVerificationList(appRecId)).then((res) => {
        const newVerificationListItem = res?.find(
          (item) => item.recId === verificationListItem.recId
        );
        dispatch(salesActions.setVerificationListItem(newVerificationListItem!));
      });
      await dispatch(getSaleData(appRecId));
      await initData();
      dispatch(salesActions.setShowVerificationForm(true));

      toast.success('Landlord Verifiaction Updated');
    } catch {
      toast.error('Error updating landlord verification');
    } finally {
      setUpdateVerificationLoading(false);
    }
  };

  const updateReferenceVerification = async (data: typeof referenceDefaultValues) => {
    const callInFuture = personalContactVerificationItems.find(
      (item) => item.itemKey === `Call${contactRecId}`
    )?.verifiedNo;

    const verificationItems = buyerInformationVerificationItems.concat(
      employerInformationVerificationItems,
      personalContactVerificationItems
    );

    const verificationCtoi = verificationItems.map((item) => {
      return {
        recId: item.appVerctoiRecId,
        verifiedN: item.verifiedNo,
        verifiedY: item.verifiedYes,
        verifiedByOn: item.verifiedBy,
      } as UpdateAppVerificationCtois;
    });

    try {
      setUpdateVerificationLoading(true);

      await accountsService.updateReference({
        recId: referenceData?.recId,
        refFor: data.referenceFor,
        name: data.name,
        shortAddress: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        knownForMonths: data.monthsKnown,
        knownForYears: data.yearsKnown,
        relationship: data.relationship,
        homePhone: '',
        cellPhone: data.phoneNumber,
        email: data.email,
        noTexts: false,
        noCalls: !!callInFuture,
        noEmails: false,
        currentCustomer: data.currentCustomer,
        currentCustomerLot: data.dealership,
        comments: data.comments,
        spokeTo: data.spokeTo,
      });

      await salesService.updateVerification({
        updateAppVerificationContact: {
          isReference: true,
          appRecId: appRecId,
          recId: verificationListItem.recId,
          verifiedAddress: verifiedAddress,
          verifiedContact: verifiedContact,
          verifiedEmployer: verifiedEmployer,
          verifiedPhone: verifiedPhone,
          callResults: data.contactStatus,
          lastCall: new Date(data.lastContact),
          lastCallLong: data.lastContact
            ? dayjs(data.lastContact).format('ddd, MMM D YYYY, h:mm A')
            : '',
        },
        updateAppVerificationCtois: verificationCtoi,
      });

      toast.success('Reference Verifiaction Updated');

      // @todo use async/await
      await dispatch(getVerificationList(appRecId)).then((res) => {
        const newVerificationListItem = res?.find(
          (item) => item.recId === verificationListItem.recId
        );
        dispatch(salesActions.setVerificationListItem(newVerificationListItem!));
      });
      await dispatch(getSaleData(appRecId));
      await initData();
    } catch {
      toast.error('Reference verification unable to be updated');
    } finally {
      setUpdateVerificationLoading(false);
    }
  };

  return {
    personalContactColumn,
    addressColumn,
    employerColumn,
    referenceVerificationForm,
    employerVerificationForm,
    landlordVerificationForm,
    buyerInformationVerificationItems,
    employerInformationVerificationItems,
    personalContactVerificationItems,
    updateEmployerVerification,
    updateLandlordVerification,
    updateReferenceVerification,
    updateVerificationLoading,
    typeOfHomeData,
  };
};
