import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// icons
import { BsFiletypeCsv, BsFiletypePdf } from 'react-icons/bs';
import { SiMicrosoftexcel } from 'react-icons/si';
// state
import { useReportsCtx } from './defaultState';
import { useReportsViewCtx } from '../state/view';
// utils
import { reportsService } from '@/services/reportsService';
import { DateFormat, slugify } from '@/utils/helpers/general';
import { formatFormDate } from '../../../general/dates';
import { IReport } from '@/interfaces/Reports';
import { ExportFileType } from '../interfaces';
// style
import styles from '../Reports.module.scss';

const iconMap: { [key in ExportFileType]: React.ReactElement } = {
  xlsx: <SiMicrosoftexcel />,
  csv: <BsFiletypeCsv />,
  pdf: <BsFiletypePdf />,
};

/** ### File-specific button that handles request for file export
 * Handles conditional logic - these render on certain conditions defined by fields in the db entry
 * If button does not render, replace with empty div of same width.
 *
 * @note kendo button component does not support child elements/components (namely icon components from react-icon library)
 */
const ExportButton: FC<{
  report: IReport;
  fileType: ExportFileType;
  /** Check if button is rendered in modal (else in table cell) */
  isInModal?: boolean;
  isDisabled?: boolean;
}> = ({ report, fileType, isInModal = false, isDisabled = false }) => {
  const setSelectedReport = useReportsCtx((s) => s.setSelectedReport);
  const modalFilterParams = useReportsCtx((s) => s.modalFilterParams);
  const doesReportHaveFilters = useReportsCtx((s) => s.doesReportHaveFilters);

  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const [exportUrl, setExportUrl] = useState('');

  // Select icon component to render based on file-type
  const icon = iconMap[fileType];

  // Event handlers
  /** ### Handle click event when user clicks on a file-export button
   * Two main conditions:
   * - If the file has conditions for modifying the report:
   *    1. Update the `selectedReport` state;
   *       - Logic elsewhere will resolve the filter fields from `selectedReport`.
   *    1. Open the modal, which will also handle the fetch;
   * - Else: Fetch report from Azure Function `Report-export-generator` (use correct name) service;
   */
  const handleClickExportFile = async () => {
    // If filters exist: open modal.  Else: fetch report without filters
    if (doesReportHaveFilters(report) && isInModal === false) {
      setSelectedReport(report);
      setIsModalOpen(true);
    } else {
      try {
        toast.info('Requesting report');
        const resExportUrl = await reportsService.fetchExport(report, fileType, modalFilterParams);
        setExportUrl(resExportUrl);
      } catch (err) {
        toast.error(`Request for file failed: ${err}`);
      }
    }
  };
  /** Runs in useEffect after export-url is set. */
  const handleExportUrl = () => {
    if (exportUrl.includes('https://')) {
      if (fileType === 'pdf') {
        window.open(exportUrl, '_blank', 'noopener,noreferrer');
      } else {
        if (exportUrl.includes('error.pdf')) {
          // @todo (on backend - azure service) respond with text describing error - cannot parse pdf due to CORS
          toast.error(`Request for file failed (likely authorization error)`);
        } else {
          const link = document.createElement('a');
          const todayFmt = formatFormDate(new Date(), {
            pattern: DateFormat.YearMonthDayDash,
            utc: false,
          });
          link.download = `${slugify(report.reportNameOrg)}-${todayFmt}.${fileType}`;
          link.href = exportUrl;

          link.click();
        }
      }

      // Reset state
      setExportUrl('');
    }
  };

  let isButtonRendered = false;
  switch (fileType) {
    case 'xlsx':
      isButtonRendered = report.hasExcel;
      break;
    case 'csv':
      isButtonRendered = report.hasCsv;
      break;
    case 'pdf':
      isButtonRendered = report.hasPrint;
      break;
    default:
      // @todo properly handle filetype error
      throw new Error(`Invalid file type used for export: ${fileType}`);
  }

  // Effects
  useEffect(() => {
    handleExportUrl();
  }, [exportUrl]);

  return (
    <div
      className={`${styles.exportButton} ${isDisabled ? styles.exportButtonDisabled : ''}`}
      onClick={isDisabled ? undefined : handleClickExportFile}
    >
      {isButtonRendered ? icon : <svg />}
    </div>
  );
};

export default ExportButton;
