import { FC, RefObject, useEffect, useRef } from 'react';
import { Status } from '@googlemaps/react-wrapper';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
// state
import { useGpsCtx } from './GpsProvider';

const MapDisplay: FC<{ mapRef: RefObject<HTMLDivElement> }> = ({ mapRef }) => {
  const gpsLastLocation = useGpsCtx((s) => s.gpsLastLocation);

  useEffect(() => {
    if (mapRef.current && gpsLastLocation) {
      const location: google.maps.LatLngLiteral = {
        lat: gpsLastLocation.lat,
        lng: gpsLastLocation.long,
      };
      const map = new window.google.maps.Map(mapRef.current, { center: location, zoom: 8 });

      /**
       * main.js:180 As of February 21st, 2024, google.maps.Marker is deprecated.
       * Please use google.maps.marker.AdvancedMarkerElement instead.
       * At this time, google.maps.Marker is not scheduled to be discontinued,
       *  but google.maps.marker.AdvancedMarkerElement is recommended over google.maps.Marker.
       * While google.maps.Marker will continue to receive bug fixes for any major regressions,
       *  existing bugs in google.maps.Marker will not be addressed.
       * At least 12 months notice will be given before support is discontinued.
       * Please see https://developers.google.com/maps/deprecations for additional details
       *  and https://developers.google.com/maps/documentation/javascript/advanced-markers/migration
       *  for the migration guide.
       */
      new google.maps.Marker({ map, position: location });

      // new google.maps.marker.AdvancedMarkerElement({
      //   map,
      //   position: location,
      //   content: mapRef.current,
      //   // content: markerEl,
      // });
    }
  }, [mapRef?.current, gpsLastLocation]);
  return null;
};
/** ###  */
const GpsMap: FC<{ status: Status }> = ({ status }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const gpsLastLocation = useGpsCtx((s) => s.gpsLastLocation);
  const gpsLastLocateLoading = useGpsCtx((s) => s.gpsLastLocateLoading);

  if (status === Status.LOADING || (gpsLastLocateLoading && gpsLastLocation === null)) {
    return <Loader />;
  }
  if (status === Status.FAILURE) {
    return <div>Unable to load map</div>;
  }
  if (gpsLastLocation === null) {
    return <div>Unable to load user's last location</div>;
  }

  return (
    <Grid flex={1} minHeight={300} maxWidth={800} ref={mapRef}>
      <MapDisplay mapRef={mapRef} />
    </Grid>
  );
};

export default GpsMap;
