import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { AccountDb } from '../interfaces';

// @todo constrain types
const PrinBalCell: FC<GridCellProps> = ({ dataItem }) => {
  const { prinBal } = dataItem as AccountDb;
  const isNull = prinBal === null;

  const amtDueFmt = isNull ? '-' : formatCurrency(prinBal);
  return (
    <td className="k-table-td" style={{ textAlign: 'end' }}>
      <span>{amtDueFmt}</span>
    </td>
  );
};

export default PrinBalCell;
