import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// components
import DashboardFilters from './viewFilters/DashboardFilters';
// cards
import PaymentsCard from './dataCards/PaymentsCard';
import CollectionsRatioCard from './dataCards/CollectionsRatioCard';
import DelinquencyRatioCard from './dataCards/DelinquencyRatioCard';
import AccountsReceivableCard from './dataCards/AccountsReceivableCard';
import SalesCard from './dataCards/SalesCard';
import UnpaidSalesCard from './dataCards/UnpaidSalesCard';
// displays
import AgingReportChart from './dataDisplays/AgingReportChart';
import TerminationsTable from './dataDisplays/TerminationsTable.tsx';
import OutForRepoTable from './dataDisplays/OutForRepoTable';
import CollectionActivityTable from './dataDisplays/CollectionActivityTable';
// state
import DashboardViewProvider from './DashboardViewProvider';

const CardListLayout: FC = () => (
  <Grid container direction="row" flex={1} gap={1}>
    <PaymentsCard />
    <CollectionsRatioCard />
    <DelinquencyRatioCard />
    <AccountsReceivableCard />
    <SalesCard />
    <UnpaidSalesCard />
  </Grid>
);

const DataTableGroupLayout: FC = () => (
  <Grid container direction="row" justifyContent="space-between" gap={2}>
    <Grid container direction="column" flex={2} gap={2}>
      <TerminationsTable />
      <OutForRepoTable />
    </Grid>

    <AgingReportChart />
  </Grid>
);

/** ###  */
const Dashboard: FC = () => (
  <DashboardViewProvider>
    <Grid
      container
      direction="column"
      flex={1}
      gap={2}
      p={2}
      m={2}
      borderRadius={1.5}
      sx={{ backgroundColor: '#EEEEEE' }}
    >
      <DashboardFilters />

      <CardListLayout />

      <DataTableGroupLayout />

      <CollectionActivityTable />
    </Grid>
  </DashboardViewProvider>
);

export default Dashboard;
