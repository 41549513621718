import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash-es';
// state
import { useAccountsViewCtx } from '../accountsViewState';
import { useTableCtx, ITableCtx } from '../tableState';
import { usePmtSubviewCtx } from './pmtSubviewState';
// utils
import { fetchPmtSubviewList } from './fetchPmtSubviewList';

export const usePmtSubviewData = () => {
  const viewState = useAccountsViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const pmtDataState = usePmtSubviewCtx((s) => s);

  // Effects
  useEffect(() => {
    // @todo remove sort conditional - handled on backend
    if (tableState.sort.length > 0) {
      fetchPmtSubviewList(viewState, tableState, pmtDataState);
    }
  }, [
    // @note this prevents unnecessary calls, as objects/arrays (i.e. tableState.filter and tableState.sort) change on each render
    tableState.filter.map((f) => `${f.field}-${f.operator}-${f.value}`).join(''),
    tableState.sort.map((s) => `${s.field}-${s.dir}`).join(''),
    tableState.page.skip,
    tableState.page.take,
    tableState.pageSizeValue,
    viewState.subview,
  ]);

  // Debounce the request filtered by 'text search filter'
  const debouncedFetch = useCallback(
    debounce(
      (tableState: ITableCtx) => fetchPmtSubviewList(viewState, tableState, pmtDataState),
      400
    ),
    []
  );
  useEffect(() => {
    !viewState.isLoading && tableState.sort.length > 0 && debouncedFetch(tableState);
  }, [tableState.textSearchFilter]);

  return pmtDataState.pmtSubviewList;
};
