import { FC, SyntheticEvent } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DCS_BLUE } from "@/mui/theme/colors";
// state
import { useAiUnderwritingCtx } from "../../AiUnderwritingProvider";
// utils
import { getLocalDateFormat } from "@/general/dates";

const { dateDisplayFormatStr } = getLocalDateFormat();

/** ###  */
const HistorySidebar: FC = () => {
  const tabItems = useAiUnderwritingCtx((s) => s.reportsHistory.value);
  const hasMultipleTabs = tabItems && tabItems.length > 1;
  const selectedAppEvalIdx = useAiUnderwritingCtx((s) => s.selectedAppEvalIdx);
  const setSelectedAppEvalIdx = useAiUnderwritingCtx((s) => s.setSelectedAppEvalIdx);

  // Event handlers
  const handleChangeTab = (_: SyntheticEvent, newTabValue: number) =>
    setSelectedAppEvalIdx(newTabValue);

  // Build tabs
  const tabElems = tabItems?.map((t, appEvalIdx) => {
    const tabLabel = t.dateCreated.format(dateDisplayFormatStr);

    return (
      <Tab
        label={tabLabel}
        value={appEvalIdx}
        sx={{
          // Container styles
          alignItems: "start",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          padding: "20px 30px",
          // Text styles
          textTransform: "none",
          fontSize: "12px",
          fontWeight: 700,
          // Selected tab styles
          "&.MuiTab-root.Mui-selected": {
            color: DCS_BLUE,
            backgroundColor: "rgba(41, 70, 149, 0.2)",
          },
        }}
        key={`${t.applicationId}-${t.dateCreated}`}
      />
    );
  });

  if (!hasMultipleTabs) return <></>;

  return (
    <Grid container direction="column" component={Paper}>
      <Tabs
        value={selectedAppEvalIdx}
        orientation="vertical"
        variant="scrollable"
        aria-label="sidebar-nav-tabs"
        onChange={handleChangeTab}
      >
        {tabElems}
      </Tabs>
    </Grid>
  );
};

export default HistorySidebar;
