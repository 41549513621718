import { Vehicle } from '@/interfaces';

export const defaultFormValues: Partial<Omit<Vehicle, 'milesOut'>> & {
  milesOut?: number;
  // Checkboxes
  notActual?: boolean;
  exceedsLim?: boolean; // @todo need to validate which is the correct request field
  exceedsLimits?: boolean; // @todo need to validate which is the correct request field
  exempt?: boolean;
  tmu?: boolean;
  // Additional fields
  bodyType?: string;
  vehicleType?: string;
  drivetrain?: string;
  transmission?: string;
  printExempt?: boolean;
  salvageTitle?: boolean;
  frameDamage?: boolean;
  unibody?: boolean;
  airbag?: boolean;
  other?: boolean;
  otherAlert?: string;
  plateNumber?: string;
  dateIssued?: string;
  dateExpires?: string;
  price?: number;
  downPayment?: number;
  downPaymentPercentage?: number;
  schedule?: string;
  payment?: number;
  months?: number;
} = {
  stockNum: '',
  vin: '',
  year: '',
  make: '',
  model: '',
  color: '',
  bodyType: '',
  vehicleType: '',
  drivetrain: '',
  transmission: 'Automatic',
  milesOut: 0,
  printExempt: false,
  tmu: false,
  salvageTitle: false,
  frameDamage: false,
  unibody: false,
  airbag: false,
  other: false,
  otherAlert: '',
  plateNumber: '',
  dateIssued: '',
  dateExpires: '',
  price: 0,
  downPayment: 0,
  downPaymentPercentage: 0,
  schedule: 'Weekly',
  payment: 0,
  months: 0,
  // Checkboxes
  notActual: false,
  exceedsLimits: false, // @todo need to validate which is the correct request field
  exceedsLim: false, // @todo need to validate which is the correct request field
};

export type IDefaultFormValues = typeof defaultFormValues;
// Alias
export type ISaleVehicle = typeof defaultFormValues;
