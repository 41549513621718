import { AppConfig } from './env/interfaces';

export const qaConfig: AppConfig = {
  localUrl: import.meta.env.MODE.includes('standalone')
    ? 'https://qa.dcstest1.com'
    : 'https://qa.afstest2.com',
  apiUrl: 'https://api.qa.dcstest1.com',
  openEdgeEnvironment: 'test', // nocommit
  signalrUrl: 'https://dcl-signalrhub-qa.azurewebsites.net/sms', // nocommit
  // @todo move to secrets-manager
  googleMapsApiKey: 'AIzaSyDr-UamFrwCy_ROiuvfSfj8pIO8MvlWKc4', // nocommit
  idsUrl: 'https://auth.qa.dcstest1.com',
};
