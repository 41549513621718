import { FC } from "react";
import { StyleSheet, ViewProps } from "@react-pdf/renderer";
// components
import PdfTableCell from "./PdfTableCell";
import PdfTableRow from "./PdfTableRow";
// interfaces
import { PdfColumnMap } from "./interfaces";

const styles = StyleSheet.create({
  row: { borderTop: undefined },
  cellContainer: { borderTop: undefined },
  cellText: {
    fontWeight: "bold",
    color: "#00000090",
    padding: "4px",
    fontSize: "11px",
  },
});

/** ### Displays the row of text cells representing the headers of each column */
const PdfTableColumnHeaderRow: FC<
  ViewProps & {
    columnLabelMap: PdfColumnMap;
    cellProps?: Omit<Parameters<typeof PdfTableCell>[0], "columnCt" | "value">;
  }
> = ({ columnLabelMap, cellProps, style, ...rowProps }) => {
  const rowStyle = { ...styles.row, ...style };
  const cellContainerStyle = { ...styles.cellContainer, ...cellProps?.style };
  const cellTextProps = {
    ...cellProps?.textProps,
    style: { ...styles.cellText, ...cellProps?.textProps?.style },
  };

  // Add column elements to table
  const columnCt = columnLabelMap.size;
  const columnHeaderElems: JSX.Element[] = [];
  columnLabelMap.forEach((columnInfo, _) =>
    // @note Apply width style last to keep consistent table layout
    columnHeaderElems.push(
      <PdfTableCell
        {...cellProps}
        value={columnInfo.label}
        columnInfo={{ ...columnInfo, columnType: "text" }}
        columnCt={columnCt}
        style={cellContainerStyle}
        textProps={cellTextProps}
        key={columnInfo.label}
      />
    )
  );

  return (
    <PdfTableRow {...rowProps} style={rowStyle}>
      {columnHeaderElems}
    </PdfTableRow>
  );
};

export default PdfTableColumnHeaderRow;
