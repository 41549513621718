import { ChangeEvent, FC, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
// components
import { ComboBoxChangeEvent } from "@progress/kendo-react-all";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import {} from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
// state
import { useInventoryViewCtx } from "../state";
// utils
import {
  DateRangeOptionKey,
  dateRangeOptionLabels,
  dateRangeOptions,
  formatFormDate,
} from "@/general/dates";
import { newModalFilterParams } from "@/features/inventory/Tables/interfaces";
// styles
import listStyles from "../ListStyles.module.scss";

/** ### Dropdown selector component for inventory sold dates
 *
 * - Renders in the table header.
 * - Begin date and end date selector with a timeframe selector that sets these values as well
 * - State managed
 */
const DateFilter: FC = () => {
  const modalFilterParams = useInventoryViewCtx((s) => s.modalFilterParams);
  const setModalFilterParams = useInventoryViewCtx((s) => s.setModalFilterParams);
  const setFormErrors = useInventoryViewCtx((s) => s.setFormErrors);

  const [errorMsgs, setErrorMsgs] = useState({ beginDate: "", endDate: "" });
  const [selectedDateRangeInput, setSelectedDateRangeInput] =
    useState<DateRangeOptionKey>("This Month-to-date");

  // Event handlers
  /** ### Handle changes to the "beginDate" and "endDate" fields by updating state with their input.
   *
   * Since component only renders if:
   * - Selected range is set; AND
   * - Modal filter params is set; AND
   * - The filter field is set;
   * Form is always controlled and no placeholder or validation is needed.
   *
   * When the date fields are used, set the "Date range" to "Custom".
   */
  const handleChangeDateRange = (inputType: "beginDate" | "endDate") => (e: Dayjs | null) => {
    const updatedModalFilterParams = {
      ...newModalFilterParams(modalFilterParams),
      [inputType]: e?.format("YYYY-MM-DD"),
    };
    setModalFilterParams(updatedModalFilterParams);
    setSelectedDateRangeInput("Custom");
  };
  const handleChangeDateRangeOption = (e: ComboBoxChangeEvent) => {
    // Validate
    const dateRangeLabel = e.target.value as DateRangeOptionKey;
    // Validate input while ignoring the "Custom" field (which is mapped to `null`)
    if (!dateRangeOptions[dateRangeLabel] && dateRangeOptions[dateRangeLabel] !== null) {
      throw new Error(`Invalid Date Range Option: ${e.target.value}`);
    }
    setSelectedDateRangeInput(dateRangeLabel);

    // If date range label === "Custom" (i.e. user manually picked the option), don't change the state
    if (dateRangeOptions[dateRangeLabel] === null) return;

    // Update calendar dates in state
    //   - Format dates to string format compatible with backend
    const dateRange = dateRangeOptions[dateRangeLabel]!;
    const dateRangeFmt = {
      beginDate: formatFormDate(dateRange.beginDate),
      endDate: formatFormDate(dateRange.endDate),
    };
    const updatedModalFilterParams = {
      ...newModalFilterParams(modalFilterParams),
      ...dateRangeFmt,
    };
    setModalFilterParams(updatedModalFilterParams);
    setErrorMsgs({ beginDate: "", endDate: "" });
  };

  // Effects
  // Validate dates in state
  useEffect(() => {
    if (modalFilterParams && modalFilterParams.beginDate && modalFilterParams.endDate) {
      const { beginDate: beginDateStr, endDate: endDateStr } = modalFilterParams;
      // Validation
      const beginDt = new Date(beginDateStr);
      const endDt = new Date(endDateStr);

      // Validate datetime input
      if (isNaN(beginDt.getMilliseconds())) {
        setErrorMsgs({ ...errorMsgs, beginDate: "Invalid date input" });
      } else if (isNaN(endDt.getMilliseconds())) {
        setErrorMsgs({ ...errorMsgs, endDate: "Invalid date input" });
      }
      // Validate date range
      else if (beginDt > endDt) {
        setErrorMsgs({ beginDate: "", endDate: '"Date From" is after "Date To"' });
      } else {
        setErrorMsgs({ beginDate: "", endDate: "" });
      }
    }
  }, [modalFilterParams]);
  // Update error-tracker in state
  useEffect(() => {
    const doesDateFormhaveErrors = errorMsgs.beginDate !== "" || errorMsgs.endDate !== "";
    setFormErrors("date", doesDateFormhaveErrors);
  }, [errorMsgs]);

  const dateInputWidth = "125px";

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ marginRight: "10px" }}>
        <DateInput
          label="Date From"
          maxDate={dayjs()}
          onChange={handleChangeDateRange("beginDate")}
          errors={errorMsgs.beginDate}
          sx={{ minWidth: dateInputWidth }}
          value={
            !modalFilterParams
              ? formatFormDate(dateRangeOptions["This Month-to-date"].beginDate)
              : modalFilterParams.beginDate
          }
          className={listStyles.customDateInput}
        />
      </div>
      <div style={{ marginRight: "10px" }}>
        <DateInput
          label="Date To"
          maxDate={dayjs()}
          onChange={handleChangeDateRange("endDate")}
          errors={errorMsgs.endDate}
          sx={{ minWidth: dateInputWidth }}
          value={
            !modalFilterParams
              ? formatFormDate(dateRangeOptions["This Month-to-date"].endDate)
              : modalFilterParams.endDate
          }
        />
      </div>
      <DropdownInput
        value={selectedDateRangeInput}
        data={dateRangeOptionLabels}
        onChange={handleChangeDateRangeOption}
        clearButton={false}
      />
    </div>
  );
};

export default DateFilter;
