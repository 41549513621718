import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaPen } from 'react-icons/fa';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { Icons } from '@/components/icons';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { Spacer } from '@/components/spacer/Spacer';
// components
// state
import { useAppDispatch } from '@/store/store';
import { useNavigationConfirm } from '@/hooks';
import { getQuoteData } from '@/features/Sales/salesActionCreator';
import { useAuthSelector } from '@/features/auth/authSlice';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { systemService } from '@/services/systemService';
import { salesService } from '@/services/salesService';
import {
  checkIfNullOrUndefined,
  formatCurrency,
  roundToTwoDecimals,
  scheduleData,
} from '@/utils/helpers/general';
import { getsalestax, reCalcFinanceTerms } from '@/utils/helpers/financeTerms';
// interfaces
import { QuoteData } from '@/interfaces';
import { SpecialPaymentPlan, TermsLockType, financeTermFormDefaults } from './interfaces';
// style
import styles from '../../../desking/deskingTabs/quickQuote/financeTab/FinanceTab.module.scss';
import tabStyles from '../../SalesTab.module.scss';
import { DateString } from '@/utils/types';

/** @deprecated needs refactor */
const FinanceTerm: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const appRecId = Number(params.id);
  const dispatch = useAppDispatch();

  const compId = useAuthSelector((s) => s.compId);
  const orgId = useAuthSelector((s) => s.orgId);
  const quoteData = useSalesSelector((s) => s.quoteData);
  const saleData = useSalesSelector((s) => s.saleData);
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);
  const quoteDataLoading = useSalesSelector((s) => s.quoteDataLoading);
  const isFinance = saleData.sale?.saletype == "Finance";
  const disableDate = orgId !== 2 || saleData.sale?.salestatus == 'Posted';

  const [specialPaymentPlanList, setSpecialPaymentPlanList] = useState<SpecialPaymentPlan[]>([]);
  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [customField6Name, setCustomField6Name] = useState('');
  const [updateFinanceLoading, setUpdateFinanceLoading] = useState(false);
  const [updateFinanceSuccessful, setUpdateFinanceSuccessful] = useState(false);
  const [previousTerm, setPreviousTerm] = useState(0);
  const [previousAmount, setPreviousAmount] = useState(0);
  const [previousNumPmts, setPreviousNumPmts] = useState(0);
  const [currentLock, setCurrentLock] = useState<TermsLockType>(TermsLockType.Amt);
  const [paymentSchedule, setPaymentSchedule] = useState('');
  const [quotePrinting, setQuotePrinting] = useState(false);
  const [financeQuoteData, setFinanceQuoteData] = useState<QuoteData | undefined>(undefined);
  const [pageError, setPageError] = useState('');
  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({ 
    defaultValues: financeTermFormDefaults,
    mode: 'onChange',
  });

  const financeSaleType = 'Retail Sale';
  const tradeAllowance = watch('tradeAllowance');
  const tradePayoff = watch('tradePayoff');
  const depositDown = watch('depositDown');
  const cashOnDelivery = watch('cashOnDelivery');
  const schedule = watch('schedule');
  const dateStarted = watch('dateStarted');
  const amount = watch('amount');

  const resetForm = () => {
    reset(
      {
        dateOfSale: (!disableDate || saleData.sale?.salestatus == 'Posted')
          && isFinance && saleData.sale?.quoteDate 
          && saleData.sale?.quoteDate !== 'Invalid Date' 
          ? dayjs(saleData.sale?.quoteDate).utc().format("MM/DD/YYYY")
          : dayjs().format('YYYY-MM-DD'),
        salesPrice: !checkIfNullOrUndefined(saleData.sale?.salesPrice)
          ? saleData.sale?.salesPrice
          : financeQuoteData?.deF_SP,
        salesTax: saleData.sale?.salesTax || 0,
        license: !checkIfNullOrUndefined(saleData.sale?.license)
          ? saleData.sale?.license
          : financeQuoteData?.pricE1,
        title: !checkIfNullOrUndefined(saleData.sale?.title)
          ? saleData.sale?.title
          : financeQuoteData?.pricE2,
        registration: !checkIfNullOrUndefined(saleData.sale?.registration)
          ? saleData.sale?.registration
          : financeQuoteData?.pricE3,
        customField1: !checkIfNullOrUndefined(saleData.sale?.addon4)
          ? saleData.sale?.addon4
          : financeQuoteData?.pricE4,
        customField2: !checkIfNullOrUndefined(saleData.sale?.addon5)
          ? saleData.sale?.addon5
          : financeQuoteData?.pricE5,
        customField3: !checkIfNullOrUndefined(saleData.sale?.addon6)
          ? saleData.sale?.addon6
          : financeQuoteData?.pricE6,
        customField4: !checkIfNullOrUndefined(saleData.sale?.addon7)
          ? saleData.sale?.addon7
          : financeQuoteData?.pricE7,
        customField5: !checkIfNullOrUndefined(saleData.sale?.addon8)
          ? saleData.sale?.addon8
          : financeQuoteData?.pricE8,
        customField6: !checkIfNullOrUndefined(saleData.sale?.addon9)
          ? saleData.sale?.addon9
          : financeQuoteData?.pricE9,
        serviceContract: !checkIfNullOrUndefined(saleData.sale?.servCont)
          ? saleData.sale?.servCont
          : financeQuoteData?.pricE10,
        creditInsurance: saleData.sale?.cRlife || 0,
        creditDisability: saleData.sale?.crDisb || 0,
        totalSalePrice: saleData.sale?.totalSP || 0,
        tradeAllowance: saleData.sale?.tradeAllow || 0,
        tradePayoff: saleData.sale?.tradePayoff || 0,
        cashOnDelivery: !checkIfNullOrUndefined(saleData.sale?.cod)
          ? saleData.sale?.cod
          : financeQuoteData?.def_Down,
        depositDown: !checkIfNullOrUndefined(saleData.sale?.depositDown)
          ? saleData.sale?.depositDown
          : 0,
        totalDown: !checkIfNullOrUndefined(saleData.sale?.totalDown) ? saleData.sale?.totalDown : 0,
        amountFinanced: saleData.sale?.amtFin || 0,
        interestRate: !checkIfNullOrUndefined(saleData.sale?.intRate)
          ? (saleData.sale?.intRate || 0) / 100
          : financeQuoteData?.def_APR && financeQuoteData.def_APR / 100,
        dateStarted: saleData.sale?.firstpmtdue
          ? dayjs(saleData.sale.firstpmtdue).utc().format('YYYY-MM-DD')
          : dayjs().add(7, 'day').format('YYYY-MM-DD'),
        schedule: !checkIfNullOrUndefined(saleData.sale?.sched)
          ? saleData.sale?.sched
          : financeQuoteData?.def_Schedule,
        term: !checkIfNullOrUndefined(saleData.sale?.term) ? saleData.sale?.term : 59.26,
        amount: !checkIfNullOrUndefined(saleData.sale?.pmtAmt)
          ? saleData.sale?.pmtAmt
          : financeQuoteData?.def_PM,
        numberOfPayments: !checkIfNullOrUndefined(saleData.sale?.numPmts)
          ? saleData.sale?.numPmts
          : 258,
        finalDueOn: dayjs(saleData.sale?.finalPmtDue).format('YYYY-MM-DD'),
        final: !checkIfNullOrUndefined(saleData.sale?.finalPmt) ? saleData.sale?.finalPmt : 0,
        financeCharge: saleData.sale?.finChg || 0,
        totalPayments: saleData.sale?.totOfPmts || 0,
      },
      { keepDirty: false }
    );
  };

  const initData = () => {
    try {
      setCustomField1Name(financeQuoteData?.adD4 || '');
      setCustomField2Name(financeQuoteData?.adD5 || '');
      setCustomField3Name(financeQuoteData?.adD6 || '');
      setCustomField4Name(financeQuoteData?.adD7 || '');
      setCustomField5Name(financeQuoteData?.adD8 || '');
      setCustomField6Name(financeQuoteData?.adD9 || '');
      //this is to prevent duplication of items in the list on re-rendering
      setSpecialPaymentPlanList([]);
      if (saleData.sale?.sPamt2) {
        setSpecialPaymentPlanList((prev) => [
          ...prev,
          {
            amount: saleData.sale?.sPamt2,
            numberOfPayments: saleData.sale?.sPnum2,
            dateStarted: dayjs(saleData.sale?.sPdd2).format('YYYY-MM-DD'),
            schedule: saleData.sale?.spSch2,
          } as (typeof specialPaymentPlanList)[0],
        ]);
      }
      if (saleData.sale?.sPamt3) {
        setSpecialPaymentPlanList((prev) => [
          ...prev,
          {
            amount: saleData.sale?.sPamt3,
            numberOfPayments: saleData.sale?.sPnum3,
            dateStarted: dayjs(saleData.sale?.sPdd3).format('YYYY-MM-DD'),
            schedule: saleData.sale?.spSch3,
          } as (typeof specialPaymentPlanList)[0],
        ]);
      }
      if (saleData.sale?.sPamt4) {
        setSpecialPaymentPlanList((prev) => [
          ...prev,
          {
            amount: saleData.sale?.sPamt4,
            numberOfPayments: saleData.sale?.sPnum4,
            dateStarted: dayjs(saleData.sale?.sPdd4).format('YYYY-MM-DD'),
            schedule: saleData.sale?.spSch4,
          } as (typeof specialPaymentPlanList)[0],
        ]);
      }
      if (saleData.sale?.sPamt5) {
        setSpecialPaymentPlanList((prev) => [
          ...prev,
          {
            amount: saleData.sale?.sPamt5,
            numberOfPayments: saleData.sale?.sPnum5,
            dateStarted: dayjs(saleData.sale?.sPdd5).format('YYYY-MM-DD'),
            schedule: saleData.sale?.spSch5,
          } as (typeof specialPaymentPlanList)[0],
        ]);
      }
      setPreviousTerm(saleData.sale?.term ?? 0);
      setPreviousAmount(saleData.sale?.pmtAmt ?? 0);
      setPreviousNumPmts(saleData.sale?.numPmts ?? 0);
    } catch (err) {
      setPageError('Unable to load quote data');
    }
  };

  const addSpecialPaymentPlan = useForm({
    defaultValues: {
      numberOfPayments: 0,
      paymentAmount: 0,
      beginningDate: '',
      schedule: 'Weekly',
      selectedIndex: undefined as unknown as number,
    },
  });

  const { NavigationConfirm } = useNavigationConfirm(
    isDirty //TODO: also check addSpecialPaymentPlan.formState.isDirty
  );

  const formatSpecialPaymentPlan = (specialPaymentPlan: SpecialPaymentPlan) => {
    return `${specialPaymentPlan.numberOfPayments} Payments of ${formatCurrency(
      specialPaymentPlan.amount
    )} ${specialPaymentPlan.schedule} Beginning ${dayjs(specialPaymentPlan.dateStarted).format(
      'MMMM D, YYYY'
    )}`;
  };

  const resetSpecialPaymentPlanForm = () => {
    addSpecialPaymentPlan.setValue('selectedIndex', -1);
    addSpecialPaymentPlan.setValue('numberOfPayments', 0);
    addSpecialPaymentPlan.setValue('paymentAmount', 0);
    addSpecialPaymentPlan.setValue('beginningDate', '');
    addSpecialPaymentPlan.setValue('schedule', 'Weekly');
  };

  const submitSpecialPaymentPlan = (formData: {
    numberOfPayments: number;
    paymentAmount: number;
    beginningDate: string;
    schedule: string;
    selectedIndex: number;
  }) => {
    const { numberOfPayments, paymentAmount, beginningDate, schedule, selectedIndex } = formData;
    if (specialPaymentPlanList.length >= 4) {
      toast.error('Cannot have more than 4 special payment plans');
    } else if (
      specialPaymentPlanList.reduce((acc, curr) => acc + curr.amount, 0) +
        paymentAmount * numberOfPayments >
      watch('amountFinanced')
    ) {
      toast.error('Total of special payments cannot exceed the amount financed');
    } else if (new Date(watch('dateOfSale')) >= new Date(beginningDate)) {
      toast.error('Special payment beginning date must be after the date of sale');
    } else {
      let newSpecialPaymentPlanList = specialPaymentPlanList;
      setSpecialPaymentPlanList((prevList) => {
        if (selectedIndex >= 0) {
          newSpecialPaymentPlanList = [...prevList];
          newSpecialPaymentPlanList[selectedIndex]!.numberOfPayments = numberOfPayments;
          newSpecialPaymentPlanList[selectedIndex]!.amount = paymentAmount;
          newSpecialPaymentPlanList[selectedIndex]!.dateStarted = beginningDate;
          newSpecialPaymentPlanList[selectedIndex]!.schedule = schedule;
        } else {
          newSpecialPaymentPlanList = [
            ...prevList,
            {
              numberOfPayments: numberOfPayments,
              amount: paymentAmount,
              dateStarted: dayjs(beginningDate).format('YYYY-MM-DD'),
              schedule: schedule,
              selectedIndex: -1,
            },
          ];
        }
        onFieldBlur(newSpecialPaymentPlanList);
        resetSpecialPaymentPlanForm();
        return newSpecialPaymentPlanList;
      });
    }
  };

  const onFieldBlur = (
    specialPaymentPlanListValue?: SpecialPaymentPlan[],
    newLock?: TermsLockType,
    changeTerm?: boolean,
    changeAmount?: boolean,
    changeNumPmts?: boolean
  ) => {
    const formValues = getValues();

    //don't recalculate OR setCurrentLock if these fields weren't actually changed
    //this is done to mimic the behavior in alpha's onChange vs react's onBlur
    if (changeTerm && formValues.term === previousTerm) return;
    if (changeAmount && formValues.amount === previousAmount) return;
    if (changeNumPmts && formValues.numberOfPayments === previousNumPmts) return;

    const lock = newLock ? newLock : currentLock;
    if (newLock) setCurrentLock(newLock);

    const addon1 = Math.max(0, formValues.license);
    const addon2 = Math.max(0, formValues.title);
    const addon3 = Math.max(0, formValues.registration);
    const addon4 = Math.max(0, formValues.customField1);
    const addon5 = Math.max(0, formValues.customField2);
    const addon6 = Math.max(0, formValues.customField3);
    const addon7 = Math.max(0, formValues.customField4);
    const addon8 = Math.max(0, formValues.customField5);
    const addon9 = Math.max(0, formValues.customField6);
    const addon10 = Math.max(0, formValues.serviceContract);
    const stax1 = saleData.sale!.staX1!;
    const stax2 = saleData.sale!.staX2!;
    const stax3 = saleData.sale!.staX3!;
    const stax4 = saleData.sale!.staX4!;
    const stax5 = saleData.sale!.staX5!;
    const stax6 = saleData.sale!.staX6!;
    const stax7 = saleData.sale!.staX7!;
    const stax8 = saleData.sale!.staX8!;
    const stax9 = saleData.sale!.staX9!;
    const stax10 = saleData.sale!.staX10!;
    const ctax1 = saleData.sale!.citytaX1!;
    const ctax2 = saleData.sale!.citytaX2!;
    const ctax3 = saleData.sale!.citytaX3!;
    const ctax4 = saleData.sale!.citytaX4!;
    const ctax5 = saleData.sale!.citytaX5!;
    const ctax6 = saleData.sale!.citytaX6!;
    const ctax7 = saleData.sale!.citytaX7!;
    const ctax8 = saleData.sale!.citytaX8!;
    const ctax9 = saleData.sale!.citytaX9!;
    const ctax10 = saleData.sale!.citytaX10!;
    const ltax1 = saleData.sale!.loctaX1!;
    const ltax2 = saleData.sale!.loctaX2!;
    const ltax3 = saleData.sale!.loctaX3!;
    const ltax4 = saleData.sale!.loctaX4!;
    const ltax5 = saleData.sale!.loctaX5!;
    const ltax6 = saleData.sale!.loctaX6!;
    const ltax7 = saleData.sale!.loctaX7!;
    const ltax8 = saleData.sale!.loctaX8!;
    const ltax9 = saleData.sale!.loctaX9!;
    const ltax10 = saleData.sale!.loctaX10!;
    const otax1 = saleData.sale!.countytaX1!;
    const otax2 = saleData.sale!.countytaX2!;
    const otax3 = saleData.sale!.countytaX3!;
    const otax4 = saleData.sale!.countytaX4!;
    const otax5 = saleData.sale!.countytaX5!;
    const otax6 = saleData.sale!.countytaX6!;
    const otax7 = saleData.sale!.countytaX7!;
    const otax8 = saleData.sale!.countytaX8!;
    const otax9 = saleData.sale!.countytaX9!;
    const otax10 = saleData.sale!.countytaX10!;

    const statesalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.staterate!,
      saleData.sale!.statemax!,
      saleData.sale!.statemin!,
      saleData.sale!.stateon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      stax1,
      stax2,
      stax3,
      stax4,
      stax5,
      stax6,
      stax7,
      stax8,
      stax9,
      stax10
    );
    const countysalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.countyrate!,
      saleData.sale!.countymax!,
      saleData.sale!.countymin!,
      saleData.sale!.countyon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      otax1,
      otax2,
      otax3,
      otax4,
      otax5,
      otax6,
      otax7,
      otax8,
      otax9,
      otax10
    );
    const citysalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.cityrate!,
      saleData.sale!.citymax!,
      saleData.sale!.citymin!,
      saleData.sale!.cityon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ctax1,
      ctax2,
      ctax3,
      ctax4,
      ctax5,
      ctax6,
      ctax7,
      ctax8,
      ctax9,
      ctax10
    );
    const locsalestax = getsalestax(
      formValues.salesPrice,
      formValues.tradeAllowance,
      saleData.sale!.locrate!,
      saleData.sale!.locmax!,
      saleData.sale!.locmin!,
      saleData.sale!.locon!,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      ltax1,
      ltax2,
      ltax3,
      ltax4,
      ltax5,
      ltax6,
      ltax7,
      ltax8,
      ltax9,
      ltax10
    );
    const newSalesTax = statesalestax + countysalestax + citysalestax + locsalestax;
    // const salesTax = 0;
    const newTotalSalePrice =
      formValues.salesPrice +
      formValues.license +
      formValues.title +
      formValues.registration +
      formValues.customField1 +
      formValues.customField2 +
      formValues.customField3 +
      formValues.customField4 +
      formValues.customField5 +
      formValues.customField6 +
      formValues.serviceContract +
      newSalesTax;
    const newAmountFinanced = newTotalSalePrice - (formValues.totalDown || 0);
    setValue('amountFinanced', newTotalSalePrice - (formValues.totalDown || 0));

    let numPayments = formValues.numberOfPayments;
    let paymentAmount = formValues.amount;
    if (changeTerm) {
      switch (schedule) {
        case 'Weekly':
          numPayments = Math.round(formValues.term * 4.3);
          break;
        case 'Bi-Weekly':
          numPayments = Math.round(formValues.term * 2.15);
          break;
        case 'Semi-Monthly':
          numPayments = Math.round(formValues.term * 2);
          break;
        default:
          numPayments = Math.round(formValues.term);
          break;
      }
      paymentAmount = 0;
    }

    const newSpecialPaymentPlanList = specialPaymentPlanListValue
      ? specialPaymentPlanListValue
      : specialPaymentPlanList;

    const newData = reCalcFinanceTerms(
      {
        dateOfSale: formValues.dateOfSale,
        salesPrice: formValues.salesPrice,
        salesTax: newSalesTax,
        license: formValues.license,
        title: formValues.title,
        registration: formValues.registration,
        customField1: formValues.customField1,
        customField2: formValues.customField2,
        customField3: formValues.customField3,
        customField4: formValues.customField4,
        customField5: formValues.customField5,
        customField6: formValues.customField6,
        serviceContract: formValues.serviceContract,
        creditInsurance: formValues.creditInsurance,
        creditDisability: formValues.creditDisability,
        totalSalePrice: newTotalSalePrice,
        tradeAllowance: tradeAllowance,
        tradePayoff: formValues.tradePayoff,
        depositDown: formValues.depositDown,
        cashOnDelivery: formValues.cashOnDelivery,
        totalDown: formValues.totalDown,
        interestRate: formValues.interestRate,
        amountFinanced: newAmountFinanced,
        schedule: formValues.schedule,
        dateStarted: formValues.dateStarted,
        term: formValues.term,
        amount: paymentAmount,
        numberOfPayments: numPayments,
        final: formValues.final,
        finalDueOn: formValues.finalDueOn,
        financeCharge: formValues.financeCharge,
        totalPayments: formValues.totalPayments,
      },
      saleData,
      newSpecialPaymentPlanList,
      financeQuoteData!,
      lock
    );

    setValue('salesPrice', newData!.salesPrice);
    setValue('salesTax', newData!.salesTax);
    setValue('license', newData!.license);
    setValue('title', newData!.title);
    setValue('registration', newData!.registration);
    setValue('customField1', newData!.addon4);
    setValue('customField2', newData!.addon5);
    setValue('customField3', newData!.addon6);
    setValue('customField4', newData!.addon7);
    setValue('customField5', newData!.addon8);
    setValue('customField6', newData!.addon9);
    setValue('serviceContract', newData!.serviceContract);
    setValue('creditInsurance', newData!.crLife);
    setValue('creditDisability', newData!.crDisb);
    setValue('totalSalePrice', newData!.totalSp);
    setValue('cashOnDelivery', newData!.cod);
    setValue('totalDown', newData!.totalDown);
    setValue('amountFinanced', newData!.amountFinanced);
    setValue('term', roundToTwoDecimals(newData!.term));
    setValue('amount', newData!.paymentAmount);
    setValue('numberOfPayments', newData!.numberPayments);
    setValue('final', newData!.finalPayment);
    setValue('finalDueOn', newData!.finalPaymentDue);
    setValue('financeCharge', newData!.financeCharge);
    setValue('totalPayments', newData!.totalOfPayments);
    setPreviousTerm(roundToTwoDecimals(newData!.term));
    setPreviousAmount(newData!.paymentAmount);
    setPreviousNumPmts(newData!.numberPayments);
  };

  const printQuoteLogic = async () => {
    const formIds = await systemService.getFormIds(saleData.sale!.compid!);
    const quoteUrl = await salesService.printSalesDoc({
      formIds: [formIds.company.formQuoteFSId!],
      appRecId,
    });
    window.open(quoteUrl);
    await salesService.setHasQuote(appRecId);
    dispatch(salesActions.setHasQuote(true));
  };

  const printQuote = async () => {
    setQuotePrinting(true);
    try {
      if (saleData.sale?.salestatus == 'Posted') {
        await printQuoteLogic();
        return;
      }

      const validForm = await trigger();
      if (!validForm) {
        toast.error('Quote has errors, please review');
        return;
      }
      onFieldBlur();
      await updateFinanceTerm(getValues())
        .then(printQuoteLogic)
        .then(resetForm);
    } catch (err) {
      toast.error('Unable to print quote');
    } finally {
      setQuotePrinting(false);
    }
  };

  useEffect(() => {
    dispatch(getQuoteData(compId!));
  }, []);

  useEffect(() => {
    if (!quoteData || !quoteData.length) return;
    setFinanceQuoteData(quoteData.find((value) => value.stype === financeSaleType));
  }, [quoteData]);

  useEffect(() => {
    setValue('totalDown', cashOnDelivery + tradeAllowance - tradePayoff + depositDown);
  }, [cashOnDelivery, tradeAllowance, tradePayoff, depositDown]);

  // should this be a useEffect?
  useMemo(() => {
    setPaymentSchedule(
      `${watch('numberOfPayments')} Payments of ${formatCurrency(
        amount
      )} ${schedule} Beginning ${dayjs(dateStarted).format('MMMM D, YYYY')}`
    );
  }, [amount, schedule, dateStarted]);

  useEffect(() => {
    if (!financeQuoteData) return;
    initData();
  }, [financeQuoteData]);

  const updateFinanceTerm = async (data: typeof financeTermFormDefaults) => {
    setUpdateFinanceLoading(true);
    try {
      if (
        specialPaymentPlanList.some((x) => dayjs(x.dateStarted).diff(data.dateOfSale, 'days') <= 0)
      ) {
        toast.error('All Special Payment Beginning Dates must be later than Date of Sale');
        throw new Error ('All Special Payment Beginning Dates must be later than Date of Sale');
      }
      const res = await salesService.updateFinanceTerms({
        appRecId: appRecId,
        saleDate: data.dateOfSale !== 'Invalid Date' ? dayjs(data.dateOfSale).utc().format("YYYY-MM-DD") : null,
        salePrice: roundToTwoDecimals(data.salesPrice),
        saleTax: roundToTwoDecimals(data.salesTax),
        license: roundToTwoDecimals(data.license),
        title: roundToTwoDecimals(data.title),
        registration: roundToTwoDecimals(data.registration),
        customField1: roundToTwoDecimals(data.customField1),
        customField2: roundToTwoDecimals(data.customField2),
        customField3: roundToTwoDecimals(data.customField3),
        customField4: roundToTwoDecimals(data.customField4),
        customField5: roundToTwoDecimals(data.customField5),
        customField6: roundToTwoDecimals(data.customField6),
        serviceContract: roundToTwoDecimals(data.serviceContract),
        creditInsurance: roundToTwoDecimals(data.creditInsurance),
        creditDisability: roundToTwoDecimals(data.creditDisability),
        totalSalePrice: roundToTwoDecimals(data.totalSalePrice),
        tradeAllowance: roundToTwoDecimals(data.tradeAllowance),
        tradePayoff: roundToTwoDecimals(data.tradePayoff),
        depositDown: roundToTwoDecimals(data.depositDown),
        cashOnDown: roundToTwoDecimals(data.cashOnDelivery),
        totalDown: roundToTwoDecimals(data.totalDown),
        interestRate: roundToTwoDecimals(data.interestRate) * 100,
        amountFinanced: roundToTwoDecimals(data.amountFinanced),
        schedule: data.schedule,
        firstPaymentDue: data.dateStarted !== 'Invalid Date' ? data.dateStarted : null,
        term: data.term,
        numberOfPayments: data.numberOfPayments,
        paymentAmount: roundToTwoDecimals(data.amount),
        finalPayment: roundToTwoDecimals(data.final),
        finalPaymentDue:
          data.finalDueOn && data.finalDueOn !== 'Invalid Date' ? data.finalDueOn : null,
        financeCharge: roundToTwoDecimals(data.financeCharge),
        totalOfPayments: roundToTwoDecimals(data.totalPayments),
        specialPaymentAmount1: data.amount,
        specialPaymentNumber1: data.numberOfPayments,
        specialPaymentDue1:
          data.dateStarted && data.dateStarted !== 'Invalid Date' ? data.dateStarted : null,
        specialPaymentSchedule1: data.schedule,
        specialPaymentAmount2: specialPaymentPlanList[0]
          ? specialPaymentPlanList[0].amount
          : undefined,
        specialPaymentNumber2: specialPaymentPlanList[0]
          ? specialPaymentPlanList[0].numberOfPayments
          : undefined,
        specialPaymentDue2: specialPaymentPlanList[0]
          ? specialPaymentPlanList[0].dateStarted
          : undefined,
        specialPaymentSchedule2: specialPaymentPlanList[0]
          ? specialPaymentPlanList[0].schedule
          : undefined,
        specialPaymentAmount3: specialPaymentPlanList[1]
          ? specialPaymentPlanList[1].amount
          : undefined,
        specialPaymentNumber3: specialPaymentPlanList[1]
          ? specialPaymentPlanList[1].numberOfPayments
          : undefined,
        specialPaymentDue3: specialPaymentPlanList[1]
          ? specialPaymentPlanList[1].dateStarted
          : undefined,
        specialPaymentSchedule3: specialPaymentPlanList[1]
          ? specialPaymentPlanList[1].schedule
          : undefined,
        specialPaymentAmount4: specialPaymentPlanList[2]
          ? specialPaymentPlanList[2].amount
          : undefined,
        specialPaymentNumber4: specialPaymentPlanList[2]
          ? specialPaymentPlanList[2].numberOfPayments
          : undefined,
        specialPaymentDue4: specialPaymentPlanList[2]
          ? specialPaymentPlanList[2].dateStarted
          : undefined,
        specialPaymentSchedule4: specialPaymentPlanList[2]
          ? specialPaymentPlanList[2].schedule
          : undefined,
        specialPaymentAmount5: specialPaymentPlanList[3]
          ? specialPaymentPlanList[3].amount
          : undefined,
        specialPaymentNumber5: specialPaymentPlanList[3]
          ? specialPaymentPlanList[3].numberOfPayments
          : undefined,
        specialPaymentDue5: specialPaymentPlanList[3]
          ? specialPaymentPlanList[3].dateStarted
          : undefined,
        specialPaymentSchedule5: specialPaymentPlanList[3]
          ? specialPaymentPlanList[3].schedule
          : undefined,
        specialPaymentAmount6: data.final,
        specialPaymentNumber6: 1,
        specialPaymentDue6: data.finalDueOn,
        specialPaymentSchedule6: '',
        plan1: paymentSchedule,
        plan2: specialPaymentPlanList[0]
          ? formatSpecialPaymentPlan(specialPaymentPlanList[0])
          : undefined,
        plan3: specialPaymentPlanList[1]
          ? formatSpecialPaymentPlan(specialPaymentPlanList[1])
          : undefined,
        plan4: specialPaymentPlanList[2]
          ? formatSpecialPaymentPlan(specialPaymentPlanList[2])
          : undefined,
        plan5: specialPaymentPlanList[3]
          ? formatSpecialPaymentPlan(specialPaymentPlanList[3])
          : undefined,
        plan6: '',
      });
      dispatch(salesActions.setSaleData(res));
      toast.success('Finance Terms Updated');
      setUpdateFinanceSuccessful(true);
    } catch (err) {
      setUpdateFinanceSuccessful(false);
      toast.error('Unable to update finance terms');
      throw err;
    } finally {
      setUpdateFinanceLoading(false);
    }
  };

  useEffect(() => {
    resetForm();
  }, [isSubmitSuccessful]);

  if (saleDataLoading || quoteDataLoading)
    return (
      <div className={tabStyles.loader}>
        <Loader size="large" />
      </div>
    );
  if (pageError) return <div>{pageError}</div>;

  return (
    <Grid container direction="column">
      <header className={tabStyles.headerContainer}>
        <h2 className={tabStyles.header}>{saleData.sale?.saletype || ''} Terms</h2>
        <div className={tabStyles.headerButtonContainer}>
          <Button label="Sales Tax" onClick={() => navigate('sales-tax')} />
          <Button label="Trade In" onClick={() => navigate('trade-in')} />
          <Button label="Print Quote" onClick={printQuote} loading={quotePrinting}/>
        </div>
      </header>
      <main className={tabStyles.body}>
        <form onSubmit={handleSubmit(updateFinanceTerm)}>
          <div className={tabStyles.bodyContainer}>
            <div className={tabStyles.bodyChild}>
              <Controller
                name="dateOfSale"
                control={control}
                render={({ field }) => <DateInput label="Date of Sale" readOnly={disableDate} {...field} />}
              />
              <Controller
                name="salesPrice"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Sales Price"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />

              <Controller
                name="salesTax"
                control={control}
                render={({ field }) => (
                  <CurrencyInput label="Sales Tax" readOnly rightAlignInput {...field} />
                )}
              />
              <Controller
                name="license"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="License"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Title"
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="registration"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label="Registration"
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    rightAlignInput
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField1"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField1Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                  />
                )}
              />
              <Controller
                name="customField2"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField2Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField3"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField3Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField4"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    label={customField4Name}
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField5"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label={customField5Name}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="customField6"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label={customField6Name}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="serviceContract"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Service Contract"
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="creditInsurance"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Credit Insurance" readOnly {...field} />
                )}
              />
              <Controller
                name="creditDisability"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Credit Disability" readOnly {...field} />
                )}
              />
              <Controller
                name="totalSalePrice"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Total Sale Price" readOnly {...field} />
                )}
              />
              <small className={tabStyles.disclaimer} style={{ alignSelf: 'flex-end' }}>
                Includes sales tax and other fees
              </small>
            </div>
            <div className={tabStyles.bodyChild}>
              <Controller
                name="tradeAllowance"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Trade Allowance" readOnly {...field} />
                )}
              />
              <Controller
                name="tradePayoff"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Trade Payoff" readOnly {...field} />
                )}
              />
              <Controller
                name="depositDown"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Deposit Down" readOnly {...field} />
                )}
              />
              <Controller
                name="cashOnDelivery"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Cash On Delivery"
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="totalDown"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Total Down" readOnly {...field} />
                )}
              />
              <Controller
                name="interestRate"
                control={control}
                render={({ field }) => (
                  <NumberInput
                    rightAlignInput
                    label="Interest Rate"
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    max={1}
                    min={0}
                    format="p2"
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="amountFinanced"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    label="Amount Financed"
                    readOnly
                    inputStyles={{ textAlign: 'right' }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="schedule"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    label="Schedule"
                    disabled={saleData.sale?.salestatus == 'Posted'}
                    data={scheduleData}
                    {...field}
                    onBlur={() => onFieldBlur()}
                  />
                )}
              />
              <Controller
                name="dateStarted"
                control={control}
                rules={{ validate: (d: string | DateString) => {
                  return dayjs(getValues().dateOfSale).startOf('day').diff(dayjs(d), 'day') >= 0
                    ? 'Date Started must be after Date of Sale'
                    : undefined;
                }}}
                render={({ field }) => (
                  <DateInput
                    label="Date Started"
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    {...field}
                    onBlur={() => onFieldBlur()}
                    errors={errors.dateStarted?.message}
                  />
                )}
              />
              <Controller
                name="term"
                control={control}
                render={({ field }) => (
                  <NumberInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Term (months)"
                    format="n2"
                    {...field}
                    onBlur={() => onFieldBlur(undefined, TermsLockType.Num, true)}
                  />
                )}
              />
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="Amount"
                    {...field}
                    onBlur={(e) =>
                      onFieldBlur(
                        undefined,
                        e.target.value != 0 ? TermsLockType.Amt : TermsLockType.Num,
                        false,
                        true
                      )
                    }
                  />
                )}
              />
              <Controller
                name="numberOfPayments"
                control={control}
                render={({ field }) => (
                  <NumberInput
                    rightAlignInput
                    readOnly={saleData.sale?.salestatus == 'Posted'}
                    label="# of Payments"
                    {...field}
                    onBlur={(e) =>
                      onFieldBlur(
                        undefined,
                        e.target.value != 0 ? TermsLockType.Num : TermsLockType.Amt,
                        false,
                        false,
                        true
                      )
                    }
                  />
                )}
              />
              <Controller
                name="final"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Final" readOnly {...field} />
                )}
              />
              <Controller
                name="finalDueOn"
                control={control}
                render={({ field }) => <DateInput label="Final Due On" readOnly {...field} />}
              />
              <Controller
                name="financeCharge"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Finance Charge" readOnly {...field} />
                )}
              />
              <Controller
                name="totalPayments"
                control={control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Total of Payments" readOnly {...field} />
                )}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.rightButtonGroup}>
              <Button
                label="Submit"
                disabled={saleData.sale?.salestatus == 'Posted'}
                buttonStyle={{ width: 102 }}
                loading={updateFinanceLoading}
              />
            </div>
          </div>
        </form>
        <Spacer size={50} />
        <div className={styles.lineSeparator}></div>
        <Spacer size={50} />
        <div className={tabStyles.bodyContainer}>
          <div className={tabStyles.bodyChild} style={{ gap: '23px' }}>
            <h3 className={tabStyles.subHeading}>Payment Schedule</h3>
            <div className={styles.paymentSchedule}>
              <ul>
                <li>
                  {watch('numberOfPayments')} Payments of {formatCurrency(watch('amount'))}{' '}
                  {watch('schedule')} Beginning {dayjs(watch('dateStarted')).format('MMMM D, YYYY')}
                </li>
                {specialPaymentPlanList.map((value, index) => (
                  <div className={styles.paymentScheduleItem}>
                    <li key={index}>
                      {value.numberOfPayments} Payments of {formatCurrency(value.amount)}{' '}
                      {value.schedule} {<br></br>} Beginning{' '}
                      {dayjs(value.dateStarted).format('MMMM D, YYYY')}
                    </li>
                    {saleData.sale?.salestatus != 'Posted' && (
                      <>
                        <div
                          className={styles.delete}
                          onClick={() => {
                            addSpecialPaymentPlan.setValue('selectedIndex', index);
                            addSpecialPaymentPlan.setValue(
                              'numberOfPayments',
                              value.numberOfPayments
                            );
                            addSpecialPaymentPlan.setValue('paymentAmount', value.amount);
                            addSpecialPaymentPlan.setValue('beginningDate', value.dateStarted);
                            addSpecialPaymentPlan.setValue('schedule', value.schedule);
                          }}
                        >
                          <FaPen />
                        </div>
                        <div
                          className={styles.delete}
                          onClick={() => {
                            let filteredSpecialPaymentPlan = specialPaymentPlanList;
                            setSpecialPaymentPlanList((prevList) => {
                              filteredSpecialPaymentPlan = prevList.filter(
                                (list, filteredIndex) => filteredIndex !== index
                              );
                              onFieldBlur(filteredSpecialPaymentPlan);
                              return filteredSpecialPaymentPlan;
                            });
                          }}
                        >
                          <Icons.X />
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <>
                  {watch('final') > 0 && (
                    <li>
                      Final Payment of {formatCurrency(watch('final'))} {<br></br>} Due{' '}
                      {dayjs(watch('finalDueOn')).format('MMMM D, YYYY')}
                    </li>
                  )}
                </>
              </ul>
            </div>
          </div>
          <form
            className={tabStyles.bodyChild}
            style={{ gap: '23px' }}
            onSubmit={addSpecialPaymentPlan.handleSubmit(submitSpecialPaymentPlan)}
          >
            <h3 className={tabStyles.subHeading}>Add Special Payment Plan +</h3>
            <Controller
              name="numberOfPayments"
              control={addSpecialPaymentPlan.control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <NumberInput
                  format="n0"
                  required
                  label="Number of Payments"
                  readOnly={saleData.sale?.salestatus == 'Posted'}
                  errors={addSpecialPaymentPlan.formState.errors.numberOfPayments?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="paymentAmount"
              control={addSpecialPaymentPlan.control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <CurrencyInput
                  required
                  label="Payment Amount"
                  readOnly={saleData.sale?.salestatus == 'Posted'}
                  errors={addSpecialPaymentPlan.formState.errors.paymentAmount?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="schedule"
              control={addSpecialPaymentPlan.control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <DropdownInput
                  required
                  label="Schedule"
                  disabled={saleData.sale?.salestatus == 'Posted'}
                  data={scheduleData}
                  errors={addSpecialPaymentPlan.formState.errors.schedule?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="beginningDate"
              control={addSpecialPaymentPlan.control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <DateInput
                  required
                  label="Beginning Date"
                  readOnly={saleData.sale?.salestatus == 'Posted'}
                  errors={addSpecialPaymentPlan.formState.errors.beginningDate?.message}
                  {...field}
                />
              )}
            />
            <div className={styles.paymentPlanButtonContainer}>
              <div className={styles.paymentPlanButton}>
                <Button
                  type="button"
                  disabled={saleData.sale?.salestatus == 'Posted'}
                  label={addSpecialPaymentPlan.watch('selectedIndex') > -1 ? 'Cancel' : 'Reset'}
                  onClick={() => {
                    resetSpecialPaymentPlanForm();
                  }}
                />
              </div>
              <div className={styles.paymentPlanButton}>
                <Button
                  label={
                    addSpecialPaymentPlan.watch('selectedIndex') > -1
                      ? 'Edit Payment'
                      : 'Add Payment'
                  }
                  disabled={saleData.sale?.salestatus == 'Posted'}
                  fillMode="outline"
                />
              </div>
            </div>
          </form>
        </div>
      </main>
      {NavigationConfirm}
    </Grid>
  );
};

export default FinanceTerm;
