import { FC } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
// interfaces
import { ColumnData, scrollBarHeight } from './interfaces';

const DataItem: FC<{ isAxis?: boolean; value: string | number | undefined }> = ({
  value = '_',
  isAxis = false,
}) => (
  <Grid alignSelf="start" pr={1} pl={isAxis ? 0 : 1} sx={{ opacity: value ? 1 : 0 }}>
    {value}
  </Grid>
);

/** ###  */
const ColumnStats: FC<Grid2Props & ColumnData<number | string> & { isAxis?: boolean }> = ({
  isAxis = false,
  percent,
  accountCount,
  payDelq,
  ar,
  ...props
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="start"
      alignItems="center"
      gap={1.2}
      wrap="nowrap"
      textOverflow="ellipsis"
      fontSize={10}
      fontWeight={isAxis ? 400 : 800}
      // @note the axis column is not scrollable
      pb={isAxis ? scrollBarHeight : undefined}
      borderRight={`1px solid rgba(0,0,0,${isAxis ? '0.1' : '0'})`}
      sx={{ textWrap: 'nowrap' }}
      {...props}
    >
      <Divider flexItem variant="fullWidth" />

      <DataItem isAxis={isAxis} value={percent} />
      <DataItem isAxis={isAxis} value={accountCount} />
      <DataItem isAxis={isAxis} value={payDelq} />
      <DataItem isAxis={isAxis} value={ar} />
    </Grid>
  );
};

export default ColumnStats;
