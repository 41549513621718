import dayjs, { Dayjs } from "dayjs";
// interfaces
import { RoleRow } from "./role";
import { ICompanyLookup } from "@/interfaces/Company";
import { UserAuthForm } from "../AuthorizationMgmt/UserAuthDetail/form";

export class UserAuthRes<TDate extends string | Dayjs = string> {
  constructor(
    public recId: number,
    public firstName: string,
    public lastName: string,
    public phoneCell: string,
    public dateLastAccess: TDate,
    public roleRecId: number | null,
    public active: boolean,
    /** @deprecated not sent from backend */
    public defOrgId: number | null,
    public defCompId: number | null,
    public userId: string,
    public phoneCell1: string,
    public securityLevel: string | null,
    public dms_Accounts: boolean,
    public dms_Collections: boolean,
    public dms_Sales: boolean,
    public dms_Inventory: boolean
  ) {}

  static fromForm(
    activeUser: Omit<UserAuthRow, keyof UserAuthForm>,
    formState: UserAuthForm
  ): UserAuthRes {
    return new UserAuthRes(
      activeUser.recId,
      activeUser.firstName,
      activeUser.lastName,
      activeUser.phoneCell,
      activeUser.dateLastAccess.toString(),
      formState.roleRecId ?? null,
      formState.active,
      null,
      formState.defCompId ?? null,
      activeUser.userId,
      activeUser.phoneCell1,
      activeUser.securityLevel,
      formState.dms_Accounts,
      formState.dms_Collections,
      formState.dms_Sales,
      formState.dms_Inventory
    );
  }
}

export class UserAuthRow {
  role: RoleRow | null;
  company: ICompanyLookup | null;

  recId: number;
  firstName: string;
  lastName: string;
  phoneCell: string;
  dateLastAccess: Dayjs;
  active: boolean;
  userId: string;
  phoneCell1: string;
  securityLevel: string | null;
  dms_Accounts: boolean;
  dms_Collections: boolean;
  dms_Sales: boolean;
  dms_Inventory: boolean;

  constructor(user: UserAuthRes, roleRows: RoleRow[], companies: ICompanyLookup[]) {
    const role = roleRows.find((role) => role.recId === user.roleRecId) || null;
    this.role = role;
    const company = companies.find((comp) => comp.recId === user.defCompId) || null;
    this.company = company;

    this.recId = user.recId; //: number;
    this.firstName = user.firstName; //: string;
    this.lastName = user.lastName; //: string;
    this.phoneCell = user.phoneCell; //: string;
    this.dateLastAccess = dayjs(user.dateLastAccess); //: Dayjs;
    this.active = user.active; //: boolean;
    this.userId = user.userId; //: string;
    this.phoneCell1 = user.phoneCell1; //: string;
    this.securityLevel = user.securityLevel; //: string | null;
    this.dms_Accounts = user.dms_Accounts; //: boolean;
    this.dms_Collections = user.dms_Collections; //: boolean;
    this.dms_Sales = user.dms_Sales; //: boolean;
    this.dms_Inventory = user.dms_Inventory; //: boolean;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
