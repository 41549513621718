import { FC, useEffect, useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// kendo
import { TextArea } from '@progress/kendo-react-inputs';
import { Icons } from '@/components/icons';
import { Button } from '@/components/old/button/Button';
import { DmsModal } from '@/components/old/dmsModal/DmsModal';
// utils
import { notesService } from '@services/notesService';
import { displayErrors } from '@/utils/helpers/general';
// style
import styles from './DmsNotes.module.scss';

export const DmsNotes: FC<{ colRecId?: number }> = ({ colRecId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [note, setNote] = useState('');
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useEffect(() => {
    getNotes();
  }, [colRecId]);

  const getNotes = async () => {
    if (!colRecId) return;
    setRefreshing(true);
    try {
      const res = await notesService.getNotes(colRecId);
      setNotes(res);
    } catch (err: any) {
      if (err?.response?.status !== 404) {
        displayErrors('Unable to get notes');
      }
    } finally {
      setRefreshing(false);
    }
  };

  const handleSubmit = () => {
    if (!colRecId) return;

    setRefreshing(true);
    const submitNote = note;
    setNote('');
    notesService.sendNote(colRecId, submitNote).then(() => {
      getNotes();
    });
  };

  const buttonStyle = {
    height: '100%',
  } as React.CSSProperties;

  return (
    <>
      {refreshing ? (
        <div className={styles.loading}>
          <CircleSpinner color={'#3299df'} size={50} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div>Customer Notes</div>
            <div>
              <Icons.PopOut width={'2rem'} height={'2rem'} onClick={() => setOpenModal(true)} />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.notesContainer}>
              <pre className={styles.noteArea}>{notes}</pre>
            </div>
            <TextArea
              name="noteInput"
              value={note}
              rows={2}
              autoSize
              onChange={(e) => setNote(e.target.value!)}
            />
            <div className={styles.footer}>
              <Button buttonLabel="Save Note" buttonStyles={buttonStyle} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      )}
      <DmsModal isOpen={openModal} closeFunction={() => setOpenModal(false)}>
        <div className={styles.modalArea}>
          <pre className={styles.noteArea}>{notes}</pre>
        </div>
      </DmsModal>
    </>
  );
};
