import { FC } from "react";
// mui
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";

const defaultStyle: SxProps = {
  fontWeight: 700,
  color: "rgb(17, 24, 39)",
  fontSize: 14,
  p: 1,
  textWrap: "nowrap",
};

/** ###  */
const MuiKendoFormLabel: FC<FormLabelProps> = ({ sx: sxProps, ...labelProps }) => (
  <FormLabel sx={{ ...defaultStyle, ...sxProps }} {...labelProps} />
);

export default MuiKendoFormLabel;
