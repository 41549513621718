import { FC, Fragment } from 'react';
// interfaces
import { LedgerPersonalReference } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardReferences: FC<{ personalReferences?: LedgerPersonalReference[] }> = ({
  personalReferences,
}) => (
  <>
    <div style={ledgerStyles.sectionHeader}>Personal References</div>
    <div>
      <table style={ledgerStyles.ledgerTable}>
        <tbody>
          {personalReferences
            ? personalReferences.map((lpr, idx) => (
                <Fragment key={idx}>
                  <tr key={idx + 'label1'}>
                    <td style={ledgerStyles.labelRowTd}>Reference For</td>
                    <td style={ledgerStyles.labelRowTd}>Name</td>
                    <td></td>
                    <td style={ledgerStyles.labelRowTd}>Address</td>
                    <td style={ledgerStyles.labelRowTd}>Known For</td>
                  </tr>
                  <tr key={idx + 'row1'}>
                    <td>{lpr.refFor}</td>
                    <td colSpan={2}>{lpr.name}</td>
                    <td>{lpr.address}</td>
                    <td>
                      {lpr.knownForYears} years & {lpr.knownForMonths} months
                    </td>
                  </tr>
                  <tr key={idx + 'label2'}>
                    <td style={ledgerStyles.labelRowTd}>Relationship</td>
                    <td style={ledgerStyles.labelRowTd}>Home Phone</td>
                    <td style={ledgerStyles.labelRowTd}>Cell Phone</td>
                    <td style={ledgerStyles.labelRowTd} colSpan={2}>
                      Email
                    </td>
                  </tr>
                  <tr key={idx + 'row2'} className={'bot-line'}>
                    <td>{lpr.relationship}</td>
                    <td>{lpr.homePhone}</td>
                    <td>{lpr.cellPhone}</td>
                    <td colSpan={2}>{lpr.email}</td>
                  </tr>
                </Fragment>
              ))
            : null}
        </tbody>
      </table>
    </div>
  </>
);

export default LedgerCardReferences;
