import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// kendo
import { ListView } from '@progress/kendo-react-all';
// components
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
// state
import { useInventoryViewCtx } from '../state';
// utils
import { inventoryTypesArr } from '../inventoryDetails/default';
import { dateRangeOptions, formatFormDate } from '../../../general/dates';
// styles
import styles from '../ListStyles.module.scss';

/** ### Dropdown selector component for inventory types
 *
 * - Renders in the Header component.
 * - Clickable button that displays a list of options which switch the view on click.
 * - View is handled by state rather than react router.
 */
const InventorySelector: FC = () => {
  const setListLength = useInventoryViewCtx((s) => s.setListLength);
  const setModalFilterParams = useInventoryViewCtx((s) => s.setModalFilterParams);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  // Add onclick handler to list items - set view and dropdown state
  const inventoryTypesWithHandler = inventoryTypesArr.map((info) => {
    return {
      ...info,
      onClick: () => {
        //clear state
        setListLength(0);
        // Set view state
        info.id === 'sold'
          ? setModalFilterParams({
              beginDate: formatFormDate(dateRangeOptions['This Month-to-date'].beginDate),
              endDate: formatFormDate(dateRangeOptions['This Month-to-date'].endDate),
              amt: null,
              int: null,
            })
          : setModalFilterParams({ beginDate: null, endDate: null, amt: null, int: null });
        navigate(`/inventory/${info.id}`);
        // Close dropdown
        setDropdownOpen(false);
      },
    };
  });

  return (
    <DropDownButton
      buttonStyles={{
        borderRadius: '16px',
        boxShadow: '0px 2px 4px 0px rgba(41, 70, 149, 0.50)',
        width: '200px',
      }}
      popUpProps={{
        popupAlign: { vertical: 'top', horizontal: 'right' },
        anchorAlign: { vertical: 'bottom', horizontal: 'right' },
      }}
      setOpen={setDropdownOpen}
      open={dropdownOpen}
      label="Select List"
    >
      <div className={styles.dropdownOptionContainer}>
        <ListView
          data={inventoryTypesWithHandler}
          item={(props) => (
            <div className={styles.dropdownOption} onClick={props.dataItem.onClick}>
              {props.dataItem.text}
            </div>
          )}
        />
      </div>
    </DropDownButton>
  );
};

export default InventorySelector;
