import { SortDescriptor, State as DataQueryState } from '@progress/kendo-data-query';

export class ServerGridOptions {
  public sort: SortDescriptor[] = [];
  public page?: DataQueryState | null = null;
  public search?: string | undefined;

  public constructor(
    sort?: SortDescriptor[],
    page?: DataQueryState | null,
    search?: string | undefined
  ) {
    this.sort = sort ?? [];
    this.page = page ?? null;
    this.search = search;
  }

  public toQueryParams() {
    const queryParams: { [key: string]: string | number | undefined } = {};

    if (this.sort.length > 0) {
      for (let i = 0; i < this.sort.length; i++) {
        queryParams[`sort[${i}].field`] = this.sort[i]?.field;
        queryParams[`sort[${i}].dir`] = this.sort[i]?.dir;
      }
    }

    if (this.page) {
      queryParams['page.skip'] = this.page.skip;
      queryParams['page.take'] = this.page.take;
    }

    if (this.search) {
      queryParams['search'] = this.search;
    }

    return queryParams;
  }
}

export interface GridResponse<T> {
  data: T[];
  total: number;
}
