import React, { FC } from 'react';
// kendo
import { Spacer } from '@/components/spacer/Spacer';
import { Button } from '@/components/old/button/Button';
import { DmsTextInput } from '@/components/old/dmsTextInput/DmsTextInput';
// style
import styles from './EditEmail.module.scss';

export const EditEmail: FC<{
  email?: string;
  saveAndUpdateEmail: (value?: string) => void;
  containerStyles?: React.CSSProperties;
  setEmail: (value: string) => void;
  header: string;
  updating?: boolean;
}> = ({ email, setEmail, saveAndUpdateEmail, containerStyles = {}, header, updating = false }) => (
  <div className={styles.container} style={containerStyles}>
    <div className={styles.title}>{header}</div>
    <Spacer size={8} />
    <div className={styles.subContainer}>
      <div className={styles.inputContainer}>
        <DmsTextInput
          value={email}
          inputStyles={{ width: '100%' }}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <Button
          buttonLabel="Save"
          buttonStyles={{
            width: '50px',
            height: '27.5px',
            padding: '4.5px 9.75px',
          }}
          disabled={updating}
          onClick={() => saveAndUpdateEmail(email)}
        />
      </div>
    </div>
  </div>
);
