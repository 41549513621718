import { FC } from "react";
// kendo
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// utils
import { getAllowedPmtTypes } from "@/utils/helpers/payment";
import { DropdownInput } from "@/components";

/** ###  */
const PaidByField: FC = () => {
  const paymentProviderCfg = usePmtFormCtx((s) => s.paymentProviders.value);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("paidBy"));
  const { isMiscPmt, isWsPmt } = usePmtFormCtx((s) => s.formState.config.external);
  const totalPayment = usePmtFormCtx((s) => s.formState.form.totalPayment);
  const isNegativeMisc = isMiscPmt && totalPayment < 0;

  const options = getAllowedPmtTypes(paymentProviderCfg);

  // Filter options based on conditions
  const optionsApplied = isNegativeMisc
    ? options.filter((o) => o !== "Credit Card")
    : isWsPmt
    ? options.filter((o) => o !== "ACH") // @note ACH may be added back to 'wholesale' when enabled
    : options;

  return (
    <DropdownInput label="Payment Type" required data={optionsApplied} {...fieldProps} />
  );
};

export default PaidByField;
